import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as SECRET from '@config/apiKey';
import { BASE_URL } from '@utils/api';
import jwt from 'jwt-simple';
import { useStoreNotification } from '@stores/catalogs.store';

const ImprimirContrato = ({ showModal, onClose, urlContrato, navigator, adjuntoId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [isLoading] = useState(false);

  const closeImprimir = () => {
    onClose(false);
  };

  const handleCopiarUrlImprimir = () => {
    const adjuntoIdEncode = jwt.encode(adjuntoId, SECRET.API_KEY);
    copyToClipboard(BASE_URL + `firmarContrato/${adjuntoIdEncode}`);
    notification({
      message: '¡Url copiada con éxito!',
      type: 'success',
    });
  };

  const handleAceptarImprimir = () => {
    window.open(urlContrato, '_blank');
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  return (
    <Modal show={showModal} onHide={closeImprimir}>
      <Modal.Header closeButton>
        <Modal.Title>Descargar contrato</Modal.Title>
      </Modal.Header>
      <Modal.Footer className='text-center'>
        <Button onClick={handleCopiarUrlImprimir} className='btn btn-primary mr-5' type='button'>
          Copiar URL
        </Button>
        <Button onClick={handleAceptarImprimir} className='btn btn-primary mr-5' type='button'>
          Imprimir
        </Button>
        <Button onClick={closeImprimir} disabled={isLoading} className='mr-5' type='button'>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ImprimirContrato.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  urlContrato: PropTypes.string.isRequired,
  navigator: PropTypes.object.isRequired,

  adjuntoId: PropTypes.string.isRequired,
};

export default ImprimirContrato;
