import { MIME_TYPES } from '@config/constants';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { urlPhoto } from '@utils/api';
import { FileDownIcon, FileUpIcon } from 'lucide-react';

const RowFichaTecnica = ({ fichaTecnica, onChangeFichaTecnica }) => {
  const imageInput = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    onChangeFichaTecnica({
      file: file,
    });
  };

  return (
    <tr>
      <td>{fichaTecnica?.nombre}</td>
      <td className='text-right'>
        <input type='file' name='image' style={{ display: 'none' }} accept={MIME_TYPES.PDF} ref={imageInput} onChange={handleImageChange} />
        <OverlayTrigger placement='top' overlay={<Tooltip>Importar</Tooltip>}>
          <Button type='button' className='btn btn-sm btn-primary mr-5' onClick={() => imageInput.current.click()}>
            <FileUpIcon />
          </Button>
        </OverlayTrigger>
        {fichaTecnica?.file ? (
          <OverlayTrigger placement='top' overlay={<Tooltip>Descargar</Tooltip>}>
            <a
              href={`${urlPhoto()}${fichaTecnica.file?.path?.substring(5, fichaTecnica.file?.path?.length)}`}
              target='_blank'
              rel='noopener noreferrer'
              className='btn btn-sm btn-warning mr-5'
            >
              <FileDownIcon />
            </a>
          </OverlayTrigger>
        ) : null}
      </td>
    </tr>
  );
};

RowFichaTecnica.propTypes = {
  fichaTecnica: PropTypes.object,
  onChangeFichaTecnica: PropTypes.func,
};

export default RowFichaTecnica;
