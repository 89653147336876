import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ catalogAnticipos, ...props }) => {
  const renderRows = () => {
    return catalogAnticipos.map((row, i) => {
      const { _id, fecha, fechaString, valor } = row;

      return (
        <tr key={i}>
          <td>{fechaString}</td>
          <td>${format(valor, 3)}</td>
          <td>
            <IfPermission action='editar'>
              <Edit id={i} itemId={_id} data={{ fecha, fechaString, valor }} {...props} />
            </IfPermission>
            <IfPermission action='eliminar'>
              <Delete id={i} itemId={_id} data={{ fecha, fechaString, valor }} {...props} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='50%'>Fecha</th>
          <th width='40%'>Valor</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  catalogAnticipos: PropTypes.array.isRequired,
};

export default List;
