import { DivFormGroup, Input, Label, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmail, isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const EnviarDocumentos = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [subject, setSubject] = useState('Envío de orden de servicio');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [checkEnviarCopia] = useState(false);
  const [codigos] = useState(true);
  const [desgloseKits] = useState(false);
  const [horasServicio] = useState(false);

  const close = () => {
    setShowModal(false);
    setSubject('');
  };

  const open = () => {
    const { email, onGuardarFirma } = props;
    onGuardarFirma(() => {
      setShowModal(true);
      setSubject('Envío de orden de servicio');
      setEmail(email || '');
      setIsLoading(false);
    });
  };

  const handleSendReference = async (event) => {
    event.preventDefault();
    const { visitaId } = props;

    const body = {
      subject: subject.trim(),
      email: email.trim(),
      enviarCopia: checkEnviarCopia,
      codigos,
      desgloseKits,
      horasServicio,
    };

    if (validations(body)) {
      await postRequest({ url: `ordenes-servicios-visitas/email/${visitaId}`, body });
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (validate.success && isEmpty(params.subject + '')) {
      validate.success = false;
      validate.message = 'El campo Asunto es requerido.';
    } else if (validate.success && params.subject.length > 60) {
      validate.success = false;
      validate.message = 'El campo Asunto excede el limite de caracteres, solo se permiten hasta 60.';
    } else if (validate.success && isEmpty(params.email + '')) {
      validate.success = false;
      validate.message = 'El campo de Destinatario(s) es requerido.';
    }

    const list = params.email.split(',');

    if (validate.success && list.length > 0) {
      const fail = list.filter((e) => {
        if (!isEmpty(e.trim() + '')) {
          return !isEmail(e.trim() + '');
        }
      });

      if (fail.length > 0) {
        validate.success = false;
        validate.message = 'Favor de especificar correo válidos en el campo de Destinatario(s).';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <Button onClick={open}>
        <Icon icon='envelope' /> Enviar por correo
      </Button>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar por correo </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSendReference}>
            <DivFormGroup>
              <Label name='subject' title='Asunto' />
              <Input name='subject' onChange={(e) => setSubject(e.target.value)} value={subject} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='email' title='Destinatario(s)' />
              <TextArea name='email' onChange={(e) => setEmail(e.target.value)} value={email} />
              <p>
                <br />
                <small>Separar correos por comas: correo1@codigoblanco.mx,correo2@codigoblanco.mx </small>
              </p>
            </DivFormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} onClick={handleSendReference} variant='success' className='pull-right'>
            Enviar
          </Button>
          <Button disabled={isLoading} onClick={close} variant='light' className='pull-right' type='button'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EnviarDocumentos.propTypes = {
  id: PropTypes.string,
  referenceId: PropTypes.string,
  email: PropTypes.string,
  contactos: PropTypes.array,
  reference: PropTypes.string,
  onGuardarFirma: PropTypes.func,
  visitaId: PropTypes.string,
};

export default EnviarDocumentos;
