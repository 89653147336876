import { DivFormGroup } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, FormGroup, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';

const Import = () => {
  const [state, setState] = useState({
    tipo: 'C',
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
  });

  const frm = useRef(null);

  useEffect(() => {}, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'tipo') {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        csv: null,
        valid: false,
        msg: '',
        progress: 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    }));

    const form = new FormData(frm.current);
    let tipo = '';
    switch (state.tipo) {
      case 'C':
        tipo = 'correos';
        break;
    }
    const { csv, valid, msg } = await postUploadRequest({ url: `importar-colonias-${tipo}-csv`, form });
    setState((prevState) => ({
      ...prevState,
      csv,
      valid,
      msg,
      cargando: false,
      progress: 0,
      isLoading: false,
    }));
  };

  const renderForm = () => {
    const { cargando, isLoading, csv, progress } = state;
    return (
      <Module onClickBack={window.history.back} title='Importar colonias'>
        <Card>
          <Card.Body>
            <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
              <Row>
                <Col sm='12'>
                  <FormGroup className='inline'>
                    <label>Tipo archivo</label>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='tipo' id='rdo-clickbalance' onChange={onChange} defaultChecked={true} value={'C'} /> Correos de
                        México
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup>
                    <div className='btn btn-default btn-file'>
                      <Icon icon='paperclip' /> Archivo de colonias
                      <input type='file' name='csv' id='csv' onChange={handleReadCSV} />
                    </div>
                  </DivFormGroup>
                </Col>
                <Col sm='9'>{isLoading ? <Saving action='Importantando' /> : ''}</Col>
                <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
              </Row>
            </form>
            <form>{csv !== null ? renderDataCSV() : null}</form>
          </Card.Body>
        </Card>
      </Module>
    );
  };

  const renderDataCSV = () => {
    const {
      csv: { detalle, headers },
      valid,
      msg,
    } = state;

    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12' style={{ position: 'relative', overflowX: 'auto', width: '100%' }}>
          <table className='table table-striped align-middle'>
            <thead>
              <tr>
                <th>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => (
                  <th key={i}>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detalle.length > 0 ? (
                detalle.map((d, i) => {
                  let datoCreacion = 0;
                  switch (state.tipo) {
                    case 'C':
                      datoCreacion = 29;
                      break;
                  }
                  return (
                    <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                      <td>{d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}</td>
                      {headers.map((e, j) => (
                        <td key={j}>{d[j]}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <RowsNotFound colSpan={7} />
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  return renderForm();
};

Import.propTypes = {};

export default Import;
