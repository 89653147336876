import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

const BsButton = ({ children, onClick, type = 'primary', disabled = false, isSubmit, size }) => {
  let typeButton = isSubmit ? 'submit' : 'button';
  let sizeButton = size && `btn-${size}`;
  return (
    <Button type={typeButton} variant={`outline-${type}`} className={`${sizeButton}`} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

BsButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  size: PropTypes.string,
};

export default BsButton;
