import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import EditVisita from './EditVisita';
import NewVisita from './NewVisita';
import { Card } from 'react-bootstrap';
import { Scheduler } from "@aldabil/react-scheduler";
import { es } from 'date-fns/locale';

const TRANSLATIONS = {
    navigation: {
      month: "Mes",
      week: "Semana",
      day: "Dia",
      today: "Hoy",
      agenda: "Agenda"
    },
    form: {
      addTitle: "Agregar visita",
      editTitle: "Editar visita",
      confirm: "Confirmar",
      delete: "Eliminar",
      cancel: "Cancelar"
    },
    event: {
      title: "Titulo",
      subtitle: "Subtitulo",
      start: "Inicio",
      end: "Fin",
      allDay: "Todo el día"
    },
    validation: {
      required: "Requerido",
      invalidEmail: "Correo inválido",
      onlyNumbers: "Solo números",
      min: "Minimo {{min}} letras",
      max: "Maximo {{max}} letras"
    },
    moreEvents: "Mas...",
    noDataToDisplay: "Sin datos para mostrar",
    loading: "Cargando..."
};
const OrdenesServicioVisitas = () => {
  const [state, setState] = useState({
    resources: [],
    list: [],
    loading: false,
    checkEnviarCopia: true,
    search: '',
    storehouses: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    tipos_ordenes_servicios: [],
    agentes: [],
    estatus_ordenes: {
      CANCELADA: 'CANCELADA',
      PAUSADA: 'PAUSADA',
      FINALIZADA: 'FINALIZADA',
      INICIADA: 'INICIADA',
      EN_PROCESO: 'EN PROCESO',
    },
    estatus: [],
    estatus_orden_servicio: '0',
    tipoOrdenServicioId: '0',
    sucursalId: '0',
    agenteId: '0',
    agenteAsignadoId: '0',
    sucursales: [],
    inList: 'CALENDARIO',
    agenda: [],
    ordenServicioId: null,
    visitaId: null,
    showModalNew: false,
    loadingCatalogos: false,
    selectedDate: new Date(),
  });

  const [fechaInicio, setFechaInicio] = useState(moment().startOf('month').format());
  const [fechaFin, setFechaFin] = useState(moment().endOf('month').format());
  const [loading, setLoading] = useState(false);  
  const [view, setView] = useState('day');
  const [listAgentes, setListAgentes] = useState([]);

  const handleSearch = async () => {
    setLoading(true);
    const body = {
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data } = await postRequest({ url: `ordenes-servicios-visitas/search`, body });
    let agentes = listAgentes.map((x) => ({ ...x, color: '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'), servicios: 0 }));
    const agenda = data.reduce((a, visita) => {
      const visitas = visita.agentes_asignados
        ? visita.agentes_asignados.map((agnt) => {
          const agentePrincipal = agentes.find((x) => x._id === agnt._id);
          if (agentePrincipal) agentePrincipal.servicios++;
            return {
              visitaId: visita._id,
              resourceId: agentePrincipal?._id,
              ordenServicioId: visita.ordenServicioId._id,
              id: visita.ordenServicioId._id + '-' + visita._id,
              backgroundColor: agentePrincipal?.color,
              title:
                'OT-' +
                visita.ordenServicioId.numero_orden_servicio +
                ' ' +
                (visita.ordenServicioId.referencia || ''),
              subtitle: visita.ordenServicioId.cliente.razon_social,
              start: moment(visita.fecha_hora).format(),
              end: visita.fecha_hora_fin
                ? moment(visita.fecha_hora_fin).format()
                : moment(visita.fecha_hora).add(visita.horas_servicio, 'hours').format(),
              description: visita.ordenServicioId.cliente.razon_social,
            };
          })
        : [];
      return [...a, ...visitas];
    }, []);
    setState((prevState) => ({
      ...prevState,
      resources: agentes.filter((x) => x.es_tecnico).map((x) => ({ id: x._id, title: x.nombre, color: x.color, servicios: x.servicios })).sort((a, b) => a.servicios - b.servicios).reverse(),
      agenda,
      list: data,
    }));
    setLoading(false);
  };

  useEffect(() => {
    if (listAgentes.length > 0)
      handleSearch();
  }, [fechaInicio, fechaFin, listAgentes]);

  const loadCatalogos = async () => {
    const {
      data: { agentes },
    } = await getRequest({ url: `ordenes-servicio-catalogos` });
    setListAgentes([...agentes]);
  };

  useEffect(() => {
    loadCatalogos();
  }, []);

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      showModalNew: false,
      visitaId: null,
      ordenServicioId: null,
    }));
  };

  const { agenda, loadingCatalogos, resources, selectedDate } = state;
  return (
    <Module title='Ordenes de servicio'>
      <Loading loading={loadingCatalogos || loading}>
        <form>
          <>
            <Card>
              <Card.Body>
                <Scheduler
                  resourceViewMode='default'
                  view={view}
                  selectedDate={selectedDate}
                  events={agenda.map((x) => ({ event_id: x.id, title: x.title, start: moment(x.start).toDate(), end: moment(x.end).toDate(), admin_id: x.resourceId, subtitle: x.subtitle }))}
                  resources={resources.map((x) => ({ admin_id: x.id, title: x.title, mobile: x.mobile || '', color: x.color || '' }))}
                  resourceFields={{
                    idField: "admin_id",
                    textField: "title",
                    subTextField: "mobile",
                    avatarField: "title",
                    colorField: "color"
                  }}
                  onSelectedDateChange={(date) => {
                    if(date != state.selectedDate){
                      setState((prevState) => ({
                        ...prevState,
                        selectedDate: date,
                      }));
                    }
                  }}
                  getRemoteEvents={(query) => {
                    setFechaInicio(moment(query.start).format('YYYY-MM-DD'));
                    setFechaFin(moment(query.end).format('YYYY-MM-DD'));
                    setView(query.view);
                  }}
                  fields={[
                    {
                      name: "admin_id",
                      type: "select",
                      default: resources[0]?.admin_id,
                      options: resources.map((res) => {
                        return {
                          id: res.admin_id,
                          text: `${res.title}`,
                          value: res.admin_id
                        };
                      }),
                      config: { label: "Asignado", required: true }
                    }
                  ]}
                  onEventClick={(event) => {
                    const [ordenServicioId, visitaId] = event.event_id.split('-');
                    setState((prevState) => ({
                      ...prevState,
                      showModal: true,
                      ordenServicioId,
                      visitaId,
                    }));
                    return false;
                  }}
                  onCellClick={(start, end, resourceKey, resouceValue) => {
                    setState((prevState) => ({
                      ...prevState,
                      showModalNew: true,
                      fecha_hora: start,
                      agentesAsignados: resources.map((x) => ({ _id: x.id, nombre: x.title })).find((x) => x._id === resouceValue),
                      fecha_hora_fin: end,
                    }));
                    setTimeout(() => {
                      const cancelButton = Array.from(document.querySelectorAll('button')).find(button => button.textContent === 'Cancelar');
                      if (cancelButton) {
                        cancelButton.click();
                      }
                    }, 10);
                    return false;
                  }}
                  disableViewer
                  translations={TRANSLATIONS}
                  locale={es}
                />
              </Card.Body>
            </Card>
          </>
          <EditVisita
            ordenServicioId={state.ordenServicioId}
            visitaId={state.visitaId}
            showModal={state.showModal}
            loadData={handleSearch}
            close={closeModal}
          />
          <NewVisita
            fecha_hora={state.fecha_hora}
            fecha_hora_fin={state.fecha_hora_fin}
            agentesAsignados={state.agentesAsignados}
            showModalNew={state.showModalNew}
            loadData={handleSearch}
            close={closeModal}
          />
        </form>
      </Loading>
    </Module>
  );
};

OrdenesServicioVisitas.propTypes = {};

export default OrdenesServicioVisitas;
