import * as SECRET from '@config/apiKey';
import jwt from 'jwt-simple';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Saving } from '@stateless';
import { BASE_URL, getRequest, URL_FILES } from '@utils/api';
import { useStoreNotification } from '@stores/catalogs.store';
import { FileDownIcon } from 'lucide-react';

const ImprimirLevantamiento = ({ id, levantamientoId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const [codigos, setCodigos] = useState(false);
  const [desgloseKits, setDesgloseKits] = useState(false);
  const [horasServicio, setHorasServicio] = useState(false);
  const [partidasConcentradas, setPartidasConcentradas] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const onClickDownloadPdf = () => {
    setShowModal(true);
  };

  const onChange = (e) => {
    const { value, name, checked, type } = e.target;
    if (type === 'checkbox') {
      if (name === 'codigos') setCodigos(checked);
      if (name === 'desgloseKits') setDesgloseKits(checked);
      if (name === 'horasServicio') setHorasServicio(checked);
      if (name === 'partidasConcentradas') setPartidasConcentradas(checked);
    } else if (type === 'radio') {
      if (name === 'codigos') setCodigos(value === 'true');
      if (name === 'desgloseKits') setDesgloseKits(value === 'true');
      if (name === 'horasServicio') setHorasServicio(value === 'true');
      if (name === 'partidasConcentradas') setPartidasConcentradas(value === 'true');
    }
  };

  const handleAceptar = async (event) => {
    event.preventDefault();
    const { data } = await getRequest({
      url: `cotizaciones/pdf/${levantamientoId}`,
      params: { codigos, desgloseKits, horasServicio, partidasConcentradas },
    });
    if (data) {
      setShowModal(false);
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };

  const handleCopiar = (e) => {
    e.preventDefault();
    const cotizacionId = jwt.encode(levantamientoId, SECRET.API_KEY);
    copyToClipboard(
      BASE_URL +
        `firmarCotizacion/${cotizacionId}?codigos=${codigos}&desgloseKits=${desgloseKits}&horasServicio=${horasServicio}&partidasConcentradas=${partidasConcentradas}`
    );
    notification({
      message: '¡Url copiada con éxito!',
      type: 'success',
    });
  };

  const handleExcel = async (e) => {
    e.preventDefault();
    const { file } = await getRequest({ url: `cotizaciones/exportar/${levantamientoId}` });
    if (file) {
      setShowModal(false);
      window.open(`${URL_FILES}/${file}`, '_blank');
    }
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={`${id}-download`}>Descargar PDF</Tooltip>}>
        <Button onClick={onClickDownloadPdf} className='btn btn-outline-primary'>
          <FileDownIcon />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ textAlign: 'center' }}>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Con códigos</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='codigos' onChange={onChange} value={false} checked={!codigos} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='codigos' onChange={onChange} value={true} checked={codigos} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Desglose de kits</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='desgloseKits' onChange={onChange} value={false} checked={!desgloseKits} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='desgloseKits' onChange={onChange} value={true} checked={desgloseKits} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Con horas de servicio</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='horasServicio' onChange={onChange} value={false} checked={!horasServicio} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='horasServicio' onChange={onChange} value={true} checked={horasServicio} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Partidas concentradas</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='partidasConcentradas' onChange={onChange} value={false} checked={!partidasConcentradas} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='partidasConcentradas' onChange={onChange} value={true} checked={partidasConcentradas} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleAceptar} variant='primary' className='pull-right mr-5' type='button'>
            Imprimir
          </Button>
          <Button variant='warning' className='pull-right mr-5' onClick={handleCopiar} disabled={isLoading}>
            Url para firma
          </Button>
          <Button variant='success' className='pull-right mr-5' onClick={handleExcel} disabled={isLoading}>
            Excel
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className='pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ImprimirLevantamiento.propTypes = {
  id: PropTypes.string.isRequired,

  levantamientoId: PropTypes.string.isRequired,
  numero_levantamiento: PropTypes.string.isRequired,
};

export default ImprimirLevantamiento;
