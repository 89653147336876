import { DivFormGroup, Label, TextArea } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Icon, RowsNotFound, Saving, SubModule } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isMongoId } from 'validator';
import SellerSelected from './SellerSelected';
import { useStoreNotification } from '@stores/catalogs.store';
import { Button, Col, Row } from 'react-bootstrap';

const SurtirCarro = () => {
  const { addNotification: notification } = useStoreNotification();
  const [isLoading] = useState(false);
  const [storehouses, setStorehouses] = useState([]);
  const [storehouseId, setStorehouseId] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [nota, setNota] = useState('');
  const [configurations, setConfigurations] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellerSelected, setSellerSelected] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [configurationId, setConfigurationId] = useState('');
  const [recipeId, setRecipeId] = useState('');
  const [equipmentId, setEquipmentId] = useState('');
  const [list, setList] = useState([]);
  const frmAdd = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { configurations, sellers, storehouses },
      } = await getRequest({ url: `surtidocarros-catalogs` });
      setConfigurations(configurations);
      setSellers(sellers);
      setStorehouses(storehouses);
    };
    fetchData();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'storehouseId') {
      setStorehouseId(value);
      setConfigurationId('');
      setRecipeId('');
      setRecipes([]);
      setEquipments([]);
      setList([]);
    } else {
      if (name === 'nota') setNota(value);
    }
  };

  const onChangeSeller = async (event) => {
    const value = event.target.value;
    if (isMongoId(value)) {
      const { data } = await getRequest({ url: `sellers/${value}` });
      setSellerId(value);
      setSellerSelected(data);
    } else {
      setSellerId('');
      setSellerSelected(null);
    }
  };

  const onChangeConfiguration = async (event) => {
    const value = event.target.value;
    if (isMongoId(storehouseId) && isMongoId(sellerId)) {
      if (isMongoId(value)) {
        setConfigurationId(value);
        setRecipeId('');
        setEquipmentId('');
        const { data } = await postRequest({ url: `surtidocarros/recipes`, body: { configurationId: value, storehouseId } });
        setRecipes(data);
        setEquipments([]);
      } else {
        setConfigurationId('');
        setRecipes([]);
        setRecipeId('');
        setEquipments([]);
        setEquipmentId('');
      }
    } else {
      notification({
        title: 'Aviso',
        message: 'Debes seleccionar un Almacén y Vendedor para seleccionar equipos',
        type: 'warning',
      });
      event.target.value = '';
      setConfigurationId('');
      setRecipes([]);
      setRecipeId('');
      setEquipments([]);
      setEquipmentId('');
    }
  };

  const onChangeRecipe = async (event) => {
    const value = event.target.value;
    if (isMongoId(value)) {
      setRecipeId(value);
      setEquipmentId('');
      const { data } = await postRequest({ url: `surtidocarros/equipos/recipes`, body: { recipeId: value, configurationId, storehouseId } });
      setEquipments(data);
    } else {
      event.target.value = '';
      setRecipeId('');
      setEquipments([]);
      setEquipmentId('');
    }
  };

  const handleSelectEquipment = () => {
    if (isMongoId(configurationId) && isMongoId(recipeId) && isMongoId(equipmentId)) {
      const onList = list.filter((l) => l.equipmentId.toString() === equipmentId.toString());
      if (onList.length === 0) {
        const configurationAdd = configurations.find((e) => e._id === configurationId);
        const recipeAdd = recipes.find((e) => e._id === recipeId);
        const equipmentAdd = equipments.find((e) => e._id === equipmentId);
        const inventory = equipmentAdd.inventory.find((i) => i.storehouseId.toString() === storehouseId.toString());
        setList([
          ...list,
          {
            configurationId,
            configuration: configurationAdd.name,
            recipeId,
            recipe: recipeAdd.recipe,
            characteristicsId: equipmentAdd.equipmentsCharacteristicsId,
            equipmentId,
            code: equipmentAdd.code,
            name: equipmentAdd.name,
            inventory,
            cantidad: 0,
          },
        ]);
      } else {
        notification({
          title: 'Aviso',
          message: 'El equipo seleccionado ya se encuentra agregado al listado, favor de verificar',
          type: 'warning',
        });
      }
    } else {
      notification({
        title: 'Aviso',
        message: 'Para agregar equipos debes seleccionar los filtros previos',
        type: 'warning',
      });
    }
  };

  const handleRemoveItem = (index) => {
    const newList = [...list];
    newList[index].cantidad = 0;
    newList.splice(index, 1);
    setList(newList);
  };

  const onChangeAmounts = (event) => {
    const { name, value } = event.target;
    if (!isNaN(parseInt(value))) {
      const newList = list.map((l) => {
        if (l.equipmentId.toString() === name.toString()) {
          l.cantidad = parseInt(value);
        }
        return l;
      });
      setList(newList);
    }
  };

  const renderEquipmentsSelected = () => {
    let rows = <RowsNotFound message='No se han seleccionado equipos para surtir al vendedor' colSpan={5} />;
    if (list.length > 0) {
      rows = list.map((r, i) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>
            <h4>
              {r.code} {r.name}
            </h4>
            <h4 style={{ marginTop: '-10px' }}>
              <small>
                {r.configuration}, {r.recipe}
              </small>
            </h4>
          </td>
          <td>
            <span className='label label-success'>{r.inventory.stock} en stock</span>
            <br />
            <span className='label label-danger'>min {r.inventory.min}</span>
            <span className='label label-primary'>max {r.inventory.max}</span>
          </td>
          <td>
            <input
              id={r.equipmentId}
              name={r.equipmentId}
              className='form-control text-center'
              type='number'
              min={1}
              max={9999}
              value={r.cantidad}
              onChange={onChangeAmounts}
            />
          </td>
          <td>
            <Button onClick={() => handleRemoveItem(i)} type='button' variant='danger' className='pull-right'>
              <Icon icon='remove' />
            </Button>
          </td>
        </tr>
      ));
    }
    return (
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th width='5%'></th>
            <th width='60%'>Equipo</th>
            <th width='20%'>Almacen</th>
            <th width='10%' className='text-center'>
              Cantidad
            </th>
            <th width='5%'></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const lista = list.map((l) => ({
      configuracionId: l.configurationId,
      recetaId: l.recipeId,
      caracteristicasId: l.characteristicsId,
      equipoId: l.equipmentId,
      cantidad: parseInt(l.cantidad),
      inventory: l.inventory,
    }));
    const data = { storehouseId, sellerId, nota, lista };
    if (validations(data)) {
      await postRequest({ url: `surtidocarros`, body: data });
      resetView();
    }
  };

  const validations = (params) => {
    let validate = { success: true, message: '' };
    if (!isMongoId(params.storehouseId)) {
      validate.success = false;
      validate.message = 'Almacén es requerido.';
    } else if (validate.success && !isMongoId(params.sellerId)) {
      validate.success = false;
      validate.message = 'Vendedor es requerido.';
    } else if (validate.success && params.lista.length <= 0) {
      validate.success = false;
      validate.message = 'Debe surtir el carro con todo el equipo necesario.';
    }
    const amounts = params.lista.filter((l) => l.cantidad <= 0);
    if (validate.success && amounts.length > 0) {
      validate.success = false;
      validate.message = 'Debe especificar las cantidades de los equipos en el detalle.';
    }
    params.lista.forEach((l, i) => {
      if (validate.success) {
        const stock = l.inventory.stock;
        const min = l.inventory.min;
        const rest = stock - l.cantidad;
        if (l.cantidad > l.inventory.stock) {
          validate.success = false;
          validate.message = `No hay suficiente stock del equipo ${i + 1}.`;
        }
        if (rest < min) {
          validate.success = false;
          validate.message = `La cantidad deseada del equipo ${i + 1} excede del permitido.`;
        }
      }
    });
    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const resetView = () => {
    frmAdd.current.reset();
    setStorehouseId('');
    setSellerId('');
    setNota('');
    setRecipes([]);
    setEquipments([]);
    setConfigurationId('');
    setRecipeId('');
    setEquipmentId('');
    setList([]);
  };

  return (
    <SubModule onClickBack={window.history.back} title='Surtir Carro'>
      <form ref={frmAdd} onSubmit={handleRegister}>
        <Row>
          <Col xs={12} md={6}>
            <DivFormGroup>
              <Label name='storehouseId' title='Almacén' />
              <select name='storehouseId' id='storehouseId' className='form-control' value={storehouseId} onChange={onChange}>
                <option value=''>Seleccione</option>
                {storehouses.map((t, i) => (
                  <option key={i} value={t._id}>
                    {t.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
            <DivFormGroup>
              <Label name='sellerId' title='Vendedor' />
              <select name='sellerId' id='sellerId' className='form-control' value={sellerId} onChange={onChangeSeller}>
                <option value=''>Seleccione</option>
                {sellers.map((t, i) => (
                  <option key={i} value={t._id}>
                    {t.name} {t.fatherLastName} {t.motherLastName}
                  </option>
                ))}
              </select>
            </DivFormGroup>
            {sellerSelected !== null ? <SellerSelected sellerSelected={sellerSelected} /> : null}
          </Col>
          <Col xs={12} md={6}>
            <DivFormGroup>
              <Label name='nota' title='Nota' />
              <TextArea name='nota' onChange={onChange} value={nota} rows={4} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <legend>
            <Icon icon='wrench' /> Equipo
          </legend>
          <Col xs={3}>
            <DivFormGroup>
              <Label name='configurationId' title='Configuración' />
              <select name='configurationId' id='configurationId' className='form-control' value={configurationId} onChange={onChangeConfiguration}>
                <option value=''>Seleccione</option>
                {configurations.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col xs={3}>
            <DivFormGroup>
              <Label name='recipeId' title='Tipo' />
              <select name='recipeId' id='recipeId' className='form-control' value={recipeId} onChange={onChangeRecipe}>
                <option value=''>Seleccione</option>
                {recipes.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.recipe}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col xs={5}>
            <DivFormGroup>
              <Label name='equipmentId' title='Equipos' />
              <select name='equipmentId' id='equipmentId' className='form-control' value={equipmentId} onChange={onChange}>
                <option value=''>Seleccione</option>
                {equipments.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.code}: {r.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col xs={1}>
            <Button type='button' onClick={handleSelectEquipment} className='btn btn-sm btn-success pull-right' style={{ marginTop: '25px' }}>
              <Icon icon='plus' /> Agregar
            </Button>
          </Col>
        </Row>
        <Row>
          <legend>
            <Icon icon='cog' /> Detalle
          </legend>
          <Col xs={10}>{renderEquipmentsSelected()}</Col>
        </Row>
        <div className='modal-footer'>
          <Saving saving={isLoading} />
          <Button type='submit' variant='primary' className='btn-lg pull-right' disabled={isLoading}>
            <Icon icon='plus-sign' /> Registrar
          </Button>
        </div>
      </form>
    </SubModule>
  );
};

SurtirCarro.propTypes = {};

export default SurtirCarro;
