import { IfPermission } from '@controls';
import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Col, DropdownButton, DropdownItem, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import { CbPagination } from '@controls';
import TableEquipments from './TableEquipments';
import IconDropdown from '@controls/IconDropdown';
import ItemEquipo from './components/ItemEquipo';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';
import SelectClasificacionCotizacion from '@components/forms/SelectClasificacionCotizacion';
import SelectMarca from '@components/forms/SelectMarca';
import Select from 'react-select';

const statusEquipos = {
  ACTIVO: 'ACTIVO',
  INACTIVO: 'INACTIVO',
  ELIMINADO: 'ELIMINADO',
  BAJA: 'BAJA',
  CONVERTIDO: 'CONVERTIDO',
};

const Equipments = () => {
  const [listEquipments, setListEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [inList, setInList] = useState(true);
  const [estatus] = useState([
    ...Object.entries(statusEquipos).map(([key, value]) => ({
      value: key,
      label: value,
    })),
  ]);
  const [status, setStatus] = useState(null);
  const [tipoLevantamiento, setTipoLevantamiento] = useState(null);
  const [clasificacionCotizacion, setClasificacionCotizacion] = useState(null);
  const [marca, setMarca] = useState(null);
  useEffect(() => {
    handleSearch();
  }, [activePage, status, search, tipoLevantamiento, clasificacionCotizacion, marca]);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      status: status ? status.value : null,
      tipoLevantamientoId: tipoLevantamiento ? tipoLevantamiento._id : null,
      clasificacionCotizacionId: clasificacionCotizacion ? clasificacionCotizacion._id : null,
      marcaId: marca ? marca.value : null,
    };
    const { data, count } = await postRequest({ url: `equipments/search`, params: { page, limit: pagination }, body });
    setListEquipments(data);
    setCount(count);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const handleExcelEncabezadoPrecios = async () => {
    const { file } = await postRequest({ url: `equipments/exportar-precios` });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const handleExcelEncabezadoPreciosWorkforce = async () => {
    const { file } = await postRequest({ url: `equipments/exportar-precios-mano-obra` });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const handleExcelEncabezado = async () => {
    const { file } = await postRequest({ url: `equipments/exportar` });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const renderList = () => {
    if (!inList)
      return (
        <Row>
          {listEquipments.map((equipment) => (
            <Col xs='6' sm='3' md='3' key={equipment._id}>
              <ItemEquipo equipment={equipment} handleSearch={handleSearch} />
            </Col>
          ))}
        </Row>
      );
    return <TableEquipments {...{ listEquipments, loading, activePage, count, pagination }} loadEquipments={handleSearch} />;
  };

  const handleChangeList = () => {
    setInList(!inList);
  };

  const moduleList = renderList();

  return (
    <Module title='Equipos'>
      <Search onSearch={onSearch} blockOne='col-sm-12' blockSecond='col-sm-12' searchOnStopType>
        <Row>
          <Col sm='2'>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tags' title='Estatus' />
              </InputGroup.Text>
              <Select name='status' options={estatus} value={status} onChange={(value) => setStatus(value)} placeholder='Seleccione' isClearable />
            </InputGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Tipo levantamiento' />
                </InputGroup.Text>
                <SelectTipoLevantamiento name='tipoLevantamiento' value={tipoLevantamiento} onChange={(value) => setTipoLevantamiento(value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Clasificacion cotización' />
                </InputGroup.Text>
                <SelectClasificacionCotizacion
                  name='clasificacionCotizacion'
                  value={clasificacionCotizacion}
                  onChange={(value) => setClasificacionCotizacion(value)}
                />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Estatus' />
                </InputGroup.Text>
                <SelectMarca name='marca' value={marca} onChange={(value) => setMarca(value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <ButtonGroup className='pull-right'>
              <IfPermission action='crear'>
                <Link to='/equipos/nuevo' className='btn btn-success'>
                  <Icon icon='plus' />
                </Link>
              </IfPermission>
              <Button onClick={handleChangeList} className='btn btn-success' type='button'>
                <Icon icon={!inList ? 'th-list' : 'th-large'} />
              </Button>
              <DropdownButton title={<IconDropdown />} as={ButtonGroup}>
                <DropdownItem href={`/equipos/importar`}>
                  <Icon icon='zoom-in' /> Importar
                </DropdownItem>
                <DropdownItem onClick={handleExcelEncabezado}>
                  <Icon icon='save-file' /> Exportar
                </DropdownItem>
                <DropdownItem onClick={handleExcelEncabezadoPrecios}>
                  <Icon icon='save-file' /> Exportar precios
                </DropdownItem>
                <DropdownItem onClick={handleExcelEncabezadoPreciosWorkforce}>
                  <Icon icon='save-file' /> Exportar precios con suministros
                </DropdownItem>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        {moduleList}
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Equipments.propTypes = {};

export default Equipments;
