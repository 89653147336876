import React, { useEffect } from 'react';
import Select from 'react-select';
import { getRequest } from '@utils/api';
import PropTypes from 'prop-types';

const SelectCuentaMonitoreoCliente = ({ onChange, value, customerId }) => {
  const [data, setData] = React.useState([]);
  const fetchData = async () => {
    if (!customerId) {
      setData([]);
      return;
    }
    const { data: cuentas } = await getRequest({ url: `cuentas-monitoreos/customer/${customerId}` });
    setData(cuentas);
  }

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const onSelect = (selected) => {
    onChange(selected);
  };

  return (
    <Select
      value={value}
      onChange={onSelect}
      options={data}
      getOptionLabel={(x) => `${x.numero} ${x.nombre}`}
      getOptionValue={(x) => x._id}
      isClearable
      placeholder={'Buscar por numero o nombre'}
    />
  );
};


SelectCuentaMonitoreoCliente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  customerId: PropTypes.string.isRequired,
};

export default SelectCuentaMonitoreoCliente;
