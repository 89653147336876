import { DivFormGroup, FormInput, Input } from '@controls';
import React, { useState, Fragment, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification, useStoreRegimenes, useStoreRegimenesFiscales, useStoreUsosCfdis } from '@stores/catalogs.store';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import ContactoEdit from '@components/customers/ContactoEdit';
import Select from 'react-select';
import SelectRegimenFiscal from '@components/forms/SelectRegimenFiscal';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
const INIT_DOMICILIO = {
  editando: true,
  estado: '',
  municipio: '',
  colonia: '',
  localidad: '',
  paisObject: null,
  estadoObject: null,
  municipioObject: null,
  coloniaObject: null,
  codigo_postal: '',
};

const INIT_CONTACTO = {
  nombre: '',
  paterno: '',
  materno: '',
  correo: '',
  telefono: '',
  tipos: [],
};

const Nuevo = () => {
  const { addNotification: notification } = useStoreNotification();
  const { data: regimenes_fiscales } = useStoreRegimenesFiscales();
  const { data: usos_cfdi } = useStoreUsosCfdis();
  const { data: regimenes } = useStoreRegimenes();
  const [showModal, setShowModal] = useState(false);
  const [numeroCliente, setNumeroCliente] = useState('');
  const [regimen, setRegimen] = useState(null);
  const [rfc, setRfc] = useState('');
  const [nombre, setNombre] = useState('');
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [razon_social, setRazonSocial] = useState('');
  const [nombre_comercial, setNombreComercial] = useState('');
  const [listContactos, setListContactos] = useState([]);
  const [listDomicilios, setListDomicilios] = useState([]);
  const [regimen_fiscal, setRegimenFiscal] = useState(null);
  const [uso_cfdi, setUsoCfdi] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const close = () => {
    setShowModal(false);
    navigate('/clientes');
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers/siguienteNumero` });
    setNumeroCliente(data.numero_cliente);
    setRegimen(regimenes.find((x) => x.value === 'I'));
    setRfc('XAXX010101000');
    setRegimenFiscal(null);
    setUsoCfdi(null);
    setRazonSocial('');
    setNombreComercial('');
    setNombre('');
    setPaterno('');
    setMaterno('');
    setShowModal(true);
    setListContactos([{ ...INIT_CONTACTO }]);
    setListDomicilios([{ ...INIT_DOMICILIO, es_fiscal: true }]);
  };

  useEffect(() => {
    if(showModal) {
      if (regimenes_fiscales) {
        setRegimenFiscal(regimenes_fiscales.find((x) => x.codigo === '616'));
      }
      if (usos_cfdi) {
        setUsoCfdi(usos_cfdi.find((x) => x.codigo === 'G03'));
      }
    }
  }, [regimenes_fiscales, usos_cfdi, showModal]);

  useEffect(() => {
    if (location.pathname === '/clientes/nuevo') {
      open();
    }
  }, [location]);

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };

    if (isEmpty(params.rfc)) {
      validate.message = 'RFC es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.nombre)) {
      validate.message = 'Nombre es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.paterno)) {
      validate.message = 'Apellido paterno es requerido.';
    } else if (
      params.listDomicilios.filter(
        (domicilio) =>
          isEmpty(domicilio.calle + '') ||
          !domicilio.paisId ||
          !domicilio.estadoId ||
          !domicilio.municipioId ||
          !domicilio.coloniaId ||
          isEmpty(domicilio.codigo_postal + '')
      ).length > 0
    ) {
      validate.message = 'Los domicilios requieren calle, pais, estado, municipio, colonia y código postal.';
    } else if (
      params.listContactos.filter(
        (contacto) => contacto.tipos.length === 0 || isEmpty(contacto.name +'') || (isEmpty(contacto.email+'') && (isEmpty(contacto.phone+'') && isEmpty(contacto.mobile+'')))
      ).length > 0
    ) {
      validate.message = 'Los contactos requieren tipo contacto, nombre y correo electronico.';
    } else {
      validate.success = true;
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();
    const body = {
      numero_cliente: numeroCliente,
      regimen: regimen?.value,
      regimen_fiscal: regimen_fiscal?._id,
      uso_cfdi: uso_cfdi?._id,
      rfc: rfc.toString().trim().toUpperCase(),
      nombre: nombre.toString().trim().toUpperCase(),
      paterno: paterno.toString().trim().toUpperCase(),
      materno: materno.toString().trim().toUpperCase(),
      razon_social: razon_social.toString().trim().toUpperCase(),
      nombre_comercial: nombre_comercial.toString().trim().toUpperCase(),
      nacionalidad: '',
      extranjero: false,
      estado_civil: '',
      sexo: '',
      fecha_nacimiento: '',
      listContactos: listContactos,
      listDomicilios: listDomicilios.map((domicilio) => ({
        calle: domicilio.calle || '',
        exterior: domicilio.exterior || '',
        interior: domicilio.interior || '',
        calle1: domicilio.calle1 || '',
        calle2: domicilio.calle2 || '',
        coloniaId: domicilio.coloniaObject?._id,
        municipioId: domicilio.municipioObject?._id,
        estadoId: domicilio.estadoObject?._id,
        paisId: domicilio.paisObject?._id,
        codigo_postal: domicilio.codigo_postal || '',
        localidad: domicilio.localidad || '',
        referencias: domicilio.referencias || '',
        nombre: domicilio.nombre || '',
        monitoreo: domicilio.monitoreo || '',
        es_fiscal: domicilio.es_fiscal,
      })),
    };

    if (validations(body)) {
      const { data } = await postRequest({ url: `customers/validar-duplicidad`, body });
      let confirmar = true;
      if (!data?.success)
        confirmar = await verificarCreacion(data.message);
      if (confirmar) {
        await postRequest({ url: `customers/nuevo`, body });
        close();
        navigate('/clientes');
      }
    }
  };

  const verificarCreacion = async (mensaje) => {
      const res = await Swal.fire({
        html: `<div style="font-size: 1.5em!important;">Confirmación</div><span>${mensaje} ¿Quieres proceder a crearlo?</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#25b003',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, confirmo',
        cancelButtonText: 'No',
      });
      return res.isConfirmed;
    };

  const onChangeRegimen = (value) => {
    if (value?.value === 'I') {
      setRfc('XAXX010101000');
    } else if (value?.value === 'E') {
      setRfc('XEXX010101000');
    }
    setRegimen(value);
  };

  const onChangeContacto = (index, contacto) => {
    setListContactos(listContactos.map((cnt, i) => (i === index ? { ...contacto } : cnt)));
  };

  const onChangeDomicilio = (index, dom) => {
    setListDomicilios(listDomicilios.map((domicilio, i) => (i === index ? { ...dom } : domicilio)));
  };

  const handleOpen = () => {
    navigate('/clientes/nuevo');
  }

  return (
    <>
      <CbButtonOver onClick={handleOpen} icon='plus' title='Nuevo' type='success'></CbButtonOver>
      <CbModal show={showModal} onClose={close} onSave={handleRegistrar} title='Nuevo cliente' size='xl'>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='3'>
                <DivFormGroup name='regimen' required title={'Régimen'}>
                  <Select
                    value={regimen}
                    name='regimen'
                    placeholder='Seleccione'
                    options={regimenes}
                    onChange={onChangeRegimen}
                    required
                    getOptionLabel={(x) => x.label}
                    getOptionValue={(x) => x.value}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='regimen_fiscal' title='Régimen Fiscal'>
                  <SelectRegimenFiscal
                    value={regimen_fiscal}
                    name='regimen_fiscal'
                    onChange={(value) => setRegimenFiscal(value)}
                    filter={(x) => x.regimenes.includes(regimen?.value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput name='numero_cliente' title='Número de cliente' onChange={(e) => setNumeroCliente(e.target.value)} value={numeroCliente} />
              </Col>
              <Col sm='3'>
                <DivFormGroup name='clienteName' required title={'RFC'}>
                  <Input
                    name='rfc'
                    title='RFC'
                    onChange={(e) => setRfc(e.target.value)}
                    value={rfc}
                    maxLength={13}
                    required
                    disabled={regimen?.value === 'I' || regimen?.value === 'E'}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              {regimen?.value === 'F' ? (
                <Fragment>
                  <Col sm='4'>
                    <FormInput name='nombre' title='Nombre(s)' onChange={(e) => setNombre(e.target.value)} value={nombre} required />
                  </Col>
                  <Col sm='4'>
                    <FormInput name='paterno' title='Apellido Paterno' onChange={(e) => setPaterno(e.target.value)} value={paterno} required />
                  </Col>
                  <Col sm='4'>
                    <FormInput name='materno' title='Apellido Materno' onChange={(e) => setMaterno(e.target.value)} value={materno} />
                  </Col>
                </Fragment>
              ) : (
                <Fragment>
                  <Col sm='12'>
                    <FormInput
                      name='razon_social'
                      title='Razón Social'
                      onChange={(e) => setRazonSocial(e.target.value)}
                      value={razon_social}
                      required
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
            <Row>
              <Col sm='6'>
                <FormInput
                  name='nombre_comercial'
                  title='Nombre comercial'
                  onChange={(e) => setNombreComercial(e.target.value)}
                  value={nombre_comercial}
                  required
                />
              </Col>
              <Col sm='6'>
                <DivFormGroup name='uso_cfdi' required title={'Uso CFDI'}>
                  <SelectUsoCFDI value={uso_cfdi} onChange={(value) => setUsoCfdi(value)} required />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className='mt-3'>
          <Card.Body>
            <Card.Title>Domicilio</Card.Title>
            <Row>
              <Col xs={12}>
                <DomicilioEdit domicilio={listDomicilios[0]} onChange={(dom)=>onChangeDomicilio(0,dom)} />
              </Col>  
            </Row>
          </Card.Body>
        </Card>
        <Card className='mt-3'>
          <Card.Body>
            <Card.Title>Contacto</Card.Title>
            <Row>
              <Col xs={12}>
                <ContactoEdit contacto={listContactos[0]} onChangeContacto={(con)=>onChangeContacto(0,con)} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </CbModal>
    </>
  );
};


export default Nuevo;
