import React from 'react';
import { ListGroup, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import RowProveedor from './RowProveedor';
import Required from '@controls/Required';
import { Icon } from '@stateless';

const TableProveedores = ({ proveedores, onChangeProveedores }) => {
  const onChange = (idx, proveedor) => {
    proveedores[idx] = proveedor;
    onChangeProveedores([...proveedores]);
  };

  const onRemove = (idx) => {
    proveedores.splice(idx, 1);
    onChangeProveedores([...proveedores]);
  };

  const onAdd = (e) => {
    e.preventDefault();
    proveedores.push({
      proveedorId: null,
      codigo: '',
      codigo_barras: '',
      date_last_cost: null,
      ultimoCosto: 0,
    });
    onChangeProveedores([...proveedores]);
  };

  return (
    <ListGroup className='mb-2 mt-2'>
      <ListGroup.Item>
        <Card.Title>
          Proveedores <Required />
        </Card.Title>
      </ListGroup.Item>
      {proveedores.map((proveedor, i) => (
        <RowProveedor  key={i} proveedor={proveedor} onChangeProveedor={(prv) => onChange(i, prv)} onRemoveItemProveedor={() => onRemove(i)} />
      ))}
      <ListGroup.Item action onClick={onAdd} className='btn-block btn-lg text-center' style={{ cursor: 'pointer', fontSize: '1.2em' }}>
        <Icon icon='plus' /> Agregar proveedor
      </ListGroup.Item>
    </ListGroup>
  );
};

TableProveedores.propTypes = {
  proveedores: PropTypes.array,
  onChangeProveedores: PropTypes.func,
};

export default TableProveedores;
