import { DivFormGroup, Input } from '@controls';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import { Module, Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { FileUpIcon } from 'lucide-react';

const Import = () => {
  const [state, setState] = useState({
    regimen: 'F',
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
    rfcReceptor: '',
    fechaInicial: moment().add(-1, 'month').format('YYYY-MM-DD'),
    horaInicial: moment().add(-1, 'month').format('HH:mm'),
    fechaFinal: moment().format('YYYY-MM-DD'),
    horaFinal: moment().format('HH:mm'),
    isCompleted: false,
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    }));

    for (let i = 1; i <= 100; i++) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, progress: i }));
      }, 100);
    }

    setTimeout(async () => {
      const body = {
        fechaInicialBusqueda: state.fechaInicial + ' ' + state.horaInicial,
        fechaFinalBusqueda: state.fechaFinal + ' ' + state.horaFinal,
        rfcReceptorBusqueda: state.rfcReceptor,
      };
      await postRequest({ url: 'migrar/facturasSat', body });
      setState((prevState) => ({ ...prevState, cargando: false, progress: 0 }));
    }, 100);
  };

  const { cargando, isLoading, progress, fechaInicial, horaInicial, fechaFinal, horaFinal, rfcReceptor } = state;

  return (
    <Module onClickBack={window.history.back} parent='Notas de credito' title='Obtener notas de credito'>
      <br />
      <form onSubmit={handleReadCSV} encType='multipart/form-data'>
        <Row>
          <Col sm='4'>
            <Row>
              <DivFormGroup name='fechaInicial' title='Fecha inicial'>
                <input className='form-control' name='fechaInicial' id='fechaInicial' type='date' defaultValue={fechaInicial} onChange={onChange} />
              </DivFormGroup>
              <DivFormGroup name='horaInicial' title='Hora'>
                <input className='form-control' name='horaInicial' id='horaInicial' type='time' defaultValue={horaInicial} onChange={onChange} />
              </DivFormGroup>
            </Row>
          </Col>
          <Col sm='4'>
            <Row>
              <DivFormGroup name='fechaFinal' title='Fecha final'>
                <input className='form-control' name='fechaFinal' id='fechaFinal' type='date' defaultValue={fechaFinal} onChange={onChange} />
              </DivFormGroup>
              <DivFormGroup name='horaFinal' title='Hora'>
                <input className='form-control' name='horaFinal' id='horaFinal' type='time' defaultValue={horaFinal} onChange={onChange} />
              </DivFormGroup>
            </Row>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='rfc' title='RFC Cliente(Opcional)'>
              <Input name='name' onChange={onChange} value={rfcReceptor} />
            </DivFormGroup>
          </Col>
          <Col sm='9'>
            <Button type='submit' className='btn btn-success' disabled={cargando}>
              <FileUpIcon /> Buscar XMLS
            </Button>
            {isLoading ? <Saving action='Importantando' /> : ''}
          </Col>
          <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
        </Row>
      </form>
    </Module>
  );
};

Import.propTypes = {};

export default Import;
