import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditUnitMeasures = ({ idUnitMeasures, loadUnitMeasures }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [measure, setMeasure] = useState('');
  const [codigo, setCodigo] = useState('');
  const [codigoSat, setCodigoSat] = useState('');
  const [note, setNote] = useState('');
  const [tipo, setTipo] = useState('EQUIPO');
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = async () => {
    const {
      data: { measure, note, tipo, codigo, codigo_sat },
    } = await getRequest({ url: `measures/${idUnitMeasures}` });
    setShowModal(true);
    setMeasure(measure);
    setCodigo(codigo || '');
    setCodigoSat(codigo_sat || '');
    setNote(note);
    setTipo(tipo);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      measure: measure.trim(),
      note: note.trim(),
      codigo: codigo.trim(),
      codigo_sat: codigoSat.trim(),
      tipo,
    };

    if (validations(data)) {
      await putRequest({ url: `measures/${idUnitMeasures}`, body: data });
      setShowModal(false);
      loadUnitMeasures();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.codigo_sat + '')) {
      validate.success = false;
      validate.message = 'Código SAT es requerido.';
    } else if (isEmpty(params.measure + '')) {
      validate.success = false;
      validate.message = 'Unidad es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='codigo' title='Código' required>
              <Input name='codigo' onChange={(e) => setCodigo(e.target.value)} value={codigo} placeholder='Código' />
            </DivFormGroup>
            <DivFormGroup name='codigo_sat' title='Código SAT' required>
              <Input name='codigo_sat' onChange={(e) => setCodigoSat(e.target.value)} value={codigoSat} placeholder='Código SAT' />
            </DivFormGroup>
            <DivFormGroup name='measure' title='Unidad' required>
              <Input name='measure' onChange={(e) => setMeasure(e.target.value)} value={measure} placeholder='Unidad' />
            </DivFormGroup>
            <FormGroup className='inline'>
              <label>Tipo de Medida</label>
              <div>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    name='tipo'
                    id='rdo-equipo'
                    onChange={(e) => setTipo(e.target.value)}
                    value={'EQUIPO'}
                    checked={tipo === 'EQUIPO'}
                  />{' '}
                  Equipo
                </label>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    name='tipo'
                    id='rdo-servicio'
                    onChange={(e) => setTipo(e.target.value)}
                    value={'SERVICIO'}
                    checked={tipo === 'SERVICIO'}
                  />{' '}
                  Servicio
                </label>
              </div>
            </FormGroup>
            <DivFormGroup name='note' title='Nota'>
              <TextArea name='note' onChange={(e) => setNote(e.target.value)} value={note} rows={3} placeholder='Nota' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditUnitMeasures.propTypes = {
  idUnitMeasures: PropTypes.string.isRequired,
  loadUnitMeasures: PropTypes.func.isRequired,
};

export default EditUnitMeasures;
