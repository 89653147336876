import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import { CbPagination } from '@controls';
import ListAgentes from './ListAgentes';
import NewAgentes from './NewAgentes';
import { Button, ButtonGroup, Col, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@components/application/controls/Stateless';
import { FileDownIcon } from 'lucide-react';
import usePagination from '@hooks/usePagination';
import { fetchAll } from '@services/agentes.service';

const Agentes = () => {
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(null);

  const { datos, paginaActual, count, cargando, cambiarPagina, setParams, refresh } = usePagination(fetchAll, pagination, 'numero');


  useEffect(() => {
    setParams({
      search,
      status: status?.value,
    });
  }, [search, status]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
  };

  const onClickExportar = () => {
    postRequest({ url: `agentes/exportar` }).then(({ file }) => {
      window.open(URL_FILES + 'excel/' + file, '_blank');
    });
  };

  return (
    <Module title='Agentes'>
      <Search onSearch={onSearch} searchOnStopType blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Row>
          <Col sm={8}>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tag' />
              </InputGroup.Text>
              <Select
                name='status'
                placeholder='Seleccione una sucursal'
                options={[
                  { value: 'ACTIVO', label: 'Activo' },
                  { value: 'BAJA', label: 'Baja' },
                  { value: 'ELIMINADO', label: 'Eliminado' },
                ]}
                isClearable
                onChange={(selectedOption) => {
                  setStatus(selectedOption);
                }}
              />
            </InputGroup>
          </Col>
          <Col sm={4}>
            <ButtonGroup>
              <Button onClick={onClickExportar} variant='outline-primary'>
                <FileDownIcon />
              </Button>
              <IfPermission action='crear'>
                <NewAgentes refresh={refresh} />
              </IfPermission>
            </ButtonGroup>
          </Col>
        </Row>
      </Search>
      <Loading loading={cargando}>
        <ListAgentes list={datos} refresh={refresh} />
        <div className='text-center'>
          <CbPagination activePage={paginaActual} count={count} pagination={pagination} onSelect={cambiarPagina} />
        </div>
      </Loading>
    </Module>
  );
};

Agentes.propTypes = {};

export default Agentes;
