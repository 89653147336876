import IfPermission from '@controls/IfPermission';
import { If } from '@controls/index';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, DropdownButton, DropdownItem } from 'react-bootstrap';
import { Icon } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import AltaAgentes from './AltaAgentes';
import BajaAgentes from './BajaAgentes';
import DeleteAgentes from './DeleteAgentes';
import EditAgentes from './EditAgentes';
import EditFirma from './EditFirma';
import IconDropdown from '@controls/IconDropdown';

const ListAgentes = ({ list, refresh }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const {
        _id,
        codigo,
        nombre,
        listCustomers,
        listProspectos,
        sucursalId,
        almacenId,
        maximo_descuento,
        firma,
        status,
        es_tecnico,
        recibe_servicios,
        recibe_pedidos,
      } = row;
      return (
        <tr key={item}>
          <td>
            <strong>{codigo}</strong>
          </td>
          <td>{nombre}</td>
          <td>{sucursalId ? sucursalId.name : ''}</td>
          <td>{listCustomers && listCustomers.length}</td>
          <td>{listProspectos && listProspectos.length}</td>
          <td>
            <CbBadge type={status === 'ACTIVO' ? 'primary' : 'secondary'}>{status}</CbBadge>
          </td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <EditAgentes
                  id={item}
                  agenteId={_id}
                  maximo_descuento={maximo_descuento}
                  sucursalId={sucursalId}
                  almacenId={almacenId}
                  nombre={nombre}
                  codigo={codigo}
                  es_tecnico={es_tecnico}
                  recibe_servicios={recibe_servicios}
                  recibe_pedidos={recibe_pedidos}
                  refresh={refresh}
                />
              </IfPermission>
              <If condition={status === 'ACTIVO'}>
                <If.Then>
                  <IfPermission action='baja'>
                    <BajaAgentes id={item} agenteId={_id} nombre={nombre} refresh={refresh} />
                  </IfPermission>
                </If.Then>
                <If.Else>
                  <IfPermission action='alta'>
                    <AltaAgentes id={item} agenteId={_id} nombre={nombre} refresh={refresh} />
                  </IfPermission>
                </If.Else>
              </If>
              <DropdownButton as={ButtonGroup} title={<IconDropdown />} variant='outline-primary'>
                <IfPermission action='eliminar'>
                  <If condition={status === 'ACTIVO'}>
                    <If.Then>
                      <DeleteAgentes id={item} agenteId={_id} nombre={nombre} refresh={refresh} />
                    </If.Then>
                  </If>
                </IfPermission>
                <IfPermission action='firma'>
                  <EditFirma
                    id={item}
                    agenteId={_id}
                    firma={firma}
                    maximo_descuento={maximo_descuento}
                    sucursalId={sucursalId ? sucursalId._id : ''}
                    almacenId={almacenId ? almacenId._id : ''}
                    nombre={nombre}
                    refresh={refresh}
                  />
                </IfPermission>
                <IfPermission action='clientes'>
                  <DropdownItem href={`/agentes/clientes/${_id}`}>
                    <Icon icon='share' /> Clientes asignados
                  </DropdownItem>
                </IfPermission>
                <IfPermission action='prospectos'>
                  <DropdownItem href={`/agentes/prospectos/${_id}`}>
                    <Icon icon='share' /> Prospectos asignados
                  </DropdownItem>
                </IfPermission>
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Codigo</th>
          <th width='30%'>Nombre</th>
          <th width='20%'>Sucursal</th>
          <th width='10%'>Clientes</th>
          <th width='10%'>Prospectos</th>
          <th width='10%'>Estatus</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListAgentes.propTypes = {
  list: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ListAgentes;
