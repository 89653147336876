import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '@hooks/useAuth';

export default function PrivateRoute() {
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <Navigate to={'/login'}></Navigate>;
  }
  return (
    <div className='content-wrapper'>
      <Outlet />
    </div>
  );
}
