import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BsButton from './BsButton';
import { Icon } from '@stateless';

const CbButtonOver = ({ id, type, onClick, title, icon, hiddenXs, size }) => {
  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <BsButton onClick={onClick} type={type} size={size}>
        <Icon icon={icon} /> {hiddenXs && <span className='hidden-xs'>{title}</span>}
      </BsButton>
    </OverlayTrigger>
  );
};

CbButtonOver.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hiddenXs: PropTypes.bool,
  size: PropTypes.string,
};

export default CbButtonOver;
