import { DivFormGroup, FormInput, Input } from '@controls';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest } from '@utils/api';
import RowReferencia from '../components/RowReferencia';
import SelectTipoEstablecimiento from '@components/forms/SelectTipoEstablecimiento';
import SelectRubro from '@components/forms/SelectRubro';
import SelectClasificacionCliente from '@components/forms/SelectClasificacionCliente';
import RowCredito from '../components/RowCredito';
import { useStoreNotification, useStoreRegimenes } from '@stores/catalogs.store';
import { useNavigate, useParams } from 'react-router';
import { validateUpdate } from '@validations/clientes';
import { putRequest } from '@utils/api';
import Select from 'react-select';
import SelectRegimenFiscal from '@components/forms/SelectRegimenFiscal';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';

const EditCustomer = () => {
  const { addNotification: notification } = useStoreNotification();
  const { data: regimenes } = useStoreRegimenes();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [isSaving] = useState(false);
  const [regimen, setRegimen] = useState(null);
  const [regimen_fiscal, setRegimenFiscal] = useState(null);
  const [rfc, setRfc] = useState('');
  const [razon_social, setRazonSocial] = useState('');
  const [nombre_comercial, setNombreComercial] = useState('');
  const [numero_cliente, setNumeroCliente] = useState('');
  const [name, setName] = useState('');
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [tipoEstablecimiento, setTipoEstablecimiento] = useState(null);
  const [rubro, setRubro] = useState(null);
  const [listCreditos, setListCreditos] = useState([]);
  const [listReferencias, setListReferencias] = useState([]);
  const [clasification, setClasification] = useState(null);
  const [comentarios, setComentarios] = useState('');
  const [uso_cfdi, setUsoCfdi] = useState(null);

  const loadData = useCallback(async () => {
    setLoading(true);
    const { data } = await getRequest({ url: `customer/${customerId}/detail` });
    // const tituloClienteId = isMongoId(data.tituloClienteId + '') ? data.tituloClienteId : '';
    setRazonSocial(data.razon_social);
    setRegimen(regimenes.find((x) => x.value === data.regimen));
    setRegimenFiscal(data.regimen_fiscal_id);
    setRfc(data.rfc || '');
    setName(data.name || '');
    setPaterno(data.paterno || '');
    setMaterno(data.materno || '');
    setNumeroCliente(data.numero_cliente || '');
    setNombreComercial(data.nombre_comercial || '');
    setEmail(data.email || '');
    setPhone(data.phone || '');
    setMobile(data.mobile || '');
    setTipoEstablecimiento(data.tipoEstablecimientoId || '');
    setRubro(data.rubroId || '');
    setListCreditos(data.listCreditos || []);
    setListReferencias(data.listReferencias || []);
    setClasification(data.clasification || null);
    setLoading(false);
    setListCreditos(data.listCreditos || []);
    setListReferencias(data.listReferencias || []);
    setComentarios(data.comentarios || '');    
    setUsoCfdi(data.uso_cfdi_id || null);
  }, [customerId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setRazonSocial(`${name || ''} ${paterno || ''} ${materno || ''}`.replace(/\s+/g, ' ').trim());
  }, [name, paterno, materno]);

  useEffect(() => {
    if (regimen === 'I') {
      setRfc('XAXX010101000');
    } else if (regimen === 'E') {
      setRfc('XEXX010101000');
    }
  }, [regimen]);

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      numero_cliente,
      regimen: regimen?.value,
      regimen_fiscal: regimen_fiscal?._id,
      uso_cfdi: uso_cfdi?._id,
      rfc: rfc.toString().trim().toUpperCase(),
      name: name.toString().trim().toUpperCase(),
      paterno: paterno.toString().trim().toUpperCase(),
      materno: materno.toString().trim().toUpperCase(),
      email,
      phone,
      mobile,
      comentarios,
      razon_social: razon_social.toString().trim().toUpperCase(),
      nombre_comercial: nombre_comercial.toString().trim().toUpperCase(),
      tipoEstablecimientoId: tipoEstablecimiento?._id,
      rubroId: rubro?._id,
      listCreditos: listCreditos.map((x) => ({ limite_credito: x.limite_credito, plazo: x.plazo })),
      listReferencias: listReferencias.map((x) => ({ bancoId: x.bancoId?._id, referencia: x.referencia })),
      clasification: clasification ? clasification._id : null,
    };
    if (validations(data)) {
      await putRequest({ url: `customer/${customerId}`, body: data });
      navigate('/clientes');
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const onChangeCredito = (idx, credito) => {
    const newCreditos = [...listCreditos];
    newCreditos[idx] = credito;
    setListCreditos(newCreditos);
  };

  const removeItemCredito = (idx) => {
    const newCreditos = listCreditos.filter((_, i) => i !== idx);
    setListCreditos(newCreditos);
  };

  const addItemCredito = (e) => {
    e.preventDefault();
    setListCreditos([...listCreditos, { limite_credito: 0, plazo: 0 }]);
  };

  const addItemReferencia = (e) => {
    e.preventDefault();
    setListReferencias([...listReferencias, { bancoId: null, referencia: '' }]);
  };

  const removeItemReferencia = (idx) => {
    const newReferencias = listReferencias.filter((_, i) => i !== idx);
    setListReferencias(newReferencias);
  };

  const onChangeReferencia = (idx, referencia) => {
    const newReferencias = [...listReferencias];
    newReferencias[idx] = referencia;
    setListReferencias(newReferencias);
  };

  const onChangeRegimen = (value) => {
    if (value?.value === 'I') {
      setRfc('XAXX010101000');
    } else if (value?.value === 'E') {
      setRfc('XEXX010101000');
    }
    setRegimen(value);
  }; 

  return (
    <Module onClickBack={window.history.back} parent='Clientes' title='Modificar cliente'>
      <Loading loading={loading}>
        <form>
          <Card className='mb-3'>
            <Card.Body>
              <Row>
                 <Col sm='3'>
                  <DivFormGroup name='regimen' required title={'Régimen'}>
                    <Select
                      value={regimen}
                      name='regimen'
                      placeholder='Seleccione'
                      options={regimenes}
                      onChange={onChangeRegimen}
                      required
                      getOptionLabel={(x) => x.label}
                      getOptionValue={(x) => x.value}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='regimen_fiscal' required title='Régimen Fiscal'>
                    <SelectRegimenFiscal
                      value={regimen_fiscal}
                      name='regimen_fiscal'
                      onChange={(value) => setRegimenFiscal(value)}
                      required
                      filter={(x) => x.regimenes.includes(regimen?.value)}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='rfc' title='RFC'>
                    <Input name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} maxLength={13} />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='numero_cliente' title='Número de cliente'>
                    <Input name='numero_cliente' onChange={(e) => setNumeroCliente(e.target.value)} value={numero_cliente} maxLength={13} />
                  </DivFormGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className='mb-3'>
            <Card.Body>
              <Row>
                <Col>
                  <FormInput
                    name='razon_social'
                    title='Razon social'
                    onChange={(e) => setRazonSocial(e.target.value)}
                    value={razon_social}
                    required
                    disabled={regimen == 'F'}
                  />
                </Col>
              </Row>
              {regimen == 'F' && (
                <>
                  <Row>
                    <Col sm='4'>
                      <FormInput name='name' title='Nombre(s)' onChange={(e) => setName(e.target.value)} value={name} required />
                    </Col>
                    <Col sm='4'>
                      <FormInput name='paterno' title='Apellido Paterno' onChange={(e) => setPaterno(e.target.value)} value={paterno} required />
                    </Col>
                    <Col sm='4'>
                      <FormInput name='materno' title='Apellido Materno' onChange={(e) => setMaterno(e.target.value)} value={materno} />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col sm='6'>
                  <FormInput
                    name='nombre_comercial'
                    title='Nombre Comercial'
                    onChange={(e) => setNombreComercial(e.target.value)}
                    value={nombre_comercial}
                  />
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='tipoEstablecimiento' title='Tipo de Establecimiento'>
                    <SelectTipoEstablecimiento
                      name='tipoEstablecimiento'
                      value={tipoEstablecimiento}
                      onChange={(value) => setTipoEstablecimiento(value)}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='rubro' title='Rubros'>
                    <SelectRubro
                      name='rubro'
                      value={rubro}
                      onChange={(value) => setRubro(value)}
                      disabled={!tipoEstablecimiento}
                      filter={(v) => v.tipoEstablecimientoId == tipoEstablecimiento?._id}
                    />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <FormInput type='email' name='email' title='Correo electrónico' onChange={(e) => setEmail(e.target.value)} value={email} />
                </Col>
                <Col sm='4'>
                  <FormInput name='phone' title='Teléfono' onChange={(e) => setPhone(e.target.value)} value={phone} maxLength={10} />
                </Col>
                <Col sm='4'>
                  <FormInput name='mobile' title='Celular' onChange={(e) => setMobile(e.target.value)} value={mobile} maxLength={10} />
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <DivFormGroup name='clasification' title='Clasificacion de cliente'>
                    <SelectClasificacionCliente name='clasification' value={clasification} onChange={(value) => setClasification(value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='uso_cfdi' title='Uso de CFDI'>
                    <SelectUsoCFDI value={uso_cfdi} onChange={(value) => setUsoCfdi(value)} />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    type='textarea'
                    name='comentarios'
                    title='Comentarios'
                    onChange={(e) => setComentarios(e.target.value)}
                    value={comentarios}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className='mb-3'>
            <Col sm='6'>
              <ListGroup>
                <ListGroup.Item>
                  <Card.Title>
                    <Icon icon='usd' style={{ fontSize: '0.8em' }} /> Créditos
                  </Card.Title>
                </ListGroup.Item>
                  {listCreditos.map((credito, idx) => (
                    <RowCredito
                      key={idx}
                      credito={credito}
                      onChangeCredito={(value) => onChangeCredito(idx, value)}
                      onRemoveCredito={() => removeItemCredito(idx)}
                    />
                  ))}
                <ListGroup.Item action onClick={addItemCredito} className='btn-block btn-lg text-center' style={{ cursor: 'pointer', fontSize: '1.2em' }}>
                  <Icon icon='plus' /> Agregar credito
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm='6'>
              <ListGroup>
                <ListGroup.Item>
                  <Card.Title>
                    <Icon icon='tag' style={{ fontSize: '0.8em' }} /> Referencias Bancarias
                  </Card.Title>
                </ListGroup.Item>
                  {listReferencias.map((x, idx) => (
                    <RowReferencia
                      key={idx}
                      referencia={x}
                      onChangeReferencia={(value) => onChangeReferencia(idx, value)}
                      onRemoveReferencia={() => removeItemReferencia(idx)}
                    />
                  ))}
                  <ListGroup.Item action onClick={addItemReferencia} className='btn-block btn-lg text-center' style={{ cursor: 'pointer', fontSize: '1.2em' }}>
                    <Icon icon='plus' /> Agregar referencia bancaria
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Card className='mb-3 mt-3'>
              <Card.Footer>
                <Row>
                  <Col sm='12'>
                    <Saving saving={isSaving} />
                    <Button onClick={handleEdit} type='button' variant='success' className='pull-right' disabled={isSaving}>
                      <Icon icon='floppy-disk' /> Guardar
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
        </form>
      </Loading>
    </Module>
  );
};

EditCustomer.propTypes = {};

export default EditCustomer;
