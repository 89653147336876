import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowReferencia from './RowReferencia';
import { Card, Col } from 'react-bootstrap';

const TableCreditos = ({ customer }) => {
  const renderTableCreditos = () => {
    const { listReferencias } = customer;

    const rows = listReferencias.map((c, i) => <RowReferencia key={i} referencia={c} />);

    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='50%'>Banco</th>
            <th width='50%'>Referencia de Pago</th>
          </tr>
        </thead>
        <tbody>{listReferencias.length > 0 ? rows : <RowsNotFound colSpan={2} message='No se han agregado referencias' />}</tbody>
      </table>
    );
  };

  if (!customer || (customer.listReferencias && customer.listReferencias.length === 0)) return null;
  const tableCreditos = renderTableCreditos();

  return (
    <Col>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon icon='tag' /> Referencias Bancarias
          </Card.Title>
        </Card.Header>
        {tableCreditos}
      </Card>
    </Col>
  );
};

TableCreditos.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableCreditos;
