import { DivFormGroup } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap';
import { Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { switchEstado } from '@utils/general';
import { useParams } from 'react-router-dom';

const Edit = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(null);
  const addForm = useRef(null);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    const { mantenimientoId } = params;
    const { plan } = await getRequest({ url: `mantenimientos/${mantenimientoId}` });

    setPlan(plan);
    setLoading(false);
  };

  const handleChange = async () => {};

  const castOption = (rowId) => {
    let option = 0;
    if (typeof addForm.current[`row-${rowId}`] !== 'undefined') {
      if (addForm.current[`row-${rowId}-1`].checked) {
        option = 1;
      } else if (addForm.current[`row-${rowId}-2`].checked) {
        option = 2;
      } else if (addForm.current[`row-${rowId}-3`].checked) {
        option = 3;
      }
    }
    return option;
  };

  const handleAddForm = async (event) => {
    event.preventDefault();

    const { mantenimientoId } = params;
    const {
      plan: { listaKit, listaTerceros },
    } = { plan };

    const kits = [];
    const terceros = [];

    listaKit.map((k) => {
      k.kitServicioId.detalle.map((r) => {
        const rowId = `${k.kitServicioId._id}-${r.piezaId._id}`;
        const option = castOption(rowId);

        kits.push({
          kitServicioId: k.kitServicioId._id,
          piezaId: r.piezaId._id,
          option,
        });
      });
    });

    listaTerceros.map((k) => {
      const { serviciosTercerosId } = k;
      const option = castOption(serviciosTercerosId._id);

      terceros.push({
        serviciosTercerosId: serviciosTercerosId._id,
        option,
      });
    });

    await putRequest({ url: `mantenimientos/${mantenimientoId}`, body: { kits, terceros } });
    loadForm();
  };

  const renderKits = () => {
    const {
      plan: { listaKit },
      mantenimientosKit,
    } = { plan, mantenimientosKit };

    const tables = listaKit.map((k, i) => {
      const { kitServicioId } = k;

      return (
        <div key={i}>
          <span className='lead'>{kitServicioId.nombre}</span>
          <table className='table table-striped align-middle table-bordered'>
            <thead>
              <tr>
                <th width='60%'>Detalle</th>
                <th width='20%'>Frecuencia</th>
                <th width='20%' className='text-center'>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {kitServicioId.detalle.map((d, x) => {
                const {
                  piezaId: { nombre, _id },
                  cantidad,
                  temporalidad,
                } = d;
                const rowId = `${kitServicioId._id}-${_id}`;
                const temporalidadLabel = switchEstado(temporalidad);

                const found = mantenimientosKit.find(
                  (m) => m.kitServicioId.toString() === kitServicioId._id.toString() && m.piezaId.toString() === _id.toString()
                );
                const has = typeof found !== 'undefined';

                let checked1 = false;
                let checked2 = false;
                let checked3 = false;

                if (has) {
                  checked1 = found.option === 1;
                  checked2 = found.option === 2;
                  checked3 = found.option === 3;
                }

                return (
                  <tr key={x}>
                    <td>
                      {cantidad > 0 ? cantidad : ''} {nombre}
                    </td>
                    <td>{typeof temporalidadLabel !== 'undefined' ? temporalidadLabel.text : ''}</td>
                    <td className='text-center'>
                      <FormGroup className='inline'>
                        <label className='radio-inline'>
                          <input
                            type='radio'
                            name={`row-${rowId}`}
                            id={`row-${rowId}-1`}
                            onChange={handleChange}
                            value={1}
                            defaultChecked={checked1}
                          />{' '}
                          Si
                        </label>
                        <label className='radio-inline'>
                          <input
                            type='radio'
                            name={`row-${rowId}`}
                            id={`row-${rowId}-2`}
                            onChange={handleChange}
                            value={2}
                            defaultChecked={checked2}
                          />{' '}
                          No
                        </label>
                        <label className='radio-inline'>
                          <input
                            type='radio'
                            name={`row-${rowId}`}
                            id={`row-${rowId}-3`}
                            onChange={handleChange}
                            value={3}
                            defaultChecked={checked3}
                          />{' '}
                          No aplica
                        </label>
                      </FormGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    });

    return (
      <div>
        <legend>Kit de Servicios</legend>
        {tables}
      </div>
    );
  };

  const renderTerceros = () => {
    const {
      plan: { listaTerceros },
      mantenimientosTerceros,
    } = { plan, mantenimientosTerceros };

    const rows = listaTerceros.map((k, i) => {
      const { serviciosTercerosId } = k;
      const rowId = serviciosTercerosId._id;

      const found = mantenimientosTerceros.find((m) => m.serviciosTercerosId.toString() === rowId.toString());
      const has = typeof found !== 'undefined';

      let checked1 = false;
      let checked2 = false;
      let checked3 = false;

      if (has) {
        checked1 = found.option === 1;
        checked2 = found.option === 2;
        checked3 = found.option === 3;
      }

      return (
        <tr key={i}>
          <td>{serviciosTercerosId.nombre}</td>
          <td>{serviciosTercerosId.proveedorId.name}</td>
          <td className='text-center'>
            <FormGroup className='inline'>
              <label className='radio-inline'>
                <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-1`} onChange={handleChange} value={1} defaultChecked={checked1} /> Si
              </label>
              <label className='radio-inline'>
                <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-2`} onChange={handleChange} value={2} defaultChecked={checked2} /> No
              </label>
              <label className='radio-inline'>
                <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-3`} onChange={handleChange} value={3} defaultChecked={checked3} /> No
                aplica
              </label>
            </FormGroup>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <legend>Servicios de Terceros</legend>

        <table className='table table-striped align-middle table-bordered'>
          <thead>
            <tr>
              <th width='50%'>Servicio</th>
              <th width='30%'>Proveedor</th>
              <th width='20%' className='text-center'>
                Opciones
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };

  const renderPlan = () => {
    return (
      <Fragment>
        {renderKits()}
        {renderTerceros()}
      </Fragment>
    );
  };

  const renderForm = () => {
    const { isLoading, date, storeId, maquinariaHerramientaId, maquinaria, stores, plan } = {
      isLoading: loading,
      date,
      storeId,
      maquinariaHerramientaId,
      maquinaria,
      stores,
      plan,
    };

    return (
      <form ref={addForm} onSubmit={handleAddForm}>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='date' title='Fecha'>
              <input
                className='form-control'
                name='date'
                id='date'
                type='date'
                value={date !== '' && date !== null ? moment(date).format('YYYY-MM-DD') : null}
                onChange={handleChange}
                disabled
              />
            </DivFormGroup>
          </Col>

          <Col sm='4'>
            <DivFormGroup name='storeId' title='Sucursal'>
              <select name='storeId' id='storeId' className='form-control' value={storeId} disabled onChange={handleChange}>
                <option value=''>Seleccione</option>
                {stores.map((s, i) => (
                  <option key={i} value={s._id}>
                    {s.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>

          <Col sm='4'>
            <DivFormGroup name='maquinariaHerramientaId' title='Maquinaria / Herramienta'>
              <select
                name='maquinariaHerramientaId'
                id='maquinariaHerramientaId'
                className='form-control'
                defaultValue={maquinariaHerramientaId}
                disabled
                onChange={handleChange}
              >
                <option value=''>Seleccione</option>
                {maquinaria.map((s, i) => (
                  <option key={i} value={s._id}>
                    {s.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
        {plan !== null ? renderPlan() : ''}
        {plan !== null ? (
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Body>
                  <Saving saving={isLoading} />
                  <Button type='submit' variant='primary' className='btn-lg pull-right' disabled={isLoading}>
                    Guardar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </form>
    );
  };

  const moduleForm = !loading ? renderForm() : <Loading />;

  return (
    <Module onClickBack={window.history.back} title='Edición del Mantenimiento'>
      {moduleForm}
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
