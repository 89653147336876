import Panel from '@controls/Panel';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { urlPhoto } from '@utils/api';
import { FileDownIcon } from 'lucide-react';

const PanelAdjuntos = ({ documentos, adjuntos }) => {
  const [documentoId, setDocumentoId] = useState('');

  const onSelectColumn = (activeKey) => {
    setDocumentoId(activeKey);
  };

  const documentoSelect = documentos.find((d) => d._id === documentoId);
  if (!documentos || documentos.length === 0) return null;

  return (
    <Col sm='12'>
      <Panel
        type='primary'
        header={
          <div>
            <Icon icon='file' /> Documentos
          </div>
        }
      >
        <Tab.Container id='PanelAdjuntos' defaultActiveKey={1} onSelect={onSelectColumn}>
          <Row className='clearfix'>
            <Col sm={4}>
              <Nav bsStyle='pills' stacked>
                {documentos.map((row, item) => {
                  const { _id, nombre } = row;

                  return (
                    <NavItem eventKey={_id} key={item}>
                      {nombre}

                      <div className='pull-right'>
                        <Icon icon='chevron-right' />
                      </div>
                    </NavItem>
                  );
                })}
              </Nav>
            </Col>
            <Col sm={8}>
              {typeof documentoSelect !== 'undefined' ? (
                <Row>
                  <Col sm='12'>
                    <div className='text-center'>
                      <span className='lead'>{documentoSelect.nombre}</span>
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Tab.Content animation>
                {documentos.map((doc, doci) => {
                  const filtro = adjuntos.filter((a) => a.documentoId.toString() === doc._id.toString());

                  return (
                    <Tab.Pane eventKey={doc._id} key={doci}>
                      <table className='table table-striped align-middle'>
                        <thead>
                          <tr>
                            <th width='80%'>Adjunto</th>
                            <th width='20%'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {filtro.length > 0 ? (
                            filtro.map((adjunto, adjuntoKey) => {
                              return (
                                <tr key={adjuntoKey}>
                                  <td>{adjunto.nombre}</td>
                                  <td>
                                    <a
                                      href={`${urlPhoto()}${adjunto.archivo}`}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='btn btn-warning pull-right'
                                    >
                                      <FileDownIcon />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <RowsNotFound colSpan={2} />
                          )}
                        </tbody>
                      </table>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Panel>
    </Col>
  );
};

PanelAdjuntos.propTypes = {
  adjuntos: PropTypes.array.isRequired,
  documentos: PropTypes.array.isRequired,
};

export default PanelAdjuntos;
