import React, { useState, useEffect } from 'react';
import { Module, Icon, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import Concepto from './Concepto';
import { renderNombreCliente } from '@utils/general';
import moment from 'moment';
import { Button, Card, Col, InputGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetalleConciliacionClientes = () => {
  const { conciliacionId } = useParams();
  const [data, setData] = useState(null);
  const [reporte, setReporte] = useState([]);
  const [lista, setLista] = useState([]);
  const [query, setQuery] = useState('');
  const [totales, setTotales] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data, reporte, totales } = await getRequest({ url: `concilianes-clientes/${conciliacionId}` });
      setData(data);
      setReporte(reporte);
      setLista(reporte);
      setTotales(totales);
    };

    fetchData();
  }, [conciliacionId]);

  const onChange = (event) => {
    const { value } = event.target;

    const filteredReporte = lista.filter((l) => {
      const {
        cliente: { name, paterno, materno, razon_social },
      } = l;
      return (
        name.toLowerCase().includes(value.toLowerCase()) ||
        paterno.toLowerCase().includes(value.toLowerCase()) ||
        materno.toLowerCase().includes(value.toLowerCase()) ||
        razon_social.toLowerCase().includes(value.toLowerCase())
      );
    });

    setQuery(value);
    setReporte(filteredReporte);
  };

  if (!data) {
    return <Module loading={true} />;
  }

  const { numero_cliente, nombre_cliente, nombre_cuenta, cuentaId, cuenta } = data;

  return (
    <Module onClickBack={window.history.back} parent='Concilaciones' title={`${cuenta} - ${cuentaId.bancoId.nombre}`} loading={!data}>
      <Row>
        <Col sm='12'>
          <Card>
            <Card.Header>
              <Card.Title>Cuenta</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm='6'>
                  <Concepto label='No. de Cliente' valor={numero_cliente} />
                  <Concepto label='Nombre del Cliente' valor={nombre_cliente} />
                  <Concepto label='Nombre de la Cuenta' valor={nombre_cuenta} />
                  <Concepto label='Cuenta' valor={cuenta} />
                </Col>
                <Col sm='6'>
                  <Concepto label='Aplicado' valor={`$ ${format(totales.aplicado, 2)} (${totales.aplicadoMovimientos} movimientos)`} />
                  <Concepto label='Por aplicar' valor={`$ ${format(totales.pendiente, 2)}  (${totales.pendienteMovimientos} movimientos)`} />
                  <Concepto label='Total' valor={`$ ${format(totales.total, 2)}  (${totales.totalMovimientos} movimientos)`} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <form className='form-horizontal'>
            <Col sm='9'></Col>
            <Col sm='3'>
              <InputGroup>
                <input type='text' id='query' name='query' className='form-control' value={query} onChange={onChange} />
                <Button className='btn btn-primary' type='button'>
                  <Icon icon='search' />
                </Button>
              </InputGroup>
            </Col>
            <br />
            <br />
            <br />
          </form>

          {reporte.map((c, ci) => {
            const { cliente, facturas, depositos } = c;

            const depositoTotal = depositos.reduce((t, r) => (t += r.depositos), 0);

            const facturasTotales = facturas.reduce(
              (t, r) => {
                t.importe += r.importe;
                t.saldoAnterior += r.saldo_anterior;
                t.saldoNuevo += r.saldo;
                return t;
              },
              {
                importe: 0,
                saldoAnterior: 0,
                saldoNuevo: 0,
              }
            );

            return (
              <Card key={ci}>
                <Card.Header>
                  <Card.Title>{renderNombreCliente(cliente)}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <h2>
                    <small>Depositos</small>
                  </h2>
                  <table className='table table-striped align-middle'>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Sucursal</th>
                        <th>Referencia</th>
                        <th>Código</th>
                        <th>Depósitos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {depositos.map((d, di) => {
                        const { numero, fecha, concepto, sucursal, referencia, codigo, depositos } = d;

                        return (
                          <tr key={di}>
                            <td>{numero}</td>
                            <td>
                              {fecha.substring(0, 2)}/{fecha.substring(2, 4)}/{fecha.substring(4, 6)}
                            </td>
                            <td>{concepto}</td>
                            <td>{sucursal}</td>
                            <td>{referencia}</td>
                            <td>{codigo}</td>
                            <td>$ {format(depositos, 2)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={6}>
                          <span className='pull-right'>Total</span>
                        </th>
                        <th>$ {format(depositoTotal, 2)}</th>
                      </tr>
                    </tfoot>
                  </table>

                  {depositos.map((d, i) => {
                    if (d.nota.length > 0) {
                      return (
                        <p key={i}>
                          <strong>Nota deposito #{d.numero}: </strong> {d.nota}
                        </p>
                      );
                    }
                  })}

                  <h2>
                    <small>Facturas</small>
                  </h2>
                  <table className='table table-striped align-middle'>
                    <thead>
                      <tr>
                        <th width='20%'>Número</th>
                        <th width='10%'>Fecha</th>
                        <th width='15%'>Importe</th>
                        <th width='15%'>Saldo anterior</th>
                        <th width='15%'>Saldo nuevo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {facturas.length > 0 ? (
                        facturas.map((r, ri) => {
                          const { serie, numero, fecha, importe, saldo_anterior, saldo } = r;

                          const isPay = saldo === 0 ? true : false;

                          let colorRowFactura = '';
                          let colorSaldo = '';

                          if (isPay) {
                            colorRowFactura = 'success';
                            colorSaldo = 'text-success';
                          } else if (saldo < saldo_anterior) {
                            colorRowFactura = 'warning';
                            colorSaldo = 'text-warning';
                          } else {
                            colorSaldo = 'text-danger';
                          }

                          return (
                            <tr key={`${ci}-${ri}`} className={colorRowFactura}>
                              <td>
                                {serie}
                                {numero}
                              </td>
                              <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
                              <td>
                                <strong>$ {format(importe, 2)}</strong>
                              </td>
                              <td>$ {format(saldo_anterior, 2)}</td>
                              <td>
                                <span className={colorSaldo}>$ {format(saldo, 2)}</span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <RowsNotFound colSpan={6} />
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={2}>
                          <span className='pull-right'>Totales</span>
                        </th>
                        <th>$ {format(facturasTotales.importe, 2)}</th>
                        <th>$ {format(facturasTotales.saldoAnterior, 2)}</th>
                        <th>$ {format(facturasTotales.saldoNuevo, 2)}</th>
                      </tr>
                    </tfoot>
                  </table>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
      </Row>
    </Module>
  );
};

DetalleConciliacionClientes.propTypes = {};

export default DetalleConciliacionClientes;
