import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import RowCotizacionesProximasAVencer from './RowCotizacionesProximasAVencer';
import PropTypes from 'prop-types';

const TableCotizacionesProximasAVencer = ({ cotizacionesProximasVencer }) => {
  if (cotizacionesProximasVencer.length === 0) return null;
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>Cotizaciones proximas a vencer</Card.Title>
          </Card.Header>
          <table className='table'>
            <thead>
              <tr>
                <th># Cotización</th>
                <th>Fecha</th>
                <th>Vencimiento</th>
                <th>Cliente</th>
                <th>Estatus</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cotizacionesProximasVencer.map((x, i) => (
                <RowCotizacionesProximasAVencer cotizacion={x} key={i} />
              ))}
            </tbody>
          </table>
        </Card>
      </Col>
    </Row>
  );
};

TableCotizacionesProximasAVencer.propTypes = {
  cotizacionesProximasVencer: PropTypes.array.isRequired,
};

export default TableCotizacionesProximasAVencer;
