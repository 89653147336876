import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { hasPermission } from '@utils/permissions';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectClienteProspecto from '@components/forms/SelectClienteProspecto';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';
import Select from 'react-select';
import { validateUpdate } from '@validations/levantamientos';
import RowArea from './components/RowArea';
import TabClasification from './components/TabClasification';

const GenerarCotizacion = ({ params, notification, navigate }) => {
  const [state, setState] = useState({
    clasificaciones: [],
    activeClasificacion: null,
    idLevantamiento: null,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: 'INICIADA',
    sucursalId: null,
    cliente: null,
    tipoCambio: 0,
    tipo_cliente: 'CLIENTE',
    tipos_clientes: [
      { value: 'PROSPECTO', label: 'PROSPECTO' },
      { value: 'CLIENTE', label: 'CLIENTE' },
    ],
    estatus_levantamientos: [{ value: 'INICIADA', label: 'INICIADA' }],
    domicilios: [],
    domicilioId: null,
    tipoLevantamientoId: null,
    areas: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `clasificadores-levantamientos/all` });
      const clasificaciones = data.filter((c) => c.subclasificaciones && c.subclasificaciones.length > 0);
      setState((prevState) => ({
        ...prevState,
        clasificaciones,
      }));
      inicializar();
    };
    fetchData();
  }, []);

  const getLevantamiento = useCallback(async () => {
    const { levantamientoId } = params;
    const { data: encabezado } = await getRequest({ url: `levantamientos/${levantamientoId}` });
    try {
      const domicilios =
        encabezado.customerId && encabezado.customerId.listDomicilios && encabezado.customerId.listDomicilios.length > 0
          ? encabezado.customerId.listDomicilios
          : [encabezado.customer.domicilio];
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: false,
        numeroOrden: encabezado.numero_levantamiento,
        fecha: moment(encabezado.fecha).local().format('YYYY-MM-DD'),
        fecha_vencimiento: moment(encabezado.fecha_vencimiento).local().format('YYYY-MM-DD'),
        estatus: encabezado.estado,
        sucursalId: encabezado.sucursal._id,
        monedaId: encabezado.moneda,
        tipoLevantamientoId: encabezado.tipo_levantamiento ? encabezado.tipo_levantamiento._id : prevState.tipoLevantamientoId,
        tipo_cliente: encabezado.tipo_cliente,
        cliente: encabezado.customer,
        agente: encabezado.agente,
        domicilioId: encabezado.customer.domicilio,
        domicilios,
        observaciones: encabezado.observaciones,
        isLoading: false,
        areas: encabezado.areas,
      }));
    } catch (e) {
      console.log(e);
    }
  }, [params]);

  const inicializar = useCallback(async () => {
    const {
      data: { monedas, measures, agente, tipos_levantamientos },
    } = await getRequest({ url: `levantamientos-catalogos` });
    const tipoLevantamientoId = tipos_levantamientos.filter((x) => x.default)[0]._id;
    setState((prevState) => ({
      ...prevState,
      tipoLevantamientoId,
      measures,
      monedas,
      agente,
      sucursalId: agente.sucursalId,
    }));
    getLevantamiento();
  }, [getLevantamiento]);

  const actualizarNumeroLevantamiento = useCallback(async () => {
    const { data } = await getRequest({ url: `levantamientos/siguienteNumero`, params: { tipoLevantamientoId: state.tipoLevantamientoId } });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_levantamiento,
      isLoadingForm: false,
    }));
  }, [state.tipoLevantamientoId]);

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      const { numeroOrden, fecha, estatus, sucursalId, almacenId, tipo_cliente, domicilioId, cliente, observaciones, areas, tipoLevantamientoId } =
        state;
      const data = {
        numero_levantamiento: numeroOrden,
        tipoLevantamientoId,
        tipo_cliente,
        fecha: moment(fecha, 'YYYY-MM-DD').utc(),
        estatus_levantamiento: estatus,
        sucursalId,
        almacenId,
        observaciones,
        clienteId: cliente ? cliente._id : '',
        agenteId: state.agente?._id,
        domicilioId,
        areas: areas.map((area) => ({
          nombre: area.nombre,
          clasificaciones: area.clasificaciones.map((clasificacion) => ({
            clasificacionId: clasificacion.clasificacionId,
            subclasificaciones: clasificacion.subclasificaciones.map((subclasificacion) => ({
              subclasificacionId: subclasificacion.subclasificacionId,
              cantidad: subclasificacion.cantidad,
            })),
          })),
          zonas: area.zonas.map((zona) => ({
            nombre: zona.nombre,
            clasificaciones: zona.clasificaciones.map((clasificacion) => ({
              clasificacionId: clasificacion.clasificacionId,
              subclasificaciones: clasificacion.subclasificaciones.map((subclasificacion) => ({
                subclasificacionId: subclasificacion.subclasificacionId,
                cantidad: subclasificacion.cantidad,
              })),
            })),
          })),
        })),
      };

      if (validations(data)) {
        const { levantamientoId } = params;
        await putRequest({ url: `levantamientos/${levantamientoId}`, body: data });
        setTimeout(() => navigate('/levantamientos'), 1000);
      }
    },
    [state, params, navigate]
  );

  const validations = useCallback(
    (params) => {
      const { error } = validateUpdate(params);
      if (error) {
        notification({
          title: 'Información incompleta',
          message: error.details[0].message,
          type: 'error',
        });
      }
      return !error;
    },
    [notification]
  );

  const onClickClasificacion = useCallback((c, event) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, activeClasificacion: c }));
  }, []);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onSelect = useCallback(
    (campo, value) => {
      setState((prevState) => ({
        ...prevState,
        [campo]: value,
      }));
      if (campo === 'tipoLevantamientoId') {
        actualizarNumeroLevantamiento();
      }
    },
    [actualizarNumeroLevantamiento]
  );

  const onChangeAgente = useCallback((itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        maximo_descuento: itemSelect.maximo_descuento,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agente: null,
      }));
    }
  }, []);

  const onChangeCliente = useCallback(
    (itemSelect) => {
      if (itemSelect) {
        const domicilios = state.tipo_cliente === 'CLIENTE' ? itemSelect.listDomicilios : [{ ...itemSelect.domicilio, _id: 1 }];
        const domicilioId = domicilios.length === 1 ? domicilios[0]._id : null;
        if (domicilios.length > 1 && this.refDomicilio) setTimeout(() => this.refDomicilio.inputRef.focus(), 300);
        if (itemSelect && itemSelect.clasification) {
          if (itemSelect.clasification.opcion === 'ADVERTENCIA') {
            notification({ title: 'Advertencia', message: 'Este cliente cuenta con saldo vencido o limite de credito.', type: 'warning' });
          } else if (itemSelect.clasification.opcion === 'OCUPA_AUTORIZACION') {
            notification({
              title: 'Advertencia',
              message: 'Este cliente cuenta con saldo vencido o limite de credito y requiere autorizacion para cotizarle.',
              type: 'warning',
            });
          } else if (itemSelect.clasification.opcion === 'SIN_AUTORIZACION') {
            return notification({
              title: 'Advertencia',
              message: 'Este cliente cuenta con saldo vencido o limite de credito asi que no es posible cotizarle.',
              type: 'error',
            });
          }
        }
        setState((prevState) => ({
          ...prevState,
          cliente: itemSelect,
          domicilioId,
          domicilios: domicilios,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          cliente: null,
          domicilioId: null,
          domicilios: [],
        }));
      }
    },
    [state.tipo_cliente, notification]
  );

  const handleAgregarArea = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      areas: [
        ...prevState.areas,
        {
          nombre: '',
          showZonas: false,
          zonas: [
            {
              nombre: '',
              subclasificaciones: [],
            },
          ],
        },
      ],
    }));
  }, []);

  const handleChangeArea = useCallback((index, area) => {
    setState((prevState) => ({
      ...prevState,
      areas: [
        ...prevState.areas.map((x, i) => {
          if (i === index) {
            return {
              ...area,
            };
          }
          return x;
        }),
      ],
    }));
  }, []);

  const onClickQuitarClasificacion = useCallback((clasificacion, e) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      clasificaciones: [...prevState.clasificaciones.filter((c) => c._id != clasificacion._id)],
      activeClasificacion:
        prevState.activeClasificacion !== null
          ? prevState.activeClasificacion._id !== clasificacion._id
            ? prevState.activeClasificacion
            : null
          : null,
      areas: [
        ...prevState.areas.map((a) => ({
          ...a,
          clasificaciones: [...a.clasificaciones.filter((c) => c._id != clasificacion._id)],
          zonas: [
            ...a.zonas.map((sb) => ({
              ...sb,
              clasificaciones: [...sb.clasificaciones.filter((c) => c._id != clasificacion._id)],
            })),
          ],
        })),
      ],
    }));
  }, []);

  const renderView = () => {
    const {
      isLoading,
      clasificaciones,
      areas,
      activeClasificacion,
      tipoLevantamientoId,
      numeroOrden,
      fecha,
      estatus,
      estatus_levantamientos,
      agente,
      sucursalId,
      tipo_cliente,
      cliente,
      domicilioId,
      domicilios,
    } = state;
    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='6 col-xs-12'>
                <Row>
                  <Col xs='6'>
                    <DivFormGroup name='tipoLevantamientoId' required title='Tipo de levantamiento'>
                      <SelectTipoLevantamiento
                        value={tipoLevantamientoId}
                        onChange={(value) => onSelect('tipoLevantamientoId', value)}
                        name='tipoLevantamientoId'
                      />
                    </DivFormGroup>
                  </Col>
                  <Col xs='6'>
                    <FormInput required title='Núm. Levantamiento' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
                  </Col>
                </Row>
              </Col>
              <Col sm='6 col-xs-12 '>
                <Row>
                  <Col xs='6'>
                    <FormInput
                      title='Fecha'
                      type='date'
                      required
                      name='fecha'
                      onChange={onChange}
                      disabled={!hasPermission('cambiar-fecha')}
                      value={fecha}
                    />
                  </Col>
                  <Col xs='6'>
                    <DivFormGroup name='estatus' required title='Estatus'>
                      <Select name='estatus' options={estatus_levantamientos} onChange={(value) => onSelect('estatus', value)} value={estatus} />
                    </DivFormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs='6'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agente} onChange={onChangeAgente} name='agente' />
                </DivFormGroup>
              </Col>
              <Col xs='6'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} name='sucursalId' />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='9'>
                <DivFormGroup name='clienteName' required title={tipo_cliente}>
                  <SelectClienteProspecto value={cliente} onChange={onChangeCliente} name='cliente' />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <SelectCreateOrUpdateDomicilio
                  customerId={cliente?._id}
                  domicilio={domicilioId}
                  onChange={(value) => onSelect('domicilioId', value)}
                  domicilios={domicilios}
                  setDomicilios={(domicilios) => setState((prevState) => ({ ...prevState, domicilios }))}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header style={{ paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px' }}>
            <ul className='nav nav-tabs' role='tablist'>
              {clasificaciones.map((c, i) => (
                <TabClasification
                  key={i}
                  clasificacion={c}
                  active={activeClasificacion && c?._id === activeClasificacion?._id}
                  onClickClasificacion={(event) => onClickClasificacion(c, event)}
                  onClickQuitarClasificacion={(e) => onClickQuitarClasificacion(c, e)}
                />
              ))}
            </ul>
          </Card.Header>
          <div className='tab-content'>
            <div className='tab-pane active'>
              {clasificaciones && (
                <div className='table-responsive'>
                  <table className='table table-striped align-middle'>
                    <thead>
                      <tr>
                        <th width='10%'>Area</th>
                        <th width='10%'>Zonas</th>
                        {activeClasificacion &&
                          activeClasificacion.subclasificaciones.map((sb, k) => (
                            <th key={k} style={{ writingMode: 'vertical-lr' }}>
                              {sb.nombre}
                            </th>
                          ))}
                        <th width='3%'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {areas.map((area, i) => (
                        <RowArea key={i} area={area} activeClasificacion={activeClasificacion} onChangeArea={(zona) => handleChangeArea(i, zona)} />
                      ))}
                      <tr>
                        <td colSpan={((activeClasificacion && activeClasificacion.subclasificaciones.length) || 1) + 3} style={{ padding: '0px' }}>
                          <Button className='btn-block' onClick={handleAgregarArea}>
                            <Icon icon='plus' /> Agregar area
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <Card.Footer></Card.Footer>
        </Card>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} variant='primary' className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Levantamientos' title='Editar levantamiento'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

GenerarCotizacion.propTypes = {
  params: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
  onChangeTipoCliente: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default GenerarCotizacion;
