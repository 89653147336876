import { RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import ImprimirLevantamiento from './ImprimirLevantamiento';
import { ButtonGroup, Card } from 'react-bootstrap';

const List = (props) => {
  const renderRows = () => {
    const { list } = props;
    return list.map((row, key) => {
      const {
        _id,
        numero_levantamiento,
        tipo_cliente,
        customer,
        customerId,
        estado,
        fecha,
        agente,
        sucursal,
        fecha_vencimiento,
        tipo_levantamiento,
        observaciones,
      } = row;
      const estadoIconMap = {
        CANCELADA: 'remove',
        DECLINADA: 'remove',
        AUTORIZADA: 'ok',
        FINALIZADA: 'ok',
        default: 'info-sign',
      };

      const estadoBadgeMap = {
        CANCELADA: 'danger',
        DECLINADA: 'danger',
        AUTORIZADA: 'primary',
        FINALIZADA: 'success',
        default: 'primary',
      };

      const icon = estadoIconMap[estado] || estadoIconMap['default'];
      const badge = estadoBadgeMap[estado] || estadoBadgeMap['default'];
      let labelEstado = (
        <CbBadge type={badge}>
          <span className='badge-label'>{estado}</span>
          <Icon style={{ marginLeft: '0.25rem ' }} icon={icon} />
        </CbBadge>
      );
      const diasVencimiento = moment(fecha_vencimiento).local().diff(moment(), 'days');
      const estaVencida = diasVencimiento < 0;
      const porVencer = diasVencimiento > 0 && diasVencimiento < 6;
      if (estaVencida && estado !== 'FINALIZADA')
        labelEstado = (
          <CbBadge type='danger'>
            <span className='badge-label'>VENCIDA</span>
            <Icon style={{ marginLeft: '0.25rem ' }} icon='info-sign' />
          </CbBadge>
        );
      return (
        <tr key={key}>
          <td>{numero_levantamiento}</td>
          <td>{tipo_levantamiento ? tipo_levantamiento.nombre : null}</td>
          <td>
            {moment(fecha).local().format('DD/MM/YYYY')}
            <span style={{ fontSize: '10px', fontWeight: 700, color: porVencer ? '#dc3545' : '#000' }}>
              {' '}
              <br /> Vence: {moment(fecha_vencimiento).local().format('DD/MM/YYYY')}
            </span>
          </td>
          <td>
            <Link
              to={`/${tipo_cliente === 'CLIENTE' ? 'clientes' : 'prospectos'}/detalle/${customer ? customer._id : null}`}
              target='_blank'
              rel='noreferrer'
            >
              {customer ? customer.razon_social : null}
            </Link>
            {customerId && customerId.nombre_comercial && (
              <span style={{ fontSize: '10px', fontWeight: 700 }}>
                {' '}
                <br /> {customerId.nombre_comercial}
              </span>
            )}
          </td>
          <td>{sucursal ? sucursal.name : null}</td>
          <td>{agente ? agente.nombre : null}</td>
          <td>{labelEstado}</td>
          <td>
            {observaciones && observaciones.length > 15 ? <span title={observaciones}>{observaciones.substring(0, 15)}...</span> : observaciones}
          </td>
          <td style={{ textAlign: 'right' }}>
            <ButtonGroup>
              <If condition={estado !== 'CANCELADA' && estado !== 'FINALIZADA' && estado !== 'ACEPTADA' && !estaVencida}>
                <If.Then>
                  <ImprimirLevantamiento id={key} numero_levantamiento={numero_levantamiento} levantamientoId={_id} />
                  <RedirectTooltip
                    id={key}
                    labelTooltip='Ir a zonas'
                    url={`/levantamientos/${_id}/areas`}
                    icon='copy'
                    className='btn btn-outline-warning'
                  />
                  <RedirectTooltip
                    id={key}
                    labelTooltip='Editar'
                    url={`/levantamientos/edicion/${_id}`}
                    icon='edit'
                    className='btn btn-outline-success'
                  />
                  <RedirectTooltip
                    id={key}
                    labelTooltip='Cotizar'
                    url={`/levantamientos/cotizacion/${_id}`}
                    icon='edit'
                    className='btn btn-outline-warning'
                  />
                </If.Then>
              </If>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  const obtenerClase = (orden, tipo, campo) => {
    if (orden !== campo) return 'sorting';
    if (tipo === -1) return 'sorting_desc';
    return 'sorting_asc';
  };

  const establecerOrden = (orden, tipo, campo) => {
    props.loadData({ page: 0, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
  };

  const { list, orden, tipo } = props;
  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={11} />;
  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th
                className={obtenerClase(orden, tipo, 'numero_levantamiento')}
                width='5%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'numero_levantamiento');
                }}
              >
                #
              </th>
              <th
                className={obtenerClase(orden, tipo, 'tipo_levantamiento')}
                width='10%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'tipo_levantamiento');
                }}
              >
                Tipo
              </th>
              <th
                className={obtenerClase(orden, tipo, 'fecha')}
                width='10%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'fecha');
                }}
              >
                Fecha
              </th>
              <th
                className={obtenerClase(orden, tipo, 'cliente')}
                onClick={() => {
                  establecerOrden(orden, tipo, 'cliente');
                }}
              >
                Cliente / Prospecto
              </th>
              <th
                className={obtenerClase(orden, tipo, 'sucursal')}
                width='10%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'sucursal');
                }}
              >
                Sucursal
              </th>
              <th
                className={obtenerClase(orden, tipo, 'agente')}
                width='10%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'vendedor');
                }}
              >
                Agente
              </th>
              <th
                className={obtenerClase(orden, tipo, 'estado')}
                onClick={() => {
                  establecerOrden(orden, tipo, 'estado');
                }}
              >
                Estatus
              </th>
              <th
                className={obtenerClase(orden, tipo, 'observaciones')}
                onClick={() => {
                  establecerOrden(orden, tipo, 'observaciones');
                }}
              >
                Observaciones
              </th>
              <th width='12%'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </Card>
  );
};

List.propTypes = {
  tipos_pedidos: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  listAlmacenes: PropTypes.array.isRequired,
  listSucursales: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  agenteId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default List;
