import React, { useState } from 'react';
import { Row, Col, DropdownButton, DropdownItem, Card, Button } from 'react-bootstrap';
import { postRequest, URL_FILES } from '@utils/api';
import { Loading, Icon } from '@stateless';
import { IfPermission } from '@controls';
import PropTypes from 'prop-types';
import { format } from '@utils/parseCost';
import { PieChart, Pie, Legend, Cell, Label, ResponsiveContainer } from 'recharts';
// import { Bar, BarChart, XAxis, YAxis, Line, LineChart, Tooltip} from 'recharts';

const IndicadoresCotizaciones = ({
  periodos,
  agentes,
  agente,
  cotizaciones,
  periodoLevantamiento,
  setPeriodoLevantamiento,
  setAgente,
  loadingCotizaciones,
  cotizacionesAgentes,
  listCotizaciones,
}) => {
  const [animation] = useState(true);

  const onClickPeriodoLevantamiento = (periodo) => {
    setPeriodoLevantamiento(periodo);
  };

  const onClickAgente = (agente) => {
    setAgente(agente);
  };

  const handleExcelEncabezado = async () => {
    const { file } = await postRequest({
      url: `dashboard/exportar-reporte-cotizaciones`,
      params: { periodo: periodoLevantamiento.value, agente: agente._id },
    });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const renderLabelContent = ({ value, x, y, midAngle, name, cantidad }) => {
    return (
      <g transform={`translate(${x}, ${y})`} textAnchor={midAngle < -90 || midAngle >= 90 ? 'end' : 'start'}>
        <text x={0} y={0}>{`${name}: ${cantidad}`}</text>
        <text x={0} y={15}>{`$ ${format(value, 2)}`}</text>
      </g>
    );
  };
  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <div className='pull-right mr-5' style={{ marginBottom: '10px' }}>
                  <DropdownButton title={periodoLevantamiento.nombre || ''} variant='light'>
                    {periodos.map((periodo, i) => (
                      <DropdownItem key={i} onClick={() => onClickPeriodoLevantamiento(periodo)}>
                        {periodo.nombre}
                      </DropdownItem>
                    ))}
                  </DropdownButton>
                </div>
                <div className='pull-right mr-5' style={{ marginBottom: '10px' }}>
                  <DropdownButton title={agente?.nombre || ''} variant='light'>
                    {agentes.map((agente, i) => (
                      <DropdownItem key={i} onClick={() => onClickAgente(agente)}>
                        {agente.nombre}
                      </DropdownItem>
                    ))}
                  </DropdownButton>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Loading loading={loadingCotizaciones}>
                  <ResponsiveContainer width='100%' height={350}>
                    <PieChart width={600} height={300}>
                      <Legend />
                      <Pie
                        data={cotizaciones}
                        dataKey='value'
                        cx={300}
                        cy={150}
                        startAngle={220}
                        endAngle={-220}
                        innerRadius={30}
                        outerRadius={80}
                        label={renderLabelContent}
                        paddingAngle={30}
                        isAnimationActive={animation}
                      >
                        {cotizaciones.map((entry, index) => (
                          <Cell key={`slice-1-${index}`} fill={entry.color} />
                        ))}
                        <Label width={50} position='center'>
                          Cotizaciones
                        </Label>
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Loading>
              </Col>
            </Row>
          </Card.Body>
          <IfPermission action='todas-cotizaciones'>
            <table className='table table-striped align-middle'>
              <thead>
                <tr>
                  <th>Agente</th>
                  <th>
                    Cotizado
                    <Button onClick={handleExcelEncabezado} variant='primary' className='pull-right mr-5' type='button'>
                      <Icon icon='save-file' /> Exportar
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {cotizacionesAgentes.map((a, i) => (
                  <tr key={i}>
                    <td scope='row'>{a.agenteNombre}</td>
                    <td width='30%'>
                      <table width='100%'>
                        <tbody>
                          {a.estatus.map((x, i) => {
                            const estado = listCotizaciones.find((y) => y.estatus == x._id.estado);
                            return (
                              <tr key={i}>
                                <th width='60%'>
                                  <span className='label' style={{ background: estado ? estado.color : '#fff' }}>
                                    {estado ? estado.name : x._id.estado}
                                  </span>
                                </th>
                                {/* <td><b>{ format(x.cantidad)}</b></td> */}
                                <td>
                                  <b>$ {format(x.total)}</b>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </IfPermission>
        </Card>
      </Col>
    </Row>
  );
};

IndicadoresCotizaciones.propTypes = {
  periodos: PropTypes.array,
  agentes: PropTypes.array,
  listCotizaciones: PropTypes.array,
  agente: PropTypes.object,
  periodoLevantamiento: PropTypes.object,
  setPeriodoLevantamiento: PropTypes.func,
  setAgente: PropTypes.func,
  cotizaciones: PropTypes.array,
  loadingCotizaciones: PropTypes.bool,
  cotizacionesAgentes: PropTypes.array,
};

export default IndicadoresCotizaciones;
