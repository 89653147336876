import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Card, Col, Row } from 'react-bootstrap';

const DetailTerceros = ({ planMantenimientoId, nombre }) => {
  const [showModal, setShowModal] = useState(false);
  const [listaTerceros, setListaTerceros] = useState([]);

  const close = () => {
    setShowModal(false);
    setListaTerceros([]);
  };

  const open = async () => {
    const {
      data: { listaTerceros },
    } = await getRequest({ url: `planes-terceros/${planMantenimientoId}` });
    setListaTerceros(listaTerceros);
    setShowModal(true);
  };

  const renderKits = () => {
    return listaTerceros.map((k, i) => {
      const { serviciosTercerosId } = k;

      return (
        <Card key={i}>
          <Card.Header>
            <Card.Title>{serviciosTercerosId.nombre}</Card.Title>
          </Card.Header>
          <Card.Body>
            <h4>
              Proveedor <small>{serviciosTercerosId.proveedorId.name}</small>
            </h4>
          </Card.Body>
        </Card>
      );
    });
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='zoom-in' title='Ver detalles' />
      <CbModal
        show={showModal}
        title={
          <>
            Servicios de terceros asignados a <strong>{nombre}</strong>
          </>
        }
        onClose={close}
      >
        <Row>
          <Col>{renderKits()}</Col>
        </Row>
      </CbModal>
    </>
  );
};

DetailTerceros.propTypes = {
  planMantenimientoId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
};

export default DetailTerceros;
