import { TYPE_CONFIGURATIONS } from '@config/constants';
import { RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import DeleteCharacteristics from './DeleteCharacteristics';
import { Col, Row } from 'react-bootstrap';

const TableCharacteristics = ({ listCharacteristics }) => {
  const renderRows = (listCharacteristics) => {
    return listCharacteristics.map((rowProduct, item) => {
      const {
        _id,
        characteristicsConfigurationId: { name, type },
        characteristicsRecipeId: { recipe },
        listFieldsValues,
      } = rowProduct;

      let labelType = '';
      let nameCompleted = `${name} ${recipe}`;

      if (type === TYPE_CONFIGURATIONS.PRODUCT) {
        labelType = <span className='label label-danger'>Productos</span>;
      } else if (type === TYPE_CONFIGURATIONS.SERVICE) {
        labelType = <span className='label label-warning'>Servicios</span>;
      }

      const content = listFieldsValues.map((f, i) => {
        if (!f.stepId.capturable) {
          nameCompleted += `, ${f.stepId.step} ${f.value}`;

          return (
            <Col sm='4' key={i}>
              <small>
                <Col sm='5'>
                  <strong className='pull-right'>{f.stepId.step}:</strong>
                </Col>
                <Col sm='7'>
                  {' '}
                  <em>{f.value}</em>
                </Col>
              </small>
            </Col>
          );
        }
        return null;
      });

      const contentCapturable = listFieldsValues.map((f, i) => {
        if (f.stepId.capturable) {
          return (
            <Col sm='6' key={i}>
              <small>
                <span>
                  <strong className='pull-right'>{f.stepId.step}</strong>
                </span>
              </small>
            </Col>
          );
        }
        return null;
      });

      return (
        <tr key={item}>
          <td>
            <small>
              <strong>
                {name}: <em>{recipe}</em>
              </strong>
            </small>
            <br />
            {labelType}
          </td>
          <td>
            <Row>{content}</Row>
          </td>
          <td>
            <Row>{contentCapturable}</Row>
          </td>
          <td>
            <DeleteCharacteristics id={item} characteristicsId={_id} name={nameCompleted} />
            <RedirectTooltip
              id={1}
              icon='pencil'
              url={`/caracteristicas/edicion/${_id}`}
              labelTooltip='Editar'
              className='btn btn-sm btn-info pull-right'
            />
            <RedirectTooltip
              id={1}
              icon='cog'
              url={`/caracteristicas/detalle/${_id}`}
              labelTooltip='Ver Equipo Completo'
              className='btn btn-sm btn-primary pull-right'
            />
          </td>
        </tr>
      );
    });
  };

  let rows = null;

  if (listCharacteristics.length > 0) {
    rows = renderRows(listCharacteristics);
  } else {
    rows = <RowsNotFound message='Aún no hay características registradas.' colSpan={4} />;
  }

  return (
    <table className='table table-striped align-middle'>
      <thead>
        <tr>
          <th width='20%'>Configuración</th>
          <th width='50%'>Descripción</th>
          <th width='15%'>Capturables</th>
          <th width='15%'></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TableCharacteristics.propTypes = {
  listCharacteristics: PropTypes.array.isRequired,
};

export default TableCharacteristics;
