import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import { useStoreNotification } from '@stores/catalogs.store';

const Editar = ({ loadData, id }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [regimen, setRegimen] = useState('I');
  const [rfc, setRfc] = useState('XAXX010101000');
  const [nombre, setNombre] = useState('');
  const [nombreComercial, setNombreComercial] = useState('');
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [contacto, setContacto] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    tipos: [],
  });
  const [domicilio, setDomicilio] = useState({
    fiscal: true,
    calle: '',
    exterior: '',
    interior: '',
    localidad: '',
    colonia: '',
    coloniaId: null,
    codigo_postal: '',
    municipio: '',
    estado: '',
    calle1: '',
    calle2: '',
    referencias: '',
    municipioId: null,
    pais: 'MÉXICO',
    paisId: null,
    estadoId: null,
    coloniaObject: null,
    municipioObject: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // componentDidMount logic here
  }, []);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `prospectos/${id}` });
    const {
      calle,
      exterior,
      interior,
      localidad,
      colonia,
      coloniaId,
      codigo_postal,
      municipio,
      municipioId,
      estado,
      estadoId,
      entre_calles: { calle1, calle2 },
      referencias,
    } = data.domicilio || { entre_calles: {} };
    const razon_social = data.regimen === 'F' ? `${data.name || ''} ${data.paterno || ''} ${data.materno || ''}`.trim() : data.razon_social;
    setRegimen(data.regimen || 'I');
    setRfc(data.rfc || 'XAXX010101000');
    setNombre(data.name || '');
    setPaterno(data.paterno || '');
    setMaterno(data.materno || '');
    setRazonSocial(razon_social);
    setNombreComercial(data.nombre_comercial || '');
    setShowModal(true);
    setContacto({
      nombre: razon_social || '',
      correo: data.email || '',
      telefono: data.phone || '',
      tipos: [],
    });
    setDomicilio({
      fiscal: true,
      calle: calle || '',
      exterior: exterior || '',
      interior: interior || '',
      localidad: localidad || '',
      colonia: colonia || '',
      coloniaId: coloniaId,
      codigo_postal: codigo_postal || '',
      municipio: municipio || '',
      estado: estado || '',
      calle1: calle1 || '',
      calle2: calle2 || '',
      referencias: referencias || '',
      municipioId: municipioId,
      estadoId: estadoId ? estadoId.toString() : null,
      pais: 'MÉXICO',
      paisId: null,
      coloniaObject: null,
      municipioObject: null,
    });
    setLoading(false);
  };

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };
    if (isEmpty(params.rfc)) {
      validate.message = 'RFC es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.nombre)) {
      validate.message = 'Nombre es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.paterno)) {
      validate.message = 'Apellido paterno es requerido.';
    } else if (isEmpty(params.phone) && isEmpty(params.email)) {
      validate.message = 'Los contactos requieren telefono o correo electronico.';
    } else {
      validate.success = true;
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();
    const data = {
      regimen,
      rfc: rfc.trim(),
      nombre: nombre.trim(),
      name: nombre.trim(),
      paterno: paterno.trim(),
      materno: materno.trim(),
      razon_social: razonSocial.trim(),
      nombre_comercial: nombreComercial.trim(),
      domicilio: {
        calle: domicilio.calle ? domicilio.calle.toString().trim().toUpperCase() : '',
        exterior: domicilio.exterior,
        interior: domicilio.interior,
        colonia: domicilio.colonia ? domicilio.colonia.toString().trim().toUpperCase() : '',
        codigo_postal: domicilio.codigo_postal,
        municipio: domicilio.municipio ? domicilio.municipio.toString().trim().toUpperCase() : '',
        municipioId: domicilio.municipioId,
        estado: domicilio.estado.toString().trim().toUpperCase(),
        estadoId: domicilio.estadoId,
        calle1: domicilio.calle1,
        calle2: domicilio.calle2,
        referencias: domicilio.referencias,
        coloniaId: domicilio.coloniaObject ? domicilio.coloniaObject._id : null,
        paisId: domicilio.paisObject ? domicilio.paisObject._id : null,
        localidad: domicilio.localidad,
      },
      email: contacto.correo,
      phone: contacto.telefono,
      mobile: contacto.telefono,
    };
    if (validations(data)) {
      await putRequest({ url: `prospectos/${id}`, body: data });
      close();
      loadData();
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    if (name === 'regimen') {
      if (value === 'I') {
        setRfc('XAXX010101000');
      } else if (value === 'E') {
        setRfc('XEXX010101000');
      }
    }
    if (name === 'nombre') setNombre(value);
    if (name === 'paterno') setPaterno(value);
    if (name === 'materno') setMaterno(value);
    if (name === 'razon_social') setRazonSocial(value);
    if (name === 'nombre_comercial') setNombreComercial(value);
    if (regimen === 'F' && (name === 'nombre' || name === 'paterno' || name === 'materno')) {
      setRazonSocial(`${nombre} ${paterno} ${materno}`.trim());
    }
  };

  const onChangeContacto = (name, e) => {
    setContacto({
      ...contacto,
      [name]: e.target.value,
    });
  };

  const onChangeDomicilio = (domicilio) => {
    setDomicilio({
      ...domicilio,
    });
  };

  return (
    <>
      <CbButtonOver icon='pencil' title='Editar' type='success' onClick={open} />
      <CbModal show={showModal} onClose={close} title='Editar ' onSave={handleRegistrar} isLoading={loading}>
        <Row>
          {regimen === 'F' ? (
            <>
              <Col>
                <FormInput name='nombre' title='Nombre(s)' onChange={onChange} value={nombre} required />
              </Col>
              <Col>
                <FormInput name='paterno' title='Apellido Paterno' onChange={onChange} value={paterno} required />
              </Col>
              <Col>
                <FormInput name='materno' title='Apellido Materno' onChange={onChange} value={materno} />
              </Col>
            </>
          ) : (
            <Col>
              <FormInput name='razon_social' title='Razón Social' onChange={onChange} value={razonSocial} required />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <FormInput name='nombre_comercial' title='Nombre comercial' onChange={onChange} value={nombreComercial} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              name='telefono'
              className='form-control input-sm'
              title='Teléfono/Celular'
              value={contacto.telefono}
              required
              onChange={(e) => onChangeContacto('telefono', e)}
            />
          </Col>
          <Col>
            <FormInput
              name='correo'
              className='form-control input-sm'
              value={contacto.correo}
              title='Correo electrónico'
              type='email'
              required
              onChange={(e) => onChangeContacto('correo', e)}
            />
          </Col>
        </Row>
        <br />
        <div>
          <Card>
            <Card.Body>
              <Card.Title>
                Domicilios
              </Card.Title>
              <DomicilioEdit domicilio={domicilio} onChange={onChangeDomicilio} />
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </div>
      </CbModal>
    </>
  );
};

Editar.propTypes = {
  loadData: PropTypes.func,
  id: PropTypes.number.isRequired,
};

export default Editar;
