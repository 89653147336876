import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { FormGroup, InputGroup } from 'react-bootstrap';

const FormGroupIcon = ({ title, icon, children }) => {
  return (
    <FormGroup>
      <InputGroup>
        <InputGroup.Text>
          <Icon icon={icon} title={title} />
        </InputGroup.Text>
        {children}
      </InputGroup>
    </FormGroup>
  );
};

FormGroupIcon.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormGroupIcon;
