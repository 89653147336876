import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import CustomLocalStorage from '@utils/localStorage';
import { Container } from 'react-bootstrap';
import { FileWarning } from 'lucide-react';
const SICOB_APP_USER = 'SICOB_APP_USER';
const SICOB_APP_TOKEN = 'SICOB_APP_TOKEN';

const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);
  useEffect(() => {
    const handleError = async (error, errorInfo) => {
      const usuario = CustomLocalStorage.getItem(SICOB_APP_USER, {});
      const email = usuario?.email || '';
      const name = usuario?.name || '';
      const token = CustomLocalStorage.getItem(SICOB_APP_TOKEN);
      // const host = window.location?.host;
      const action = window.location?.pathname || '';
      if (process.env.NODE_ENV === 'production') {
        axios.post(
          'https://hooks.slack.com/services/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',
          JSON.stringify({
            text: `Usuario: ${email} ${name}
Token: ${token}
Url: ${action}
Info: ${JSON.stringify(errorInfo)}
Error: ${error ? error.toString() : error}`,
          })
        );
      } else {
        setError(error);
        setErrorInfo(errorInfo);
      }
    };

    const errorListener = (event) => {
      handleError(event.error, { componentStack: event.error.stack });
    };

    window.addEventListener('error', errorListener);

    return () => {
      window.removeEventListener('error', errorListener);
    };
  }, [location]);

  if (errorInfo) {
    return (
      <Container>
        <div className='well'>
          <h2>
            <FileWarning size={20} /> Algo salió mal.
          </h2>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </div>
        </div>
      </Container>
    );
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
