import { DivFormGroup, Input } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const IS_NEW = 'NEW';

const NewCharacteristics = () => {
  const { addNotification: notification } = useStoreNotification();
  const [isLoading] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const [configurationId, setConfigurationId] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [recipeId, setRecipeId] = useState('');
  const [recipe, setRecipe] = useState(null);
  const frmNewCharacteristics = useRef(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      const {
        data: { configurations },
      } = await getRequest({ url: `catalog/characteristics` });
      setConfigurations(configurations);
    };

    fetchConfigurations();
  }, []);

  const onChange = (event) => {
    const target = event.target;
    const name = target.name;

    let recipesFiltering = recipes;
    let newRecipe = recipe;

    if (name === 'configurationId') {
      const serviceSelected = configurations.find((service) => service._id === target.value);

      recipesFiltering = typeof serviceSelected !== 'undefined' ? serviceSelected.listRecipes : [];

      frmNewCharacteristics.current.recipeId.value = '';
      newRecipe = null;
    }

    if (name === 'configurationId') setConfigurationId(target.value);
    if (name === 'recipeId') setRecipeId(target.value);
    setRecipes(recipesFiltering);
    setRecipe(newRecipe);
  };

  const onChangeRecipeId = async (event) => {
    const { value } = event.target;
    let newRecipeId = value;
    if (newRecipeId) {
      const { data } = await getRequest({ url: `catalog/recipe/${newRecipeId}` });
      setRecipeId(newRecipeId);
      setRecipe(data);
    } else {
      setRecipeId('');
      setRecipe(null);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const {
      measureId,
      providerId,
      storehouseId,
      recipe: { _id, listSteps, configurationId },
    } = recipe;

    const catalogFields = [];

    listSteps.map((row) => {
      if (row.addProcess) {
        const { _id, addNew } = row;

        const field = addNew ? `new-${_id}` : _id;
        const value = row.capturable ? '' : frmNewCharacteristics.current[field].value.trim();

        catalogFields.push({
          value,
          stepId: _id,
        });
      }
    });

    const data = {
      configurationId: configurationId._id,
      recipeId: _id,
      measureId,
      providerId,
      storehouseId,
      catalogFields,
    };

    const allFieldsEmpty = catalogFields.filter((f) => isEmpty(f.value));

    if (allFieldsEmpty.length !== catalogFields.length) {
      if (validations(data, frmNewCharacteristics.current)) {
        await postRequest({ url: 'characteristics', body: data });
      }
    } else {
      notification({
        title: 'Información incompleta',
        message: 'Favor de especificar valores para los campos de Procedimiento',
        type: 'error',
      });
    }
  };

  const onChangeValueStep = (stepId) => {
    const isNew = frmNewCharacteristics.current[stepId].value.trim() === IS_NEW;

    recipe.listSteps.map((step) => {
      if (step._id.toString() === stepId.toString()) {
        step.addNew = isNew;
      }
    });

    setRecipe({ ...recipe });

    setTimeout(() => {
      if (isNew) {
        frmNewCharacteristics.current[`new-${stepId}`].focus();
      }
    }, 10);
  };

  const renderForm = () => {
    const { recipe: recipeName, configurationId, listSteps } = recipe;

    const listStepsProcess = listSteps.filter((step) => step.addProcess && !step.capturable);
    const listStepsCapturables = listSteps.filter((step) => step.capturable);

    const formStepsProcess = listStepsProcess.map((itemStep, indexStep) => {
      const { step, note, required, options, addNew, defaultNew } = itemStep;
      const stepId = itemStep._id;

      const optionsValue = Object.keys(options).map((key) => {
        return (
          <option key={key} value={options[key]}>
            {options[key]}
          </option>
        );
      });

      return (
        <Col sm='6' key={indexStep}>
          <DivFormGroup name={stepId} title={required ? `* ${step}` : step}>
            {defaultNew ? (
              <Input name={'new-' + stepId} disabled={!addNew} />
            ) : (
              <div>
                <select id={stepId} name={stepId} ref={stepId} className='form-control' onChange={() => onChangeValueStep(stepId)}>
                  <option value=''>Seleccione</option>
                  {optionsValue}
                  <option value={IS_NEW}>Otro ...</option>
                </select>

                {addNew ? (
                  <Input name={'new-' + stepId} disabled={!addNew} style={{ marginTop: '5px' }} placeholder='Favor de proporcionar el nuevo valor' />
                ) : null}
              </div>
            )}

            <small>{note}</small>
          </DivFormGroup>
        </Col>
      );
    });

    const formListCapturables = listStepsCapturables.map((step, i) => {
      return (
        <span key={i}>
          <h3 className='lead'>
            <Icon icon='minus' /> {step.step}
          </h3>
        </span>
      );
    });

    return (
      <div>
        <h3>
          <u>{configurationId.name}</u> / <small>{recipeName}</small>
        </h3>

        {listStepsProcess.length > 0 ? (
          <Row>
            <br />
            <Col>
              <legend>
                <Icon icon='cog' /> Procedimiento
              </legend>
            </Col>
            {formStepsProcess}
          </Row>
        ) : null}

        {formListCapturables.length > 0 ? (
          <Row>
            <br />
            <Col>
              <legend>
                <Icon icon='cog' /> Campos de capturables en carros
              </legend>
            </Col>
            {formListCapturables}
          </Row>
        ) : null}

        <div>
          <Saving saving={isLoading} />
          <Button type='submit' variant='primary' className='btn-lg pull-right' disabled={isLoading}>
            <Icon icon='floppy-disk' /> Guardar
          </Button>
        </div>
      </div>
    );
  };

  const renderTypeServicesRecipe = () => {
    return (
      <Module onClickBack={window.history.back} title='Agregar Detalle a Características'>
        <form ref={frmNewCharacteristics} onSubmit={handleRegister}>
          <Row>
            <Col>
              <legend>
                <Icon icon='wrench' /> Configuraciones / Características
              </legend>
            </Col>

            <Col sm='6'>
              <DivFormGroup name='configurationId' title='Configuración'>
                <select id='configurationId' name='configurationId' onChange={onChange} className='form-control' defaultValue={configurationId}>
                  <option value=''>Selecciona</option>
                  {configurations.map((row, item) => {
                    return (
                      <option key={item} value={row._id}>
                        {row.name}
                      </option>
                    );
                  })}
                </select>
              </DivFormGroup>
            </Col>
            <Col sm='6'>
              <DivFormGroup name='recipeId' title='Tipo'>
                <select
                  id='recipeId'
                  name='recipeId'
                  onChange={onChangeRecipeId}
                  disabled={configurations.length === 0}
                  className='form-control'
                  defaultValue={recipeId}
                >
                  <option value=''>Selecciona</option>
                  {recipes.map(({ _id, recipe, listSteps }, item) => {
                    if (listSteps.length > 0) {
                      return (
                        <option key={item} value={_id}>
                          {recipe}
                        </option>
                      );
                    }
                  })}
                </select>
              </DivFormGroup>
            </Col>
          </Row>

          {recipe ? renderForm() : null}
        </form>
      </Module>
    );
  };

  const validations = (params, form) => {
    let validate = {
      success: true,
      message: '',
    };

    const { listSteps } = recipe;

    listSteps.map((row) => {
      if (row.addProcess) {
        const field = row.addNew ? `new-${row._id}` : row._id;

        if (!row.capturable) {
          if (validate.success && isEmpty(form[field].value) && row.required) {
            validate.success = false;
            validate.message = `El campo ${row.step} es requerido.`;
          }
        }
      }
    });

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return configurations.length > 0 ? renderTypeServicesRecipe() : <Loading />;
};

NewCharacteristics.propTypes = {};

export default NewCharacteristics;
