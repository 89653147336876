import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { components } from 'react-select';
import { useStoreSavingLoading } from '@stores/catalogs.store';
import { Button, Card, Container } from 'react-bootstrap';

import {
  TrashIcon, 
  PlusIcon, 
  ShareIcon, 
  PencilIcon, 
  FileDownIcon, 
  ZoomInIcon, 
  FileIcon, 
  TagsIcon, 
  CopyIcon, 
  MessageSquareIcon, 
  GlobeIcon, 
  BookmarkIcon, 
  CameraIcon, 
  DollarSignIcon, 
  LockIcon, 
  ClockIcon, 
  UserIcon, 
  CheckIcon, 
  BadgeDollarSignIcon, 
  AlertCircleIcon, 
  SearchIcon, 
  TableOfContentsIcon,
  ListIcon,
  HomeIcon,
  MapPinIcon,
  MessageCircleIcon,
  PauseIcon,
  FileInputIcon,
  SaveIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  FolderIcon,
  ArrowDownIcon,
  FolderOpenDotIcon,
  PrinterIcon,
  RefreshCcw,
  SendIcon,
  InboxIcon,
  ChevronRightIcon,
  CalendarIcon,
  CreditCardIcon,
  BookIcon,
  ShoppingCartIcon,
  List,
  BarChart2Icon,
  SettingsIcon,
  BriefcaseIcon,
  CogIcon,
  ArrowUpIcon,
} from 'lucide-react';

export const CustomerOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ borderRadius: '0', fontSize: '12px' }}>
        <b>
          {rest.data.tipo_cliente && (
            <span title='Tipo cliente' className='label label-warning'>
              {rest.data.tipo_cliente}
            </span>
          )}{' '}
          {rest.data.numero_cliente && (
            <span title='Numero de cliente' className='label label-success'>
              {rest.data.numero_cliente}
            </span>
          )}{' '}
          {rest.data.razon_social}
        </b>{' '}
        <br />
        <span title='RFC' className='label label-primary'>
          {rest.data.rfc}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.nombre_comercial}</span>
      </div>
    </components.Control>
  );
};

CustomerOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const OrdenServicioOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ padding: '5px', borderRadius: '0' }}>
        OT:{' '}
        <b>
          {rest.data.numero_orden_servicio && (
            <span title='Descripción' className='label label-success'>
              {rest.data.numero_orden_servicio}
            </span>
          )}{' '}
          {rest.data.referencia}
        </b>{' '}
        <br />
        CLIENTE:
        <b>
          {rest.data.customerId?.numero_cliente && (
            <span title='Numero de cliente' className='label label-success'>
              {rest.data.cliente?.numero_cliente}
            </span>
          )}{' '}
          {rest.data.cliente?.razon_social}
        </b>{' '}
        <br />
        <span title='RFC' className='label label-primary'>
          {rest.data.cliente?.rfc}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.cliente?.nombre_comercial}</span> <br />
        DOMICILIO:{' '}
        <span title='MONITOREO' className='label label-primary'>
          {rest.data.domicilioId?.monitoreo}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.cliente?.domicilio_entrega?.nombre}</span>
      </div>
    </components.Control>
  );
};

OrdenServicioOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const CustomerRegistroOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ padding: '5px', borderRadius: '0' }}>
        CRM:{' '}
        <b>
          {rest.data.folio && (
            <span title='Descripción' className='label label-success'>
              {rest.data.folio}
            </span>
          )}{' '}
          {rest.data.descripcion}
        </b>{' '}
        <br />
        CLIENTE:
        <b>
          {rest.data.customerId?.numero_cliente && (
            <span title='Numero de cliente' className='label label-success'>
              {rest.data.customerId?.numero_cliente}
            </span>
          )}{' '}
          {rest.data.customerId?.razon_social}
        </b>{' '}
        <br />
        <span title='RFC' className='label label-primary'>
          {rest.data.customerId?.rfc}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.customerId?.nombre_comercial}</span> <br />
        DOMICILIO:{' '}
        <span title='MONITOREO' className='label label-primary'>
          {rest.data.domicilioId?.monitoreo}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.domicilioId?.nombre}</span>
      </div>
    </components.Control>
  );
};

CustomerRegistroOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const DomicilioOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ padding: '5px', borderRadius: '0' }}>
        <span title='MONITOREO' className='label label-primary'>
          {rest.data.monitoreo}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.nombre}</span> <br />
        <span title='Calle'>{rest.data.calle}</span>
        {rest.data.exterior && <span title='Numero exterior'> {rest.data.exterior}</span>}
        {rest.data.interior && <span title='Numero interior'>-{rest.data.interior}</span>}, &nbsp;
        <span title='Colonia'>{rest.data.colonia}</span>, <span title='Código postal'>{rest.data.codigo_postal}</span>
        <br />
        <span title='Localidad'>{rest.data.municipio || rest.data.localidad}</span>, <span title='Estado'>{rest.data.estado}</span>,{' '}
        <span title='País'>{rest.data.pais}.</span>
      </div>
    </components.Control>
  );
};

DomicilioOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const ProductOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ borderRadius: '0', fontSize: '12px' }}>
        <b>
          {rest.data.tipo_partida && (
            <span title='Tipo partida' className='label label-warning'>
              {rest.data.tipo_partida}
            </span>
          )}{' '}
          {rest.data.code}
        </b>{' '}
        {rest.data.name}
      </div>
    </components.Control>
  );
};

ProductOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const Loading = ({ loading = true, children }) => {
  if (!loading) return children;
  return (
    <div className='text-center'>
      <br />
      <br />
      <br />
      <div className="spinner-grow mr-2" style={{width: '2rem', height: '2rem'}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="spinner-grow mr-2" style={{width: '2rem', height: '2rem'}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="spinner-grow mr-2" style={{width: '2rem', height: '2rem'}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <h3 className='mt-4'>Cargando ...</h3>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export const Module = ({ loading = false, children }) => {
  return (
    <>
      <div className='content-header'>
       
      </div>
      <div className='content'>
        <Container fluid>
          <Loading loading={loading}>{children}</Loading>
        </Container>
      </div>
    </>
  );
};

Module.propTypes = {
  icon: PropTypes.string,
  parent: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClickBack: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export const SubModule = (props) => {
  const icon = typeof props.icon !== 'undefined' ? props.icon : '';
  const space = icon !== '' ? '' : '';
  const onClickBack = typeof props.onClickBack !== 'undefined' ? props.onClickBack : '';
  return (
    <div>
      <h1 className='page-header'>
        {onClickBack ? (
          <Button variant='link' title='Atrás' onClick={onClickBack}>
            <Icon icon='glyphicon glyphicon-chevron-left' style={{ fontSize: '20px', fontWeight: '100', color: '#3333335c' }} />
          </Button>
        ) : null}
        <Icon icon={icon} />
        {space}
        <small>{props.title}</small>
      </h1>
      {props.children}
    </div>
  );
};

SubModule.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClickBack: PropTypes.func,
};

export const getIcon = (icon) => {
  switch (icon) {
    case 'trash':
      return <TrashIcon size={16} className='align-middle'/>;
    case 'plus': 
      return <PlusIcon size={16} className='align-middle' />;
    case 'share':
      return <ShareIcon size={16} className='align-middle' />;
    case 'pencil':
      return <PencilIcon size={16} className='align-middle' />;
    case 'download-alt': 
      return <FileDownIcon size={16} className='align-middle' />;
    case 'zoom-in':
      return <ZoomInIcon size={16} className='align-middle' />;
    case 'file':
      return <FileIcon size={16} className='align-middle' />;
    case 'tags':
      return <TagsIcon size={16} className='align-middle' />;
    case 'duplicate':
      return <CopyIcon size={16} className='align-middle' />;
    case 'comment':
      return <MessageSquareIcon size={16} className='align-middle' />;
    case 'globe':
      return <GlobeIcon size={16} className='align-middle' />;
    case 'bookmark':
      return <BookmarkIcon size={16} className='align-middle' />;
    case 'screenshot':
      return <CameraIcon size={16} className='align-middle' />;
    case 'usd':
      return <DollarSignIcon size={16} className='align-middle' />;
    case 'lock':
      return <LockIcon size={16} className='align-middle' />;
    case 'time':
      return <ClockIcon size={16} className='align-middle' />;
    case 'user':
      return <UserIcon size={16} className='align-middle' />;
    case 'remove':
      return <TrashIcon size={16} className='align-middle' />;
    case 'check':
      return <CheckIcon size={16} className='align-middle' />;
    case 'save-file':
      return <BadgeDollarSignIcon size={16} className='align-middle' />;
    case 'exclamation-sign':
      return <AlertCircleIcon size={16} className='align-middle' />;
    case 'search':
      return <SearchIcon size={16} className='align-middle' />;
    case 'th-list': 
      return <TableOfContentsIcon size={16} className='align-middle' />;
    case 'home': 
      return <HomeIcon size={16} className='align-middle' />;
    case 'list': 
      return <ListIcon size={16} className='align-middle' />;
    case 'map-marker':
      return <MapPinIcon size={16} className='align-middle' />;
    case 'edit':
      return <PencilIcon size={16} className='align-middle' />;
    case 'envelope':
      return <MessageCircleIcon size={16} className='align-middle' />;
    case 'pause':
      return <PauseIcon size={16} className='align-middle' />;
    case 'retweet':
      return <FileInputIcon size={16} className='align-middle' />;
    case 'th-large':
      return <TableOfContentsIcon size={16} className='align-middle' />;
    case 'picture':
      return <CameraIcon size={16} className='align-middle' />;
    case 'copy':
      return <CopyIcon size={16} className='align-middle' />;
    case 'open-file':
      return <FileIcon size={16} className='align-middle' />;
    case 'floppy-disk':
      return <SaveIcon size={16} className='align-middle' />;
    case 'triangle-top':
      return <ChevronUpIcon size={16} className='align-middle' />;
    case 'triangle-bottom':
      return <ChevronDownIcon size={16} className='align-middle' />;
    case 'folder-close':
      return <FolderIcon size={16} className='align-middle' />;
    case 'arrow-down':
      return <ArrowDownIcon size={16} className='align-middle' />;
    case 'folder-open':
      return <FolderOpenDotIcon size={16} className='align-middle' />;
    case 'info-sign':
      return <AlertCircleIcon size={16} className='align-middle' />;
    case 'print':
      return <PrinterIcon size={16} className='align-middle' />;
    case 'tag':
      return <TagsIcon size={16} className='align-middle' />;
    case 'export':
      return <FileDownIcon size={16} className='align-middle' />;
    case 'sync':
      return <RefreshCcw size={16} className='align-middle' />;
    case 'refresh':
      return <RefreshCcw size={16} className='align-middle' />;
    case 'send':
      return <SendIcon size={16} className='align-middle' />;
    case 'inbox':
      return <InboxIcon size={16} className='align-middle' />;
    case 'menu-right':
      return <ChevronRightIcon size={16} className='align-middle' />;
    case 'calendar':
      return <CalendarIcon size={16} className="align-middle" />;
    case 'credit-card':
      return <CreditCardIcon size={16} className="align-middle" />;
    case 'book':
      return <BookIcon size={16} className="align-middle" />;
    case 'shopping-cart':
      return <ShoppingCartIcon size={16} className="align-middle" />;
    case 'list-alt':
      return <List size={16} className="align-middle" />;
    case 'equalizer':
      return <BarChart2Icon size={16} className="align-middle" />;
    case 'wrench':
      return <SettingsIcon size={16} className="align-middle" />;
    case 'briefcase':
      return <BriefcaseIcon size={16} className="align-middle" />;
    case 'cog':
      return <CogIcon size={16} className="align-middle" />;
    case 'arrow-up':
      return <ArrowUpIcon size={16} className="align-middle" />;
    default:
      console.log('Icon not found', icon);
      return null;
  }
};

export const Icon = (props) => {
  if(getIcon(props.icon)) return getIcon(props.icon); 
  return <span className={`glyphicon glyphicon-${props.icon}`} style={{ ...props.style }} {...props} aria-hidden='true'></span>;
};

Icon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export const DataNotFount = ({ style, message = 'No se encontraron resultados.'}) => {
  return (
    <div className='alert alert-info text-center' style={style} role='alert'>
      {message}
    </div>
  );
};

DataNotFount.propTypes = {
  style: PropTypes.object,
  message: PropTypes.string,
};


export const HR = () => {
  return <hr style={{ marginTop: '4px', marginBottom: '5px' }} />;
};

export const Space = (props) => {
  return <span className={props.className}>&nbsp;</span>;
};

Space.propTypes = {
  className: PropTypes.string,
};

export const Saving = ({ action, saving }) => {
  const getStoreSavingLoading = useStoreSavingLoading();
  if (saving || getStoreSavingLoading.saving) {
    return (
      <span className='lead' style={{ marginRight: '10px' }}>
        {action || 'Guardando'} favor de esperar...
      </span>
    );
  }
  return null;
};

Saving.propTypes = {
  action: PropTypes.string,
  saving: PropTypes.bool,
};

export const TitlePanelDiets = (props) => {
  const styles = {
    marginBottom: '5px',
    marginTop: '5px',
  };

  return (
    <Card.Title style={styles}>
      {props.title}
    </Card.Title>
  );
};

TitlePanelDiets.propTypes = {
  title: PropTypes.string,
};

export const RowsNotFound = ({ colSpan, children, message = 'Aún no hay registros.'}) => {
  if (children && children.length > 0) return children;
  return (
    <tr>
      <td colSpan={colSpan} className='text-center'>
        {message}
      </td>
    </tr>
  );
};

RowsNotFound.propTypes = {
  colSpan: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
};
