import { PENDIENTE } from '@config/constants';
import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import { Card } from 'react-bootstrap';

const Table = ({ list, loadData }) => {
  const onChange = () => {};

  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha, numero_merma, estado, moneda, sucursal, almacen, estatus_merma } = r;
      return (
        <tr key={i}>
          <td className='text-center'>
            <input
              type='checkbox'
              name='mermas[]'
              id={`merma-${i}`}
              onChange={onChange}
              value={_id}
              disabled={estado !== PENDIENTE || moneda !== 'PESO'}
            />
          </td>
          <td>{numero_merma}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>{sucursal.name}</td>
          <td>{almacen.name}</td>
          <td className='with-actions'>
            <CbBadge type='primary'>{estatus_merma}</CbBadge>
            <span className='actions'>
              <RedirectTooltip
                id={1}
                icon='search'
                url={`/ordenes-mermas/detalle/${_id}`}
                labelTooltip='Detalles'
                className='btn btn-sm btn-default'
              />
              <If condition={estado !== 'CANCELADA'}>
                <If.Then>
                  <Confirmation
                    id={_id}
                    btnIcon='remove'
                    typeConfirm='danger'
                    btnType='danger'
                    action='Cancelar merma'
                    url={`mermas/cancelar/${_id}`}
                    description={`¿Confirma que desea cancelar la <strong>merma: <u>#${numero_merma}</u></strong> ?`}
                    loadData={loadData}
                  />
                </If.Then>
              </If>
            </span>
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='1%'>&nbsp;</th>
              <th width='10%'># Merma</th>
              <th width='13%'>Fecha</th>
              <th width='10%'>Sucursal</th>
              <th width='10%'>Almacen</th>
              <th width='10%'>Estatus</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </Card>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
