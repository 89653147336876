import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, DropdownItem, FormGroup, Modal, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmail, isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const subjectDefault = 'Envío de cotización';

const EnviarLevantamiento = ({ id, email: propEmail }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [checkEnviarCopia, setCheckEnviarCopia] = useState(true);
  const [codigos, setCodigos] = useState(true);
  const [desgloseKits, setDesgloseKits] = useState(false);
  const [horasServicio, setHorasServicio] = useState(false);

  const close = () => {
    setShowModal(false);
    setSubject(subjectDefault);
  };

  const open = () => {
    setShowModal(true);
    setSubject(subjectDefault);
    setEmail(propEmail);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'radio') {
      if (name === 'codigos') setCodigos(!(value === 'true'));
      if (name === 'desgloseKits') setDesgloseKits(!(value === 'true'));
      if (name === 'horasServicio') setHorasServicio(!(value === 'true'));
    } else {
      if (name === 'subject') setSubject(value);
      if (name === 'email') setEmail(value);
    }
  };

  const handleSendReference = async (event) => {
    event.preventDefault();

    const body = {
      subject: subject.trim(),
      email: email.trim(),
      enviarCopia: checkEnviarCopia,
      codigos,
      desgloseKits,
      horasServicio,
    };

    if (validations(body)) {
      await postRequest({ url: `cotizaciones/email/${id}`, body });
      close();
    }
  };

  const onChangeCheck = (event) => {
    const { name, checked } = event.target;
    if (name === 'checkEnviarCopia') setCheckEnviarCopia(checked);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (validate.success && isEmpty(params.subject + '')) {
      validate.success = false;
      validate.message = 'El campo Asunto es requerido.';
    } else if (validate.success && params.subject.length > 60) {
      validate.success = false;
      validate.message = 'El campo Asunto excede el limite de caracteres, solo se permiten hasta 60.';
    } else if (validate.success && isEmpty(params.email + '')) {
      validate.success = false;
      validate.message = 'El campo de Destinatario(s) es requerido.';
    }

    const list = params.email.split(',');

    if (validate.success && list.length > 0) {
      const fail = list.filter((e) => {
        if (!isEmpty(e.trim() + '')) {
          return !isEmail(e.trim() + '');
        }
      });

      if (fail.length > 0) {
        validate.success = false;
        validate.message = 'Favor de especificar correo válidos en el campo de Destinatario(s).';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='envelope' /> Enviar por correo
      </DropdownItem>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar por correo </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSendReference}>
            <Row>
              <Col sm='4'>
                <FormGroup className='inline'>
                  <label>Con códigos</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='codigos' onChange={onChange} value={true} checked={!codigos} /> No
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='codigos' onChange={onChange} value={false} checked={codigos} /> Si
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col sm='4'>
                <FormGroup className='inline'>
                  <label>Desglose de kits</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='desgloseKits' onChange={onChange} value={true} checked={!desgloseKits} /> No
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='desgloseKits' onChange={onChange} value={false} checked={desgloseKits} /> Si
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col sm='4'>
                <FormGroup className='inline'>
                  <label>Con horas de servicio</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='horasServicio' onChange={onChange} value={true} checked={!horasServicio} /> No
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='horasServicio' onChange={onChange} value={false} checked={horasServicio} /> Si
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <DivFormGroup name='subject' title='Asunto'>
              <Input name='subject' onChange={onChange} defaultValue={subject} />
            </DivFormGroup>
            <DivFormGroup name='email' title='Destinatario(s)'>
              <TextArea name='email' onChange={onChange} defaultValue={email} />
              <p>
                <br />
                <small>Separar correos por comas: correo1@codigoblanco.mx,correo2@codigoblanco.mx </small>
              </p>
            </DivFormGroup>
            <div className='checkbox'>
              <label>
                <input type='checkbox' name='checkEnviarCopia' onChange={onChangeCheck} checked={checkEnviarCopia} /> Enviar a copia al
                contacto@codigoblanco.mx
              </label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} onClick={handleSendReference} variant='success' className='pull-right'>
            Enviar
          </Button>
          <Button disabled={isLoading} onClick={close} variant='light' className='pull-right' type='button'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EnviarLevantamiento.propTypes = {
  id: PropTypes.string,
  email: PropTypes.string,
};

export default EnviarLevantamiento;
