import React, { useEffect } from 'react';
import Select from 'react-select';
import { getRequest } from '@utils/api';
import PropTypes from 'prop-types';
import { CustomerRegistroOption } from '@stateless';

const SelectCRMCliente = ({ onChange, name, value, customerId, domicilioId }) => {
  const [data, setData] = React.useState([]);
  const fetchData = async () => {
    if (!customerId) {
      setData([]);
      return;
    }
    const { data: crms } = await getRequest({ url: `customers-registros/customer/${customerId}` });
    const crmsd = crms.filter((c) => c.domicilio === domicilioId);
    setData(crmsd);
  }

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const onSelect = (selected) => {
    onChange(selected);
  };

  return (
    <Select
      inputId={name}
      value={value}
      options={data}
      onChange={onSelect}
      getOptionLabel={(x) => `${x.folio} ${x.descripcion}`}
      getOptionValue={(x) => x._id}
      isClearable
      placeholder={'Buscar por folio o descripcion '}
      components={{ Option: CustomerRegistroOption }}
    />
  );
};

SelectCRMCliente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  customerId: PropTypes.string.isRequired,
  domicilioId: PropTypes.string.isRequired,
};

export default SelectCRMCliente;
