import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { switchColorStock } from '@utils/general';
import CbModal from '@cbcomponents/CbModal';
import { Dropdown } from 'react-bootstrap';
import { Icon } from '@stateless';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const LimitesExistencias = ({ equipmentId, storehouses }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [listLimitesExistencias, setListLimitesExistencias] = useState([]);

  const close = () => setShowModal(false);

  const open = async () => {
    const responseEquipment = await getRequest({ url: `equipments/${equipmentId}` });
    const responseExistencia = await getRequest({ url: `existencias-almacen/${equipmentId}` });
    let listLimitesExistencias = responseEquipment.data.listLimitesExistencias || [];
    listLimitesExistencias = storehouses.map((s) => {
      const storeInventory = responseExistencia.data.find((x) => x._id.storehouseId._id === s._id);
      const storeInventoryLimit = listLimitesExistencias.find((x) => x.storehouseId === s._id);
      return {
        _id: storeInventoryLimit?._id,
        equipmentId,
        storehouseId: s._id,
        storehouse: s,
        equipment: responseEquipment.data,
        stock: storeInventory?.stock || 0,
        minimo: storeInventoryLimit?.minimo || 0,
        maximo: storeInventoryLimit?.maximo || 0,
      };
    });

    setShowModal(true);
    setListLimitesExistencias(listLimitesExistencias);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const data = { listLimitesExistencias };
    if (validations(data)) {
      await postRequest({ url: `equipments/limitesExistencias/${equipmentId}`, body: data });
      close();
    }
  };

  const validations = (params) => {
    let validate = { success: true, message: '' };
    for (let i = 0; i < params.listLimitesExistencias.length; i++) {
      const limiteExistencia = params.listLimitesExistencias[i];
      if (limiteExistencia.maximo < limiteExistencia.minimo) {
        validate.success = false;
        validate.message = 'Limite maximo no debe ser menor a limite minimo.';
        break;
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleChange = (index, e) => {
    const { value, name } = e.target;
    setListLimitesExistencias((prevList) => prevList.map((x, i) => (i === index ? { ...x, [name]: cleanNumber(value) } : x)));
  };

  return (
    <>
      <Dropdown.Item onClick={open}>
        <Icon icon='tasks' /> Inventario
      </Dropdown.Item>
      <CbModal show={showModal} onClose={close} title='Editar' size='lg' onSave={handleSave}>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th>Almacén</th>
              <th className='text-center'>Actual</th>
              <th className='text-center'>
                <span className='label label-danger'>Mínimo</span>
              </th>
              <th className='text-center'>
                <span className='label label-success'>Máximo</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {listLimitesExistencias.map((s, i) => (
              <tr key={i}>
                <td>
                  <strong>{s.storehouse.name}</strong>
                </td>
                <td>
                  <div className='text-center'>
                    <span className={`label label-${switchColorStock(s)}`}>{s.stock}</span>
                  </div>
                </td>
                <td>
                  <InputNumber name='minimo' value={s.minimo} onChange={(e) => handleChange(i, e)} />
                </td>
                <td>
                  <InputNumber name='maximo' value={s.maximo} onChange={(e) => handleChange(i, e)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CbModal>
    </>
  );
};

LimitesExistencias.propTypes = {
  equipmentId: PropTypes.string.isRequired,
  storehouses: PropTypes.array.isRequired,
};

export default LimitesExistencias;
