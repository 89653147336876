import React, { useState, useEffect } from 'react';
import { Module, Loading, Icon } from '@stateless';
import { RowItem } from '@controls';
import Panel from '@controls/Panel';
import { TYPE_CONFIGURATIONS } from '@config/constants';
import { getRequest } from '@utils/api';
import DataEmpty from './DataEmpty';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const QuotationByCustomer = () => {
  const params = useParams();
  const [quotation, setQuotation] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuotation = async () => {
      const { customerId, quotationId } = params;
      const { data } = await getRequest({ url: `customer/${customerId}/quotation/${quotationId}` });
      setQuotation(data);
      setLoading(false);
    };

    fetchQuotation();
  }, [params]);

  const renderFisica = () => {
    const {
      customerId: { tituloClienteId, name, paterno, materno, phone, mobile, email },
      sellerId,
    } = quotation;

    const abreviatura = typeof tituloClienteId !== 'undefined' ? tituloClienteId.abreviatura : '';

    return (
      <Card>
        <Card.Body>
          <Row>
            <Col sm='6'>
              <RowItem lead={false} label='Cliente'>
                {abreviatura} {name} {paterno} {materno}
              </RowItem>
              <RowItem lead={false} label='Telefonos'>
                {phone} {mobile}
              </RowItem>
              <RowItem lead={false} label='Correo electrónico'>
                {email}
              </RowItem>
            </Col>
            <Col sm='6'>
              <p className='pull-right'>
                VENDEDOR{' '}
                <strong>
                  {sellerId.name} {sellerId.fatherLastName} {sellerId.motherLastName}
                </strong>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const renderMoral = () => {
    const {
      customerId: {
        representante_legal: { tituloRepresentanteId, nombre, paterno, materno },
        phone,
        mobile,
        email,
        razon_social,
        rfc,
        nombre_comercial,
      },
      sellerId,
    } = quotation;

    const abreviatura = typeof tituloRepresentanteId !== 'undefined' && tituloRepresentanteId !== null ? tituloRepresentanteId.abreviatura : '';

    return (
      <Card>
        <Card.Body>
          <Row>
            <Col sm='4'>
              <RowItem lead={false} label='Razón Social'>
                {razon_social}
              </RowItem>
              <RowItem lead={false} label='Nombre Comercial'>
                {nombre_comercial}
              </RowItem>
              <RowItem lead={false} label='RFC'>
                {rfc}
              </RowItem>
            </Col>
            <Col sm='4'>
              <RowItem lead={false} label='Representante Legal'>
                {abreviatura} {nombre} {paterno} {materno}
              </RowItem>
              <RowItem lead={false} label='Telefonos'>
                {phone} {mobile}
              </RowItem>
              <RowItem lead={false} label='Correo electrónico'>
                {email}
              </RowItem>
            </Col>
            <Col sm='4'>
              <p className='pull-right'>
                VENDEDOR{' '}
                <strong>
                  {sellerId.name} {sellerId.fatherLastName} {sellerId.motherLastName}
                </strong>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const renderQuotation = () => {
    const { quotationId } = params;
    const {
      customerId: { regimen },
      listAreas,
    } = quotation;

    return (
      <Module onClickBack={window.history.back} title={`Cotización Folio ${quotationId}`}>
        {regimen === 'M' ? renderMoral() : renderFisica()}
        <legend>
          <Icon icon='home' /> Áreas
        </legend>
        {listAreas.map((a, aKey) => {
          const { listZones } = a;

          return (
            <div key={aKey}>
              <h2>
                <u>{a.area}</u> <small>Área {aKey + 1}</small>
              </h2>

              <div>
                <h3>
                  <Icon icon='screenshot' /> Zonas
                </h3>

                {listZones.map((z, zKey) => {
                  const { listConfigurations } = z;

                  return (
                    <div key={zKey}>
                      <h3 className='text-center'>
                        <u>{z.zone}</u> <small>Zona {zKey + 1}</small>
                      </h3>
                      <div>
                        {listConfigurations.map((s, sKey) => {
                          const { quotationsListRecipes } = s;
                          return (
                            <div key={sKey}>
                              <h4>
                                <u>{s.quotationsConfigurationId.name}</u>
                                <small>Tipo de {s.quotationsConfigurationId.type === TYPE_CONFIGURATIONS.PRODUCT ? 'Equipo' : 'Servicio'}</small>
                              </h4>
                              {quotationsListRecipes.length > 0 ? (
                                quotationsListRecipes.map((r, rKey) => {
                                  const { equipmentsSelect, servicesSelect } = r;

                                  return (
                                    <div key={rKey}>
                                      <h5>
                                        <u>{r.quotationsRecipeId.recipe}</u>
                                        <small>{s.quotationsConfigurationId.type === TYPE_CONFIGURATIONS.PRODUCT ? 'Equipo' : 'Servicio'}</small>
                                      </h5>

                                      <Row>
                                        {equipmentsSelect.map((e, eKey) => {
                                          const {
                                            amount,
                                            capturable,
                                            quotationsEquipmentWorkforceId,
                                            quotationsEquipmentId: {
                                              code,
                                              name,
                                              make,
                                              description,
                                              equipmentsCharacteristicsId: { listFieldsValues },
                                            },
                                          } = e;

                                          let labelWorkForce = null;

                                          if (typeof quotationsEquipmentWorkforceId !== 'undefined' && quotationsEquipmentWorkforceId !== null) {
                                            labelWorkForce = (
                                              <span>
                                                Tipo de Mano de Obra{' '}
                                                <span className='label label-danger'>nivel {quotationsEquipmentWorkforceId.level}</span> -{' '}
                                                <strong>{quotationsEquipmentWorkforceId.value} hrs</strong>
                                              </span>
                                            );
                                          }

                                          return (
                                            <Col sm={4} key={eKey}>
                                              <Panel
                                                type='info'
                                                header={
                                                  <div>
                                                    <span className='label label-primary'>{amount}</span> {name}
                                                  </div>
                                                }
                                              >
                                                <div>
                                                  <small>
                                                    Código {code}, Marca {make}
                                                    <br />
                                                    Descripción {description}
                                                    <br />
                                                    {labelWorkForce}
                                                  </small>
                                                </div>
                                                <hr />

                                                {listFieldsValues.map((f, fKey) => {
                                                  return (
                                                    <Col key={fKey} sm={6}>
                                                      <RowItem lead={false} label={f.stepId.step}>
                                                        {f.stepId.capturable ? capturable : f.value}
                                                      </RowItem>
                                                    </Col>
                                                  );
                                                })}
                                              </Panel>
                                            </Col>
                                          );
                                        })}

                                        {servicesSelect.map((e, eKey) => {
                                          const {
                                            amount,
                                            quotationsServicesId: {
                                              code,
                                              name,
                                              make,
                                              description,
                                              servicesCharacteristicsId: { listFieldsValues },
                                            },
                                          } = e;

                                          return (
                                            <Col sm={4} key={eKey}>
                                              <Panel
                                                type='info'
                                                header={
                                                  <div>
                                                    <span className='label label-primary'>{amount}</span> {name}
                                                  </div>
                                                }
                                              >
                                                <div>
                                                  <small>
                                                    Código {code}, Marca {make}
                                                    <br />
                                                    Descripción {description}
                                                  </small>
                                                </div>
                                                <hr />

                                                {listFieldsValues.map((f, fKey) => {
                                                  return (
                                                    <Col key={fKey} sm={6}>
                                                      <RowItem lead={false} label={f.stepId.step}>
                                                        {f.value}
                                                      </RowItem>
                                                    </Col>
                                                  );
                                                })}
                                              </Panel>
                                            </Col>
                                          );
                                        })}
                                      </Row>
                                    </div>
                                  );
                                })
                              ) : (
                                <DataEmpty />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Module>
    );
  };

  return (
    <Row>
      <Col sm='12'>{!loading ? renderQuotation() : <Loading />}</Col>
    </Row>
  );
};

QuotationByCustomer.propTypes = {};

export default QuotationByCustomer;
