import { DivFormGroup, Input, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import { Col, Row } from 'react-bootstrap';

const Edit = ({ tipoNominaId, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [nombre, setNombre] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const {
      data: { codigo, nombre },
    } = await getRequest({ url: `tiposnominas/${tipoNominaId}` });
    setShowModal(true);
    setCodigo(codigo);
    setNombre(nombre);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      codigo: codigo.toUpperCase().trim(),
      nombre: nombre.toUpperCase().trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `tiposnominas/${tipoNominaId}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='codigo' title='Código' required />
              <Input name='codigo' placeholder='Código' onChange={(e) => setCodigo(e.target.value)} value={codigo} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='nombre' title='Nombre' required />
              <Input name='nombre' placeholder='Nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  tipoNominaId: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
