import { DivFormGroup, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmail, isEmpty, isInt, isLength, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Row, Col, Card, InputGroup, ListGroup } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';

const NORMAL = 'NORMAL';
const AGENTE = 'AGENTE';

const EditUser = ({ idUser, loadUsers }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);

  const [name, setName] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agenteId, setAgenteId] = useState('');
  const [rol, setRol] = useState(false);
  const [modulosAsignados, setModulosAsignados] = useState([]);

  const close = () => {
    setShowModal(false);
    setIsLoading(false);
  };

  const open = async () => {
    const { data, modules } = await getRequest({ url: `users/${idUser}` });
    setShowModal(true);
    setName(data.name);
    setCellPhone(data.cellPhone);
    setEmail(data.email);
    setAgenteId(data.agenteId);
    setRol(data.rol === AGENTE);
    setModulosAsignados(
      modules.map((mod) => {
        const moduleChecked = data.modulesAssing.find((assing) => assing.moduleID._id === mod._id);
        return {
          _id: mod._id,
          name: mod.module,
          icon: mod.icon,
          checked: !!moduleChecked,
          submodulos: mod.submodules.map((subModule) => {
            let subModuleChecked =
              moduleChecked && moduleChecked.subModules.find((assingSubMod) => assingSubMod._id && assingSubMod._id._id === subModule._id);
            return {
              _id: subModule._id,
              name: subModule.name,
              path: subModule.path,
              checked: !!subModuleChecked,
              procesos: (subModule.processes || []).map((process) => {
                let processChecked =
                  subModuleChecked &&
                  subModuleChecked.processes &&
                  subModuleChecked.processes.find((assingProcess) => assingProcess === process.code);
                return {
                  _id: process._id,
                  name: process.name,
                  code: process.code,
                  checked: !!processChecked,
                };
              }),
            };
          }),
        };
      })
    );
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const assinned = modulosAsignados
      .filter((x) => x.checked)
      .map((x) => ({
        module: x._id,
        submodules: x.submodulos
          .filter((y) => y.checked)
          .map((y) => ({ submodule: y._id, processes: y.procesos.filter((z) => z.checked).map((z) => z.code) })),
      }));

    const data = {
      rol: rol === true ? AGENTE : NORMAL,
      agenteId: agenteId?._id,
      name,
      cellPhone,
      email,
      assinned: JSON.stringify(assinned),
    };

    if (validations(data)) {
      await putRequest({ url: `users/${idUser}`, body: data });
      loadUsers();
      close();
    }
  };

  const selectedModule = (moduleSelected, e) => {
    const { checked } = e.target;
    setModulosAsignados(
      modulosAsignados.map((x) => {
        if (x._id === moduleSelected) {
          return {
            ...x,
            checked,
            submodulos: x.submodulos.map((y) => {
              return {
                ...y,
                checked,
                procesos: y.procesos.map((z) => {
                  return {
                    ...z,
                    checked,
                  };
                }),
              };
            }),
          };
        }
        return x;
      })
    );
  };

  const selectedSubModule = (moduleSelected, subModuleSelected, e) => {
    const { checked } = e.target;
    setModulosAsignados(
      modulosAsignados.map((x) => {
        if (x._id === moduleSelected) {
          return {
            ...x,
            checked: checked ? checked : x.checked,
            submodulos: x.submodulos.map((y) => {
              if (y._id === subModuleSelected) {
                return {
                  ...y,
                  checked,
                  procesos: y.procesos.map((z) => {
                    return {
                      ...z,
                      checked,
                    };
                  }),
                };
              }
              return y;
            }),
          };
        }
        return x;
      })
    );
  };

  const onSelect = (campo, value) => {
    if (campo === 'agenteId') {
      setAgenteId(value);
    }
  };

  const selectedProcess = (moduleSelected, subModuleSelected, processSelected, e) => {
    const { checked } = e.target;
    setModulosAsignados(
      modulosAsignados.map((x) => {
        if (x._id === moduleSelected) {
          return {
            ...x,
            checked: checked ? checked : x.checked,
            submodulos: x.submodulos.map((y) => {
              if (y._id === subModuleSelected) {
                return {
                  ...y,
                  checked: checked ? checked : y.checked,
                  procesos: y.procesos.map((z) => {
                    if (z._id === processSelected) {
                      return {
                        ...z,
                        checked,
                      };
                    }
                    return z;
                  }),
                };
              }
              return y;
            }),
          };
        }
        return x;
      })
    );
  };

  const chunk = (arr, chunkSize) => {
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize));
    return R;
  };

  const renderCheckModules = () => {
    return modulosAsignados.map((mod, keyModule) => {
      const componentsLi = mod.submodulos.map((subModule, keySubModule) => {
        return (
          <Col key={keySubModule} md={3}>
            <ListGroup>
              <ListGroup.Item>
                <div className='checkbox' style={{ margin: '0px', padding: '0px' }}>
                  <label>
                    <input
                      onClick={(e) => selectedSubModule(mod._id, subModule._id, e)}
                      checked={subModule.checked}
                      value={subModule._id}
                      type='checkbox'
                    />
                    &nbsp;
                    <b style={{ fontSize: '17px' }}>{subModule.name}</b>
                  </label>
                </div>
              </ListGroup.Item>
              {subModule.procesos.length > 0 ? (
                <ListGroup.Item>
                  {subModule.procesos.map((process, i) => (
                    <div className='checkbox' key={keySubModule + i} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <label>
                        <input type='checkbox' checked={process.checked} onClick={(e) => selectedProcess(mod._id, subModule._id, process._id, e)} />
                        &nbsp;
                        {process.name}
                      </label>
                    </div>
                  ))}
                </ListGroup.Item>
              ) : null}
            </ListGroup>
          </Col>
        );
      });
      return (
        <Col sm="12" key={keyModule}>
          <Card className='mt-3'>
            <Card.Header role='tab' id='headingOne' style={{ borderBottom: '1px solid #ddd' }}>
              <Card.Title>
                <div className='checkbox' style={{ margin: '0px', padding: '0px' }}>
                  <label>
                    <input onClick={(e) => selectedModule(mod._id, e)} checked={mod.checked} value={mod._id} type='checkbox' />
                    &nbsp;
                    <Icon icon={mod.icon} /> {mod.name}
                  </label>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: '5px 15px' }}>
              {chunk(componentsLi, 4).map((x, i) => (
                <Row key={i}>
                  {x}
                </Row>
              ))}
            </Card.Body>
          </Card>
        </Col>
      );
    });
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.name + '') || isEmpty(params.email + '') || isEmpty(params.password + '') || isEmpty(params.repassword + '')) {
      validate.message = 'Información incompleta: Nombre, Email, Password y Confirmar Password, son obligatorios.';
      validate.success = false;
    } else if (!isEmpty(params.cellPhone + '')) {
      if (!isLength(params.cellPhone + '', { min: 10, max: 10 }) || !isInt(params.cellPhone + '')) {
        validate.message = 'El campo celular no contiene un formato valido.';
        validate.success = false;
      }
    } else if (params.rol === AGENTE) {
      if (!isMongoId(params.agenteId + '')) {
        validate.message = 'El campo Agente relacionado es requerido.';
        validate.success = false;
      }
    } else if (!isEmail(params.email + '')) {
      validate.message = 'El campo email no contiene un formato valido.';
      validate.success = false;
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='pencil' onClick={open} title='Editar' type='primary' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <label htmlFor='name'>Nombre</label>
              <input type='text' className='form-control' name='name' value={name} onChange={(e) => setName(e.target.value)} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup>
              <label htmlFor='cellPhone'>Celular</label>
              <input type='text' className='form-control' name='cellPhone' value={cellPhone} onChange={(e) => setCellPhone(e.target.value)} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup>
              <Label name='rol' title='Agente' />
              <InputGroup>
                <InputGroup.Text>
                  <input type='checkbox' checked={rol} name='rol' onChange={(e) => setRol(e.target.checked)} />
                </InputGroup.Text>
                <SelectAgente name='agenteId' value={agenteId} disabled={!rol} onChange={(value) => onSelect('agenteId', value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup>
              <label htmlFor='email'>Email</label>
              <input type='email' className='form-control input-lgr' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </DivFormGroup>
          </Col>
        </Row>
        <Card.Body>
          <Row>{renderCheckModules()}</Row>
        </Card.Body>
      </CbModal>
    </>
  );
};

EditUser.propTypes = {
  idUser: PropTypes.string.isRequired,
  loadUsers: PropTypes.func.isRequired,
};

export default EditUser;
