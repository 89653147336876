import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { Icon, Loading, RowsNotFound } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { useStoreNotification } from '@stores/catalogs.store';

const GenerarOrdenCompra = ({ pedidoId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [equipos, setEquipos] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };

  const onOpenModal = async (e) => {
    e && e.preventDefault();
    setShowModal(true);
    setIsLoading(true);
    const { data } = await getRequest({ url: `pedido/${pedidoId}` });
    setEquipos(data.detalle.filter((x) => x.cantidad - x.entregado > x.existencia).map((x) => ({ ...x, selected: true })));
    setIsLoading(false);
  };

  const onSolicitar = async () => {
    if (equipos.filter((x) => x.selected).length > 0) {
      const data = {
        detalles: equipos.filter((x) => x.selected),
      };
      await postRequest({ url: `pedidos/solicitud-compra/${pedidoId}`, body: data });
      setShowModal(false);
    } else {
      notification({
        title: 'Información incompleta',
        message: 'Es necesario seleccionar por lo menos un detalle',
        type: 'error',
      });
    }
  };

  const handleClickCheckboxAgregarDetalle = (index, checked, e) => {
    e.stopPropagation();
    setEquipos(equipos.map((x, i) => (i === index ? { ...x, selected: checked } : x)));
  };

  const rows =
    equipos.length > 0 ? (
      equipos.map((x, i) => (
        <tr key={i} onClick={(e) => handleClickCheckboxAgregarDetalle(i, !x.selected, e)} style={{ cursor: 'pointer' }}>
          <td className='text-center'>
            <input type='checkbox' name='equipos[]' checked={x.selected} onChange={(e) => handleClickCheckboxAgregarDetalle(i, !x.selected, e)} />
          </td>
          <td>{x.codigo}</td>
          <td>{x.descripcion.toUpperCase()}</td>
          <td>{x.unidadMedidaId.measure}</td>
          <td>{Number(x.cantidad - x.entregado)}</td>
          <td>{Number(x.existencia)}</td>
          <td>{Number(x.cantidad - x.entregado - x.existencia)}</td>
        </tr>
      ))
    ) : (
      <RowsNotFound message='Sin equipos' colSpan={8} />
    );

  return (
    <Fragment>
      <Button type='button' onClick={onOpenModal} className='btn btn-primary pull-right mr-5'>
        <Icon icon='file' /> Solicitar equipos faltantes
      </Button>
      <Modal size='large' show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading loading={isLoading}>
            <Card>
              <div className='table-responsive'>
                <table className='table table-striped align-middle'>
                  <thead>
                    <tr>
                      <th width='2%'>&nbsp;</th>
                      <th width='5%'>Codigo</th>
                      <th width='45%'>Descripción</th>
                      <th width='5%'>Unidad</th>
                      <th width='5%'>Cantidad Requerida</th>
                      <th width='5%'>Existencia</th>
                      <th width='5%'>Cantidad Solicitada</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </Card>
          </Loading>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-primary pull-right' onClick={onSolicitar}>
            Solicitud
          </Button>
          <Button onClick={closeModal} className='mr-5 pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

GenerarOrdenCompra.propTypes = {
  pedidoId: PropTypes.string.isRequired,
};

export default GenerarOrdenCompra;
