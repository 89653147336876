import { TIPOS_DOMICILIOS } from '@config/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import ModalDomicilios from './ModalDomicilios';

const RowContacto = ({ tipoContactoId, domicilioId }) => {
  const hasValue = (field) => field !== null && field !== '';

  if (domicilioId !== null) {
    const { nombre, monitoreo, calle, exterior, interior, colonia, codigo_postal, municipio, estado, pais, tipo, entre_calles, referencias } =
      domicilioId;

    let entreCalles = '';

    if (hasValue(entre_calles.calle1) && !hasValue(entre_calles.calle2)) {
      entreCalles = entre_calles.calle1;
    }

    if (hasValue(entre_calles.calle1) && hasValue(entre_calles.calle2)) {
      entreCalles = `${entre_calles.calle1} y ${entre_calles.calle2}`;
    }

    if (!hasValue(entre_calles.calle1) && hasValue(entre_calles.calle2)) {
      entreCalles = entre_calles.calle2;
    }

    let ubicacion = '';

    if (hasValue(referencias)) {
      if (entreCalles !== '') {
        ubicacion = (
          <small>
            Entre {entreCalles}
            <br />
            Referencia {referencias}
          </small>
        );
      } else {
        ubicacion = `Referencia ${referencias}`;
      }
    } else if (entreCalles !== '') {
      ubicacion = `Entre ${entreCalles}`;
    }

    return (
      <tr>
        <td>{tipoContactoId && tipoContactoId.tipo}</td>
        <td>
          {tipo === TIPOS_DOMICILIOS.SUCURSAL ? (
            <span className='label label-default'>Sucursal</span>
          ) : (
            <span className='label label-primary'>
              <Icon icon='tag' /> Principal
            </span>
          )}
          {nombre}
        </td>
        <td>{monitoreo}</td>
        <td>
          {calle} {exterior}, {interior},
          <br />
          Colonia {colonia} C.P. {codigo_postal},
          <br />
          {municipio} {estado}, {pais}
        </td>
        <td>{ubicacion}</td>
        <td>
          <ModalDomicilios domicilioId={domicilioId} />
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>{tipoContactoId && tipoContactoId.tipo}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <ModalDomicilios domicilioId={domicilioId} />
        </td>
      </tr>
    );
  }
};

RowContacto.propTypes = {
  tipoContactoId: PropTypes.object.isRequired,
  domicilioId: PropTypes.object,
};

export default RowContacto;
