import { FORZOSO } from '@config/constants';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteCondicionesPagoServicios from './DeleteCondicionesPagoServicios';
import EditCondicionesPagoServicios from './EditCondicionesPagoServicios';

const ListCondicionesPagoServicios = ({ catalogCondicionesPagoServicios }) => {
  const renderRows = () => {
    return catalogCondicionesPagoServicios.map((row, item) => {
      const { _id, tipo, plazo } = row;

      return (
        <tr key={item}>
          <td>
            {tipo === FORZOSO ? (
              <span>
                <strong>Forzoso</strong> a <strong>{plazo}</strong> meses
              </span>
            ) : (
              <strong>Indeterminado</strong>
            )}
          </td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteCondicionesPagoServicios
                id={item}
                condicionespagoserviciosId={_id}
                tipo={tipo}
                plazo={plazo}
              />
            </IfPermission>
            <IfPermission action='editar'>
              <EditCondicionesPagoServicios id={item} condicionespagoserviciosId={_id}  />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Condición</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListCondicionesPagoServicios.propTypes = {
  catalogCondicionesPagoServicios: PropTypes.array.isRequired,
};

export default ListCondicionesPagoServicios;
