import Confirmation from '@controls/Confirmation';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Edit from './Edit';
import { ButtonGroup } from 'react-bootstrap';

const List = ({ list, load }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, name, address } = row;
      return (
        <tr key={item}>
          <td>{name}</td>
          <td>{address}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <Edit id={item} sucursalId={_id} load={load}/>
              </IfPermission>
              <IfPermission action='eliminar'>
                <Confirmation
                  btnIcon='trash'
                  typeRequest='deleteRequest'
                  btnType='outline-danger btn-sm'
                  action='Eliminar'
                  url={`sucursales/${_id}`}
                  description={`¿Confirma que desea eliminar la sucursal <strong><u> ${name}</u> </strong>?`}
                  loadData={load}
                />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Nombre</th>
          <th width='30%'>Domicilio</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
