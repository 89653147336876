import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import Delete from './Delete';
import Edit from './Edit';
import { Card } from 'react-bootstrap';

const List = ({ list, load }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, codigo, nombre } = row;
      return (
        <tr key={item}>
          <td>{codigo}</td>
          <td>{nombre}</td>
          <td>
            <Delete id={item} grupoEmpleadoId={_id} codigo={codigo} {...{ list, load }} />
            <Edit id={item} grupoEmpleadoId={_id} {...{ list, load }} />
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={3} />;

  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='20%'>Código</th>
              <th width='30%'>Nombre</th>
              <th width='20%'></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </Card>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
