import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import FormAgente from './FormAgente';
import { useStoreNotification } from '@stores/catalogs.store.js';

const EditAgentes = ({ ...props }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [maximoDescuento, setMaximoDescuento] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [recibePedidos, setRecibePedidos] = useState(false);
  const [recibeServicios, setRecibeServicios] = useState(false);
  const [ocupaAutorizacion, setOcupaAutorizacion] = useState(false);
  const [esTecnico, setEsTecnico] = useState(false);
  const [sucursalId, setSucursalId] = useState(null);
  const [almacenId, setAlmacenId] = useState(null);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setNombre(props.nombre);
    setCodigo(props.codigo);
    setMaximoDescuento(props.maximo_descuento);
    setRecibePedidos(props.recibe_pedidos);
    setRecibeServicios(props.recibe_servicios);
    setEsTecnico(props.es_tecnico);
    setOcupaAutorizacion(props.ocupa_autorizacion);
    setSucursalId(props.sucursalId);
    setAlmacenId(props.almacenId);
    setIsLoading(false);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { agenteId, loadAgentes } = props;
    const data = {
      codigo: codigo.trim(),
      nombre: nombre.trim().toUpperCase(),
      sucursalId: sucursalId?._id,
      almacenId: almacenId?._id,
      maximo_descuento: maximoDescuento,
      recibe_pedidos: recibePedidos,
      recibe_servicios: recibeServicios,
      es_tecnico: esTecnico,
      ocupa_autorizacion: ocupaAutorizacion,
    };

    if (validations(data)) {
      const { error } = await putRequest({ url: `agentes/${agenteId}`, body: data });
      if (error) return;
      loadAgentes();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver title='Editar' onClick={open} icon='pencil' />
      <CbModal show={showModal} title='Editar agente' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <FormAgente
          nombre={nombre}
          codigo={codigo}
          maximo_descuento={maximoDescuento}
          recibe_pedidos={recibePedidos}
          recibe_servicios={recibeServicios}
          es_tecnico={esTecnico}
          ocupa_autorizacion={ocupaAutorizacion}
          sucursalId={sucursalId}
          almacenId={almacenId}
          setState={(state) => {
            setNombre(state.nombre);
            setCodigo(state.codigo);
            setMaximoDescuento(state.maximo_descuento);
            setRecibePedidos(state.recibe_pedidos);
            setRecibeServicios(state.recibe_servicios);
            setEsTecnico(state.es_tecnico);
            setOcupaAutorizacion(state.ocupa_autorizacion);
            setSucursalId(state.sucursalId);
            setAlmacenId(state.almacenId);
          }}
        />
      </CbModal>
    </>
  );
};

EditAgentes.propTypes = {
  id: PropTypes.number.isRequired,
  agenteId: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  maximo_descuento: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  loadAgentes: PropTypes.func.isRequired,
  sucursales: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  recibe_pedidos: PropTypes.bool.isRequired,
  recibe_servicios: PropTypes.bool.isRequired,
  es_tecnico: PropTypes.bool.isRequired,
  ocupa_autorizacion: PropTypes.bool.isRequired,
};

export default EditAgentes;
