import { DOLAR, ENCABEZADOS, EURO, PESO } from '@config/constants';
import { DivFormGroup } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Icon, Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import { Button, Card, Col, FormGroup, InputGroup, Row } from 'react-bootstrap';
import RowComponent from './components/Row';
import SelectCliente from '@components/forms/SelectCliente';
import SelectAgente from '@components/forms/SelectAgente';
import InputPercentage from '@components/forms/InputPercentage';

const pagination = 25;

const List = () => {
  const [state, setState] = useState({
    total: 0,
    saldo: 0,
    clientes: 0,
    list: [],
    loading: true,
    activePage: 1,
    pagination: 20,
    count: 0,
    dias: '',
    desde: '',
    hasta: '',
    moneda: PESO,
    secciones: [],
    searching: false,
    encabezados: {
      activePageD1_30: 1,
      activePageD31_60: 1,
      activePageD61_90: 1,
      activePageD91_120: 1,
      activePageD121: 1,
    },
    agenteName: '',
    agente: null,
    agenteObj: null,
    clienteName: '',
    clienteObj: null,
    cliente: null,
  });

  const frm = useRef(null);

  const getEncabezados = useCallback(() => {
    const list = [];
    if (frm.current['encabezado-1'].checked) {
      list.push(ENCABEZADOS.D1_30);
    }
    if (frm.current['encabezado-2'].checked) {
      list.push(ENCABEZADOS.D31_60);
    }
    if (frm.current['encabezado-3'].checked) {
      list.push(ENCABEZADOS.D61_90);
    }
    if (frm.current['encabezado-4'].checked) {
      list.push(ENCABEZADOS.D91_120);
    }
    if (frm.current['encabezado-5'].checked) {
      list.push(ENCABEZADOS.D121);
    }

    return list;
  }, []);

  const loadData = useCallback(async () => {
    const { pagination, activePage, dias, desde, hasta, moneda, agente, cliente } = state;
    const page = activePage * pagination - pagination;
    const encabezados = getEncabezados();
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      dias: isNaN(parseInt(dias)) ? 0 : parseInt(dias),
      desde,
      hasta,
      moneda,
      encabezados,
      agenteId: agente ? agente._id : null,
      customerId: cliente ? cliente._id : null,
    };

    const { data, count, total, saldo, clientes, filtros } = await postRequest({
      url: `cartera-vencida`,
      params: { page, limit: pagination },
      body,
    });

    setState((prevState) => ({
      ...prevState,
      list: data,
      count,
      total,
      saldo,
      clientes,
      secciones: filtros,
      sources: filtros,
      loading: false,
      searching: false,
    }));
  }, [state.pagination, state.activePage, state.dias, state.desde, state.hasta, state.moneda, state.agente, state.cliente]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleFilter = () => {
    setState((prevState) => ({
      ...prevState,
      activePage: 1,
      encabezados: {
        activePageD1_30: 1,
        activePageD31_60: 1,
        activePageD61_90: 1,
        activePageD91_120: 1,
        activePageD121: 1,
      },
      searching: true,
    }));
    loadData();
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
    loadData();
  };

  const paginationEncabezado = ({ encabezado, source }, eventKey) => {
    const { encabezados, secciones } = state;
    if (encabezado === ENCABEZADOS.D1_30) {
      encabezados.activePageD1_30 = eventKey;
    } else if (encabezado === ENCABEZADOS.D31_60) {
      encabezados.activePageD31_60 = eventKey;
    } else if (encabezado === ENCABEZADOS.D61_90) {
      encabezados.activePageD61_90 = eventKey;
    } else if (encabezado === ENCABEZADOS.D91_120) {
      encabezados.activePageD91_120 = eventKey;
    } else if (encabezado === ENCABEZADOS.D121) {
      encabezados.activePageD121 = eventKey;
    }

    const page = eventKey * pagination - pagination;
    const listPaginado = source.slice(page, page + pagination);

    secciones.map((s, i) => {
      if (s.encabezado === encabezado) {
        secciones[i].list = listPaginado;
      }
    });

    setState((prevState) => ({ ...prevState, encabezados, secciones }));
  };

  const onChange = (event) => {
    const { name, value, checked, type } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const renderList = () => {
    const { total, saldo, count, clientes, secciones, activePage, list, loading } = state;
    return (
      <RowComponent
        total={total}
        saldo={saldo}
        count={count}
        clientes={clientes}
        list={list}
        loading={loading}
        activePage={activePage}
        pagination={pagination}
        handlePagination={handlePagination}
        secciones={secciones}
        paginationEncabezado={paginationEncabezado}
        handleExcelEncabezado={handleExcelEncabezado}
        handleExcel={handleExcel}
      />
    );
  };

  const handleExcel = async () => {
    const { file } = await postRequest({ url: `equipments/exportar` });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const handleExcelEncabezado = async (encabezado) => {
    const { dias, desde, hasta, moneda } = state;
    const body = {
      dias: isNaN(parseInt(dias)) ? 0 : parseInt(dias),
      desde,
      hasta,
      moneda,
      encabezado,
    };
    const { file } = await postRequest({ url: `cartera-vencida-excel`, body });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState(
        (prevState) => ({
          ...prevState,
          agenteObj: itemSelect,
          agente: itemSelect,
        }),
        loadData
      );
    } else {
      setState((prevState) => ({ ...prevState, agenteName: '', agenteObj: null, agente: null }), loadData);
    }
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      setState(
        (prevState) => ({
          ...prevState,
          clienteObj: itemSelect,
          cliente: itemSelect,
        }),
        loadData
      );
    } else {
      setState((prevState) => ({ ...prevState, clienteName: '', clienteObj: null, cliente: null }), loadData);
    }
  };

  const { searching, dias, desde, hasta, moneda, agenteObj, clienteObj } = state;

  return (
    <Module title='Cartera Vencida'>
      <form ref={frm}>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm='3'>
                    <DivFormGroup name='clienteName' title='Cliente'>
                      <SelectCliente value={clienteObj} onChange={onChangeCliente} name='cliente' />
                    </DivFormGroup>
                  </Col>
                  <Col sm='3'>
                    <DivFormGroup name='agenteName' title='Agente'>
                      <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agente' />
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='dias' title='Días de vencimiento'>
                      <InputGroup>
                        <InputPercentage name='dias' className='form-control' value={dias} onChange={onChange} />
                        <InputGroup.Text>
                          <Icon icon='exclamation-sign' />
                        </InputGroup.Text>
                      </InputGroup>
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='desde' title='Desde'>
                      <input
                        className='form-control'
                        name='desde'
                        id='desde'
                        type='date'
                        defaultValue={desde !== '' && desde !== null ? moment(desde).format('YYYY-MM-DD') : null}
                        onChange={onChange}
                      />
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='hasta' title='Hasta'>
                      <input
                        className='form-control'
                        name='hasta'
                        id='hasta'
                        type='date'
                        defaultValue={hasta !== '' && hasta !== null ? moment(hasta).format('YYYY-MM-DD') : null}
                        onChange={onChange}
                      />
                    </DivFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='4'>
                    <label>Moneda</label>
                    <FormGroup className='inline'>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-peso' onChange={onChange} value={PESO} checked={moneda === PESO ? true : false} />{' '}
                        <strong>Pesos</strong>
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          id='rdo-dolar'
                          onChange={onChange}
                          value={DOLAR}
                          checked={moneda === DOLAR ? true : false}
                        />{' '}
                        <strong>Dólares</strong>
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-euro' onChange={onChange} value={EURO} checked={moneda === EURO ? true : false} />{' '}
                        <strong>Euros</strong>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm='7'>
                    <label>Encabezados</label>
                    <FormGroup className='inline'>
                      <label className='radio-inline'>
                        <input type='checkbox' name='encabezado-1' id='encabezado-1' onChange={onChange} value={ENCABEZADOS.D1_30} />{' '}
                        <strong>1 - 30 días</strong>
                      </label>
                      <label className='radio-inline'>
                        <input type='checkbox' name='encabezado-2' id='encabezado-2' onChange={onChange} value={ENCABEZADOS.D31_60} />{' '}
                        <strong>31 - 60 días</strong>
                      </label>
                      <label className='radio-inline'>
                        <input type='checkbox' name='encabezado-3' id='encabezado-3' onChange={onChange} value={ENCABEZADOS.D61_90} />{' '}
                        <strong>61 - 90 días</strong>
                      </label>
                      <label className='radio-inline'>
                        <input type='checkbox' name='encabezado-4' id='encabezado-4' onChange={onChange} value={ENCABEZADOS.D91_120} />{' '}
                        <strong>91 - 120 días</strong>
                      </label>
                      <label className='radio-inline'>
                        <input type='checkbox' name='encabezado-5' id='encabezado-5' onChange={onChange} value={ENCABEZADOS.D121} />{' '}
                        <strong>+121 días</strong>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm='1'>
                    <Button onClick={handleFilter} className='btn btn-primary pull-right' type='button'>
                      <Icon icon='search' /> Buscar
                    </Button>
                  </Col>
                </Row>
                <Loading loading={searching} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
      {renderList()}
    </Module>
  );
};

export default List;
