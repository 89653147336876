import { RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';
import { ButtonGroup } from 'react-bootstrap';

const List = ({ list, ...props }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, customerId, coloniaId, nombre, calle, exterior, interior, colonia, municipio, estado, pais, codigo_postal, entre_calle } = row;
      return (
        <tr key={item}>
          <td>
            <Link to={`/clientes/detalle/${customerId._id}`} target='_blank' rel='noreferrer'>
              {customerId ? customerId.razon_social : null}
            </Link>
            {customerId && customerId.nombre_comercial && (
              <span style={{ fontSize: '10px', fontWeight: 700 }}>
                {' '}
                <br /> {customerId.nombre_comercial}
              </span>
            )}
          </td>
          <td>{nombre}</td>
          <td>
            {calle} {exterior} {interior && `, INT: ${interior}`}
            <br />
            {entre_calle && entre_calle.calle1}
            {entre_calle && entre_calle.calle2}
          </td>
          <td>
            {coloniaId && '*'} {colonia} C.P. {codigo_postal}
          </td>
          <td>{municipio}</td>
          <td>
            {estado}, {pais}
          </td>
          <td>
            <ButtonGroup>
              <Delete id={item} domicilioId={_id} nombre={nombre} {...props} />
              <Edit id={item} domicilioId={_id} {...row} {...props} />
              <RedirectTooltip
                id={1}
                url={`/clientes/detalle/${customerId._id}`}
                labelTooltip='Clientes'
                icon='user'
                className='btn btn-outline-primary'
              />
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Cliente</th>
          <th>Nombre domicilio</th>
          <th>Calle y numero</th>
          <th>Colonia</th>
          <th>Municipio</th>
          <th>Estado</th>
          <th width='15%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  tiposContactos: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default List;
