import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, DropdownButton, DropdownItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CompletarOrdenDeCompra from './CompletarOrdenDeCompra';
import IconDropdown from '@controls/IconDropdown';

const ESTATUS_SOLICITUDES = {
  INICIADA: 'INICIADA',
  EN_PROCESO: 'EN PROCESO',
  RECHAZADA: 'RECHAZADA',
  ACEPTADA: 'ACEPTADA',
  CANCELADA: 'CANCELADA',
  TERMINADA: 'TERMINADA',
};

const Table = ({ list, loadData, onChangeList }) => {
  const estatus_label = (estatus_solicitud) => {
    const badgeClasses = {
      CANCELADA: 'danger',
      ACEPTADA: 'info',
      RECHAZADA: 'warning',
      TERMINADA: 'secondary',
      INICIADA: 'primary',
      EN_PROCESO: 'success',
    };

    const badgeClass = badgeClasses[estatus_solicitud] || 'primary';
    return <CbBadge type={badgeClass}>{ESTATUS_SOLICITUDES[estatus_solicitud]}</CbBadge>;
  };

  const onClickDownloadPdf = async (id, e) => {
    e.preventDefault();
    const { data } = await getRequest({ url: `solicitud-compra/pdf/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const onChange = (indexi, indexj, check) => {
    onChangeList([
      ...list.map((x, i) => {
        if (indexi === i) {
          return {
            ...x,
            detalles: [
              ...x.detalles.map((y, j) => {
                if (indexj === j) {
                  return {
                    ...y,
                    check: check,
                  };
                }
                return y;
              }),
            ],
          };
        }
        return x;
      }),
    ]);
  };

  const onClickMostrar = (indexi) => {
    onChangeList([
      ...list.map((x, i) => {
        if (indexi === i) {
          return {
            ...x,
            mostrar: !x.mostrar,
          };
        }
        return x;
      }),
    ]);
  };

  const renderRows = () => {
    return list.map((r, i) => {
      const { fecha, numero_solicitud, sucursal, estatus_solicitud, agente, _id, compraId, detalles, mostrar } = r;
      return (
        <React.Fragment key={i}>
          <tr onClick={() => onClickMostrar(i)} style={{ cursor: 'pointer' }}>
            <td style={{ background: '#efefef', padding: '5px 5px' }}>
              <b>{numero_solicitud}</b>
            </td>
            <td style={{ background: '#efefef', padding: '5px 5px' }}>{moment(fecha).format('DD/MM/YYYY')}</td>
            <td style={{ background: '#efefef', padding: '5px 5px' }}> {sucursal ? sucursal.name : null} </td>
            <td style={{ background: '#efefef', padding: '5px 5px' }}> {agente ? agente.nombre : null} </td>
            <td style={{ background: '#efefef', padding: '5px 5px' }}>{estatus_label(estatus_solicitud)}</td>
            <td style={{ background: '#efefef', padding: '5px 5px' }}>
              <div className='btn-group pull-right' role='group' aria-label='...'>
                <Button type='button' onClick={() => onClickMostrar(i)} className='btn btn-sm btn-outline-success '>
                  <Icon icon={!mostrar ? 'triangle-right' : 'triangle-bottom'} />
                </Button>
                <OverlayTrigger placement='top' overlay={<Tooltip id={`${_id}-download`}>Descargar PDF</Tooltip>}>
                  <Button type='button' onClick={(e) => onClickDownloadPdf(_id, e)} className='btn btn-sm btn-outline-dark '>
                    <Icon icon='download-alt' />
                  </Button>
                </OverlayTrigger>
                <If condition={(estatus_solicitud === 'INICIADA' || estatus_solicitud === 'EN_PROCESO') && estatus_solicitud !== 'CANCELADA'}>
                  <If.Then>
                    <RedirectTooltip
                      id={2}
                      icon='edit'
                      url={`/solicitudes-compras/edicion/${_id}`}
                      labelTooltip='Editar'
                      className='btn btn-sm btn-outline-primary'
                    />
                  </If.Then>
                </If>
                <DropdownButton title={<IconDropdown />}>
                  <If condition={estatus_solicitud !== 'TERMINADA' && estatus_solicitud !== 'CANCELADA'}>
                    <If.Then>
                      <CompletarOrdenDeCompra solicitudId={_id} loadData={loadData} />
                    </If.Then>
                  </If>
                  <IfPermission action='detalle'>
                    <DropdownItem href={`/solicitudes-compras/detalle/${_id}`}>
                      <Icon icon='search' /> Detalles
                    </DropdownItem>
                  </IfPermission>
                  <If condition={(estatus_solicitud === 'INICIADA' || estatus_solicitud === 'EN_PROCESO') && estatus_solicitud !== 'CANCELADA'}>
                    <If.Then>
                      <Confirmation
                        id={_id}
                        btnIcon='remove'
                        className='btn btn-sm btn-danger mr-5'
                        typeConfirm='danger'
                        btnType='danger'
                        action='Cancelar'
                        isMenu
                        url={`solicitudes-compras/cancelar/${_id}`}
                        description={`¿Confirma que desea cancelar la <strong>solicitud de compra:<u> #${numero_solicitud}</u></strong>?`}
                        loadData={loadData}
                      />
                    </If.Then>
                  </If>
                  <If condition={(estatus_solicitud === 'INICIADA' || estatus_solicitud === 'EN_PROCESO') && estatus_solicitud !== 'CANCELADA'}>
                    <If.Then>
                      <Confirmation
                        id={_id}
                        btnIcon='ok'
                        className='btn btn-sm btn-info mr-5'
                        typeConfirm='info'
                        btnType='info'
                        action='Aceptar'
                        isMenu
                        url={`solicitudes-compras/aceptar/${_id}`}
                        description={`¿Confirma que desea aceptar la <strong>solicitud de compra:<u> #${numero_solicitud}</u></strong>?`}
                        loadData={loadData}
                      />
                    </If.Then>
                  </If>
                  <If condition={(estatus_solicitud === 'INICIADA' || estatus_solicitud === 'EN_PROCESO') && estatus_solicitud !== 'CANCELADA'}>
                    <If.Then>
                      <Confirmation
                        id={_id}
                        btnIcon='ban-circle'
                        className='btn btn-sm btn-default mr-5'
                        typeConfirm='default'
                        btnType='default'
                        action='Rechazar'
                        isMenu
                        url={`solicitudes-compras/rechazar/${_id}`}
                        description={`¿Confirma que desea rechazar la <strong>solicitud de compra:<u> #${numero_solicitud}</u></strong>?`}
                        loadData={loadData}
                      />
                    </If.Then>
                  </If>
                  <If condition={compraId}>
                    <If.Then>
                      <DropdownItem href={`/compras/edicion/${compraId}`}>
                        <Icon icon='edit' /> Compra
                      </DropdownItem>
                    </If.Then>
                  </If>
                </DropdownButton>
              </div>
            </td>
          </tr>
          {mostrar && (
            <tr>
              <td colSpan='6' style={{ padding: '0px', verticalAlign: 'middle' }}>
                <table className='table table-striped table-condenced table-hover' style={{ margin: '0px' }}>
                  <thead>
                    <tr>
                      <th width='5%' style={{ padding: '0px 5px' }}>
                        &nbsp;
                      </th>
                      <th width='5%' style={{ padding: '0px 5px' }}>
                        &nbsp;
                      </th>
                      <th width='5%' style={{ padding: '0px 5px' }}>
                        #
                      </th>
                      <th width='20%' style={{ padding: '0px 5px' }}>
                        Descripción
                      </th>
                      <th width='60%' style={{ padding: '0px 5px' }}>
                        Cantidad
                      </th>
                      <th width='5%' style={{ padding: '0px 5px' }}>
                        Unidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detalles &&
                      detalles.map((detalle, j) => (
                        <tr
                          key={j}
                          onClick={(estatus_solicitud === 'INICIADA' || estatus_solicitud === 'EN_PROCESO') && (() => onChange(i, j, !detalle.check))}
                          style={{ cursor: 'pointer' }}
                        >
                          <td style={{ padding: '2px 5px' }} width='5%'>
                            &nbsp;
                          </td>
                          <td style={{ padding: '2px 5px' }} width='5%'>
                            {(estatus_solicitud === 'INICIADA' || estatus_solicitud === 'EN_PROCESO') && (
                              <input type='checkbox' name='check' checked={detalle.check} onChange={() => onChange(i, j, !detalle.check)} />
                            )}
                          </td>
                          <td style={{ padding: '2px 5px' }} width='20%'>
                            {detalle.codigo}
                          </td>
                          <td style={{ padding: '2px 5px' }} width='60%'>
                            {detalle.descripcion}
                          </td>
                          <td style={{ padding: '2px 5px' }} width='5%'>
                            <b>{detalle.cantidad}</b>
                          </td>
                          <td style={{ padding: '2px 5px' }} width='5%'>
                            {detalle.unidadMedida}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='15%'># Solicitud </th>
          <th width='20%'>Fecha</th>
          <th>Sucursal</th>
          <th>Agente</th>
          <th width='10%'>Estatus</th>
          <th></th>
        </tr>
      </thead>
      <CbTableBody colSpan={6}>{rows}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,

  loadData: PropTypes.func.isRequired,
  onChangeList: PropTypes.func.isRequired,
};

export default Table;
