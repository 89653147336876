import React, { useEffect, useState } from 'react';
import { Modal, Button, DropdownItem } from 'react-bootstrap';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';
import { DivFormGroup } from '@controls';
import { Row, Col, InputGroup } from 'react-bootstrap';
import { getReferencia } from '@utils/cotizaciones';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import { Input } from '@controls';
import NewCRM from '@pages/clientes/cotizaciones/NewCRM';
import EditCRM from '@pages/clientes/cotizaciones/EditCRM';
import SelectTipoManoDeObra from '@components/forms/SelectTipoManoDeObra';
import SelectTipoCobroEquipo from '@components/forms/SelectTipoCobroEquipo';
// import MultiSelectSolucionesSolicitadas from '@components/forms/MultiSelectSolucionesSolicitadas';
import MultiSelectTiposServiciosSolicitados from '@components/forms/MultiSelectTiposServiciosSolicitados';
import { getRequest, postRequest } from '@utils/api';
import SelectCuentaMonitoreoCliente from '@components/forms/SelectCuentaMonitoreoCliente';
import SelectCRMCliente from '@components/forms/SelectCRMCliente';

const GeneradorReferencia = ({ refresh, id }) => {
    const { addNotification: notification } = useStoreNotification();
    const [tipos_mano_obra_id, setTiposManoObraId] = useState(null);
    const [tipos_cobro_equipo_id, setTiposCobroEquipoId] = useState(null);
    const [tipos_servicios_solicitados_id, setTiposServiciosSolicitadosId] = useState([]);
    const [horas, setHoras] = useState(0);
    const [clienteRegistro, setClienteRegistro] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [agente, setAgente] = useState(null);
    const [agente2, setAgente2] = useState(null);
    const [numero_cotizacion, setNumeroCotizacion] = useState('');
    const [cuentaMonitoreo, setCuentaMonitoreo] = useState(null);
    const [show, setShow] = useState(false);
    const [cliente, setCliente] = useState(null);

    const fetchCotizacion = async (id) => {
        const { data: cotizacion } = await getRequest({ url: `cotizaciones/${id}` });
        if (!cotizacion) return;
        setCliente(cotizacion?.customer);
        if (cotizacion.referencia_id) {
            const { data: referencia } = await getRequest({ url: `cotizaciones/referencia/${id}` });
            if (!referencia) return;
            setTiposManoObraId(referencia.tipos_mano_obra_id);
            setTiposCobroEquipoId(referencia.tipos_cobro_equipo_id);
            setTiposServiciosSolicitadosId(referencia.tipos_servicios_solicitados_id);
            setHoras(referencia.horas);
            setClienteRegistro(referencia.cliente_registro_id);
            setDescripcion(referencia.descripcion);
            setAgente(referencia.agente_id);
            setAgente2(referencia.agente_compartido_id);
            setNumeroCotizacion(cotizacion.numero_levantamiento);
            setCuentaMonitoreo(referencia.cuenta_monitoreo_id);
        } else {
            const { cuenta_monitoreo, detalles_servicios, customer_registro } = await getRequest({
                url: `cotizaciones/infopedidoservicio/${id}/null`,
            });
            setAgente(cotizacion.agente);
            setAgente2(cotizacion.agente);
            setHoras(detalles_servicios.map((x) => x.cantidad).reduce((s, a) => s + a, 0));
            setNumeroCotizacion(cotizacion.numero_levantamiento);
            setClienteRegistro(customer_registro);
            setCuentaMonitoreo(cuenta_monitoreo);
        }
    };

    useEffect(() => {
        if (id && show) {
            fetchCotizacion(id);
        }
    }, [id, show]);


    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        const { data } = postRequest({
            url: `cotizaciones/referencia/${id}`,
            body: {
                tipos_mano_obra_id: tipos_mano_obra_id?._id,
                tipos_cobro_equipo_id: tipos_cobro_equipo_id?._id,
                tipos_servicios_solicitados_id: tipos_servicios_solicitados_id.map((x) => x._id),
                horas,
                cliente_registro_id: clienteRegistro?._id,
                agente_id: agente?._id,
                agente_compartido_id: agente2?._id,
                numero_cotizacion,
                cuenta_monitoreo_id: cuentaMonitoreo?._id,
                descripcion,
            },
        });
        if (data) {
            handleClose();
            refresh();
        }
    };


    const referencia = getReferencia({
        tipos_mano_obra: tipos_mano_obra_id?.clave,
        tipo_servicio_solicitado: tipos_servicios_solicitados_id.length > 0 ? tipos_servicios_solicitados_id[0]?.clave : '',
        tipos_cobro_equipo: tipos_cobro_equipo_id?.clave,
        monitoreo: cuentaMonitoreo?.numero,
        folio: clienteRegistro?.folio,
        descripcion,
        horas,
        numero_levantamiento: numero_cotizacion,
        agente: agente?.codigo,
        agente_compartido: agente2?.codigo,
      });
    
      const handleCopiar = (e) => {
        e.preventDefault();
        copyToClipboard(referencia);
        notification({
          message: '¡Referencia copiada con éxito!',
          type: 'success',
        });
      };
    
      const copyToClipboard = (str) => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
      };

    return (
        <>
           <DropdownItem onClick={handleShow}>
                <Icon icon='pencil' /> Generar Referencia
            </DropdownItem>
            <Modal show={show} onHide={handleClose} size='lg' fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Referencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <DivFormGroup name='tipos_mano_obra_id' required title='Cobro mano de obra'>
                                <SelectTipoManoDeObra value={tipos_mano_obra_id} onChange={(value) => setTiposManoObraId(value)} name='tipos_mano_obra_id' />
                            </DivFormGroup>
                        </Col>
                        <Col sm={3}>
                            <DivFormGroup name='tipos_cobro_equipo_id' required title='Cobro equipo'>
                                <SelectTipoCobroEquipo
                                    value={tipos_cobro_equipo_id}
                                    onChange={(value) => setTiposCobroEquipoId(value)}
                                    name='tipos_cobro_equipo_id'
                                />
                            </DivFormGroup>
                        </Col>
                        <Col sm={3}>
                            <DivFormGroup name='numero_cotizacion' required title='Numero cotización'>
                                <Input
                                    name='numero_cotizacion'
                                    value={numero_cotizacion}
                                    placeholder='Numero cotización'
                                    onChange={(e) => setNumeroCotizacion(e.target.value)}
                                />
                            </DivFormGroup>
                        </Col>
                        <Col sm={3}>
                            <DivFormGroup name='agente' required title='Agente principal'>
                                <SelectAgente
                                    value={agente}
                                    name='agente'
                                    onChange={(value) => {
                                        setAgente(value);
                                        setAgente2(value);
                                    }}
                                />
                            </DivFormGroup>
                        </Col>
                        <Col sm={4}>
                            <DivFormGroup name='agente' required title='Agente compartido'>
                                <SelectAgente value={agente2} name='agente2' onChange={(value) => setAgente2(value)} />
                            </DivFormGroup>
                        </Col>
                        <Col sm={4}>
                            <DivFormGroup name='tipos_servicios_solicitados_id' required title='Tipos servicios solicitados'>
                                <MultiSelectTiposServiciosSolicitados
                                    name='tipos_servicios_solicitados_id'
                                    value={tipos_servicios_solicitados_id}
                                    onChange={(value) => setTiposServiciosSolicitadosId(value)}
                                />
                            </DivFormGroup>
                        </Col>
                        <Col sm={4}>
                            <DivFormGroup name='horas' title='Horas cotizadas' required>
                                <Input name='horas' value={horas} placeholder='horas' onChange={(e) => setHoras(e.target.value)} />
                            </DivFormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <DivFormGroup name='registroclienteName' required title='Registro de cliente'>
                                <InputGroup>
                                    <SelectCRMCliente
                                        customerId={cliente?._id}
                                        domicilioId={cliente?.domicilio?._id}
                                        onChange={(value) => setClienteRegistro(value)}
                                        name='clienteRegistro'
                                        value={clienteRegistro}
                                    />
                                    {!clienteRegistro ? (
                                        <NewCRM refresh={(value) => setClienteRegistro(value)} />
                                    ) : (
                                        <EditCRM id={clienteRegistro?._id} refresh={(value) => setClienteRegistro(value)} />
                                    )}
                                </InputGroup>
                            </DivFormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <DivFormGroup name='cuentaMonitoreo' required title='Cuenta monitoreo'>
                                <SelectCuentaMonitoreoCliente
                                    customerId={cliente?._id}
                                    onChange={(value) => setCuentaMonitoreo(value)}
                                    name='cuentaMonitoreo'
                                    value={cuentaMonitoreo}
                                />
                            </DivFormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <DivFormGroup name='descripcion' required title='Oportunidad / Observaciones'>
                                <Input name='descripcion' value={descripcion} placeholder='Descripción corta' onChange={(e) => setDescripcion(e.target.value)} />
                            </DivFormGroup>
                        </Col>
                        <Col sm={12}>
                            <DivFormGroup name='referencia' title='Referencia'>
                                <InputGroup>
                                <Button variant='outline-warning' onClick={handleCopiar}>
                                    <Icon icon='copy' />
                                </Button>
                                <Input name='referencia' value={referencia} placeholder='Referencia' style={{ fontSize: '20px' }} />
                                </InputGroup>
                            </DivFormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

GeneradorReferencia.propTypes = {
    refresh: PropTypes.func.isRequired,
    id: PropTypes.string,
};


export default GeneradorReferencia;