import PropTypes from 'prop-types';
import React from 'react';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import TextDateDiff from '@controls/TextDateDiff';
import TextDate from '@controls/TextDate';

const List = ({ list }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const {
        fecha_inicio,
        fecha_fin,
        tipo_solicitud,
        estatus,
        created
      } = row;
      return (
        <tr key={item}>
            <td>
            <TextDate format='YYYY-MM-DD HH:mm'>{fecha_inicio}</TextDate> - <TextDate format='YYYY-MM-DD HH:mm'>{fecha_fin}</TextDate>
            </td>
            <td>{tipo_solicitud}</td>
            <td>
                <CbBadge type={'secondary'}>{estatus}</CbBadge>
            </td>
            <td><TextDateDiff>{created}</TextDateDiff></td>
            <td></td>  
        </tr>
      );
    });
  };
  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Fechas</th>
          <th width='10%'>Tipo solicitud</th>
          <th width='10%'>Estatus</th>
          <th width='10%'>Creado</th>
          <th width='10%'>Acciones</th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default List;
