import React, { useEffect, useState } from 'react';
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { DivFormGroup, FormInput } from '@controls';
import EquiposBuscadorProducto from './EquiposBuscadorProducto';
import Select from 'react-select';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { useStoreMarcas, useStoreClasificacionesCotizaciones } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const ModalBuscadorProducto = ({ show, onClose, monedaId, tipoCambio, onSelectProducto }) => {
  const [clasificacionCotizacionId, setClasificacionCotizacionId] = useState('');
  const [marcaId, setMarcaId] = useState('');
  const [search, setSearch] = useState('');
  const [inList, setInList] = useState('');
  const [loading, setLoading] = useState(false);
  const [equipos, setEquipos] = useState([]);
  const [count, setCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pagination] = useState(20);
  const [skip, setSkip] = useState(1);
  const getStoreMarcas = useStoreMarcas();
  const getStoreClasificacionesCotizaciones = useStoreClasificacionesCotizaciones();
  let timeout = 0;
  useEffect(() => {
    getStoreMarcas.execute();
    getStoreClasificacionesCotizaciones.execute();
  }, []);
  useEffect(() => {
    handleSearch();
  }, [clasificacionCotizacionId, marcaId, activePage]);

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!loading && (search.toString().length === 0 || search.toString().length >= 3)) {
        handleSearch();
      }
    }, 800);
  };

  const onSelect = (name, value) => {
    if (name === 'clasificacionCotizacionId') {
      setClasificacionCotizacionId(value);
    }
    if (name === 'marcaId') {
      setMarcaId(value);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const body = {
      search,
      clasificacionCotizacionId: clasificacionCotizacionId?._id,
      marcaId: marcaId?._id,
    };
    const { data, count } = await postRequest({ url: `equipments-search`, params: { page: skip, limit: pagination }, body });
    if (data) {
      setEquipos(data.map((x) => ({ ...x, tipo_partida: 'EQUIPO' })));
      setCount(count);
    }
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    setSkip(eventKey * pagination - pagination);
  };

  const onClickEquipo = (producto) => {
    onSelectProducto(producto);
  };

  return (
    <Modal show={show} onHide={onClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Equipos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={2}>
            <DivFormGroup name='clasificacionCotizacionId' title='Linea' required>
              <Select
                value={clasificacionCotizacionId}
                name='clasificacionCotizacionId'
                options={getStoreClasificacionesCotizaciones.data}
                isClearable
                getOptionLabel={(x) => `${x.nombre}`}
                getOptionValue={(x) => x._id}
                placeholder='Seleccione..'
                onChange={(value) => onSelect('clasificacionCotizacionId', value)}
              />
            </DivFormGroup>
          </Col>
          <Col sm={2}>
            <DivFormGroup name='marcaId' title='Marca' required>
              <Select
                value={marcaId}
                name='marcaId'
                options={getStoreMarcas.data}
                isClearable
                getOptionLabel={(x) => `${x.nombre}`}
                getOptionValue={(x) => x._id}
                placeholder='Seleccione..'
                onChange={(value) => onSelect('marcaId', value)}
              />
            </DivFormGroup>
          </Col>
          <Col sm={7}>
            <FormInput title='Código o Descripción' placeholder='Buscar...' name='buscar' onChange={onChangeSearch} value={search} />
          </Col>
          <Col sm={1}>
            <DivFormGroup>
              <label>&nbsp;</label>
              <InputGroup>
                <Button onClick={() => setInList(!inList)} variant='success' className='pull-right' type='button'>
                  <Icon icon={!inList ? 'th-list' : 'th-large'} />
                </Button>
              </InputGroup>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <EquiposBuscadorProducto
              equipos={equipos}
              monedaId={monedaId}
              tipoCambio={tipoCambio}
              activePage={activePage}
              count={count}
              pagination={pagination}
              loading={loading}
              inList={inList}
              handlePagination={handlePagination}
              onClickEquipo={onClickEquipo}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ModalBuscadorProducto.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  monedaId: PropTypes.string,
  tipoCambio: PropTypes.number,
  onSelectProducto: PropTypes.func,
};

export default ModalBuscadorProducto;
