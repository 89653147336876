import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput, Label, TextArea } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Module, Saving } from '@stateless';
import { getRequest, postUploadRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import FormEquipmentCostos from './FormEquipmentCostos';
import { Button, Card, Col, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { validateUpdate } from '@validations/equipments';
import TableWorkForces from './components/TableWorkForces';
import TableFichaTecnica from './components/TableFichaTecnica';
import TableProveedores from './components/TableProveedores';
import TableAlternativos from './components/TableAlternativos';
import SelectUnidadMedida from '@components/forms/SelectUnidadMedida';
import SelectMarcas from '@components/forms/SelectMarca';
import SelectClasificacionCotizacion from '@components/forms/SelectClasificacionCotizacion';
import SelectClasificacionLevantamiento from '@components/forms/SelectClasificacionLevantamiento';
import ImagenEquipo from './components/ImagenEquipo';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const EditEquipment = () => {
  const { equipmentId } = useParams();
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [short_name, setShortName] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [measure, setMeasure] = useState(null);
  const [marca, setMarca] = useState(null);
  const [clasificacionCotizacion, setClasificacionCotizacion] = useState(null);
  const [clasificacionLevantamiento, setClasificacionLevantamiento] = useState(null);
  const [caducidad, setCaducidad] = useState(false);
  const [tiempo_caducidad, setTiempoCaducidad] = useState(0);
  const [clave_sat, setClaveSat] = useState('');
  const [codigo_barras, setCodigoBarras] = useState('');
  const [usa_series, setUsaSeries] = useState(false);
  const [alternativos, setAlternativos] = useState([]);
  const [proveedores, setProveedores] = useState([{ proveedorId: null, codigo: '', codigo_barras: '', date_last_cost: null, ultimoCosto: 0 }]);
  const [listLevels, setListLevels] = useState([]);
  const [listFichas, setListFichas] = useState([]);

  const [state, setState] = useState({
    isLoading: false,
    moneda: { value: MONEDA.DOLAR, label: MONEDA.DOLAR },
    moneda_compra: { value: MONEDA.DOLAR, label: MONEDA.DOLAR },
    precio: 0,
    value: '',
    file: '',
    imagePreviewUrl: '',
    backgroundImage: {},
    showIconRemove: false,
    last_cost: 0,
    cost_average: 0,
    tipo_cambio: 1,
    date_last_cost: moment().format('YYYY-MM-DD'),
    margen_ganancia: 65,
    iva: 16,
    cost: 0,
    tipos_costeo: [],
    tipo_costeo: { codigo: 'PROMEDIO', descripcion: 'PROMEDIO' },
    monedas: [
      { value: MONEDA.DOLAR, label: MONEDA.DOLAR },
      { value: MONEDA.PESO, label: MONEDA.PESO },
    ],
    listLevels: [],
    listFichas: [],
  });

  const getEquipo = async () => {
    const {
      data: equipo,
      catalogs: { tipos_costeo },
    } = await getRequest({ url: `equipments/${equipmentId}` });
    setCode(equipo.code);
    setShortName(equipo.short_name);
    setName(equipo.name);
    setDescription(equipo.description);
    setMeasure(equipo.equipmentsMeasureId);
    setMarca(equipo.marcaId);
    setClasificacionCotizacion(equipo.clasificacionCotizacionId);
    setClasificacionLevantamiento(equipo.clasificacionLevantamientoId);
    setClaveSat(equipo.clave_sat);
    setCodigoBarras(equipo.codigo_barras);
    setCaducidad(equipo.caducidad);
    setTiempoCaducidad(equipo.tiempo_caducidad);
    setUsaSeries(equipo.usa_series);
    setAlternativos(equipo.listAlternos);
    setProveedores(
      equipo.listCostosProveedores.map((x) => ({
        proveedorId: x.proveedorId,
        codigo: x.codigo,
        codigo_barras: x.codigo_barras,
        date_last_cost: moment(x.date_last_cost).format('YYYY-MM-DD'),
        ultimoCosto: x.ultimo_costo,
      }))
    );
    setListLevels(equipo.listWorkforce);
    setListFichas(equipo.listFichas);
    setState((prevState) => ({
      ...prevState,
      moneda: { value: equipo.moneda, label: equipo.moneda },
      moneda_compra: { value: equipo.moneda_compra, label: equipo.moneda_compra },
      precio: equipo.precio,
      last_cost: equipo.last_cost,
      cost_average: equipo.cost_average,
      tipo_cambio: equipo.tipo_cambio,
      date_last_cost: moment(equipo.date_last_cost).format('YYYY-MM-DD'),
      margen_ganancia: equipo.margen_ganancia,
      iva: equipo.iva,
      cost: equipo.cost,
      tipos_costeo,
      tipo_costeo: tipos_costeo.find((x) => x.codigo === equipo.tipo_costeo),
    }));
  };

  useEffect(() => {
    getEquipo();
  }, [equipmentId]);

  const handleRegisterProduct = async (event) => {
    event.preventDefault();
    const {
      date_last_cost,
      pedimento,
      serie,
      precio,
      last_cost,
      cost_average,
      tipo_costeo,
      margen_ganancia,
      iva,
      moneda,
      moneda_compra,
      cost,
      tipo_cambio,
      file,
    } = state;

    const listWorkforce = listLevels.map((x) => ({ level: x.level, value: x.value }));
    const data = {
      code: code.trim(),
      short_name: short_name.trim(),
      name: name.trim(),
      description: description.trim(),
      measureId: measure?._id,
      marcaId: marca?._id,
      clasificacionCotizacionId: clasificacionCotizacion?._id,
      clasificacionLevantamientoId: clasificacionLevantamiento?._id,
      clave_sat,
      serie,
      codigo_barras,
      pedimento,
      usa_series,
      caducidad,
      tiempo_caducidad,
      moneda_compra: moneda_compra?.value,
      tipo_costeo: tipo_costeo?.codigo,
      last_cost: clean(last_cost),
      cost_average: clean(cost_average),
      date_last_cost,
      cost,
      moneda: moneda?.value,
      tipo_cambio: clean(tipo_cambio),
      margen_ganancia: clean(margen_ganancia),
      precio: clean(precio),
      iva: clean(iva),
      proveedores: proveedores.map((x) => ({
        proveedorId: x.proveedorId?._id,
        codigo: x.codigo,
        codigo_barras: x.codigo_barras,
        date_last_cost: x.date_last_cost,
        ultimo_costo: x.ultimoCosto,
      })),
      alternativos: alternativos.map((x) => ({ equipoId: x.alternativoId?._id })),
      listWorkforce,
    };
    const form = new FormData();
    for (const [key, value] of Object.entries(data)) {
      form.append(key, typeof value === 'string' ? value : JSON.stringify(value));
    }
    if (file) form.append('image', file);

    if (validations(data)) {
      await postUploadRequest({ url: `equipments/${equipmentId}`, form });
      setTimeout(() => {
        navigate(`/equipos`);
      }, 1000);
    }
  };

  const handleUpdateImage = useCallback((file, image) => {
    setState((prevState) => ({
      ...prevState,
      file,
      imagePreviewUrl: image,
    }));
  }, []);

  const handleRemoveImage = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      file: '',
      imagePreviewUrl: '',
    }));
  }, []);

  const onChangeCosteo = (costeo) => {
    setState((prevState) => ({
      ...prevState,
      ...costeo,
    }));
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const { isLoading } = state;

  return (
    <Module onClickBack={window.history.back} parent='Equipos' title='Agregar equipo'>
      <form onSubmit={handleRegisterProduct}>
        <Row>
          <Col sm='8'>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm='4'>
                    <FormInput title='Código' placeholder='Código' required name='code' onChange={(e) => setCode(e.target.value)} value={code} />
                  </Col>
                  <Col sm='8'>
                    <FormInput title='Nombre corto' required name='short_name' onChange={(e) => setShortName(e.target.value)} value={short_name} />
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <FormInput title='Nombre' required name='name' onChange={(e) => setName(e.target.value)} value={name} />
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <DivFormGroup>
                      <Label name='description' title='Descripción' required />
                      <TextArea name='description' onChange={(e) => setDescription(e.target.value)} placeholder='Descripción' value={description} />
                    </DivFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='3'>
                    <DivFormGroup name='measure' title='Unidad' required>
                      <SelectUnidadMedida
                        name='measure'
                        value={measure}
                        onChange={(value) => setMeasure(value)}
                        filter={(x) => x.tipo === 'EQUIPO'}
                      />
                    </DivFormGroup>
                  </Col>
                  <Col sm='3'>
                    <DivFormGroup name='marca' title='Marca' required>
                      <SelectMarcas name='marca' value={marca} onChange={(value) => setMarca(value)} />
                    </DivFormGroup>
                  </Col>
                  <Col sm='3'>
                    <DivFormGroup name='clasificacionCotizacion' title='Linea' required>
                      <SelectClasificacionCotizacion
                        name='clasificacionCotizacion'
                        value={clasificacionCotizacion}
                        onChange={(value) => setClasificacionCotizacion(value)}
                      />
                    </DivFormGroup>
                  </Col>
                  <Col sm='3'>
                    <DivFormGroup name='clasificacionLevantamiento' title='Levantamientos' required>
                      <SelectClasificacionLevantamiento
                        name='clasificacionLevantamiento'
                        value={clasificacionLevantamiento}
                        onChange={(value) => setClasificacionLevantamiento(value)}
                      />
                    </DivFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='4'>
                    <FormInput title='Clave SAT' name='clave_sat' onChange={(e) => setClaveSat(e.target.value)} value={clave_sat} />
                  </Col>
                  <Col sm='4'>
                    <FormInput
                      title='Código de barras'
                      name='codigo_barras'
                      onChange={(e) => setCodigoBarras(e.target.value)}
                      value={codigo_barras}
                    />
                  </Col>
                  <Col sm='4'>
                    <FormGroup className='inline'>
                      <label>Caducidad en meses</label>
                      <InputGroup>
                        <InputGroup.Text>
                          <input type='checkbox' name='caducidad' onChange={(e) => setCaducidad(e.target.checked)} checked={caducidad} />
                        </InputGroup.Text>
                        <InputNumber
                          disabled={!caducidad}
                          name='tiempo_caducidad'
                          style={{ zIndex: 0 }}
                          onChange={(e) => setTiempoCaducidad(e.target.value)}
                          value={tiempo_caducidad}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='4'>
                    <div className='checkbox'>
                      <label>
                        <input type='checkbox' name='usa_series' onChange={(e) => setUsaSeries(e.target.checked)} checked={usa_series} /> Usa series
                      </label>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm='4'>
            <Row>
              <ImagenEquipo urlImage={state.imagePreviewUrl} onRemove={handleRemoveImage} onUpdate={handleUpdateImage} />
            </Row>
          </Col>
        </Row>
        <FormEquipmentCostos {...state} onChange={onChangeCosteo} />
        <Row>
          <Col sm='12'>
            <TableProveedores proveedores={proveedores} onChangeProveedores={(provs) => setProveedores(provs)} />
          </Col>
          <Col sm='12'>
            <TableAlternativos alternativos={alternativos} onChangeAlternativos={(alts) => setAlternativos(alts)} />
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <TableFichaTecnica fichasTecnicas={listFichas} onChangeFichasTecnicas={(list) => setListFichas(list)} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md='12'>
            <TableWorkForces listLevels={listLevels} onChangeWorkforces={(list) => setListLevels(list)} />
          </Col>
        </Row>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button type='submit' className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </form>
    </Module>
  );
};

EditEquipment.propTypes = {};

export default EditEquipment;
