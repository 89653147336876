import { FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Col, DropdownItem, FormGroup, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import ContactoEdit from '@components/customers/ContactoEdit';

const ConvertProspectosClientes = ({ prospectoId, refresh }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    numero_cliente: '',
    regimen: 'F',
    rfc: '',
    estados: [],
    tiposContactos: [],
    listContactos: [],
    listDomicilios: [],
    titulos: [],
  });

  const close = () => setState({ ...state, showModal: false });

  const open = async () => {
    const { estados, tiposContactos, paises } = await getRequest({ url: `catalogos/customers` });
    const {
      data: { numero_cliente },
    } = await getRequest({ url: `customers/siguienteNumero` });
    ObtenerProspecto(prospectoId, tiposContactos, estados, paises, numero_cliente);
  };

  const ObtenerProspecto = async (prospectoId, tiposContactos, estados, paises, numero_cliente) => {
    const { data } = await getRequest({ url: `prospectos/${prospectoId}` });
    const {
      calle,
      exterior,
      interior,
      localidad,
      coloniaId,
      codigo_postal,
      entre_calles: { calle1, calle2 },
    } = data.domicilio || { entre_calles: {} };
    const razon_social = data.regimen === 'F' ? `${data.name || ''} ${data.paterno || ''} ${data.materno || ''}`.trim() : data.razon_social;
    const colonia = coloniaId;
    const municipio = colonia && colonia.municipio_id;
    const estado = municipio && municipio.estadoId;
    const pais = estado && estado.paisId;
    const [paterno, materno, ...nombreArray] = data.razon_social.split(' ').reverse();
    const nombre = nombreArray.reverse().join(' ');
    const domicilio = {
      calle,
      exterior,
      interior,
      localidad,
      codigo_postal,
      calle1: calle1,
      calle2: calle2,
      editando: true,
      estadoObject: estado,
      municipioObject: municipio,
      coloniaObject: colonia,
      paisObject: pais,
    };
    setState({
      ...state,
      tiposContactos: tiposContactos.map((tipo) => ({
        value: tipo._id,
        label: tipo.tipo,
      })),
      numero_cliente,
      regimen: data.regimen || 'F',
      rfc: data.rfc || 'XAXX010101000',
      curp: data.curp || '',
      nombre: data.name || '',
      paterno: data.paterno || '',
      materno: data.materno || '',
      razon_social: razon_social,
      nombre_comercial: data.nombre_comercial,
      nacionalidad: data.nacionalidad || '',
      extranjero: data.extranjero,
      estado_civil: data.estado_civil,
      sexo: data.sexo,
      fecha_nacimiento: moment(data.fecha_nacimiento).format('YYYY-MM-DD'),
      showModal: true,
      estados,
      paises,
      listContactos: [
        {
          name: nombre || '',
          paterno: paterno || '',
          materno: materno || '',
          email: data.email || '',
          phone: data.phone,
          mobile: data.mobile,
          tipos: [],
        },
      ],
      listDomicilios: [
        {
          ...domicilio,
          monitoreo: '',
          es_fiscal: true,
        }
      ],
    });
  };

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };

    if (isEmpty(params.rfc|| '')) {
      validate.message = 'RFC es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.nombre || '')) {
      validate.message = 'Nombre es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.paterno || '')) {
      validate.message = 'Apellido paterno es requerido.';
    } else if (
      params.listDomicilios.filter(
        (domicilio) =>
          isEmpty(domicilio.calle + '') ||
          !domicilio.paisId ||
          !domicilio.estadoId ||
          !domicilio.municipioId ||
          !domicilio.coloniaId ||
          isEmpty(domicilio.codigo_postal + '')
      ).length > 0
    ) {
      validate.message = 'Los domicilios requieren calle, pais, estado, municipio, colonia y código postal.';
    } else if (
      params.listContactos.filter(
        (contacto) => contacto.tipos.length === 0 || isEmpty(contacto.name || '') || (isEmpty(contacto.correo || '') && isEmpty(contacto.telefono || ''))
      ).length > 0
    ) {
      validate.message = 'Los contactos requieren tipo contacto, nombre y correo electronico.';
    } else {
      validate.success = true;
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();
    const {
      regimen,
      numero_cliente,
      rfc,
      curp,
      nombre,
      paterno,
      materno,
      razon_social,
      nombre_comercial,
      nacionalidad,
      extranjero,
      estado_civil,
      sexo,
      fecha_nacimiento,
      listContactos,
      listDomicilios,
    } = state;
    const body = {
      prospectoId,
      numero_cliente,
      regimen,
      rfc,
      curp,
      nombre,
      paterno,
      materno,
      razon_social,
      nombre_comercial,
      nacionalidad,
      extranjero,
      estado_civil,
      sexo,
      fecha_nacimiento,
      listContactos: listContactos.map((x) => ({
        ...x,
        tipos: x.tipos.map((y) => y._id),
      })),
      listDomicilios: listDomicilios.map((domicilio) => ({
        calle: domicilio.calle || '',
        exterior: domicilio.exterior || '',
        interior: domicilio.interior || '',
        calle1: domicilio.calle1 || '',
        calle2: domicilio.calle2 || '',
        coloniaId: domicilio.coloniaObject?._id,
        municipioId: domicilio.municipioObject?._id,
        estadoId: domicilio.estadoObject?._id,
        paisId: domicilio.paisObject?._id,
        codigo_postal: domicilio.codigo_postal || '',
        localidad: domicilio.localidad || '',
        referencias: domicilio.referencias || '',
        nombre: domicilio.nombre || '',
        monitoreo: domicilio.monitoreo || '',
        es_fiscal: domicilio.es_fiscal,
      })),
    };
    if (validations(body)) {
      const { data } = await postRequest({ url: `customers/prospecto`, body });
      if (data) {
        refresh();
        close();
      }
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    let { rfc } = state;
    if (name === 'regimen') {
      if (value === 'I') {
        rfc = 'XAXX010101000';
      } else if (value === 'E') {
        rfc = 'XEXX010101000';
      }
    }
    setState({ ...state, rfc, [name]: value });
  };


  const onChangeContacto = (index, contacto) => {
    setState({
      ...state,
      listContactos: [
        ...state.listContactos.map((c, i) => {
          if (i === index) {
            return {
              ...c,
              ...contacto,
            };
          }
          return {
            ...c,
          };
        }),
      ],
    });
  };

  const onChangeDomicilio = (index, dom) => {
    setState({
      ...state,
      listDomicilios: [
        ...state.listDomicilios.map((domicilio, i) => {
          if (i === index) return { ...dom };
          return {
            ...domicilio,
          };
        }),
      ],
    });
  };

  const { numero_cliente, regimen, rfc, showModal, nombre, paterno, materno, razon_social, nombre_comercial, isLoading, listDomicilios, listContactos } = state;
  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='retweet' /> Convertir a cliente
      </DropdownItem>
      <CbModal show={showModal} onClose={close} title='Convertir' isLoading={isLoading} onSave={handleRegistrar} size='xl'>
        <Card>
          <Card.Body>
            <Row>
              <Col xs={4}>
                <FormGroup className='inline'>
                  <label>Régimen</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} value={'F'} checked={regimen === 'F'} /> Física
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} value={'M'} checked={regimen === 'M'} /> Moral
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} value={'I'} checked={regimen === 'I'} /> Informal
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} value={'E'} checked={regimen === 'E'} /> Extranjera
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={3}>
                <FormInput name='numero_cliente' title='Número de cliente' onChange={onChange} value={numero_cliente} required />
              </Col>
              <Col xs={5}>
                <FormInput
                  name='rfc'
                  title='RFC'
                  onChange={onChange}
                  value={rfc}
                  maxLength={13}
                  required
                  disabled={regimen === 'I' || regimen === 'E'}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className='mt-3'>
          <Card.Body>
            <Row>
              {regimen === 'F' ? (
                <>
                  <Col xs={4}>
                    <FormInput name='nombre' title='Nombre(s)' onChange={onChange} value={nombre} required />
                  </Col>
                  <Col xs={4}>
                    <FormInput name='paterno' title='Apellido Paterno' onChange={onChange} value={paterno} required />
                  </Col>
                  <Col xs={4}>
                    <FormInput name='materno' title='Apellido Materno' onChange={onChange} value={materno} />
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <FormInput name='razon_social' title='Razón Social' onChange={onChange} value={razon_social} required />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col>
                <FormInput name='nombre_comercial' title='Nombre comercial' onChange={onChange} value={nombre_comercial} required />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className='mt-3'>
          <Card.Body>
            <Card.Title>Domicilio</Card.Title>
            <Row>
              <Col xs={12}>
                <DomicilioEdit domicilio={listDomicilios[0]} onChange={(dom)=>onChangeDomicilio(0,dom)} />
              </Col>  
            </Row>
          </Card.Body>
        </Card>
        <Card className='mt-3'>
          <Card.Body>
            <Card.Title>Contacto</Card.Title>
            <Row>
              <Col xs={12}>
                <ContactoEdit contacto={listContactos[0]} onChangeContacto={(con)=>onChangeContacto(0,con)} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </CbModal>
    </>
  );
};

ConvertProspectosClientes.propTypes = {
  prospectoId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ConvertProspectosClientes;
