import React, { useEffect, useState } from 'react';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ButtonGroup, DropdownButton, DropdownItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import DetailCustomer from '@components/customers/DetailCustomer';
import AsignarAgente from './AsignarAgente';
import Cancel from './Cancel';
import DescuentoPago from './DescuentoPago';
import PagoManual from './PagoManual';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import TextCurrency from '@controls/TextCurrency';
import IconDropdown from '@controls/IconDropdown';

const Row = (props) => {
  const { _id, saldo, estado, moneda, serie, numero, fecha, customerId, titulo, importe, fecha_pago, descuento_pago, agenteId } = props;
  const PENDIENTE = 'PENDIENTE';
  const PAGADA = 'PAGADA';
  const PAGADA_MANUAL = 'PAGADA_MANUAL';
  const [currency, setCurrency] = useState('MXN');
  const [descuento, setDescuento] = useState({});
  useEffect(() => {
    if (!descuento_pago) setDescuento({});
    if (moneda === 'DOLAR' || moneda === 'DOLLAR' || moneda === 'DÓLAR') setCurrency('USD');
  }, []);

  const switchEstado = (estado) => {
    const estados = {
      PENDIENTE: { text: 'Pendiente', color: 'secondary' },
      PAGADA: { text: 'Pagada', color: 'success' },
      PAGADA_MANUAL: { text: 'Pagada manualmente', color: 'success' },
      ABONADA: { text: 'Abonada', color: 'warning' },
      CANCELADA: { text: 'Cancelada', color: 'danger' },
    };

    const { text = '', color = 'secondary' } = estados[estado] || {};
    return <CbBadge type={color}>{text}</CbBadge>;
  };

  const onClickDownloadPdf = async (id, event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `factura/pdf/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const onClickDownloadXml = async (id, event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `factura/xml/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const onClickCobrar = () => {
    props.cobrarFactura();
  };

  const onChange = (event) => {
    props.onChange(event);
  };

  return (
    <tr>
      <td className='text-center'>
        <input
          type='checkbox'
          name='facturas[]'
          id={`factura-${_id}`}
          onChange={onChange}
          value={_id}
          disabled={saldo === 0 || estado !== PENDIENTE || moneda !== 'PESO'}
        />
      </td>
      <td>{serie ? `${serie}-${numero}` : numero}</td>
      <td>{moment(fecha).local().format('DD/MM/YYYY HH:mm')}</td>
      <td>
        <DetailCustomer customer={customerId} />
      </td>
      <td>{titulo}</td>
      <td>
        <TextCurrency>{importe}</TextCurrency>
      </td>
      <td>
        <TextCurrency>{saldo}</TextCurrency>
      </td>
      <td>
        <CbBadge type='primary mr-5'>{currency}</CbBadge>
        {switchEstado(estado)}
        {estado === PAGADA || estado === PAGADA_MANUAL ? (
          <span>
            <br />
            <small>
              <em>{moment(fecha_pago).isValid() ? moment(fecha_pago).format('DD/MM/YYYY HH:mm') : ''} </em>
            </small>
          </span>
        ) : null}
        <If condition={descuento && descuento.percentage}>
          <If.Then>
            <OverlayTrigger placement='top' overlay={<Tooltip>Descuento de pago</Tooltip>}>
              <CbBadge type='warning mr-5'>% {descuento.percentage}</CbBadge>
            </OverlayTrigger>
          </If.Then>
        </If>
      </td>
      <td>
        <ButtonGroup>
          <CbButtonOver title='Descargar PDF' onClick={() => onClickDownloadPdf(_id)} icon='download-alt' type='primary' />
          <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-primary'>  
            <DropdownItem href={`/facturas/detalle/${_id}`}>
              <Icon icon='zoom-in' /> Detalle
            </DropdownItem>
            <AsignarAgente itemId={_id} factura={{ serie, numero, saldo, descuento_pago: descuento, agenteId }} {...props} />
            <DropdownItem onClick={() => onClickDownloadXml(_id)}>
              <Icon icon='file' /> Descargar XML
            </DropdownItem>
            <DropdownItem onClick={() => onClickCobrar(_id)}>
              <Icon icon='usd' /> Cobrar
            </DropdownItem>
            {estado === PENDIENTE && (
              <>
                <Cancel itemId={_id} factura={{ serie, numero }} {...props} />
                <PagoManual itemId={_id} factura={{ serie, numero, saldo }} {...props} />
                <DescuentoPago itemId={_id} factura={{ serie, numero, saldo, descuento_pago: descuento }} {...props} />
              </>
            )}
          </DropdownButton>
        </ButtonGroup>
      </td>
    </tr>
  );
};

Row.propTypes = {
  _id: PropTypes.string,
  saldo: PropTypes.number,
  estado: PropTypes.string,
  moneda: PropTypes.string,
  serie: PropTypes.string,
  numero: PropTypes.number,
  fecha: PropTypes.string,
  customerId: PropTypes.string,
  titulo: PropTypes.string,
  importe: PropTypes.number,
  currency: PropTypes.string,
  fecha_pago: PropTypes.string,
  descuento_pago: PropTypes.object,
  agenteId: PropTypes.string,
  cobrarFactura: PropTypes.func,
  onChange: PropTypes.func,
};

export default Row;
