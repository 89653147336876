import { MONEDA } from '@config/constants';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Icon, Module, Saving } from '@stateless';
import { FormInput } from '@controls';
import Swal from 'sweetalert2';
import { getRequest, putRequest } from '@utils/api';
import {
  castServicio,
  getTipoCotizacion,
  measuresServicios,
  validations,
  getHorasCotizacion,
  HORA,
  mapDataDetalles,
  recalcularPrecioDetalle,
  recalcularPrecioServicio,
} from '@utils/cotizaciones';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import BuscadorDetalles from './New/BuscadorDetalles';
import BuscadorKits from './New/BuscadorKits';
import FormEncabezado from './New/FormEncabezado';
import ModalImprimir from './New/ModalImprimir';
import RowDetalle from './New/RowDetalle';
import Totales from './New/Totales';
import getState from '@utils/state';
import ModalImportarExcel from './New/ModalImportarExcel';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import TableHeaders from './New/components/TableHeaders';
import { useStoreMedidas } from '@stores/catalogs.store';
import { TIPOS_CLIENTES, TIPOS_PARTIDAS, ESTATUS_COTIZACIONES } from '@utils/cotizaciones';
import useAuth from '@hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const params = useParams();
  const { levantamientoId } = params;
  const [idCotizacion, setIdCotizacion] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalSubirExcel, setShowModalSubirExcel] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [estatusLevantamientos] = useState([...ESTATUS_COTIZACIONES]);
  const [showModalKitsComerciales, setShowModalKitsComerciales] = useState(false);
  const { agente } = useAuth();

  const [state, setState] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    fecha_vencimiento: moment().add(30, 'days').format('YYYY-MM-DD'),
    monedaId: {
      enumerador: MONEDA.PESO,
      descripcion: 'PESO',
    },
    agenteId: null,
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: { value: 'INICIADA', label: 'INICIADA' },
    sucursalId: null,
    cliente: null,
    domicilios: [],
    domicilioId: null,
    anticipo: 0,
    tipoLevantamientoId: null,
  });

  const getStoreMedidas = useStoreMedidas();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingForm(true);
      if (!levantamientoId) {
        notification({
          title: 'Información incompleta',
          message: 'Es necesario tener el id del levantamiento',
          type: 'error',
        });
        setTimeout(() => {
          navigate('/cotizaciones');
        }, 1000);
      }
      if (!agente) {
        notification({
          title: 'Información incompleta',
          message: 'Es necesario tener configurado el usuario como agente',
          type: 'error',
        });
        setTimeout(() => {
          navigate('/');
        }, 1000);
        return;
      }
      const { data: cotizacion } = await getRequest({ url: `cotizaciones/${levantamientoId}` });
      const domicilios =
        cotizacion.customerId?.listDomicilios?.length > 0 ? cotizacion.customerId.listDomicilios : [{ ...cotizacion.customer.domicilio }];
      setState((prevState) => ({
        ...prevState,
        maximo_descuento: agente.maximo_descuento,
        numeroOrden: cotizacion.numero_levantamiento,
        fecha: moment(cotizacion.fecha).local().format('YYYY-MM-DD'),
        fecha_vencimiento: moment(cotizacion.fecha_vencimiento).local().format('YYYY-MM-DD'),
        ordenServicioId: cotizacion.ordenServicioId,
        es_adicional: cotizacion.es_adicional,
        estatus: estatusLevantamientos.find((x) => x.value === cotizacion.estado),
        sucursalId: cotizacion.sucursal,
        almacenId: cotizacion.almacen,
        monedaId: {
          enumerador: cotizacion.moneda,
          descripcion: cotizacion.moneda,
        },
        tipoLevantamientoId: cotizacion.tipo_levantamiento,
        tipoCambio: cotizacion.tipo_cambio,
        anticipo: cotizacion.anticipo,
        tipo_cliente: TIPOS_CLIENTES.find((x) => x.value === cotizacion.tipo_cliente),
        cliente: {
          ...cotizacion.customer,
        },
        agenteId: cotizacion.agente,
        agente: {
          ...cotizacion.agente._id,
        },
        domicilioId: cotizacion.customer.domicilio,
        domicilios,
        observaciones: cotizacion.observaciones,
        detalles: cotizacion.detalles.map((x) => {
          if (x.tipo_partida === 'KIT_COMERCIAL') {
            x.colapsado = true;
            x.partes = x.partes.map((y) => {
              return {
                ...y,
                tipo_partida: TIPOS_PARTIDAS.find((x) => x.value === y.tipo_partida),
                relacionado: y.relacionadoId,
                relacionadoId: y.relacionadoId._id,
                status: y.relacionadoId.status,
                measureId: {
                  _id: y.unidadMedida._id,
                  measure: y.unidadMedida.nombre,
                },
                noIdentificador: y.codigo,
                precio_original: y.precio,
                moneda: cotizacion.moneda,
                moneda_original: cotizacion.moneda,
                iva: y.iva || 16,
                imagen: y.relacionadoId?.imagen,
                tiene_sustitutos: y.relacionadoId?.listAlternos?.length > 0,
                tiene_otro_proveedor: y.relacionadoId?.listCostosProveedores?.length > 1,
              };
            });
          }
          return {
            ...x,
            tipo_partida: TIPOS_PARTIDAS.find((y) => y.value === x.tipo_partida),
            relacionado: x.relacionadoId,
            relacionadoId: x.relacionadoId._id,
            measureId: {
              _id: x.unidadMedida._id,
              measure: x.unidadMedida.nombre,
            },
            moneda: cotizacion.moneda,
            moneda_original: cotizacion.moneda,
            noIdentificador: x.codigo,
            precio: x.precio,
            precio_original: x.precio,
            iva: x.iva,
            imagen: x.relacionadoId?.imagen,
            status: x.relacionadoId.status,
            tiene_sustitutos: x.relacionadoId?.listAlternos?.length > 0,
            tiene_otro_proveedor: x.relacionadoId?.listCostosProveedores?.length > 1,
          };
        }),
      }));
      setTimeout(() => setIsLoadingForm(false), 100);
    };
    fetchData();
  }, []);

  const precios = state.detalles.map((detalle) => detalle?.precio).join('-');
  const cantidades = state.detalles.map((detalle) => detalle?.cantidad).join('-');

  useEffect(() => {
    recalcularTipoCambio();
  }, [state.monedaId, state.tipoCambio]);

  useEffect(() => {
    recalcularTipoCambio();
  }, [precios, cantidades]);

  useEffect(() => {
    recalcularServicios();
  }, [state.sucursalId]);

  useEffect(() => {
    console.log(isLoadingForm, state.tipoLevantamientoId);
    if (!isLoadingForm) actualizarNumeroLevantamiento();
  }, [state.tipoLevantamientoId?._id]);

  const actualizarNumeroLevantamiento = async () => {
    setIsLoadingForm(true);
    const { data } = await getRequest({
      url: `cotizaciones/siguienteNumero`,
      params: { tipoLevantamientoId: getTipoCotizacion(state.tipoLevantamientoId) },
    });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_levantamiento,
    }));
    setIsLoadingForm(false);
  };

  const onChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      ...getState(event.target),
    }));
  };

  const subirExcel = () => {
    setState((prevState) => ({
      ...prevState,
      showModalSubirExcel: true,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroOrden,
      fecha,
      fecha_vencimiento,
      estatus,
      sucursalId,
      monedaId,
      tipoCambio,
      anticipo,
      tipo_cliente,
      domicilioId,
      cliente,
      observaciones,
      detalles,
      agenteId,
      tipoLevantamientoId,
      maximo_descuento
    } = state;
    const data = {
      numero_levantamiento: numeroOrden,
      tipoLevantamientoId: tipoLevantamientoId?._id,
      tipo_cliente: tipo_cliente?.value,
      fecha: moment(fecha, 'YYYY-MM-DD').utc().format(),
      fecha_vencimiento: moment(fecha_vencimiento, 'YYYY-MM-DD').utc().format(),
      estatus_levantamiento: estatus?.value,
      sucursalId: sucursalId?._id,
      monedaId: monedaId?.enumerador,
      tipoCambio,
      anticipo,
      observaciones,
      clienteId: cliente?._id,
      agenteId: agenteId?._id,
      domicilioId: domicilioId ? domicilioId._id : 1,
      detalles: mapDataDetalles(detalles),
    };
    let sobrepasa_descuento = detalles.some((detalle) => detalle.descuento > maximo_descuento);
    if (sobrepasa_descuento) {
      notification({
        title: 'Validaciones',
        message: 'El descuento de un detalle sobrepasa el máximo permitido',
        type: 'error',
      });
      return;
    }
    let { horas_cotizadas, horas_sugeridas } = getHorasCotizacion(data.detalles);
    if (horas_sugeridas > horas_cotizadas) {
      const titulo = `¡Se esta cotizando menos horas de las sugeridas!`;
      const message = `Las horas sugeridas para los equipos cotizados son <b>${Math.ceil(
        horas_sugeridas
      )}</b>. ¿Quiere cotizar debajo de las horas sugeridas?`;
      Swal.fire({
        html: `<div style="font-size: 1.5em!important;">${titulo}</div><span>${message}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#25b003',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, confirmo',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          onSaveCotizacion(data);
        }
      });
      return;
    }
    onSaveCotizacion(data);
  };

  const onSaveCotizacion = async (data) => {
    const { success, message } = validations(data);
    if (!success) {
      return notification({
        title: 'Validaciones',
        message,
        type: 'error',
      });
    }
    setIsLoadingForm(true);
    setIsLoading(true);
    putRequest({ url: `cotizaciones/${levantamientoId}`, body: data }).then(({ data: cotizacion }) => {
      setIdCotizacion(cotizacion._id);
      setShowModalDownload(true);
      setIsLoadingForm(false);
      setIsLoading(false);
    });
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((x, i) => (i === index ? detalle : x))],
    }));
  };

  const recalcularTipoCambio = () => {
    const { monedaId, tipoCambio } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioDetalle(detalle, monedaId, tipoCambio))],
    }));
  };

  const recalcularServicios = () => {
    const { sucursalId } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioServicio(detalle, sucursalId))],
    }));
  };

  const onRemoveDetalle = (index) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const closeSubirExcel = () => {
    setShowModalSubirExcel(false);
  };

  const onImportarExcel = (detallesNuevos) => {
    const { detalles } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...detalles, ...detallesNuevos],
    }));
    setShowModalSubirExcel(false);
  };

  const mismoServicio = (detalle, producto) => {
    return detalle.relacionadoId === producto.relacionadoId && detalle.measureId._id === producto.measureId._id;
  };

  const onSelectServicio = ({ servicio, listWorkForces, relacionadoId }) => {
    const { monedaId, tipoCambio, detalles, sucursalId } = state;
    const cantidad = listWorkForces.map((x) => x.cantidad).reduce((a, b) => a + b, 0);
    let listWorkForcesSettedNueva = detalles
      .map((d) => (d.relacionadoId == relacionadoId ? listWorkForces : d.listWorkForcesSetted))
      .filter((d) => d)
      .reduce((a, b) => a.concat(b), []);
    const nuevoValor = listWorkForcesSettedNueva.map((x) => Math.ceil(x.value * x.cantidad)).reduce((a, b) => a + b, 0);
    const measures = measuresServicios(getStoreMedidas.data);
    const producto = castServicio(servicio, monedaId, tipoCambio, measures, sucursalId);
    const hora = measures.find((y) => y.measure.toUpperCase() === HORA);
    if (hora) {
      producto.measureId = hora;
      producto.precio = (producto.listCost.find((y) => y.measureId === hora._id) || { cost: 0 }).cost;
      producto.precio_original = producto.precio;
    }
    let detallesNuevos = [
      ...detalles.map((d) => {
        if (d.relacionadoId === relacionadoId) {
          return {
            ...d,
            cantidad: cantidad,
            listWorkForcesSetted: listWorkForces,
            showModalWorkforce: false,
            tiene_servicio: true,
          };
        } else if (mismoServicio(d, producto)) {
          return {
            ...d,
            showModalWorkforce: false,
            cantidad: Number(nuevoValor),
          };
        }
        return d;
      }),
    ];
    const estaRegistrado = !!detallesNuevos.find((d) => mismoServicio(d, producto));
    if (!estaRegistrado) {
      detallesNuevos.push({
        ...producto,
        tipo_partida: { value: 'SERVICIO', label: 'SERVICIO' },
        cantidad: nuevoValor,
        descuento: 0,
        iva: producto.iva || 16,
        tiene_sustitutos: producto.listAlternos?.length > 0,
        tiene_otro_proveedor: producto.listCostosProveedores?.length > 1,
      });
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [...detallesNuevos],
    }));
  };

  const closeDownload = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate(-1), 1000);
  };

  const onDescartar = async () => {
    const { levantamientoId } = params;
    await putRequest({ url: `cotizaciones/descartar/${levantamientoId}` });
    setTimeout(() => navigate(-1), 1000);
  };

  const { detalles, monedaId, tipoCambio, sucursalId, observaciones } = state;

  return (
    <Module onClickBack={window.history.back} parent='Cotizaciones' title='Editar cotización' loading={isLoadingForm}>
      <BuscadorKits
        onClose={() => setShowModalKitsComerciales(false)}
        sucursalId={sucursalId}
        monedaId={monedaId}
        tipoCambio={tipoCambio}
        showModalKitsComerciales={showModalKitsComerciales}
        onAgregarDetalle={(detalle) => {
          setState((prevState) => ({ ...prevState, detalles: [...detalles, detalle] }));
        }}
        onLoadingTable={(isLoadingTable) => setIsLoadingTable(isLoadingTable)}
      />
      <ModalImprimir showModal={showModalDownload} onClose={closeDownload} idCotizacion={idCotizacion} />
      <ModalImportarExcel showModal={showModalSubirExcel} onClose={closeSubirExcel} tipoCambio={state.tipoCambio} onSave={onImportarExcel} />
      <FormEncabezado
        numeroOrden={state.numeroOrden}
        fecha={state.fecha}
        fecha_vencimiento={state.fecha_vencimiento}
        estatus={state.estatus}
        sucursalId={state.sucursalId}
        monedaId={state.monedaId}
        cliente={state.cliente}
        tipoCambio={state.tipoCambio}
        estatus_levantamientos={estatusLevantamientos}
        domicilioId={state.domicilioId}
        domicilios={state.domicilios}
        agenteId={state.agenteId}
        anticipo={state.anticipo}
        tipoLevantamientoId={state.tipoLevantamientoId}
        setState={(obj) => setState((prevState) => ({ ...prevState, ...obj }))}
        onSelectTipoLevantamiento={(tipoLevantamientoId) => setState((prevState) => ({ ...prevState, tipoLevantamientoId }))}
      />
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <BuscadorDetalles
              {...state}
              onAgregarDetalle={(detalle) => {
                setState((prevState) => ({ ...prevState, detalles: [...detalles, detalle] }));
              }}
              onLoadingTable={(isLoadingTable) => setIsLoadingTable(isLoadingTable)}
            />
          </Container>
        </Card.Body>
        {state.detalles && (
          <CbTableResponsive>
            <TableHeaders />
            <CbTableBody loading={isLoadingTable} colSpan={8}>
              {detalles.map((detalle, i) => (
                <RowDetalle
                  key={i}
                  monedaId={monedaId}
                  tipoCambio={tipoCambio}
                  sucursalId={sucursalId}
                  detalle={detalle}
                  onChangeDetalle={(detalle) => onChangeDetalle(i, detalle)}
                  onRemoveDetalle={() => onRemoveDetalle(i)}
                  onSelectServicio={onSelectServicio}
                />
              ))}
              <Totales colSpan={8} moneda={state.monedaId} detalles={state.detalles} anticipo={state.anticipo} />
            </CbTableBody>
          </CbTableResponsive>
        )}
        <Card.Footer />
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                <FormInput type='textarea' title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                {state.ordenServicioId && state.es_adicional && (
                  <Button onClick={onDescartar} variant='warning' type='button'>
                    Descartar
                  </Button>
                )}
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                <Button onClick={subirExcel} variant='warning pull-right mr-5' disabled={isLoading}>
                  <Icon icon='open-file' /> Importar Excel
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
