import { RedirectTooltip } from '@controls';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteContactos from './DeleteContactos';
import EditContacto from './EditContacto';
import { ButtonGroup } from 'react-bootstrap';

const List = ({ list, ...props }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, name, tipos, paterno, materno, phone, email } = row;
      return (
        <tr key={item}>
          <td>
            <strong>{tipos.map((x) => (x.tipoContactoId ? x.tipoContactoId.tipo : null))}</strong>
          </td>
          <td>{name + ' ' + paterno + ' ' + materno}</td>
          <td>{phone}</td>
          <td>{email}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='eliminar'>
                <DeleteContactos id={item} contactoId={_id} name={name} {...props} />
              </IfPermission>
              <IfPermission action='editar'>
                <EditContacto id={item} contactoId={_id} {...row} {...props} />
              </IfPermission>
              <IfPermission action='clientes'>
                <RedirectTooltip
                  id={1}
                  url={`/contactos/clientes/${_id}`}
                  labelTooltip='Clientes asignados'
                  icon='share'
                  className='btn btn-outline-primary'
                />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Tipo</th>
          <th width='25%'>Nombre</th>
          <th width='15%'>Teléfono</th>
          <th width='25%'>Correo electrónico</th>
          <th width='15%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  tiposContactos: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  loadContactos: PropTypes.func.isRequired,
};

export default List;
