import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

const AddPlazos = ({ plazos }) => {
  const renderRows = () => {
    return plazos.map((a, i) => {
      const label = `${a.plazo} meses ${a.taza !== null ? '- Tasa ' + a.taza + '%' : ''}`;

      return (
        <tr key={i}>
          <td>{label}</td>
          <td>
            <input id={a._id} name={a._id} type='checkbox' />
          </td>
        </tr>
      );
    });
  };

  const rowsForm = renderRows();

  return (
    <Col sm='12'>
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th width='80%'>Plazos</th>
            <th width='20%'>Selecciona</th>
          </tr>
        </thead>
        <tbody>{rowsForm}</tbody>
      </table>
    </Col>
  );
};

AddPlazos.propTypes = {
  plazos: PropTypes.array.isRequired,
};

export default AddPlazos;
