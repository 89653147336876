import React, { useEffect, useState } from 'react';
import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import List from './List';
import CbCustomPagination from '@cbcomponents/CbCustomPagination';
import { Card, Col, InputGroup, Row } from 'react-bootstrap';
import { fetchAll } from '@services/cotizaciones.service';
import usePagination from '@hooks/usePagination';
import useAuth from '@hooks/useAuth';
import Select from 'react-select';
import { ESTATUS_COTIZACIONES } from '@utils/cotizaciones';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';

const Cotizaciones = (props) => {
  const { agente: agenteAuth } = useAuth();
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [sucursal, setSucursal] = useState(null);
  const [agente, setAgente] = useState(agenteAuth);
  const [estatus_levantamiento, setEstatusLevantamiento] = useState(null);
  const [pagination, setPagination] = useState(50);

  const { datos, paginaActual, count, cargando, cambiarPagina, setParams, refresh } = usePagination(fetchAll, pagination, 'numero_levantamiento');
  useEffect(() => {
    setParams({
      search,
      fechaInicio,
      fechaFin,
      sucursalId: sucursal?._id || 0,
      agenteId: agente?._id || 0,
      estatus_levantamiento: estatus_levantamiento?.value || 0,
    });
  }, [fechaInicio, fechaFin, sucursal, agente, estatus_levantamiento, search]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setFechaInicio(params.fechaInicio);
    setFechaFin(params.fechaFin);
  };

  return (
    <Module title='Cotizaciones'>
      <Search onSearch={onSearch} withDatetime searchOnStopType blockOne='col-sm-12' blockSecond='col-sm-12' loading={cargando}>
        <Row>
          <Col sm='3'>
            <IfPermission action='TodasSucursales'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='home' title='sucursales' />
                  </InputGroup.Text>
                  <SelectSucursal value={sucursal} onChange={(value) => setSucursal(value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <IfPermission action='TodosAgentes'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='user' title='Agentes' />
                  </InputGroup.Text>
                  <SelectAgente value={agente} onChange={(value) => setAgente(value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Estatus' />
                </InputGroup.Text>
                <Select
                  value={estatus_levantamiento}
                  placeholder='Selecciona'
                  options={ESTATUS_COTIZACIONES}
                  isClearable
                  onChange={(value) => setEstatusLevantamiento(value)}
                />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <Link to='/cotizaciones/nuevo' className='btn btn-success pull-right'>
              <Icon icon='plus' /> Nuevo
            </Link>
          </Col>
        </Row>
      </Search>
      <Loading loading={cargando}>
        <Card>
          <List {...props} list={datos} loadData={refresh} agenteId={agente?._id} sucursalId={sucursal?._id} />
        </Card>
        <Row>
          <Col>
            <br />
            <div className='text-center'>
              <CbCustomPagination
                pagination={pagination}
                activePage={paginaActual}
                count={count}
                onPagination={cambiarPagina}
                onChangeItemsPerPagina={(pagination) => setPagination(pagination)}
              />
            </div>
          </Col>
        </Row>
      </Loading>
    </Module>
  );
};

export default Cotizaciones;
