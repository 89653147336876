import axios from 'axios';
import jwt from 'jwt-simple';
import { useStoreNotification } from '@stores/catalogs.store';
import CustomLocalStorage from './localStorage';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_URL_API;
export const URL_FILES = process.env.REACT_APP_URL_FILES;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const ENV = process.env.NODE_ENV;

const request = axios.create({
  baseURL: API_URL,
});

request.interceptors.request.use(
  async (request) => {
    const token = CustomLocalStorage.getItem('SICOB_APP_TOKEN');
    const user = CustomLocalStorage.getItem('SICOB_APP_USER');
    request.headers['X-Api-Key'] = API_KEY;
    if (token) {
      try {
        jwt.decode(token, API_KEY);
      } catch (e) {
        CustomLocalStorage.removeItem('SICOB_APP_TOKEN');
        CustomLocalStorage.removeItem('SICOB_APP_USER');
        CustomLocalStorage.removeItem('SICOB_APP_AGENTE');
        CustomLocalStorage.removeItem('SICOB_APP_TIPO_CAMBIO');
        CustomLocalStorage.removeItem('SICOB_APP_TASKS');
        CustomLocalStorage.removeItem('SICOB_APP_MESSAGES');
        CustomLocalStorage.removeItem('SICOB_APP_NOTIFICATIONS');
        window.location.href = '/login';
      }
      request.credentials = 'include';
      request.headers.Authorization = 'Bearer ' + token;
      request.headers.User = user?.email;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const notificar = (error) => {
  const status = error.response ? error.response.status : 404;
  const method = error.config ? error.config.method : '';
  const data = error.config ? error.config.data : {};
  const url = error.config ? error.config.url : '';
  let message = error.response ? error.response.statusText : '';
  let title = 'Advertencia';
  let type = error?.response?.data ? error.response.data.type : 'error';
  if (ENV !== 'production') console.log(status, message, error.response ? error.response : error);
  switch (status) {
    case 500:
      title = 'Oops...';
      message = '¡Algo salio mal!';
      break;
    case 404:
      title = 'Oops...';
      message = 'No es posible acceder a los servicios de la aplicación.';
      break;
    case 401:
      CustomLocalStorage.removeItem('SICOB_APP_TOKEN');
      CustomLocalStorage.removeItem('SICOB_APP_USER');
      CustomLocalStorage.removeItem('SICOB_APP_AGENTE');
      CustomLocalStorage.removeItem('SICOB_APP_TIPO_CAMBIO');
      CustomLocalStorage.removeItem('SICOB_APP_TASKS');
      CustomLocalStorage.removeItem('SICOB_APP_MESSAGES');
      CustomLocalStorage.removeItem('SICOB_APP_NOTIFICATIONS');
      window.location.href = '/login';
      break;
    case 422:
      message = error.response && error.response.data ? error.response.data.message : message;
      break;
    default:
      message = error.response ? error.response.statusText : '';
      break;
  }
  if (ENV === 'production') {
    axios.post(
      'https://hooks.slack.com/services/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',
      JSON.stringify({
        text: `Usuario: ${CustomLocalStorage.getItem('SICOB_APP_USER', {})?.email}
        Error: ${message}
        Url: ${url}
        Metodo: ${method}
        Status: ${status}
        Data: ${JSON.stringify(data)}
        `,
      })
    );
  }
  useStoreNotification.getState().addAlert({
    title,
    message,
    type: type ? type : 'error',
  });
};

const notificarSuccess = (response) => {
  let message = response?.message || '';
  let type = response?.type || 'success';
  if (type && message) {
    useStoreNotification.getState().addAlert({
      title: 'Atención',
      message: message,
      type,
    });
  }
};

export const getRequest = async (params) => {
  let response = {};
  if (validateParameters(params)) {
    try {
      const responseAxios = await request.get(params.url, { signal: params?.signal, params: params?.params });
      response = responseAxios.data;
    } catch (error) {
      if (error.name != 'CanceledError') {
        notificar(error);
      }
      response = { error };
    }
  }
  return response;
};

export const postRequest = async (params) => {
  let response = {};
  if (validateParameters(params)) {
    try {
      const responseAxios = await request.post(params.url, params.body, {
        responseType: 'json',
        signal: params?.signal,
        params: params?.params,
      });
      response = responseAxios.data;
      notificarSuccess(response);
    } catch (error) {
      if (error.name != 'CanceledError') {
        notificar(error);
      }
      response = { error };
    }
  }
  return response;
};

export const postUploadRequest = async (params) => {
  let response = {};
  if (validateParameters(params)) {
    try {
      const responseAxios = await request.post(params.url, params.form, {
        signal: params?.signal,
        responseType: 'json',
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: params.onProgress,
        params: params?.params,
      });
      response = responseAxios.data;
      notificarSuccess(response);
    } catch (error) {
      if (error.name != 'CanceledError') {
        notificar(error);
      }
      response = { error };
    }
  }
  return response;
};

export const putRequest = async (params) => {
  let response = {};
  if (validateParameters(params)) {
    try {
      const responseAxios = await request.put(params.url, params.body, {
        responseType: 'json',
        signal: params?.signal,
        params: params?.params,
      });
      response = responseAxios.data;
      notificarSuccess(response);
    } catch (error) {
      if (error.name != 'CanceledError') {
        notificar(error);
      }
      response = { error };
    }
  }
  return response;
};

export const putUploadRequest = async (params) => {
  let response = {};
  if (validateParameters(params)) {
    try {
      const responseAxios = await request.put(params.url, params.form, {
        signal: params?.signal,
        responseType: 'json',
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: params.onProgress,
        params: params?.params,
      });
      response = responseAxios.data;
      notificarSuccess(response);
    } catch (error) {
      if (error.name != 'CanceledError') {
        notificar(error);
      }
      response = { error };
    }
  }
  return response;
};

export const deleteRequest = async (params) => {
  let response = {};
  if (validateParameters(params)) {
    try {
      const responseAxios = await request.delete(params.url, { signal: params.signal, params: params?.params });
      response = responseAxios.data;
      notificarSuccess(response);
    } catch (error) {
      if (error.name != 'CanceledError') {
        notificar(error);
      }
      response = { error };
    }
  }
  return response;
};

const validateParameters = (params) => {
  let successfully = true;
  if (typeof params.url !== 'string') {
    successfully = false;
    throw 'url: undefined string';
  }
  return successfully;
};

export const pathFilesApi = () => {
  return URL_FILES;
};

export const urlPhoto = () => {
  return URL_FILES;
};

export const imageExists = (imageUrl) => {
  var http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, false);
  http.send();

  if (http.status === 404 || http.status === 401) {
    return false;
  }
  return true;
};
