import { DivFormGroup, FormInput, Input } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postUploadRequest, URL_FILES, urlPhoto, postRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const FORMAS_PAGOS = [
  { value: 'PAGO_EN_SITIO', label: 'PAGO EN SITIO' },
  { value: 'TRANSFERENCIA', label: 'TRANSFERENCIA' },
  { value: 'PAGO_CON_TARJETA', label: 'PAGO CON TARJETA' },
  { value: 'POLIZA', label: 'POLIZA' },
];

const FirmaCliente = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    isLoadingForm: true,
    fecha_hora_inicio: moment().format('YYYY-MM-DD HH:mm'),
    fecha_hora_fin: moment().format('YYYY-MM-DD HH:mm'),
    quien_recibe: '',
    observaciones: '',
    no_concluyo_trabajo: '',
    comentarios_adicionales_cliente: '',
    ordenServicioId: null,
    observacionId: null,
    numero_orden_servicio: '',
    orden_servicio: null,
    actualizar: false,
    forma_pago: null,
    poliza: '',
    correo: '',
    telefono: '',
    showModalPagoEnLinea: false,
    firma_cliente: null,
  });

  const canvasRef = useRef(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { visitaId } = params;
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    const observacion = data.observacion || {};
    const contacto = data.ordenServicioId.contacto_solicitante;
    setState((prevState) => ({
      ...prevState,
      isLoadingForm: false,
      actualizar: observacion ? true : false,
      observacionId: observacion ? observacion._id : '',
      ordenServicioId: data.ordenServicioId._id,
      fecha_hora_inicio: observacion ? moment(observacion.fecha_hora_inicio).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: observacion ? moment(observacion.fecha_hora_fin).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      observaciones: observacion ? observacion.observaciones : '',
      quien_recibe: data.quien_recibe || '',
      no_concluyo_trabajo: observacion ? observacion.no_concluyo_trabajo : '',
      comentarios_adicionales_cliente: observacion ? observacion.comentarios_adicionales_cliente : '',
      orden_servicio: data.ordenServicioId,
      numero_orden_servicio: data.ordenServicioId.numero_orden_servicio,
      total_cotizacion: data.ordenServicioId?.cotizacionId?.total || 0,
      total_cotizacion_adicional: data.ordenServicioId?.cotizacionAdicionalId?.total || 0,
      cotizacionId: data.ordenServicioId?.cotizacionId?._id,
      cotizacionAdicionalId: data.ordenServicioId?.cotizacionAdicionalId?._id,
      forma_pago: data.forma_pago ? FORMAS_PAGOS.find((x) => x.value === data.forma_pago) : null,
      poliza: data.poliza || '',
      estatus: data.estatus,
      correo: contacto?.emial || '',
      telefono: contacto?.phone || '',
      firma_cliente: data.firma_cliente,
    }));
    // setTimeout(() => {
    //   if (!canvasRef.current) return;
    //   const canvas = canvasRef.current.getCanvas();
    //   const context = canvas.getContext('2d');
    //   const base_image = new Image();
    //   base_image.crossOrigin = 'Anonymous';
    //   base_image.src = urlPhoto() + data.firma_cliente.photo;
    //   base_image.onload = () => {
    //     context.drawImage(base_image, 0, 0);
    //   };
    // }, 1000);
  };
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current.getCanvas();
    const context = canvas.getContext('2d');
    const base_image = new Image();
    base_image.crossOrigin = 'Anonymous';
    base_image.src = urlPhoto() + state.firma_cliente.photo;
    base_image.onload = () => {
      context.drawImage(base_image, 0, 0);
    };
  }, [state.firma_cliente, state.isLoadingForm]);

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.quien_recibe + '')) {
      validate.success = false;
      validate.message = 'Quien recibe es requerido.';
    }
    if (isEmpty(params.forma_pago + '')) {
      validate.success = false;
      validate.message = 'Forma de pago es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' || type === 'checkbox' ? checked : value }));
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const { visitaId } = params;
    const blob = DataURIToBlob(canvasRef.current.toDataURL('image/png'));
    const form = new FormData();
    const { quien_recibe, poliza } = state;
    const forma_pago = state.forma_pago ? state.forma_pago.value : '';
    form.append('quien_recibe', quien_recibe || '');
    form.append('forma_pago', forma_pago || '');
    form.append('poliza', poliza || '');
    form.append('image', blob, 'firma.png');
    if (!validations({ quien_recibe, forma_pago, poliza })) return false;
    await postUploadRequest({ url: `/ordenes-servicios-visitas/firma_cliente/${visitaId}`, form });
    navigate(`/ordenes-servicios-visitas/firma-agente/${visitaId}`);
  };

  const onSelectFormaPago = async (value) => {
    try {
      const { visitaId } = params;
      const { cotizacionId, cotizacionAdicionalId } = state;
      setState((prevState) => ({ ...prevState, forma_pago: value, isLoadingForm: true }));
      await postRequest({ url: `cotizaciones/aplicar-forma-pago`, body: { forma_pago: value.value, cotizacionId, cotizacionAdicionalId } });
      const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: false,
        total_cotizacion: data.ordenServicioId?.cotizacionId?.total || 0,
        total_cotizacion_adicional: data.ordenServicioId?.cotizacionAdicionalId?.total || 0,
        cotizacionId: data.ordenServicioId?.cotizacionId?._id,
        cotizacionAdicionalId: data.ordenServicioId?.cotizacionAdicionalId?._id,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimpiar = () => {
    canvasRef.current.clear();
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const onClickCotizacionPrincipal = async () => {
    const { cotizacionId } = state;
    const { data } = await getRequest({
      url: `cotizaciones/pdf/${cotizacionId}`,
      params: { codigos: false, desgloseKits: false, horasServicio: false },
    });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };

  const onClickOrdenDeTrabajo = async () => {
    const { visitaId } = params;
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/pdf/${visitaId}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };

  const onClickCotizacionAdicional = async () => {
    const { cotizacionAdicionalId } = state;
    const { data } = await getRequest({
      url: `cotizaciones/pdf/${cotizacionAdicionalId}`,
      params: { codigos: false, desgloseKits: false, horasServicio: false },
    });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };

  const handleSiguiente = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    navigate(`/ordenes-servicios-visitas/firma-agente/${visitaId}`);
  };

  const handleAtras = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    navigate(`/ordenes-servicios-visitas/calificaciones/${visitaId}`);
  };

  const onClickPagoEnLinea = () => {
    setState((prevState) => ({ ...prevState, showModalPagoEnLinea: true }));
  };

  const onSave = async () => {
    const { ordenServicioId, telefono, correo } = state;
    const { data } = await postRequest({
      url: `ordenes-servicio/pago/${ordenServicioId}`,
      body: { email: correo, phone: telefono },
    });
    setState((prevState) => ({ ...prevState, showModalPagoEnLinea: false }));
    if (data) {
      window.open(`${data.url}`, '_blank');
    }
  };

  const closeModal = () => {
    setState((prevState) => ({ ...prevState, showModalPagoEnLinea: false }));
  };

  const renderView = () => {
    const {
      isLoading,
      fecha_hora_inicio,
      numero_orden_servicio,
      quien_recibe,
      total_cotizacion,
      total_cotizacion_adicional,
      cotizacionId,
      cotizacionAdicionalId,
      forma_pago,
      poliza,
      estatus,
      correo,
      telefono,
    } = state;

    return (
      <>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='wrench' /> Firma de cliente
            </div>
          }
        >
          <Row>
            <Col sm='6'>
              <FormInput
                title='Numero de orden de servicio'
                placeholder='Numero de orden de servicio'
                disabled
                name='numero_orden_servicio'
                value={numero_orden_servicio}
              />
            </Col>
            <Col sm='6'>
              <DivFormGroup name='fecha_hora_inicio' title='Fecha y hora de inicio' required>
                <Input type='datetime-local' name='fecha_hora_inicio' onChange={onChange} value={fecha_hora_inicio} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                title='Nombre de quien recibe'
                placeholder='Nombre de quien recibe'
                name='quien_recibe'
                onChange={onChange}
                value={quien_recibe}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DivFormGroup name='forma_pago' title='Forma de pago' required>
                <Select
                  value={forma_pago}
                  name='forma_pago'
                  placeholder='Selecciona opciones'
                  options={FORMAS_PAGOS}
                  onChange={onSelectFormaPago}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              </DivFormGroup>
            </Col>
          </Row>
          {forma_pago && forma_pago.value === 'PAGO_EN_SITIO' && (
            <Row>
              <Col>
                <br />
                <Button onClick={onClickPagoEnLinea} variant='outline-warning' className='btn-block' type='button'>
                  PAGO EN LINEA
                </Button>
                <br />
              </Col>
            </Row>
          )}
          {forma_pago && forma_pago.value === 'POLIZA' && (
            <Row>
              <Col>
                <FormInput title='Numero de poliza' placeholder='Numero de poliza' name='poliza' onChange={onChange} value={poliza} required />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormInput
                title='Total a pagar'
                placeholder='Total a pagar'
                disabled
                name='importe_a_apagar'
                value={format(total_cotizacion + total_cotizacion_adicional, 2)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            {cotizacionId && (
              <Col sm={cotizacionAdicionalId ? '6' : '12'}>
                <br />
                <Button onClick={onClickCotizacionPrincipal} className='btn btn-block btn-success' type='button'>
                  Cotización principal
                </Button>
              </Col>
            )}
            {cotizacionAdicionalId && (
              <Col sm='6'>
                <br />
                <Button onClick={onClickCotizacionAdicional} className='btn btn-block btn-success' type='button'>
                  Cotización de adicionales
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <br />
              <Button onClick={onClickOrdenDeTrabajo} className='btn btn-block btn-primary' type='button'>
                Orden de trabajo
              </Button>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>Firme del cliente aquí</h3>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <div style={{ border: '1px solid black', display: 'inline-block', textAlign: 'center' }}>
                <SignatureCanvas
                  ref={canvasRef}
                  penColor='black'
                  canvasProps={{ width: 300, height: 150, className: 'sigCanvas', margin: '0 auto' }}
                />
              </div>
            </Col>
          </Row>
        </Panel>
        <Card>
          <Card.Footer>
            <Row>
              <Col>
                <Saving saving={isLoading} />
                <Button onClick={handleAdd} className='btn btn-primary pull-right  mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                <Button variant='warning' className='pull-right mr-5' onClick={handleLimpiar}>
                  Limpiar
                </Button>
                {estatus === 'FINALIZADO' && (
                  <>
                    <Button onClick={handleSiguiente} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-right' /> Siguente (sin guardar)
                    </Button>
                    <Button onClick={handleAtras} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-left' /> Atrás (sin guardar)
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        <CbModal show={state.showModalPagoEnLinea} onSave={onSave} onClose={closeModal} title='Pago en linea' size='lg'>
          <Row>
            <Col>
              <FormInput title='Correo electronico' name='correo' onChange={onChange} value={correo} required type='email' />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput title='Telefono' name='telefono' onChange={onChange} value={telefono} required type='tel' />
            </Col>
          </Row>
        </CbModal>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Detalles del trabajo'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

FirmaCliente.propTypes = {};

export default FirmaCliente;
