import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteAgentes = ({ agenteId, nombre, refresh }) => {
  return (
    <Confirmation
      btnIcon='remove'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`agentes/${agenteId}`}
      isMenu
      description={`¿Confirma que desea eliminar el agente <strong><u> ${nombre}</u> </strong>?`}
      loadData={refresh}
    />
  );
};

DeleteAgentes.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  agenteId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default DeleteAgentes;
