import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import RowVisitasPorAtender from './RowVisitasPorAtender';
import PropTypes from 'prop-types';

const TableVisitasPorAtender = ({ visitasPorAtender }) => {
  if (visitasPorAtender.length == 0) return null;
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>Ordenes de servicio por atender</Card.Title>
          </Card.Header>
          <div className='table-responsive'>
            <table className='table'>
              <tbody>
                {visitasPorAtender.map((a, i) => (
                  <RowVisitasPorAtender key={i} a={a} />
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

TableVisitasPorAtender.propTypes = {
  visitasPorAtender: PropTypes.array,
};

export default TableVisitasPorAtender;
