import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Loading, Module, Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { Row, Col, Card, Button, OverlayTrigger, Popover, InputGroup, FormGroup } from 'react-bootstrap';
import DateRangePickerEs from '@components/DateRangePickerEs';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { URL_FILES } from '@utils/api';
import TextCurrency from '@controls/TextCurrency';
// const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const ReporteOrdenesServicios = () => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [data, setData] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().startOf('month').toDate());
    const [fechaFin, setFechaFin] = useState(moment().endOf('month').toDate());
    const [loading, setLoading] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const handleSearch = async () => {
        setLoading(true);
        const { data } = await postRequest({ url: `reportes/horas-trabajadas`, body: { fechaInicio, fechaFin } });
        setData(data);
        let detalles = [];
        data.forEach(item => {
            item.sucursales.forEach(sucursal => {
                detalles = [...detalles, ...sucursal.detalles.map(x => x.codigo)];
            });
        });
        detalles = [...new Set(detalles)];
        const sucursalesUnificadas = data.reduce((acc, item) => {
            item.sucursales.forEach(sucursal => {
                if (!acc.some(existingSucursal => existingSucursal._id === sucursal.sucursal._id)) {
                    acc.push({
                        _id: sucursal.sucursal._id,
                        name: sucursal.sucursal.name,
                        detalles
                    });
                }
            });
            return acc;
        }, []);
        setSucursales(sucursalesUnificadas);
        setLoading(false);
    };

    useEffect(() => {
        handleSearch();
    },[fechaInicio, fechaFin]);

    const handleSelect = ({ startDate, endDate }) => {
        setFechaInicio(startDate);
        setFechaFin(endDate);
        setShow(false);
    };


    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const getDestajoAgente = (item) => {
        let total = 0;
        item.sucursales.forEach(sucursal => {
            sucursal.detalles.forEach(detalle => {
                total += detalle?.destajo || 0;
            });
        });
        return total;
    };

    const renderList = () => {
        return (
            <>
            <Card>
                <div className='table-responsive'>
                    <table className='table table-striped table-hover table-bordered'>
                        <thead>
                            <tr>
                                <th rowSpan={2}>Agentes</th>
                                <th rowSpan={2}>Servicios</th>
                                <th rowSpan={2}>Horas</th>
                                <th rowSpan={2}>Destajo</th>
                                { sucursales.map((sucursal) => (<th key={sucursal._id} colSpan={sucursal.detalles.length}>{sucursal.name}</th>)) }
                            </tr>
                            <tr>
                                { sucursales.map((sucursal) => sucursal.detalles.map((detalle, index) => (
                                    <th key={index} style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)' }} colSpan={2}>{detalle}</th>
                                ))) }
                            </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.agente.nombre}</td>
                                    <td>{item.servicios}</td>
                                    <td>{item.horas}</td>
                                    <td>
                                        <TextCurrency>{ getDestajoAgente(item) }</TextCurrency>
                                    </td>
                                    {
                                        sucursales.map((sucursal) => {
                                            const sucursalData = item.sucursales?.find(suc => suc.sucursal._id === sucursal._id);
                                            return sucursal?.detalles.map((detalle, index) => {
                                                const servSuc = sucursalData?.detalles?.find(x => x.codigo === detalle);
                                                const cantidad = servSuc?.cantidad || 0;
                                                const destajo = servSuc?.destajo || 0;
                                                return (
                                                    <Fragment key={index}>
                                                        <td key={index} style={{ textAlign: 'right' }}>
                                                            {cantidad}
                                                        </td>
                                                        <td key={index} style={{ textAlign: 'right' }}>
                                                           <TextCurrency>{ destajo }</TextCurrency>
                                                        </td>
                                                    </Fragment>
                                                );
                                            });
                                        })
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </Card>
            </>
        );
    };

    const handleExcel = async () => {
        setLoading(true);
        const { data } = await postRequest({ url: `reportes/horas-trabajadas-excel`, body: { fechaInicio, fechaFin } });
        if (data?.url) {
            window.open(URL_FILES + data.url);
        }
        setLoading(false);
    };

    const handleExcelDetallado = async () => {
        setLoading(true);
        const { data } = await postRequest({ url: `reportes/horas-trabajadas-detallado-excel`, body: { fechaInicio, fechaFin } });
        if (data?.url) {
            window.open(URL_FILES + data.url);
        }
        setLoading(false);
    };

    return (
        <Module title='Ordenes de Servicios'>
            <Row>
                <Col xs={12}>
                    <Card style={{ marginBottom: '10px' }}>
                        <Card.Body style={{ paddingTop: '10px', paddingBottom: '7px' }}>
                        <Row>
                            <div className={'col-xs-12'}>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup className='pull-right'>
                                            <InputGroup>
                                                <Button variant='outline-success' onClick={handleExcel}>
                                                    <Icon icon='file' /> Excel
                                                </Button>
                                                <Button variant='outline-success' onClick={handleExcelDetallado}>
                                                    <Icon icon='file' /> Detallado
                                                </Button>
                                                <OverlayTrigger
                                                    show={show}
                                                    target={target}
                                                    container={ref.current}
                                                    placement='bottom'
                                                    overlay={
                                                        <Popover id='popover-basic' style={{ maxWidth: '100%' }}>
                                                        <Popover.Header as='h3'>Rango de Fechas</Popover.Header>
                                                        <Popover.Body style={{ padding: 0 }}>
                                                            <DateRangePickerEs startDateInit={fechaInicio} endDateInit={fechaFin} onChange={handleSelect} />
                                                        </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                <Button variant='outline-primary' onClick={handleClick}>
                                                    <Icon icon='calendar' /> {fechaInicio.toLocaleDateString()} - {fechaFin.toLocaleDateString()}
                                                </Button>
                                                </OverlayTrigger>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>    
            <Loading loading={loading}>
                {renderList()}
            </Loading>
        </Module>
    );
};

export default ReporteOrdenesServicios;
