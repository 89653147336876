import Confirmation from '@controls/Confirmation';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import Edit from './Edit';
import { ButtonGroup } from 'react-bootstrap';

const ListRow = ({ _id, nombre, clave, loadData }) => {
  return (
    <tr>
      <td>{clave}</td>
      <td>{nombre}</td>
      <td>
        <ButtonGroup>
          <IfPermission action='editar'>
            <Edit itemId={_id} {...{ _id, nombre, clave, loadData }} />
          </IfPermission>
          <IfPermission action='eliminar'>
            <Confirmation
              btnIcon='trash'
              typeRequest='deleteRequest'
              btnType='outline-danger btn-sm'
              action='Eliminar'
              url={`tipos-monitoreos/${_id}`}
              description={`¿Confirma que desea eliminar el tipo de monitoreo <strong><u>  ${clave} - ${nombre}</u> </strong>?`}
              loadData={loadData}
            />
          </IfPermission>
        </ButtonGroup>
      </td>
    </tr>
  );
};

ListRow.propTypes = {
  _id: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  clave: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default ListRow;
