import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postUploadRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [description, setDescription] = useState('');

  const close = () => {
    setShowModal(false);
    setDescription('');
    setFile(null);
    setImagePreviewUrl('');
  };

  const open = () => {
    setShowModal(true);
    setDescription('');
    setFile(null);
    setImagePreviewUrl('');
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      description: description.trim().toUpperCase(),
    };

    const form = new FormData();
    form.append('description', description.trim().toUpperCase());
    form.append('image', file);

    if (validations(data)) {
      await postUploadRequest({ url: 'configurations_carrusel', form });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.description)) {
      validate.success = false;
      validate.message = 'Nombre es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const handleImageChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Descripcion' name='description' required onChange={(e) => setDescription(e.target.value)} value={description} />
          </Col>
          <Col>
            <DivFormGroup>
              <input
                type='file'
                name='image'
                style={{ display: 'none' }}
                ref={(ref) => (this.image = ref)}
                accept='image/jpg, image/jpeg, image/png'
                onChange={handleImageChange}
              />
              <Button type='button' className='btn btn-primary' onClick={() => this.image.click()}>
                <Icon icon='picture' /> Selecciona una imagen
              </Button>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <img id='input_img' src={imagePreviewUrl} className='img-responsive' />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
