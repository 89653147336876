import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';

const DetailServicios = ({ codigo, nombre, listaServicios }) => {
  const [showModal, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
  };

  const type = listaServicios.filter((x) => x?.servicioId?.status !== 'ACTIVO').length > 0 ? 'danger' : 'success';

  return (
    <>
      <CbButtonOver onClick={open} title='Ver detalle' icon='list' type={type} size='sm' />
      <CbModal show={showModal} onClose={close} title={`Detalle de Servicios - ${codigo} - ${nombre}`} size='xl'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='10%'>Código</th>
              <th width='60%'>Servicio</th>
              <th width='10%'>Cantidad</th>
              <th width='30%'>Medida</th>
            </tr>
          </thead>
          <tbody>
            {listaServicios
              .filter((x) => x.servicioId)
              .map((e, i) => {
                const {
                  servicioId: { name, code, _id, status },
                  cantidad,
                  measureId,
                } = e;
                return (
                  <tr key={i} className={status !== 'ACTIVO' && 'danger'} title={status !== 'ACTIVO' && 'Dado de baja o eliminado'}>
                    <td>
                      <Link to={`/servicios/detalle/${_id}`}>{code}</Link>
                    </td>
                    <td>{name}</td>
                    <td>{cantidad}</td>
                    <td>
                      {measureId?.measure}{' '}
                      {status != 'ACTIVO' && (
                        <CbBadge type='danger pull-right' title='Dado de baja o Eliminado'>
                          <Icon icon='warning-sign'></Icon>
                        </CbBadge>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </CbModal>
    </>
  );
};

DetailServicios.propTypes = {
  id: PropTypes.number.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  listaServicios: PropTypes.array,
};

export default DetailServicios;
