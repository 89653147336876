import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, DropdownButton, DropdownItem, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Required from '@controls/Required';
import RowDetalle from './components/RowDetalle';
import { validateUpdate } from '@validations/solicitudCompra';
import SelectAgente from '@components/forms/SelectAgente';
import SelectEquipoServicio from '@components/forms/SelectEquipoServicio';
import SelectSucursal from '@components/forms/SelectSucursal';
import Select from '@components/forms/Select';

const Edit = ({ params, navigate, notification }) => {
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fecha_entrega: null,
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    measures: [],
    detalles: [],
    observaciones: '',
    estatus: 'INICIADA',
    sucursalId: null,
    productoName: '',
    productoObj: null,
    tipoCambio: 0,
    usa_almacen: false,
    usa_servicios: false,
    usa_gastos: false,
    tipo_partida: 'EQUIPO',
    tipos_partidas: [
      { value: 'EQUIPO', label: 'EQUIPO' },
      { value: 'SERVICIO', label: 'SERVICIO' },
    ],
    estatus_solicitud: [{ value: 'INICIADA', label: 'INICIADA' }],
  });

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
  };

  const onChangeProducto = (itemSelect) => {
    const { monedaId, tipoCambio, tipo_partida, detalles } = state;
    if (itemSelect) {
      const producto = itemSelect.row;
      let precio = producto.last_cost;
      if (monedaId !== producto.moneda) {
        if (monedaId === MONEDA.PESO && producto.moneda === MONEDA.DOLAR) {
          precio = producto.last_cost * cleanNumber(tipoCambio);
        } else if (monedaId === MONEDA.DOLAR && producto.moneda === MONEDA.PESO) {
          precio = producto.last_cost / cleanNumber(tipoCambio);
        }
      }
      let measure = null,
        measureId = null,
        measures = [];
      if (tipo_partida === 'EQUIPO') {
        measure =
          producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure ? producto.equipmentsMeasureId.measure : producto.equipmentsMeasureId;
        measureId =
          producto.equipmentsMeasureId && producto.equipmentsMeasureId._id ? producto.equipmentsMeasureId._id : producto.equipmentsMeasureId;
      } else {
        if (producto.listCost.length > 0) {
          measures = state.measures.filter((x) => {
            const existe = producto.listCost.map((x) => x.measureId).includes(x.value);
            return existe;
          });
        } else {
          measures = state.measures.filter((x) => x.tipo === 'SERVICIO');
        }
      }
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        producto: '',
        productoObj: '',
        detalles: [
          ...detalles,
          {
            ...producto,
            relacionadoId: producto._id,
            descripcion: producto.name.toUpperCase(),
            tipo_partida: tipo_partida,
            noIdentificador: producto.code,
            moneda_original: producto.moneda,
            precio_original: producto.last_cost,
            measure,
            measures,
            measureId,
            precio,
            cantidad: 1,
            descuento: 0,
            impuesto: 0,
            importe: precio,
          },
        ],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        productoObj: null,
        producto: null,
      }));
    }
  };

  const onChangeDetalle = (name, index, event) => {
    if (['cantidad', 'descuento', 'impuesto', 'precio'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: [
          ...prevState.detalles.map((x, i) => {
            if (i === index) x[name] = cleanNumber(event.target.value);
            return { ...x };
          }),
        ],
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...prevState.detalles.map((x, i) => {
          if (i === index) {
            x[name] = event.target.value;
          }
          return { ...x };
        }),
      ],
    }));
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect.row,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const handleRegisterCompra = async (event) => {
    event.preventDefault();
    const {
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      tipoCambio,
      total,
      subtotal,
      descuento,
      impuestos,
      agenteId,
      usoCfdiId,
      observaciones,
      detalles,
    } = state;

    const data = {
      numero_solicitud: numeroOrden,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      estatus_solicitud: estatus,
      sucursalId,
      agenteId,
      tipoCambio,
      fecha_entrega,
      total: total || 0,
      subtotal: subtotal || 0,
      descuento: descuento || 0,
      iva: impuestos || 0,
      usoCfdiId,
      observaciones,
      detalles: detalles.map((detalle) => {
        return {
          ...detalle,
        };
      }),
    };

    if (validations(data)) {
      const { solicitudCompraId } = params;
      await putRequest({ url: `solicitudes-compras/${solicitudCompraId}`, body: data });
      setTimeout(() => navigate('/solicitudes-compras'), 1000);
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return error;
  };

  useEffect(() => {
    const fetchData = async () => {
      const { solicitudCompraId } = params;
      const fecha = moment().format('YYYY-MM-DD');
      const { data: tipoCambioData } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
      if (!tipoCambioData?._id) {
        return notification({
          title: 'Advertencia',
          message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
          type: 'warning',
        });
      }
      const { data: catalogosData } = await getRequest({ url: `compras-catalogos` });
      const { data: solicitudCompraData } = await getRequest({ url: `solicitud-compra/${solicitudCompraId}` });
      setState((prevState) => ({
        ...prevState,
        sucursales: catalogosData.sucursales,
        measures: catalogosData.measures,
        tipos_compras: catalogosData.tipos_compras,
        ubicaciones: catalogosData.ubicaciones,
        usos_cfdi: catalogosData.usos_cfdi,
        monedas: catalogosData.monedas,
        agenteId: catalogosData.agente._id,
        maximo_descuento: catalogosData.agente.maximo_descuento,
        agenteName: catalogosData.agente.nombre,
        isLoadingForm: false,
        showModal: true,
        numeroOrden: solicitudCompraData.encabezado.numero_solicitud,
        fecha: moment(solicitudCompraData.encabezado.fecha).format('YYYY-MM-DD'),
        estatus: solicitudCompraData.encabezado.estatus_solicitud,
        sucursalId: solicitudCompraData.encabezado.sucursal._id,
        monedaId: solicitudCompraData.encabezado.moneda,
        tipoCambio: solicitudCompraData.encabezado.tipo_cambio,
        fecha_entrega: solicitudCompraData.encabezado.fecha_entrega
          ? moment(solicitudCompraData.encabezado.fecha_entrega).format('YYYY-MM-DD')
          : null,
        total: solicitudCompraData.encabezado.importe,
        subtotal: solicitudCompraData.encabezado.sub_total,
        descuento: solicitudCompraData.encabezado.descuento,
        iva: solicitudCompraData.encabezado.iva,
        agente: solicitudCompraData.encabezado.agente._id,
        agenteObj: { value: solicitudCompraData.encabezado.agente._id, label: solicitudCompraData.encabezado.agente.nombre },
        observaciones: solicitudCompraData.encabezado.observaciones,
        detalles: solicitudCompraData.detalle.map((x) => {
          let measure = null,
            measureId = null,
            measures = [];
          measure = x.unidadMedida;
          measureId = x.unidadMedidaId;
          if (x.tipo_partida === 'EQUIPO') {
            // ALGO
          } else {
            if (x.relacionadoId && x.relacionadoId.listCost && x.relacionadoId.listCost.length > 0) {
              measures = state.measures.filter((y) => x.relacionadoId.listCost.map((z) => z.measureId).includes(y.value));
            } else {
              measures = state.measures.filter((y) => y.tipo === 'SERVICIO');
            }
          }
          return {
            ...x,
            measure,
            measureId,
            measures,
            tipo_partida: x.tipo_partida,
            noIdentificador: x.codigo,
            moneda_original: x.moneda,
            precio_original: x.last_cost,
            impuesto: x.iva,
          };
        }),
      }));
    };
    fetchData();
  }, [params, notification]);

  const renderView = () => {
    const { numeroOrden, fecha, estatus, sucursalId, isLoading, productoObj, observaciones, estatus_solicitud, tipo_partida, agenteObj } = state;
    return (
      <form onSubmit={handleRegisterCompra}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='4'>
                <FormInput title='Número de orden' placeholder='Num. de orden' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
              </Col>
              <Col sm='4'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </Col>
              <Col sm='4'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} options={estatus_solicitud} onChange={onSelect.bind(this, 'estatus')} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={onSelect.bind(this, 'sucursalId')} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='10'>
                <DivFormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <DropdownButton title={tipo_partida} id={`dropdown-basic`}>
                        {state.tipos_partidas.map((x, i) => (
                          <DropdownItem
                            key={i}
                            eventKey={i + 1}
                            active={x.value === tipo_partida}
                            onClick={() => setState((prevState) => ({ ...prevState, tipo_partida: x.value }))}
                          >
                            {x.label}
                          </DropdownItem>
                        ))}
                      </DropdownButton>
                    </InputGroup.Text>
                    <SelectEquipoServicio value={productoObj} onChange={onChangeProducto} name='productoObj' />
                  </InputGroup>
                </DivFormGroup>
              </Col>
              <Col sm='2'>
                <Link to={`${tipo_partida === 'EQUIPO' ? '/equipos' : '/servicios'}/nuevo`} className='btn btn-success pull-right'>
                  <Icon icon='plus' /> Nuevo
                </Link>
              </Col>
            </Row>
          </Card.Body>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  Descripción <Required />
                </th>
                <th>
                  Unidad <Required />
                </th>
                <th>
                  Cantidad <Required />
                </th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <CbTableBody colSpan={5}>
              {state.detalles.map((detalle, i) => (
                <RowDetalle key={i} detalle={detalle} i={i} onChangeDetalle={onChangeDetalle} onClickRemoveDetalle={onClickRemoveDetalle} />
              ))}
            </CbTableBody>
          </CbPanelTableResponsive>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegisterCompra} variant='success'  className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </form>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de compras' title='Editar solicitud de compra'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Edit.propTypes = {
  params: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
};

export default Edit;
