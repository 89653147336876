import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DivFormGroup } from '@controls';
import { Icon } from '@stateless';
import { Button } from 'react-bootstrap';

const AdjuntImage = ({ loadFile }) => {
  const imageRef = useRef(null);

  const handleImageChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    loadFile(file);
  };

  return (
    <DivFormGroup>
      <input
        type='file'
        name='image'
        style={{ display: 'none' }}
        ref={imageRef}
        accept='image/jpg, image/jpeg, image/png'
        onChange={handleImageChange}
      />
      <Button type='button' variant='primary' onClick={() => imageRef.current.click()}>
        <Icon icon='picture' /> Selecciona una imagen
      </Button>
    </DivFormGroup>
  );
};

AdjuntImage.propTypes = {
  loadFile: PropTypes.func.isRequired,
};

export default AdjuntImage;
