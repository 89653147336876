import DivFormGroup from '@controls/DivFormGroup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownItem, Modal, Row, Col, Button } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { putRequest } from '@utils/api';

const ResetPassword = ({ id, email: initialEmail, login_activo: initialLoginActivo, refresh }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [loginActivo, setLoginActivo] = useState(false);

  const close = (event) => {
    if (event) event.preventDefault();
    setShowModal(false);
    setIsLoading(false);
  };

  const open = () => {
    setShowModal(true);
    setEmail(initialEmail);
    setLoginActivo(initialLoginActivo);
  };

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setLoginActivo(checked);
    } else {
      if (name === 'email') setEmail(value);
      if (name === 'password') setPassword(value);
      if (name === 'repassword') setRepassword(value);
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { email, login_activo: loginActivo, password, repassword };
    await putRequest({ url: `customer/login/${id}`, body: data });
    refresh();
    close();
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='lock' /> Plataforma de clientes
      </DropdownItem>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Plataforma de clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEdit}>
            <Row>
              <Col>
                <DivFormGroup>
                  <div className='checkbox'>
                    <label>
                      <input type='checkbox' name='login_activo' checked={loginActivo} onChange={onChange} /> Habilitar inicio de sesión
                    </label>
                  </div>
                </DivFormGroup>
                <DivFormGroup name='password' required title='Correo electronico'>
                  <input
                    type='text'
                    className='form-control'
                    name='email'
                    placeholder='Correo electronico'
                    autoComplete='off'
                    value={email}
                    onChange={onChange}
                    disabled={!loginActivo}
                  />
                </DivFormGroup>
                <DivFormGroup name='password' required title='Nueva contraseña'>
                  <input
                    type='password'
                    className='form-control'
                    name='password'
                    placeholder='Nueva contraseña'
                    autoComplete='off'
                    value={password}
                    onChange={onChange}
                    disabled={!loginActivo}
                  />
                  <small className='text-center'>Debe contener entre 8 y 10 caracteres</small>
                </DivFormGroup>
                <DivFormGroup name='repassword' required title='Confirmar nueva contraseña'>
                  <input
                    type='password'
                    className='form-control'
                    name='repassword'
                    placeholder='Confirmar nueva contraseña'
                    autoComplete='off'
                    value={repassword}
                    onChange={onChange}
                    disabled={!loginActivo}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleEdit} variant='success' className=' pull-right' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className=' pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ResetPassword.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  login_activo: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ResetPassword;
