import { DivFormGroup } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, FormGroup, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';

const Import = () => {
  const [tipo, setTipo] = useState('C');
  const [isLoading, setIsLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [csv, setCsv] = useState(null);
  const [valid, setValid] = useState(false);
  const [msg, setMsg] = useState('');
  const [progress, setProgress] = useState(0);
  const frm = useRef(null);

  useEffect(() => {}, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'tipo') {
      setTipo(value);
      setCsv(null);
      setValid(false);
      setMsg('');
      setProgress(0);
    }
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setCargando(true);
    setProgress(0);
    setCsv(null);
    setIsLoading(true);

    const form = new FormData(frm.current);
    const { csv, valid, msg } = await postUploadRequest({ url: `importar-municipios-csv`, form });
    setCsv(csv);
    setValid(valid);
    setMsg(msg);
    setCargando(false);
    setProgress(0);
    setIsLoading(false);
  };

  const renderForm = () => {
    return (
      <Module onClickBack={window.history.back} title='Importar municipios'>
        <Card>
          <Card.Body>
            <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
              <Row>
                <Col sm='12'>
                  <FormGroup className='inline'>
                    <label>Tipo archivo</label>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='tipo' id='rdo-clickbalance' onChange={onChange} defaultChecked={true} value={'C'} /> Normal
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup>
                    <div className='btn btn-default btn-file'>
                      <Icon icon='paperclip' /> Archivo
                      <input type='file' name='csv' id='csv' onChange={handleReadCSV} />
                    </div>
                  </DivFormGroup>
                </Col>
                <Col sm='9'>{isLoading ? <Saving action='Importantando' /> : ''}</Col>
                <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
              </Row>
            </form>
            <form>{csv !== null ? renderDataCSV() : null}</form>
          </Card.Body>
        </Card>
      </Module>
    );
  };

  const renderDataCSV = () => {
    const { detalle, headers } = csv;

    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12' style={{ position: 'relative', overflowX: 'auto', width: '100%' }}>
          <table className='table table-striped align-middle'>
            <thead>
              <tr>
                <th>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => (
                  <th key={i}>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detalle.length > 0 ? (
                detalle.map((d, i) => {
                  let datoCreacion = 0;
                  switch (tipo) {
                    case 'C':
                      datoCreacion = 29;
                      break;
                  }
                  return (
                    <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                      <td>{d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}</td>
                      {Object.values(d).map((e, j) => (
                        <td key={j}>{e}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <RowsNotFound colSpan={7} />
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  return renderForm();
};

Import.propTypes = {};

export default Import;
