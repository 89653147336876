import { RowItem } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

const SellerSelected = ({ sellerSelected: { name, fatherLastName, motherLastName } }) => {
  return (
    <div>
      <RowItem label=''>
        {name} {fatherLastName} {motherLastName}
      </RowItem>
      <Col>
        <span>
          <strong>Teléfono</strong>{' '}
        </span>
        <br />
        <span>
          <strong>Correo electrónico</strong>{' '}
        </span>
      </Col>
      <Col>
        <span>
          <strong>Celular</strong>
        </span>
      </Col>
    </div>
  );
};

SellerSelected.propTypes = {
  sellerSelected: PropTypes.object.isRequired,
};

export default SellerSelected;
