import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const Row = ({ row, item, ...props }) => {
  const { _id, nombre, clave, multiples } = row;
  return (
    <tr key={item}>
      <td>{clave}</td>
      <td>{nombre}</td>
      <td>{multiples ? 'Si' : 'No'}</td>
      <td>
        <IfPermission action='eliminar'>
          <Delete id={item} itemId={_id} {...props} clave={clave} nombre={nombre} />
        </IfPermission>
        <IfPermission action='editar'>
          <Edit id={item} itemId={_id} clave={clave} nombre={nombre} multiples={multiples} {...row} {...props} />
        </IfPermission>
      </td>
    </tr>
  );
};

Row.propTypes = {
  row: PropTypes.object.isRequired,
  item: PropTypes.number.isRequired,
};

const List = ({ data, ...props }) => {
  const renderRows = () => {
    return data.map((row, item) => <Row key={item} row={row} item={item} {...props} />);
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Clave</th>
          <th width='30%'>Nombre</th>
          <th width='20%'>Múltiples documentos</th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
