import { DivFormGroup, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputPercentage from '@components/forms/InputPercentage';

const EditEsquemas = ({ esquemasId, plazos, loadEsquemas }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [esquema, setEsquema] = useState('');
  const [minimo, setMinimo] = useState(0);
  const [maximo, setMaximo] = useState(0);
  const [descuentoMinimo, setDescuentoMinimo] = useState(0);
  const [descuentoMaximo, setDescuentoMaximo] = useState(0);
  const [listPlazos, setListPlazos] = useState([]);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setEsquema('');
    setListPlazos([]);
  };

  const open = async () => {
    const {
      data: { esquema, listPlazos, anticipos, descuento },
    } = await getRequest({ url: `esquemas/${esquemasId}` });
    setShowModal(true);
    setEsquema(esquema);
    setListPlazos(listPlazos);
    setMinimo(anticipos.minimo);
    setMaximo(anticipos.maximo);
    setDescuentoMinimo(descuento.minimo);
    setDescuentoMaximo(descuento.maximo);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const collectionPlazos = [];

    plazos.forEach((p) => {
      if (document.getElementById(p._id).checked) {
        collectionPlazos.push({
          plazoId: p._id,
        });
      }
    });

    const data = {
      esquema: esquema.trim(),
      minimo: clean(minimo),
      maximo: clean(maximo),
      descuento_minimo: clean(descuentoMinimo),
      descuento_maximo: clean(descuentoMaximo),
      plazos: collectionPlazos,
    };

    if (validations(data)) {
      await putRequest({ url: `esquemas/${esquemasId}`, body: data });
      loadEsquemas();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.esquema + '')) {
      validate.success = false;
      validate.message = 'Esquema de pago es requerido.';
    } else if (validate.success && params.minimo > params.maximo) {
      validate.success = false;
      validate.message = 'Anticipo mínimo no puede ser mayor que anticipo máximo.';
    } else if (validate.success && params.descuento_minimo > params.descuento_maximo) {
      validate.success = false;
      validate.message = 'Descuento mínimo no puede ser mayor que descuento máximo.';
    }

    const { plazos } = params;

    if (validate.success && plazos.length > 0) {
      const unique = [...new Set(plazos.map((a) => a.plazoId))];

      if (unique.length !== plazos.length) {
        validate.success = false;
        validate.message = 'Hay plazos duplicados, favor de corregir.';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const renderRowsPlazos = () => {
    return plazos.map((row, i) => {
      const { _id, plazo, taza } = row;
      const label = `${plazo} meses ${taza !== null ? '- Tasa ' + taza + '%' : ''}`;

      const find = listPlazos.filter((p) => p._id === _id);
      const has = find.length > 0;

      return (
        <tr key={i}>
          <td>{label}</td>
          <td>
            <input id={_id} name={_id} type='checkbox' defaultChecked={has} />
          </td>
        </tr>
      );
    });
  };

  const rowsFormPlazos = renderRowsPlazos();

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} isLoading={isLoading} title='Editar'>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='esquema' title='Esquema de Pago' required>
              <TextArea name='esquema' onChange={(e) => setEsquema(e.target.value)} value={esquema} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='minimo' title='Anticipo mínimo'>
              <InputPercentage name='minimo' value={minimo} onChange={(e) => setMinimo(e.target.value)} />
            </DivFormGroup>
            <DivFormGroup name='maximo' title='Anticipo máximo'>
              <InputPercentage name='maximo' value={maximo} onChange={(e) => setMaximo(e.target.value)} />
            </DivFormGroup>
            <DivFormGroup name='descuento_minimo' title='Descuento mínimo'>
              <InputPercentage name='descuento_minimo' value={descuentoMinimo} onChange={(e) => setDescuentoMinimo(e.target.value)} />
            </DivFormGroup>
            <DivFormGroup name='descuento_maximo' title='Descuento máximo'>
              <InputPercentage name='descuento_maximo' value={descuentoMaximo} onChange={(e) => setDescuentoMaximo(e.target.value)} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <table className='table table-striped align-middle'>
              <thead>
                <tr>
                  <th width='80%'>Plazos</th>
                  <th width='20%'>Selecciona</th>
                </tr>
              </thead>
              <tbody>{rowsFormPlazos}</tbody>
            </table>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditEsquemas.propTypes = {
  esquemasId: PropTypes.string.isRequired,
  plazos: PropTypes.array.isRequired,
  loadEsquemas: PropTypes.func.isRequired,
};

export default EditEsquemas;
