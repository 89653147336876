import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from '@utils/api';
import PropTypes from 'prop-types';
import { CustomerRegistroOption } from '@stateless';

function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

const SelectCRM = ({ onChange, name, value, customerId, domicilioId }) => {
  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    const { data: clientes } = await getRequest({
      url: `customers-registros/autocomplete`,
      params: { query: inputValue, customerId, domicilioId },
    });
    return clientes;
  };

  const onSelect = (selected) => {
    onChange(selected);
  };

  const debouncedLoadOptions = useCallback(
    debounce((inputValue, callback) => {
      loadOptions(inputValue).then(callback);
    }, 500),
    []
  );

  return (
    <AsyncSelect
      inputId={name}
      value={value}
      loadOptions={debouncedLoadOptions}
      onChange={onSelect}
      getOptionLabel={(x) => `${x.folio} ${x.descripcion}`}
      getOptionValue={(x) => x._id}
      isClearable
      placeholder={'Buscar por folio o descripcion '}
      components={{ Option: CustomerRegistroOption }}
    />
  );
};

SelectCRM.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  customerId: PropTypes.string.isRequired,
  domicilioId: PropTypes.string.isRequired,
};

export default SelectCRM;
