import React from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IfAccess } from '@controls';
import { User, UserSearch, FileBadge, Receipt, ScrollText, ListCheck, ScanBarcode, Wrench, Group, Shuffle, ArrowBigLeft, Users, BookOpenCheck, Contact } from 'lucide-react';

const QuickAccess = [
  {
    to: '/prospectos',
    icon: <UserSearch size='35' />,
    text: 'Prospectos',
    access: 'prospectos',
  },
  {
    to: '/clientes',
    icon: <User size='35' />,
    text: 'Clientes',
    access: 'clientes',
  },
  {
    to: '/clientes-registros',
    icon: <FileBadge size='35' />,
    text: 'Registro de clientes',
    access: 'clientes-registros',
  },
  {
    to: '/cotizaciones/nuevo',
    icon: <Receipt size='35' />,
    text: 'Nueva Cotización',
    access: 'cotizaciones',
  },
  {
    to: '/cotizaciones',
    icon: <ScrollText size='35' />,
    text: 'Cotizaciones',
    access: 'cotizaciones',
  },
  {
    to: '/levantamientos/nuevo',
    icon: <ListCheck size='35' />,
    text: 'Nuevo Levantamiento',
    access: 'levantamientos',
  },
  {
    to: '/levantamientos',
    icon: <ScrollText size='35' />,
    text: 'Levantamientos',
    access: 'levantamientos',
  },
  {
    to: '/ordenes-servicio',
    icon: <ScrollText size='35' />,
    text: 'Ordenes de servicios',
    access: 'ordenes-servicio',
  },
  {
    to: '/pedidos',
    icon: <ScrollText size='35' />,
    text: 'Pedidos',
    access: 'pedidos',
  },
  {
    to: '/equipos',
    icon: <ScanBarcode size='35' />,
    text: 'Equipos',
    access: 'equipos',
  },
  {
    to: '/servicios',
    icon: <Wrench size='35' />,
    text: 'Servicios',
    access: 'servicios',
  },
  {
    to: '/kits-comerciales',
    icon: <Group size='35' />,
    text: 'Kits comerciales',
    access: 'kits-comerciales',
  },
  {
    to: '/seguimientos',
    icon: <Shuffle size='35' />,
    text: 'Seguimientos',
    access: 'seguimientos',
  },
  {
    to: '/ruleta-asesores',
    icon: <ArrowBigLeft size='35' />,
    text: 'Ruleta asesores',
    access: 'ruleta-asesores',
  },
  {
    to: '/ordenes-mermas',
    icon: <ScrollText size='35' />,
    text: 'Mermas',
    access: 'ordenes-mermas',
  },
  {
    to: '/ordenes-traspasos',
    icon: <ScrollText size='35' />,
    text: 'Traspasos',
    access: 'ordenes-traspasos',
  },
  {
    to: '/ajustes',
    icon: <ScrollText size='35' />,
    text: 'Ajustes',
    access: 'ajustes',
  },
  {
    to: '/existencias',
    icon: <ScrollText size='35' />,
    text: 'Existencias',
    access: 'existencias',
  },
  {
    to: '/compras',
    icon: <ScrollText size='35' />,
    text: 'Compras',
    access: 'compras',
  },
  {
    to: '/solicitudes-compras',
    icon: <ScrollText size='35' />,
    text: 'Solicitudes de compras',
    access: 'solicitudes-compras',
  },
  {
    to: '/recepciones-compras',
    icon: <ScrollText size='35' />,
    text: 'Recepciones de compras',
    access: 'recepciones-compras',
  },
  {
    to: '/usuarios',
    icon: <Users size='35' />,
    text: 'Usuarios',
    access: 'usuarios',
  },
  {
    to: '/modulos',
    icon: <BookOpenCheck size='35' />,
    text: 'Modulos',
    access: 'modulos',
  },
  {
    to: '/agentes',
    icon: <Contact size='35' />,
    text: 'Agentes',
    access: 'agentes',
  },
]

const AccesosDirectos = () => {
  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Container>
              <Row className='row-cols-2 row-cols-sm-4 row-cols-md-5 g-4 justify-content-center'> 
                {QuickAccess.map((item, index) => (
                  <IfAccess path={item.access} key={index}>
                    <Col>
                      <Button as={Link} to={item.to} className='w-100 h-100 d-flex flex-column align-items-center justify-content-center p-3' variant='light'>
                        { item.icon }
                        <span className='text mt-2'>{item.text}</span>
                      </Button>
                    </Col>
                  </IfAccess>
                ))}
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <br />
      </Col>
    </Row>
  );
};

export default AccesosDirectos;
