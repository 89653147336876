import { Input } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { Button, ListGroupItem, Row, Col, InputGroup } from 'react-bootstrap';
import SelectProveedor from '@components/forms/SelectProveedor';
import InputCurrency from '@components/forms/InputCurrency';

const RowProveedor = ({ proveedor, onChangeProveedor, onRemoveItemProveedor }) => {
  const handleChangeProveedor = (itemSelect) => {
    onChangeProveedor({
      ...proveedor,
      proveedorId: itemSelect,
    });
  };

  const handleChangeProveedorInput = (key, e) => {
    const { value } = e.target;
    onChangeProveedor({
      ...proveedor,
      [key]: value,
    });
  };

  const handleRemoveItemProveedor = () => {
    onRemoveItemProveedor();
  };

  return (
    <ListGroupItem>
      <Row>
        <Col md="7">
          <InputGroup>
            <SelectProveedor name='proveedorId' value={proveedor.proveedorId} onChange={handleChangeProveedor} />
            <Input name='codigo' placeholder='Código' value={proveedor.codigo} onChange={(e) => handleChangeProveedorInput('codigo', e)} />
            <Input
              name='codigo_barras'
              placeholder='Código barras'
              value={proveedor.codigo_barras}
              onChange={(e) => handleChangeProveedorInput('codigo_barras', e)}
            />
          </InputGroup>
        </Col>
        <Col md="4">
          <InputGroup>
            <Input
              name='date_last_cost'
              title='Fecha de último costo'
              type='date'
              value={proveedor.date_last_cost}
              onChange={(e) => handleChangeProveedorInput('date_last_cost', e)}
            />
            <InputCurrency name='ultimoCosto' value={proveedor.ultimoCosto} onChange={(e) => handleChangeProveedorInput('ultimoCosto', e)} />
          </InputGroup>
        </Col>
        <Col md="1">
          <Button onClick={handleRemoveItemProveedor} variant='danger' className='pull-right' type='button'>
            <Icon icon='trash' />
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

RowProveedor.propTypes = {
  proveedor: PropTypes.object,
  onChangeProveedor: PropTypes.func,
  onRemoveItemProveedor: PropTypes.func,
};

export default RowProveedor;
