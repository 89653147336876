import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Module } from '@stateless';
import { getRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import AplicarConciliacion from './AplicarConciliacion';
import Concepto from './Concepto';
import { Button, Card, Col, InputGroup, Row } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import SelectCliente from '@components/forms/SelectCliente';

const DetallePreconciliacion = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [detalle, setDetalle] = useState([]);
  const [lista, setLista] = useState([]);
  const [query, setQuery] = useState('');
  const [totales, setTotales] = useState(null);

  const loadData = useCallback(async () => {
    const { conciliacionId } = params;
    const { data, detalle, totales } = await getRequest({ url: `concilianes-pendientes/${conciliacionId}` });
    if (detalle.length > 0) {
      setData(data);
      setDetalle(
        detalle.map((x) => ({
          ...x,
          clienteName: '',
          clienteObj: null,
          cliente: null,
        }))
      );
      setLista(detalle);
      setTotales(totales);
    } else {
      redirect();
    }
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const redirect = () => {
    setTimeout(() => navigate('/conciliaciones'), 1000);
  };

  const onChange = (event) => {
    const { value } = event.target;
    const filteredDetalle = lista.filter((l) => {
      const {
        deposito: { concepto, cliente },
      } = l;
      if (cliente !== null) {
        if (
          cliente.name.toLowerCase().includes(value.toLowerCase()) ||
          cliente.paterno.toLowerCase().includes(value.toLowerCase()) ||
          cliente.materno.toLowerCase().includes(value.toLowerCase()) ||
          cliente.razon_social.toLowerCase().includes(value.toLowerCase()) ||
          concepto.toLowerCase().includes(value.toLowerCase())
        ) {
          return l;
        }
      } else {
        if (concepto.toLowerCase().includes(value.toLowerCase())) {
          return l;
        }
      }
      return false;
    });
    setQuery(value);
    setDetalle(filteredDetalle);
  };

  const onChangeCliente = (index, itemSelect) => {
    setDetalle(
      detalle.map((x, i) => {
        if (i === index) {
          if (itemSelect) {
            return {
              ...x,
              cliente: itemSelect,
            };
          } else {
            return {
              ...x,
              cliente: null,
            };
          }
        }
        return x;
      })
    );
  };

  if (!data) {
    return null;
  }

  const { numero_cliente, nombre_cliente, nombre_cuenta, cuentaId, cuenta } = data;

  return (
    <Module onClickBack={window.history.back} parent='Concilaciones' title={`${cuenta} - ${cuentaId.bancoId.nombre}`} loading={!data}>
      <Row>
        <Col sm='12'>
          <Card>
            <Card.Header>
              <Card.Title>Cuenta</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm='6'>
                  <Concepto label='No. de Cliente' valor={numero_cliente} />
                  <Concepto label='Nombre del Cliente' valor={nombre_cliente} />
                  <Concepto label='Nombre de la Cuenta' valor={nombre_cuenta} />
                  <Concepto label='Cuenta' valor={cuenta} />
                </Col>
                <Col sm='6'>
                  <Concepto label='Aplicado' valor={`$ ${format(totales.aplicado, 2)} (${totales.aplicadoMovimientos} movimientos)`} />
                  <Concepto label='Por aplicar' valor={`$ ${format(totales.pendiente, 2)}  (${totales.pendienteMovimientos} movimientos)`} />
                  <Concepto label='Total' valor={`$ ${format(totales.total, 2)}  (${totales.totalMovimientos} movimientos)`} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <form className='form-horizontal'>
            <Col sm='9'></Col>
            <Col sm='3'>
              <InputGroup>
                <input type='text' id='query' name='query' className='form-control' value={query} onChange={onChange} />
                <Button className='btn btn-primary' type='button'>
                  <Icon icon='search' />
                </Button>
              </InputGroup>
            </Col>
            <br />
            <br />
            <br />
          </form>

          <legend>Movimientos pendientes de conciliación</legend>

          {detalle.map((d, i) => {
            const {
              deposito: { _id, numero, fecha, concepto, sucursal, referencia, codigo, depositos },
              clienteObj,
            } = d;

            return (
              <Card key={i}>
                <Card.Header>
                  <Card.Title>Movimiento No {numero}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <AplicarConciliacion
                    id={i}
                    itemId={_id}
                    deposito={{ _id, numero, fecha, concepto, sucursal, referencia, codigo, depositos }}
                    loadData={loadData}
                  />
                </Card.Body>
                <table className='table table-striped align-middle'>
                  <thead>
                    <tr>
                      <th width='5%'>No</th>
                      <th width='10%'>Fecha</th>
                      <th width='35%'>Concepto</th>
                      <th width='10%'>Sucursal</th>
                      <th width='10%'>Referencia</th>
                      <th width='10%'>Código</th>
                      <th width='10%'>Depósitos</th>
                      <th width='20%'>Cliente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={i} className='danger'>
                      <td>{numero}</td>
                      <td>
                        {fecha.substring(0, 2)}/{fecha.substring(2, 4)}/{fecha.substring(4, 6)}
                      </td>
                      <td>{concepto}</td>
                      <td>{sucursal}</td>
                      <td>{referencia}</td>
                      <td>{codigo}</td>
                      <td>$ {format(depositos, 2)}</td>
                      <td>
                        <SelectCliente value={clienteObj} onChange={(itemSelect) => onChangeCliente(i, itemSelect)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            );
          })}
        </Col>
      </Row>
    </Module>
  );
};

DetallePreconciliacion.propTypes = {};

export default DetallePreconciliacion;
