import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteEsquemas from './DeleteEsquemas';
import EditEsquemas from './EditEsquemas';

const ListEsquemas = ({ catalogEsquemas }) => {
  const generatePlazos = (listPlazos) => {
    return listPlazos.map((a, i) => {
      const label = `${a.plazo} meses ${a.taza !== null ? '- Tasa ' + a.taza + '%' : ''}`;

      return <li key={i}>{label}</li>;
    });
  };

  const renderRows = () => {
    return catalogEsquemas.map((row, item) => {
      const { _id, esquema, anticipos, descuento, listPlazos } = row;

      return (
        <tr key={item}>
          <td>{esquema}</td>

          {typeof anticipos !== 'undefined' ? (
            <td>
              <span className='label label-warning'>{anticipos.minimo}% mínimo</span>
              <br />
              <span className='label label-danger'>{anticipos.maximo}% máximo</span>
            </td>
          ) : (
            <td></td>
          )}
          {typeof descuento !== 'undefined' ? (
            <td>
              <span className='label label-info'>{descuento.minimo}% mínimo</span>
              <br />
              <span className='label label-primary'>{descuento.maximo}% máximo</span>
            </td>
          ) : (
            <td></td>
          )}
          <td>
            <ul>{generatePlazos(listPlazos)}</ul>
          </td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteEsquemas id={item} esquemasId={_id} name={esquema} />
            </IfPermission>
            <IfPermission action='editar'>
              <EditEsquemas id={item} esquemasId={_id} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Esquemas</th>
          <th width='15%'>Anticipos</th>
          <th width='15%'>Descuento</th>
          <th width='20%'>Plazos</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListEsquemas.propTypes = {
  catalogEsquemas: PropTypes.array.isRequired,
};

export default ListEsquemas;
