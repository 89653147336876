import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '@styles/nav-bar.css';
import { useStoreSidebar } from '@stores/catalogs.store';
import useAuth from '@hooks/useAuth';
import { Container, NavDropdown, Nav, Badge, Overlay, Popover, ListGroup, Image, Button, ButtonGroup } from 'react-bootstrap';
import { BellIcon, EyeIcon, MessagesSquareIcon } from 'lucide-react';
import TextDateDiff from '@controls/TextDateDiff';
import { putRequest } from '@utils/api';

export default function NavBar() {
  const [showMessages, setShowMessages] = useState(false)
  const messageTarget = useRef(null)
  const getStoreSidebar = useStoreSidebar();
  const { user, notifications, messages, setNotifications } = useAuth();
  const onClick = (e) => {
    e.preventDefault();
    getStoreSidebar.toggleCollapsed();
  };

  const [showNotifications, setShowNotifications] = useState(false)
  const notificationTarget = useRef(null)


  const getSrcImg = () => {
    return 'https://icones.pro/wp-content/uploads/2022/05/icones-de-noel-pere-noel-orange.png';
    // if (isURL(avatar + '')) return avatar;
    // if (avatar) return urlPhoto() + avatar;
    // return 'https://img2.freepng.es/20180612/ih/kisspng-computer-icons-avatar-user-profile-clip-art-5b1f69f0e68650.4078880515287853929442.jpg';
  };

  const onMarkRead = async (id, idx) => {
    setShowNotifications(false);
    await putRequest({ url: `user/notificaciones/read/${id}`, body: { estado: 'LEIDO' } });
    setNotifications([
      ...notifications.filter((n, i) => i !== idx),
    ]);
  }

  return (
    <>
      <Link className='logo navbar-brand hidden-xs' to='/dashboard'>
        <img src='/img/logo2.png' className='logo-lg' />
        <img src='/img/favicon.png' className='logo-mini' />
      </Link>
      <nav className='navbar navbar-default navbar-static-top ' style={{ padding: 0 }}>
        <Container fluid style={{ display: 'block' }}>
          <div className='d-flex flex-wrap justify-content-center'>
            {user?.id && (
              <>
                <a onClick={onClick} href='#' className='sidebar-toggle me-md-auto' data-toggle='push-menu' role='button'>
                  <span className='sr-only'>Toggle navigation</span>
                </a>
                <Nav className='d-flex flex-wrap align-items-center text-light' >
                  <Nav.Link
                    ref={messageTarget}
                    onClick={() => setShowMessages(!showMessages)}
                    className="position-relative me-3"
                    style={{ cursor: 'pointer', color: 'white' }}
                  >
                    <MessagesSquareIcon size={24} />
                    {messages?.length > 0 && (
                      <Badge bg="danger" className="position-absolute top-10 start-75 translate-middle rounded-pill">
                        {messages?.length}
                      </Badge>                      
                    )}
                  </Nav.Link>
                  <Overlay target={messageTarget.current} show={showMessages} placement="bottom" rootClose onHide={() => setShowMessages(false)}>
                    <Popover id="popover-messages">
                      <Popover.Header as="h3">Mensajes</Popover.Header>
                      <ListGroup >
                        {messages?.map((message) => (
                          <ListGroup.Item key={message._id} className="d-flex align-items-start">
                            <Image src={getSrcImg(message.emisor.avatar) || "/placeholder.svg"} roundedCircle width={40} height={40} className="me-3" />
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{message.emisor.name}</div>
                              <div className="text-muted">{message.asunto}</div>
                              <small className="text-muted"><TextDateDiff>{message.created}</TextDateDiff></small>
                            </div>
                          </ListGroup.Item>
                        ))}
                        <ListGroup.Item action as={Link} to="/mensajes" onClick={() => setShowMessages(false)}>
                          Mirar todos
                        </ListGroup.Item>
                      </ListGroup>
                    </Popover>
                  </Overlay>
                  <Nav.Link
                    ref={notificationTarget}
                    onClick={() => setShowNotifications(!showNotifications)}
                    className="position-relative me-3"
                    style={{ cursor: 'pointer', color: 'white' }}
                  >
                    <BellIcon size="20" />
                    {notifications?.length > 0 && (
                      <Badge bg="danger" className="position-absolute top-10 start-75 translate-middle rounded-pill">
                        {notifications.length}
                      </Badge>
                    )}
                  </Nav.Link>
                  <Overlay target={notificationTarget.current} show={showNotifications} placement="bottom" rootClose onHide={() => setShowNotifications(false)}>
                    <Popover id="popover-notifications">
                      <Popover.Header as="h3">Notificaciones</Popover.Header>
                      <ListGroup >
                        {notifications?.map((notification, i) => (
                          <ListGroup.Item key={notification._id} className={`d-flex justify-content-between align-items-start`}>
                            <div className="ms-2 me-auto">
                              <div className={"fw-bold"}>{notification.asunto}</div>
                              <TextDateDiff>{notification.created}</TextDateDiff>
                            </div>
                            <div>
                              <ButtonGroup>
                                <Button variant="link" size="sm" onClick={() => onMarkRead(notification?._id, i)} title="Marcar como leída" className="me-2">
                                  <EyeIcon />
                                </Button>
                              </ButtonGroup>
                            </div>
                          </ListGroup.Item>
                        ))}
                        <ListGroup.Item action as={Link} to="/notificaciones" onClick={() => setShowNotifications(false)}>
                          Mirar todos
                        </ListGroup.Item>
                      </ListGroup>
                    </Popover>
                  </Overlay>
                  <NavDropdown title={<>
                    <img
                        src={getSrcImg(user && user.avatar)}
                        className='user-image'
                        alt={user && user.email}
                        title={user && user.email}
                        style={{ background: 'white', padding: 5, borderRadius: '50%', width: 30, height: 30 }}
                      />
                      <span className='hidden-xs' style={{ cursor: 'pointer', color: 'white' }}> {user && user.name}</span>
                  </>} id="basic-nav-dropdown" align="end">
                    <NavDropdown.Item href="#profile">Perfil</NavDropdown.Item>
                    <NavDropdown.Item href="#settings">Configuración</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/logout">Cerrar sesión</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
            </>
            )}
          </div>
        </Container>
      </nav>
    </>
  );
}
