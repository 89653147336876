import { DivFormGroup } from '@controls';
import Panel from '@controls/Panel';
import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Card, Col, InputGroup, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { useParams, useNavigate } from 'react-router-dom';

const CALIFICACION = {
  MALO: 'MALO',
  REGULAR: 'REGULAR',
  BUENO: 'BUENO',
};

const RESPUESTA = {
  SI: 'SI',
  NO: 'NO',
};

const CalificacionServicio = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    isLoadingForm: true,
    visitaId: null,
    numero_orden_servicio: '',
    orden_servicio: null,
    calificacionId: null,
    alarma: false,
    camara: false,
    smart_home: false,
    control_acceso: false,
    savia: false,
    mejora_internet: false,
    aforo: false,
    certificacion_nodos: false,
    deteccion_humo: false,
    limpieza: '',
    atencion: '',
    experiencia: '',
    alarma_funcional: '',
    comunicar_asesor_tecnico: '',
    recomendar_servicios: '',
    con_quien: '',
    actualizar: false,
  });

  useEffect(() => {
    const { visitaId } = params;
    loadData(visitaId);
  }, [params]);

  const loadData = async (visitaId) => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    if (data?.calificacion) {
      const { alarma, camara, smart_home, control_acceso, savia, mejora_internet, aforo, certificacion_nodos, deteccion_humo } =
        data.calificacion.soluciones;
      const { limpieza, atencion, experiencia, alarma_funcional, comunicar_asesor_tecnico, recomendar_servicios, con_quien } =
        data.calificacion.calidad_servicio;
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: false,
        alarma,
        camara,
        smart_home,
        control_acceso,
        savia,
        mejora_internet,
        aforo,
        certificacion_nodos,
        deteccion_humo,
        limpieza,
        atencion,
        experiencia,
        alarma_funcional,
        comunicar_asesor_tecnico,
        recomendar_servicios,
        con_quien,
        actualizar: true,
        calificacionId: data?.calificacion._id,
        estatus: data.estatus,
      }));
    } else {
      setState((prevState) => ({ ...prevState, isLoadingForm: false }));
    }
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const { visitaId } = params;
    const {
      alarma,
      camara,
      smart_home,
      control_acceso,
      savia,
      mejora_internet,
      aforo,
      certificacion_nodos,
      deteccion_humo,
      limpieza,
      atencion,
      experiencia,
      alarma_funcional,
      comunicar_asesor_tecnico,
      recomendar_servicios,
      con_quien,
    } = state;
    const data = {
      alarma,
      camara,
      smart_home,
      control_acceso,
      savia,
      mejora_internet,
      aforo,
      certificacion_nodos,
      deteccion_humo,
      limpieza,
      atencion,
      experiencia,
      alarma_funcional,
      comunicar_asesor_tecnico,
      recomendar_servicios,
      con_quien,
    };
    await postRequest({ url: `ordenes-servicios-visitas/calificaciones/${visitaId}`, body: data });
    navigate(`/ordenes-servicios-visitas/firma-cliente/${visitaId}`);
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const onClickCalidad = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const handleSiguiente = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    navigate(`/ordenes-servicios-visitas/firma-cliente/${visitaId}`);
  };

  const handleAtras = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    navigate(`/ordenes-servicios-visitas/observaciones-comentarios/${visitaId}`);
  };

  const renderView = () => {
    const {
      isLoading,
      alarma,
      camara,
      smart_home,
      control_acceso,
      savia,
      mejora_internet,
      aforo,
      certificacion_nodos,
      deteccion_humo,
      limpieza,
      atencion,
      experiencia,
      alarma_funcional,
      comunicar_asesor_tecnico,
      recomendar_servicios,
      con_quien,
      estatus,
    } = state;

    return (
      <>
        <Row>
          <Col sm='12'>
            <Panel
              type='primary'
              header={
                <div>
                  <Icon icon='book' /> Calidad de servicio al cliente
                </div>
              }
            >
              <p>
                Estamos comprometidos con dar el mejor servicio al cliente, por ello trabajamos constantemente en mejorar nuestros servicios. Por
                favor califique en base a su experiencia con el servicio.
              </p>
              <Row>
                <Col sm='12'>
                  <p style={{ fontWeight: 'bold' }}>1. La limpieza con la que se realizó el trabajo fue:</p>
                </Col>
                <Col sm='12'>
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('limpieza', CALIFICACION.MALO)}
                      variant={limpieza === CALIFICACION.MALO ? 'danger' : 'outline-primary'}
                    >
                      MALO
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('limpieza', CALIFICACION.REGULAR)}
                      variant={limpieza === CALIFICACION.REGULAR ? 'warning' : 'outline-primary'}
                    >
                      REGULAR
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('limpieza', CALIFICACION.BUENO)}
                      variant={limpieza === CALIFICACION.BUENO ? 'primary' : 'outline-primary'}
                    >
                      BUENO
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <p style={{ fontWeight: 'bold' }}>2. La atención que le proporcionaron los tecnicos fue:</p>
                </Col>
                <Col sm='12'>
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('atencion', CALIFICACION.MALO)}
                      variant={atencion === CALIFICACION.MALO ? 'danger' : 'outline-primary'}
                    >
                      MALO
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('atencion', CALIFICACION.REGULAR)}
                      variant={atencion === CALIFICACION.REGULAR ? 'warning' : 'outline-primary'}
                    >
                      REGULAR
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('atencion', CALIFICACION.BUENO)}
                      variant={atencion === CALIFICACION.BUENO ? 'success' : 'outline-primary'}
                    >
                      BUENO
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <p style={{ fontWeight: 'bold' }}>3. ¿Cómo ha sido su experiencia en general con Código Blanco?</p>
                </Col>
                <Col sm='12'>
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('experiencia', CALIFICACION.MALO)}
                      variant={experiencia === CALIFICACION.MALO ? 'danger' : 'outline-primary'}
                    >
                      MALO
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('experiencia', CALIFICACION.REGULAR)}
                      variant={experiencia === CALIFICACION.REGULAR ? 'warning' : 'outline-primary'}
                    >
                      REGULAR
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('experiencia', CALIFICACION.BUENO)}
                      variant={experiencia === CALIFICACION.BUENO ? 'success' : 'outline-primary'}
                    >
                      BUENO
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <p style={{ fontWeight: 'bold' }}>4. ¿Se probó que todas las zonas de su alarma seán funcionales?</p>
                </Col>
                <Col sm='12'>
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('alarma_funcional', RESPUESTA.NO)}
                      variant={alarma_funcional === RESPUESTA.NO ? 'warning' : 'outline-primary'}
                    >
                      NO
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('alarma_funcional', RESPUESTA.SI)}
                      variant={alarma_funcional === RESPUESTA.SI ? 'success' : 'outline-primary'}
                    >
                      SI
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <p style={{ fontWeight: 'bold' }}>5. ¿Lo comunicaron con su asesor técnico para la actualización de datos?</p>
                </Col>
                <Col sm='12'>
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('comunicar_asesor_tecnico', RESPUESTA.NO)}
                      variant={comunicar_asesor_tecnico === RESPUESTA.NO ? 'warning' : 'outline-primary'}
                    >
                      NO
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('comunicar_asesor_tecnico', RESPUESTA.SI)}
                      variant={comunicar_asesor_tecnico === RESPUESTA.SI ? 'success' : 'outline-primary'}
                    >
                      SI
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <header>Comentarios:</header>

              <Row>
                <Col sm='12'>
                  <p style={{ fontWeight: 'bold' }}>¿Estarias dispuesto a recomendar nuestros servicios?</p>
                </Col>
                <Col sm='12'>
                  <ButtonGroup>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('recomendar_servicios', RESPUESTA.NO)}
                      variant={recomendar_servicios === RESPUESTA.NO ? 'warning' : 'outline-primary'}
                    >
                      NO
                    </Button>
                    <Button
                      type='button'
                      onClick={() => onClickCalidad('recomendar_servicios', RESPUESTA.SI)}
                      variant={recomendar_servicios === RESPUESTA.SI ? 'success' : 'outline-primary'}
                    >
                      SI
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              {recomendar_servicios === 'SI' ? (
                <Row>
                  <Col sm='12'>
                    <p style={{ fontWeight: 'bold' }}>¿Con quién?</p>
                  </Col>
                  <Col sm='12'>
                    <InputGroup>
                      <input className='form-control' type='text' name='con_quien' value={con_quien} onChange={onChange} />
                    </InputGroup>
                  </Col>
                </Row>
              ) : null}
            </Panel>
          </Col>

          <Col sm='12'>
            <Panel
              type='primary'
              header={
                <div>
                  <Icon icon='book' /> Soluciones código blanco
                </div>
              }
            >
              <p>
                Conoce nuestras soluciones. Si desea información adicional, solo marque el recuadro, usted sera contactado por nuestros asesores para
                brindarle la información deseada.
              </p>
              <Row>
                <Col sm='12'>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' id='alarma' name='alarma' value={alarma} onChange={onChange} checked={alarma} />
                      </InputGroup.Text>
                      <label htmlFor='alarma' className='form-control'>
                        Alarma
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' id='camara' name='camara' value={camara} onChange={onChange} checked={camara} />
                      </InputGroup.Text>
                      <label htmlFor='camara' className='form-control'>
                        Cámara
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' id='smart_home' name='smart_home' value={smart_home} onChange={onChange} checked={smart_home} />
                      </InputGroup.Text>
                      <label htmlFor='smart_home' className='form-control'>
                        Smart Home
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input
                          type='checkbox'
                          id='control_acceso'
                          name='control_acceso'
                          value={control_acceso}
                          onChange={onChange}
                          checked={control_acceso}
                        />
                      </InputGroup.Text>
                      <label htmlFor='control_acceso' className='form-control'>
                        Control de acceso
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' id='savia' name='savia' value={savia} onChange={onChange} checked={savia} />
                      </InputGroup.Text>
                      <label htmlFor='savia' className='form-control'>
                        SAVIA
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input
                          type='checkbox'
                          id='mejora_internet'
                          name='mejora_internet'
                          value={mejora_internet}
                          onChange={onChange}
                          checked={mejora_internet}
                        />
                      </InputGroup.Text>
                      <label htmlFor='mejora_internet' className='form-control'>
                        Mejora tu internet
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' id='aforo' name='aforo' value={aforo} onChange={onChange} checked={aforo} />
                      </InputGroup.Text>
                      <label htmlFor='aforo' className='form-control'>
                        Aforo
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input
                          type='checkbox'
                          id='certificacion_nodos'
                          name='certificacion_nodos'
                          value={certificacion_nodos}
                          onChange={onChange}
                          checked={certificacion_nodos}
                        />
                      </InputGroup.Text>
                      <label htmlFor='certificacion_nodos' className='form-control'>
                        Certificación de nodos
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <input
                          type='checkbox'
                          id='deteccion_humo'
                          name='deteccion_humo'
                          value={deteccion_humo}
                          onChange={onChange}
                          checked={deteccion_humo}
                        />
                      </InputGroup.Text>
                      <label htmlFor='deteccion_humo' className='form-control'>
                        Detección de humo
                      </label>
                    </InputGroup>
                  </DivFormGroup>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleAdd} variant='primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                {estatus === 'FINALIZADO' && (
                  <>
                    <Button onClick={handleSiguiente} variant='primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-right' /> Siguente (sin guardar)
                    </Button>
                    <Button onClick={handleAtras} variant='primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-left' /> Atrás (sin guardar)
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} title='Calificación del servicio'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

CalificacionServicio.propTypes = {};

export default CalificacionServicio;
