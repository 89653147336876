import * as SECRET from '@config/apiKey';
import jwt from 'jwt-simple';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Modal, Row } from 'react-bootstrap';
import { BASE_URL, getRequest, URL_FILES } from '@utils/api';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const ModalImprimir = ({ idCotizacion, showModal, isLoading, onClose }) => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [codigos, setCodigos] = useState(true);
  const [horasServicio, setHorasServicio] = useState(false);
  const [desgloseKits, setDesgloseKits] = useState(false);

  const onNuevo = () => {
    onClose();
    setTimeout(() => navigate('/cotizaciones/nuevo'), 1000);
  };

  const onConvertir = () => {
    onClose();
    setTimeout(() => navigate(`/cotizaciones/convertir/${idCotizacion}`), 1000);
  };

  const onAceptar = async () => {
    const { data } = await getRequest({ url: `cotizaciones/pdf/${idCotizacion}`, params: { codigos, desgloseKits, horasServicio } });
    if (data) {
      onClose();
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      setTimeout(() => navigate(-1), 1000);
    }
  };

  const handleCopiar = (e) => {
    e.preventDefault();
    const cotizacionId = jwt.encode(idCotizacion, SECRET.API_KEY);
    copyToClipboard(BASE_URL + `firmarCotizacion/${cotizacionId}?codigos=${codigos}&desgloseKits=${desgloseKits}&horasServicio=${horasServicio}`);
    notification({
      message: '¡Url copiada con éxito!',
      type: 'success',
    });
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  const close = () => {
    onClose();
  };

  const onChange = (event) => {
    const { value, name, checked, type } = event.target;
    if (type === 'checkbox') {
      if (name === 'codigos') setCodigos(checked);
      if (name === 'desgloseKits') setDesgloseKits(checked);
      if (name === 'horasServicio') setHorasServicio(checked);
    } else if (type === 'radio') {
      if (name === 'codigos') setCodigos(value === 'true');
      if (name === 'desgloseKits') setDesgloseKits(value === 'true');
      if (name === 'horasServicio') setHorasServicio(value === 'true');
    }
  };

  const handleExcel = async (e) => {
    e.preventDefault();
    const { file } = await getRequest({ url: `cotizaciones/exportar/${idCotizacion}` });
    if (file) {
      onClose();
      window.open(`${URL_FILES}/${file}`, '_blank');
    }
  };

  return (
    <Modal show={showModal} onHide={close} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Descargar cotización</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <FormGroup className='inline'>
              <label>Con códigos</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='codigos' onChange={onChange} value={false} checked={!codigos} /> No
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='codigos' onChange={onChange} value={true} checked={codigos} /> Si
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className='inline'>
              <label>Desglose de kits</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='desgloseKits' onChange={onChange} value={false} checked={!desgloseKits} /> No
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='desgloseKits' onChange={onChange} value={true} checked={desgloseKits} /> Si
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className='inline'>
              <label>Con horas de servicio</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='horasServicio' onChange={onChange} value={false} checked={!horasServicio} /> No
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='horasServicio' onChange={onChange} value={true} checked={horasServicio} /> Si
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer />
      <Modal.Body>
        <Row>
          <Col>
            <Button onClick={close} variant='outline-dark' className='mr-5' style={{ width: '100%' }} type='button'>
              Cerrar
            </Button>
          </Col>
          <Col>
            <Button onClick={onConvertir} variant='outline-success' className='mr-5' style={{ width: '100%' }} type='button'>
              Convertir
            </Button>
          </Col>
          <Col>
            <Button variant='outline-success' className='mr-5' style={{ width: '100%' }} onClick={handleExcel} disabled={isLoading}>
              Excel
            </Button>
          </Col>
          <Col>
            <Button onClick={onNuevo} variant='outline-success' className='mr-5' style={{ width: '100%' }} type='button'>
              Nuevo
            </Button>
          </Col>
          <Col>
            <Button onClick={onAceptar} variant='outline-primary' className='mr-5' style={{ width: '100%' }} type='button'>
              Imprimir
            </Button>
          </Col>
          <Col>
            <Button onClick={handleCopiar} variant='outline-warning' className='mr-5' style={{ width: '100%' }} disabled={isLoading}>
              Url firma
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ModalImprimir.propTypes = {
  idCotizacion: PropTypes.number,
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalImprimir;
