import React, { useEffect, useState } from 'react';
import Search from '@controls/Search';
import { ButtonGroup, Card, DropdownButton, DropdownItem } from 'react-bootstrap';
import { Loading, Module } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import { CbPagination } from '@controls';
import Nuevo from '../Nuevo';
import List from './List';
import IconDropdown from '@controls/IconDropdown';
import useAuth from '@hooks/useAuth';
import usePagination from '@hooks/usePagination';
import { fetchAll } from '@services/clientes.service';
import { FileDownIcon, FileUpIcon } from 'lucide-react';

const ListCustomer = () => {
  const { geolocation } = useAuth();
  const [search, setSearch] = useState('');
  const [pagination] = useState(50);

  const { datos, paginaActual, count, cargando, cambiarPagina, setParams, refresh, orden, cambiarOrden } = usePagination(
    fetchAll,
    pagination,
    'numero_cliente'
  );
  useEffect(() => {
    setParams({
      search,
    });
  }, [search]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
  };

  const exportar = async () => {
    const { file } = await getRequest({ url: `customers/exportar` });
    if (file) {
      let a = document.createElement('a');
      a.href = `${URL_FILES}${file}`;
      a.download = `Clientes.xlsx`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    }
  };
  return (
    <Module title='Clientes'>
      <Search searchOnStopType blockOne='col-sm-10' blockSecond='col-sm-2' onSearch={onSearch}>
        <ButtonGroup className='pull-right'>
          <Nuevo handleSearch={refresh} />
          <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-dark'>
            <DropdownItem href={`/clientes/importar`}>
              <FileUpIcon /> Importar
            </DropdownItem>
            <DropdownItem onClick={exportar}>
              <FileDownIcon /> Exportar
            </DropdownItem>
          </DropdownButton>
        </ButtonGroup>
      </Search>
      <Loading loading={cargando}>
        <Card>
          <div className='table-responsive'>
            <table className='table table-striped align-middle'>
              <List list={datos} position={geolocation} refresh={refresh} orden={orden.campo} tipo={orden.direccion} onChangeOrden={cambiarOrden} />
            </table>
          </div>
          <Card.Footer >
            <CbPagination activePage={paginaActual} count={count} pagination={pagination} onSelect={cambiarPagina} />
          </Card.Footer>
        </Card>
      </Loading>
    </Module>
  );
};

ListCustomer.propTypes = {};

export default ListCustomer;
