import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const Panel = ({ header, color, children }) => {
  const componentHeader = header ? header : '';
  return (
    <Card style={{ borderColor: color }}>
      {componentHeader && (
        <Card.Header style={{ borderColor: color, backgroundColor: color }}>
          <Card.Title>{componentHeader}</Card.Title>
        </Card.Header>
      )}
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

Panel.propTypes = {
  type: PropTypes.string,
  header: PropTypes.node,
  classHeader: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
};


export default Panel;
