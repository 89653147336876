import * as SECRET from '@config/apiKey';
import jwt from 'jwt-simple';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Saving } from '@stateless';
import '@styles/btn-login.css';
import { getRequest, postUploadRequest, URL_FILES } from '@utils/api';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function FirmarPublica() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [url, setUrl] = useState(null);
  const contratoId = jwt.decode(params.hash, SECRET.API_KEY);
  const canvas = useRef(null);
  useEffect(() => {
    getPdfContrato();
  }, []);

  const getPdfContrato = async () => {
    setLoading(true);
    setUrl('');
    const { data } = await getRequest({ url: `customer/regeneraDocumentoContratoVentaEquipo/${contratoId}` });
    if (data) {
      setUrl(`${URL_FILES}/${data.url}`);
    }
    setLoading(false);
  };

  const handleLimpiar = () => {
    canvas.current.clear();
  };

  const handleFirmaCliente = async (event) => {
    event.preventDefault();
    setSaving(true);
    const blob = DataURIToBlob(canvas.current.toDataURL('image/png'));
    const form = new FormData();
    form.append('image', blob, 'firma.png');
    await postUploadRequest({ url: `customer/contrato/firma_cliente/${contratoId}`, form });
    handleLimpiar();
    getPdfContrato();
    setSaving(false);
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  return (
    <>
      <Container fluid style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col style={{ height: '100%' }}>
            <iframe src={url} width='100%' height='600px'></iframe>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <h3>Firme aquí</h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <div style={{ border: '1px solid black', display: 'inline-block', textAlign: 'center' }}>
              <SignatureCanvas ref={canvas} penColor='black' canvasProps={{ width: 300, height: 150, className: 'sigCanvas', margin: '0 auto' }} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'center', margin: '1em' }}>
            <Saving saving={saving} />
            <Button variant='warning' className='mr-5' onClick={handleLimpiar} disabled={loading || saving}>
              Limpiar
            </Button>
            <Button variant='primary' className='mr-5' onClick={handleFirmaCliente} disabled={loading || saving}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

FirmarPublica.propTypes = {};
