import React, { useState, useEffect } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import Concepto from './Concepto';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

const ESTATUS_RECEPCIONES = {
  INICIADO: 'INICIADO',
  FINALIZADO: 'FINALIZADO',
  EN_PROCESO: 'EN PROCESO',
};

const DetalleRecepcion = () => {
  const { recepcionCompraId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `recepcionescompras/${recepcionCompraId}` });
      setData(data);
    };

    fetchData();
  }, [recepcionCompraId]);

  const renderDetalle = () => {
    const detalles = data.detalles.map((d, i) => {
      const {
        codigo,
        descripcion,
        unidadMedidaId: { measure },
        cantidad,
        cantidad_comprada,
        compraDetalleId,
        ubicacionId,
      } = d;
      return (
        <tr key={i}>
          <td>{codigo}</td>
          <td>{descripcion}</td>
          <td>{measure}</td>
          <td>{cantidad}</td>
          <td>{cantidad_comprada}</td>
          <td>{compraDetalleId.cantidad_recibida}</td>
          <td>{ubicacionId.nombre}</td>
        </tr>
      );
    });
    return (
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descripción</th>
            <th>Medida</th>
            <th>Cant.</th>
            <th>Cant. Comprada</th>
            <th>Cant. Recibida</th>
            <th>Ubicación</th>
          </tr>
        </thead>
        <tbody>{detalles.length > 0 ? detalles : <RowsNotFound message='Sin detalle' colSpan={11} />}</tbody>
      </table>
    );
  };

  const renderData = () => {
    const { fecha, fecha_entrega, tipoCompraId, observaciones, estatus, sucursal, almacen, agente, numero_recepcion_compra, numero_compra } = data;

    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <Concepto label='Tipo compra:' valor={tipoCompraId.nombre} />
                <Concepto label='Núm. Compra:' valor={numero_compra} />
                <Concepto label='Núm. Orden:' valor={numero_recepcion_compra} />
                <Concepto label='Estatus:' valor={ESTATUS_RECEPCIONES[estatus]} />
              </Col>
              <Col xs={6}>
                <div className='pull-right'>
                  <Concepto label='Fecha:' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                  {fecha_entrega !== null ? <Concepto label='Fecha de entrega:' valor={moment(fecha_entrega).local().format('DD/MM/YYYY')} /> : null}
                  <Concepto label='Sucursal:' valor={sucursal.name} />
                  <Concepto label='Almacén:' valor={almacen.name} />
                  <Concepto label='Agente:' valor={agente.nombre} />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>{renderDetalle()}</Card>
        <Card>
          <Card.Body>
            <Row>
              <Col xs={12}>
                <Concepto label='Observaciones:' />
                {observaciones}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <Module title={`Recepción de compra #${data && data.numero_recepcion_compra}`} onClickBack={window.history.back} loading={!data}>
      {data && renderData()}
    </Module>
  );
};

DetalleRecepcion.propTypes = {};

export default DetalleRecepcion;
