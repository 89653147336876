import React from 'react';
import { Card, Row, Col, InputGroup, Button } from 'react-bootstrap';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import SelectDomicilioCliente from '@components/forms/SelectDomicilioCliente';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import { postRequest, deleteRequest } from '@utils/api';
import { DivFormGroup, If } from '@controls';
import Swal from 'sweetalert2';
import { mapDomicilioFront } from '@utils/contratos';

const INIT_DOMICILIO = {
  codigo_postal: '',
  es_fiscal: false,
  nombre: '',
  monitoreo: '',
  calle: '',
  exterior: '',
  interior: '',
  calle1: '',
  calle2: '',
  paisObject: null,
  estadoObject: null,
  municipioObject: null,
  coloniaObject: null,
  localidad: '',
  referencias: '',
};

const SelectCreateOrUpdateDomicilio = ({
  customerId,
  domicilios,
  value: domicilio,
  onChange: setDomicilio,
  setDomicilios,
  name = 'domicilio',
  title = 'Domicilio',
}) => {
  const { addNotification: notification } = useStoreNotification();
  const handleRegisterDomicilio = async () => {
    if (
      (!customerId && isEmpty(domicilio.calle)) ||
      !domicilio.paisObject ||
      !domicilio.estadoObject ||
      !domicilio.municipioObject ||
      !domicilio.coloniaObject ||
      isEmpty(domicilio.codigo_postal.toString())
    ) {
      notification({
        title: 'Información incompleta',
        message: 'Los domicilios requieren calle, pais, estado, municipio, colonia y código postal.',
        type: 'error',
      });
      return;
    }
    const data = {
      domicilioId: domicilio?._id,
      calle: domicilio?.calle,
      exterior: domicilio?.exterior,
      interior: domicilio?.interior,
      calle1: domicilio?.calle1,
      calle2: domicilio?.calle2,
      coloniaId: domicilio?.coloniaObject?._id,
      municipioId: domicilio?.municipioObject?._id,
      estadoId: domicilio?.estadoObject?._id,
      paisId: domicilio?.paisObject?._id,
      codigo_postal: domicilio?.codigo_postal,
      localidad: domicilio?.localidad,
      referencias: domicilio?.referencias,
      nombre: domicilio?.nombre,
      monitoreo: domicilio?.monitoreo,
      es_fiscal: domicilio?.es_fiscal,
    };
    const { data: newDomicilio, error } = await postRequest({ url: `customers/${customerId}/domicilio`, body: data });
    if (!error) {
      if (domicilios.find((c) => c._id === newDomicilio._id)) {
        setDomicilios(domicilios.map((c) => (c._id === newDomicilio._id ? {...mapDomicilioFront(newDomicilio), editando: false } : c)));
      } else {
        setDomicilios([...domicilios, {...mapDomicilioFront(newDomicilio)}]);
      }
      setDomicilio({...mapDomicilioFront(newDomicilio), editando: false });
    }
  };

  const handleEliminarDomicilio = async () => {
    if (!domicilio?._id) {
      return;
    }
    const confirm = await confirmarEliminar();
    if (confirm) {
      const { error } = await deleteRequest({ url: `customers/${customerId}/domicilios/${domicilio._id}` });
      if (!error) {
        setDomicilio(null);
        setDomicilios(domicilios.filter((c) => c._id !== domicilio._id));
      }
    }
  };

  const confirmarEliminar = async () => {
    const resultEliminar = await Swal.fire({
      html: `<div style="font-size: 1.5em!important;">¿Confirma que desea eliminar el domicilio?</div>`,
      icon: 'primary',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    });
    return resultEliminar.isConfirmed;
  };

  if (!domicilio?.editando) {
    return (
      <DivFormGroup name={name} required title={title}>
        <InputGroup>
          <SelectDomicilioCliente
            value={domicilio}
            name={name}
            options={domicilios}
            customerId={customerId}
            onChange={(value) => {
              if (value) {
                setDomicilio({
                  ...value,
                  calle1: value?.entre_calles?.calle1,
                  calle2: value?.entre_calles?.calle2,
                  coloniaObject: value?.coloniaId,
                  municipioObject: value?.coloniaId?.municipio_id,
                  estadoObject: value?.coloniaId?.municipio_id?.estadoId,
                  paisObject: value?.coloniaId?.municipio_id?.estadoId?.paisId,
                  editando: false,
                });
              } else {
                setDomicilio(null);
              }
            }}
            isDisabled={!customerId || domicilio?._id == 1}
          />
          <If condition={customerId && domicilio?._id != 1}>
            <If.Then>
              <If condition={domicilio?.editando}>
                <If.Then>
                  <Button variant='danger' onClick={() => setDomicilio({ ...domicilio, editando: false })}>
                    <Icon icon='remove' />
                  </Button>
                </If.Then>
                <If.Else>
                  <If condition={domicilio?._id}>
                    <If.Then>
                      <Button variant='success' onClick={() => setDomicilio({ ...domicilio, editando: true })}>
                        <Icon icon='edit' />
                      </Button>
                    </If.Then>
                    <If.Else>
                      <Button variant='success' onClick={() => setDomicilio({ ...INIT_DOMICILIO, customerId: customerId, editando: true })}>
                        <Icon icon='plus' />
                      </Button>
                    </If.Else>
                  </If>
                </If.Else>
              </If>
            </If.Then>
          </If>
        </InputGroup>
      </DivFormGroup>
    );
  }
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12}>
            <DivFormGroup name={name} required title={title}>
              <InputGroup>
                <SelectDomicilioCliente
                  value={domicilio}
                  name={name}
                  options={domicilios}
                  customerId={customerId}
                  onChange={(value) => {
                    if (value) {
                      setDomicilio({
                        ...value,
                        calle1: value?.entre_calles?.calle1,
                        calle2: value?.entre_calles?.calle2,
                        coloniaObject: value?.coloniaId,
                        municipioObject: value?.coloniaId?.municipio_id,
                        estadoObject: value?.coloniaId?.municipio_id?.estadoId,
                        paisObject: value?.coloniaId?.municipio_id?.estadoId?.paisId,
                      });
                    } else {
                      setDomicilio(null);
                    }
                  }}
                  isDisabled={!customerId || domicilio?._id == 1}
                />
              </InputGroup>
            </DivFormGroup>
          </Col>
        </Row>
        {domicilio?.editando && <DomicilioEdit domicilio={domicilio} onChange={setDomicilio} />}
      </Card.Body>
      {domicilio?.editando && (
        <Card.Footer>
          <Row>
            <Col xs={12}>
              {domicilio?._id && (
                <Button onClick={handleEliminarDomicilio} variant='outline-danger'>
                  Eliminar domicilio
                </Button>
              )}

              <div className='pull-right'>
                <Button
                  onClick={() => {
                    if (!domicilio?._id) {
                      setDomicilio(null);
                    } else {
                      setDomicilio({ ...domicilio, editando: false });
                    }
                  }}
                  variant='light'
                >
                  Cancelar
                </Button>
                <Button onClick={handleRegisterDomicilio} variant='success'>
                  Guardar domicilio
                </Button>
              </div>
              <br />
            </Col>
          </Row>
        </Card.Footer>
      )}
    </Card>
  );
};

SelectCreateOrUpdateDomicilio.propTypes = {
  customerId: PropTypes.string,
  domicilios: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  setDomicilios: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
};

export default SelectCreateOrUpdateDomicilio;
