import { IfPermission, RedirectTooltip } from '@controls';
import { Confirmation, If } from '@controls/index';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { RowsNotFound, Icon } from '@stateless';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';
import DeleteEquipment from './DeleteEquipment';
import EquipoPrincipal from './EquipoPrincipal';
import LimitesExistencias from './LimitesExistencias';
import TiposCotizaciones from './TiposCotizaciones';
import IconDropdown from '@controls/IconDropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Link } from 'react-router-dom';

const TableEquipments = ({ listEquipments, loadEquipments }) => {
  const renderRows = () => {
    return listEquipments.map((rowProduct, item) => {
      const {
        _id,
        code,
        principal,
        iva,
        name,
        clasificacionCotizacionId,
        marcaId,
        equipmentsMeasureId,
        precio,
        moneda,
        status,
        tiposCotizacionesId,
      } = rowProduct;
      let currency = 'MXN';
      if (moneda === 'DOLAR' || moneda === 'DOLLAR' || moneda === 'DÓLAR') currency = 'USD';
      return (
        <tr key={item}>
          <td>
            {tiposCotizacionesId &&
              tiposCotizacionesId.map((x, i) => (
                <CbBadge key={i} type='default mr-5'>
                  {x.nombre}
                </CbBadge>
              ))}
          </td>
          <td>
            <small>{clasificacionCotizacionId && clasificacionCotizacionId.nombre} </small>
          </td>
          <td>
            {principal ? <EquipoPrincipal /> : ''}
            <u>{code}</u>
          </td>
          <td>
            {marcaId && (
              <>
                <CbBadge type='warning'>{marcaId.nombre}</CbBadge>&nbsp;
              </>
            )}
            <strong>{name}</strong>
          </td>
          <td>{equipmentsMeasureId?.measure}</td>
          <td>
            $ {format(precio * (1 + iva / 100), 2)} <CbBadge type='primary'>{currency}</CbBadge>
          </td>
          <td className='with-actions'>
            <CbBadge type={`${status === 'ACTIVO' ? 'primary' : 'secondary'}`}>{status}</CbBadge>
          </td>
          <td className='with-actions'>
            <ButtonGroup>
              <IfPermission action='eliminar'>
                <DeleteEquipment id={item} equipmentId={_id} name={`${code} ${name}`} />
              </IfPermission>
              <If condition={status === 'ACTIVO'}>
                <If.Then>
                  <IfPermission action='baja'>
                    <Confirmation
                      id={_id}
                      btnIcon='arrow-down'
                      typeConfirm='warning'
                      btnType='outline-warning'
                      action='Baja'
                      typeRequest='putRequest'
                      url={`equipments/baja/${_id}`}
                      description={`¿Confirma que desea dar de alta el equipo <strong>${code} ${name}</strong> ?`}
                      loadData={loadEquipments}
                    />
                  </IfPermission>
                </If.Then>
              </If>
              <If condition={status === 'BAJA'}>
                <If.Then>
                  <IfPermission action='alta'>
                    <Confirmation
                      id={_id}
                      btnIcon='arrow-up'
                      typeConfirm='success'
                      btnType='outline-success'
                      action='Activar'
                      typeRequest='putRequest'
                      url={`equipments/alta/${_id}`}
                      description={`¿Confirma que desea dar de alta el equipo <strong>${code} ${name}</strong> ?`}
                      loadData={loadEquipments}
                    />
                  </IfPermission>
                </If.Then>
              </If>
              <IfPermission action='editar'>
                <RedirectTooltip id={1} icon='pencil' url={`/equipos/edicion/${_id}`} labelTooltip='Editar' className='btn btn-outline-info' />
              </IfPermission>
              <IfPermission action='detalle'>
                <RedirectTooltip id={1} icon='eye-open' url={`/equipos/detalle/${_id}`} labelTooltip='Detalle' className='btn btn-outline-primary' />
              </IfPermission>
              <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-primary'>
                <LimitesExistencias equipmentId={_id} name={`${code} ${name}`} />
                <TiposCotizaciones equipmentId={_id} name={`${code} ${name}`} />
                <IfPermission action='editar'>
                  <Dropdown.Item as={Link} to={`/equipos/edicion/precios/${_id}`}>
                    <Icon icon='usd' /> Precios
                  </Dropdown.Item>
                </IfPermission>
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  let rows = listEquipments.length > 0 ? renderRows() : <RowsNotFound message='Aún no hay equipos registrados.' colSpan={8} />;
  return (
    <Card>
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th width='10%'>Tipo Cot.</th>
            <th width='10%'>Linea</th>
            <th width='10%'>Código</th>
            <th width='20%'>Nombre</th>
            <th width='10%'>Unidad</th>
            <th width='15%'>Precio IVA</th>
            <th width='15%'>Estatus</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Card>
  );
};

TableEquipments.propTypes = {
  listEquipments: PropTypes.array.isRequired,
  loadEquipments: PropTypes.func.isRequired,
};

export default TableEquipments;
