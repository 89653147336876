import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import InputCurrency from '@components/forms/InputCurrency';
import InputPercentage from '@components/forms/InputPercentage';
import InputNumber from '@components/forms/InputNumber';
import { Button } from 'react-bootstrap';

const RowDetalle = ({ unidades, servicio, listas_precios, onRemoveItemServicio, onChangeServicioDetalle }) => {
  const onChangeServicioListaPreciosInput = useCallback(
    (index, campo, e) => {
      let { value } = e.target;
      if (campo === 'precio' || campo === 'descuento') value = cleanNumber(value);
      onChangeServicioDetalle({
        ...servicio,
        listasPrecios: servicio.listasPrecios.map((listaPrecio, i) => {
          if (i === index)
            return {
              ...listaPrecio,
              [campo]: value,
            };
          return listaPrecio;
        }),
      });
    },
    [servicio, onChangeServicioDetalle]
  );

  const onChangeServicioInput = useCallback(
    (campo, e) => {
      let { value } = e.target;
      if (campo === 'cantidad') value = cleanNumber(value);
      onChangeServicioDetalle({
        ...servicio,
        [campo]: value,
      });
    },
    [servicio, onChangeServicioDetalle]
  );

  const onSelectServicio = useCallback(
    (itemSelect) => {
      onChangeServicioDetalle({
        ...servicio,
        servicio: itemSelect,
        descripcion: itemSelect.name,
      });
    },
    [servicio, onChangeServicioDetalle]
  );

  const onSelectUnidad = useCallback(
    (itemSelect) => {
      onChangeServicioDetalle({
        ...servicio,
        unidad: itemSelect,
      });
    },
    [servicio, onChangeServicioDetalle]
  );

  const loadOptionsServicios = useCallback(async (inputValue, callback) => {
    const { data } = await getRequest({ url: `services-autocomplete`, params: { query: inputValue } });
    callback(data);
  }, []);

  return (
    <>
      <tr>
        <td>
          <DivFormGroup>
            <AsyncSelect
              isClearable
              value={servicio.servicio}
              onChange={onSelectServicio}
              loadOptions={loadOptionsServicios}
              getOptionLabel={(x) => `${x.code} ${x.name}`}
              getOptionValue={(x) => x._id}
              placeholder='Selecciona'
              noResultsText='No se encontraron resultados'
            />
          </DivFormGroup>
        </td>
        <td>
          <FormInput
            title='Descripcion'
            name='descripcion'
            withoutLabel
            required
            onChange={(e) => onChangeServicioInput('descripcion', e)}
            value={servicio.descripcion}
          />
        </td>
        <td>
          <DivFormGroup>
            <Select
              value={servicio.unidad}
              name='unidad'
              placeholder='Selecciona'
              options={unidades}
              onChange={onSelectUnidad}
              getOptionLabel={(x) => `${x.measure}`}
              getOptionValue={(x) => x._id}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </td>
        <td>
          <DivFormGroup>
            <InputNumber
              placeholder='Cantidad'
              className='form-control'
              onChange={(e) => onChangeServicioInput('cantidad', e)}
              value={servicio.cantidad}
            />
          </DivFormGroup>
        </td>
        <td>
          <Button onClick={onRemoveItemServicio} variant='danger' className='pull-right' type='button'>
            <Icon icon='trash' />
          </Button>
        </td>
      </tr>
      <tr>
        <td colSpan={5} style={{ margin: 0, padding: 0 }}>
          <table className='table table-striped align-middle' style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                {listas_precios.map((listaPrecio, i) => (
                  <th className='text-center' colSpan='2' key={i}>
                    {listaPrecio.nombre}
                  </th>
                ))}
              </tr>
              {listas_precios.map((_, i) => (
                <tr key={i}>
                  <th className='text-center'>Precio</th>
                  <th className='text-center'>Descuento</th>
                </tr>
              ))}
            </thead>
            <tbody>
              {servicio.listasPrecios.map((listaPrecios, i) => (
                <tr key={i}>
                  <td>
                    <InputCurrency
                      placeholder='Precio'
                      className='form-control'
                      onChange={(e) => onChangeServicioListaPreciosInput(i, 'precio', e)}
                      value={listaPrecios.precio}
                    />
                  </td>
                  <td>
                    <InputPercentage
                      placeholder='Descuento'
                      className='form-control'
                      onChange={(e) => onChangeServicioListaPreciosInput(i, 'descuento', e)}
                      value={listaPrecios.descuento}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

RowDetalle.propTypes = {
  unidades: PropTypes.array.isRequired,
  servicio: PropTypes.object.isRequired,
  onRemoveItemServicio: PropTypes.func.isRequired,
  onChangeServicioDetalle: PropTypes.func.isRequired,
  listas_precios: PropTypes.array.isRequired,
};

export default RowDetalle;
