import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ data, ...props }) => {
  const renderRows = () => {
    return data.map((row, i) => {
      const { _id, orden, nombre } = row;
      return (
        <tr key={i}>
          <td>{orden}</td>
          <td>{nombre}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={i} itemId={_id} nombre={nombre} {...props} />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={i} itemId={_id} nombre={nombre} orden={orden} {...props} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='5%'>#</th>
          <th width='75%'>Condición</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
