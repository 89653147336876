import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@stateless';
import Table from '../Table';
import CbPagination from '@cbcomponents/CbPagination';
import { format } from '@utils/parseCost';
import PropTypes from 'prop-types';
import { ENCABEZADOS } from '@config/constants';
import { FileUpIcon } from 'lucide-react';

const ENCABEZADOS_2 = {
  activePageD1_30: 1,
  activePageD31_60: 1,
  activePageD61_90: 1,
  activePageD91_120: 1,
  activePageD121: 1,
};

const getEncabezadoLabel = (encabezado) => {
  let label = '';
  if (encabezado === ENCABEZADOS.D1_30) {
    label = '1 - 30 días';
  } else if (encabezado === ENCABEZADOS.D31_60) {
    label = '31 - 60 días';
  } else if (encabezado === ENCABEZADOS.D61_90) {
    label = '61 - 90 días';
  } else if (encabezado === ENCABEZADOS.D91_120) {
    label = '91 - 120 días';
  } else if (encabezado === ENCABEZADOS.D121) {
    label = '+ 121 días';
  }

  return label;
};

const getActivePageEncabezado = (encabezado) => {
  const { activePageD1_30, activePageD31_60, activePageD61_90, activePageD91_120, activePageD121 } = ENCABEZADOS_2;
  let page = 1;
  if (encabezado === ENCABEZADOS.D1_30) {
    page = activePageD1_30;
  } else if (encabezado === ENCABEZADOS.D31_60) {
    page = activePageD31_60;
  } else if (encabezado === ENCABEZADOS.D61_90) {
    page = activePageD61_90;
  } else if (encabezado === ENCABEZADOS.D91_120) {
    page = activePageD91_120;
  } else if (encabezado === ENCABEZADOS.D121) {
    page = activePageD121;
  }

  return page;
};

const RowComponent = ({
  total,
  saldo,
  list,
  count,
  clientes,
  secciones,
  activePage,
  pagination,
  handleExcel,
  handlePagination,
  handleExcelEncabezado,
  paginationEncabezado,
}) => {
  return (
    <Row>
      <Col sm='12'>
        <br />
        <Button onClick={handleExcel} variant='success' className='pull-right' type='button'>
          <Icon icon='save-file' /> Excel
        </Button>
        <Link to='/cartera-vencida/importar' className='btn btn-success pull-right mr-5'>
          <Icon icon='plus' /> Importar
        </Link>
        <legend>General</legend>
        <Col sm='3'>
          <span className='lead'>Importe Total $ {format(total, 2)}</span>
        </Col>
        <Col sm='3'>
          <span className='lead'>Saldo Total $ {format(saldo, 2)}</span>
        </Col>
        <Col sm='3'>
          <span className='lead'>{count} Facturas</span>
        </Col>
        <Col sm='3'>
          <span className='lead'>{clientes} Clientes</span>
        </Col>
        <Col sm='12'>
          <Row>
            <Table list={list} />
            <div className='text-center'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
            </div>
          </Row>
        </Col>
      </Col>
      {secciones.map((s, i) => {
        const labelEncabezado = getEncabezadoLabel(s.encabezado);
        const page = getActivePageEncabezado(s.encabezado);
        return (
          <Col sm='12' key={i}>
            <Row>
              <Button onClick={() => handleExcelEncabezado(s.encabezado)} variant='success' className='pull-right' type='button'>
                <Icon icon='save-file' /> Excel
              </Button>
              <Button onClick={() => handleExcelEncabezado(s.encabezado)} variant='warning' className='pull-right' type='button'>
                <FileUpIcon /> Importar
              </Button>
              <legend style={{ marginBottom: '5px' }}>{labelEncabezado}</legend>
              <Col sm='3'>
                <span className='lead'>Importe Total $ {format(s.total, 2)}</span>
              </Col>
              <Col sm='3'>
                <span className='lead'>Saldo Total $ {format(s.saldo, 2)}</span>
              </Col>
              <Col sm='3'>
                <span className='lead'>{s.count} Facturas</span>
              </Col>
              <Col sm='3'>
                <span className='lead'>{s.clientes} Clientes</span>
              </Col>
              <Col sm='12'>
                <Table list={s.list.length > 10 ? s.list.slice(0, 10) : s.list} />
                <div className='text-center'>
                  <CbPagination activePage={page} count={s.count} pagination={pagination} onSelect={() => paginationEncabezado(s)} />
                </div>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

RowComponent.propTypes = {
  total: PropTypes.number.isRequired,
  saldo: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  clientes: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  secciones: PropTypes.array.isRequired,
  activePage: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  handleExcel: PropTypes.func.isRequired,
  handlePagination: PropTypes.func.isRequired,
  getEncabezadoLabel: PropTypes.func.isRequired,
  getActivePageEncabezado: PropTypes.func.isRequired,
  handleExcelEncabezado: PropTypes.func.isRequired,
  paginationEncabezado: PropTypes.func.isRequired,
};

export default RowComponent;
