import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const TextDateDiff = ({ children, format = 'YYYY-MM-DDTHH:mm:ssZ' }) => {
    if (!children) return null;
    if (!moment(children).isValid()) return null; 
    let start = moment(children, format).local();
    let end = moment();
    return <>{start.from(end)}</>;
};

TextDateDiff.propTypes = {
  children: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default TextDateDiff;
