import { AutoComplete, DivFormGroup } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest, putRequest } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import Calendario from './Calendario';
import List from './List';
import { Card, Col, Row, Button } from 'react-bootstrap';

const MatrizVacaciones = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anio] = useState(moment().format('YYYY'));
  const [departamentos, setDepartamentos] = useState([]);
  const [gruposEmpleados, setGruposEmpleados] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [puestoId, setPuestoId] = useState(null);
  const [grupoEmpleadoId, setGrupoEmpleadoId] = useState(null);
  const [departamentoId, setDepartamentoId] = useState(null);
  const [tipo, setTipo] = useState('LISTAR');
  const [diasCorrespondientes] = useState([
    { anios: 1, dias: 6 },
    { anios: 2, dias: 8 },
    { anios: 3, dias: 10 },
    { anios: 4, dias: 12 },
    { anios: 9, dias: 14 },
    { anios: 14, dias: 16 },
    { anios: 19, dias: 18 },
    { anios: 24, dias: 20 },
    { anios: 29, dias: 22 },
  ]);

  const loadCatalogos = useCallback(async (callback) => {
    const {
      data: { puestos, departamentos, grupos_empleados },
    } = await getRequest({ url: `empleados/catalogos` });
    setDepartamentos(departamentos.map((x) => ({ value: x._id, label: x.nombre, disabled: false })));
    setPuestos(puestos.map((x) => ({ value: x._id, label: x.nombre, disabled: false })));
    setGruposEmpleados(grupos_empleados.map((x) => ({ value: x._id, label: x.nombre, disabled: false })));
    if (callback) callback();
  }, []);

  const getDiasCorrespondientes = (antiguedad) => {
    for (let i = 0; i < diasCorrespondientes.length; i++) {
      const x = diasCorrespondientes[i];
      if (antiguedad <= x.anios) {
        return x.dias;
      }
    }
    return 22;
  };

  const load = useCallback(() => {
    loadCatalogos(async () => {
      const { data } = await getRequest({ url: `empleados/list` });
      const responseVacaciones = await getRequest({ url: `empleados-vacaciones/anio/${anio}` });
      try {
        setList(
          data.map((x) => {
            const antiguedad = moment().diff(moment(x.fecha_ingreso), 'years');
            const dias_correspondientes = getDiasCorrespondientes(antiguedad);
            let dias_tomados = 0;
            let dias_solicitados = 0;
            let dias_adicionales = 0;
            const vacaciones = responseVacaciones.data.find((y) => y.empleadoId === x._id) || {};
            let estatus = '';
            let vacacionesId = null;
            let primaVacacional = false;
            if (vacaciones._id) {
              primaVacacional = vacaciones.prima_vacacional;
              vacacionesId = vacaciones._id;
              dias_adicionales = vacaciones.dias_adicionales;
              dias_solicitados = vacaciones.rangos.map((y) => y.dias_solicitados).reduce((a, b) => a + b, 0);
              if (vacaciones.rangos.length > 0) {
                let esUltimoRango = false;
                if (dias_solicitados === 0) {
                  estatus = <CbBadge type='secondary'>Sin asignar</CbBadge>;
                } else if (dias_solicitados === dias_correspondientes + dias_adicionales) {
                  estatus = <CbBadge type='primary'>Solicitadas</CbBadge>;
                } else {
                  estatus = <CbBadge type='primary'>Solicitadas parcialmente</CbBadge>;
                }
                for (let i = 0; i < vacaciones.rangos.length; i++) {
                  esUltimoRango = i === vacaciones.rangos.length - 1;
                  const rango = vacaciones.rangos[i];
                  const fecha_inicial = moment(rango.fecha_inicial.substring(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD');
                  const fecha_final = moment(rango.fecha_final.substring(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD');
                  const difInicio = moment(moment(fecha_inicial).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days');
                  const difFinal = moment(moment(fecha_final).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days');
                  if (difInicio < 0) {
                    if (difFinal < 0) {
                      if (esUltimoRango && dias_solicitados === dias_correspondientes + dias_adicionales) {
                        estatus = (
                          <CbBadge type='warning' style={{ background: 'success' }}>
                            Tomadas
                          </CbBadge>
                        );
                        break;
                      }
                      estatus = (
                        <CbBadge type='warning' style={{ background: 'success' }}>
                          Tomadas parcialmente
                        </CbBadge>
                      );
                    } else {
                      estatus = (
                        <CbBadge type='warning' style={{ background: 'orange' }}>
                          En vacaciones
                        </CbBadge>
                      );
                      break;
                    }
                  } else {
                    if (difInicio <= 30) {
                      estatus = (
                        <CbBadge type='warning' style={{ background: 'yellow' }}>
                          Proximo a tomar
                        </CbBadge>
                      );
                      break;
                    }
                  }
                }
              } else {
                estatus = <CbBadge type='secondary'>Sin asignar</CbBadge>;
              }
            } else {
              estatus = <CbBadge type='secondary'>Sin asignar</CbBadge>;
            }

            return {
              ...x,
              anio,
              antiguedad,
              vacacionesId,
              dias_adicionales,
              dias_correspondientes,
              dias_tomados,
              dias_solicitados,
              prima_vacacional: primaVacacional,
              rangos: vacaciones.rangos || [],
              estatus,
            };
          })
        );
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    });
  }, [anio, loadCatalogos]);

  const establecerPrimaVacacional = async (item, vacaciones_empleado, vacacionesId) => {
    setList((prevList) =>
      prevList.map((x, i) => ({
        ...x,
        prima_vacacional: item === i ? !x.prima_vacacional : x.prima_vacacional,
      }))
    );
    const body = {
      _id: vacacionesId,
      prima_vacacional: !vacaciones_empleado.prima_vacacional,
    };
    await putRequest({ url: `empleados-vacaciones/prima_vacacional/${vacacionesId}`, body });
  };

  const onClickPrimaVacacional = async (item, vacaciones_empleado) => {
    if (!vacaciones_empleado.vacacionesId) {
      const body = {
        empleadoId: vacaciones_empleado._id,
        ...vacaciones_empleado,
      };
      const { data } = await postRequest({ url: 'empleados-vacaciones', body });
      establecerPrimaVacacional(item, vacaciones_empleado, data._id);
      return;
    }
    establecerPrimaVacacional(item, vacaciones_empleado, vacaciones_empleado.vacacionesId);
  };

  const renderList = () => {
    const listdetail = list.filter(
      (x) =>
        (!departamentoId || (departamentoId && x.departamentoId && departamentoId === x.departamentoId._id)) &&
        (!puestoId || (puestoId && x.puestoId && puestoId === x.puestoId._id)) &&
        (!grupoEmpleadoId || (grupoEmpleadoId && x.grupoEmpleadoId && grupoEmpleadoId === x.grupoEmpleadoId._id))
    );
    if (tipo === 'LISTAR') {
      return <List list={listdetail} load={load} onClickPrimaVacacional={onClickPrimaVacacional} />;
    }
    return <Calendario list={listdetail} load={load} />;
  };

  const onChangeAutoComplete = (name, value) => {
    if (name === 'grupoEmpleadoId') setGrupoEmpleadoId(value);
    if (name === 'departamentoId') setDepartamentoId(value);
    if (name === 'puestoId') setPuestoId(value);
  };

  useEffect(() => {
    load();
  }, [load]);

  const moduleList = renderList();

  return (
    <Module title='Matriz Vacacional'>
      <Card>
        <Card.Body>
          <Row>
            <Col sm='9'>
              <Row>
                <Col md='4'>
                  <DivFormGroup name='grupoEmpleadoId' title='Grupo de empleados'>
                    <AutoComplete
                      id='grupoEmpleadoId'
                      name='grupoEmpleadoId'
                      defaultValue={grupoEmpleadoId}
                      data={gruposEmpleados}
                      callback={(value) => onChangeAutoComplete('grupoEmpleadoId', value)}
                    />
                  </DivFormGroup>
                </Col>
                <Col md='4'>
                  <DivFormGroup name='departamentoId' title='Departamento'>
                    <AutoComplete
                      id='departamentoId'
                      name='departamentoId'
                      defaultValue={departamentoId}
                      data={departamentos}
                      callback={(value) => onChangeAutoComplete('departamentoId', value)}
                    />
                  </DivFormGroup>
                </Col>
                <Col md='4'>
                  <DivFormGroup name='puestoId' title='Puesto'>
                    <AutoComplete
                      id='puestoId'
                      name='puestoId'
                      defaultValue={puestoId}
                      data={puestos}
                      callback={(value) => onChangeAutoComplete('puestoId', value)}
                    />
                  </DivFormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm='3'>
              {tipo === 'LISTAR' ? (
                <Button variant='success' className='pull-right' onClick={() => setTipo('CALENDARIO')}>
                  CALENDARIO
                </Button>
              ) : (
                <Button variant='success' className='pull-right' onClick={() => setTipo('LISTAR')}>
                  TABLA
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Loading loading={loading}>{moduleList}</Loading>
    </Module>
  );
};

MatrizVacaciones.propTypes = {};

export default MatrizVacaciones;
