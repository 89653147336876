import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { format } from '@utils/parseCost';
import { IfPermission } from '@controls';
import PropTypes from 'prop-types';

const TableProductosVendidos = ({ productosVendidos }) => {
  if (productosVendidos.length == 0) return null;
  return (
    <IfPermission action='ventas'>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>Totales de productos vendidos</Card.Title>
            </Card.Header>
            <table className='table'>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Descripción</th>
                  <th width='10%'>Cantidad</th>
                  {/* <th width="10%">Total</th> */}
                </tr>
              </thead>
              <tbody>
                {productosVendidos.map((a, i) => (
                  <tr key={i}>
                    <th>{a._id.codigo}</th>
                    <td>{a._id.descripcion}</td>
                    <th>{format(a.cantidad)}</th>
                    {/* <td>$ {format(a.total)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </IfPermission>
  );
};

TableProductosVendidos.propTypes = {
  productosVendidos: PropTypes.array,
};

export default TableProductosVendidos;
