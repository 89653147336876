import { TEMPORALIDAD } from '@config/constants';
import { DivFormGroup, Label, TextArea } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import { useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const [state, setState] = useState({
    loading: true,
    nombre: '',
    temporalidad: '',
    piezas: [],
    detalle: [],
    itemPiezas: 1,
    totalPiezas: 0,
  });

  const addForm = useRef(null);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    const { kitServicioId } = params;
    const { data, piezas } = await getRequest({ url: `kit-servicios/${kitServicioId}` });
    setState({
      ...state,
      nombre: data.nombre,
      piezas,
      detalle: data.detalle,
      itemPiezas: data.detalle.length,
      loading: false,
    });
  };

  const onChangePiezas = () => {
    const { itemPiezas } = state;

    let totalPiezas = 0;

    for (let x = 0; x < itemPiezas; x++) {
      totalPiezas += castMasks(`cantidad-${x}`);
    }

    setState({ ...state, totalPiezas });
  };

  const castMasks = (field) => {
    let value = 0;

    if (typeof addForm.current[field] !== 'undefined') {
      value = parseFloat(addForm.current[field].value.trim().replace(/\$|,/gi, ''));

      if (isNaN(value)) {
        value = 0;
      }
    }

    return value;
  };

  const handleAddForm = async (event) => {
    event.preventDefault();

    const { kitServicioId } = params;
    const { nombre, temporalidad, itemPiezas } = state;

    const listDetalle = [];
    let total = 0;

    for (let i = 0; i < itemPiezas; i++) {
      if (typeof addForm.current[`piezaId-${i}`] !== 'undefined') {
        const cantidad = castMasks(`cantidad-${i}`);
        if (isMongoId(addForm.current[`piezaId-${i}`].value + '') && cantidad > 0 && !isEmpty(addForm.current[`temporalidad-${i}`].value)) {
          listDetalle.push({
            piezaId: addForm.current[`piezaId-${i}`].value,
            cantidad,
            temporalidad: addForm.current[`temporalidad-${i}`].value,
          });
          total += cantidad;
        }
      }
    }

    const data = {
      nombre,
      temporalidad,
      detalle: listDetalle,
      total,
    };

    if (validations(data)) {
      await putRequest({ url: `kit-servicios/${kitServicioId}`, body: data });
      loadForm();
    }
  };

  const addItemPieza = () => {
    setState({ ...state, itemPiezas: state.itemPiezas + 1 });
  };

  const removeItemPieza = (item) => {
    document.getElementById(`row-pieza-${item}`).innerHTML = null;
    onChangePiezas();
  };

  const renderPiezas = () => {
    const views = [];
    const { itemPiezas, piezas, detalle } = state;

    for (var i = 0; i < itemPiezas; i++) {
      const item = detalle[i];
      const setValue = typeof item !== 'undefined' ? true : false;

      const view = (
        <div key={i} id={`row-pieza-${i}`}>
          <Col sm='4'>
            <DivFormGroup>
              <select
                name={`piezaId-${i}`}
                id={`piezaId-${i}`}
                className='form-control'
                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                disabled={piezas.length > 0 ? false : true}
                defaultValue={setValue ? item.piezaId : ''}
              >
                <option value=''>Seleccione</option>
                {piezas.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup>
              <select
                name={`temporalidad-${i}`}
                id={`temporalidad-${i}`}
                className='form-control'
                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                defaultValue={setValue ? item.temporalidad : ''}
              >
                <option value=''>Seleccione</option>
                <option value={TEMPORALIDAD.EVENTO}>Por evento</option>
                <option value={TEMPORALIDAD.DIARIO}>Diario</option>
                <option value={TEMPORALIDAD.SEMANAL}>Semanal</option>
                <option value={TEMPORALIDAD.MENSUAL}>Mensual</option>
                <option value={TEMPORALIDAD.ANUAL}>Anual</option>
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <InputNumber name={`cantidad-${i}`} onChange={onChangePiezas} value={setValue ? item.cantidad : ''} />
            </DivFormGroup>
          </Col>
          <Col sm='1'>
            <Button onClick={() => removeItemPieza(i)} variant='danger' className='pull-right' type='button'>
              <Icon icon='trash' />
            </Button>
          </Col>
        </div>
      );
      views.push(view);
    }

    return (
      <>
        <Button onClick={addItemPieza} variant='success' className='btn-sm pull-right' style={{ marginTop: '-55px' }} type='button'>
          <Icon icon='plus' />
        </Button>
        <Col sm='4'>
          <label>Pieza</label>
        </Col>
        <Col sm='4'>
          <label>Frecuencia</label>
        </Col>
        <Col sm='3'>
          <label>Cantidad</label>
        </Col>
        {views}
      </>
    );
  };

  const renderForm = () => {
    const { loading, nombre } = state;

    return (
      <form ref={addForm} onSubmit={handleAddForm}>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <Label name='nombre' title='Nombre del Kit' />
              <TextArea name='nombre' onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })} defaultValue={nombre} />
            </DivFormGroup>
          </Col>
        </Row>

        <Row>
          <legend>Componentes del Kit</legend>
          {renderPiezas()}
        </Row>

        <Row>
          <Col sm='12'>
            <Card>
              <Card.Body>
                <Saving saving={loading} />
                <Button type='submit'variant='primary' className='btn-lg pull-right' disabled={loading}>
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    );
  };

  const validations = (data) => {
    let validate = { success: true, message: '' };

    if (isEmpty(data.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es Requerido.';
    } else if (validate.success && data.detalle.length <= 0) {
      validate.success = false;
      validate.message = 'Falta especificar las piezas del kit';
    } else if (validate.success && data.total <= 0) {
      validate.success = false;
      validate.message = 'Falta especificar las piezas del kit';
    }

    const unique = Array.from(new Set(data.detalle.map((d) => d.piezaId)));

    if (validate.success && unique.length < data.detalle.length) {
      validate.success = false;
      validate.message = 'Hay piezas duplicadas, favor de verificar';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { loading } = state;
  const moduleForm = !loading ? renderForm() : <Loading />;

  return (
    <Module onClickBack={window.history.back} title='Edición del Kit'>
      {moduleForm}
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
