import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalImprimir = ({ showModalDownload, close, agregarOtra, onAceptar }) => {
  return (
    <Modal show={showModalDownload} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Descargar pedido</Modal.Title>
      </Modal.Header>
      <Modal.Footer style={{ textAlign: 'center' }}>
        <Button onClick={close} className='mr-5' type='button'>
          &nbsp;Cerrar&nbsp;
        </Button>
        <Button onClick={agregarOtra} variant='primary' className='mr-5' type='button'>
          &nbsp;Nueva&nbsp;
        </Button>
        <Button onClick={onAceptar} variant='primary' className='mr-5' type='button'>
          Imprimir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalImprimir.propTypes = {
  showModalDownload: PropTypes.bool,
  close: PropTypes.func,
  agregarOtra: PropTypes.func,
  onAceptar: PropTypes.func,
};

export default ModalImprimir;
