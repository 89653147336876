import React, { useState, useEffect } from 'react';
import IfPermission from '@controls/IfPermission';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { DivFormGroup } from '@controls';
import { Module, Loading } from '@stateless';
import { getRequest } from '@utils/api';
import { Card, Col, Row } from 'react-bootstrap';
import SelectAgente from '@components/forms/SelectAgente';

const AgendaVisitas = () => {
  const [loading, setLoading] = useState(true);
  const [agente, setAgente] = useState(null);
  const [agenda, setAgenda] = useState([]);

  useEffect(() => {
    inicializar();
  }, []);

  const loadData = async () => {
    const { data } = await getRequest({ url: `ordenes-servicio/agendaVisitas/${agente?._id}` });
    setAgenda(data);
    setLoading(false);
  };

  const inicializar = async () => {
    const {
      data: { agente },
    } = await getRequest({ url: `agenda-visitas-catalogos` });
    setAgente(agente);
    loadData();
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setAgente(itemSelect);
      setLoading(true);
      loadData();
    } else {
      setAgente(null);
    }
  };

  return (
    <Module title='Agenda de visitas'>
      <IfPermission action='TodosAsesores'>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <form>
                  <Row>
                    <Col>
                      <DivFormGroup name='agentes' title='Seleccionar agente'>
                        <SelectAgente value={agente} onChange={onChangeAgente} name='agente' />
                      </DivFormGroup>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </IfPermission>
      <Loading loading={loading}>
        <Card>
          <Card.Header>Calendario</Card.Header>
          <Card.Body>
            <FullCalendar locales={[esLocale]} locale='es' defaultView='dayGridMonth' plugins={[dayGridPlugin]} events={agenda} />
          </Card.Body>
        </Card>
      </Loading>
    </Module>
  );
};

export default AgendaVisitas;
