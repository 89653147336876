import { DivFormGroup, Input } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import { Col, Row } from 'react-bootstrap';

const NewVisita = ({ loadData, close, agentes, showModalNew, list }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    visitaId: '',
    ordenServicioId: null,
    ordenServicio: null,
    fecha_hora: moment().format('DD/MM/YYYY HH:mm'),
    horas_servicio: 1,
    agentes_asignados: [],
    observaciones: '',
    agentes: [],
    agente_id: [],
  });

  useEffect(() => {
    // componentDidMount logic here
  }, []);

  const handleClose = () => {
    setState({
      ...state,
      showModal: false,
      visitaId: '',
      ordenServicioId: '',
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
      horas_servicio: 1,
      observaciones: '',
      agentes: [],
      agente_id: [],
      visita: {},
    });
    close();
  };

  const onSelect = (campo, value) => {
    setState({ ...state, [campo]: value });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleAddVisita = async () => {
    const { ordenServicioId, fecha_hora, horas_servicio, agente_id, observaciones } = state;
    const body = {
      fecha_hora: Date.parse(fecha_hora),
      horas_servicio,
      agente_id: agente_id.map((x) => x.value),
      observaciones,
    };
    if (validations(body)) {
      try {
        await postRequest({ url: `ordenes-servicio/visitas/agregar/${ordenServicioId._id}`, body });
        handleClose();
        loadData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    } else if (params.agente_id.length <= 0) {
      validate.success = false;
      validate.message = 'Agentes son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const { agente_id, fecha_hora, horas_servicio, observaciones, ordenServicioId } = state;

  return (
    <CbModal show={showModalNew} onClose={handleClose} title='Nueva visita' onSave={handleAddVisita}>
      <Row>
        <Col sm='12'>
          <DivFormGroup name='agente_id' required title='Orden de servicio'>
            <Select
              value={ordenServicioId}
              name='ordenServicioId'
              getOptionLabel={(e) =>
                `ORDEN #${e.numero_orden_servicio} CLIENTE: ${e.cliente.razon_social} DOMICILIO: ${textDomicilio(e.cliente.domicilio)}`
              }
              getOptionValue={(e) => e._id}
              options={list}
              onChange={(value) => onSelect('ordenServicioId', value)}
              className='basic-multi-select'
              placeholder='Seleccione una orden de servicio'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='fecha_hora' title='Fecha y hora'>
            <Input type='datetime-local' name='fecha_hora' onChange={onChange} value={fecha_hora} />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='horas_servicio' title='Horas de servicio'>
            <Input type='number' name='horas_servicio' onChange={onChange} value={horas_servicio} />
          </DivFormGroup>
        </Col>
        <Col sm='12'>
          <DivFormGroup name='observaciones' title='Observaciones' required>
            <textarea className='form-control' name='observaciones' onChange={onChange} value={observaciones} />
          </DivFormGroup>
        </Col>
        <Col sm='12'>
          <DivFormGroup name='agente_id' required title='Agentes'>
            <Select
              isMulti
              value={agente_id}
              name='agente_id'
              options={agentes}
              onChange={(value) => onSelect('agente_id', value)}
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder='Seleccione agentes'
              getOptionLabel={(e) => e.nombre}
              getOptionValue={(e) => e._id}
            />
          </DivFormGroup>
        </Col>
      </Row>
    </CbModal>
  );
};

NewVisita.propTypes = {
  loadData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  agentes: PropTypes.array.isRequired,
  showModalNew: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
};

export default NewVisita;
