import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';
import { Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import ModalOpcionesDocumentos from './ModalOpcionesDocumentos';
import FormOrdenServicio from './FormOrdenServicio';
import { If } from '@controls';
import FormPedido from './FormPedido';
import { validationsConversion } from '@utils/cotizaciones';
import { useStoreAgentes, useStoreSucursales } from '@stores/catalogs.store';
import { getReferencia } from '@utils/cotizaciones';
import { socketManager } from '../../../../../socketManager';
import useAuth from '@hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import Swal from 'sweetalert2';

const ConvertirCotizacion = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const { levantamientoId } = useParams();
  const { agente: agenteAuth } = useAuth();
  const getStoreSucursales = useStoreSucursales();
  const getStoreAgentes = useStoreAgentes();
  const [cotizacion, setCotizacion] = useState({});

  const [state, setState] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    fecha_solicitud: moment().format('YYYY-MM-DD'),
    fecha_entrega: moment().format('YYYY-MM-DD'),
    observacion: '',
    descripcion: '',
    sucursal: null,
    almacen: null,
    cliente: null,
    agente: null,
    clienteRegistro: null,
    genera_orden_servicio: true,
    horas: 0,
    detalles_equipos: [],
    detalles_servicios: [],
    tipos_pedidos: [],
    soluciones_solicitadas_id: [],
    tipos_servicios_solicitados_id: [],
    tipos_mano_obra_id: null,
    tipoPedidoId: null,
    tipoOrdenServicioId: null,
    showModalDownload: false,
    numero_pedido: '',
    numero_orden_servicio: '',
    detalles: [],
  });

  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStoreAgentes.execute();
    getStoreSucursales.execute();
  }, []);

  useEffect(() => {
    ObtenerDatos();
  }, [getStoreAgentes.data]);

  useEffect(() => {
    if (state.tipoPedidoId) {
      ObtenerNumeroPedido();
    }
  }, [state.tipoPedidoId?._id]);

  useEffect(() => {
    if (state.tipoOrdenServicioId) {
      ObtenerNumeroOrdenServicio();
    }
  }, [state.tipoOrdenServicioId?._id]);

  useEffect(() => {
    ObtenerInformacionPedidoServicio();
  }, [state.almacen?._id]);

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSelectTipoPedido = (value) => {
    setState((prevState) => ({
      ...prevState,
      tipoPedidoId: value,
    }));
  };

  const onSelectTipoOrdenServicio = (value) => {
    setState((prevState) => ({
      ...prevState,
      tipoOrdenServicioId: value,
    }));
  };

  const onChangeCliente = (value) => {
    setState((prevState) => ({
      ...prevState,
      clienteRegistro: value,
    }));
  };

  const closeDownload = () => {
    setState(
      (prevState) => ({
        ...prevState,
        showModalDownload: false,
      }),
      () => {
        setTimeout(() => navigate('/cotizaciones'), 1000);
      }
    );
  };

  const onOpenPedidoId = () => {
    const { pedidoId } = state;
    navigate(`/pedidos/edicion/${pedidoId}`);
  };

  const onOpenOrdenServicioId = () => {
    const { ordenServicioId } = state;
    navigate(`/ordenes-servicio/editar/${ordenServicioId}`);
  };

  const onOpenEditarCotizacion = () => {
    navigate(`/cotizaciones/edicion/${levantamientoId}`);
  };

  const ObtenerDatos = async () => {
    setIsLoadingForm(true);
    const { data: cotizacion } = await getRequest({ url: `cotizaciones/${levantamientoId}` });
    if (cotizacion.tipo_cliente === 'PROSPECTO') {
      notification({
        title: 'Alerta',
        message: '¡Esta cotización es de un prospecto!',
        type: 'warning',
      });
      setTimeout(() => navigate('/cotizaciones'), 1000);
      return;
    }
    if (!!cotizacion.pedidoId && !!cotizacion.ordenServicioId) {
      notification({
        title: 'Alerta',
        message: '¡Esta cotización ya tiene pedido y orden de servicio!',
        type: 'warning',
      });
      setTimeout(() => navigate('/cotizaciones'), 1000);
      return;
    }
    const agente = getStoreAgentes.data.find((x) => x._id === cotizacion.agente._id);
    setCotizacion(cotizacion);
    if (cotizacion.referencia_id) {
      const { data: referencia } = await getRequest({ url: `cotizaciones/referencia/${levantamientoId}` });
      setState((prevState) => ({
        ...prevState,
        agente: agente,
        sucursal: cotizacion.sucursal,
        almacen: agenteAuth?.almacenId,
        tienePedido: !!cotizacion.pedidoId,
        pedidoId: cotizacion.pedidoId,
        ordenServicioId: cotizacion.ordenServicioId,
        tieneOrdenServicio: !!cotizacion.ordenServicioId,
        soluciones_solicitadas_id: referencia.soluciones_solicitadas_id,
        tipos_servicios_solicitados_id: referencia.tipos_servicios_solicitados_id,
        tipos_mano_obra_id: referencia.tipos_mano_obra_id,
        horas: referencia.horas,
        tipos_cobro_equipo_id: referencia.tipos_cobro_equipo_id,
        descripcion: referencia.descripcion,
        clienteRegistro: referencia.cliente_registro_id,
      }));
    } else {
    setState((prevState) => ({
      ...prevState,
      agente: agente,
      sucursal: cotizacion.sucursal,
      almacen: agenteAuth?.almacenId,
      tienePedido: !!cotizacion.pedidoId,
      pedidoId: cotizacion.pedidoId,
      ordenServicioId: cotizacion.ordenServicioId,
      tieneOrdenServicio: !!cotizacion.ordenServicioId,
    }));

    }
    setIsLoadingForm(false);
    ObtenerInformacionPedidoServicio();
  };

  const ObtenerInformacionPedidoServicio = async () => {
    setIsLoadingTable(true);
    const { almacen } = state;
    const { detalles_equipos, detalles_servicios, customer_registro } = await getRequest({
      url: `cotizaciones/infopedidoservicio/${levantamientoId}/${almacen?._id}`,
    });
    setState((prevState) => ({
      ...prevState,
      horas: detalles_servicios.map((x) => x.cantidad).reduce((s, a) => s + a, 0),
      clienteRegistro: customer_registro,
      detalles_equipos,
      detalles_servicios,
      genera_orden_servicio: !cotizacion.ordenServicioId && detalles_servicios.length > 0,
    }));
    setIsLoadingTable(false);
  };

  const ObtenerNumeroPedido = async () => {
    const { tipoPedidoId } = state;
    const {
      data: { numero_pedido },
    } = await getRequest({ url: `pedidos/siguienteNumero`, params: { tipoPedidoId: tipoPedidoId._id } });
    setState((prevState) => ({
      ...prevState,
      numero_pedido,
    }));
  };

  const ObtenerNumeroOrdenServicio = async () => {
    const { tipoOrdenServicioId } = state;
    const {
      data: { numero_orden_servicio },
    } = await getRequest({ url: `ordenes-servicio/siguienteNumero`, params: { tipoOrdenServicioId: tipoOrdenServicioId._id } });
    setState((prevState) => ({
      ...prevState,
      numero_orden_servicio,
    }));
  };

  const handleCompletarPedidoServicio = async (event) => {
    event.preventDefault();
    const {
      sucursal,
      almacen,
      agente,
      detalles_equipos,
      detalles_servicios,
      soluciones_solicitadas_id,
      tipos_servicios_solicitados_id,
      tipos_mano_obra_id,
      tipoPedidoId,
      numero_pedido,
      numero_orden_servicio,
      tipoOrdenServicioId,
      tipos_cobro_equipo_id,
      clienteRegistro,
      descripcion,
      observacion,
      tienePedido,
      genera_orden_servicio,
      horas,
      fecha_solicitud,
      fecha_entrega,
    } = state;

    const data = {
      fecha_solicitud: moment(fecha_solicitud, 'YYYY-MM-DD').utc().format(),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD').utc().format(),
      tipoPedidoId: tipoPedidoId?._id,
      numero_pedido: numero_pedido || 0,
      tipoOrdenServicioId: tipoOrdenServicioId?._id,
      numero_orden_servicio,
      clienteRegistroId: clienteRegistro?._id,
      agenteId: agente?._id,
      sucursalId: sucursal?._id,
      almacenId: almacen?._id,
      detalles_equipos,
      detalles_servicios,
      soluciones_solicitadas_id: soluciones_solicitadas_id.map((x) => x._id),
      tipos_servicios_solicitados_id: tipos_servicios_solicitados_id.map((x) => x._id),
      tipos_mano_obras_id: tipos_mano_obra_id?._id,
      tipos_cobro_equipo_id: tipos_cobro_equipo_id?._id,
      descripcion,
      observacion,
      horas,
      tienePedido,
      genera_orden_servicio,
      referencia,
    };
    const { success, message } = validationsConversion(data);
    if (!success) {
      return notification({
        title: 'Validaciones',
        message,
        type: 'error',
      });
    }
    setIsLoading(true);
    const { data: dataValidacion } = await postRequest({ url: `cotizaciones/validarordenreciente/${clienteRegistro?._id}` });
    let confirmar = true;
    if (!dataValidacion) {
        confirmar = await verificarGenerarConOrdenServicioActiva();
    }
    if (dataValidacion?.accion == 'CONFIRMAR') {
      confirmar = await verificarGenerarOrdenServicio();
    }
    if (confirmar) {
      const {
        data: { notificaciones, pedido, orden_servicio },
      } = await postRequest({ url: `cotizaciones/generarpedidoservicio/${levantamientoId}`, body: data });
      notificaciones.forEach((notificacion) => {
        socketManager.emit('NOTIFICATION', notificacion);
      });
      setState((prevState) => ({
        ...prevState,
        showModalDownload: true,
        pedido,
        orden_servicio,
      }));
    }
    setIsLoading(false);
  };

  const verificarGenerarOrdenServicio = async () => {
    const res = await Swal.fire({
      html: `<div style="font-size: 1.5em!important;">Confirmación</div><span>¡El CRM tiene una visita generada recientemente! ¿Esta de acuerdo?</span>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, confirmo',
      cancelButtonText: 'No',
    });
    return res.isConfirmed;
  };

  const verificarGenerarConOrdenServicioActiva = async () => {
    const res = await Swal.fire({
      html: `<div style="font-size: 1.5em!important;">Confirmación</div><span>¡El CRM tiene una visita generada activa! ¿Aun así quieres generarla?</span>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, confirmo',
      cancelButtonText: 'No',
    });
    return res.isConfirmed;
  };
  
  const {
    showModalDownload,
    pedido,
    orden_servicio,
    almacen,
    tipoPedidoId,
    soluciones_solicitadas_id,
    tipos_servicios_solicitados_id,
    tipos_mano_obra_id,
    horas,
    tipoOrdenServicioId,
    tipos_cobro_equipo_id,
    descripcion,
    clienteRegistro,
    numero_orden_servicio,
    agente,
    sucursal,
    fecha_solicitud,
    fecha_entrega,
    genera_orden_servicio,
    tienePedido,
    detalles_equipos,
    detalles_servicios,
    tieneOrdenServicio,
    numero_pedido,
  } = state;

  const referencia = getReferencia({
    tipos_mano_obra: tipos_mano_obra_id?.clave,
    tipo_servicio_solicitado: tipos_servicios_solicitados_id.length > 0 ? tipos_servicios_solicitados_id[0]?.clave : '',
    tipos_cobro_equipo: tipos_cobro_equipo_id?.clave,
    monitoreo: clienteRegistro?.monitoreo,
    folio: clienteRegistro?.folio,
    descripcion,
    horas,
    numero_levantamiento: cotizacion?.numero_levantamiento,
    numero_pedido: numero_pedido,
    agente: agente?.codigo,
  });

  return (
    <Module onClickBack={window.history.back} parent='Cotizaciones' title='Convertir cotización'>
      <Loading loading={isLoadingForm}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <DivFormGroup name='sucursal' required title='Sucursal'>
                  <Select
                    id='sucursal'
                    value={sucursal}
                    name='sucursal'
                    placeholder='Seleccionar'
                    options={getStoreSucursales.data}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x._id}
                    onChange={(value) => onSelect('sucursal', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm={3}>
                <DivFormGroup name='agente' required title='Agente solicitante'>
                  <Select
                    id='agente'
                    value={agente}
                    name='agente'
                    placeholder='Seleccionar'
                    options={getStoreAgentes.data}
                    getOptionLabel={(x) => x.nombre}
                    getOptionValue={(x) => x._id}
                    onChange={(value) => onSelect('agente', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm={3}>
                <FormInput title='Fecha solicitud' type='date' required name='fecha_solicitud' onChange={onChange} value={fecha_solicitud} />
              </Col>
              <Col sm={3}>
                <FormInput title='Fecha entrega' type='date' required name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <h3>
                  <div className='checkbox'>
                    <label htmlFor='genera_orden_servicio'>
                      {!tieneOrdenServicio && detalles_servicios.length > 0 && (
                        <input type='checkbox' name='genera_orden_servicio' checked={genera_orden_servicio} onChange={onChange} />
                      )}
                      Orden de servicio
                    </label>
                  </div>
                </h3>
              </Col>
            </Row>
            <If condition={!tieneOrdenServicio}>
              <If.Then>
                <If condition={detalles_servicios.length > 0}>
                  <If.Then>
                    <If condition={genera_orden_servicio}>
                      <FormOrdenServicio
                        soluciones_solicitadas_id={soluciones_solicitadas_id}
                        tipos_servicios_solicitados_id={tipos_servicios_solicitados_id}
                        tipos_mano_obra_id={tipos_mano_obra_id}
                        horas={horas}
                        tipoOrdenServicioId={tipoOrdenServicioId}
                        tipos_cobro_equipo_id={tipos_cobro_equipo_id}
                        descripcion={descripcion}
                        clienteRegistro={clienteRegistro}
                        cotizacion={cotizacion}
                        numero_orden_servicio={numero_orden_servicio}
                        onChange={onChange}
                        onSelect={onSelect}
                        onChangeCliente={onChangeCliente}
                        onSelectTipoOrdenServicio={onSelectTipoOrdenServicio}
                        referencia={referencia}
                      />
                    </If>
                  </If.Then>
                  <If.Else>
                    <Card.Body>
                      <Row>
                        <Col>
                          <h4>No tiene horas de servicio registradas para generar una orden de servicio.</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button variant='primary' onClick={onOpenEditarCotizacion}>
                            Ir a editar la cotización
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </If.Else>
                </If>
              </If.Then>
              <If.Else>
                <Card.Body>
                  <Row>
                    <Col>
                      <Button variant='success' onClick={onOpenOrdenServicioId}>
                        Ir a orden de servicio
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </If.Else>
            </If>
          </Card.Body>
        </Card>
        <br />
        <If condition={!tienePedido}>
          <If.Then>
            <If condition={detalles_equipos.length > 0}>
              <FormPedido
                tipoPedidoId={tipoPedidoId}
                numero_pedido={numero_pedido}
                almacen={almacen}
                isLoadingTable={isLoadingTable}
                detalles_equipos={detalles_equipos}
                onSelectTipoPedido={onSelectTipoPedido}
                onChange={onChange}
                onSelect={onSelect}
              />
            </If>
          </If.Then>
          <If.Else>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <h3>Pedido</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant='primary' onClick={onOpenPedidoId}>
                      Ir a pedido
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </If.Else>
        </If>
        <br />
        <If condition={(!tienePedido && detalles_equipos.length > 0) || (!tieneOrdenServicio && detalles_servicios.length > 0)}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Saving saving={isLoading} />
                      <Button variant='primary pull-right' onClick={handleCompletarPedidoServicio} disabled={isLoading}>
                        Generar documentos
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </If>
        <br />
      </Loading>
      <ModalOpcionesDocumentos show={showModalDownload} onClose={closeDownload} pedidoId={pedido?._id} ordenServicioId={orden_servicio?._id} />
    </Module>
  );
};

ConvertirCotizacion.propTypes = {};

export default ConvertirCotizacion;
