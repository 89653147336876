import { AutoComplete, DivFormGroup, Input } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load, departamentos, puestos, tiposnominas, grupos_empleados }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    numero_empleado: '',
    nombre_completo: '',
    rfc: '',
    curp: '',
    departamentoId: null,
    grupoEmpleadoId: null,
    puestoId: null,
    tipoNominaId: null,
    imss: '',
    isLoading: false,
    fecha_ingreso: null,
    fecha_nacimiento: null,
  });

  const close = () => {
    setState({
      showModal: false,
      numero_empleado: '',
      nombre_completo: '',
      rfc: '',
      curp: '',
      grupoEmpleadoId: null,
      departamentoId: null,
      puestoId: null,
      tipoNominaId: null,
      imss: '',
      fecha_ingreso: null,
      fecha_nacimiento: null,
    });
  };

  const open = () => {
    setState({ ...state, showModal: true, isLoading: false });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const onChangeAutoComplete = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numero_empleado,
      nombre_completo,
      rfc,
      curp,
      departamentoId,
      grupoEmpleadoId,
      puestoId,
      fecha_nacimiento,
      fecha_ingreso,
      tipoNominaId,
      imss,
    } = state;

    const data = {
      numero_empleado: numero_empleado.trim().toUpperCase(),
      nombre_completo: nombre_completo.trim().toUpperCase(),
      grupoEmpleadoId,
      rfc: rfc.trim().toUpperCase(),
      curp: curp.trim().toUpperCase(),
      departamentoId,
      puestoId,
      fecha_nacimiento,
      fecha_ingreso,
      tipoNominaId,
      imss,
    };

    if (validations(data)) {
      const { data: responseData } = await postRequest({ url: `empleados`, body: data });
      if (responseData) {
        load();
        close();
      }
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.numero_empleado + '')) {
      validate.success = false;
      validate.message = 'Número de empleado es requerido.';
    } else if (isEmpty(params.nombre_completo + '')) {
      validate.success = false;
      validate.message = 'Nombre completo es requerido.';
    } else if (isEmpty(params.rfc + '')) {
      validate.success = false;
      validate.message = 'RFC es requerido.';
    } else if (isEmpty(params.curp + '')) {
      validate.success = false;
      validate.message = 'CURP es requerida.';
    } else if (!isMongoId(params.departamentoId + '')) {
      validate.success = false;
      validate.message = 'Departamento es requerido.';
    } else if (!isMongoId(params.grupoEmpleadoId + '')) {
      validate.success = false;
      validate.message = 'Grupo de empleado es requerido.';
    } else if (!isMongoId(params.puestoId + '')) {
      validate.success = false;
      validate.message = 'Puesto es requerido.';
    } else if (!params.fecha_ingreso) {
      validate.success = false;
      validate.message = 'Fecha de ingreso es requerida.';
    } else if (moment(params.fecha_ingreso).diff(moment(), 'days') > 0) {
      validate.success = false;
      validate.message = 'Fecha de ingreso no debe ser mayor a la fecha actual.';
    } else if (!isMongoId(params.tipoNominaId + '')) {
      validate.success = false;
      validate.message = 'Tipo de nomina es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const {
    showModal,
    nombre_completo,
    numero_empleado,
    departamentoId,
    grupoEmpleadoId,
    puestoId,
    rfc,
    curp,
    fecha_nacimiento,
    fecha_ingreso,
    tipoNominaId,
    imss,
    isLoading,
  } = state;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleRegister}>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='numero_empleado' title='Número de empleado' required>
                  <Input name='numero_empleado' placeholder='Número de empleado' onChange={onChange} value={numero_empleado} />
                </DivFormGroup>
                <DivFormGroup name='nombre_completo' title='Nombre completo' required>
                  <Input name='nombre_completo' placeholder='Nombre completo' onChange={onChange} value={nombre_completo} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='rfc' title='RFC' required>
                  <Input name='rfc' placeholder='RFC' onChange={onChange} value={rfc} />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='curp' title='CURP' required>
                  <Input name='curp' placeholder='CURP' onChange={onChange} value={curp} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='grupoEmpleadoId' title='Grupo de empleados' required>
                  <AutoComplete
                    id='grupoEmpleadoId'
                    name='grupoEmpleadoId'
                    defaultValue={grupoEmpleadoId}
                    data={grupos_empleados}
                    callback={(value) => onChangeAutoComplete('grupoEmpleadoId', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='departamentoId' title='Departamento' required>
                  <AutoComplete
                    id='departamentoId'
                    name='departamentoId'
                    defaultValue={departamentoId}
                    data={departamentos}
                    callback={(value) => onChangeAutoComplete('departamentoId', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='puestoId' title='Puesto' required>
                  <AutoComplete
                    id='puestoId'
                    name='puestoId'
                    defaultValue={puestoId}
                    data={puestos}
                    callback={(value) => onChangeAutoComplete('puestoId', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='fecha_nacimiento' title='Fecha nacimiento'>
                  <Input name='fecha_nacimiento' type='date' placeholder='Fecha nacimiento' onChange={onChange} value={fecha_nacimiento} />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='fecha_ingreso' title='Fecha ingreso' required>
                  <Input name='fecha_ingreso' type='date' placeholder='Fecha ingreso' onChange={onChange} value={fecha_ingreso} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='tipoNominaId' title='Tipo de nomina' required>
                  <AutoComplete
                    id='tipoNominaId'
                    name='tipoNominaId'
                    defaultValue={tipoNominaId}
                    data={tiposnominas}
                    callback={(value) => onChangeAutoComplete('tipoNominaId', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='imss' title='IMSS'>
                  <Input name='imss' placeholder='IMSS' onChange={onChange} value={imss} />
                </DivFormGroup>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleRegister} variant='primary' className='pull-right' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,
  departamentos: PropTypes.array.isRequired,
  grupos_empleados: PropTypes.array.isRequired,
  puestos: PropTypes.array.isRequired,
  tiposnominas: PropTypes.array.isRequired,
};

export default New;
