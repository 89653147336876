import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Loading } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import CbTableTodosTotales from '@cbcomponents/CbTableTodosTotales';
import Required from '@controls/Required';
import RowDetalle from './RowDetalle';
import { Row, Col } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectTipoMonitoreo from '@components/forms/SelectTipoMonitoreo';
import SelectCliente from '@components/forms/SelectCliente';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectDealer from '@components/forms/SelectDealer';
import SelectListaPrecios from '@components/forms/SelectListaPrecios';
import SelectMedioComunicacion from '@components/forms/SelectMedioComunicacion';

const INIT_DETALLE = { servicioId: null, measureId: null, cantidad: 1, name: '', listaPreciosId: null, obj: null };

const Edit = ({ itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    isLoading: false,
    numero: '',
    nombre: '',
    contrato: '',
    cliente_id: null,
    domicilio_monitoreo_id: null,
    dealer_id: null,
    tipo_monitoreo_id: null,
    lista_precios_id: null,
    medio_comunicacion_id: null,
    datos_domicilio_texto: '',
    domicilios: [],
    detalles: [{ ...INIT_DETALLE }],
    listas_precios: [],
    dealers: [],
    medios_comunicaciones: [],
    tipos_monitoreos: [],
    unidades: [],
    evento: {},
    datos_domicilio: {},
    observaciones: '',
    loadingDetails: false,
  });

  useEffect(() => {
    ActualizarDetalles();
  }, [state.tipo_monitoreo_id, state.lista_precios_id]);

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      detalles: [{ ...INIT_DETALLE }],
      nombre: '',
      numero: '',
    }));
  };

  const open = async () => {
    const { data } = await getRequest({ url: `cuentas-monitoreos/${itemId}` });
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      numero: data.numero,
      nombre: data.nombre,
      observaciones: data.observaciones,
      contrato: data.contrato,
      cliente_id: data.cliente_id,
      domicilio_monitoreo_id: data.domicilio_monitoreo_id,
      dealer_id: data.dealer_id,
      tipo_monitoreo_id: data.tipo_monitoreo_id,
      medio_comunicacion_id: data.medio_comunicacion_id,
      lista_precios_id: data.lista_precios_id,
      domicilios: data.cliente_id ? data.cliente_id.listDomicilios : [],
      datos_domicilio: data.datos_domicilio,
      evento: data.evento,
      estatus: data.estatus,
      agenda_monitoreo_id: data.agenda_monitoreo_id,
      sistema_servicio: data.sistema_servicio,
      datos_domicilio_texto: `${data.datos_domicilio.calle || ''} ${data.datos_domicilio.numero_ext || ''}, ${data.datos_domicilio.ciudad || ''}, ${
        data.datos_domicilio.cp || ''
      } ${data.datos_domicilio.referencia || ''}`,
      detalles: data.detalles
        ? data.detalles.map((detalle) => ({
            _id: detalle._id,
            servicio: detalle.servicioId,
            descripcion: detalle.descripcion,
            cantidad: detalle.cantidad,
            precio: detalle.precio,
            iva: detalle.servicioId.iva || 16,
            listasPrecios: detalle.listasPrecios,
            descuento: detalle.descuento,
            unidad: detalle.measureId,
          }))
        : [{ ...INIT_DETALLE }],
    }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSelectTipoMonitoreo = async (value) => {
    setState((prevState) => ({
      ...prevState,
      tipo_monitoreo_id: value,
    }));
  };

  const ActualizarDetalles = async () => {
    if (!state.tipo_monitoreo_id || !state.lista_precios_id) return;
    setState((prevState) => ({ ...prevState, loadingDetails: true }));
    const { data } = await getRequest({ url: `tipos-monitoreos/${state.tipo_monitoreo_id._id}` });
    setState((prevState) => ({
      ...prevState,
      loadingDetails: false,
      detalles:
        data?.detalles?.map((detalle) => ({
          servicio: detalle.servicioId,
          descripcion: detalle.descripcion,
          cantidad: detalle.cantidad,
          unidad: detalle.measureId,
          precio: (detalle.listasPrecios || []).find((y) => y?.listaPrecioId?._id === prevState.lista_precios_id?._id)?.precio || 0,
          descuento: (detalle.listasPrecios || []).find((y) => y?.listaPrecioId?._id === prevState.lista_precios_id?._id)?.descuento || 0,
          iva: detalle.servicioId.iva || 16,
        })) || [],
    }));
  };

  const onSelectListaPrecios = (value) => {
    setState((prevState) => ({
      ...prevState,
      lista_precios_id: value,
    }));
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      numero,
      nombre,
      tipo_monitoreo_id,
      contrato,
      cliente_id,
      domicilio_monitoreo_id,
      dealer_id,
      lista_precios_id,
      medio_comunicacion_id,
      detalles,
      observaciones,
    } = state;

    const data = {
      numero: numero.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      tipo_monitoreo_id: tipo_monitoreo_id?._id,
      contrato: contrato.trim().toUpperCase(),
      cliente_id: cliente_id?._id,
      domicilio_monitoreo_id: domicilio_monitoreo_id?._id,
      dealer_id: dealer_id?._id,
      lista_precios_id: lista_precios_id?._id,
      medio_comunicacion_id: medio_comunicacion_id?._id,
      observaciones: observaciones.trim(),
      detalles: detalles.map((x) => ({
        _id: x?._id,
        servicioId: x.servicio?._id,
        measureId: x.unidad?._id,
        descripcion: x.descripcion || '',
        precio: cleanNumber(x.precio),
        descuento: cleanNumber(x.descuento),
        cantidad: cleanNumber(x.cantidad),
      })),
    };

    if (validations(data)) {
      await putRequest({ url: `cuentas-monitoreos/${itemId}`, body: data });
      setState((prevState) => ({ ...prevState, showModal: false }));
      loadData();
    }
  };

  const addItemServicio = () => {
    setState((prevState) => ({ ...prevState, detalles: [...prevState.detalles, { servicioId: null, cantidad: 1, name: '', obj: null }] }));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.numero)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }
    if (params.tipo_monitoreo_id && params.lista_precios_id) {
      for (let i = 0; i < params.detalles.length; i++) {
        const servicio = params.detalles[i];
        if (!isMongoId(servicio.servicioId + '')) {
          validate.success = false;
          validate.message = 'Servicio en servicios es requerido.';
          break;
        } else if (servicio.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad en servicios, debe ser mayor a cero.';
          break;
        } else if (!isMongoId(servicio.measureId + '')) {
          validate.success = false;
          validate.message = 'Unidad en servicios es requerida.';
          break;
        }
      }
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.listDomicilios;
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      setState((prevState) => ({
        ...prevState,
        cliente_id: itemSelect,
        domicilio_monitoreo_id: domicilioId,
        domicilios: domicilios,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        cliente_id: null,
        domicilio_monitoreo_id: null,
        domicilios: [],
      }));
    }
  };

  const onChangeServicioDetalle = (index, servicio) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...prevState.detalles.map((x, i) => {
          if (i === index) {
            return servicio;
          }
          return x;
        }),
      ],
    }));
  };

  const onRemoveItemServicio = (index) => {
    setState((prevState) => ({ ...prevState, detalles: [...prevState.detalles.filter((x, i) => i !== index)] }));
  };

  const {
    showModal,
    isLoading,
    numero,
    nombre,
    contrato,
    cliente_id,
    domicilio_monitoreo_id,
    dealer_id,
    tipo_monitoreo_id,
    lista_precios_id,
    medio_comunicacion_id,
    domicilios,
    detalles,
    observaciones,
  } = state;

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' size='sm' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading} size='xl'>
        <Row>
          <Col md={2}>
            <FormInput title='Numero' name='numero' required onChange={onChange} value={numero} />
          </Col>
          <Col md={4}>
            <FormInput title='Nombre' name='nombre' required onChange={onChange} value={nombre} />
          </Col>
          <Col md={3}>
            <DivFormGroup name='tipo_monitoreo_id' required title='Tipo monitoreo'>
              <SelectTipoMonitoreo onChange={(value) => onSelectTipoMonitoreo(value)} value={tipo_monitoreo_id} />
            </DivFormGroup>
          </Col>
          <Col md={3}>
            <FormInput title='Contrato' name='contrato' required onChange={onChange} value={contrato} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <DivFormGroup name='clienteName' required title='Cliente'>
              <SelectCliente value={cliente_id} onChange={onChangeCliente} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SelectCreateOrUpdateDomicilio
              name='domicilio_monitoreo_id'
              customerId={cliente_id?._id}
              value={domicilio_monitoreo_id}
              domicilios={domicilios}
              setDomicilios={(domicilios) => setState({ ...state, domicilios })}
              onChange={(value) => onSelect('domicilio_monitoreo_id', { ...value})}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <DivFormGroup name='medio_comunicacion_id' required title='Medio comunicación'>
              <SelectMedioComunicacion value={medio_comunicacion_id} onChange={(value) => onSelect('medio_comunicacion_id', value)} />
            </DivFormGroup>
          </Col>
          <Col xs={4}>
            <DivFormGroup name='dealer_id' required title='Dealer'>
              <SelectDealer name='dealer_id' onChange={(value) => onSelect('dealer_id', value)} value={dealer_id} />
            </DivFormGroup>
          </Col>
          <Col xs={4}>
            <DivFormGroup name='lista_precios_id' required title='Lista de precios'>
              <SelectListaPrecios name='lista_precios_id' value={lista_precios_id} onChange={onSelectListaPrecios} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup>
              <FormInput name='datos_domicilio_texto' title='Domicilio Bykom' value={state.datos_domicilio_texto} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} type='textarea' row='3' />
          </Col>
        </Row>
        <br />
        {tipo_monitoreo_id && lista_precios_id && (
          <Row>
            <Col>
              <Loading loading={state.loadingDetails}>
                <CbPanelTableResponsive
                  title={
                    <>
                      Servicios <Required />
                    </>
                  }
                >
                  <thead>
                    <tr>
                      <th width='15%'>Código</th>
                      <th>Servicio</th>
                      <th width='15%'>Medida</th>
                      <th width='5%'>Cant.</th>
                      <th width='10%'>Precio</th>
                      <th width='5%'>Desc.</th>
                      <th width='10%'>&nbsp;</th>
                    </tr>
                  </thead>
                  <CbTableBody colSpan={7}>
                    {detalles.map((servicio, i) => (
                      <RowDetalle
                        key={i}
                        servicio={servicio}
                        onChangeServicioDetalle={(servicio) => onChangeServicioDetalle(i, servicio)}
                        onRemoveItemServicio={() => onRemoveItemServicio(i)}
                      />
                    ))}
                    <CbTableTodosTotales colSpan={7} detalles={detalles} />
                    <CbTableFooterBotton colSpan={7} onClick={addItemServicio} title='Agregar servicio' />
                  </CbTableBody>
                </CbPanelTableResponsive>
              </Loading>
            </Col>
          </Row>
        )}
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  unidades: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,

  detalles: PropTypes.array,
  listas_precios: PropTypes.array,
};

export default Edit;
