import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { urlPhoto } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';

const Evidencias = ({ evidencias: initialEvidencias }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [evidencias, setEvidencias] = useState([]);
  const [imgSrcModal, setImgSrcModal] = useState('');
  const [showModalImg, setShowModalImg] = useState(false);
  const [isPdf, setIsPdf] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setEvidencias(initialEvidencias ? initialEvidencias.map((x) => ({ imagePreviewUrl: urlPhoto() + x, actual: true })) : []);
  };

  const handleDescargarEvidencia = (event) => {
    event.preventDefault();
    if (evidencias.length > 0) {
      for (let i = 0; i < evidencias.length; i++) {
        const evidencia = evidencias[i];
        const link = document.createElement('a');
        link.href = evidencia.imagePreviewUrl;
        link.download = 'evidencia';
        link.click();
      }
    }
  };

  const onChangeEvidencias = (evidencias) => {
    setEvidencias([...evidencias]);
  };

  const closeModalImagen = () => {
    setShowModalImg(false);
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='picture' title='Evidencias' type='primary' />
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar evidencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ImageUploading multiple onChange={onChangeEvidencias} value={evidencias} dataURLKey='imagePreviewUrl'>
                    {({ imageList }) => (
                      <div className='upload__image-wrapper row'>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item col-sm-4' style={{ position: 'relative' }}>
                            <img
                              src={image.imagePreviewUrl}
                              alt=''
                              width='100%'
                              className='img-responsive'
                              onClick={() => {
                                setShowModalImg(true);
                                setImgSrcModal(image.imagePreviewUrl);
                                setIsPdf(false);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDescargarEvidencia} variant='warning' className='pull-right mr-5' disabled={isLoading}>
            Descargar
          </Button>
          <Button onClick={close} disabled={isLoading} className='pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size='large' show={showModalImg} onHide={closeModalImagen}>
        <Modal.Header closeButton>
          <Modal.Title>Evidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPdf ? <iframe width='100%' height='700px' src={imgSrcModal}></iframe> : <img className='img-responsive' src={imgSrcModal} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModalImagen} className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Evidencias.propTypes = {
  id: PropTypes.string.isRequired,
  ordenServicioId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  evidencias: PropTypes.array.isRequired,
};

export default Evidencias;
