import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteCondicionesPagoEquipos from './DeleteCondicionesPagoEquipos';
import EditCondicionesPagoEquipos from './EditCondicionesPagoEquipos';

const ListCondicionesPagoEquipos = ({ catalogCondicionesPagoEquipos, ...props }) => {
  const renderRows = () => {
    return catalogCondicionesPagoEquipos.map(({ _id, condicion }, item) => (
      <tr key={item}>
        <td>{condicion}</td>
        <td>
          <IfPermission action='eliminar'>
            <DeleteCondicionesPagoEquipos
              id={item}
              condicionespagoequiposId={_id}
              name={condicion}
              {...props}
            />
          </IfPermission>
          <IfPermission action='editar'>
            <EditCondicionesPagoEquipos id={item} condicionespagoequiposId={_id} {...props} />
          </IfPermission>
        </td>
      </tr>
    ));
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Condición</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListCondicionesPagoEquipos.propTypes = {
  catalogCondicionesPagoEquipos: PropTypes.array.isRequired,
};

export default ListCondicionesPagoEquipos;
