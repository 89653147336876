import { DivFormGroup, FormInput } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectCliente from '@components/forms/SelectCliente';
import MultiSelectCuentaMonitoreoCliente from '@components/forms/MultiSelectCuentaMonitoreoCliente';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';
import SelectFormaPago from '@components/forms/SelectFormaPago';
import SelectMetodoPago from '@components/forms/SelectMetodoPago';

const New = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [state, setState] = useState({
    progresss: false,
    isLoading: false,
    loading: false,
    customer: [],
    siguiente_emision: moment().format('YYYY-MM-01'),
    medio_comunicacion: '',
    cliente: null,
    clienteId: null,
    clienteName: '',
    fecha: moment().format('YYYY-MM-DD'),
    domicilios: [],
    domicilio_pago_id: '',
    nombre_agenda: '',
    periodos_pago: [
      { label: 'Mensual', value: 'MENSUAL' },
      { label: 'Trimestral', value: 'TRIMESTRAL' },
      { label: 'Semestral', value: 'SEMESTRAL' },
      { label: 'Anual', value: 'ANUAL' },
    ],
    periodicidad: { label: 'Mensual', value: 'MENSUAL' },
    tipos_pago: [
      { label: 'Domicilio', value: 'Domicilio' },
      { label: 'Cobrador', value: 'Cobrador' },
      { label: 'Transferencia', value: 'Transferencia' },
      { label: 'Efectivo', value: 'Efectivo' },
      { label: 'Cheque', value: 'Cheque' },
      { label: 'NA', value: 'NA' },
    ],
    tipo_pago_value: 'NA',
    sistemas_servicio: [
      { label: 'Esquema', value: 'Esquema' },
      { label: 'Póliza', value: 'Póliza' },
      { label: 'Garantía', value: 'Garantía' },
      { label: 'Normal', value: 'Normal' },
      { label: 'Cortesía', value: 'Cortesía' },
    ],
    sistema_servicio_value: { label: 'Normal', value: 'Normal' },
    estatus: [
      { label: 'En servicio', value: 'EN_SERVICIO' },
      { label: 'Baja', value: 'BAJA' },
      { label: 'Activación en proceso', value: 'ACTIVACION_EN_PROCESO' },
      { label: 'Baja en proceso', value: 'BAJA_EN_PROCESO' },
      { label: 'Suspendido', value: 'SUSPENDIDO' },
      { label: 'Cuenta duplicada', value: 'CUENTA_DUPLICADA' },
      { label: 'Baja sin desconexión', value: 'BAJA_SIN_DESCONEXIÓN' },
      { label: 'Cambio de cuenta', value: 'CAMBIO_DE_CUENTA' },
      { label: 'Datos pendientes', value: 'DATOS_PENDIENTES' },
    ],
    estatus_value: { label: 'En servicio', value: 'EN_SERVICIO' },
    periodo_garantia: null,
    tipos_monitoreos: [],
    tipo_monitoreo_default_id: null,
    sat_usos_cfdis: [],
    medios_comunicaciones: [],
    sat_uso_cfdi_id: null,
    dealers: [],
    dealer_id: null,
    sucursales: [],
    sucursal_id: null,
    periodo_garantia_status: true,
    listDomiciliosMonitoreo: [],
    showModal: false,
    contactos_pago_id: [],
    contactos: [],
  });

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    setState((prevState) => ({ ...prevState, loading: true, progresss: true }));
    const {
      data: { sat_usos_cfdis, sat_formas_pagos, sat_metodos_pagos, sucursales },
    } = await getRequest({ url: `agenda_monitoreo_catalogos` });
    const sat_uso_cfdi_id = sat_usos_cfdis.find((x) => x.codigo === 'S01');
    const sat_forma_pago_id = sat_formas_pagos.find((x) => x.codigo === '99');
    const sat_metodo_pago_id = sat_metodos_pagos.find((x) => x.codigo === 'PUE');
    setState((prevState) => ({
      ...prevState,
      sat_uso_cfdi_id,
      sat_metodo_pago_id,
      sat_forma_pago_id,
      sat_formas_pagos,
      sat_metodos_pagos,
      sat_usos_cfdis,
      sucursales: sucursales,
      loading: false,
      progresss: false,
    }));
  };

  const onSelectPeriodicidad = (value) => {
    setState((prevState) => ({
      ...prevState,
      periodicidad: value,
    }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSelectCuentas = (value) => {
    let { nombre_agenda } = state;
    nombre_agenda = value
      .map((x) => x.numero)
      .join(' ')
      .concat(' ')
      .concat(value.map((x) => x.nombre).join(', '));
    setState((prevState) => ({
      ...prevState,
      cuentas: value,
      nombre_agenda,
    }));
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const onChangeRequiereFactura = (event) => {
    const { checked } = event.target;
    let { sat_uso_cfdi_id, sat_forma_pago_id, sat_metodo_pago_id, sat_formas_pagos, sat_usos_cfdis, sat_metodos_pagos } = state;
    if (checked) {
      sat_uso_cfdi_id = sat_usos_cfdis.find((x) => x.codigo === 'G03');
      sat_forma_pago_id = sat_formas_pagos.find((x) => x.codigo === '99');
      sat_metodo_pago_id = sat_metodos_pagos.find((x) => x.codigo === 'PPD');
    } else {
      sat_uso_cfdi_id = sat_usos_cfdis.find((x) => x.codigo === 'S01');
      sat_forma_pago_id = sat_formas_pagos.find((x) => x.codigo === '99');
      sat_metodo_pago_id = sat_metodos_pagos.find((x) => x.codigo === 'PUE');
    }
    setState((prevState) => ({
      ...prevState,
      requiere_factura: checked,
      sat_uso_cfdi_id,
      sat_forma_pago_id,
      sat_metodo_pago_id,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      nombre_agenda,
      cliente,
      siguiente_emision,
      periodicidad,
      sat_uso_cfdi_id,
      sat_forma_pago_id,
      sat_metodo_pago_id,
      sucursal_id,
      estatus_value,
      fecha,
      cuentas,
      requiere_factura,
    } = state;
    const data = {
      nombre_agenda,
      cliente_id: cliente?._id,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      siguiente_emision: moment(siguiente_emision, 'YYYY-MM-DD').utc(),
      periodicidad: periodicidad?.value,
      sat_uso_cfdi_id: sat_uso_cfdi_id?._id,
      sat_forma_pago_id: sat_forma_pago_id?._id,
      sat_metodo_pago_id: sat_metodo_pago_id?._id,
      sucursal_id: sucursal_id?._id,
      estatus: estatus_value?.value,
      cuentas: cuentas.map((x) => x._id),
      requiere_factura,
    };
    if (validations(data)) {
      await postRequest({ url: `agendas-monitoreos`, body: data });
      setTimeout(() => navigate(-1), 1000);
    }
  };

  const onChangeCliente = async (itemSelect) => {
    if (itemSelect) {
      const { data } = await getRequest({ url: `cuentas-monitoreos/customer/${itemSelect._id}` });
      setState((prevState) => ({
        ...prevState,
        cliente: itemSelect,
        cliente_cuentas: data,
        cuentas: [],
        detalles: [],
        isLoading: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        cliente: null,
        cliente_cuentas: [],
        cuentas: [],
        detalles: [],
      }));
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre_agenda + '')) {
      validate.success = false;
      validate.message = 'Nombre agenda es requerido.';
    } else if (!isMongoId(params.cliente_id + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    } else if (isEmpty(params.estatus + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else if (!isMongoId(params.sucursal_id + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerida.';
    } else if (!isMongoId(params.sat_uso_cfdi_id + '')) {
      validate.success = false;
      validate.message = 'Uso de CFDI es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const {
    progresss,
    nombre_agenda,
    cuentas,
    cliente_cuentas,
    siguiente_emision,
    fecha,
    estatus,
    estatus_value,
    periodos_pago,
    periodicidad,
    isLoading,
    cliente,
    sat_uso_cfdi_id,
    sucursal_id,
    sat_forma_pago_id,
    sat_metodo_pago_id,
    requiere_factura,
  } = state;

  return (
    <Module onClickBack={window.history.back} parent='Agenda de monitoreo' title='Agregar monitoreo'>
      <Loading loading={progresss}>
        <form onSubmit={handleRegister}>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='book' /> Información de la agenda
              </div>
            }
          >
            <Row>
              <Col sm='6'>
                <FormInput title='Nombre agenda' name='nombre_agenda' required onChange={onChange} value={nombre_agenda} />
              </Col>
              <Col sm='3'>
                <FormInput type='date' title='Fecha' name='fecha' required onChange={onChange} value={fecha} />
              </Col>
              <Col sm='3'>
                <DivFormGroup name='estatus_value' required title='Estatus'>
                  <Select
                    value={estatus_value}
                    name='estatus_value'
                    options={estatus}
                    placeholder='Seleccione..'
                    onChange={(value) => onSelect('estatus_value', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='sucursal_id' required title='Sucursal'>
                  <SelectSucursal value={sucursal_id} onChange={(value) => onSelect('sucursal_id', value)} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='periodicidad' required title='Periodicidad'>
                  <Select
                    value={periodicidad}
                    name='periodicidad'
                    options={periodos_pago}
                    placeholder='Seleccione..'
                    onChange={onSelectPeriodicidad}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <FormInput type='date' title='Siguiente Emisión' name='siguiente_emision' required onChange={onChange} value={siguiente_emision} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='clienteName' required title='Cliente'>
                  <SelectCliente value={cliente} onChange={onChangeCliente} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='cuentas' required title='Cuentas de monitoreo'>
                  <MultiSelectCuentaMonitoreoCliente value={cuentas} onChange={onSelectCuentas} options={cliente_cuentas} isDisabled={!cliente} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <div className='checkbox'>
                  <label className='control-label'>
                    <input type='checkbox' name='requiere_factura' checked={requiere_factura} onChange={onChangeRequiereFactura} />
                    &nbsp;
                    <strong>Requiere Factura</strong>
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='sat_usos_cfdis' required title='Uso de CFDI'>
                  <SelectUsoCFDI value={sat_uso_cfdi_id} onChange={(value) => onSelect('sat_uso_cfdi_id', value)} isDisabled={!requiere_factura} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='sat_forma_pago_id' required title='Forma pago'>
                  <SelectFormaPago
                    value={sat_forma_pago_id}
                    onChange={(value) => onSelect('sat_forma_pago_id', value)}
                    isDisabled={!requiere_factura}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='sat_metodo_pago_id' required title='Metodo pago'>
                  <SelectMetodoPago
                    value={sat_metodo_pago_id}
                    onChange={(value) => onSelect('sat_metodo_pago_id', value)}
                    isDisabled={!requiere_factura}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </Panel>
          <div className='modal-footer panel' style={{ padding: '10px 15px' }}>
            <Saving saving={isLoading} />
            <Button type='submit' variant='primary' className='pull-right' disabled={isLoading}>
              Guardar
            </Button>
          </div>
        </form>
      </Loading>
    </Module>
  );
};

New.propTypes = {};

export default New;
