import { DivFormGroup } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, FormGroup, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';
import { FileUpIcon } from 'lucide-react';

const ImportZip = () => {
  const [tipo, setTipo] = useState('X');
  const [isLoading, setIsLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [csv, setCsv] = useState(null);
  const [valid, setValid] = useState(false);
  const [msg, setMsg] = useState('');
  const [progress, setProgress] = useState(0);
  const frm = useRef(null);

  useEffect(() => {}, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'tipo') {
      setTipo(value);
      setCsv(null);
      setValid(false);
      setMsg('');
      setProgress(0);
    }
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();

    setCargando(true);
    setProgress(0);
    setCsv(null);
    setIsLoading(true);

    const form = new FormData(frm.current);
    for (let i = 1; i <= 100; i++) {
      setTimeout(() => {
        setProgress(i);
      }, 100);
    }

    let tipoUrl = '';
    switch (tipo) {
      case 'Z':
        tipoUrl = 'importar/zip';
        break;
      case 'X':
        tipoUrl = 'importar/xml';
        break;
    }

    await postUploadRequest({ url: `facturas/${tipoUrl}`, form });
    setCargando(false);
    setProgress(0);
    setIsLoading(false);
  };

  const renderDataCSV = () => {
    const { detalle, headers } = csv;

    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12' style={{ position: 'relative', overflowX: 'auto', width: '100%' }}>
          <table className='table table-striped align-middle'>
            <thead>
              <tr>
                <th>
                  <strong>Número</strong>
                </th>
                <th>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => (
                  <th key={i}>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detalle.length > 0 ? (
                detalle.map((d, i) => {
                  let datoCreacion = 3;
                  return (
                    <React.Fragment key={i}>
                      <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                        <td>{i + 1}</td>
                        <td>
                          {d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}
                        </td>
                        {headers.map((e, j) => (
                          <td key={j}>
                            {d[j]}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <RowsNotFound colSpan={8} />
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  return (
    <Module onClickBack={window.history.back} parent='Facturas' title='Importar facturas'>
      <br />
      <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
        <Row>
          <Col sm='12'>
            <FormGroup className='inline'>
              <label>Tipo archivo</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='tipo' id='rdo-zip' onChange={onChange} defaultChecked={true} value={'Z'} checked={tipo === 'Z'} /> Zip
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='tipo' id='rdo-xml' onChange={onChange} value={'X'} checked={tipo === 'X'} /> Xml
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <DivFormGroup>
              <div className='btn btn-default btn-file'>
                <Icon icon='paperclip' /> Archivo
                <input type='file' name='archivo' id='archivo' multiple />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='9'>
            <Button type='submit' className='btn btn-success' disabled={cargando}>
              <FileUpIcon /> Importar
            </Button>
            {isLoading ? <Saving action='Importantando' /> : ''}
          </Col>
          <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
        </Row>
      </form>
      <form>{csv !== null ? renderDataCSV() : null}</form>
    </Module>
  );
};

ImportZip.propTypes = {};

export default ImportZip;
