import { Confirmation, FormInput, IfPermission } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Icon, Loading, Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import Edit from './Edit';
import New from './New';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const Visitas = () => {
  const params = useParams();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    fecha_solicitud: moment().format('YYYY-MM-DD'),
    ordenServicioId: null,
    numero_orden_servicio: '',
    agente_solicitante: '',
    visitas: [],
    agentes: [],
    orden_servicio: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { ordenServicioId } = params;
    const { orden_servicio, visitas, agentes } = await getRequest({ url: `ordenes-servicio/listVisitas/${ordenServicioId}` });
    setState({
      ...state,
      ordenServicioId: ordenServicioId,
      visitas: visitas,
      orden_servicio: orden_servicio,
      agente_solicitante: orden_servicio.agente.nombre,
      numero_orden_servicio: orden_servicio.numero_orden_servicio,
      agentes: agentes.map((agente) => {
        return {
          value: agente._id,
          label: agente.nombre,
        };
      }),
    });
  };

  const renderAgentes = (agentes_asignados) => {
    let agente_nombre = [];
    agentes_asignados.map((a, i) => {
      if (i > 0) {
        agente_nombre.push(<br key={'br' + i} />);
      }
      const { nombre } = a;
      agente_nombre.push(
        <CbBadge key={'span' + i} type={`secondary`}>
          {nombre}
        </CbBadge>
      );
    });
    return <Col sm='12'>{agentes_asignados.length > 0 ? agente_nombre : <span>Sin agentes asignados</span>}</Col>;
  };

  const renderRows = () => {
    const { visitas } = state;
    return visitas.map((r, i) => {
      const { _id, ordenServicioId, fecha_hora, horas_servicio, agentes_asignados, observaciones } = r;
      return (
        <tr key={i}>
          <td>{moment(fecha_hora).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>{horas_servicio}</td>
          <td>{renderAgentes(agentes_asignados)}</td>
          <td>{observaciones}</td>
          <td className='with-actions'>
            <span className='actions'>
              <IfPermission action='eliminar-visitas'>
                <Confirmation
                  id={_id}
                  btnIcon='remove'
                  typeConfirm='danger'
                  btnType='danger'
                  action='Eliminar visita'
                  url={`ordenes-servicio/visitas/eliminar/${ordenServicioId}/${_id}`}
                  description={`¿Confirma que desea eliminar la <strong>visita: <u> ${observaciones}</u></strong>?`}
                  loadData={() => loadData(ordenServicioId)}
                />
              </IfPermission>
              <IfPermission action='editar-visitas'>
                <Edit {...state} ordenServicioId={ordenServicioId} visitaId={_id} loadData={loadData} />
              </IfPermission>
            </span>
          </td>
        </tr>
      );
    });
  };

  const renderView = () => {
    const { agente_solicitante, numero_orden_servicio, visitas, ordenServicioId } = state;

    const rows = visitas.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

    return (
      <form>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='book' /> Datos de orden de servicio
            </div>
          }
        >
          <Row>
            <Col sm='6'>
              <FormInput title='Agente solicitante' placeholder='Agente solicitante' disabled name='agente_solicitante' value={agente_solicitante} />
            </Col>
            <Col sm='6'>
              <FormInput
                title='Numero de orden de servicio'
                placeholder='Numero de orden de servicio'
                disabled
                name='numero_orden_servicio'
                value={numero_orden_servicio}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='user' /> Visitas
            </div>
          }
        >
          <Row>
            <Col sm='12'>
              <New {...state} ordenServicioId={ordenServicioId} loadData={loadData} />
              <table className='table table-striped align-middle'>
                <thead>
                  <tr>
                    <th width='15%'>Fecha y hora</th>
                    <th width='10%'>Horas de servicio</th>
                    <th width='30%'>Agentes</th>
                    <th width='40%'>Observaciones</th>
                    <th width='5%'>Acciones</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </Col>
          </Row>
        </Panel>
      </form>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Visitas de orden de servicio'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Visitas.propTypes = {};

export default Visitas;
