import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const TextDate = ({ children, format = 'YYYY/MM/DD' }) => {
  if (!children) return null;
  if(!moment(children).isValid()) return null; 
  return <>{moment(children).local().format(format)}</>;
};

TextDate.propTypes = {
  children: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default TextDate;
