import { ContactoPrincipal, DivFormGroup, TextArea } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { isEmpty, isMongoId } from 'validator';
import RowCotizacion from './RowCotizacion';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const SeguimientoProspectos = () => {
  const { addNotification: notification } = useStoreNotification();

  const params = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    isLoading: false,
    cargando: true,
    fecha: '',
    hora: '',
    motivoId: '',
    resultadoVisitaId: '',
    observaciones: '',
    fechaProximaVisita: '',
    horaProximaVisita: '',
    motivos: [],
    resultados: [],
    seguimientos: [],
    isCompleted: false,
    seguimientoId: null,
    data: null,
    levantamientos: [],
  });

  const loadData = useCallback(async () => {
    const { prospectoId } = params;
    const { data, motivos, resultados, seguimientos, levantamientos } = await getRequest({ url: `seguimientos/prospectos/${prospectoId}` });
    setState((prevState) => ({
      ...prevState,
      data,
      motivos,
      resultados,
      seguimientos,
      levantamientos,
      cargando: false,
    }));
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const selectSeguimiento = (select) => {
    const { _id, fecha, hora, motivoId, resultadoVisitaId, observaciones, fechaProximaVisita, horaProximaVisita } = select;

    let isCompleted = false;

    if (!isEmpty(fecha) && !isEmpty(observaciones) && isMongoId(motivoId + '') && isMongoId(resultadoVisitaId + '')) {
      isCompleted = true;
    }

    setState((prevState) => ({
      ...prevState,
      seguimientoId: _id,
      isCompleted,
      fecha: moment(fecha).format('YYYY-MM-DD'),
      hora,
      motivoId: isMongoId(motivoId + '') ? motivoId : '',
      resultadoVisitaId: isMongoId(resultadoVisitaId + '') ? resultadoVisitaId : '',
      observaciones,
      fechaProximaVisita: fechaProximaVisita === null ? '' : moment(fechaProximaVisita).format('YYYY-MM-DD'),
      horaProximaVisita,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { prospectoId } = params;
    const { seguimientoId, fecha, hora, motivoId, resultadoVisitaId, observaciones, fechaProximaVisita, horaProximaVisita } = state;
    const data = {
      prospectoId,
      seguimientoId,
      fecha,
      hora,
      motivoId,
      resultadoVisitaId,
      observaciones,
      fechaProximaVisita,
      horaProximaVisita,
    };
    if (validations(data)) {
      await postRequest({ url: 'seguimientos/prospectos', body: data });
      reload();
    }
  };

  const reload = () => {
    setTimeout(() => navigate(0), 1000);
  };

  const renderForm = () => {
    const {
      isLoading,
      fecha,
      hora,
      motivoId,
      resultadoVisitaId,
      observaciones,
      fechaProximaVisita,
      horaProximaVisita,
      data,
      motivos,
      resultados,
      levantamientos,
      seguimientos,
      isCompleted,
    } = state;

    const rowsCotizaciones = levantamientos.map((s, i) => <RowCotizacion key={i} cotizacion={s} {...data} />);

    return (
      <>
        <Card>
          <Card.Header>
            <Card.Title>Cliente</Card.Title>
          </Card.Header>
          <Card.Body>
            {data !== null ? (
              <Row>
                <Col sm='4'>
                  <h3 className='lead'>{renderNombreCliente(data)}</h3>
                  <h3 className='lead'>RFC {data.rfc}</h3>
                </Col>
                <Col sm='4'>
                  <h3 className='lead'>
                    Tel
                    <ContactoPrincipal type='phone' value={data.phone} principal={null} clienteId={data._id} callback={loadData} />
                  </h3>
                  <h3 className='lead'>
                    Cel
                    <ContactoPrincipal type='mobile' value={data.mobile} principal={null} clienteId={data._id} callback={loadData} />
                  </h3>
                </Col>
                <Col sm='4'>
                  <h3 className='lead'>
                    Correo
                    <ContactoPrincipal type='email' value={data.email} principal={null} clienteId={data._id} callback={loadData} />
                  </h3>
                </Col>
              </Row>
            ) : null}
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title>Cotizaciones</Card.Title>
          </Card.Header>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th width='5%'>Número</th>
                <th width='10%'>Sucursal</th>
                <th width='15%'>Fecha alta</th>
                <th width='15%'>Fecha vencimiento</th>
                <th width='10%'>Importe</th>
                <th width='10%'>Estatus</th>
                <th width='10%'></th>
              </tr>
            </thead>
            <tbody>{levantamientos.length > 0 ? rowsCotizaciones : <RowsNotFound colSpan={6} />}</tbody>
          </table>
        </Card>
        <form onSubmit={handleRegister}>
          <Row>
            <Col sm='3'>
              <Card>
                <Card.Header>
                  <Card.Title>Visitas</Card.Title>
                </Card.Header>
                <Card.Body>
                  <table className='table table-striped table-hover' style={{ marginTop: '-21px' }}>
                    <tbody>
                      {seguimientos.length > 0 ? (
                        seguimientos.map((s, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                Visita #{i + 1} - {moment(s.fecha).local().format('DD/MM/YYYY')} {s.hora}
                              </td>
                              <td>
                                <a href='#' onClick={() => selectSeguimiento(s)} className='btn btn-primary pull-right'>
                                  <Icon icon='share' />
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <RowsNotFound colSpan={2} message='Sin visitas' />
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
            <Col sm='9'>
              <Card>
                <Card.Header>
                  <Card.Title>Seguimiento de Visitas</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm='12'>
                      <Col sm='3'>
                        <DivFormGroup name='fecha' title='Fecha de visita'>
                          <Form.Control
                            name='fecha'
                            id='fecha'
                            type='date'
                            defaultValue={fecha}
                            onChange={onChange}
                            disabled={isCompleted}
                          />
                        </DivFormGroup>
                      </Col>
                      <Col sm='2'>
                        <DivFormGroup name='hora' title='Hora' />
                        <Form.Control
                          name='hora'
                          id='hora'
                          type='time'
                          defaultValue={hora}
                          onChange={onChange}
                          disabled={isCompleted}
                        />
                      </Col>
                    </Col>
                    <Col sm='6'>
                      <Col sm='12'>
                        <DivFormGroup name='motivoId' title='Motivo de la visita'>
                          <select name='motivoId' id='motivoId'  onChange={onChange} value={motivoId} disabled={isCompleted}>
                            <option value=''>Seleccione</option>
                            {motivos.map((m, i) => (
                              <option key={i} value={m._id}>
                                {m.nombre}
                              </option>
                            ))}
                          </select>
                        </DivFormGroup>
                      </Col>
                    </Col>
                    <Col sm='6'>
                      <Col sm='12'>
                        <DivFormGroup name='resultadoVisitaId' title='Resultado de la visita'>
                          <select
                            name='resultadoVisitaId'
                            id='resultadoVisitaId'
                            className='form-control'
                            onChange={onChange}
                            value={resultadoVisitaId}
                            disabled={isCompleted}
                          >
                            <option value=''>Seleccione</option>
                            {resultados.map((m, i) => (
                              <option key={i} value={m._id}>
                                {m.nombre}
                              </option>
                            ))}
                          </select>
                        </DivFormGroup>
                      </Col>
                    </Col>
                    <Col sm='12'>
                      <Col sm='12'>
                        <DivFormGroup name='observaciones' title='Observaciones'>
                          <TextArea
                            id='observaciones'
                            name='observaciones'
                            rows={5}
                            onChange={onChange}
                            value={observaciones}
                            disabled={isCompleted}
                          />
                        </DivFormGroup>
                      </Col>
                    </Col>
                    <Col sm='12'>
                      <Col sm='3'>
                        <DivFormGroup name='fechaProximaVisita' title='Próxima visita'>
                          <Form.Control
                            name='fechaProximaVisita'
                            id='fechaProximaVisita'
                            type='date'
                            defaultValue={fechaProximaVisita}
                            onChange={onChange}
                            disabled={isCompleted}
                          />
                        </DivFormGroup>
                      </Col>
                      <Col sm='2'>
                        <DivFormGroup name='horaProximaVisita' title='Hora'>
                          <Form.Control
                            name='horaProximaVisita'
                            id='horaProximaVisita'
                            type='time'
                            defaultValue={horaProximaVisita}
                            onChange={onChange}
                            disabled={isCompleted}
                          />
                        </DivFormGroup>
                      </Col>
                    </Col>
                  </Row>
                  {!isCompleted && data !== null ? (
                    <>
                      <Saving saving={isLoading} />
                      <Button type='submit' className='btn btn-primary pull-right' disabled={isLoading}>
                        Guardar
                      </Button>
                    </>
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </form>
      </>
    );
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha de visita es requerido.';
    } else if (validate.success && isEmpty(params.hora + '')) {
      validate.success = false;
      validate.message = 'Hora de visita es requerida.';
    } else if (validate.success && !isMongoId(params.motivoId + '')) {
      validate.success = false;
      validate.message = 'Motivo de la visita es requerido.';
    } else if (validate.success && !isMongoId(params.resultadoVisitaId + '')) {
      validate.success = false;
      validate.message = 'Resultado de la visita es requerido.';
    } else if (validate.success && isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones es requerido.';
    } else if (validate.success && !isEmpty(params.fechaProximaVisita + '')) {
      if (validate.success && isEmpty(params.horaProximaVisita + '')) {
        validate.success = false;
        validate.message = 'Hora de Próxima visita es requerida.';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { cargando } = state;

  return (
    <Module onClickBack={window.history.back} parent='Seguimientos' title='Seguimiento'>
      {!cargando ? renderForm() : <Loading />}
    </Module>
  );
};

SeguimientoProspectos.propTypes = {};

export default SeguimientoProspectos;
