import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';
import { castEquipo, castServicio, getImporte, getTipoCotizacion, mapParte } from '@utils/cotizaciones';
import ModalBuscadorProducto from './components/ModalBuscadorProducto';
import { ProductOption } from '@controls/Stateless';
import { useStoreMedidas } from '@stores/catalogs.store';
import { TIPOS_PARTIDAS } from '@utils/cotizaciones';
import { Button, Col, Row } from 'react-bootstrap';

const BuscadorDetalles = ({ onLoadingTable, sucursalId, monedaId, tipoCambio, onAgregarDetalle, tipoLevantamientoId }) => {
  const [showModal, setShowModal] = useState(false);
  const getStoreMedidas = useStoreMedidas();

  const agregarKitComercial = async (producto, cantidad) => {
    onLoadingTable(true);
    const { data } = await getRequest({ url: `kits-comerciales/${producto._id}` });
    try {
      const measure = getStoreMedidas.data.find((x) => x.measure.toUpperCase() === 'KIT');
      const detalleKit = {
        relacionadoId: data._id,
        descripcion: data.nombre,
        tipo_partida: { value: 'KIT_COMERCIAL', label: 'KIT_COMERCIAL' },
        noIdentificador: data.codigo,
        measureId: measure,
        precio_original: 0,
        precio: 0,
        moneda_original: monedaId,
        moneda: monedaId,
        cantidad: cantidad || 0,
        descuento: 0,
        iva: producto.iva || 16,
        colapsado: true,
        status: 'ACTIVO',
        partes: [],
      };
      const partes = data.partes.filter((x) => x && x._id).map((parte) => mapParte(parte, sucursalId, monedaId, tipoCambio));
      onAgregarDetalle({
        ...detalleKit,
        precio: getImporte(partes, 4),
        partes,
      });
      onLoadingTable(false);
    } catch (e) {
      console.log(e);
    }
  };

  const measuresServicios = (measures) => {
    return measures.filter((x) => x.tipo === 'SERVICIO');
  };

  const onChangeProducto = (itemSelect) => {
    const cantidad = 1;
    let detalle = {};
    if (itemSelect) {
      const producto = itemSelect;
      switch (producto.tipo_partida) {
        case 'SERVICIO':
          detalle = castServicio(producto, monedaId, tipoCambio, measuresServicios(getStoreMedidas.data), sucursalId);
          break;
        case 'EQUIPO':
          detalle = castEquipo(producto, monedaId, tipoCambio);
          break;
        case 'KIT_COMERCIAL':
          return agregarKitComercial(producto, cantidad, tipoCambio, monedaId);
      }
      onAgregarDetalle({
        ...detalle,
        tipo_partida: TIPOS_PARTIDAS.find((x) => x.value === producto.tipo_partida),
        showModalWorkforce: producto?.listWorkforce?.length > 0,
      });
    }
  };

  const close = () => {
    setShowModal(false);
  };

  const onClickBuscar = () => {
    setShowModal(true);
  };

  const onSelectProducto = (producto) => {
    setShowModal(false);
    onChangeProducto(producto);
  };

  const loadOptionsProducto = async (inputValue, callback) => {
    const [equipos, servicios, kits] = await Promise.all([
      getRequest({ url: `equipments-autocomplete/${getTipoCotizacion(tipoLevantamientoId)}`, params: { query: inputValue } }),
      getRequest({ url: `services-autocomplete`, params: { query: inputValue } }),
      getRequest({ url: `kits-comerciales-autocomplete`, params: { query: inputValue } }),
    ]);
    callback([
      ...equipos.data.map((x) => ({ ...x, tipo_partida: 'EQUIPO' })),
      ...servicios.data.map((x) => ({ ...x, tipo_partida: 'SERVICIO' })),
      ...kits.data.map((x) => ({ ...x, tipo_partida: 'KIT_COMERCIAL' })),
    ]);
  };

  return (
    <Row>
      <Col sm='11'>
        <DivFormGroup name='producto' title='Producto'>
          <AsyncSelect
            value={null}
            loadOptions={loadOptionsProducto}
            onChange={onChangeProducto}
            getOptionLabel={(x) => `${x.code} ${x.name}`}
            getOptionValue={(x) => x._id}
            components={{ Option: ProductOption }}
            isClearable
            placeholder={'Buscar por código o descripción'}
          />
        </DivFormGroup>
      </Col>
      <Col sm='1'>
        <br />
        <Button onClick={onClickBuscar} variant='success' className='pull-right' type='button'>
          <Icon icon='search' /> <span>Buscador</span>
        </Button>
        <ModalBuscadorProducto
          show={showModal}
          onClose={close}
          onSelectProducto={onSelectProducto}
          monedaId={monedaId?.enumerador}
          tipoCambio={tipoCambio}
        />
      </Col>
    </Row>
  );
};

BuscadorDetalles.propTypes = {
  recalcularTipoCambio: PropTypes.func,
  tipoLevantamientoId: PropTypes.string,
  tipo_partida: PropTypes.string,
  monedaId: PropTypes.string,
  sucursalId: PropTypes.string,
  tipoCambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipos_partidas: PropTypes.array,
  detalles: PropTypes.array,
  measures: PropTypes.array,
  setState: PropTypes.func,
  onAgregarDetalle: PropTypes.func,
  onOpenWorkforce: PropTypes.func,
  onLoadingTable: PropTypes.func,
};

export default BuscadorDetalles;
