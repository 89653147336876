import { DivFormGroup, FilterSelectNuevo, FormInput, Label } from '@controls';
import React, { useState } from 'react';
import InputNumber from 'react-text-mask';
import { Icon, Module, Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import Required from '@controls/Required';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const New = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [nombre, setNombre] = useState('');
  const [listEntradas, setListEntradas] = useState([{ equipoId: null, cantidad: 1, name: '', obj: null }]);
  const [listSalidas, setListSalidas] = useState([{ equipoId: null, cantidad: 1, name: '', obj: null }]);

  const handleRegister = async (event) => {
    event.preventDefault();
    const listaEntradas = listEntradas.map((x) => ({ ...x, equipoId: x.equipoId, cantidad: cleanNumber(x.cantidad) }));
    const listaSalidas = listSalidas.map((x) => ({ ...x, equipoId: x.equipoId, cantidad: cleanNumber(x.cantidad) }));

    const data = {
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      listaEntradas,
      listaSalidas,
    };

    if (validations(data)) {
      const { data } = await postRequest({ url: `recetas`, body: data });
      resetForm();
    }
  };

  const addItemEquipoEntrada = () => {
    setListEntradas([...listEntradas, { equipoId: null, cantidad: 1, name: '', obj: null }]);
  };

  const onChangeEquipoEntradaInput = (index, campo, value) => {
    setListEntradas(
      listEntradas.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            [campo]: campo === 'cantidad' ? cleanNumber(value.target.value) : value,
          };
        }
        return x;
      })
    );
  };

  const onSelectEquipoEntrada = (index, campo, itemSelect) => {
    setListEntradas(
      listEntradas.map((x, i) => {
        if (i === index) {
          if (itemSelect) {
            return {
              ...x,
              equipoId: itemSelect,
            };
          } else {
            return {
              ...x,
              name: '',
              obj: null,
              equipoId: null,
            };
          }
        }
        return x;
      })
    );
  };

  const removeItemEquipoEntrada = (item) => {
    setListEntradas(listEntradas.filter((x, i) => i !== item));
  };

  const renderEntradas = () => {
    return (
      <div>
        <div className='list-group'>
          {listEntradas.map((equipo, i) => (
            <div key={`row-equipo-${i}`} id={`row-equipo-${i}`} className='list-group-item'>
              <Row>
                <Col sm='7'>
                  <DivFormGroup>
                    <Label name={`equipo-${i}`} title='Equipo' required />
                    <FilterSelectNuevo
                      async={true}
                      name={`equipo-${i}`}
                      value={equipo.obj}
                      inputValue={equipo.name}
                      onInputChange={(value) => onChangeEquipoEntradaInput(i, 'name', value)}
                      onChangeOption={(itemSelect) => onSelectEquipoEntrada(i, 'obj', itemSelect)}
                      url={`equipments-autocomplete`}
                      fields={['_id', 'code, name']}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='4'>
                  <DivFormGroup>
                    <Label name={`equipo-cantidad-${i}`} title='Cantidad' required />
                    <InputNumber
                      name={`equipo-cantidad-${i}`}
                      placeholder='Cantidad'
                      className='form-control'
                      onChange={(value) => onChangeEquipoEntradaInput(i, 'cantidad', value)}
                      value={equipo.cantidad}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='1'>
                  <Button onClick={() => removeItemEquipoEntrada(i)} variant='danger' className='pull-right' type='button'>
                    <Icon icon='trash' />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button
            onClick={addItemEquipoEntrada}
            className='list-group-item'
            style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
            type='button'
          >
            <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar material
          </Button>
        </div>
      </div>
    );
  };

  const addItemEquipoSalida = () => {
    setListSalidas([...listSalidas, { equipoId: null, cantidad: 1, name: '', obj: null }]);
  };

  const onChangeEquipoSalidaInput = (index, campo, value) => {
    setListSalidas(
      listSalidas.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            [campo]: campo === 'cantidad' ? cleanNumber(value.target.value) : value,
          };
        }
        return x;
      })
    );
  };

  const onSelectEquipoSalida = (index, campo, itemSelect) => {
    setListSalidas(
      listSalidas.map((x, i) => {
        if (i === index) {
          if (itemSelect) {
            return {
              ...x,
              obj: itemSelect,
              equipoId: itemSelect,
            };
          } else {
            return {
              ...x,
              name: '',
              obj: null,
              equipoId: null,
            };
          }
        }
        return x;
      })
    );
  };

  const removeItemEquipoSalidas = (item) => {
    setListSalidas(listSalidas.filter((x, i) => i !== item));
  };

  const renderSalidas = () => {
    return (
      <div>
        <div className='list-group'>
          {listSalidas.map((equipo, i) => (
            <div key={`row-equipo-salida-${i}`} id={`row-equipo-salida-${i}`} className='list-group-item'>
              <Row>
                <Col sm='7'>
                  <DivFormGroup>
                    <Label name={`equipo-salida-${i}`} title='Equipo salida' required />
                    <FilterSelectNuevo
                      async={true}
                      id={`equipo-${i}`}
                      name={`equipo-${i}`}
                      value={equipo.obj}
                      inputValue={equipo.name}
                      onInputChange={(value) => onChangeEquipoSalidaInput(i, 'name', value)}
                      onChangeOption={(itemSelect) => onSelectEquipoSalida(i, 'obj', itemSelect)}
                      url={`equipments-autocomplete`}
                      fields={['_id', 'code, name']}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='4'>
                  <DivFormGroup>
                    <Label name={`equipo-salida-cantidad-${i}`} title='Cantidad' required />
                    <InputNumber
                      name={`equipo-cantidad-${i}`}
                      placeholder='Cantidad'
                      className='form-control'
                      onChange={(value) => onChangeEquipoSalidaInput(i, 'cantidad', value)}
                      value={equipo.cantidad}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='1'>
                  <Button onClick={() => removeItemEquipoSalidas(i)} variant='danger' className='pull-right' type='button'>
                    <Icon icon='trash' />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button
            onClick={addItemEquipoSalida}
            className='list-group-item'
            style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
            type='button'
          >
            <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar equipo saliente
          </Button>
        </div>
      </div>
    );
  };

  const resetForm = () => {
    setTimeout(() => navigate(0), 1000);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    const uniqueEntrada = Array.from(new Set(params.listaEntradas.map((d) => d.equipoId)));
    const uniqueSalida = Array.from(new Set(params.listaSalidas.map((d) => d.equipoId)));

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (uniqueEntrada.length < params.listaEntradas.length) {
      validate.success = false;
      validate.message = 'Hay materiales duplicados.';
    } else if (uniqueSalida.length < params.listaSalidas.length) {
      validate.success = false;
      validate.message = 'Hay equipos salientes duplicados.';
    } else if (params.listaEntradas.length === 0) {
      validate.success = false;
      validate.message = 'Materiales son requeridos.';
    } else {
      for (let i = 0; i < params.listaEntradas.length; i++) {
        const element = params.listaEntradas[i];
        if (!isMongoId(element.equipoId + '')) {
          validate.success = false;
          validate.message = 'Equipo en materiales es requerido.';
          break;
        } else if (element.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad en materiales, debe ser mayor a cero.';
          break;
        }
      }
    }

    if (validate.success) {
      if (params.listaSalidas.length === 0) {
        validate.success = false;
        validate.message = 'Equipos salientes son requeridos.';
      } else {
        for (let i = 0; i < params.listaSalidas.length; i++) {
          const element = params.listaSalidas[i];
          if (!isMongoId(element.equipoId + '')) {
            validate.success = false;
            validate.message = 'Equipo en equipos salientes es requerido.';
            break;
          } else if (element.cantidad <= 0) {
            validate.success = false;
            validate.message = 'Cantidad en equipos salientes, debe ser mayor a cero.';
            break;
          }
        }
      }
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <Module onClickBack={window.history.back} parent='Recetas' title='Agregar receta'>
      <form onSubmit={handleRegister}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='6'>
                <FormInput title='Código' name='codigo' required onChange={(e) => setCodigo(e.target.value)} value={codigo} />
              </Col>
              <Col sm='6'>
                <FormInput title='Nombre' name='nombre' required onChange={(e) => setNombre(e.target.value)} value={nombre} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Header>
                <Card.Title>
                  Materiales <Required />
                </Card.Title>
              </Card.Header>
              <Card.Body>{renderEntradas()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Header>
                <Card.Title>
                  Equipos salientes <Required />
                </Card.Title>
              </Card.Header>
              <Card.Body>{renderSalidas()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <Saving saving={loading} />
                <Button type='submit' variant='primary' className='pull-right' disabled={loading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </form>
    </Module>
  );
};

New.propTypes = {};

export default New;
