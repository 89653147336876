import { TEMPORALIDAD } from '@config/constants';
import { DivFormGroup, Label, TextArea } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const Register = () => {
  const { addNotification: notification } = useStoreNotification();
  const [loading, setLoading] = useState(true);
  const [nombre, setNombre] = useState('');
  const [temporalidad, setTemporalidad] = useState('');
  const [piezas, setPiezas] = useState([]);
  const [itemPiezas, setItemPiezas] = useState(1);
  const addForm = useRef(null);

  useEffect(() => {
    const loadForm = async () => {
      const { piezas } = await getRequest({ url: `catalogo-kit-servicios` });
      setPiezas(piezas);
      setLoading(false);
    };
    loadForm();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'date') {
      setNombre(setupDate(value));
    } else {
      if (name === 'nombre') setNombre(value);
      if (name === 'temporalidad') setTemporalidad(value);
    }
  };

  const onChangePiezas = () => {};

  const setupDate = (value) => {
    const d = value.split('-');
    return new Date(d[0], d[1] - 1, d[2]);
  };

  const castMasks = (field) => {
    let value = 0;
    if (addForm.current[field] !== undefined) {
      value = parseFloat(addForm.current[field].value.trim().replace(/\$|,/gi, ''));
      if (isNaN(value)) {
        value = 0;
      }
    }
    return value;
  };

  const handleAddForm = async (event) => {
    event.preventDefault();
    const listDetalle = [];
    let total = 0;
    for (let i = 0; i < itemPiezas; i++) {
      if (addForm.current[`piezaId-${i}`] !== undefined) {
        const cantidad = castMasks(`cantidad-${i}`);
        if (isMongoId(addForm.current[`piezaId-${i}`].value + '') && cantidad > 0 && !isEmpty(addForm.current[`temporalidad-${i}`].value)) {
          listDetalle.push({
            piezaId: addForm.current[`piezaId-${i}`].value,
            cantidad,
            temporalidad: addForm.current[`temporalidad-${i}`].value,
          });
          total += cantidad;
        }
      }
    }

    const data = {
      nombre,
      temporalidad,
      detalle: listDetalle,
      total,
    };

    if (validations(data)) {
      await postRequest({ url: `kit-servicios`, body: data });
      addForm.current.reset();
      setNombre('');
      setItemPiezas(1);
    }
  };

  const addItemPieza = () => {
    setItemPiezas(itemPiezas + 1);
  };

  const removeItemPieza = (item) => {
    document.getElementById(`row-pieza-${item}`).innerHTML = null;
  };

  const renderPiezas = () => {
    const views = [];
    for (let i = 0; i < itemPiezas; i++) {
      views.push(
        <div key={i} id={`row-pieza-${i}`}>
          <Col sm='4'>
            <DivFormGroup>
              <select
                name={`piezaId-${i}`}
                id={`piezaId-${i}`}
                className='form-control'
                onChange={onChange}
                disabled={piezas.length > 0 ? false : true}
              >
                <option value=''>Seleccione</option>
                {piezas.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup>
              <select name={`temporalidad-${i}`} id={`temporalidad-${i}`} className='form-control' onChange={onChange}>
                <option value=''>Seleccione</option>
                <option value={TEMPORALIDAD.EVENTO}>Por evento</option>
                <option value={TEMPORALIDAD.DIARIO}>Diario</option>
                <option value={TEMPORALIDAD.SEMANAL}>Semanal</option>
                <option value={TEMPORALIDAD.MENSUAL}>Mensual</option>
                <option value={TEMPORALIDAD.ANUAL}>Anual</option>
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <InputNumber name={`cantidad-${i}`} value={itemPiezas[i].cantidad} onChange={onChangePiezas} />
            </DivFormGroup>
          </Col>
          <Col sm='1'>
            <Button onClick={() => removeItemPieza(i)} variant='danger' className='pull-right' type='button'>
              <Icon icon='trash' />
            </Button>
          </Col>
        </div>
      );
    }

    return (
      <div>
        <Button onClick={addItemPieza} variant='primary' className='btn-sm pull-right' style={{ marginTop: '-55px' }} type='button'>
          <Icon icon='plus' />
        </Button>
        <Col sm='4'>
          <label>Pieza</label>
        </Col>
        <Col sm='4'>
          <label>Frecuencia</label>
        </Col>
        <Col sm='3'>
          <label>Cantidad</label>
        </Col>
        {views}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form ref={addForm} onSubmit={handleAddForm}>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <Label name='nombre' title='Nombre del Kit' />
              <TextArea name='nombre' onChange={onChange} defaultValue={nombre} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <legend>Componentes del Kit</legend>
            {renderPiezas()}
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Body>
                <Saving saving={loading} />
                <Button type='submit' className='btn-lg pull-right' disabled={loading}>
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    );
  };

  const validations = (data) => {
    let validate = { success: true, message: '' };

    if (isEmpty(data.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es Requerido.';
    } else if (validate.success && data.detalle.length <= 0) {
      validate.success = false;
      validate.message = 'Falta especificar las piezas del kit';
    } else if (validate.success && data.total <= 0) {
      validate.success = false;
      validate.message = 'Falta especificar las piezas del kit';
    }

    const unique = Array.from(new Set(data.detalle.map((d) => d.piezaId)));

    if (validate.success && unique.length < data.detalle.length) {
      validate.success = false;
      validate.message = 'Hay piezas duplicadas, favor de verificar';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <Module onClickBack={window.history.back} title='Kit'>
      {!loading ? renderForm() : <Loading />}
    </Module>
  );
};

Register.propTypes = {};

export default Register;
