import moment from 'moment';
import NuevoMensaje from '@pages/application/mensajes/NuevoMensaje';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form } from 'react-bootstrap';
import { Icon, Module } from '@stateless';
import { getRequest } from '@utils/api';
import { printDiffDate } from '@utils/parseDates';
import DivFormGroup from '@controls/DivFormGroup';
import useAuth from '@hooks/useAuth';

const Mensajes = () => {
  const { user } = useAuth();
  const [inbox, setInbox] = useState([]);
  const [selected, setSelected] = useState('RECIBIDOS');

  useEffect(() => {
    if (user) loadData();
  }, [selected, user ]);

  const getList = () => {
    switch (selected) {
      case 'RECIBIDOS':
        return 'inbox';
      case 'ENVIADOS':
        return 'sends';
      default:
        return '';
    }
  };

  const loadData = async () => {
    const tipo = getList();
    const { data } = await getRequest({ url: `user/messages/${tipo}/${user?.id}` });
    if (tipo === 'inbox') {
      setInbox(data);
    }
  };

  const handleClick = (newSelected, e) => {
    e.preventDefault();
    setSelected(newSelected);
  };

  const renderRecibidos = () => (
    <Card>
      <Card.Header>
        <Card.Title>Recibidos</Card.Title>
      </Card.Header>
      <Card.Body>
        <DivFormGroup>
          <InputGroup>
            <Form.Control type='text' />
            <InputGroup.Text>
              <Button type='submit'>
                <Icon icon='search' />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </DivFormGroup>
      </Card.Body>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th>
                <th width='5%'>
                  <input type='checkbox' />
                </th>
                <td width='30%'>&nbsp;</td>
                <td>&nbsp;</td>
                <td width='15%'>&nbsp;</td>
              </th>
            </tr>
          </thead>
          <tbody>
            {inbox.map((m, i) => (
              <tr key={i} style={{ fontWeight: m.estado === 'SIN_LEER' ? '700' : '400', cursor: 'pointer' }} onClick={() => {}}>
                <td width='5%'>
                  <input type='checkbox' />
                </td>
                <td width='30%'>{m.emisor.name}</td>
                <td>{m.asunto}</td>
                <td width='15%' className='text-right with-actions'>
                  {printDiffDate(moment(m.created).format())}
                  <span className='actions'>
                    <Button variant='danger' className='btn-sm' title='Eliminar'>
                      <Icon icon='trash' />
                    </Button>
                    <Button variant='primary' className='btn-sm' title='Responder'>
                      <Icon icon='arrow-left' />
                    </Button>
                    <Button variant='primary' className='btn-sm' title='Reenviar'>
                      <Icon icon='share-alt' />
                    </Button>
                    <Button variant='primary' className='btn-sm' title='Detalle'>
                      <Icon icon='search' />
                    </Button>
                    <Button variant='warning' className='btn-sm' title='Marcar como no leido'>
                      <Icon icon='folder-close' />
                    </Button>
                    <Button variant='light' className='btn-sm' title='Marcar como leido'>
                      <Icon icon='folder-open' />
                    </Button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );

  return (
    <Module title='Inicio'>
      <Row>
        <Col sm='3'>
          <Row>
            <Col sm='12'>
              <NuevoMensaje />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <br />
              <Card>
                <ul className='nav nav-pills nav-stacked'>
                  <li className={selected === 'RECIBIDOS' ? 'active' : ''}>
                    <a href='#' onClick={(e) => handleClick('RECIBIDOS', e)}>
                      <Icon icon='inbox' /> Recibidos
                      <span className='label label-primary pull-right'>{inbox.length}</span>
                    </a>
                  </li>
                  <li className={selected === 'ENVIADOS' ? 'active' : ''}>
                    <a href='#' onClick={(e) => handleClick('ENVIADOS', e)}>
                      <Icon icon='envelope' /> Enviados
                    </a>
                  </li>
                  <li className={selected === 'BORRADORES' ? 'active' : ''}>
                    <a href='#' onClick={(e) => handleClick('BORRADORES', e)}>
                      <Icon icon='file' /> Borradores
                    </a>
                  </li>
                  <li className={selected === 'ELIMINADOS' ? 'active' : ''}>
                    <a href='#' onClick={(e) => handleClick('ELIMINADOS', e)}>
                      <Icon icon='trash' /> Eliminados
                    </a>
                  </li>
                </ul>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <br />
              <Card>
                <Card.Header>
                  <Card.Title>Etiquetas</Card.Title>
                </Card.Header>
                <ul className='nav nav-pills nav-stacked'>
                  <li>
                    <a href='#'>
                      <Icon icon='menu-right' /> Importantes
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <Icon icon='menu-right' /> Promociones
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <Icon icon='menu-right' /> Social
                    </a>
                  </li>
                </ul>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm='9'>{renderRecibidos()}</Col>
      </Row>
    </Module>
  );
};

Mensajes.propTypes = {};

export default Mensajes;
