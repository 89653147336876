import React, { useState } from 'react';
import { Module, Icon } from '@stateless';
import { DivFormGroup } from '@controls';
import { Row, Col, Container, Card, InputGroup, Button } from 'react-bootstrap';
import { getReferencia } from '@utils/cotizaciones';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import { Input } from '@controls';
import NewCRM from '@pages/clientes/cotizaciones/NewCRM';
import EditCRM from '@pages/clientes/cotizaciones/EditCRM';
import SelectTipoManoDeObra from '@components/forms/SelectTipoManoDeObra';
import SelectTipoCobroEquipo from '@components/forms/SelectTipoCobroEquipo';
// import MultiSelectSolucionesSolicitadas from '@components/forms/MultiSelectSolucionesSolicitadas';
import MultiSelectTiposServiciosSolicitados from '@components/forms/MultiSelectTiposServiciosSolicitados';
import SelectCRM from '@components/forms/SelectCRM';
import SelectCuentaMonitoreo from '@components/forms/SelectCuentaMonitoreo';

const Index = () => {
  const { addNotification: notification } = useStoreNotification();
  const [tipos_mano_obra_id, setTiposManoObraId] = useState(null);
  const [tipos_cobro_equipo_id, setTiposCobroEquipoId] = useState(null);
  // const [soluciones_solicitadas_id, setSolucionesSolicitadasId] = useState([]);
  const [tipos_servicios_solicitados_id, setTiposServiciosSolicitadosId] = useState([]);
  const [horas, setHoras] = useState(0);
  const [clienteRegistro, setClienteRegistro] = useState(null);
  const [descripcion, setDescripcion] = useState('');
  const [agente, setAgente] = useState(null);
  const [agente2, setAgente2] = useState(null);
  const [numero_cotizacion, setNumeroCotizacion] = useState('');
  const [cuentaMonitoreo, setCuentaMonitoreo] = useState(null);

  const referencia = getReferencia({
    tipos_mano_obra: tipos_mano_obra_id?.clave,
    tipo_servicio_solicitado: tipos_servicios_solicitados_id.length > 0 ? tipos_servicios_solicitados_id[0]?.clave : '',
    tipos_cobro_equipo: tipos_cobro_equipo_id?.clave,
    monitoreo: cuentaMonitoreo?.numero,
    folio: clienteRegistro?.folio,
    descripcion,
    horas,
    numero_levantamiento: numero_cotizacion,
    agente: agente?.codigo,
    agente_compartido: agente2?.codigo,
  });

  const handleCopiar = (e) => {
    e.preventDefault();
    copyToClipboard(referencia);
    notification({
      message: '¡Referencia copiada con éxito!',
      type: 'success',
    });
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  return (
    <Module title='Estado de cuenta'>
      <Container>
        <Card>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <DivFormGroup name='tipos_mano_obra_id' required title='Cobro mano de obra'>
                  <SelectTipoManoDeObra value={tipos_mano_obra_id} onChange={(value) => setTiposManoObraId(value)} name='tipos_mano_obra_id' />
                </DivFormGroup>
              </Col>
              <Col sm={3}>
                <DivFormGroup name='tipos_cobro_equipo_id' required title='Cobro equipo'>
                  <SelectTipoCobroEquipo
                    value={tipos_cobro_equipo_id}
                    onChange={(value) => setTiposCobroEquipoId(value)}
                    name='tipos_cobro_equipo_id'
                  />
                </DivFormGroup>
              </Col>
              <Col sm={3}>
                <DivFormGroup name='numero_cotizacion' required title='Numero cotización'>
                  <Input
                    name='numero_cotizacion'
                    value={numero_cotizacion}
                    placeholder='Numero cotización'
                    onChange={(e) => setNumeroCotizacion(e.target.value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm={3}>
                <DivFormGroup name='agente' required title='Agente principal'>
                  <SelectAgente
                    value={agente}
                    name='agente'
                    onChange={(value) => {
                      setAgente(value);
                      setAgente2(value);
                    }}
                  />
                </DivFormGroup>
              </Col>
              <Col sm={4}>
                <DivFormGroup name='agente' required title='Agente compartido'>
                  <SelectAgente value={agente2} name='agente2' onChange={(value) => setAgente2(value)} />
                </DivFormGroup>
              </Col>
              {/* <Col sm={3}>
                <DivFormGroup name='soluciones_solicitadas_id' title='Soluciones solicitadas' >
                  <MultiSelectSolucionesSolicitadas
                    name='soluciones_solicitadas_id'
                    value={soluciones_solicitadas_id}
                    onChange={(value) => setSolucionesSolicitadasId(value)}
                  />
                </DivFormGroup>
              </Col> */}
              <Col sm={4}>
                <DivFormGroup name='tipos_servicios_solicitados_id' required title='Tipos servicios solicitados'>
                  <MultiSelectTiposServiciosSolicitados
                    name='tipos_servicios_solicitados_id'
                    value={tipos_servicios_solicitados_id}
                    onChange={(value) => setTiposServiciosSolicitadosId(value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm={4}>
                <DivFormGroup name='horas' title='Horas cotizadas' required>
                  <Input name='horas' value={horas} placeholder='horas' onChange={(e) => setHoras(e.target.value)} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <DivFormGroup name='registroclienteName' required title='Registro de cliente'>
                  <InputGroup>
                    <SelectCRM onChange={(value) => setClienteRegistro(value)} name='clienteRegistro' value={clienteRegistro} />
                    {!clienteRegistro ? (
                      <NewCRM refresh={(value) => setClienteRegistro(value)} />
                    ) : (
                      <EditCRM id={clienteRegistro?._id} refresh={(value) => setClienteRegistro(value)} />
                    )}
                  </InputGroup>
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <DivFormGroup name='cuentaMonitoreo' required title='Cuenta monitoreo'>
                  <SelectCuentaMonitoreo onChange={(value) => setCuentaMonitoreo(value)} name='cuentaMonitoreo' value={cuentaMonitoreo} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <DivFormGroup name='descripcion' required title='Oportunidad / Observaciones'>
                  <Input name='descripcion' value={descripcion} placeholder='Descripción corta' onChange={(e) => setDescripcion(e.target.value)} />
                </DivFormGroup>
              </Col>
              <Col sm={12}>
                <DivFormGroup name='referencia' title='Referencia'>
                  <InputGroup>
                    <Button variant='outline-warning' onClick={handleCopiar}>
                      <Icon icon='copy' />
                    </Button>
                    <Input name='referencia' value={referencia} placeholder='Referencia' style={{ fontSize: '20px' }} />
                  </InputGroup>
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </Module>
  );
};

export default Index;
