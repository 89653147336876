import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { format } from '@utils/parseCost';
import { IfPermission } from '@controls';

const IndicadoresVentas = ({
  loading,
  clientes,
  prospectos,
  cotizacionesCount,
  cotizacionesImporte,
  pagos,
  pagosImporte,
  facturas,
  facturasImporte,
  servicios,
  serviciosImporte,
  documentosPagos,
  documentosPagosImporte,
}) => {
  return (
    <Row>
      <Col>
        <Row className='mb-3'>
          <IfPermission action='clientes'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default w-100 h-100'>
                <div className='inner'>
                  <p>Clientes / Prospectos</p>
                  <h3>
                    {!loading ? clientes : '-'} / {!loading ? prospectos : '-'}
                  </h3>
                  <h3>&nbsp;</h3>
                </div>
                <div className='icon'>
                  <span className="glyphicon glyphicon-user"/>
                </div>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='ventas'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default w-100 h-100'>
                <div className='inner'>
                  <p>Cotizaciones</p>
                  <h3>{!loading ? cotizacionesCount : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(cotizacionesImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <span className="glyphicon glyphicon-list"/>
                </div>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='ventas'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default w-100 h-100'>
                <div className='inner'>
                  <p>Pagos Conekta</p>
                  <h3>{!loading ? pagos : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(pagosImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <span className="glyphicon glyphicon-usd"/>
                </div>
              </div>
            </Col>
          </IfPermission>
        </Row>
        <Row>
          <IfPermission action='ventas'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default w-100 h-100'>
                <div className='inner'>
                  <p>Facturas equipos</p>
                  <h3>{!loading ? facturas : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(facturasImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <span className="glyphicon glyphicon-list"/>
                </div>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='servicios'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default w-100 h-100'>
                <div className='inner'>
                  <p>Facturas servicios</p>
                  <h3>{!loading ? servicios : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(serviciosImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <span className="glyphicon glyphicon-wrench"/>
                </div>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='pagos'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default w-100 h-100'>
                <div className='inner'>
                  <p>Pagos</p>
                  <h3>{!loading ? documentosPagos : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(documentosPagosImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <span className="glyphicon glyphicon-usd"/>
                </div>
              </div>
            </Col>
          </IfPermission>
        </Row>
      </Col>
    </Row>
  );
};

IndicadoresVentas.propTypes = {
  loading: PropTypes.bool,
  clientes: PropTypes.number,
  prospectos: PropTypes.number,
  cotizacionesCount: PropTypes.number,
  cotizacionesImporte: PropTypes.number,
  pagos: PropTypes.number,
  pagosImporte: PropTypes.number,
  facturas: PropTypes.number,
  facturasImporte: PropTypes.number,
  servicios: PropTypes.number,
  serviciosImporte: PropTypes.number,
  documentosPagos: PropTypes.number,
  documentosPagosImporte: PropTypes.number,
};

export default IndicadoresVentas;
