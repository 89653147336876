import { AutoComplete, DivFormGroup, Input } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, empleadoId, load, departamentos, puestos, tiposnominas, grupos_empleados }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    numero_empleado: '',
    nombre_completo: '',
    rfc: '',
    curp: '',
    departamentoId: null,
    grupoEmpleadoId: null,
    puestoId: null,
    tipoNominaId: null,
    imss: '',
    fecha_nacimiento: '',
    fecha_ingreso: '',
  });
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = async () => {
    const {
      data: {
        numero_empleado,
        nombre_completo,
        rfc,
        curp,
        departamentoId,
        puestoId,
        fecha_nacimiento,
        fecha_ingreso,
        tipoNominaId,
        imss,
        grupoEmpleadoId,
      },
    } = await getRequest({ url: `empleados/${empleadoId}` });

    setFormData({
      numero_empleado: numero_empleado.trim().toUpperCase(),
      nombre_completo: nombre_completo.trim().toUpperCase(),
      rfc: rfc.trim().toUpperCase(),
      curp: curp.trim().toUpperCase(),
      departamentoId: departamentoId ? departamentoId._id : null,
      grupoEmpleadoId: grupoEmpleadoId ? grupoEmpleadoId._id : null,
      puestoId: puestoId._id,
      fecha_nacimiento: fecha_nacimiento ? moment(fecha_nacimiento.substring(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      fecha_ingreso: moment(fecha_ingreso.substring(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      tipoNominaId: tipoNominaId._id,
      imss,
    });

    setShowModal(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAutoCompleteChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const {
      numero_empleado,
      nombre_completo,
      rfc,
      curp,
      departamentoId,
      grupoEmpleadoId,
      puestoId,
      fecha_nacimiento,
      fecha_ingreso,
      tipoNominaId,
      imss,
    } = formData;

    const data = {
      numero_empleado: numero_empleado.trim().toUpperCase(),
      nombre_completo: nombre_completo.trim().toUpperCase(),
      rfc: rfc.trim().toUpperCase(),
      curp: curp.trim().toUpperCase(),
      departamentoId,
      grupoEmpleadoId,
      puestoId,
      fecha_nacimiento,
      fecha_ingreso,
      tipoNominaId,
      imss,
    };

    if (validations(data)) {
      await putRequest({ url: `empleados/${empleadoId}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.numero_empleado + '')) {
      validate.success = false;
      validate.message = 'Número de empleado es requerido.';
    } else if (isEmpty(params.nombre_completo + '')) {
      validate.success = false;
      validate.message = 'Nombre completo es requerido.';
    } else if (isEmpty(params.rfc + '')) {
      validate.success = false;
      validate.message = 'RFC es requerido.';
    } else if (isEmpty(params.curp + '')) {
      validate.success = false;
      validate.message = 'CURP es requerida.';
    } else if (!isMongoId(params.departamentoId + '')) {
      validate.success = false;
      validate.message = 'Departamento es requerido.';
    } else if (!isMongoId(params.grupoEmpleadoId + '')) {
      validate.success = false;
      validate.message = 'Grupo de empleado es requerido.';
    } else if (!isMongoId(params.puestoId + '')) {
      validate.success = false;
      validate.message = 'Puesto es requerido.';
    } else if (isEmpty(params.fecha_ingreso + '')) {
      validate.success = false;
      validate.message = 'Fecha de ingreso es requerida.';
    } else if (moment(params.fecha_ingreso).diff(moment(), 'days') > 0) {
      validate.success = false;
      validate.message = 'Fecha de ingreso no debe ser mayor a la fecha actual.';
    } else if (!isMongoId(params.tipoNominaId + '')) {
      validate.success = false;
      validate.message = 'Tipo de nomina es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const {
    numero_empleado,
    nombre_completo,
    rfc,
    curp,
    departamentoId,
    grupoEmpleadoId,
    puestoId,
    fecha_nacimiento,
    fecha_ingreso,
    tipoNominaId,
    imss,
  } = formData;

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={id}>Editar</Tooltip>}>
        <Button onClick={open} variant='outline-success' type='button'>
          <Icon icon='pencil' />
        </Button>
      </OverlayTrigger>

      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEdit}>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='numero_empleado' title='Número de empleado' required>
                  <Input name='numero_empleado' placeholder='Número de empleado' onChange={handleChange} value={numero_empleado} />
                </DivFormGroup>
                <DivFormGroup name='nombre_completo' title='Nombre completo' required>
                  <Input name='nombre_completo' placeholder='Nombre completo' onChange={handleChange} value={nombre_completo} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='rfc' title='RFC' required>
                  <Input name='rfc' placeholder='RFC' onChange={handleChange} value={rfc} />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='curp' title='CURP' required>
                  <Input name='curp' placeholder='CURP' onChange={handleChange} value={curp} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='grupoEmpleadoId' title='Grupo de empleados' required>
                  <AutoComplete
                    id='grupoEmpleadoId'
                    name='grupoEmpleadoId'
                    defaultValue={grupoEmpleadoId}
                    data={grupos_empleados}
                    callback={handleAutoCompleteChange.bind(this, 'grupoEmpleadoId')}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='departamentoId' title='Departamento' required>
                  <AutoComplete
                    id='departamentoId'
                    name='departamentoId'
                    defaultValue={departamentoId}
                    data={departamentos}
                    callback={handleAutoCompleteChange.bind(this, 'departamentoId')}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='puestoId' title='Puesto' required>
                  <AutoComplete
                    id='puestoId'
                    name='puestoId'
                    defaultValue={puestoId}
                    data={puestos}
                    callback={handleAutoCompleteChange.bind(this, 'puestoId')}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='fecha_nacimiento' title='Fecha nacimiento'>
                  <Input name='fecha_nacimiento' type='date' placeholder='Fecha nacimiento' onChange={handleChange} value={fecha_nacimiento} />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='fecha_ingreso' title='Fecha ingreso' required>
                  <Input name='fecha_ingreso' type='date' placeholder='Fecha ingreso' onChange={handleChange} value={fecha_ingreso} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='tipoNominaId' title='Tipo de nomina' required>
                  <AutoComplete
                    id='tipoNominaId'
                    name='tipoNominaId'
                    defaultValue={tipoNominaId}
                    data={tiposnominas}
                    callback={handleAutoCompleteChange.bind(this, 'tipoNominaId')}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='imss' title='IMSS'>
                  <Input name='imss' placeholder='IMSS' onChange={handleChange} value={imss} />
                </DivFormGroup>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleEdit} variant='primary' className='pull-right' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  empleadoId: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  departamentos: PropTypes.array.isRequired,
  grupos_empleados: PropTypes.array.isRequired,
  tiposnominas: PropTypes.array.isRequired,
  puestos: PropTypes.array.isRequired,
};

export default Edit;
