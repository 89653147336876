import { Confirmation } from '@controls/index';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteAgentes = ({ nombre, agenteId, refresh }) => {
  return (
    <Confirmation
      id={agenteId}
      btnIcon='arrow-down'
      typeConfirm='primary'
      btnType='outline-warning'
      action='Baja'
      typeRequest='putRequest'
      url={`agentes/${agenteId}/baja`}
      description={`¿Confirma que desea dar de baja el agente: <strong>${nombre}</strong>?`}
      loadData={refresh}
    />
  );
};

DeleteAgentes.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  agenteId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default DeleteAgentes;
