import CbDomicilio from '@controls/CbDomicilio';
import Confirmation from '@controls/Confirmation';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CbBadge from '@cbcomponents/CbBadge';
import DetailCustomer from '@components/customers/DetailCustomer';
import CambiarEstado from './CambiarEstado';
import Edit from './Edit';
import TextCurrency from '@components/application/controls/TextCurrency';

// TODO: PASAR ESTADOS MANDADO A CONSTANTES
const ESTATUS_CUENTA_MONITOREO = [
  { value: 'EN_SERVICIO', label: 'EN SERVICIO', type: 'success' },
  { value: 'CANCELADO', label: 'CANCELADO', type: 'success' },
  { value: 'BAJA', label: 'BAJA', type: 'danger' },
  { value: 'ACTIVACION_EN_PROCESO', label: 'ACTIVACION EN PROCESO', type: 'secondary' },
  { value: 'BAJA_EN_PROCESO', label: 'BAJA EN PROCESO', type: 'warning' },
  { value: 'SUSPENDIDO', label: 'SUSPENDIDO', type: 'danger' },
  { value: 'CUENTA_DUPLICADA', label: 'CUENTA DUPLICADA', type: 'danger' },
  { value: 'BAJA_SIN_DESCONEXION', label: 'BAJA SIN DESCONEXION', type: 'warning' },
  { value: 'CAMBIO_DE_CUENTA', label: 'CAMBIO DE CUENTA', type: 'warning' },
  { value: 'DATOS_PENDIENTES', label: 'DATOS PENDIENTES', type: 'secondary' },
];

const ListRow = ({ row, item, loadData }) => {
  const {
    _id,
    nombre,
    numero,
    cliente_id,
    domicilio_monitoreo_id,
    dealer_id,
    tipo_monitoreo_id,
    agenda_monitoreo_id,
    evento,
    estatus,
    contrato,
    total,
  } = row;

  const fechaValida = moment(evento?.fecha_ultimo_evento).isValid();
  const dias = moment().diff(moment(evento?.fecha_ultimo_evento), 'days');
  const diasSinComunicar = moment(evento?.fecha_ultimo_evento).from(moment());
  const typeDias = dias > 30 ? 'danger' : 'success';
  const estado = ESTATUS_CUENTA_MONITOREO.find((estado) => estado.value === estatus);

  return (
    <tr key={item}>
      <td width='5%'>
        <OverlayTrigger placement='top' overlay={<Tooltip>{tipo_monitoreo_id?.nombre}</Tooltip>}>
          <CbBadge type='success mr-5'>{tipo_monitoreo_id?.clave}</CbBadge>
        </OverlayTrigger>
        {total > 0 && (
          <CbBadge type='dark'>
            <TextCurrency>{total}</TextCurrency>
          </CbBadge>
        )}
        <br />
        <CbBadge type='primary'>{contrato}</CbBadge>
      </td>
      <td>
        <CbBadge type='primary'>{numero}</CbBadge> <span className='mr-1'></span>
        <CbBadge type='primary'>{dealer_id?.nombre}</CbBadge>
        <br />
        {nombre}
      </td>
      <td>
        <DetailCustomer customer={cliente_id} />
      </td>
      <td>
        <CbDomicilio address={domicilio_monitoreo_id} />
      </td>
      <td>
        <OverlayTrigger placement='top' overlay={<Tooltip>{agenda_monitoreo_id?.referencia}</Tooltip>}>
          <div>
            {agenda_monitoreo_id?.referencia?.length > 50
              ? agenda_monitoreo_id?.referencia?.substring(0, 50) + '...'
              : agenda_monitoreo_id?.referencia}
            { agenda_monitoreo_id && agenda_monitoreo_id?.estatus != 'EN_SERVICIO' && <CbBadge type='danger'>{agenda_monitoreo_id?.estatus}</CbBadge> } 
          </div>
        </OverlayTrigger>
      </td>
      <td>
        <CbBadge type={estado?.type}>{estado?.label}</CbBadge>
        {fechaValida && (
          <OverlayTrigger placement='top' overlay={<Tooltip>{evento?.ultimo_evento}</Tooltip>}>
            <CbBadge type={typeDias}>{diasSinComunicar}</CbBadge>
          </OverlayTrigger>
        )}
      </td>
      <td>
        <ButtonGroup>
          <IfPermission action='editar'>
            <CambiarEstado id={item} itemId={_id} numero={numero} nombre={nombre} {...row} loadData={loadData} />
          </IfPermission>
          <IfPermission action='editar'>
            <Edit id={item} itemId={_id} numero={numero} nombre={nombre} {...row} loadData={loadData} />
          </IfPermission>
          <IfPermission action='eliminar'>
            <Confirmation
              btnIcon='trash'
              typeRequest='deleteRequest'
              btnType='outline-danger btn-sm'
              action='Eliminar'
              url={`cuentas-monitoreos/${_id}`}
              description={`¿Confirma que desea eliminar el cuenta de monitoreo <strong><u>  ${numero} - ${nombre}</u> </strong>?`}
              loadData={loadData}
            />
          </IfPermission>
        </ButtonGroup>
      </td>
    </tr>
  );
};

ListRow.propTypes = {
  row: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  item: PropTypes.number.isRequired,
};

export default ListRow;
