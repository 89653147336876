import React, { useState, useEffect, useRef } from 'react';
import { Loading, Module, Icon } from '@stateless';
import { postRequest, getRequest, URL_FILES } from '@utils/api';
import { Row, Col, Card, Button, OverlayTrigger, Popover, InputGroup, ButtonGroup, FormGroup } from 'react-bootstrap';
import DateRangePickerEs from '@components/DateRangePickerEs';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import TextDate from '@controls/TextDate';
import CbDomicilio from '@cbcomponents/CbDomicilio';
import DetailCustomer from '@components/customers/DetailCustomer';
import CbBadge from '@cbcomponents/CbBadge';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import Evidencias from './Evidencias';

const onClickDownloadPdf = async (idOrdenServicio) => {
    const { data } = await getRequest({ url: `ordenes-servicio/pdf/${idOrdenServicio}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };
// const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const ReporteEvidenciasOrdenesDeServicios = () => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [data, setData] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().startOf('month').toDate());
    const [fechaFin, setFechaFin] = useState(moment().endOf('month').toDate());
    const [loading, setLoading] = useState(false);
    
    const handleSearch = async () => {
        setLoading(true);
        const { data } = await postRequest({ url: `reportes/evidencias`, body: { fechaInicio, fechaFin } });
        setData(data);
        setLoading(false);
    };

    useEffect(() => {
        handleSearch();
    },[fechaInicio, fechaFin]);

    const handleSelect = ({ startDate, endDate }) => {
        setFechaInicio(startDate);
        setFechaFin(endDate);
        setShow(false);
    };

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const renderList = () => {
        return (
            <>
                <Card>
                    <div className='table-responsive'>
                        <table className='table table-striped table-hover table-bordered'>
                            <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Numero</th>
                                <th>Fechas</th>
                                <th>Cliente / Domicilio</th>
                                <th>Agentes</th>        
                                <th>Referencia</th>
                                <th>Evidencias</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map(({ totalEvidencias, _id: { _id, tipoOrdenServicioId, numero_orden_servicio, fecha_entrega, fecha_finalizacion, fecha_solicitud, cliente, domicilio_entrega, agente, agentes_asignados, referencia }, evidencias }, index) => (
                                    <tr key={index}>
                                        <td>{tipoOrdenServicioId?.nombre}</td>
                                        <td>{numero_orden_servicio}</td>
                                        <td>
                                            <span>
                                                <b>
                                                    Solicitud: <br />
                                                    </b>
                                                        <TextDate>{fecha_solicitud}</TextDate>
                                                    <br />
                                                <b>
                                                    Instalación: <br />
                                                </b>
                                                    <TextDate>{fecha_entrega}</TextDate>
                                                {fecha_finalizacion && (
                                                    <>
                                                        <br />
                                                        <b>
                                                            Finalización: <br />
                                                        </b>
                                                        <TextDate>{fecha_finalizacion}</TextDate>
                                                    </>
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                        <DetailCustomer customer={cliente} />
                                        <CbDomicilio address={domicilio_entrega} />
                                        </td>
                                        <td>
                                        <span>
                                            <b>
                                            Solicitante: <br /> {agente ? <CbBadge type='default'>{agente?.nombre}</CbBadge> : null}
                                            </b>{' '}
                                            <br />
                                            <b>
                                            Asignado(s): <br />{' '}
                                            {agentes_asignados
                                                ? agentes_asignados
                                                    .map((x) => x.nombre)
                                                    .map((x) => (
                                                        <CbBadge key={x} type='default'>
                                                            {x}
                                                        </CbBadge>
                                                    ))
                                                : 'Sin agente asignado'}
                                            </b>
                                        </span>
                                        </td>
                                        <td>{referencia}</td>
                                        <td>{totalEvidencias}</td>
                                        <td>
                                            <ButtonGroup>
                                                <CbButtonOver onClick={() => onClickDownloadPdf(_id)} icon='download-alt' title='Descargar PDF' type='warning' />
                                                 <Evidencias
                                                    evidencias={evidencias.flat()}
                                                    ordenServicioId={_id}
                                                />
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </Card>
            </>
        );
    };

    const listView = !loading ? renderList() : <Loading />;
    return (
        <Module title='Ordenes de Servicios'>
            <Row>
                <Col xs={12}>
                    <Card style={{ marginBottom: '10px' }}>
                        <Card.Body style={{ paddingTop: '10px', paddingBottom: '7px' }}>
                        <Row>
                            <div className={'col-xs-12'}>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup className='pull-right'>
                                            <InputGroup>
                                                <OverlayTrigger
                                                    show={show}
                                                    target={target}
                                                    container={ref.current}
                                                    placement='bottom'
                                                    overlay={
                                                        <Popover id='popover-basic' style={{ maxWidth: '100%' }}>
                                                        <Popover.Header as='h3'>Rango de Fechas</Popover.Header>
                                                        <Popover.Body style={{ padding: 0 }}>
                                                            <DateRangePickerEs startDateInit={fechaInicio} endDateInit={fechaFin} onChange={handleSelect} />
                                                        </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                <Button variant='outline-primary' onClick={handleClick}>
                                                    <Icon icon='calendar' /> {fechaInicio.toLocaleDateString()} - {fechaFin.toLocaleDateString()}
                                                </Button>
                                                </OverlayTrigger>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>    
            {listView}
        </Module>
    );
};

export default ReporteEvidenciasOrdenesDeServicios;
