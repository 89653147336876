import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import TableReferences from './TableReferences';
import { Col, Row } from 'react-bootstrap';

export const FILTERS = [
  { name: 'ALL', text: 'Todos', className: 'info' },
  { name: 'SERVICE', text: 'Servicios', className: 'primary' },
  { name: 'PRODUCT', text: 'Productos', className: 'warning' },
];

const ListReferences = () => {
  const [catalogReferences, setCatalogReferences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data, count } = await postRequest({ url: `references/search`, params: { page, limit: pagination }, body });
    setCatalogReferences(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search, fechaFin, fechaInicio]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    setFechaInicio(params.fechaInicio);
    setFechaFin(params.fechaFin);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  return (
    <Module title='Referencias'>
      <Row>
        <Col>
          <Search onSearch={onSearch} withDatetime></Search>
        </Col>
      </Row>
      <Loading loading={loading}>
        <TableReferences catalogReferences={catalogReferences} load={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ListReferences.propTypes = {};

export default ListReferences;
