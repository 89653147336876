import { DivFormGroup, FormInput, Input } from '@controls';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest, URL_FILES } from '@utils/api';

import moment from 'moment';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import ModalImprimir from './components/ModalImprimir';
import { validateUpdate } from '@validations/ordenesServicio';
import SelectTipoOrdenServicio from '@components/forms/SelectTipoOrdenServicio';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectDomicilioCliente from '@components/forms/SelectDomicilioCliente';
import SelectContactoCliente from '@components/forms/SelectContactoCliente';
import SelectCliente from '@components/forms/SelectCliente';
import { ESTATUS_ORDENES } from '@config/constants';
import SelectTipoManoDeObra from '@components/forms/SelectTipoManoDeObra';
import SelectTipoCobroEquipo from '@components/forms/SelectTipoCobroEquipo';
import MultiSelectTiposServiciosSolicitados from '@components/forms/MultiSelectTiposServiciosSolicitados';
import MultiSelectSolucionesSolicitadas from '@components/forms/MultiSelectSolucionesSolicitadas';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const New = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const { agente: agenteAuth } = useAuth();
  const [state, setState] = useState({
    isLoadingForm: true,
    isLoading: false,
    fecha_solicitud: moment().format('YYYY-MM-DD HH:mm'),
    sucursales: [],
    soluciones_solicitadas: [],
    tipos_servicios_solicitados: [],
    tipos_mano_obra: [],
    tipos_cobro_equipo: [],
    estatus: { value: 'INICIADA', label: 'INICIADA' },
    buscar: '',
    estatus_servicios: ESTATUS_ORDENES,
    numeroOrdenServicio: 0,
    clienteId: null,
    cliente: null,
    agente: agenteAuth,
    agenteId: agenteAuth?._id,
    sucursal: agenteAuth?.sucursalId,
    domicilioId: null,
    domicilios: [],
    tipos_servicios_solicitados_id: [],
    soluciones_solicitadas_id: [],
    contactos: [],
    loading: true,
    showModalDownload: false,
    idOrdenServicio: null,
    closeDownload: false,
  });

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = (campo, value) => {
    setState(
      (prevState) => ({
        ...prevState,
        [campo]: value,
      }),
      () => {
        if (campo === 'tipoOrdenServicioId') {
          actualizarNumeroOrdenServicio();
        }
      }
    );
  };

  const actualizarNumeroOrdenServicio = async () => {
    const { tipoOrdenServicioId } = state;
    setState((prevState) => ({ ...prevState, isLoadingForm: true }));
    const {
      data: { numero_orden_servicio },
    } = await getRequest({ url: `ordenes-servicio/siguienteNumero`, params: { tipoOrdenServicioId: tipoOrdenServicioId._id } });
    setState((prevState) => ({ ...prevState, numeroOrdenServicio: numero_orden_servicio, isLoadingForm: false }));
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.listDomicilios;
      const contactos = itemSelect.listContactos;
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      const contactoId = contactos.length === 1 ? contactos[0] : null;
      setState((prevState) => ({
        ...prevState,
        cliente: itemSelect,
        clienteId: itemSelect._id,
        domicilioId,
        domicilioClienteId: domicilioId,
        contacto: contactoId,
        domicilios,
        contactos,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        clienteId: null,
        cliente: null,
        domicilioId: null,
        domicilioClienteId: null,
        contacto: null,
        domicilios: [],
        contactos: [],
      }));
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const close = () => {
    setState((prevState) => ({ ...prevState, showModal: false }));
  };

  const onClickDownloadPdf = (id) => {
    setState((prevState) => ({ ...prevState, showModalDownload: true, idOrdenServicio: id }));
  };

  const closeDownload = () => {
    setState(
      (prevState) => ({ ...prevState, showModalDownload: false }),
      () => {
        setTimeout(() => navigate('/ordenes-servicio'), 1000);
      }
    );
  };

  const agregarOtra = () => {
    setState(
      (prevState) => ({ ...prevState, showModalDownload: false }),
      () => {
        setTimeout(() => navigate(0), 1000);
      }
    );
  };

  const onAceptar = async (event) => {
    event.preventDefault();
    const { idOrdenServicio } = state;
    const { data } = await getRequest({ url: `ordenes-servicio/pdf/${idOrdenServicio}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      close();
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroOrdenServicio,
      fecha_solicitud,
      fecha_entrega,
      agenteId,
      estatus,
      sucursal,
      clienteId,
      domicilioId,
      soluciones_solicitadas_id,
      tipos_servicios_solicitados_id,
      tipos_mano_obra_id,
      domicilioClienteId,
      referencia,
      contacto,
      tipoOrdenServicioId,
      tipos_cobro_equipo_id,
      descripcion,
      observacion,
    } = state;

    const body = {
      numero_orden_servicio: numeroOrdenServicio,
      fecha_solicitud: moment(fecha_solicitud, 'YYYY-MM-DD HH:mm').utc(),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD HH:mm').utc(),
      estatus_servicios: estatus.value,
      sucursalId: sucursal._id,
      agenteId,
      domicilio_entrega_Id: domicilioId ? domicilioId._id : null,
      clienteId: clienteId,
      tipoOrdenServicioId: tipoOrdenServicioId ? tipoOrdenServicioId._id : null,
      domicilio_cliente_id: domicilioClienteId ? domicilioClienteId._id : null,
      referencias_cliente: referencia,
      contacto_solicitante: contacto ? contacto._id : null,
      soluciones_solicitadas_id: soluciones_solicitadas_id.map((x) => x._id),
      tipos_servicios_solicitados_id: tipos_servicios_solicitados_id.map((x) => x._id),
      tipos_mano_obra_id: tipos_mano_obra_id ? tipos_mano_obra_id._id : null,
      tipos_cobro_equipo_id: tipos_cobro_equipo_id ? tipos_cobro_equipo_id._id : null,
      descripcion,
      observacion,
    };

    if (validations(body)) {
      const { data } = await postRequest({ url: `ordenes-servicio`, body });
      if (data) {
        onClickDownloadPdf(data._id);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!agenteAuth) {
        notification({
          title: 'Información incompleta',
          message: 'Es necesario tener configurado el usuario como agente',
          type: 'error',
        });
        navigate('/');
        return;
      }
      setState((prevState) => ({
        ...prevState,
      }));
    };
    fetchData();
  }, [navigate]);

  const renderView = () => {
    const {
      numeroOrdenServicio,
      fecha_solicitud,
      fecha_entrega,
      estatus,
      sucursal,
      domicilioId,
      domicilios,
      domicilioClienteId,
      isLoading,
      estatus_servicios,
      cliente,
      contacto,
      contactos,
      descripcion,
      observacion,
      referencia,
      agente,
      soluciones_solicitadas_id,
      tipos_servicios_solicitados_id,
      tipos_mano_obra_id,
      tipos_cobro_equipo_id,
      tipoOrdenServicioId,
    } = state;

    return (
      <form onSubmit={handleRegister}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='3'>
                <DivFormGroup name='tipoOrdenServicioId' title='Tipo orden de servicio' required>
                  <SelectTipoOrdenServicio
                    value={tipoOrdenServicioId}
                    name='tipoOrdenServicioId'
                    onChange={(value) => onSelect('tipoOrdenServicioId', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput
                  title='# Orden de servicio'
                  placeholder='Número de orden de servicio'
                  name='numeroOrdenServicio'
                  onChange={onChange}
                  value={numeroOrdenServicio}
                />
              </Col>
              <Col sm='3'>
                <FormInput
                  title='Fecha solicitud'
                  type='datetime-local'
                  required
                  name='fecha_solicitud'
                  onChange={onChange}
                  value={fecha_solicitud}
                />
              </Col>
              <Col sm='3'>
                <FormInput title='Fecha entrega' type='datetime-local' name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='agente' required title='Agente solicitante'>
                  <SelectAgente value={agente} name='agente' onChange={(value) => onSelect('agente', value)} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursal} name='sucursal' onChange={(value) => onSelect('sucursal', value)} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='estatus_servicios' required title='Estatus'>
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    value={estatus}
                    isLoading={isLoading}
                    name='estatus_servicios'
                    placeholder='Seleccione una opción'
                    options={estatus_servicios}
                    onChange={(value) => onSelect('estatus', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='8'>
                <DivFormGroup name='cliente' required title='Clientes'>
                  <SelectCliente value={cliente} name='cliente' onChange={onChangeCliente} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioId' required title='Domicilio instalación'>
                  <SelectDomicilioCliente
                    value={domicilioId}
                    name='domicilioId'
                    onChange={(value) => onSelect('domicilioId', value)}
                    options={domicilios}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioClienteId' required title='Domicilio cliente'>
                  <SelectDomicilioCliente
                    value={domicilioClienteId}
                    name='domicilioClienteId'
                    onChange={(value) => onSelect('domicilioClienteId', value)}
                    options={domicilios}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='contactoClienteId' required title='Contacto solicitante'>
                  <SelectContactoCliente
                    value={contacto}
                    name='contacto'
                    onChange={(value) => onSelect('contacto', value)}
                    options={contactos}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <h4>Diagnóstico preliminar</h4>
              </Col>
            </Row>
            <Row>
              <Col sm='3'>
                <DivFormGroup name='soluciones_solicitadas_id' required title='Soluciones solicitadas'>
                  <MultiSelectSolucionesSolicitadas
                    value={soluciones_solicitadas_id}
                    onChange={(value) => onSelect('soluciones_solicitadas_id', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='tipos_servicios_solicitados_id' required title='Tipos servicios solicitados'>
                  <MultiSelectTiposServiciosSolicitados
                    value={tipos_servicios_solicitados_id}
                    onChange={(value) => onSelect('tipos_servicios_solicitados_id', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='tipos_mano_obra_id' required title='Tipo de mano de obra'>
                  <SelectTipoManoDeObra
                    value={tipos_mano_obra_id}
                    name='tipos_mano_obra_id'
                    onChange={(value) => onSelect('tipos_mano_obra_id', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='tipos_cobro_equipo_id' required title='Tipo de cobro de equipo'>
                  <SelectTipoCobroEquipo
                    value={tipos_cobro_equipo_id}
                    name='tipos_cobro_equipo_id'
                    onChange={(value) => onSelect('tipos_cobro_equipo_id', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <h4>Detalles</h4>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='descripcion' title='Descripción corta'>
                  <Input name='descripcion' value={descripcion} placeholder='descripcion' onChange={onChange} />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='referencia' title='Referencia'>
                  <Input name='referencia' value={referencia} onChange={onChange} placeholder='Referencia' />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='observacion' title='Observación'>
                  <Input name='observacion' value={observacion} placeholder='Observacion' onChange={onChange} />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} variant='success' className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </form>
    );
  };

  const { isLoadingForm, showModalDownload } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Nueva orden de servicio'>
      {!isLoadingForm ? renderView() : <Loading />}
      <ModalImprimir showModalDownload={showModalDownload} closeDownload={closeDownload} agregarOtra={agregarOtra} onAceptar={onAceptar} />
    </Module>
  );
};

New.propTypes = {};

export default New;
