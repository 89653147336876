import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Modal, Button } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import moment from 'moment';

const Pdf = ({ ordenServicioId, visitaId }) => {
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    visitaId: '',
    ordenServicioId: '',
    fecha_hora: moment().format('DD/MM/YYYY HH:mm'),
    horas_servicio: 1,
    agentes_asignados: [],
    observaciones: '',
    agentes: [],
    agente_id: [],
    numero_orden_servicio: '',
    orden_servicio: null,
    calificacionId: null,
    alarma: false,
    camara: false,
    smart_home: false,
    control_acceso: false,
    savia: false,
    mejora_internet: false,
    aforo: false,
    certificacion_nodos: false,
    deteccion_humo: false,
    malo: 'MALO',
    regular: 'REGULAR',
    bueno: 'BUENO',
    si: 'SI',
    no: 'NO',
    limpieza: '',
    atencion: '',
    experiencia: '',
    alarma_funcional: '',
    comunicar_asesor_tecnico: '',
    recomendar_servicios: '',
    con_quien: '',
    actualizar: false,
  });

  const close = () => {
    setShowModal(false);
    setState({
      ...state,
      visitaId: '',
      ordenServicioId: '',
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
      horas_servicio: 1,
      observaciones: '',
      agentes: [],
      agente_id: [],
      visita: {},
    });
  };

  const open = () => {
    setShowModal(true);
    setState({ ...state, ordenServicioId, visitaId });
  };

  const onAceptar = async () => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/pdf/${visitaId}` });
    if (data) {
      setShowModal(false);
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      close();
    }
  };

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Descargar PDF</Tooltip>}>
        <Button onClick={open} className='btn btn-outline-warning' type='button'>
          <Icon icon='download-alt' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar orden de servicio</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ textAlign: 'center' }}>
          <Button onClick={close} variant='light' className='mr-5' type='button'>
            &nbsp;Cerrar&nbsp;
          </Button>
          <Button onClick={onAceptar} variant='primary' className='mr-5' type='button'>
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Pdf.propTypes = {
  ordenServicioId: PropTypes.string.isRequired,
  visitaId: PropTypes.string.isRequired,
  agentes: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Pdf;
