import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowQuotation from './RowQuotation';
import { Card, Col } from 'react-bootstrap';

const TableQuotations = ({ customer }) => {
  const renderTableQuotations = () => {
    const { _id, listQuotations } = customer;

    const rows = listQuotations.map((q, i) => {
      return <RowQuotation key={i} customerId={_id} quotation={q} />;
    });

    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='20%'>#</th>
            <th width='20%'>Vendedor</th>
            <th width='20%'>Fecha</th>
            <th width='20%'>Total</th>
            <th width='20%'></th>
          </tr>
        </thead>
        <tbody>{listQuotations.length > 0 ? rows : <RowsNotFound colSpan={5} />}</tbody>
      </table>
    );
  };

  if (!customer || (customer.listQuotations && customer.listQuotations.length === 0)) return null;
  const tableQuotations = renderTableQuotations();

  return (
    <Col>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon icon='check' /> Cotizaciones
          </Card.Title>
        </Card.Header>
        {tableQuotations}
      </Card>
    </Col>
  );
};

TableQuotations.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableQuotations;
