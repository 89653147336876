import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Button, ButtonGroup, DropdownButton, DropdownItem, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DetailCustomer from '@components/customers/DetailCustomer';
import Movimientos from './Movimientos';
import IconDropdown from '@controls/IconDropdown';

const Table = ({ list, loadData }) => {
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [idPedido, setIdPedido] = useState(null);

  const onClickDownloadPdf = (id) => {
    setShowModalDownload(true);
    setIdPedido(id);
  };

  const close = () => {
    setShowModalDownload(false);
  };

  const onAceptar = async (event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `pedido/pdf/${idPedido}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      close();
    }
  };

  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha, numero_pedido, sucursal, almacen, customer, estatus_pedido, agente_solicitante, tipoPedidoId, levantamientoId } = r;
      const badgeClasses = {
        CANCELADO: 'danger',
        ENTREGADO: 'info',
        FINALIZADO: 'secondary',
        EN_PROCESO: 'success',
      };
      const type = badgeClasses[estatus_pedido] || 'primary';
      const labelEstado = <CbBadge type={type}>{estatus_pedido}</CbBadge>;
      return (
        <tr key={i}>
          <td>{tipoPedidoId?.nombre}</td>
          <td>{numero_pedido}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          <td>{sucursal.name}</td>
          <td>{almacen.name}</td>
          <td>
            <DetailCustomer customer={customer} />
          </td>
          <td>{agente_solicitante.nombre}</td>
          <td>{labelEstado}</td>
          <td>
            <ButtonGroup>
              <If condition={estatus_pedido !== 'ENTREGADO' && estatus_pedido !== 'CANCELADO'}>
                <RedirectTooltip id={1} icon='edit' url={`/pedidos/edicion/${_id}`} labelTooltip='Editar' className='btn btn-outline-primary' />
              </If>
              <OverlayTrigger placement='top' overlay={<Tooltip id={`${_id}-download`}>Descargar PDF</Tooltip>}>
                <Button onClick={() => onClickDownloadPdf(_id)} className='btn btn-outline-primary'>
                  <Icon icon='download-alt' />
                </Button>
              </OverlayTrigger>
              <DropdownButton title={<IconDropdown />} as={ButtonGroup}>
                <If condition={estatus_pedido !== 'CANCELADO'}>
                  <Movimientos id={1} className='btn-sm mr-5' pedidoId={_id} />
                </If>
                <DropdownItem href={`/pedidos/detalle/${_id}`}>
                  <Icon icon='search' /> Detalles
                </DropdownItem>
                <If condition={levantamientoId && estatus_pedido !== 'CANCELADO'}>
                  <If.Then>
                    <DropdownItem href={`/cotizaciones/${levantamientoId}`}>
                      <Icon icon='file' /> Cotizaciones
                    </DropdownItem>
                  </If.Then>
                </If>
                <If condition={estatus_pedido !== 'CANCELADO' && estatus_pedido !== 'ENTREGADO'}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeConfirm='danger'
                      btnType='danger'
                      action='Cancelar pedidos'
                      isMenu
                      url={`pedido/cancelar/${_id}`}
                      description={`¿Confirma que desea cancelar el <strong>pedido: <u>#${numero_pedido}</u></strong> ?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th width='10%'>Tipo</th>
            <th width='10%'># Pedido</th>
            <th width='13%'>Fecha</th>
            <th width='10%'>Sucursal</th>
            <th width='10%'>Almacén</th>
            <th width='10%'>Cliente</th>
            <th width='10%'>Solicitante</th>
            <th width='10%'>Estatus</th>
            <th width='10%'>&nbsp;</th>
          </tr>
        </thead>
        <CbTableBody colSpan={9}>{renderRows()}</CbTableBody>
      </CbPanelTableResponsive>
      <Modal show={showModalDownload} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar pedido</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ textAlign: 'center' }}>
          <Button onClick={close} variant='light' className='mr-5' type='button'>
            &nbsp;Cerrar&nbsp;
          </Button>
          <Button onClick={onAceptar} variant='primary' className='mr-5' type='button'>
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
