import React from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from '@stateless';
import moment from 'moment';
import AsignarPeriodosVacaciones from './AsignarPeriodosVacaciones';
import { Card } from 'react-bootstrap';

const List = ({ list, load, onClickPrimaVacacional }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const {
        numero_empleado,
        nombre_completo,
        grupoEmpleadoId,
        puestoId,
        fecha_ingreso,
        antiguedad,
        dias_correspondientes,
        dias_adicionales,
        dias_solicitados,
        dias_tomados,
        prima_vacacional,
        estatus,
      } = row;
      return (
        <tr key={item}>
          <td>
            <b>{numero_empleado}</b>
          </td>
          <td>{nombre_completo}</td>
          <td>{grupoEmpleadoId ? grupoEmpleadoId.nombre : ''}</td>
          <td>{puestoId ? puestoId.nombre : ''}</td>
          <td>{moment(fecha_ingreso).format('DD-MM-YYYY')}</td>
          <td>{antiguedad}</td>
          <td>
            <input type='checkbox' checked={prima_vacacional} onClick={() => onClickPrimaVacacional(item, row)} />
          </td>
          <td>{dias_adicionales}</td>
          <td>{dias_correspondientes}</td>
          <td>{dias_solicitados}</td>
          <td>{dias_tomados}</td>
          <td className='with-actions'>
            {estatus}
            <span className='actions'>
              <AsignarPeriodosVacaciones {...row} dias_ya_solicitados={row.dias_solicitados} load={load} />
            </span>
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={12} />;

  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th rowSpan='2' width='5%'>
                # Empleado
              </th>
              <th rowSpan='2' width='20%'>
                Nombre completo
              </th>
              <th rowSpan='2' width='15%'>
                Grupo empleado
              </th>
              <th rowSpan='2' width='15%'>
                Puesto
              </th>
              <th rowSpan='2' width='10%'>
                Fecha de ingreso
              </th>
              <th rowSpan='2' width='10%'>
                Antiguedad
              </th>
              <th rowSpan='2' width='10%'>
                Prima vacacional
              </th>
              <th colSpan='4' style={{ textAlign: 'center' }}>
                Días
              </th>
              <th rowSpan='2' width='10%'>
                Estatus
              </th>
            </tr>
            <tr>
              <th width='10%'>Adeudados</th>
              <th width='10%'>Correspondientes</th>
              <th width='10%'>Solicitados</th>
              <th width='10%'>Tomados</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </Card>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
  onClickPrimaVacacional: PropTypes.func.isRequired,
};

export default List;
