import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';
import InputNumber from '@components/forms/InputNumber';
import { Button } from 'react-bootstrap';

const RowServicio = ({ servicio, medidas, onChangeServicio, onRemoveItemServicio }) => {
  const loadOptions = useCallback(async (inputValue, callback) => {
    const { data } = await getRequest({ url: `services-autocomplete`, params: { query: inputValue } });
    callback(data);
  }, []);

  const handleServicioChange = (itemSelect) => {
    onChangeServicio({
      servicioId: itemSelect,
    });
  };

  const handleMeasureChange = (itemSelect) => {
    onChangeServicio({
      measureId: itemSelect,
    });
  };

  const handleServicioInputChange = (key, e) => {
    const { value } = e.target;
    onChangeServicio({
      [key]: value,
    });
  };

  return (
    <tr>
      <td>
        <AsyncSelect
          value={servicio.servicioId}
          loadOptions={loadOptions}
          onChange={handleServicioChange}
          getOptionLabel={(x) => `${x.code} ${x.name}`}
          getOptionValue={(x) => x._id}
          isClearable
          placeholder='Buscar...'
        />
      </td>
      <td>
        <Select
          value={servicio.measureId}
          placeholder='Selecciona opciones'
          options={medidas}
          getOptionLabel={(e) => e.measure}
          getOptionValue={(x) => x._id}
          onChange={handleMeasureChange}
          className='basic-multi-select'
          classNamePrefix='select'
        />
      </td>
      <td>
        <InputNumber
          placeholder='Cantidad'
          className='form-control'
          onChange={(e) => handleServicioInputChange('cantidad', e)}
          value={servicio.cantidad}
        />
      </td>
      <td>
        <Button onClick={onRemoveItemServicio} variant='danger' className='pull-right' type='button'>
          <Icon icon='trash' />
        </Button>
      </td>
    </tr>
  );
};

RowServicio.propTypes = {
  servicio: PropTypes.object,
  medidas: PropTypes.array,
  onChangeServicio: PropTypes.func,
  onRemoveItemServicio: PropTypes.func,
};

export default RowServicio;
