import PropTypes from 'prop-types';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';

const ButtonUp = ({ id, position = 'left', callback }) => {
  const handleClick = () => {
    callback();
  };

  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id={id}>Subir de posición</Tooltip>}>
      <Button variant='success' className={`pull-${position}`} type='button' onClick={handleClick}>
        <Icon icon='arrow-up' />
      </Button>
    </OverlayTrigger>
  );
};

ButtonUp.propTypes = {
  id: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  position: PropTypes.string,
};

export default ButtonUp;
