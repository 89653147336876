import { DivFormGroup, FilterSelectNuevo, FormInput, Label } from '@controls';
import TextArea from '@controls/TextArea';
import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const NuevoMensaje = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [state, setState] = useState({
    receptor: null,
    receptorName: '',
    receptorObj: null,
    para: '',
    asunto: '',
    mensaje: '',
    showModal: false,
    isLoading: false,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {};

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  const open = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      isLoading: false,
      emisor: {
        id: null,
        name: null,
        email: null,
      },
    }));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { receptor, asunto, mensaje } = state;
    const data = {
      receptor: receptor._id,
      asunto,
      mensaje,
    };

    if (validations(data)) {
      const { data } = await postRequest({ url: `user/messages`, body: data });
      close();
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.receptor + '')) {
      validate.success = false;
      validate.message = 'Para es requerida.';
    } else if (isEmpty(params.asunto + '')) {
      validate.success = false;
      validate.message = 'Asunto es requerida.';
    } else if (isEmpty(params.mensaje + '')) {
      validate.success = false;
      validate.message = 'Mensaje es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangeReceptorInput = (value) => {
    setState((prevState) => ({ ...prevState, receptorName: value }));
  };

  const onChangeReceptor = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        receptor: itemSelect,
        receptorObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        receptorName: '',
        receptor: null,
        receptorObj: null,
      }));
    }
  };

  const { showModal, isLoading, asunto, mensaje, receptorObj, receptorName } = state;
  return (
    <>
      <a onClick={open} className='btn btn-block btn-default'>
        <Icon icon='send' /> Nuevo Mensaje
      </a>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleRegister}>
            <Row>
              <Col sm='12'>
                <DivFormGroup>
                  <Label name='clienteName' required title='Para' />
                  <FilterSelectNuevo
                    async={true}
                    value={receptorObj}
                    inputValue={receptorName}
                    onInputChange={onChangeReceptorInput}
                    onChangeOption={onChangeReceptor}
                    url={`users-autocomplete`}
                    fields={['_id', 'name']}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <FormInput title='Asunto' name='asunto' required onChange={onChange} value={asunto} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup>
                  <Label name='mensaje' title='Mensaje' required />
                  <TextArea name='mensaje' onChange={onChange} rows={5} value={mensaje}></TextArea>
                </DivFormGroup>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleRegister} variant='primary' className='pull-right' disabled={isLoading}>
            Enviar
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NuevoMensaje.propTypes = {};

export default NuevoMensaje;
