import { postRequest } from '@utils/api';
import IfPermission from '@controls/IfPermission';
import { CbPagination } from '@controls';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {  Loading, Module } from '@stateless';
import New from './New';
import List from './List';
import { FileUpIcon } from 'lucide-react';

const EstadosCuentas = () => {
  const [estadoCuenta] =useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: `/customers/estados-cuentas/search`, params: { page, limit: pagination }, body });
    setData(data);
    setCount(count);
    setLoading(false);
  }

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const onSearch = (params = {search: ''}) => {
    setSearch(params.search);
    setActivePage(1);
  };
 

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };
  const renderList = () => {
    return <List data={data} loadData={handleSearch}/>;
  }
  const moduleList = !loading ? renderList() : <Loading/>;
  return (
    <Module title='Estado de cuenta'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-6' blockSecond='col-sm-6'>
        <Row>
          <Col sm='6'>
            <Link to='/estados-cuentas/importar' className='btn btn-success pull-right mr-5'>
              <FileUpIcon /> <span className='hidden-xs'>Importar</span>
            </Link>
            <IfPermission action='crear'>
          <New loadData={handleSearch} estadoCuenta={estadoCuenta}/>
        </IfPermission>
          </Col>
        </Row>
      </Search>
      <Row>
        <Col>
          <br />
          {moduleList}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};
EstadosCuentas.propsTypes = {};

export default EstadosCuentas;
