import { RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import { Card } from 'react-bootstrap';

const Table = ({ list, desde, hasta }) => {
  const renderRows = () => {
    return list.map((r, i) => {
      const {
        facturas,
        saldo,
        importe,
        _id: { _id, nombre },
      } = r;
      return (
        <tr key={i}>
          <td>{nombre}</td>
          <td>{facturas}</td>
          <td>$ {format(importe, 2)}</td>
          <td>$ {format(importe - saldo, 2)}</td>
          <td>$ {format(saldo, 2)}</td>
          <td>
            <RedirectTooltip
              id={1}
              icon='search'
              url={`/asignaciones/detalle/${_id}?desde=${desde}&hasta=${hasta}`}
              labelTooltip='Completa'
              className='btn btn-sm btn-primary pull-right'
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <Card>
      <CbTableResponsive>
        <thead>
          <tr>
            <th width='40%'>Agente</th>
            <th width='10%'>Facturas</th>
            <th width='10%'>Importe</th>
            <th width='10%'>Pagado</th>
            <th width='10%'>Saldo</th>
            <th width='5%'></th>
          </tr>
        </thead>
        <CbTableBody colSpan={6}>{renderRows()}</CbTableBody>
      </CbTableResponsive>
    </Card>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  desde: PropTypes.array.isRequired,
  hasta: PropTypes.array.isRequired,
};

export default Table;
