import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreRegimenesFiscales } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const SelectRegimenFiscal = ({ onChange, name, value, filter, styles }) => {
  const { data, execute } = useStoreRegimenesFiscales();
  useEffect(() => {
    execute();
  }, []);
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      styles={styles}
      placeholder='Seleccione'
      options={data.filter((x) => (filter ? filter(x) : true))}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => `${x.codigo} - ${x.descripcion}`}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
    />
  );
};

SelectRegimenFiscal.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  filter: PropTypes.func,
  styles: PropTypes.object,
};

export default SelectRegimenFiscal;
