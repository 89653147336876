import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import RowsSurtidoCarros from './RowsSurtidoCarros';

const TableSurtidoCarros = ({ surtidocarros }) => {
  const renderRows = () => {
    return surtidocarros.map((c, i) => <RowsSurtidoCarros key={i} {...c} />);
  };

  const rows = surtidocarros.length > 0 ? renderRows() : <RowsNotFound message='Aún no hay recepciones.' colSpan={5} />;

  return (
    <table className='table table-striped align-middle'>
      <thead>
        <tr>
          <th width='50%'>Vendedor</th>
          <th width='30%'>En carro</th>
          <th width='10%'>Fecha</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TableSurtidoCarros.propTypes = {
  surtidocarros: PropTypes.array.isRequired,
};

export default TableSurtidoCarros;
