import { DivFormGroup, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Col, InputGroup, Form } from 'react-bootstrap';
import { getRequest, postRequest } from '@utils/api';
import { isEmail, isEmpty, isInt, isLength, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';

const NORMAL = 'NORMAL';
const AGENTE = 'AGENTE';

const NewUser = ({ loadUsers }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    modules: [],
    showModal: false,
    isLoading: false,
    agenteId: null,
    rol: false,
    email: '',
    password: '',
    repassword: '',
    modulosAsignados: [],
    name: '',
    cellPhone: '',
  });

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      isLoading: false,
      email: '',
      password: '',
      repassword: '',
      agenteId: '',
      rol: false,
    }));
  };

  const open = async () => {
    const { data } = await getRequest({ url: `modules/submodules` });
    setState((prevState) => ({
      ...prevState,
      modules: data,
      modulosAsignados: data.map((mod) => ({
        _id: mod._id,
        name: mod.module,
        checked: false,
        submodulos: mod.submodules.map((subModule) => ({
          _id: subModule._id,
          name: subModule.name,
          path: subModule.path,
          checked: false,
          procesos: (subModule.processes || []).map((process) => ({
            _id: process._id,
            name: process.name,
            code: process.code,
            checked: false,
          })),
        })),
      })),
      showModal: true,
    }));
  };

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleRegisterUser = async (event) => {
    event.preventDefault();
    const { agenteId, rol, modulosAsignados, name, cellPhone, email, password, repassword } = state;
    const assinned = modulosAsignados
      .filter((x) => x.checked)
      .map((x) => ({
        module: x._id,
        submodules: x.submodulos
          .filter((y) => y.checked)
          .map((y) => ({ submodule: y._id, processes: y.procesos.filter((z) => z.checked).map((z) => z.code) })),
      }));

    const data = {
      rol: rol === true ? AGENTE : NORMAL,
      agenteId: agenteId?._id,
      email: email.trim(),
      password: password.trim(),
      repassword: repassword.trim(),
      name: name.trim(),
      cellPhone: cellPhone.trim(),
      assinned: JSON.stringify(assinned),
    };

    if (validations(data)) {
      await postRequest({ url: `users`, body: data });
      loadUsers();
      close();
    }
  };

  const selectedModule = (moduleSelected, e) => {
    const { checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      modulosAsignados: prevState.modulosAsignados.map((x) => {
        if (x._id === moduleSelected) {
          return {
            ...x,
            checked,
            submodulos: x.submodulos.map((y) => ({
              ...y,
              checked,
              procesos: y.procesos.map((z) => ({
                ...z,
                checked,
              })),
            })),
          };
        }
        return x;
      }),
    }));
  };

  const selectedSubModule = (moduleSelected, subModuleSelected, e) => {
    const { checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      modulosAsignados: prevState.modulosAsignados.map((x) => {
        if (x._id === moduleSelected) {
          return {
            ...x,
            checked: checked ? checked : x.checked,
            submodulos: x.submodulos.map((y) => {
              if (y._id === subModuleSelected) {
                return {
                  ...y,
                  checked,
                  procesos: y.procesos.map((z) => ({
                    ...z,
                    checked,
                  })),
                };
              }
              return y;
            }),
          };
        }
        return x;
      }),
    }));
  };

  const selectedProcess = (moduleSelected, subModuleSelected, processSelected, e) => {
    const { checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      modulosAsignados: prevState.modulosAsignados.map((x) => {
        if (x._id === moduleSelected) {
          return {
            ...x,
            checked: checked ? checked : x.checked,
            submodulos: x.submodulos.map((y) => {
              if (y._id === subModuleSelected) {
                return {
                  ...y,
                  checked: checked ? checked : y.checked,
                  procesos: y.procesos.map((z) => {
                    if (z._id === processSelected) {
                      return {
                        ...z,
                        checked,
                      };
                    }
                    return z;
                  }),
                };
              }
              return y;
            }),
          };
        }
        return x;
      }),
    }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const renderCheckModules = () => {
    const { modulosAsignados } = state;
    return modulosAsignados.map((mod, keyModule) => {
      const componentsLi = mod.submodulos.map((subModule, keySubModule) => (
        <li key={keySubModule}>
          <div className='checkbox'>
            <label>
              <input
                onClick={(e) => selectedSubModule(mod._id, subModule._id, e)}
                checked={subModule.checked}
                value={subModule._id}
                type='checkbox'
              />{' '}
              {subModule.name}
            </label>
          </div>
          <ul>
            {subModule.procesos.map((process, i) => (
              <label className='checkbox-inline' key={keySubModule + i}>
                <input type='checkbox' checked={process.checked} onClick={(e) => selectedProcess(mod._id, subModule._id, process._id, e)} />{' '}
                {process.name}
              </label>
            ))}
          </ul>
        </li>
      ));
      return (
        <ul key={keyModule}>
          <li>
            <div className='checkbox'>
              <label>
                <input onClick={(e) => selectedModule(mod._id, e)} checked={mod.checked} value={mod._id} type='checkbox' /> {mod.name}
              </label>
            </div>
            <ul>{componentsLi}</ul>
          </li>
        </ul>
      );
    });
  };

  const validations = (params) => {
    let success = true;
    let validate = { title: 'Información incorrecta', message: '' };

    if ((success && isEmpty(params.name)) || isEmpty(params.email) || isEmpty(params.password) || isEmpty(params.repassword)) {
      validate.title = 'Información incompleta';
      validate.message = 'Información incompleta: Nombre, Email, Password y Confirmar Password, son obligatorios.';
      success = false;
    }

    if (!isEmpty(params.cellPhone)) {
      if (!isLength(params.cellPhone + '', { min: 10, max: 10 }) || !isInt(params.cellPhone + '')) {
        validate.message = 'El campo celular no contiene un formato valido.';
        success = false;
      }
    }

    if (success && params.rol === AGENTE) {
      if (!isMongoId(params.agenteId + '')) {
        validate.message = 'El campo agente relacionado es requerido.';
        success = false;
      }
    }

    if (success && !isEmail(params.email + '')) {
      validate.message = 'El campo correo electrónico no contiene un formato válido.';
      success = false;
    }

    if (success && !isLength(params.password + '', { min: 8, max: 10 })) {
      validate.message = 'La contraseña debe contener min 8 y max 10 caracteres.';
      success = false;
    }

    if (success && !isLength(params.repassword + '', { min: 8, max: 10 })) {
      validate.message = 'La contraseña debe contener min 8 y max 10 caracteres.';
      success = false;
    }

    if (success && params.password !== params.repassword) {
      validate.message = 'Contraseñas no coinciden. Favor de verficar.';
      success = false;
    }

    const assinned = JSON.parse(params.assinned);

    if (success && assinned.length <= 0) {
      validate.message = 'Debe asignar permisos.';
      success = false;
    }

    assinned.map((mod) => {
      if (success && !isMongoId(mod.module + '')) {
        validate.message = 'Debe asignar permisos.';
        success = false;
      }

      if (success) {
        mod.submodules.map((sub) => {
          if (success && !isMongoId(sub.submodule + '')) {
            validate.message = 'Debe asignar permisos.';
            success = false;
          }
        });
      }
    });

    if (!success) {
      notification({ title: validate.title, message: validate.message, type: 'error' });
    }

    return success;
  };

  const { showModal, isLoading, agenteId, rol, email, password, repassword, name, cellPhone } = state;

  return (
    <>
      <CbButtonOver title='Nuevo' icon='plus' onClick={open} type='success' hiddenXs />
      <CbModal title='Agregar Usuario' show={showModal} onClose={close} onSave={handleRegisterUser} isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <label htmlFor='name'>Nombre</label>
              <Form.Control type='text'  name='name' placeholder='Nombre' value={name} onChange={onChange} />
            </DivFormGroup>
            <DivFormGroup>
              <label htmlFor='cellPhone'>Celular</label>
              <Form.Control type='text'  name='cellPhone' placeholder='Celular' value={cellPhone} onChange={onChange} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='rol' title='Agente' />
              <InputGroup>
                <InputGroup.Text>
                  <Form.Control type='checkbox' checked={rol} name='rol' onChange={onChange} />
                </InputGroup.Text>
                <SelectAgente name='agenteId' value={agenteId} disabled={!rol} onChange={(value) => onSelect('agenteId', value)} />
              </InputGroup>
            </DivFormGroup>
            <hr />
            <DivFormGroup>
              <label htmlFor='email'>Correo electrónico</label>
              <Form.Control
                type='email'
                name='email'
                placeholder='Correo electrónico'
                autoComplete='off'
                value={email}
                onChange={onChange}
              />
            </DivFormGroup>
            <DivFormGroup>
              <label htmlFor='password'>Contraseña</label>
              <Form.Control
                type='password'
                name='password'
                placeholder='Contraseña'
                autoComplete='off'
                value={password}
                onChange={onChange}
              />
              <small className='text-center'>Máximo 10 caracteres</small>
            </DivFormGroup>
            <DivFormGroup>
              <label htmlFor='repassword'>Confirmar contraseña</label>
              <Form.Control
                type='password'
                name='repassword'
                placeholder='Confirmar contraseña'
                value={repassword}
                onChange={onChange}
              />
            </DivFormGroup>
          </Col>
          <Col sm='6'>{renderCheckModules()}</Col>
        </Row>
      </CbModal>
    </>
  );
};

NewUser.propTypes = {
  loadUsers: PropTypes.func.isRequired,
};

export default NewUser;
