import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { getRequest, URL_FILES } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';

const ImprimirRecibo = ({ reciboId }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const [codigos, setCodigos] = useState(false);
  const [desgloseKits, setDesgloseKits] = useState(false);
  const [horasServicio, setHorasServicio] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const onClickDownloadPdf = () => {
    setShowModal(true);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    const booleanValue = !(value === 'true');
    if (name === 'codigos') setCodigos(booleanValue);
    if (name === 'desgloseKits') setDesgloseKits(booleanValue);
    if (name === 'horasServicio') setHorasServicio(booleanValue);
  };

  const handleAceptar = async (event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `recibos/pdf/${reciboId}`, params: { codigos, desgloseKits, horasServicio } });
    if (data) {
      setShowModal(false);
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };

  return (
    <>
      <CbButtonOver onClick={onClickDownloadPdf} icon='download-alt' title='Descargar PDF' type='warning' size='sm' />
      <CbModal show={showModal} onClose={close} onSave={handleAceptar} title='Descargar PDF' textSave='Descargar' isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <FormGroup className='inline'>
              <label>Con códigos</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='codigos' onChange={onChange} value={true} checked={!codigos} /> No
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='codigos' onChange={onChange} value={false} checked={codigos} /> Si
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col sm='4'>
            <FormGroup className='inline'>
              <label>Desglose de kits</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='desgloseKits' onChange={onChange} value={true} checked={!desgloseKits} /> No
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='desgloseKits' onChange={onChange} value={false} checked={desgloseKits} /> Si
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col sm='4'>
            <FormGroup className='inline'>
              <label>Con horas de servicio</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='horasServicio' onChange={onChange} value={true} checked={!horasServicio} /> No
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='horasServicio' onChange={onChange} value={false} checked={horasServicio} /> Si
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

ImprimirRecibo.propTypes = {
  reciboId: PropTypes.string.isRequired,
};

export default ImprimirRecibo;
