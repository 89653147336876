import { DivFormGroup, Input, Label } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InputPercentage from '@components/forms/InputPercentage';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';

const FormAgente = ({
  nombre,
  codigo,
  almacenes,
  sucursales,
  sucursalId,
  almacenId,
  maximo_descuento,
  recibe_pedidos,
  recibe_servicios,
  es_tecnico,
  ocupa_autorizacion,
  setState,
}) => {
  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    setState({
      nombre,
      codigo,
      almacenes,
      sucursales,
      sucursalId,
      almacenId,
      maximo_descuento,
      recibe_pedidos,
      recibe_servicios,
      es_tecnico,
      ocupa_autorizacion,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onSelect = (name, value) => {
    setState({
      nombre,
      codigo,
      almacenes,
      sucursales,
      sucursalId,
      maximo_descuento,
      recibe_pedidos,
      recibe_servicios,
      es_tecnico,
      ocupa_autorizacion,
      almacenId: name === 'sucursalId' ? null : almacenId,
      [name]: value,
    });
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <DivFormGroup>
            <Label name='codigo' title='Codigo' required />
            <Input name='codigo' onChange={onChange} placeholder='Nombre' value={codigo} />
          </DivFormGroup>
        </Col>
        <Col md={8}>
          <DivFormGroup>
            <Label name='nombre' title='Nombre' required />
            <Input name='nombre' onChange={onChange} placeholder='Nombre' value={nombre} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <DivFormGroup>
            <Label name='sucursalId' title='Sucursal' />
            <SelectSucursal
              name='sucursalId'
              value={sucursalId}
              onChange={(value) => onSelect('sucursalId', value)}
            />
          </DivFormGroup>
        </Col>
        <Col>
          <DivFormGroup>
            <Label name='almacenId' title='Almacén' />
            <SelectAlmacen
              name='almacenId'
              value={almacenId}
              onChange={(value) => onSelect('almacenId', value)}
              filter={(x) => x.sucursalId === sucursalId?._id}
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <DivFormGroup>
            <Label name='maximo_descuento' title='Máximo descuento(%)' />
            <InputPercentage name='maximo_descuento' value={maximo_descuento} onChange={onChange} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='checkbox'>
            <label className='control-label'>
              <input name='recibe_pedidos' type='checkbox' checked={recibe_pedidos} onChange={onChange} /> <strong>Recibe pedidos</strong>
            </label>
          </div>
        </Col>
        <Col>
          <div className='checkbox'>
            <label className='control-label'>
              <input name='recibe_servicios' type='checkbox' checked={recibe_servicios} onChange={onChange} /> <strong>Recibe servicios</strong>
            </label>
          </div>
        </Col>
        <Col>
          <div className='checkbox'>
            <label className='control-label'>
              <input name='es_tecnico' type='checkbox' checked={es_tecnico} onChange={onChange} /> <strong>Es tecnico</strong>
            </label>
          </div>
        </Col>
        <Col>
          <div className='checkbox'>
            <label className='control-label'>
              <input name='ocupa_autorizacion' type='checkbox' checked={ocupa_autorizacion} onChange={onChange} /> <strong>Ocupa autorización</strong>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
};

FormAgente.propTypes = {
  id: PropTypes.number.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  maximo_descuento: PropTypes.number.isRequired,
  sucursales: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  recibe_pedidos: PropTypes.bool.isRequired,
  recibe_servicios: PropTypes.bool.isRequired,
  es_tecnico: PropTypes.bool.isRequired,
  ocupa_autorizacion: PropTypes.bool.isRequired,
};

export default FormAgente;
