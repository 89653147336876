import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, DropdownButton, DropdownItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { isMongoId } from 'validator';
import { CbTableResponsive } from '@controls';
import CbBadge from '@cbcomponents/CbBadge';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableThSort from '@cbcomponents/CbTableThSort';
import ConvertProspectosClientes from '@pages/catalogs/prospectos/convert';
import DetailCustomer from '@components/customers/DetailCustomer';
import EnviarLevantamiento from './EnviarLevantamiento';
import FirmaCliente from './FirmaCliente';
import ImprimirLevantamiento from './ImprimirLevantamiento';
import IconDropdown from '@controls/IconDropdown';
import { useNavigate } from 'react-router-dom';
import TextCurrency from '@components/application/controls/TextCurrency';
import GeneradorReferencia from './referencia';

const ESTATUS_COTIZACIONES = {
  EN_PROCESO: {
    label: 'EN_PROCESO',
    type: 'primary',
    icon: 'info-sign',
  },
  PENDIENTE: {
    label: 'PENDIENTE',
    type: 'primary',
    icon: 'info-sign',
  },
  INICIADA: {
    label: 'INICIADA',
    type: 'primary',
    icon: 'info-sign',
  },
  ENVIADA: {
    label: 'ENVIADA',
    type: 'primary',
    icon: 'info-sign',
  },
  ACEPTADA: {
    label: 'ACEPTADA',
    type: 'primary',
    icon: 'info-sign',
  },
  DECLINADA: {
    label: 'DECLINADA',
    type: 'danger',
    icon: 'remove',
  },
  AUTORIZADA: {
    label: 'AUTORIZADA',
    type: 'primary',
    icon: 'ok',
  },
  FINALIZADA: {
    label: 'FINALIZADA',
    type: 'success',
    icon: 'ok',
  },
  CANCELADA: {
    label: 'CANCELADA',
    type: 'danger',
    icon: 'remove',
  },
  PAUSADA: {
    label: 'PAUSADA',
    type: 'primary',
    icon: 'info-sign',
  },
  REMISIONADA: {
    label: 'REMISIONADA',
    type: 'primary',
    icon: 'info-sign',
  },
  VENCIDA: {
    label: 'VENCIDA',
    type: 'danger',
    icon: 'info-sign',
  },
};

const List = ({ loadData, list, agenteId, sucursalId, orden, tipo }) => {
  const navigate = useNavigate();
  const renderRows = () => {
    return list.map((row, key) => {
      const {
        _id,
        numero_levantamiento,
        tipo_cliente,
        customer,
        estado,
        fecha,
        agente,
        sucursal,
        moneda,
        fecha_vencimiento,
        tipo_levantamiento,
        pedidoId,
        ordenServicioId,
        observaciones,
        ocupa_autorizacion,
        autorizada,
        autorizadorId,
        total,
      } = row;
      let labelEstado = (
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip id={1}>{ocupa_autorizacion && autorizada && autorizadorId ? autorizadorId.name : estado}</Tooltip>}
        >
          <CbBadge type={ESTATUS_COTIZACIONES[estado].type}>
            <span className='badge-label'>{estado}</span>
            <Icon style={{ marginLeft: '0.25rem ' }} icon={ESTATUS_COTIZACIONES[estado].icon} />
          </CbBadge>
        </OverlayTrigger>
      );
      const diasVencimiento = moment(fecha_vencimiento).local().diff(moment(), 'days');
      const estaVencida = diasVencimiento < 0;
      const porVencer = diasVencimiento > 0 && diasVencimiento < 6;
      return (
        <tr key={key}>
          <td>{numero_levantamiento}</td>
          <td>{tipo_levantamiento ? tipo_levantamiento.nombre : null}</td>
          <td>
            {moment(fecha).local().format('DD/MM/YYYY')}
            <span style={{ fontSize: '10px', fontWeight: 700, color: porVencer || estaVencida ? '#dc3545' : '#000' }}>
              {' '}
              <br /> Vence: {moment(fecha_vencimiento).local().format('DD/MM/YYYY')}
            </span>
          </td>
          <td>
            <DetailCustomer customer={customer} isCustomer={tipo_cliente === 'CLIENTE'} />
          </td>
          {!isMongoId(sucursalId + '') && <td>{sucursal ? sucursal.name : null}</td>}
          <td>
            <TextCurrency moneda={moneda}>{total}</TextCurrency>
          </td>
          {!isMongoId(agenteId + '') && <td>{agente ? agente.nombre : null}</td>}
          <td>
            {ocupa_autorizacion && !autorizada && (
              <CbBadge type='warning'>
                <Icon icon='alert' /> Requiere autorización
              </CbBadge>
            )}
            {labelEstado}
          </td>
          <td>{observaciones.length > 15 ? <span title={observaciones}>{observaciones.substring(0, 15)}...</span> : observaciones}</td>
          <td style={{ textAlign: 'right' }}>
            <ButtonGroup>
              <ImprimirLevantamiento id={key} numero_levantamiento={numero_levantamiento} levantamientoId={_id} />
              <IfPermission action='editar-finalizada'>
                <If condition={estado === 'FINALIZADA'}>
                  <If.Then>
                    <RedirectTooltip
                      id={key}
                      labelTooltip='Editar'
                      url={`/cotizaciones/edicion/${_id}`}
                      icon='edit'
                      className='btn btn-outline-success'
                    />
                  </If.Then>
                </If>
              </IfPermission>
              <If condition={estado !== 'CANCELADA' && estado !== 'FINALIZADA' && estado !== 'ACEPTADA' && !estaVencida}>
                <If.Then>
                  <IfPermission action='editar'>
                    <RedirectTooltip
                      id={key}
                      labelTooltip='Editar'
                      url={`/cotizaciones/edicion/${_id}`}
                      icon='edit'
                      className='btn btn-outline-success'
                    />
                  </IfPermission>
                </If.Then>
              </If>
              <DropdownButton variant='outline-primary' as={ButtonGroup} title={<IconDropdown />} pullRight>
                <DropdownItem href={`/cotizaciones/${_id}`}>
                  <Icon icon='zoom-in' /> Detalle
                </DropdownItem>
                <If condition={pedidoId}>
                  <If.Then>
                    <DropdownItem href={`/pedidos/edicion/${pedidoId}`}>
                      <Icon icon='equalizer' /> Pedido
                    </DropdownItem>
                  </If.Then>
                </If>
                <If condition={ordenServicioId}>
                  <If.Then>
                    <DropdownItem href={`/ordenes-servicio/editar/${ordenServicioId}`}>
                      <Icon icon='cog' /> Orden de servicio
                    </DropdownItem>
                  </If.Then>
                </If>
                <Confirmation
                  id={_id}
                  btnIcon='duplicate'
                  typeRequest='postRequest'
                  btnType='success '
                  isMenu
                  action='Clonar '
                  url={`cotizaciones/clonar/${_id}?fecha=${moment().local().format('YYYY-MM-DD')}`}
                  description={`¿Confirma que desea clonar la <strong>cotización: <u>#${numero_levantamiento}</u></strong>?`}
                  loadData={(data) => {
                    setTimeout(() => {
                      navigate(`/cotizaciones/edicion/${data._id}`);
                    }, 1000);
                  }}
                />
                <IfPermission action='sincronizar'>
                  <Confirmation
                    id={_id}
                    btnIcon='duplicate'
                    typeRequest='postRequest'
                    btnType='success'
                    isMenu
                    action='Sincronizar'
                    url={`cotizaciones/sincronizar/${_id}?fecha=${moment().local().format('YYYY-MM-DD')}`}
                    description={`¿Confirma que desea sincronizar la <strong>cotización: <u>#${numero_levantamiento}</u></strong>?`}
                    loadData={loadData}
                  />
                </IfPermission>
                <GeneradorReferencia refresh={loadData} id={_id} />
                <IfPermission action='autorizar'>
                  <If
                    condition={
                      estado !== 'CANCELADA' &&
                      estado !== 'DECLINADA' &&
                      estado !== 'FINALIZADA' &&
                      estado !== 'ENVIADA' &&
                      estado !== 'ACEPTADA' &&
                      estado !== 'AUTORIZADA' &&
                      !estaVencida
                    }
                  >
                    <If.Then>
                      <Confirmation
                        id={_id}
                        btnIcon='arrow-right'
                        isMenu
                        typeRequest='putRequest'
                        btnType='success '
                        action='Autorizar'
                        url={`cotizaciones/autorizar/${_id}`}
                        description={`¿Confirma que desea autorizar la <strong>cotización: <u>#${numero_levantamiento}</u></strong>?`}
                        loadData={loadData}
                      />
                    </If.Then>
                  </If>
                </IfPermission>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && estado !== 'FINALIZADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeRequest='putRequest'
                      btnType='danger '
                      isMenu
                      action='Cancelar '
                      url={`cotizaciones/cancelar/${_id}`}
                      description={`¿Confirma que desea cancelar la <strong>cotización: <u>#${numero_levantamiento}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && estado !== 'FINALIZADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='ok'
                      typeRequest='putRequest'
                      btnType='primary '
                      isMenu
                      action='Aceptada por cliente'
                      url={`cotizaciones/aceptar/${_id}`}
                      description={`¿Confirma que desea aceptar la <strong>cotización: <u>#${numero_levantamiento}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && estado !== 'FINALIZADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeRequest='putRequest'
                      btnType='warning '
                      isMenu
                      action='Rechazada por cliente'
                      url={`cotizaciones/declinar/${_id}`}
                      description={`¿Confirma que desea declinar la <strong>cotización: <u>#${numero_levantamiento}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && !estaVencida}>
                  <If.Then>
                    <EnviarLevantamiento {...row} id={_id} />
                  </If.Then>
                </If>
                <If
                  condition={
                    (estado !== 'CANCELADA' && estado !== 'DECLINADA' && estado !== 'FINALIZADA') ||
                    (estado === 'FINALIZADA' && (!pedidoId || !ordenServicioId))
                  }
                >
                  <If.Then>
                    <IfPermission action='convertir'>
                      <DropdownItem href={`/cotizaciones/convertir/${_id}`}>
                        <Icon icon='ok' /> Convertir a Pedido y Orden de S.
                      </DropdownItem>
                    </IfPermission>
                    <If condition={tipo_cliente === 'PROSPECTO'}>
                      <If.Then>
                        <ConvertProspectosClientes refresh={loadData} prospectoId={customer._id} />
                      </If.Then>
                    </If>
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && !estaVencida}>
                  <If.Then>
                    <FirmaCliente
                      {...row}
                      id={_id}
                      firma={row.firma_cliente}
                      levantamientoId={_id}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  const establecerOrden = (orden, tipo, campo) => {
    loadData({ page: 0, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
  };

  return (
    <CbTableResponsive>
      <thead>
        <tr>
          <CbTableThSort orden={orden} tipo={tipo} name='numero_levantamiento' onSort={establecerOrden} width='5%'>
            #
          </CbTableThSort>
          <CbTableThSort orden={orden} tipo={tipo} name='tipo_levantamiento' onSort={establecerOrden} width='10%'>
            Tipo
          </CbTableThSort>
          <CbTableThSort orden={orden} tipo={tipo} name='fecha' onSort={establecerOrden} width='10%'>
            Fecha
          </CbTableThSort>
          <CbTableThSort orden={orden} tipo={tipo} name='cliente' onSort={establecerOrden}>
            Cliente / Prospecto
          </CbTableThSort>
          {!isMongoId(sucursalId + '') && (
            <CbTableThSort orden={orden} tipo={tipo} name='sucursal' onSort={establecerOrden} width='10%'>
              Sucursal
            </CbTableThSort>
          )}
          <CbTableThSort orden={orden} tipo={tipo} name='total' onSort={establecerOrden} width='10%'>
            Total
          </CbTableThSort>
          {!isMongoId(agenteId + '') && (
            <CbTableThSort orden={orden} tipo={tipo} name='agente' onSort={establecerOrden} width='10%'>
              Agenda
            </CbTableThSort>
          )}
          <CbTableThSort orden={orden} tipo={tipo} name='estado' onSort={establecerOrden} width='10%'>
            Estatus
          </CbTableThSort>
          <CbTableThSort orden={orden} tipo={tipo} name='observaciones' onSort={establecerOrden} width='10%'>
            Observaciones
          </CbTableThSort>
          <th width='12%'>&nbsp;</th>
        </tr>
      </thead>
      <CbTableBody colSpan={11}>{renderRows()}</CbTableBody>
    </CbTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  agenteId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default List;
