import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { cleanNumber } from '@utils/formatter';
import InputNumber from '@components/forms/InputNumber';
import InputCurrency from '@components/forms/InputCurrency';
import InputPercentage from '@components/forms/InputPercentage';
import SelectUnidadMedida from '@components/forms/SelectUnidadMedida';
import SelectServicio from '@components/forms/SelectServicio';
import { Button } from 'react-bootstrap';

const RowDetalle = ({ servicio, onRemoveItemServicio, onChangeServicioDetalle }) => {
  const onChangeServicioInput = (campo, e) => {
    let { value } = e.target;
    if (campo === 'cantidad') value = cleanNumber(value);
    onChangeServicioDetalle({
      ...servicio,
      [campo]: value,
    });
  };

  const onSelectServicio = (itemSelect) => {
    onChangeServicioDetalle({
      ...servicio,
      servicio: itemSelect,
      descripcion: itemSelect.name,
    });
  };

  const onSelectUnidad = (itemSelect) => {
    onChangeServicioDetalle({
      ...servicio,
      unidad: itemSelect,
    });
  };

  return (
    <tr>
      <td>
        <DivFormGroup>
          <SelectServicio value={servicio.servicio} name='servicio' onChange={onSelectServicio} />
        </DivFormGroup>
      </td>
      <td>
        <FormInput
          title='Descripcion'
          name='descripcion'
          withoutLabel
          required
          onChange={(e) => onChangeServicioInput('descripcion', e)}
          value={servicio.descripcion}
        />
      </td>
      <td>
        <DivFormGroup>
          <SelectUnidadMedida value={servicio.unidad} name='unidad' onChange={onSelectUnidad} />
        </DivFormGroup>
      </td>
      <td>
        <DivFormGroup>
          <InputNumber placeholder='Cantidad' onChange={(e) => onChangeServicioInput('cantidad', e)} value={servicio.cantidad} />
        </DivFormGroup>
      </td>
      <td>
        <DivFormGroup>
          <InputCurrency placeholder='Precio' onChange={(e) => onChangeServicioInput('precio', e)} value={servicio.precio} />
        </DivFormGroup>
      </td>
      <td>
        <DivFormGroup>
          <InputPercentage placeholder='Descuento' onChange={(e) => onChangeServicioInput('descuento', e)} value={servicio.descuento} />
        </DivFormGroup>
      </td>
      <td>
        <Button onClick={onRemoveItemServicio} variant='danger' className='pull-right btn-sm' type='button'>
          <Icon icon='trash' />
        </Button>
      </td>
    </tr>
  );
};

RowDetalle.propTypes = {
  unidades: PropTypes.array.isRequired,
  servicio: PropTypes.object.isRequired,
  onRemoveItemServicio: PropTypes.func.isRequired,
  onChangeServicioDetalle: PropTypes.func.isRequired,
};

export default RowDetalle;
