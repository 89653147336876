import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { Icon, Saving } from '@stateless';
import { getRequest, postUploadRequest, urlPhoto } from '@utils/api';
import { useStoreNotification } from '@stores/catalogs.store';

const Evidencias = ({ id, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [imgSrcModal, setImgSrcModal] = useState('');
  const [showModalImg, setShowModalImg] = useState(false);
  const [isPdf, setIsPdf] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers-registros/${id}` });
    const documentos = [];
    for (let i = 0; i < data.documentos.length; i++) {
      const documento = data.documentos[i];
      const url = urlPhoto() + documento.url;
      const res = await fetch(url);
      const contentType = res.headers.get('Content-Type');
      const raw = await res.blob();
      documentos.push({
        imagePreviewUrl: urlPhoto() + documento.url,
        ...documento,
        file: new File([raw], url, { type: contentType }),
      });
    }
    setDocumentos(documentos);
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = { documentos };

    const form = new FormData();
    for (let i = 0; i < documentos.length; i++) {
      const evidencia = documentos[i];
      if (evidencia._id) {
        form.append('images_data', evidencia._id);
      } else {
        form.append('images', evidencia.file);
      }
    }
    if (!validations(data)) return;
    try {
      await postUploadRequest({ url: `customers-registros/documentos/${id}`, form });
      loadData();
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (params.documentos.length === 0) {
      validate.success = false;
      validate.message = 'Documentos son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChangedocumentos = (documentos) => {
    setDocumentos(
      documentos.map((x) => ({
        ...x,
        descripcion: x.descripcion || x.file.name,
      }))
    );
  };

  const closeModalImagen = () => {
    setShowModalImg(false);
    setImgSrcModal('');
  };

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip>Documentos</Tooltip>}>
        <Button onClick={open} variant='outline-primary' type='button'>
          <Icon icon='file' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Body>
                  <ImageUploading
                    multiple
                    onChange={onChangedocumentos}
                    value={documentos}
                    allowNonImageType={true}
                    acceptType={['jpeg', 'jpg', 'png', 'pdf']}
                    dataURLKey='imagePreviewUrl'
                  >
                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                      <div className='upload__image-wrapper row'>
                        <div {...dragProps} className={`image-item col-sm-${documentos.length > 0 ? 4 : 12}`}>
                          <div
                            style={{
                              minHeight: '150px',
                              position: 'relative',
                              cursor: 'pointer',
                              border: isDragging ? 'dashed 1px red' : 'dashed 1px black',
                            }}
                            onClick={onImageUpload}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                fontSize: '10px',
                                transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                              }}
                            >
                              <Icon icon='plus' style={{ fontSize: '4em' }} />
                              <br />
                              {isDragging ? 'Suelte la evidencia aquí porfavor' : 'Click aquí o suelte la evidencia aquí'}
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item col-sm-4' style={{ position: 'relative' }}>
                            <div className='image-item__btn-wrapper' style={{ position: 'absolute', top: '10px', right: '30px' }}>
                              <a
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageRemove(index);
                                }}
                              >
                                <Icon icon='trash' style={{ fontSize: '1.5em', color: 'red' }} />
                              </a>
                            </div>
                            {image.file.type === 'application/pdf' ? (
                              <img
                                src='/img/pdf.png'
                                alt=''
                                width='100%'
                                className='img-responsive'
                                onClick={() => {
                                  setShowModalImg(true);
                                  setImgSrcModal(image.imagePreviewUrl);
                                  setIsPdf(true);
                                }}
                              />
                            ) : (
                              <img
                                src={image.imagePreviewUrl}
                                alt=''
                                width='100%'
                                className='img-responsive'
                                onClick={() => {
                                  setShowModalImg(true);
                                  setImgSrcModal(image.imagePreviewUrl);
                                  setIsPdf(false);
                                }}
                              />
                            )}
                            <small>{image.descripcion}</small>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleRegister} variant='primary' className='pull-right' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size='large' show={showModalImg} onHide={closeModalImagen}>
        <Modal.Header closeButton>
          <Modal.Title>Evidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPdf ? <iframe width='100%' height='700px' src={imgSrcModal}></iframe> : <img className='img-responsive' src={imgSrcModal} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModalImagen} variant='light' className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Evidencias.propTypes = {
  id: PropTypes.string.isRequired,
  seguimientoId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Evidencias;
