import { DivFormGroup, RedirectTooltip } from '@controls';
import moment from 'moment';
import React, { useState, useRef } from 'react';
import { Button, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';

const New = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [csv, setCsv] = useState(null);
  const [valid, setValid] = useState(false);
  const [msg, setMsg] = useState('');
  const [progress, setProgress] = useState(0);
  const frm = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'csv') {
      setCsv(value);
    }
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();

    setCargando(true);
    setProgress(0);
    setCsv(null);
    setIsLoading(true);

    const form = new FormData(frm.current);
    const { csv, valid, msg } = await postUploadRequest({ url: 'preconciliar-csv', form });
    setCsv({
      ...csv,
      detalle: csv.detalle.map((x) => ({ ...x, collapsed: false })),
    });
    setValid(valid);
    setMsg(msg);
    setCargando(false);
    setProgress(0);
    setIsLoading(false);
  };

  const renderDataCSV = () => {
    const { detalle } = csv;
    let headers = [];
    if (detalle.length > 0) headers = Object.keys(detalle[0]);
    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12'>
          <Card>
            <Card.Body>
              {headers.map((e, i) => {
                return (
                  <Col key={i} xs='6'>
                    <span>
                      <strong>{e.label}</strong> {e.value}
                    </span>
                  </Col>
                );
              })}
            </Card.Body>
          </Card>
        </Col>
        <Col sm='12'>
          {detalle.length > 0 ? (
            detalle.map((d, i) => {
              const { No, Fecha, Concepto, Sucursal, Referencia, Codigo, Depositos, isReferencias, facturas, cliente, saldoFavor, collapsed } = d;
              if (isReferencias) {
                return (
                  <React.Fragment key={i}>
                    <table className='table table-striped align-middle table-bordered table-striped'>
                      <thead>
                        <tr>
                          <th width='5%'>No</th>
                          <th width='10%'>Fecha</th>
                          <th width='45%'>Concepto</th>
                          <th width='10%'>Sucursal</th>
                          <th width='10%'>Referencia</th>
                          <th width='10%'>Código</th>
                          <th width='10%'>Depósitos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={i} className={'success'}>
                          <td>{No}</td>
                          <td>{Fecha}</td>
                          <td>{Concepto}</td>
                          <td>{Sucursal}</td>
                          <td>
                            <span className='label label-success'>{Referencia}</span>
                          </td>
                          <td>{Codigo}</td>
                          <td>{Depositos}</td>
                        </tr>
                        <tr>
                          <td colSpan={7} style={{ padding: '0' }}>
                            <Col sm='7'>
                              <h4>
                                <small>
                                  <strong>
                                    <em>{renderNombreCliente(cliente)}</em>
                                  </strong>
                                </small>
                              </h4>
                            </Col>
                            <Col sm='3'>
                              <h4>
                                <span className='text-success'>Referencia bancaria {Referencia}</span>
                              </h4>
                            </Col>
                            <Col sm='2'>
                              <h4>
                                <CbBadge type={saldoFavor > 0 ? 'success' : 'secondary'}>$ {format(saldoFavor, 2)}</CbBadge>{' '}
                                <small>
                                  <em>Saldo a favor</em>
                                </small>
                              </h4>
                            </Col>
                            {collapsed && (
                              <table className='table table-striped align-middle'>
                                <thead>
                                  <tr>
                                    <th width='20%'>Factura</th>
                                    <th width='25%'>UUID</th>
                                    <th width='10%'>Fecha</th>
                                    <th width='15%'>Importe</th>
                                    <th width='15%'>Saldo anterior</th>
                                    <th width='15%'>Saldo nuevo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {facturas.length > 0 ? (
                                    facturas.map((r, ri) => {
                                      const { facturaId, serie, numero, uuid, fecha_original, importe, saldoOriginal, saldoNuevo } = r;
                                      const isPay = saldoNuevo === 0 ? true : false;

                                      let colorRowFactura = '';
                                      let colorSaldo = '';

                                      if (isPay) {
                                        colorRowFactura = 'success';
                                        colorSaldo = 'text-success';
                                      } else if (saldoNuevo < saldoOriginal) {
                                        colorRowFactura = 'warning';
                                        colorSaldo = 'text-warning';
                                      } else {
                                        colorSaldo = 'text-danger';
                                      }
                                      return (
                                        <tr key={`${i}-${ri}`} className={colorRowFactura}>
                                          <td>
                                            <strong>
                                              {serie}
                                              {numero}
                                            </strong>
                                          </td>
                                          <td>
                                            <small>{uuid}</small>
                                          </td>
                                          <td>{moment(fecha_original).local().format('DD/MM/YYYY HH:mm')}</td>
                                          <td>
                                            <strong>$ {format(importe, 2)}</strong>
                                          </td>
                                          <td>$ {format(saldoOriginal, 2)}</td>
                                          <td>
                                            <span className={colorSaldo}>$ {format(saldoNuevo, 2)}</span>
                                          </td>
                                          <td>
                                            <RedirectTooltip
                                              id={1}
                                              icon='search'
                                              url={`/facturas/detalle/${facturaId}`}
                                              labelTooltip='Completa'
                                              className='btn btn-sm btn-primary pull-right'
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <RowsNotFound colSpan={6} />
                                  )}
                                </tbody>
                              </table>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                );
              } else {
                return (
                  <table className='table table-striped align-middle table-bordered table-striped' key={i}>
                    <thead>
                      <tr>
                        <th width='5%'>No</th>
                        <th width='10%'>Fecha</th>
                        <th width='45%'>Concepto</th>
                        <th width='10%'>Sucursal</th>
                        <th width='10%'>Referencia</th>
                        <th width='10%'>Código</th>
                        <th width='10%'>Depósitos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={i} className='danger'>
                        <td>{No}</td>
                        <td>{Fecha}</td>
                        <td>{Concepto}</td>
                        <td>{Sucursal}</td>
                        <td>
                          <span className='label label-danger'>{Referencia}</span>
                        </td>
                        <td>{Codigo}</td>
                        <td>{Depositos}</td>
                      </tr>
                    </tbody>
                  </table>
                );
              }
            })
          ) : (
            <table className='table table-striped align-middle table-bordered table-striped'>
              <thead>
                <tr>
                  <th width='5%'>No</th>
                  <th width='10%'>Fecha</th>
                  <th width='45%'>Concepto</th>
                  <th width='10%'>Sucursal</th>
                  <th width='10%'>Referencia</th>
                  <th width='10%'>Código</th>
                  <th width='10%'>Depósitos</th>
                </tr>
              </thead>
              <tbody>
                <RowsNotFound colSpan={7} />
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <Module onClickBack={window.history.back} parent='Conciaciones' title='Conciliar'>
      <br />
      <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
        <Row>
          <Col sm='3'>
            <DivFormGroup>
              <div className='btn btn-default btn-file'>
                <Icon icon='paperclip' /> Archivo de conciliación
                <input type='file' name='csv' id='csv' onChange={onChange} />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='9'>
            <Button type='submit' className='btn  btn-success' disabled={cargando}>
              <Icon icon='random' /> Conciliar
            </Button>
            {isLoading ? <Saving action='Conciliando' /> : ''}
          </Col>
          <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
        </Row>
      </form>

      <form>{csv !== null ? renderDataCSV() : null}</form>
    </Module>
  );
};

New.propTypes = {};

export default New;
