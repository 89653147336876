import React, { useState } from 'react';
import { Confirmation, IfPermission } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ButtonGroup, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import Edit from './Edit';
import Evidencias from './Evidencias';
import New from './New';
import Pdf from './Pdf';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const ListadoVisitas = ({ visitas: initVisitas, numero_orden_servicio, agentes_asignados, horas, loadData, ordenServicioId, estatus_servicios }) => {
  const navigate = useNavigate();
  const { geolocation } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [visitas, setVisitas] = useState([]);

  const close = () => setShowModal(false);
  const open = () => {
    setShowModal(true);
    setVisitas(initVisitas);
  };

  const renderAgentes = (agentes_asignados) => {
    let agente_nombre = [];
    agentes_asignados.map((a, i) => {
      if (i > 0) {
        agente_nombre.push(<br key={'br' + i} />);
      }
      const { nombre } = a;
      agente_nombre.push(
        <CbBadge key={'span' + i} type={`secondary`}>
          {nombre}
        </CbBadge>
      );
    });
    return <Col xs={12}>{agentes_asignados.length > 0 ? agente_nombre : <span>Sin agentes asignados</span>}</Col>;
  };

  const renderRows = () => {
    return visitas.map((r, i) => {
      const { fecha_hora, horas_servicio, agentes_asignados, observaciones, numero_visita, _id, estatus, observacion, paso } = r;
      return (
        <tr key={i}>
          <td>{numero_visita}</td>
          <td>{moment(fecha_hora).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>{horas_servicio}</td>
          <td>{renderAgentes(agentes_asignados)}</td>
          <td>{observaciones}</td>
          <td>
            <CbBadge type={`primary`}>{paso || 'PENDIENTE'}</CbBadge>
          </td>
          <td>
            <CbBadge type={`primary`}>{estatus || 'PENDIENTE'}</CbBadge>
          </td>
          <td>
            <ButtonGroup>
              {observacion && (
                <>
                  {observacion.posicion_inicio && (
                    <>
                      <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Localizacion inicio</Tooltip>}>
                        <a
                          className='btn btn-success'
                          href={`https://maps.google.com/?q=${observacion.posicion_inicio.latitud},${observacion.posicion_inicio.longitud}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Icon icon='globe' />
                        </a>
                      </OverlayTrigger>{' '}
                      <br />
                    </>
                  )}
                  {observacion.posicion_fin && (
                    <>
                      <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Localizacion final</Tooltip>}>
                        <a
                          className='btn btn-success'
                          href={`https://maps.google.com/?q=${observacion.posicion_fin.latitud},${observacion.posicion_fin.longitud}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Icon icon='globe' />
                        </a>
                      </OverlayTrigger>
                    </>
                  )}
                </>
              )}
              <If condition={r.evidencias.length > 0}>
                <If.Then>
                  <Evidencias
                    visitaId={_id}
                    evidencias={r.evidencias}
                    comentarios={r.evidencias_comentarios}
                    ordenServicioId={ordenServicioId}
                    loadData={loadData}
                  />
                </If.Then>
              </If>
              <If condition={estatus === 'PENDIENTE' || estatus === 'PAUSADO'}>
                <If.Then>
                  <Confirmation
                    id={_id}
                    btnIcon='play'
                    typeConfirm='primary'
                    btnType='primary'
                    action='Iniciar visita'
                    url={`ordenes-servicios-visitas/iniciar/${ordenServicioId}/${_id}?latitude=${geolocation?.latitude}&longitude=${geolocation?.longitude}`}
                    description={`¿Confirma que desea iniciar la <strong>visita: <u> ${observaciones}</u></strong>?`}
                    loadData={(data) => {
                      navigate(`/ordenes-servicios-visitas/observaciones/${data._id}`);
                    }}
                  />
                </If.Then>
                <If.ElseIf condition={estatus != 'FINALIZADO' && estatus != 'INCONCLUSA'}>
                  <Confirmation
                    id={_id}
                    btnIcon='pause'
                    typeConfirm='secondary'
                    btnType='secondary'
                    action='Pausar visita'
                    url={`ordenes-servicios-visitas/pausar/${ordenServicioId}/${_id}?latitude=${geolocation?.latitude}&longitude=${geolocation?.longitude}`}
                    description={`¿Confirma que desea pausar la <strong>visita: <u> ${observaciones}</u></strong>?`}
                    loadData={loadData}
                    input='text'
                    inputAttributes={{ autocapitalize: 'off' }}
                    onClick={close}
                  />
                  <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Ir a orden</Tooltip>}>
                    <Link className='btn btn-success' to={`/ordenes-servicios-visitas/observaciones/${_id}`}>
                      <Icon icon='copy' />
                    </Link>
                  </OverlayTrigger>
                </If.ElseIf>
                <If.Else>
                  <IfPermission action='editar-finalizada'>
                    <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Ir a orden</Tooltip>}>
                      <Link className='btn btn-success' to={`/ordenes-servicios-visitas/observaciones/${_id}`}>
                        <Icon icon='copy' />
                      </Link>
                    </OverlayTrigger>
                  </IfPermission>
                </If.Else>
              </If>
              <Pdf {...r} id={_id} visitaId={_id} ordenServicioId={_id} loadData={loadData} />
              <If condition={estatus === 'PENDIENTE'}>
                <If.Then>
                  <Edit visitaId={_id} ordenServicioId={ordenServicioId} loadData={loadData} />
                  <Confirmation
                    id={_id}
                    btnIcon='remove'
                    typeConfirm='danger'
                    btnType='danger'
                    action='Eliminar visita'
                    url={`ordenes-servicios-visitas/eliminar/${ordenServicioId}/${_id}`}
                    description={`¿Confirma que desea eliminar la <strong>visita: <u> ${observaciones}</u></strong>?`}
                    loadData={loadData}
                  />
                </If.Then>
              </If>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  const rows = visitas.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <>
      <CbButtonOver onClick={open} icon='list' title='Ver detalles' type='secondary' />
      <CbModal
        show={showModal}
        title={
          <>
            Visitas de orden de servicio{' '}
            <strong>
              <u>#{numero_orden_servicio}</u>
            </strong>
          </>
        }
        onClose={close}
      >
        <div className='table-responsive'>
          <table className='table table-striped align-middle'>
            <thead>
              <tr>
                <th width='10%'># Visita</th>
                <th width='15%'>Fecha y hora</th>
                <th width='10%'>Horas de servicio</th>
                <th width='30%'>Agentes</th>
                <th width='40%'>Observaciones</th>
                <th>Paso</th>
                <th>Estatus</th>
                <th width='5%'>
                  <If condition={estatus_servicios !== 'FINALIZADA'}>
                    <If.Then>
                      <New agentes_asignados={agentes_asignados} ordenServicioId={ordenServicioId} horas={horas} loadData={loadData} />
                    </If.Then>
                  </If>
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </CbModal>
    </>
  );
};

ListadoVisitas.propTypes = {
  id: PropTypes.number.isRequired,
  numero_orden_servicio: PropTypes.string.isRequired,
  visitas: PropTypes.array,
  position: PropTypes.object,
  agentes_asignados: PropTypes.array,
  horas: PropTypes.array,
  loadData: PropTypes.func.isRequired,
  ordenServicioId: PropTypes.string.isRequired,
  estatus_servicios: PropTypes.string.isRequired,
};

export default ListadoVisitas;
