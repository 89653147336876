import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteTitulos from './DeleteTitulos';
import EditTitulos from './EditTitulos';

const ListTitulos = ({ catalogTitulos, ...props }) => {
  const renderRows = () => {
    return catalogTitulos.map((row, item) => {
      const { _id, abreviatura, titulo } = row;
      return (
        <tr key={item}>
          <td>
            <strong>{abreviatura}</strong>
          </td>
          <td>{titulo}</td>
          <td>
            <IfPermission action='crear'>
              <DeleteTitulos id={item} tituloId={_id} abreviatura={abreviatura} titulo={titulo} {...props} />
            </IfPermission>
            <IfPermission action='crear'>
              <EditTitulos id={item} tituloId={_id} abreviatura={abreviatura} titulo={titulo} {...props} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Abreviatura</th>
          <th width='60%'>Título</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListTitulos.propTypes = {
  catalogTitulos: PropTypes.array.isRequired,
};

export default ListTitulos;
