import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Button, DropdownItem, Modal } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';

const Contactos = ({ id }) => {
  const [showModal, setShowModal] = useState(false);
  const [actividadesAgentePorDia, setActividadesAgentePorDia] = useState([]);

  const close = () => setShowModal(false);

  const open = async () => {
    const { data } = await getRequest({ url: `customer/${id}/actividades_seguimientos` });
    const actividadesAgentePorDia = Object.values(
      data.reduce((a, f) => {
        const fecha = moment(f.created).format('YYYY-MM-DD');
        if (!a[fecha]) {
          a[fecha] = {
            fecha,
            actividades: [f],
          };
        } else {
          a[fecha].actividades.push(f);
        }
        return a;
      }, {})
    );
    setActividadesAgentePorDia(actividadesAgentePorDia);
    setShowModal(true);
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='time' /> Actividades
      </DropdownItem>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Actividades</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='timeline'>
            {actividadesAgentePorDia.map((x) => (
              <Fragment key={x.fecha}>
                <div key={x.fecha} className='time-label'>
                  <span className='bg-green'>{x.fecha}</span>
                </div>
                {x.actividades.map((y) => (
                  <div key={y._id}>
                    <Icon icon='time bg-blue fas'></Icon>
                    <div className='timeline-item'>
                      <span className='time'>
                        <Icon icon='time '></Icon> {moment(y.created).local().format('HH:mm')}
                      </span>
                      <h3 className='timeline-header'>
                        <a href='#'>{y.agenteId ? y.agenteId.nombre : ''}</a> {y.titulo}
                      </h3>
                      <div className='timeline-body'>{y.descripcion}</div>
                    </div>
                  </div>
                ))}
              </Fragment>
            ))}
            <div>
              <Icon icon='time bg-blue fas'></Icon>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} className='pull-right' type='button'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Contactos.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Contactos;
