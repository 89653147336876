import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { CULIACAN } from '@config/constants';
import { If } from '@controls';
import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Card, Col, DropdownButton, DropdownItem, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import EditVisita from './EditVisita';
import NewVisita from './NewVisita';
import Table from './Table';
import { DivFormGroup } from '@controls';
import CbCustomPagination from '@cbcomponents/CbCustomPagination';
import NewRapida from './NewRapida.js';
import { searchPosition } from '@utils/googleMaps';
import { ESTATUS_ORDENES } from '@config/constants';
import SelectTipoOrdenServicio from '@components/forms/SelectTipoOrdenServicio';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import usePagination from '@hooks/usePagination';
import { fetchAll } from '@services/ordenesServicios.service';

const containerStyle = {
  width: '100%',
  height: '70vh',
};

const OrdenesServicio = () => {
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [sucursal, setSucursal] = useState(null);
  const [tipoOrdenServicio, setTipoOrdenServicio] = useState(null);
  const [agente, setAgente] = useState(null);
  const [agenteAsignado, setAgenteAsignado] = useState(null);
  const [estatusOrdenServicio, setEstatusOrdenServicio] = useState([]);
  const [pagination, setPagination] = useState(50);
  const [list, setList] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const [resources, setResources] = useState([]);

  const [state, setState] = useState({
    showModal: false,
    enviados: false,
    inList: 'LISTA',
    ordenServicioId: null,
    visitaId: null,
    showModalNew: false,
  });
  const {
    datos,
    paginaActual,
    count,
    cargando: loading,
    cambiarPagina,
    setParams,
    refresh,
    orden,
  } = usePagination(fetchAll, pagination, 'numero_orden_servicio');
  useEffect(() => {
    setParams({
      search,
      fechaInicio,
      fechaFin,
      sucursalId: sucursal?._id || '0',
      agenteId: agente?._id || '0',
      estatus: estatusOrdenServicio.map((x) => x.estatus),
      tipoOrdenServicioId: tipoOrdenServicio ? tipoOrdenServicio._id : '0',
      agenteAsignadoId: agenteAsignado ? agenteAsignado._id : '0',
    });
  }, [fechaInicio, fechaFin, sucursal, agente, tipoOrdenServicio, estatusOrdenServicio, search, agenteAsignado]);

  const updateList = async (datos) => {
    const agentesData = datos.map((y) => y.agentes_asignados).flat();
    if (state.inList === 'MAPA') {
      for (let i = 0; i < datos.length; i++) {
        const x = datos[i];
        const address = `${x.domicilio_entrega?.calle} ${x.domicilio_entrega?.exterior} ${x.domicilio_entrega?.interior} ${x.domicilio_entrega?.colonia} ${x.domicilio_entrega?.municipio} ${x.domicilio_entrega?.estado}`;
        const datos = await searchPosition(address);
        if (datos) {
          datos[i].position = {
            lat: datos.data.location.lat,
            lng: datos.data.location.lng,
          };
          datos[i].ubicacion = datos.ubicacion;
        }
      }
    }
    const uniqueAgentes = Array.from(new Set(agentesData.map((a) => a._id)))
      .map((id) => agentesData.find((a) => a._id === id))
      .map((x) => ({ ...x, color: '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0') }));
    setResources(uniqueAgentes);
    setList(datos);
    const agenda = datos.reduce((a, orden) => {
      return [
        ...a,
        ...orden.visitas.map((visita) => {
          if (visita.agentes_asignados.length === 0) return null;
          const agentePrincipal = visita.agentes_asignados[0];
          const agentePrincipalData = uniqueAgentes.find((x) => x._id === agentePrincipal._id);
          return {
            visitaId: visita._id,
            resourceId: agentePrincipal?._id,
            ordenServicioId: orden._id,
            id: orden._id + '-' + visita._id,
            title: visita.agentes_asignados.map((x) => x.nombre).join(', ') + ' ' + (orden.referencia || ''),
            backgroundColor: agentePrincipalData?.color,
            start: visita.fecha_hora,
            end: moment(visita.fecha_hora).add(visita.horas_servicio, 'hours'),
            description: orden.cliente.razon_social,
          };
        }),
      ];
    }, []);
    setAgenda(agenda.filter((x) => x !== null));
  };

  useEffect(() => {
    updateList(datos);
  }, [datos]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setFechaInicio(params.fechaInicio);
    setFechaFin(params.fechaFin);
  };

  const handlePagination = (eventKey) => {
    cambiarPagina(eventKey);
  };

  const handleChangeList = (tipo) => {
    setState((prevState) => ({ ...prevState, inList: tipo }));
  };

  const handleEvents = (events) => {
    setState((prevState) => ({
      ...prevState,
      currentEvents: events,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      showModalNew: false,
      visitaId: null,
      ordenServicioId: null,
    }));
  };

  const handleEventClick = (clickInfo) => {
    const [ordenServicioId, visitaId] = clickInfo.event.id.split('-');
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      visitaId: visitaId,
      ordenServicioId: ordenServicioId,
    }));
  };

  const handleDateSelect = (selectInfo) => {
    setState((prevState) => ({
      ...prevState,
      showModalNew: true,
      fecha_hora: selectInfo.startStr,
    }));
  };

  const handleClickImprimir = async () => {
    const lista = list.filter((x) => x.seleccionada);
    for (let i = 0; i < lista.length; i++) {
      const orden = lista[i];
      for (let j = 0; j < orden.visitas.length; j++) {
        const visita = orden.visitas[j];
        const { data } = await getRequest({ url: `ordenes-servicios-visitas/pdf/${visita._id}` });
        if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
      }
    }
  };

  return (
    <Module title='Ordenes de servicio'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm={4}>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Tipos de pedidos' />
                </InputGroup.Text>
                <SelectTipoOrdenServicio value={tipoOrdenServicio} name='tipoOrdenServicio' onChange={(value) => setTipoOrdenServicio(value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm={4}>
            <IfPermission action='TodasSucursales'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='home' title='sucursales' />
                  </InputGroup.Text>
                  <SelectSucursal value={sucursal} name='sucursal' onChange={(value) => setSucursal(value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm={4}>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Estatus' />
                </InputGroup.Text>
                <Select
                  isMulti
                  value={estatusOrdenServicio}
                  name='estatusOrdenServicio'
                  options={ESTATUS_ORDENES}
                  onChange={(value) => setEstatusOrdenServicio(value)}
                  placeholder='Seleccione una opción'
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.estatus}
                  isClearable
                />
              </InputGroup>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <IfPermission action='TodosAgentes'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='user' title='Agentes' />
                  </InputGroup.Text>
                  <SelectAgente value={agente} name='agente' onChange={(value) => setAgente(value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm={4}>
            <IfPermission action='TodosAgentes'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='user' title='Agentes asignados' />
                  </InputGroup.Text>
                  <SelectAgente value={agenteAsignado} name='agenteAsignado' onChange={(value) => setAgenteAsignado(value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm={4}>
            <ButtonGroup className='pull-right'>
              <IfPermission action='crear'>
                <NewRapida onReload={refresh} />
              </IfPermission>
              <Link to='/ordenes-servicios-visitas' className='btn btn-warning'>
                Visitas
              </Link>
              <If condition={list.filter((x) => x.seleccionada).length > 0}>
                <If.Then>
                  <Button className='btn btn-success' onClick={handleClickImprimir}>
                    <Icon icon='print' /> Imprimir
                  </Button>
                </If.Then>
              </If>
              <DropdownButton
                title={
                  <span>
                    <Icon icon={state.inList === 'LISTA' ? 'th-list' : state.inList === 'CALENDARIO' ? 'th-large' : 'map-marker'} />
                  </span>
                }
                as={ButtonGroup}
              >
                <DropdownItem onClick={() => handleChangeList('LISTA')}>
                  <Icon icon='th-list' /> Lista
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeList('CALENDARIO')}>
                  <Icon icon='th-large' /> Calendario
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeList('MAPA')}>
                  <Icon icon='map-marker' /> Mapa
                </DropdownItem>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        {state.inList === 'LISTA' ? (
          <Table
            list={list}
            onChangeList={(list) => setState((prevState) => ({ ...prevState, list }))}
            loadData={refresh}
            orden={orden.campo}
            tipo={orden.direccion}
          />
        ) : state.inList === 'CALENDARIO' ? (
          <>
            <Card>
              <Card.Body>
                <FullCalendar
                  locales={[esLocale]}
                  locale='es'
                  defaultView='resourceTimeGridDay'
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,resourceTimeGridDay',
                  }}
                  events={agenda}
                  resources={resources}
                  initialView='resourceTimeGridDay'
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={true}
                  select={handleDateSelect}
                  eventClick={handleEventClick}
                  eventsSet={handleEvents}
                />
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <LoadScript googleMapsApiKey='AIzaSyD_Eg_re4nHLgp05E8bFCFnrDvI6EdteF0'>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                  lat: CULIACAN.LATITUDE,
                  lng: CULIACAN.LONGITUDE,
                }}
                zoom={10}
              >
                {list.map((x, i) => (
                  <Marker key={i} position={x.position} draggable={false} />
                ))}
              </GoogleMap>
            </LoadScript>
          </>
        )}
        {state.showModal && <EditVisita {...state} loadData={refresh} close={closeModal} />}
        {state.showModalNew && <NewVisita {...state} loadData={refresh} close={closeModal} />}
        <Row>
          <Col>
            <Card className='mt-3'>
              <Card.Footer>
                <CbCustomPagination
                  pagination={pagination}
                  activePage={paginaActual}
                  count={count}
                  onPagination={handlePagination}
                  onChangeItemsPerPagina={(porPagina) => setPagination(porPagina)}
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Loading>
    </Module>
  );
};

OrdenesServicio.propTypes = {};

export default OrdenesServicio;
