import React, {useEffect, useState } from 'react';
import { Module, Icon, Saving } from '@stateless';
import { useStoreSucursales, useStoreServicios } from '@stores/catalogs.store';
import Select from 'react-select';
import { getRequest, putRequest } from '@utils/api';
import { Button, Card, Table } from 'react-bootstrap';
import { DivFormGroup } from '@controls';
import InputCurrency from '@components/forms/InputCurrency';

const DestajosPage = () => {
    const { data: sucursales, execute: executeSucursales } = useStoreSucursales();
    const { data: servicios, execute: executeServicios } = useStoreServicios();
    const [servicio] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataId,setDataId] = useState(null);
    useEffect(() => {
        executeSucursales();
        executeServicios();
    }, []);

    useEffect(() => {
        loadData();
    }, [sucursales, servicios]);

    const loadData = async () => {
        setIsLoading(true);
        const { data } = await getRequest({ url: `configurations_destajos` });
        const servs = data?.servicios || []; 
        setData(servs.map(service => (
            {
                servicioId: service.servicioId._id,
                code: service.servicioId.code,
                name: service.servicioId.name,
                sucursales: service.sucursales.map(sucursal => ({
                    sucursalId: sucursal?.sucursalId?._id,
                    precio: sucursal.precio || 0,
                })),
            }
        )));
        setDataId(data?._id);
        setIsLoading(false);
    };

    const handleClickEliminar = (idx) => {
        const newData = data.filter((service, index) => index !== idx);
        setData(newData);
    }

    const handlePriceChange = (servicioId, sucursalId, value) => {
        const newData = data.map((service, idx) => {
            if (idx === servicioId) {
                return {
                    ...service,
                    sucursales: service.sucursales.map((sucursal, jdx) => {
                        if (jdx === sucursalId) {
                            return {
                                ...sucursal,
                                precio: value || 0,
                            };
                        }
                        return sucursal;
                    }),
                };
            }
            return service;
        });
        setData(newData);
    };

    const handleServiceChange = (selectedServices) => {
        if (!selectedServices) return;
        const newService = {
            servicioId: selectedServices._id,
            code: selectedServices.code,
            name: selectedServices.name,
            sucursales: sucursales.map(sucursal => {
                return {
                    sucursalId: sucursal._id,
                    precio: 0
                };
            }),
        };
        setData(prevData => ([...prevData, newService]));
    };

    const handleSave = async () => {
        setIsLoading(true);
        const body = {
            _id: dataId,
            servicios: data.map(service => ({
                servicioId: service.servicioId,
                sucursales: service.sucursales.map(sucursal => ({
                        sucursalId: sucursal.sucursalId,
                        precio: sucursal.precio,
                    })),
                }))
        };
        await putRequest({ url: `configurations_destajos`, body });
        loadData();
        setIsLoading(false);
    };

    return (
        <Module title='Configuración general' isLoading={isLoading}>
            <Card>
                <Card.Body>
                    <Card.Title>Destajos</Card.Title>
                    <DivFormGroup title="Servicios a tomar en cuenta">
                        <Select
                            value={servicio}
                            placeholder="Seleccione servicios"
                            onChange={handleServiceChange}
                            options={servicios.filter(s => !data.find(d => d.servicioId === s._id))}
                            isClearable
                            getOptionLabel={(option) => `${option.code} ${option.name}`}
                            getOptionValue={(option) => option._id}
                        />
                    </DivFormGroup>
                </Card.Body>
            </Card>
            <Card className="mt-3">
                <Table striped bordered hover className='align-middle'>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Servicio</th>
                            {sucursales.map(sucursal => (
                                <th key={sucursal.id}>{sucursal.name}</th>
                            ))}
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((service,idx) => (
                            <tr key={service.servicioId}>
                                <td>{service.code}</td>
                                <td>{service.name}</td>
                                {sucursales.map((sucursal,jdx) => {
                                    const datasucu = service.sucursales.find(s => s.sucursalId === sucursal._id)?.precio;
                                    return (
                                        <td key={sucursal._id}>
                                            <InputCurrency
                                                value={datasucu}
                                                onChange={(e) => handlePriceChange(idx, jdx, e.target.value)}
                                            />
                                        </td>
                                    );
                                })}
                                <td className="text-center">
                                    <Button variant='danger' onClick={()=>handleClickEliminar(idx)}><Icon icon="trash"/> </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>
            <Card className="mt-3">
                <Card.Body>
                    <Saving saving={isLoading} />
                    <Button variant='success' className='pull-right' type='button' onClick={handleSave}>
                        <Icon icon='save' /> Guardar
                    </Button>
                </Card.Body>
            </Card>
        </Module>
    );
};

export default DestajosPage;