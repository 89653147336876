import { DivFormGroup } from '@controls';
import React, { useState, useRef } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest, URL_FILES } from '@utils/api';

const Import = () => {
  const [state, setState] = useState({
    regimen: 'C',
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
  });

  const frm = useRef(null);

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setState({ ...state, cargando: true, progress: 0, csv: null, isLoading: true });
    const form = new FormData(frm.current);
    const { data } = await postUploadRequest({ url: `importar-estados-cuentas-csv`, form });
    setTimeout(() => {
      window.open(`${URL_FILES}${data.url}`, '_blank');
    }, 1000);
    setState({ ...state, cargando: false, progress: 0, isLoading: false });
  };

  const renderDataCSV = () => {
    const {
      csv: { detalle, headers },
      valid,
      msg,
    } = state;

    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12' style={{ position: 'relative', overflowX: 'auto', width: '100%' }}>
          <table className='table table-striped align-middle'>
            <thead>
              <tr>
                <th>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => (
                  <th key={i}>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detalle.length > 0 ? (
                detalle.map((d, i) => {
                  let datoCreacion = 0;
                  return (
                    <>
                      <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                        <td>
                          {d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}
                        </td>
                        {headers.map((e, j) => (
                          <td key={j}>
                            {d[j]}
                          </td>
                        ))}
                      </tr>
                    </>
                  );
                })
              ) : (
                <RowsNotFound colSpan={7} />
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const { cargando, isLoading, progress } = state;

  return (
    <Module onClickBack={window.history.back} parent='Estados de cuentas' title='Importar'>
      <br />
      <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
        <Row>
          <Col sm='3'>
            <DivFormGroup>
              <div className='btn btn-default btn-file'>
                <Icon icon='paperclip' /> Archivo de estado de cuenta
                <input type='file' name='csv' id='csv' accept='.csv,.xls,.xlsx' onChange={handleReadCSV} />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='9'>{isLoading ? <Saving action='Importantando' /> : ''}</Col>
          <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
        </Row>
      </form>
      {state.csv && renderDataCSV()}
    </Module>
  );
};

Import.propTypes = {};

export default Import;
