import { DirectionsRenderer, GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { CULIACAN } from '@config/constants';
import { DivFormGroup, Input, Label } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { OrdenServicioOption } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import { isEmpty } from 'validator';
import useAuth from '@hooks/useAuth';
import { searchPosition } from '@utils/googleMaps';
import { useStoreNotification } from '@stores/catalogs.store';
import MultiSelectAgentes from '@components/forms/MultiSelectAgentes';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const Edit = ({ fecha_hora, loadData, close, agentesAsignados, showModalNew, fecha_hora_fin }) => {
  const { addNotification: notification } = useStoreNotification();
  const { geolocation } = useAuth();
  const [state, setState] = useState({
    visitaId: '',
    ordenServicioId: null,
    ordenServicio: null,
    fecha_hora: moment().format('DD/MM/YYYY HH:mm'),
    fecha_hora_fin: moment().add(1, 'hours').format('YYYY-MM-DD HH:mm'),
    horas_servicio: 1,
    agentes_asignados: [],
    observaciones: '',
    agentes: [],
    directions: [],
    domicilios: [],
    agente_id: [],
    showModal: false,
  });

  useEffect(() => {
    open();
  }, [showModalNew]);

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      visitaId: '',
      ordenServicioId: '',
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: moment().add(1, 'hours').format('YYYY-MM-DD HH:mm'),
      horas_servicio: 1,
      observaciones: '',
      agentes: [],
      agente_id: [],
      visita: {},
    }));
    close();
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const onSelectOrdenServicio = async (value) => {
    if (value) {
      setState((prevState) => ({ ...prevState, ordenServicioId: value }));
      establecerDomicilios(value._id);
    } else {
      setState((prevState) => ({
        ...prevState,
        domicilios: [],
        directions: [],
        ordenServicioId: null,
      }));
    }
  }
      

  const establecerDomicilios = async (ordenServicioId) => {
    const responseOrdenServicio = await getRequest({ url: `ordenes-servicio/${ordenServicioId}` });
    const domicilios = [];
    const listDomicilios = [responseOrdenServicio.data.domicilio_entrega];
    for (let i = 0; i < listDomicilios.length; i++) {
      const x = listDomicilios[i];
      let ubicacionActual = x.tiene_posicion ? x.ubicacion : '';
      let posicionActual = x.tiene_posicion ? { lat: x.latitud, lng: x.longitud } : '';
      if (!x.tiene_posicion) {
        const address = `${x.calle} ${x.exterior} ${x.interior} ${x.colonia} ${x.municipio} ${x.estado}`;
        const { data, ubicacion } = await searchPosition(address);
        ubicacionActual = ubicacion;
        posicionActual = { lat: data.location.lat, lng: data.location.lng };
        await postRequest({
          url: `/customers/${responseOrdenServicio.data.cliente._id._id}/domicilios/posicion/${x._id}`,
          body: { location: data.location, ubicacion },
        });
      }
      domicilios.push({
        position: posicionActual,
        address: (
          <div>
            {x.calle} {x.exterior} {x.interior},
            <br />
            Colonia {x.colonia} C.P. {x.codigo_postal}
            <br />
            {x.municipio} {x.estado}, {x.pais}
          </div>
        ),
        name: x.nombre,
        ubicacion: ubicacionActual,
      });
    }
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    const directions = [];
    let ubicacionAnterio = '';
    for (let i = 0; i < domicilios.length; i++) {
      const { ubicacion } = domicilios[i];
      if (ubicacionAnterio) {
        const response = await directionsService.route({
          origin: ubicacionAnterio,
          destination: ubicacion,
          travelMode: 'DRIVING',
        });
        directions.push({ ...response });
      }
      ubicacionAnterio = ubicacion;
    }
    setState((prevState) => ({
      ...prevState,
      domicilios,
      directions: [...directions],
      showModal: true,
    }));
  };

  const open = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      fecha_hora_fin: moment(fecha_hora_fin).local().format('YYYY-MM-DD HH:mm'),
      agente_id: agentesAsignados || [],
      fecha_hora: moment(fecha_hora).local().format('YYYY-MM-DD HH:mm'),
    }));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddVisita = async () => {
    const { ordenServicioId, fecha_hora, fecha_hora_fin, horas_servicio, agente_id, observaciones } = state;
    const data = {
      fecha_hora: moment(fecha_hora).utc(),
      fecha_hora_fin: moment(fecha_hora_fin).utc(),
      horas_servicio,
      agente_id: agente_id.map((x) => x._id),
      observaciones,
    };
    if (validations(data)) {
      await postRequest({ url: `ordenes-servicios-visitas/agregar/${ordenServicioId._id}`, body: data });
      closeModal();
      loadData();
    }
  };

  const onLoad = async () => {
    const { domicilios } = state;
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    const directions = [];
    let ubicacionAnterio = '';
    for (let i = 0; i < domicilios.length; i++) {
      const { ubicacion } = domicilios[i];
      if (ubicacionAnterio) {
        const response = await directionsService.route({
          origin: ubicacionAnterio,
          destination: ubicacion,
          travelMode: 'DRIVING',
        });
        directions.push(response);
      }
      ubicacionAnterio = ubicacion;
    }
    setState((prevState) => ({ ...prevState, directions: [...directions] }));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    } else if (params.agente_id.length <= 0) {
      validate.success = false;
      validate.message = 'Agentes son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const loadOptions = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `ordenes-servicio/autocomplete-no-terminados`, params: { query: inputValue } });
    callback(data);
  };

  const onMarkerClose = () => {};
  const onMarkerClick = () => {};

  const { agente_id, fecha_hora: fechaHora, horas_servicio, observaciones, ordenServicioId } = state;

  return (
    <Modal size='large' show={showModalNew} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Nueva visita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='ordenServicioId' required title='Orden de servicio' />
              <AsyncSelect
                isClearable
                value={ordenServicioId}
                getOptionLabel={(e) =>
                  `ORDEN #${e.numero_orden_servicio} FECHA: ${moment(e.feche_solicitud).local().format('YYYY-MM-DD')} CLIENTE: ${e.cliente.razon_social} DOMICILIO: ${textDomicilio(e.cliente.domicilio)}`
                }
                getOptionValue={(e) => e._id}
                loadOptions={loadOptions}
                placeholder='Seleccione orden de servicio'
                noResultsText='No se encontraron resultados'
                onChange={(value) => onSelectOrdenServicio(value)}
                className='basic-multi-select'
                classNamePrefix='select'
                components={{ Option: OrdenServicioOption }}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <form>
          <Row>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='fecha_hora' title='Fecha iniciación' />
                <Input type='datetime-local' name='fecha_hora' onChange={onChange} value={fechaHora} />
              </DivFormGroup>
            </Col>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='horas_servicio' title='Horas de servicio' />
                <Input type='number' name='horas_servicio' onChange={onChange} value={horas_servicio} />
              </DivFormGroup>
            </Col>
            <Col sm='12'>
              <DivFormGroup>
                <Label name='observaciones' title='Observaciones' required />
                <textarea className='form-control' name='observaciones' onChange={onChange} value={observaciones} />
              </DivFormGroup>
            </Col>
            <Col sm='12'>
              <DivFormGroup>
                <Label name='agente_id' required title='Agentes' />
                <MultiSelectAgentes value={agente_id} onChange={(value) => onSelect('agente_id', value)} />
              </DivFormGroup>
            </Col>
          </Row>
        </form>
        <LoadScript googleMapsApiKey='AIzaSyD_Eg_re4nHLgp05E8bFCFnrDvI6EdteF0' onLoad={onLoad} libraries={['places']}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
              lat: geolocation?.latitude || CULIACAN.LATITUDE,
              lng: geolocation?.longitude || CULIACAN.LONGITUDE,
            }}
            zoom={11}
          >
            {state.directions.map((x, i) => (
              <DirectionsRenderer key={i} directions={x} />
            ))}
            {state.domicilios.map((x, i) => (
              <Marker key={i} title={x.title} position={x.position} draggable={false} onClick={() => onMarkerClick(x)}>
                {x.showInfo && (
                  <InfoWindow onCloseClick={() => onMarkerClose(x)}>
                    <div>
                      <strong>{x.name}</strong>
                      <div>{x.address}</div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </GoogleMap>
        </LoadScript>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} variant='light' className='pull-right mr-5' type='button'>
          Cerrar
        </Button>
         <Button onClick={handleAddVisita} type='button' variant='success' className='pull-right mr-5'>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Edit.propTypes = {
  fecha_hora: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  agentesAsignados: PropTypes.array.isRequired,
  showModalNew: PropTypes.bool.isRequired,
  fecha_hora_fin: PropTypes.string.isRequired,
};

export default Edit;
