import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import { isNumeric } from 'validator';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';

const NumberPicker = ({ name, min, max = Number.POSITIVE_INFINITY, size, defaultValue }) => {
  const [value, setValue] = useState(typeof defaultValue !== 'undefined' ? defaultValue : 0);
  let [message, setMessage] = useState('');

  const changeOriginalValue = (valueOriginal) => {
    if (typeof min === 'number' && typeof max === 'number') {
      if (max === Infinity) {
        setMessage = `min ${min}`;
      } else {
        setMessage = `min ${min} y max ${max}`;
      }
    }

    if (valueOriginal === null || valueOriginal === '') {
      setValue(valueOriginal);
      setMessage(message);
    } else {
      const value = parseInt(valueOriginal);

      if (isNumeric(value + '') && !isNaN(value)) {
        if (value >= min && value <= max) {
          setValue(value);
          setMessage('');
        } else {
          setMessage(message);
        }
      }
    }
  };

  const onDecrease = () => {
    if (typeof max === 'number') {
      if (value > min) {
        changeOriginalValue(value - 1);
      }
    } else {
      changeOriginalValue(value - 1);
    }
  };

  const onIncrease = () => {
    if (typeof min === 'number') {
      if (value < max) {
        isMore(value);
      }
    } else {
      isMore(value);
    }
  };

  const isMore = (value) => {
    if (isNaN(value)) {
      changeOriginalValue(1);
    } else {
      changeOriginalValue(parseInt(value) + 1);
    }
  };

  const onChange = (event) => {
    event.target.value.replace('.', null);
    changeOriginalValue(event.target.value.replace('.', null));
  };

  const onKeyUp = (event) => {
    setValue(event.target.value.replace('.', ''));
  };

  let controlsSize = {};

  if (typeof size !== 'undefined' && (size === 'lg' || size === 'sm')) {
    controlsSize = {
      btn: `btn-${size}`,
      input: `input-${size}`,
    };
  }

  return (
    <Row>
      <Col>
        <InputGroup>
          <Button onClick={onDecrease} className={`${controlsSize.btn}`} type='button'>
            <Icon icon='minus' />
          </Button>
          <input
            type='text'
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            className={`form-control ${controlsSize.input} text-center`}
            autoComplete='off'
          />
          <Button onClick={onIncrease} className={`${controlsSize.btn}`} type='button'>
            <Icon icon='plus' />
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
};

NumberPicker.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.number,
};

export default NumberPicker;
