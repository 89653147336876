import Label from '@controls/Label';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { putRequest } from '@utils/api';
import { isEmpty, isLength } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import { DivFormGroup } from '@controls';

const ResetPassword = ({ id, idUser, loadUsers }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  const close = (event) => {
    event && event.preventDefault();
    setShowModal(false);
    setIsLoading(false);
  };

  const open = () => {
    setShowModal(true);
  };

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      if (name === 'password') setPassword(checked);
      if (name === 'repassword') setRepassword(checked);
    } else {
      if (name === 'password') setPassword(value);
      if (name === 'repassword') setRepassword(value);
    }
  };

  const handleEditUser = async (event) => {
    event.preventDefault();
    const data = { password, repassword };

    if (validations(data)) {
      await putRequest({ url: `users/password/${idUser}`, body: data });
      loadUsers();
      close();
    }
  };

  const validations = (params) => {
    let validate = { message: '', success: true };
    if (isEmpty(params.password + '')) {
      validate = { message: 'Nueva password es requerido.', success: false };
    } else if (!isLength(params.password + '', { min: 8, max: 10 })) {
      validate = { message: 'Nueva password debe contener min 8 y max 10 caracteres.', success: false };
    } else if (isEmpty(params.repassword + '')) {
      validate = { message: 'Confirmar nueva password es requerido.', success: false };
    } else if (params.password !== params.repassword) {
      validate = { message: 'El campo "Confirmar nueva password" debe coincidir con el campo "Nueva password."', success: false };
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const tooltipEdit = <Tooltip id={id}>Restablecer contraseña</Tooltip>;

  return (
    <>
      <OverlayTrigger placement='top' overlay={tooltipEdit}>
        <Button onClick={open} className='btn btn-outline-warning' type='button'>
          <Icon icon='lock' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Restablecer contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row>
              <Col>
                <DivFormGroup>
                  <Label name='password' required>
                    Nueva password
                  </Label>
                  <input
                    type='password'
                    className='form-control'
                    name='password'
                    placeholder='Nueva password'
                    autoComplete='off'
                    value={password}
                    onChange={onChange}
                  />
                  <small className='text-center'>Debe contener entre 8 y 10 caracteres</small>
                </DivFormGroup>
                <DivFormGroup>
                  <Label name='repassword' required>
                    Confirmar nueva password
                  </Label>
                  <input
                    type='password'
                    className='form-control'
                    name='repassword'
                    placeholder='Confirmar nueva password'
                    autoComplete='off'
                    value={repassword}
                    onChange={onChange}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleEditUser} variant='success' className='pull-right' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ResetPassword.propTypes = {
  id: PropTypes.number.isRequired,
  idUser: PropTypes.string.isRequired,
  loadUsers: PropTypes.func.isRequired,
  pagination: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default ResetPassword;
