import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import RowSeguimientosPorAtender from './RowSeguimientosPorAtender';
import PropTypes from 'prop-types';

const TableSeguimientosPorAtender = ({ seguimientosPorAtender }) => {
  if (seguimientosPorAtender.length === 0) return null;
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>Seguimientos por atender</Card.Title>
          </Card.Header>
          <table className='table'>
            <thead>
              <tr>
                <th>Titulo</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Estatus</th>
              </tr>
            </thead>
            <tbody>
              {seguimientosPorAtender.map((a, i) => (
                <RowSeguimientosPorAtender key={i} seguimiento={a} />
              ))}
            </tbody>
          </table>
        </Card>
      </Col>
    </Row>
  );
};

TableSeguimientosPorAtender.propTypes = {
  seguimientosPorAtender: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      fecha: PropTypes.string.isRequired,
      customerId: PropTypes.shape({
        razon_social: PropTypes.string.isRequired,
      }),
      estatus: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TableSeguimientosPorAtender;
