import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { IfPermission } from '@controls';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';

const TableAgenda = ({ agenda }) => {
  return (
    <Row>
      <IfPermission action='seguimientos'>
        <Col>
          {agenda && agenda.length > 0 && (
            <Card>
              <Card.Header>SEGUIMIENTOS</Card.Header>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Hora</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {agenda.map((a, i) => (
                    <tr key={i}>
                      <th scope='row'>{moment(a.start).local().format('h:mm:ss a')}</th>
                      <td>{a.title}</td>
                      <td>{a.description}</td>
                      <td>
                        <a className='btn btn-sm btn-success' href={a.url}>
                          <Icon icon='list-alt' />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          )}
        </Col>
      </IfPermission>
    </Row>
  );
};

TableAgenda.propTypes = {
  agenda: PropTypes.array,
};
export default TableAgenda;
