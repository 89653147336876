import { TYPES_USERS_FRONT } from '@config/constants';
import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Button, Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditSubModule = ({ idCatSubModule, loadCatalog, subModule }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState(1);
  const [nameSubModule, setNameSubModule] = useState('');
  const [path, setPath] = useState('');
  const [processes, setProcesses] = useState([]);
  const [type, setType] = useState(TYPES_USERS_FRONT.ADMIN);
  const [isLoading] = useState(false);

  const open = () => {
    setShowModal(true);
    setSort(subModule.sort);
    setNameSubModule(subModule.name);
    setPath(subModule.path);
    setProcesses(subModule.processes);
    setType(subModule.type);
  };

  const close = () => {
    setShowModal(false);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      sort: sort,
      name: nameSubModule.trim(),
      path: path.trim(),
      processes,
      type: type,
    };

    if (validations(data)) {
      await putRequest({ url: `submodule/${idCatSubModule}`, body: data });
      loadCatalog();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.sort.toString())) {
      validate.success = false;
      validate.message = 'Orden es requerido.';
    } else if (params.sort <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.name)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.path)) {
      validate.success = false;
      validate.message = 'Ruta es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const addItemProcess = () => {
    setProcesses([...processes, { code: '', name: '', sort: processes.length + 1 }]);
  };

  const onChangeProcess = (index, campo, event) => {
    const { value } = event.target;
    setProcesses(
      processes.map((x, i) => {
        if (i === index) return { ...x, [campo]: value };
        return x;
      })
    );
  };

  const removeItemProcess = (item) => {
    setProcesses(processes.filter((x, i) => i !== item));
  };

  const renderProcesses = () => {
    return (
      <table className='table'>
        <tbody>
          {processes.map((process, i) => (
            <tr key={i}>
              <td>
                <DivFormGroup>
                  <Input name={`process-${i}`} value={process.code} placeholder='Código' onChange={(e) => onChangeProcess(i, 'code', e)} />
                </DivFormGroup>
              </td>
              <td>
                <DivFormGroup>
                  <Input name={`process-${i}`} placeholder='Nombre' value={process.name} onChange={(e) => onChangeProcess(i, 'name', e)} />
                </DivFormGroup>
              </td>
              <td>
                <Button onClick={() => removeItemProcess(i)} className='btn btn-outline-danger' type='button'>
                  <Icon icon='trash' />
                </Button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan='3'>
              <Button
                onClick={addItemProcess}
                className='list-group-item'
                style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
                type='button'
              >
                <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar acción
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <CbButtonOver icon='pencil' onClick={open} title='Editar' type='primary btn-sm' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <DivFormGroup required title='Orden'>
              <input type='number' className='form-control' id='sort' name='sort' onChange={(e) => setSort(e.target.value)} value={sort} />
            </DivFormGroup>
          </Col>
          <Col sm='8'>
            <DivFormGroup name='nameSubModule' required title='Nombre'>
              <input
                type='text'
                className='form-control'
                name='nameSubModule'
                onChange={(e) => setNameSubModule(e.target.value)}
                value={nameSubModule}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='path' required title='Ruta'>
              <input type='text' className='form-control' name='path' onChange={(e) => setPath(e.target.value)} value={path} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <h4>Acciones</h4>
          </Col>
        </Row>
        {renderProcesses()}
      </CbModal>
    </>
  );
};

EditSubModule.propTypes = {
  idCatSubModule: PropTypes.string.isRequired,
  subModule: PropTypes.object.isRequired,
  loadCatalog: PropTypes.func.isRequired,
};

export default EditSubModule;
