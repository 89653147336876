import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';

const List = ({ data }) => {
  const renderRows = () => {
    return data.map((row, item) => {
      const {
        session: {
          session: { mMap },
        },
        exception,
        app,
      } = row;

      return (
        <tr key={item}>
          <td>
            <code>{exception}</code>
          </td>
          <td>
            <p>{JSON.stringify(mMap)}</p>
          </td>
          <td>{moment(app).local().format('DD/MMMM/YYYY hh:mm A')}</td>
        </tr>
      );
    });
  };

  const rows = data.length > 0 ? renderRows() : <RowsNotFound message='Aún no hay registros' colSpan={3} />;

  return (
    <table className='table table-striped align-middle'>
      <thead>
        <tr>
          <th width='70%'>Error</th>
          <th width='20%'>Sesión</th>
          <th width='10%'>Fecha</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
