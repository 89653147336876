import { ABONADA, CANCELADA, PAGADA, PAGADA_MANUAL, PENDIENTE } from '@config/constants';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import Concepto from './Concepto';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetalleNotaCredito = () => {
  const { notaCreditoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `nota-credito/${notaCreditoId}` });
      setData(data);
    };

    fetchData();
  }, [notaCreditoId]);

  const renderDetalle = () => {
    const { detalle } = data;

    return (
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Código</th>
            <th>Descripción</th>
            <th>Medida</th>
            <th>Precio</th>
            <th>Subtotal</th>
            <th>Descuento</th>
            <th>IVA</th>
            <th>IEPS</th>
            <th>Retención</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {detalle.length > 0 ? (
            detalle.map((d, i) => {
              const { codigo, descripcion, unidadMedidaId, precio, cantidad, sub_total, descuento, iva, ieps, retencion, importe } = d;

              return (
                <tr key={i}>
                  <td>{cantidad}</td>
                  <td>{codigo}</td>
                  <td>{descripcion}</td>
                  <td>{unidadMedidaId ? unidadMedidaId.measure : null}</td>
                  <td>${format(precio, 2)}</td>
                  <td>${format(sub_total, 2)}</td>
                  <td>${format(descuento, 2)}</td>
                  <td>${format(iva, 2)}</td>
                  <td>${format(ieps, 2)}</td>
                  <td>${format(retencion, 2)}</td>
                  <td>${format(importe, 2)}</td>
                </tr>
              );
            })
          ) : (
            <RowsNotFound message='Sin detalle' colSpan={11} />
          )}
        </tbody>
      </table>
    );
  };

  const switchEstado = (estado) => {
    let text = '';
    let color = 'secondary';

    if (estado === PENDIENTE) {
      text = 'Pendiente de pago';
      color = 'secondary';
    } else if (estado === PAGADA) {
      text = 'Pagada';
      color = 'success';
    } else if (estado === PAGADA_MANUAL) {
      text = 'Pago manual';
      color = 'success';
    } else if (estado === ABONADA) {
      text = 'Abonada';
      color = 'warning';
    } else if (estado === CANCELADA) {
      text = 'Cancelada';
      color = 'danger';
    }

    return <span className={`label label-${color}`}>{text}</span>;
  };

  if (!data) {
    return <Module title='Loading...' onClickBack={window.history.back} loading={true} />;
  }

  const {
    encabezado: {
      serie,
      numero,
      uuid,
      fecha,
      fecha_entrega,
      importe,
      moneda,
      forma_pago,
      tipoDocumentoId,
      customerId,
      estado,
      cancelacion,
      tipo_relacion_sat,
      cfdi_relacionados,
    },
  } = data;

  const cliente = renderNombreCliente(customerId);

  return (
    <Module title={cliente} onClickBack={window.history.back} loading={data === null}>
      <Row>
        <Col sm='12'>
          <Card>
            <Card.Header>
              <Card.Title>Nota de credito</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm='6'>
                  <Concepto label='Serie' valor={serie} />
                  <Concepto label='Número' valor={numero} />
                  <Concepto label='Folio Fiscal' valor={uuid} />
                  <Concepto label='Tipo' valor={tipoDocumentoId ? tipoDocumentoId.nombre : null} />
                  <Concepto label='Fecha' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                  {fecha_entrega !== null ? <Concepto label='Fecha de Entrega' valor={moment(fecha_entrega).local().format('DD/MM/YYYY')} /> : null}
                </Col>
                <Col sm='6 pull-right'>
                  <div className='pull-right'>
                    <Concepto label='Moneda' valor={moneda} />
                    <Concepto label='Tipo de Pago' valor={forma_pago} />

                    <div>
                      <span className='text-success'>
                        <strong>Importe</strong> <u>$ {format(importe, 2)}</u>
                      </span>
                    </div>
                  </div>
                </Col>

                <Col sm='12'>
                  <legend></legend>
                </Col>

                <Col sm='6'>
                  <Concepto label='Cliente' valor={cliente} />
                  <Concepto label='RFC' valor={customerId.rfc} />
                </Col>

                <Col sm='12'>
                  <legend></legend>
                </Col>
                <Col sm='6'>
                  {switchEstado(estado)}

                  {estado === CANCELADA ? (
                    <div>
                      <p>{cancelacion.nota}</p>
                      <small>
                        Fecha de cancelación <em>{moment(cancelacion.fecha).local().format('DD/MM/YYYY HH:mm')}</em>
                      </small>
                    </div>
                  ) : null}
                </Col>
                {cfdi_relacionados.length > 0 ? (
                  <Col sm='12'>
                    <Concepto label='Tipo Relación' valor={tipo_relacion_sat.descripcion} />
                    {cfdi_relacionados.map((x) => (
                      <Concepto key={x.uuid} label='UUID Relacionado' valor={x.uuid} />
                    ))}
                  </Col>
                ) : null}
              </Row>
            </Card.Body>
            {renderDetalle()}
          </Card>
        </Col>
      </Row>
    </Module>
  );
};

DetalleNotaCredito.propTypes = {};

export default DetalleNotaCredito;
