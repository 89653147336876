import { Confirmation } from '@controls/index';
import PropTypes from 'prop-types';
import React from 'react';

const AltaAgentes = ({ id, agenteId, nombre, refresh }) => {
  return (
    <Confirmation
      id={id}
      btnIcon='arrow-up'
      typeConfirm='success'
      btnType='outline-success'
      action='Activar'
      typeRequest='putRequest'
      url={`agentes/${agenteId}/activar`}
      description={`¿Confirma que desea dar de alta al agente: <strong>${nombre}</strong> ?`}
      loadData={refresh}
    />
  );
};

AltaAgentes.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  agenteId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default AltaAgentes;
