import React from 'react';
import { Icon } from '@stateless';
import CbDomicilio from '@controls/CbDomicilio';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import PropTypes from 'prop-types';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Confirmation } from '@controls';
import { Button, ButtonGroup, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const RowDomicilio = ({ domicilio, editDomicilio, onChangeDomicilio, loadData, customerId }) => {
  const { addNotification: notification } = useStoreNotification();
  const onSaveDomicilio = async (e) => {
    e.preventDefault();
    if (
      isEmpty(domicilio.calle) ||
      !domicilio.paisObject ||
      !domicilio.estadoObject ||
      !domicilio.municipioObject ||
      !domicilio.coloniaObject ||
      isEmpty(domicilio.codigo_postal.toString())
    ) {
      notification({
        title: 'Información incompleta',
        message: 'Los domicilios requieren calle, pais, estado, municipio, colonia y código postal.',
        type: 'error',
      });
      return;
    }
    const data = {
      domicilioId: domicilio?._id,
      calle: domicilio?.calle,
      exterior: domicilio?.exterior,
      interior: domicilio?.interior,
      calle1: domicilio?.calle1,
      calle2: domicilio?.calle2,
      coloniaId: domicilio?.coloniaObject._id,
      municipioId: domicilio?.municipioObject._id,
      estadoId: domicilio?.estadoObject._id,
      paisId: domicilio?.paisObject._id,
      codigo_postal: domicilio?.codigo_postal,
      localidad: domicilio?.localidad,
      referencias: domicilio?.referencias,
      nombre: domicilio?.nombre,
      monitoreo: domicilio?.monitoreo,
      es_fiscal: domicilio?.es_fiscal,
    };
    await postRequest({ url: `customers/${customerId}/domicilio`, body: data });
    loadData();
  };

  return (
    <>
      <ListGroup.Item className={`list-group-item ${domicilio.esta_completo ? '' : 'list-group-item-danger'}`}>
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">
            {domicilio.es_fiscal && <span title='Domicilio Fiscal'><Icon icon='check' /></span>} {domicilio.nombre}
          </h5>
          <ButtonGroup>
            <CbButtonOver onClick={editDomicilio} icon='pencil' title='Editar' type='success' />
            <Confirmation
              id={domicilio._id}
              btnIcon='trash'
              typeConfirm='danger'
              btnType='outline-danger'
              action='Eliminar'
              typeRequest='deleteRequest'
              url={`customers/${customerId}/domicilios/${domicilio._id}`}
              description={`¿Confirma que desea dar de baja el domicilio?`}
              loadData={loadData}
            />
          </ButtonGroup>
        </div>
        <p className="mb-1">
          { !domicilio.editando && <CbDomicilio address={domicilio} />}
        </p>
      </ListGroup.Item>
      {domicilio.editando && (
        <ListGroup.Item>
          <DomicilioEdit domicilio={domicilio} onChange={onChangeDomicilio} />
          <Container>
            <Row>
              <Col xs={12}>
                <div className='pull-right'>
                  <Button onClick={editDomicilio} variant='light' className='mr-2'>
                    Cancelar
                  </Button>
                  <Button onClick={onSaveDomicilio} variant='success'>
                    Guardar
                  </Button>
                </div>
                <br />
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
      )}
    </>
  );
};

RowDomicilio.propTypes = {
  domicilio: PropTypes.object.isRequired,
  editDomicilio: PropTypes.func.isRequired,
  cancelDomicilio: PropTypes.func.isRequired,
  onChangeDomicilio: PropTypes.func.isRequired,
  estados: PropTypes.array.isRequired,
  paises: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,

  customerId: PropTypes.number.isRequired,
};

export default RowDomicilio;
