import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import ChangePassword from './ChangePassword';
import DeleteSellers from './DeleteSellers';
import EditSellers from './EditSellers';
import LogoutCarroJuan from './LogoutCarroJuan';

const ListSellers = ({ catalogSellers, ...props }) => {
  const renderSession = (open) => {
    if (open) {
      return (
        <span className='label label-success'>
          <Icon icon='phone' /> logueado
        </span>
      );
    } else {
      return (
        <span className='label label-danger'>
          <Icon icon='off' /> deslogueado
        </span>
      );
    }
  };

  const renderRows = () => {
    return catalogSellers.map((row, item) => {
      const { _id, name, fatherLastName, motherLastName, username, openSession, last_access } = row;
      const labelSession = renderSession(openSession);
      return (
        <tr key={item}>
          <td>
            {name} {fatherLastName} {motherLastName}
          </td>
          <td>{username}</td>
          <td>
            {labelSession}

            {openSession ? <LogoutCarroJuan id={item} userId={_id} {...props} /> : null}
          </td>
          <td>{moment(last_access).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteSellers
                id={item}
                idSellers={_id}
                name={`${name} ${fatherLastName} ${motherLastName}`}
                {...props}
              />
            </IfPermission>
            <IfPermission action='cambiar'>
              <ChangePassword id={item} idSellers={_id} {...props} />
            </IfPermission>
            <IfPermission action='edita'>
              <EditSellers id={item} idSellers={_id} {...props} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Nombre</th>
          <th width='20%'>Usuario</th>
          <th width='20%'>Sesión</th>
          <th width='10%'>Última sesión</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListSellers.propTypes = {
  catalogSellers: PropTypes.array.isRequired,
};

export default ListSellers;
