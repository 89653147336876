const isJson = (item) => {
  try {
    JSON.parse(item);
    return true;
  } catch (e) {
    return false;
  }
};

class CustomLocalStorage {
  static getItem(key, defaultValue = null) {
    try {
      const item = window.localStorage.getItem(key);
      if (item == null) return defaultValue;
      return isJson(item) ? JSON.parse(item) : item;
    } catch (error) {
      console.error('Error al obtener el valor de localStorage', error);
      return defaultValue;
    }
  }

  static setItem(key, value) {
    try {
      if(value === null) return window.localStorage.removeItem(key);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error al guardar el valor en localStorage', error);
    }
  }

  static removeItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error('Error al eliminar el valor de localStorage', error);
    }
  }
}

export default CustomLocalStorage;
