import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';

const Table = ({ list, loadData }) => {
  const onClickDownloadPdf = async (id, numero_conversion, event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `conversion/pdf/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha, numero_conversion, sucursal, almacen, estatus_conversion, agente } = r;
      return (
        <tr key={i}>
          <td>{numero_conversion}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          <td>{sucursal.name}</td>
          <td>{almacen.name}</td>
          <td>{agente.nombre}</td>
          <td className='with-actions'>
            <CbBadge type={`primary`}>{estatus_conversion}</CbBadge>
            <span className='actions'>
              <RedirectTooltip
                id={1}
                icon='search'
                url={`/conversiones/detalle/${_id}`}
                labelTooltip='Detalles'
                className='btn btn-sm btn-default mr-5'
              />
              <If condition={estatus_conversion !== 'CANCELADA'}>
                <If.Then>
                  <RedirectTooltip
                    id={1}
                    icon='edit'
                    url={`/conversiones/edicion/${_id}`}
                    labelTooltip='Editar'
                    className='btn btn-sm btn-primary mr-5'
                  />
                </If.Then>
              </If>
              <OverlayTrigger placement='top' overlay={<Tooltip id={`${_id}-download`}>Descargar PDF</Tooltip>}>
                <Button onClick={(event) => onClickDownloadPdf(_id, numero_conversion, event)} className='btn btn-sm btn-primary mr-5'>
                  <Icon icon='download-alt' />
                </Button>
              </OverlayTrigger>
              <If condition={estatus_conversion !== 'CANCELADA'}>
                <If.Then>
                  <Confirmation
                    id={_id}
                    btnIcon='remove'
                    typeConfirm='danger'
                    btnType='danger mr-5'
                    action='Cancelar conversión'
                    url={`conversion/cancelar/${_id}`}
                    description={`¿Confirma que desea CANCELAR la conversión <strong>#${numero_conversion}</strong> ?`}
                    loadData={loadData}
                  />
                </If.Then>
              </If>
            </span>
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='10%'># Conversión</th>
              <th width='13%'>Fecha</th>
              <th width='10%'>Sucursal</th>
              <th width='10%'>Almacen</th>
              <th width='10%'>Agente</th>
              <th width='10%'>Estatus</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </Card>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
