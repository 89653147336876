import { TYPE_CONFIGURATIONS } from '@config/constants';
import { DivFormGroup, Input, Label, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditConfiguration = ({ configurationId, esquemas, loadConfigurations }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [esquemaId, setEsquemaId] = useState('');
  const [type, setType] = useState(TYPE_CONFIGURATIONS.PRODUCT);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = async () => {
    const {
      data: { name, note, type, esquemaId },
    } = await getRequest({ url: `configuration/${configurationId}` });
    setShowModal(true);
    setName(name);
    setNote(note);
    setEsquemaId(esquemaId ? esquemaId._id : '');
    setType(type);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      name: name.trim(),
      note: note.trim(),
      type,
      esquemaId,
    };

    if (validations(data)) {
      await putRequest({ url: `configuration/${configurationId}`, body: data });
      loadConfigurations();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre de la Configuración es requerido.';
    } else if (validate.success && (isEmpty(params.esquemaId + '') || !isMongoId(params.esquemaId + ''))) {
      validate.success = false;
      validate.message = 'Esquema de Pago es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='name' title='Nombre de la Configuración' />
              <Input name='name' onChange={(e) => setName(e.target.value)} value={name} />
            </DivFormGroup>
            <FormGroup className='inline'>
              <label>Clasificación</label>
              <div>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    name='type'
                    onChange={(e) => setType(e.target.value)}
                    value={TYPE_CONFIGURATIONS.PRODUCT}
                    checked={type === TYPE_CONFIGURATIONS.PRODUCT}
                  />{' '}
                  Productos
                </label>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    name='type'
                    onChange={(e) => setType(e.target.value)}
                    value={TYPE_CONFIGURATIONS.SERVICE}
                    checked={type === TYPE_CONFIGURATIONS.SERVICE}
                  />{' '}
                  Servicios
                </label>
              </div>
            </FormGroup>
            <DivFormGroup>
              <Label name='note' title='Nota' />
              <TextArea name='note' onChange={(e) => setNote(e.target.value)} value={note} rows={3} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='esquemaId' title='Esquema de Pago' />
              <select name='esquemaId' id='esquemaId' className='form-control' value={esquemaId} onChange={(e) => setEsquemaId(e.target.value)}>
                <option value=''>Seleccione</option>
                {esquemas.map((e, i) => (
                  <option value={e._id} key={i}>
                    {e.esquema}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditConfiguration.propTypes = {
  esquemas: PropTypes.array.isRequired,
  configurationId: PropTypes.string.isRequired,
  loadConfigurations: PropTypes.func.isRequired,
};

export default EditConfiguration;
