import { KITS, MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import RowEquipo from './components/RowEquipo';
import RowServicio from './components/RowServicio';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { validateUpdate } from '@validations/kitsComerciales';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';

const EditKits = () => {
  const { addNotification: notification } = useStoreNotification();

  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    loading: false,
    data: null,
    tipoCotizacionId: '',
    orden: 1,
    codigo: '',
    nombre: '',
    moneda: MONEDA.DOLAR,
    precio: '',
    tipo: '',
    clase: '',
    rows: 1,
    complementarios: 1,
    rowsServicios: 1,
    cotizaciones: [],
    levantamientos: [],
    equipos: [],
    equiposComplementarios: [],
    equiposGral: [],
    servicios: [],
    kits: [],
    medidas: [],
    listKits: [],
    listEquipos: [],
    listServicios: [],
    listComplementarios: [],
  });

  const loadData = useCallback(async () => {
    const { kitId } = params;
    const { cotizaciones, data, medidas } = await getRequest({ url: `kits-comerciales/${kitId}` });
    let precio = data.precio;
    setState((prevState) => ({
      ...prevState,
      cotizaciones,
      data,
      medidas,
      orden: data.orden,
      codigo: data.codigo,
      nombre: data.nombre,
      moneda: data.moneda,
      precio: precio,
      tipoCotizacionId: data.tipoCotizacionId,
      tipo: data.tipo,
      clase: data.clase,
      rows: data.lista.length,
      listKits: data.listaKits.map((x, i) => ({
        ...x,
        index: i,
        code: x.kitId.codigo,
        obj: { value: x.kitId._id, label: x.kitId.nombre },
        name: x.kitId.nombre,
        kitId: x.kitId,
      })),
      listEquipos: data.lista.map((x, i) => ({
        ...x,
        index: i,
        code: x.equipoId.code,
        name: x.equipoId.name,
        equipoId: x.equipoId,
        status: x.equipoId.status,
      })),
      listServicios: data.listaServicios.map((x, i) => ({
        ...x,
        index: i,
        code: x.servicioId.code,
        name: x.servicioId.name,
        servicioId: x.servicioId,
        measureId: x.measureId,
        status: x.servicioId.status,
      })),
      listComplementarios: data.listaComplementarios.map((x, i) => ({
        ...x,
        index: i,
        code: x.equipoId.code,
        name: x.equipoId.name,
        equipoId: x.equipoId,
        status: x.equipoId.status,
      })),
    }));
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSelect = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChange = (event) => {
    const { name, checked, type } = event.target;
    let value = event.target.value;
    if (type === 'checkbox') value = checked;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { kitId } = params;
    const { tipoCotizacionId, tipo, clase, orden, moneda, codigo, nombre, precio } = state;
    const listaKits = state.listKits.map((x) => ({ ...x, cantidad: cleanNumber(x.cantidad) }));
    const lista = state.listEquipos.map((x) => ({ equipoId: x.equipoId?._id, cantidad: cleanNumber(x.cantidad) }));
    const listaComplementarios = state.listComplementarios.map((x) => ({ ...x, cantidad: cleanNumber(x.cantidad) }));
    const listaServicios = state.listServicios.map((x) => ({
      servicioId: x.servicioId?._id,
      measureId: x.measureId?._id,
      cantidad: cleanNumber(x.cantidad),
    }));

    const data = {
      orden,
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      moneda,
      precio: cleanNumber(precio),
      tipoCotizacionId,
      tipo,
      clase,
      lista,
      listaKits,
      listaComplementarios,
      listaServicios,
    };

    if (validations(data)) {
      await putRequest({ url: `kits-comerciales/${kitId}`, body: data });
      resetForm();
    }
  };

  const resetForm = () => {
    setTimeout(() => navigate(0), 1000);
  };

  const addItemEquipo = () => {
    setState((prevState) => ({
      ...prevState,
      listEquipos: [
        ...prevState.listEquipos,
        { index: prevState.listEquipos.length, equipoId: null, cantidad: 1, name: '', obj: null, status: 'ACTIVO' },
      ],
    }));
  };

  const removeItemEquipo = (item) => {
    setState((prevState) => ({
      ...prevState,
      listEquipos: prevState.listEquipos.filter((x, i) => i !== item),
    }));
  };

  const onChangeEquipo = (index, value) => {
    setState((prevState) => ({
      ...prevState,
      listEquipos: prevState.listEquipos.map((x, i) => (i === index ? { ...x, ...value } : x)),
    }));
  };

  const renderEquipos = () => {
    const { listEquipos } = state;
    let views = listEquipos.map((equipo, i) => (
      <RowEquipo key={i} equipo={equipo} onChangeEquipo={onChangeEquipo.bind(null, i)} onRemoveItemEquipo={removeItemEquipo.bind(null, i)} />
    ));
    return (
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th>Equipo</th>
            <th>Cantidad</th>
            <th></th>
          </tr>
        </thead>
        <CbTableBody>
          {views}
          <CbTableFooterBotton colSpan={3} onClick={addItemEquipo} title='Agregar equipo' />
        </CbTableBody>
      </CbPanelTableResponsive>
    );
  };

  const addItemServicio = () => {
    setState((prevState) => ({
      ...prevState,
      listServicios: [
        ...prevState.listServicios,
        { index: prevState.listServicios.length, servicioId: null, cantidad: 1, name: '', obj: null, status: 'ACTIVO' },
      ],
    }));
  };

  const onChangeServicio = (index, value) => {
    setState((prevState) => ({
      ...prevState,
      listServicios: prevState.listServicios.map((x, i) => (i === index ? { ...x, ...value } : x)),
    }));
  };

  const removeItemServicio = (item) => {
    setState((prevState) => ({
      ...prevState,
      listServicios: prevState.listServicios.filter((x, i) => i !== item),
    }));
  };

  const renderServicios = () => {
    const { listServicios, medidas } = state;
    let views = listServicios.map((servicio, i) => (
      <RowServicio
        key={i}
        servicio={servicio}
        medidas={medidas}
        onChangeServicio={onChangeServicio.bind(null, i)}
        onRemoveItemServicio={removeItemServicio.bind(null, i)}
      />
    ));
    return (
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th>Servicio</th>
            <th>Unidad</th>
            <th>Cantidad</th>
            <th></th>
          </tr>
        </thead>
        <CbTableBody colSpan={4}>
          {views}
          <CbTableFooterBotton colSpan={4} onClick={addItemServicio} title='Agregar servicio' />
        </CbTableBody>
      </CbPanelTableResponsive>
    );
  };

  const renderForm = () => {
    const { orden, codigo, nombre, tipoCotizacionId, tipo, clase, moneda, loading } = state;

    return (
      <Module onClickBack={() => window.history.back()} title='Editar Kit comercial'>
        <form onSubmit={handleRegister}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm='6'>
                  <FormInput title='Orden' name='orden' required onChange={onChange} value={orden} />
                </Col>
                <Col sm='6'>
                  <FormInput title='Código' name='codigo' required onChange={onChange} value={codigo} />
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <FormInput title='Nombre' name='nombre' required onChange={onChange} value={nombre} />
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='moneda' title='Moneda' required inline>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-dolar' onChange={onChange} value={MONEDA.DOLAR} checked={MONEDA.DOLAR === moneda} />{' '}
                        Dolares
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-peso' onChange={onChange} value={MONEDA.PESO} checked={MONEDA.PESO === moneda} />{' '}
                        Pesos
                      </label>
                    </div>
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <DivFormGroup name='tipoCotizacionId' title='Linea' required>
                    <SelectTipoLevantamiento name='tipoCotizacionId' value={tipoCotizacionId} onChange={onSelect.bind(null, 'tipoCotizacionId')} />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup inline title='Tipo de Kit'>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='tipo' id='rdo-hibrido' onChange={onChange} value={KITS.HIBRIDO} checked={KITS.HIBRIDO === tipo} />{' '}
                        Hibrido
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='tipo'
                          id='rdo-inalambrico'
                          onChange={onChange}
                          value={KITS.INALAMBRICO}
                          checked={KITS.INALAMBRICO === tipo}
                        />{' '}
                        Inalámbrico
                      </label>
                    </div>
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup inline title='Clase de Kit'>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='clase' id='rdo-interactivo' onChange={onChange} value={0} checked={0 === clase} /> Interactivo
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='clase' id='rdo-no-interactivo' onChange={onChange} value={1} checked={1 === clase} /> No Interactivo
                      </label>
                    </div>
                  </DivFormGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <Card.Title>Equipos</Card.Title>
                </Card.Header>
                {renderEquipos()}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <Card.Title>Servicios</Card.Title>
                </Card.Header>
                <Card.Body>{renderServicios()}</Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Saving saving={loading} />
              <Button type='submit' className='btn btn-primary pull-right' disabled={loading}>
                <Icon icon='save' /> Guardar
              </Button>
            </Col>
          </Row>
        </form>
      </Module>
    );
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error,
        type: 'error',
      });
    }
    return !error;
  };

  const { data } = state;
  return data ? renderForm() : <Loading />;
};

EditKits.propTypes = {};

export default EditKits;
