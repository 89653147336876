import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from '@stateless';
import Panel from '@controls/Panel';
import { Icon, Loading } from '@stateless';
import ListServicios from './ListServicios';
import { Input } from '@controls';
import { Col, Row } from 'react-bootstrap';
import InputCurrency from '@components/forms/InputCurrency';

const ContratoEquipoVenta = (props) => {
  const onChange = (event) => {
    props.onChange(event);
  };

  const onChangeServicios = (item, nombre, event) => {
    props.onChangeServicios(item, nombre, event);
  };

  const onChangeList = (item, nombre, name, value) => {
    props.onChangeList(item, nombre, name, value);
  };

  const renderFormaPagoEquipos = () => {
    const { catalogoFormaPagoEquipos } = props;
    if (catalogoFormaPagoEquipos.length > 0) {
      return catalogoFormaPagoEquipos.map((row, item) => {
        const { forma } = row;
        if (catalogoFormaPagoEquipos[item].tipo === 'EQUIPO') {
          return (
            <tr key={item}>
              <td style={{ padding: '0px', textAlign: 'center', verticalAlign: 'middle' }}>
                <input
                  type='radio'
                  name='checkFPE'
                  checked={row.check}
                  onChange={(event) => onChangeServicios(item, 'catalogoFormaPagoEquipos', event)}
                />
              </td>
              <td style={{ padding: '0px' }}>
                <input className='form-control disabled' style={{ borderRadius: '0px' }} value={forma} />
              </td>
            </tr>
          );
        }
      });
    } else {
      return (
        <Fragment>
          <RowsNotFound />
        </Fragment>
      );
    }
  };

  const renderCondicionesPagoEquipos = () => {
    const { catalogoCondicionesPagoEquipos } = props;
    if (catalogoCondicionesPagoEquipos.length > 0) {
      return catalogoCondicionesPagoEquipos.map((row, item) => {
        const { condicion } = row;
        return (
          <tr key={item}>
            <td style={{ padding: '0px', textAlign: 'center', verticalAlign: 'middle' }}>
              <input type='radio' name='checkCPE' onChange={(event) => onChangeServicios(item, 'catalogoCondicionesPagoEquipos', event)} />
            </td>
            <td style={{ padding: '0px' }}>
              <input className='form-control disabled' style={{ borderRadius: '0px' }} value={condicion} />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <Fragment>
          <RowsNotFound />
        </Fragment>
      );
    }
  };

  const renderTipoEstablecimientos = () => {
    const { catalogoTipoEstablecimientos } = props;
    if (catalogoTipoEstablecimientos.length > 0) {
      return catalogoTipoEstablecimientos.map((row, item) => {
        const { nombre } = row;
        return (
          <tr key={item}>
            <td style={{ padding: '0px', textAlign: 'center', verticalAlign: 'middle' }}>
              <input type='radio' name='checkTE' onChange={(event) => onChangeServicios(item, 'catalogoTipoEstablecimientos', event)} />
            </td>
            <td style={{ padding: '0px' }}>
              <input className='form-control disabled' style={{ borderRadius: '0px' }} value={nombre} />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <Fragment>
          <RowsNotFound />
        </Fragment>
      );
    }
  };

  const renderTipoCableados = () => {
    const { catalogoTipoCableados } = props;
    if (catalogoTipoCableados.length > 0) {
      return catalogoTipoCableados.map((row, item) => {
        const { nombre } = row;
        return (
          <tr key={item}>
            <td style={{ padding: '0px', textAlign: 'center', verticalAlign: 'middle' }}>
              <input type='radio' name='checkTC' onChange={(event) => onChangeServicios(item, 'catalogoTipoCableados', event)} />
            </td>
            <td style={{ padding: '0px' }}>
              <input className='form-control disabled' style={{ borderRadius: '0px' }} value={nombre} />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <Fragment>
          <RowsNotFound />
        </Fragment>
      );
    }
  };

  const renderList = (list, nombre) => {
    return (
      <ListServicios
        catalogServicios={list}
        loadServicios={props.loadServicios}
        onChange={(item, name, value) => onChangeList(item, nombre, name, value)}
      />
    );
  };

  const { equipo_seguridad, fecha_venta_equipo, card, clabe, total, loading } = props;
  const listEquipoSeguridad = !loading ? renderList(equipo_seguridad, 'equipo_seguridad') : <Loading />;
  const forma_pago_equipos = !loading ? renderFormaPagoEquipos() : <Loading />;
  const condiciones_pago_equipos = !loading ? renderCondicionesPagoEquipos() : <Loading />;
  const tipo_establecimientos = !loading ? renderTipoEstablecimientos() : <Loading />;
  const tipo_cableados = !loading ? renderTipoCableados() : <Loading />;

  return (
    <Fragment>
      <Row>
        <Col sm='6'>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='wrench' /> Equipo de seguridad electronica adquirida
              </div>
            }
          >
            <table className='table table-striped align-middle'>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Producto</th>
                  <th>Precio de venta</th>
                </tr>
              </thead>
              <tbody>{listEquipoSeguridad}</tbody>
              <thead>
                <tr>
                  <th colSpan={3}>Total de productos comprados (con IVA)</th>
                </tr>
                <tr>
                  <th colSpan={2}>Totales</th>
                  <th>
                    <InputCurrency name='total' className='form-control' value={total} style={{ width: '100%', minWidth: '80px' }} disabled />
                  </th>
                </tr>
              </thead>
            </table>
          </Panel>
        </Col>
        <Col sm='6'>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='credit-card' /> Forma de pago para compra de equipos
              </div>
            }
          >
            <table className='table table-striped align-middle'>
              <tbody>
                {forma_pago_equipos}
                {card ? (
                  <Fragment>
                    <tr>
                      <td style={{ padding: '0px', verticalAlign: 'middle' }}>Cuenta / CLABE:</td>
                      <td style={{ padding: '0px' }}>
                        <Input style={{ borderRadius: '0px' }} type='text' name='clabe' value={clabe} onChange={onChange} />
                      </td>
                    </tr>
                  </Fragment>
                ) : null}
              </tbody>
            </table>
          </Panel>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='info-sign' /> Condiciones de pago
              </div>
            }
          >
            <table className='table table-striped align-middle'>
              <tbody>{condiciones_pago_equipos}</tbody>
            </table>
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='tree-conifer' /> Tipo de establecimiento
              </div>
            }
          >
            <table className='table table-striped align-middle'>
              <tbody>{tipo_establecimientos}</tbody>
            </table>
          </Panel>
        </Col>
        <Col sm='6'>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='hourglass' /> Fecha probable de instalación
              </div>
            }
          >
            <Input type='date' name='fecha_venta_equipo' value={fecha_venta_equipo} onChange={onChange} />
          </Panel>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='cog' /> Tipo de instalación del cableado
              </div>
            }
          >
            <table className='table table-striped align-middle'>
              <tbody>{tipo_cableados}</tbody>
            </table>
          </Panel>
        </Col>
      </Row>
    </Fragment>
  );
};

ContratoEquipoVenta.propTypes = {
  monitoreo: PropTypes.array.isRequired,
  interactivos: PropTypes.array.isRequired,
  catalogoCondicionesPagoEquipos: PropTypes.array.isRequired,
  equipo_seguridad: PropTypes.array.isRequired,
  catalogoFormaPagoEquipos: PropTypes.array.isRequired,
  catalogoTipoEstablecimientos: PropTypes.array.isRequired,
  catalogoTipoCableados: PropTypes.array.isRequired,
  total: PropTypes.string.isRequired,

  fecha_venta_equipo: PropTypes.object.isRequired,
  onChangeList: PropTypes.func.isRequired,
  onChangeServicios: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  card: PropTypes.bool.isRequired,
  clabe: PropTypes.string.isRequired,
  loadServicios: PropTypes.func.isRequired,
};

export default ContratoEquipoVenta;
