import { ADICIONAL, KIT, MONEDA } from '@config/constants';
import Panel from '@controls/Panel';
import React, { useState, useEffect } from 'react';
import { Icon, Module, RowsNotFound } from '@stateless';
import { getRequest, urlPhoto } from '@utils/api';
import { format } from '@utils/parseCost';
import { isMongoId, isURL } from 'validator';
import CbBadge from '@cbcomponents/CbBadge';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FileDownIcon } from 'lucide-react';

const DetailEquipment = () => {
  const { equipmentId } = useParams();
  const [equipment, setEquipment] = useState(null);
  const [fichasTecnicas, setFichasTecnicas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data,
        catalogs: { fichas_tecnicas },
      } = await getRequest({ url: `equipments/${equipmentId}` });
      setEquipment(data);
      setFichasTecnicas(fichas_tecnicas);
    };

    fetchData();
  }, [equipmentId]);

  const renderProveedor = (listProveedores) => {
    return listProveedores.map((s, i) => (
      <tr key={i}>
        <td>{s.name}</td>
      </tr>
    ));
  };

  const renderEquipoAlternativo = (listAlternos) => {
    return listAlternos
      .filter((s) => s.equipoId)
      .map((s, i) => (
        <tr key={i}>
          <td>
            <strong>{s.equipoId.code}</strong>
          </td>
          <td>{s.equipoId.name}</td>
        </tr>
      ));
  };

  const renderFicha = (listFichas, fichas_tecnicas) => {
    return fichas_tecnicas.map((s, i) => {
      const file = listFichas.find((f) => f.fichaTecnicaId.toString() === s._id.toString());
      const has = typeof file !== 'undefined';

      return (
        <tr key={i}>
          <td>{s.nombre}</td>
          <td>
            {has ? (
              <a
                href={`${urlPhoto()}${file.path.substring(5, file.path.length)}`}
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-sm btn-warning'
              >
                <FileDownIcon />
              </a>
            ) : null}
          </td>
        </tr>
      );
    });
  };

  const renderWorkforce = (listWorkforce) => {
    if (listWorkforce && listWorkforce.length > 0) {
      return listWorkforce.map((work, item) => (
        <tr key={item}>
          <td>
            <h4 className='text-center'>Nivel {work.level}</h4>
          </td>
          <td>
            <h4 className='text-center'>{work.value} hr</h4>
          </td>
        </tr>
      ));
    } else {
      return <RowsNotFound colSpan={2} message='No tiene niveles creados.' />;
    }
  };

  const renderDetalle = () => {
    const {
      asociadoId,
      principal,
      name,
      short_name,
      marcaId,
      description,
      precio,
      moneda,
      status,
      listWorkforce,
      equipmentsMeasureId,
      listProveedores,
      integracion,
      clasificacionCotizacionId,
      clasificacionLevantamientoId,
      imagen,
      clave_sat,
      serie,
      codigo_barras,
      pedimento,
      listAlternos,
      listFichas,
    } = equipment;

    const detailWorkforce = renderWorkforce(listWorkforce);
    const detailFicha = renderFicha(listFichas, fichasTecnicas);
    const detailEquipoAlterno = renderEquipoAlternativo(listAlternos);
    const detailProveedor = renderProveedor(listProveedores);

    let hasCotizacion = false;
    let hasLevantamiento = false;

    if (typeof clasificacionCotizacionId !== 'undefined') {
      if (clasificacionCotizacionId !== null) {
        hasCotizacion = true;
      }
    }

    if (typeof clasificacionLevantamientoId !== 'undefined') {
      if (clasificacionLevantamientoId !== null) {
        hasLevantamiento = true;
      }
    }

    let hasAsociado = false;
    if (typeof asociadoId !== 'undefined') {
      if (isMongoId(asociadoId + '')) {
        hasAsociado = true;
      }
    }

    return (
      <>
        <Row>
          <Col sm='12'>
            <Panel
              type='primary'
              header={
                <div>
                  <Icon icon='tag' /> Generales
                </div>
              }
            >
              <Row>
                <Col sm='6'>
                  {principal ? (
                    <div>
                      <p>
                        <CbBadge type='warning'>
                          <Icon icon='star' />
                        </CbBadge>
                        <span>Equipo principal</span>
                      </p>
                    </div>
                  ) : null}
                  <div>
                    <p>
                      <span>
                        <strong>Nombre: </strong>
                      </span>
                      <span> {name}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Nombre corto:</strong>
                      </span>
                      <span> {short_name}</span>
                    </p>
                  </div>

                  <div>
                    <p>
                      <span>
                        <strong>Estatus:</strong>
                      </span>
                      <span> {status}</span>
                    </p>
                  </div>

                  <div>
                    <p>
                      <span>
                        <strong>Moneda: </strong>
                      </span>
                      <span> {moneda === MONEDA.DOLAR ? 'Dolares' : 'Pesos'}</span>
                    </p>
                  </div>

                  <div>
                    <p>
                      <span>
                        <strong>Precio: </strong>
                      </span>
                      <span> $ {format(precio, 2)}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Descripción: </strong>
                      </span>
                      <span> {description}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Clave SAT: </strong>
                      </span>
                      <span> {clave_sat}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Pedimento: </strong>
                      </span>
                      <span> {pedimento}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Serie: </strong>
                      </span>
                      <span> {serie}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Código de barras: </strong>
                      </span>
                      <span> {codigo_barras}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Equipo relacionado: </strong>
                      </span>
                      <span> {hasAsociado ? `${asociadoId.code} ${asociadoId.name}` : ''}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Unidad de medida: </strong>
                      </span>
                      <span> {equipmentsMeasureId ? equipmentsMeasureId.measure : ''}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Marca: </strong>
                      </span>
                      <span> {marcaId ? marcaId.nombre : null}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Integración: </strong>
                      </span>
                      {integracion === KIT ? <span>Kit</span> : ''}
                      {integracion === ADICIONAL ? <span>Adicional</span> : ''}
                    </p>
                  </div>
                </Col>
                <Col sm='6'>
                  {imagen && imagen.thumbnail && imagen.thumbnail.length > 0 ? (
                    <img
                      src={imagen.thumbnail ? (isURL(imagen.thumbnail) ? imagen.thumbnail : urlPhoto() + imagen.thumbnail) : ''}
                      className='img-thumbnail img-responsive'
                      style={{ height: '200px' }}
                    />
                  ) : null}
                </Col>
                <Col sm='6'>
                  <div>
                    <legend>Clasificadores</legend>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Linea: </strong>
                      </span>
                      <span> {hasCotizacion ? clasificacionCotizacionId.nombre : ''}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>
                        <strong>Levantamientos: </strong>
                      </span>
                      <span> {hasLevantamiento ? clasificacionLevantamientoId.nombre : ''}</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Panel>

            <Card>
              <Card.Header>
                <Card.Title>Proveedores</Card.Title>
              </Card.Header>
              <table className='table table-striped align-middle'>
                <thead>
                  <tr>
                    <th className='text-center'>Proveedor</th>
                  </tr>
                </thead>
                <tbody>{detailProveedor}</tbody>
              </table>
            </Card>

            <Card>
              <Card.Header>
                <Card.Title>Equipos alternativos</Card.Title>
              </Card.Header>
              <table className='table table-striped align-middle'>
                <thead>
                  <tr>
                    <th className='text-center'>Código</th>
                    <th className='text-center'>Equipo</th>
                  </tr>
                </thead>
                <tbody>{detailEquipoAlterno}</tbody>
              </table>
            </Card>

            <Card>
              <Card.Header>
                <Card.Title>Ficha técnica</Card.Title>
              </Card.Header>
              <table className='table table-striped align-middle'>
                <tbody>{detailFicha}</tbody>
              </table>
            </Card>

            <Card>
              <Card.Header>
                <Card.Title>Mano de Obra Nivel (Hr)</Card.Title>
              </Card.Header>
              <table className='table table-striped align-middle'>
                <thead>
                  <tr>
                    <th className='text-center'>Nivel</th>
                    <th className='text-center'>Hora</th>
                  </tr>
                </thead>
                <tbody>{detailWorkforce}</tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Module onClickBack={window.history.back} title={equipment && `${equipment.name} - ${equipment.code} `} loading={!equipment}>
      {equipment && renderDetalle()}
    </Module>
  );
};

DetailEquipment.propTypes = {};

export default DetailEquipment;
