import PropTypes from 'prop-types';
import React from 'react';

import { RedirectTooltip } from '@controls';
import { RowsNotFound } from '@stateless';

const TableProducts = ({ listProducts }) => {
  const renderRows = (listProducts) => {
    return listProducts.map((rowProduct, item) => {
      const {
        _id,
        quantity,
        make,
        model,
        measureId: { measure },
        description,
        note,
        unit_price,
        amount,
      } = rowProduct;

      return (
        <tr key={item}>
          <td>{quantity}</td>
          <td>{make}</td>
          <td>
            <strong>{model}</strong>
          </td>
          <td>{measure}</td>

          <td>
            <strong>{description}</strong>
            <p>
              <small>
                <em>{note}</em>
              </small>
            </p>
          </td>

          <td>{unit_price}</td>
          <td>{amount}</td>

          <td>
            <RedirectTooltip id={1} icon='briefcase' url={`/socio/${_id}`} labelTooltip='Ver detalle' className='btn btn-sm btn-default pull-right' />
          </td>
        </tr>
      );
    });
  };

  let rows = null;

  if (listProducts.length > 0) {
    rows = renderRows(listProducts);
  } else {
    rows = <RowsNotFound message='Aún no hay productos registrados.' colSpan={8} />;
  }

  return (
    <table className='table table-striped align-middle'>
      <thead>
        <tr>
          <th width='10%'>Cantidad</th>
          <th width='10%'>Marca</th>
          <th width='10%'>Modelo</th>
          <th width='10%'>U.M.</th>
          <th width='10%'>Descripción</th>
          <th width='10%'>Precio Unitario</th>
          <th width='10%'>Importe</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TableProducts.propTypes = {
  listProducts: PropTypes.array.isRequired,
};

export default TableProducts;
