import { ABONADA, PAGADA, PENDIENTE } from '@config/constants';
import { DivFormGroup, FilterSelect, Label, TextArea } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RowsNotFound } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const AplicarConciliacion = ({ deposito, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [clienteId, setClienteId] = useState('');
  const [clienteSeleccionado, setClienteSeleccionado] = useState('');
  const [facturas, setFacturas] = useState([]);
  const [saldoFavor, setSaldoFavor] = useState(0);
  const [nota, setNota] = useState('');

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);
    setClienteId('');
    setClienteSeleccionado('');
    setFacturas([]);
    setSaldoFavor(0);
    setNota('');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nota') setNota(value);
  };

  const onChangeCliente = async (itemSelect) => {
    if (itemSelect !== null) {
      setClienteSeleccionado(itemSelect.label);
      setClienteId(itemSelect.value);
      const clienteId = itemSelect.value;
      const { data } = await getRequest({ url: `facturas-pendientes/${clienteId}` });
      const facturas = [];
      const {
       depositos
      } = deposito;
      let saldoFavor = data.saldo_favor + depositos;

      data.facturas.forEach((f) => {
        const { _id, serie, numero, uuid, fecha, importe, saldo_anterior, saldo } = f;
        let saldoFactura = saldo;

        if (saldoFavor > 0) {
          if (saldoFavor > saldo) {
            saldoFavor -= saldoFactura;
            saldoFactura = 0;
          } else {
            saldoFactura = saldo - saldoFavor;
            saldoFavor = 0;
          }

          facturas.push({
            _id,
            serie,
            numero,
            uuid,
            fecha,
            importe,
            saldo_anterior: saldo,
            saldo: saldoFactura,
            estado: saldoFactura === 0 ? PAGADA : ABONADA,
          });
        } else {
          facturas.push({
            _id,
            serie,
            numero,
            uuid,
            fecha,
            importe,
            saldo_anterior,
            saldo,
            estado: PENDIENTE,
          });
        }
      });

      setFacturas(facturas);
      setSaldoFavor(saldoFavor);
    } else {
      setClienteSeleccionado('');
      setClienteId('');
      setFacturas([]);
    }
  };

  const handleAplicar = async (event) => {
    event.preventDefault();

    const body = {
      facturas: facturas.filter((f) => f.estado !== PENDIENTE),
      saldo_favor: saldoFavor,
      clienteId,
      nota,
    };

    if (validations(body)) {
      await putRequest({ url: `aplicar-concilacion/${itemId}`, body });
      close();
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.clienteId + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    } else if (validate.success && params.facturas.length <= 0) {
      validate.success = false;
      validate.message = 'Las facturas del Cliente son necesarias.';
    } else if (validate.success && isEmpty(params.nota + '')) {
      validate.success = false;
      validate.message = 'Nota es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { numero, fecha, concepto, sucursal, referencia, codigo, depositos } = deposito;

  const facturasTotales = facturas.reduce(
    (t, r) => {
      t.importe += r.importe;
      t.saldoAnterior += r.saldo_anterior;
      t.saldoNuevo += r.saldo;
      return t;
    },
    {
      importe: 0,
      saldoAnterior: 0,
      saldoNuevo: 0,
    }
  );

  return (
    <>
      <CbButtonOver title='Buscar cliente y aplicar deposito' icon='check' onClick={open} hiddenXs type='primary btn-sm' />
      <CbModal title='Busqueda de cliente y aplicación de deposito' show={showModal} onClose={close} onSave={handleAplicar}>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='5%'>No</th>
              <th width='10%'>Fecha</th>
              <th width='45%'>Concepto</th>
              <th width='10%'>Sucursal</th>
              <th width='10%'>Referencia</th>
              <th width='10%'>Código</th>
              <th width='10%'>Depósitos</th>
            </tr>
          </thead>
          <tbody>
            <tr className='danger'>
              <td>{numero}</td>
              <td>
                {fecha.substring(0, 2)}/{fecha.substring(2, 4)}/{fecha.substring(4, 6)}
              </td>
              <td>{concepto}</td>
              <td>{sucursal}</td>
              <td>{referencia}</td>
              <td>{codigo}</td>
              <td>$ {format(depositos, 2)}</td>
            </tr>
          </tbody>
        </table>

        <DivFormGroup>
          <Label name='clienteId' title='Busqueda de clientes con facturas' />
          <input type='hidden' id='clienteId' name='clienteId' onChange={onChange} value={clienteId} />

          <FilterSelect
            async={true}
            disabled={false}
            value={clienteId.toString()}
            onChangeOption={onChangeCliente}
            url={`clientes-concilaciones-autocomplete`}
            fields={['_id', 'name']}
          />

          <br />
          <span className='lead'>
            <u>{clienteSeleccionado}</u>
          </span>
        </DivFormGroup>

        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='20%'>Factura</th>
              <th width='25%'>UUID</th>
              <th width='10%'>Fecha</th>
              <th width='15%'>Importe</th>
              <th width='15%'>Saldo anterior</th>
              <th width='15%'>Saldo nuevo</th>
            </tr>
          </thead>
          <tbody>
            {facturas.length > 0 ? (
              facturas.map((r, ri) => {
                const { serie, numero, uuid, fecha, importe, saldo_anterior, saldo } = r;

                const isPay = saldo === 0;

                let colorRowFactura = '';
                let colorSaldo = '';

                if (isPay) {
                  colorRowFactura = 'success';
                  colorSaldo = 'text-success';
                } else if (saldo < saldo_anterior) {
                  colorRowFactura = 'warning';
                  colorSaldo = 'text-warning';
                } else {
                  colorSaldo = 'text-danger';
                }

                return (
                  <tr key={ri} className={colorRowFactura}>
                    <td>
                      {serie}
                      {numero}
                    </td>
                    <td>{uuid}</td>
                    <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
                    <td>
                      <strong>$ {format(importe, 2)}</strong>
                    </td>
                    <td>$ {format(saldo_anterior, 2)}</td>
                    <td>
                      <span className={colorSaldo}>$ {format(saldo, 2)}</span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <RowsNotFound colSpan={6} />
            )}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}>
                <span className='pull-right'>Totales</span>
              </th>
              <th>$ {format(facturasTotales.importe, 2)}</th>
              <th>$ {format(facturasTotales.saldoAnterior, 2)}</th>
              <th>$ {format(facturasTotales.saldoNuevo, 2)}</th>
            </tr>
          </tfoot>
        </table>
        <DivFormGroup>
          <Label name='nota' title='Nota:' />
          <TextArea id='nota' name='nota' rows={5} defaultValue={nota} onChange={onChange} />
        </DivFormGroup>
      </CbModal>
    </>
  );
};

AplicarConciliacion.propTypes = {
  id: PropTypes.number.isRequired,

  deposito: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default AplicarConciliacion;
