import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import NuevoAsesor from './NuevoAsesor';
import { Col, InputGroup, Row } from 'react-bootstrap';

const RuletaAsesores = () => {
  const [state, setState] = useState({
    list: [],
    loading: true,
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    agentes: [],
    agenteId: null,
    agente: null,
    medios_comunicaciones: [],
    soluciones_solicitadas: [],
    agentes_mostrar: [],
  });

  const handleSearch = async () => {
    const { pagination, activePage, search, fechaFin, fechaInicio, agente } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      agenteId: agente ? agente.value : null,
    };
    const { data, count } = await postRequest({ url: 'ruleta-asesores/search', params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      list: data,
      count: count,
      loading: false,
    }));
  };

  const onSearch = (params = { search: '' }) => {
    setState(
      (prevState) => ({
        ...prevState,
        search: params.search,
        fechaInicio: params.fechaInicio,
        fechaFin: params.fechaFin,
        activePage: 1,
      }),
      handleSearch
    );
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }), handleSearch);
  };

  const loadCollections = async (callback) => {
    const {
      data: { agentes, soluciones_solicitadas, medios_comunicaciones, agente },
    } = await getRequest({ url: `ruleta-asesores/catalogos` });
    setState(
      (prevState) => ({
        ...prevState,
        agentes: agentes.map((agente) => ({
          value: agente._id,
          label: agente.nombre,
        })),
        soluciones_solicitadas: soluciones_solicitadas.map((solucion) => ({
          value: solucion._id,
          label: solucion.definicion,
        })),
        medios_comunicaciones: medios_comunicaciones.map((medio) => ({
          value: medio._id,
          label: medio.nombre,
        })),
        agentes_mostrar: [
          {
            value: '0',
            label: 'Todos los agentes',
          },
          ...agentes.map((agente) => ({
            value: agente._id,
            label: agente.nombre,
          })),
        ],
        agente: agente ? { value: agente._id, label: agente.nombre } : null,
        agenteId: agente ? agente._id : null,
      }),
      callback
    );
  };

  useEffect(() => {
    loadCollections(handleSearch);
  }, []);

  const renderList = () => {
    const { agentes, list, soluciones_solicitadas, medios_comunicaciones } = state;
    return (
      <List
        {...state}
        agentes={agentes}
        soluciones_solicitadas={soluciones_solicitadas}
        medios_comunicaciones={medios_comunicaciones}
        list={list}
        loadRuleta={handleSearch}
      />
    );
  };

  const { loading, pagination, count, activePage, agentes, soluciones_solicitadas, medios_comunicaciones, agente, agentes_mostrar } = state;
  const moduleList = !loading ? renderList() : <Loading />;
  return (
    <Module title='Ruleta asesores'>
      <Search onSearch={onSearch} withDatetime>
        <Row>
          <Col sm='1'></Col>
          <Col sm='8'>
            <IfPermission action='crear'>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='user' title='Agentes' />
                </InputGroup.Text>
                <Select
                  value={agente}
                  name='medio_comunicacion'
                  placeholder='Selecciona opciones'
                  options={agentes_mostrar}
                  onChange={(value) =>
                    setState(
                      (prevState) => ({ ...prevState, agente: value }),
                      () => handlePagination(1)
                    )
                  }
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </InputGroup>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <IfPermission action='crear'>
              <NuevoAsesor
                agentes={agentes}
                agente={agente}
                soluciones_solicitadas={soluciones_solicitadas}
                medios_comunicaciones={medios_comunicaciones}
                loadRuleta={handleSearch}
              />
            </IfPermission>
          </Col>
        </Row>
      </Search>
      <Row>
        <Col>
          {moduleList}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

RuletaAsesores.propTypes = {};

export default RuletaAsesores;
