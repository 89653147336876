import { DivFormGroup } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon, Loading, RowsNotFound } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import { CbPagination } from '@controls';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';

const New = ({ onSeleccionarCompra, compraId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [compras, setCompras] = useState([]);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [pagination] = useState(20);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [fechaInicioMoment, setFechaInicioMoment] = useState(moment(moment().format('YYYY-MM-01')).toDate());
  const [fechaFinMoment, setFechaFinMoment] = useState(moment(moment().format('YYYY-MM-DD')).toDate());

  useEffect(() => {
    onOpenModalSeleccionar();
  }, []);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    onOpenModalSeleccionar();
  };

  const onChangePagina = (pagina) => {
    setActivePage(pagina);
    onOpenModalSeleccionar();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDatepickerChange = (dates) => {
    const [start, end] = dates;
    setFechaInicioMoment(start);
    setFechaFinMoment(end);
    setFechaInicio(moment(start, 'YYYY-MM-DD').utc());
    setFechaFin(moment(end).isValid() ? moment(end, 'YYYY-MM-DD').utc() : null);
    if (start && end) onOpenModalSeleccionar();
  };

  const onOpenModalSeleccionar = async (e) => {
    e && e.preventDefault();
    const page = activePage * pagination - pagination;
    setShowModal(true);
    setIsLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      tipoCompraId: compraId,
    };
    const { data, count } = await postRequest({ url: `compras/search`, params: { page, limit: pagination }, body });
    setCompras(data);
    setCount(count);
    setIsLoading(false);
  };

  const handleClickSeleccionar = async (index, checked, e) => {
    e.stopPropagation();
    const compra = compras.find((x, i) => i === index);
    const {
      data: { encabezado, detalle },
    } = await getRequest({ url: `compra/${compra._id}` });
    if (detalle.filter((x) => x.tipo_partida === 'EQUIPO').length > 0) {
      setShowModal(false);
      onSeleccionarCompra({ ...encabezado, detalle });
    } else {
      notification({
        title: 'Información inválida',
        message: 'Esta compra no cuenta con equipos',
        type: 'error',
      });
    }
  };

  const onSelect = () => {};

  const rows =
    compras.length > 0 ? (
      compras.map((x, i) => (
        <tr key={i} onClick={(e) => handleClickSeleccionar(i, !x.selected, e)} style={{ cursor: 'pointer' }}>
          <td>{x.tipoCompraId.nombre}</td>
          <td>{x.numero_compra}</td>
          <td>{moment(x.fecha).local().format('YYYY-MM-DD')}</td>
          <td>{x.provider.name}</td>
          <td>{x.sucursal.name}</td>
          <td>$ {format(x.importe, 2)}</td>
        </tr>
      ))
    ) : (
      <RowsNotFound message='Sin compras' colSpan={11} />
    );

  return (
    <>
      <Modal size='large' show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Compras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md='12'>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md='4'>
                      <DivFormGroup>
                        <InputGroup>
                          <InputGroup.Text>Tipo compra</InputGroup.Text>
                          <SelectTipoCompra name='tipoCompraId' value={compraId} onChange={onSelect} />
                        </InputGroup>
                      </DivFormGroup>
                    </Col>
                    <Col md='4'>
                      <DivFormGroup>
                        <InputGroup>
                          <InputGroup.Text>Buscar</InputGroup.Text>
                          <input type='text' className='form-control' placeholder='Buscar ...' value={search} onChange={onChangeSearch} />
                          <Button type='submit'>
                            <span className='glyphicon glyphicon-search' icon='search' aria-hidden='true'></span>
                          </Button>
                        </InputGroup>
                      </DivFormGroup>
                    </Col>
                    <Col xs={4} md={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <Icon icon='calendar' title='Desde' />
                        </InputGroup.Text>
                        <DatePicker
                          selectsRange
                          startDate={fechaInicioMoment}
                          endDate={fechaFinMoment}
                          onChange={handleDatepickerChange}
                          maxDate={new Date()}
                          placeholder='Start Date'
                          className='form-control'
                          wrapperClassName='form-control'
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Loading />
          ) : (
            <Card>
              <div className='table-responsive'>
                <table className='table table-striped align-middle'>
                  <thead>
                    <tr>
                      <th width='5%'>Tipo compra</th>
                      <th width='5%'># Compra</th>
                      <th width='5%'>Fecha</th>
                      <th>Proveedor</th>
                      <th width='10%'>Sucursal</th>
                      <th width='5%'>Importe</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </Card>
          )}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={onChangePagina} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className='mr-5 pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

New.propTypes = {
  onSeleccionarCompra: PropTypes.func.isRequired,

  compraId: PropTypes.string.isRequired,
  tipos_compras: PropTypes.array.isRequired,
};

export default New;
