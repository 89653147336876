import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const clienteSchemaUpdate = Joi.object({
  regimen: Joi.string().valid('F', 'M', 'E', 'I').required().label('Tipo de Régimen'),
  numero_cliente: Joi.string().optional().label('Número de Cliente'),
  rfc: Joi.string().required().label('RFC'),
  razon_social: Joi.string().required().label('Razón Social'),
  name: Joi.string().allow(null, '').when('regimen', { is: 'F', then: Joi.required() }).label('Nombre'),
  paterno: Joi.string().allow(null, '').when('regimen', { is: 'F', then: Joi.required() }).label('Apellido Paterno'),
  materno: Joi.string().allow(null, '').when('regimen', { is: 'F', then: Joi.required() }).label('Apellido Materno'),
  nombre_comercial: Joi.string().optional().allow(null, '').label('Nombre Comercial'),
  tipoEstablecimientoId: Joi.string().optional().label('Tipo de Establecimiento').regex(R_MONGO_ID),
  rubroId: Joi.string().optional().label('Rubro').regex(R_MONGO_ID),
  email: Joi.string().allow(null, '').optional().label('Correo Electrónico'),
  phone: Joi.string().optional().allow(null, '').label('Teléfono'),
  mobile: Joi.string().optional().allow(null, '').label('Celular'),
  clasification: Joi.string().optional().label('Clasificación').regex(R_MONGO_ID),
  comentarios: Joi.string().optional().allow(null, '').label('Comentarios'),
  regimen_fiscal: Joi.string().optional().allow(null, '').label('Régimen Fiscal').regex(R_MONGO_ID),
  uso_cfdi: Joi.string().optional().allow(null, '').label('Uso de CFDI').regex(R_MONGO_ID),
  listCreditos: Joi.array().items(
    Joi.object({
      _id: Joi.string().optional().label('ID Crédito'),
      limite_credito: Joi.number().required().label('Limite'),
      plazo: Joi.date().required().label('Plazo'),
    })
  ),
  listReferencias: Joi.array().items(
    Joi.object({
      _id: Joi.string().optional().label('ID Referencia'),
      referencia: Joi.string().required().label('Referencia'),
      bancoId: Joi.string().required().label('Banco').regex(R_MONGO_ID),
    })
  ),
}).messages(MESSAGES);

export function validateUpdate(cliente) {
  console.log('cliente', cliente);
  return clienteSchemaUpdate.validate(cliente);
}
