import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteRubros from './DeleteRubros';
import EditRubros from './EditRubros';

const ListRubros = ({ catalogRubros }) => {
  const renderRows = () => {
    return catalogRubros.map((row, item) => {
      const { _id, rubro } = row;

      return (
        <tr key={item}>
          <td>
            <strong>{rubro}</strong>
          </td>
          <td>
            <IfPermission action='eliminar-rubros'>
              <DeleteRubros id={item} rubroId={_id} rubro={rubro}  />
            </IfPermission>
            <IfPermission action='editar-rubros'>
              <EditRubros id={item} rubroId={_id} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListRubros.propTypes = {
  catalogRubros: PropTypes.array.isRequired,
};

export default ListRubros;
