import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { urlPhoto } from '@utils/api';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbModal from '@cbcomponents/CbModal';
import { Card, Col, Row } from 'react-bootstrap';

const List = ({ carpetas, archivos, path, onPath }) => {
  const [showModalFile, setShowModalFile] = useState(false);
  const [fileSrcModal, setFileSrcModal] = useState('');
  const [typeFile, setTypeFile] = useState('');

  const onClick = (log, e) => {
    e.preventDefault();
    if (log.type === 'folder') {
      onPath(log.path);
    } else {
      if (log.file.match(/\.(jpeg|jpg|gif|png|svg)$/) != null) {
        setShowModalFile(true);
        setFileSrcModal(`${urlPhoto()}${log.path}`);
        setTypeFile('image');
      } else if (log.file.match(/\.(pdf)$/) != null) {
        setShowModalFile(true);
        setFileSrcModal(`${urlPhoto()}${log.path}`);
        setTypeFile('pdf');
      }
    }
  };

  const onClickPath = (path, i, e) => {
    const algo = path
      .split('/')
      .filter((x, index) => index <= i)
      .join('/');
    e.preventDefault();
    onPath(algo);
  };

  const renderFile = (log) => {
    if (log.type === 'folder') {
      return <Icon icon='folder-close' />;
    }
    return <Icon icon='file' />;
  };

  const renderRows = (logs) => {
    return logs.map((log, item) => (
      <tr key={item} className='' onClick={(e) => onClick(log, e)}>
        <td>
          {renderFile(log)} &nbsp; {log.file}
        </td>
        <td>
          <small>{log.size}</small>
        </td>
        <td>
          <small>{moment(log.birthtime).format('YYYY-MM-DD HH:mm')}</small>
        </td>
      </tr>
    ));
  };

  const closeModalImagen = () => {
    setShowModalFile(false);
    setFileSrcModal('');
  };

  const rows = renderRows([
    ...carpetas.map((carpeta, index) => ({ ...carpeta, type: 'folder', index })),
    ...archivos.map((archivo, index) => ({ ...archivo, type: 'file', index })),
  ]);

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col xs='6'>
              <Card.Title>
                {path.split('/').map((x, i) => (
                  <React.Fragment key={i}>
                    <a onClick={(e) => onClickPath(x, i, e)}> /{x ? x : 'files'}</a>
                  </React.Fragment>
                ))}
              </Card.Title>
            </Col>
          </Row>
        </Card.Header>
        <CbTableResponsive>
          <thead>
            <tr>
              <th width='5%' className='text-center'>
                &nbsp;
              </th>
              <th width='10%'>Tamaño</th>
              <th width='10%'>Creación</th>
            </tr>
          </thead>
          <CbTableBody colSpan={3}>{rows}</CbTableBody>
        </CbTableResponsive>
      </Card>
      <CbModal show={showModalFile} onClose={closeModalImagen} title='Evidencia'>
        {typeFile === 'pdf' ? (
          <embed src={fileSrcModal} type='application/pdf' width='100%' height='600px' />
        ) : (
          <img className='img-responsive' src={fileSrcModal} />
        )}
      </CbModal>
    </>
  );
};

List.propTypes = {
  logs: PropTypes.array.isRequired,
  onPath: PropTypes.func.isRequired,
  carpetas: PropTypes.array.isRequired,
  archivos: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

export default List;
