import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import EditarSeguimiento from './EditarSeguimiento';
import Nuevo from './Nuevo';
import Table from './Table';
import { Row, Col, InputGroup, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SelectAgente from '@components/forms/SelectAgente';
import SelectMotivo from '@components/forms/SelectMotivo';
import Select from '@components/forms/Select';

const Seguimientos = () => {
  const params = useParams();
  const [state, setState] = useState({
    list: [],
    loading: true,
    activePage: 1,
    fechaInicio: moment(moment().format('YYYY-MM-01')).utc().format(),
    fechaFin: moment().set('hours', 23).set('minute', 59).set('second', 59).utc().format(),
    count: 0,
    pagination: 20,
    search: '',
    agenteObj: null,
    agenteId: null,
    agenteAsignadoObj: null,
    agenteAsignadoId: null,
    agenteName: '',
    estatus: '0',
    estatus_seguimientos: [
      { value: '0', label: 'Todos los estatus' },
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'PENDIENTE', label: 'PENDIENTE' },
      { value: 'FINALIZADO', label: 'FINALIZADO' },
    ],
    agente: null,
    agentes: [],
    motivo: '0',
    motivos: [],
    seguimientoId: null,
  });

  useEffect(() => {
    inicializar();
    if (params.seguimientoId) {
      setState((prevState) => ({ ...prevState, seguimientoId: params.seguimientoId }));
    }
  }, [params.seguimientoId]);

  const inicializar = async () => {
    const {
      data: { agente, agentes, motivos },
    } = await getRequest({ url: `agenda-seguimiento-catalogos` });
    setState((prevState) => ({
      ...prevState,
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: agente.nombre,
        })),
      ],
      motivos: [
        {
          value: '0',
          label: 'Todos los motivos',
        },
        ...motivos.map((motivo) => ({
          value: motivo._id,
          label: motivo.nombre,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : null,
      agenteAsignadoId: '0',
    }));
    handleSearch();
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    handleSearch();
  };

  const handleSearch = async () => {
    const { pagination, activePage, search, agenteId, agenteAsignadoId, fechaFin, fechaInicio, estatus, motivo } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      estatus,
      agenteId,
      agenteAsignadoId,
      motivo,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data, count } = await postRequest({ url: 'seguimientos/search', params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      list: data,
      count: count,
      loading: false,
    }));
  };

  const onSearch = (params = { search: '' }) => {
    setState((prevState) => ({
      ...prevState,
      search: params.search,
      activePage: 1,
      fechaInicio: params.fechaInicio,
      fechaFin: params.fechaFin,
    }));
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
    handleSearch();
  };

  const { loading, pagination, count, activePage, agenteId, estatus, estatus_seguimientos, motivo, seguimientoId } = state;

  return (
    <Module title='Seguimientos'>
      <Search onSearch={onSearch} searchOnStopType withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='11'>
            <Row>
              <Col sm='4'>
                <IfPermission action='TodosAsesores'>
                  <DivFormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <Icon icon='user' title='Agente' />
                      </InputGroup.Text>
                      <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
                    </InputGroup>
                  </DivFormGroup>
                </IfPermission>
              </Col>
              <Col sm='4'>
                <DivFormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <Icon icon='tags' title='Motivo' />
                    </InputGroup.Text>
                    <SelectMotivo value={motivo} onChange={(value) => onSelect('motivo', value)} />
                  </InputGroup>
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <Icon icon='th-list' title='Estatus' />
                    </InputGroup.Text>
                    <Select value={estatus} options={estatus_seguimientos} onChange={(value) => onSelect('estatus', value)} />
                  </InputGroup>
                </DivFormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm='1'>
            <Nuevo load={handleSearch} />
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <Card>
          <Table {...state} loadData={handleSearch} />
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Card>
      </Loading>
      {seguimientoId && <EditarSeguimiento seguimientoId={seguimientoId} load={handleSearch} />}
    </Module>
  );
};

Seguimientos.propTypes = {};

export default Seguimientos;
