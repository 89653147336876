import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Table from './Table';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from 'react-select';
import { Col, InputGroup, Row } from 'react-bootstrap';

const List = () => {
  const [state, setState] = useState({
    recepcionescompras: [],
    loading: true,
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    sucursalId: '0',
    sucursales: [],
    tipoCompraId: '0',
    tipos_compras: [],
    agenteId: '0',
    agentes: [],
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    estatus_recepciones: {
      INICIADO: 'INICIADO',
      EN_PROCESO: 'EN PROCESO',
    },
    estatus: [],
    estatus_recepcion: '0',
    listAlmacenes: [],
  });

  const loadCatalogos = useCallback(async (callback) => {
    const {
      data: { agente },
    } = await getRequest({ url: `recepcionescompras-catalogs` });
    setState((prevState) => ({
      ...prevState,
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_recepciones).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : null,
      sucursalId: agente ? agente.sucursalId : null,
      almacenId: agente ? agente.almacenId : null,
    }));
    if (callback) callback();
  }, []);

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, [loadCatalogos]);

  const handleSearch = useCallback(() => {
    const { pagination, activePage, search, fechaInicio, fechaFin, sucursalId, tipoCompraId, agenteId, estatus_recepcion, almacenId } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    (async () => {
      const body = {
        search,
        fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
        fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
        sucursalId,
        tipoCompraId,
        agenteId,
        estatus: estatus_recepcion,
        almacenId,
      };
      const { data, count } = await postRequest({ url: `recepcionescompras/search`, params: { page, limit: pagination }, body });
      setState((prevState) => ({
        ...prevState,
        recepcionescompras: data,
        count: count,
        loading: false,
      }));
    })();
  }, [state]);

  const onSearch = (params = { search: '' }) => {
    setState((prevState) => ({
      ...prevState,
      search: params.search,
      fechaInicio: params.fechaInicio,
      fechaFin: params.fechaFin,
      activePage: 1,
    }));
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
    handleSearch();
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    handlePagination(1);
  };

  const { loading, pagination, count, activePage, sucursalId, tipoCompraId, agenteId, estatus_recepcion, estatus, almacenId } = state;

  return (
    <Module title='Recepciónes de compras'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tags' title='Tipos de compras' />
              </InputGroup.Text>
              <SelectTipoCompra value={tipoCompraId} onChange={(value) => onSelect('tipoCompraId', value)} name='tipoCompraId' />
            </InputGroup>
          </Col>
          <Col sm={2}>
            <IfPermission action='TodasSucursales'>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='home' title='sucursales' />
                </InputGroup.Text>
                <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} name='sucursalId' />
              </InputGroup>
            </IfPermission>
          </Col>
          <Col sm={2}>
            <IfPermission action='TodosAlmacenes'>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='equalizer' title='almacenes' />
                </InputGroup.Text>
                <SelectAlmacen value={almacenId} onChange={(value) => onSelect('almacenId', value)} name='almacenId' />
              </InputGroup>
            </IfPermission>
          </Col>
          <Col sm={2}>
            <IfPermission action='TodosAgentes'>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='user' title='Agentes' />
                </InputGroup.Text>
                <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} name='agenteId' />
              </InputGroup>
            </IfPermission>
          </Col>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tags' title='Estatus' />
              </InputGroup.Text>
              <Select
                value={estatus_recepcion}
                onChange={(value) => onSelect('estatus_recepcion', value)}
                name='estatus_recepcion'
                options={estatus}
              />
            </InputGroup>
          </Col>
          <Col sm={2}>
            <IfPermission action='crear'>
              <Link to='/recepciones-compras/nuevo' className='btn btn-success pull-right'>
                <Icon icon='plus' /> Nueva
              </Link>
            </IfPermission>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <Table {...state} loadRecepcionCompras={handleSearch} loadData={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

List.propTypes = {};

export default List;
