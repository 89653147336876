import React, { useState, useEffect } from 'react';
import { Module, Icon } from '@stateless';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStoreNotification } from '@stores/catalogs.store.js';
import { Card, Row, Col, Form } from 'react-bootstrap';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid,
  borderRadius: '5px',
  background: isDragging ? '#e4e4e4' : '#f4f4f4',
  marginBottom: '2px',
  borderLeft: '2px solid #e6e7e8',
  color: '#444',
  position: 'relative',
  ...draggableStyle,
});

const Tareas = () => {
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    summary: {},
    status: 0,
    notExits: true,
    agenda: [],
    clientes: 0,
    facturas: 0,
    pagos: 0,
    servicios: 0,
    video: '',
    items: {
      1: [],
      2: [],
      3: [],
      4: [],
    },
    names: {
      1: 'POR HACER',
      2: 'HACIENDO',
      3: 'HECHAS',
      4: 'CANCELADAS',
    },
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {};

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(state.items[source.droppableId], source.index, destination.index);
      setState((prevState) => ({
        ...prevState,
        items: {
          ...prevState.items,
          [source.droppableId]: items,
        },
      }));
    } else {
      const result = move(state.items[source.droppableId], state.items[destination.droppableId], source, destination);
      setState((prevState) => ({
        ...prevState,
        items: {
          ...prevState.items,
          [source.droppableId]: result[source.droppableId],
          [destination.droppableId]: result[destination.droppableId],
        },
      }));
    }
  };

  const onRemoveItem = (name, index, e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        [name]: prevState.items[name].filter((x, i) => i !== index),
      },
    }));
    notification({ title: 'Atención', message: '¡Tarea eliminada!', type: 'success' });
  };

  const onAddItem = (name, e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        [name]: [
          ...prevState.items[name],
          {
            id: `item-${name + prevState.items[name].length}`,
            content: `item ${name + prevState.items[name].length}`,
            title: `item ${name + prevState.items[name].length}`,
          },
        ],
      },
    }));
    notification({ title: 'Atención', message: '¡Tarea agregada!', type: 'success' });
  };

  const onChangeInput = (name, index, e) => {
    setState((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        [name]: prevState.items[name].map((x, i) => {
          if (i === index) {
            return { ...x, [e.target.name]: e.target.value };
          }
          return x;
        }),
      },
    }));
  };

  const onChangeName = (name, e) => {
    setState((prevState) => ({
      ...prevState,
      names: {
        ...prevState.names,
        [name]: e.target.value,
      },
    }));
  };

  const onRemoveList = (name, e) => {
    e.preventDefault();
    const { items, names } = state;
    delete items[name];
    delete names[name];
    setState({
      items: { ...items },
      names: { ...names },
    });
    notification({ title: 'Atención', message: '¡Lista eliminada!', type: 'success' });
  };

  const onClickAgregarLista = (e) => {
    e.preventDefault();
    const { items, names } = state;
    const name = Object.keys(state.items).length + 1;
    setState({
      items: { ...items, [name]: [] },
      names: { ...names, [name]: '' },
    });
    notification({ title: 'Atención', message: '¡Lista agregada!', type: 'success' });
  };

  const renderList = (item, list, name) => {
    return (
      <Col md={3} xs={6} key={name}>
        <Card>
          <Card.Header style={{ position: 'relative' }}>
            <Card.Title>
              <a href='#' onClick={(e) => onRemoveList(name, e)} style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '12px' }}>
                <Icon icon='remove' style={{ color: '#888' }} />
              </a>
              <Form.Control
                value={state.names[name]}
                name='title'
                style={{
                  background: 'transparent',
                  border: 'none',
                  padding: '0px',
                  boxShadow: 'none',
                  fontSize: '22px',
                  height: '22px',
                  fontWeight: '700',
                }}
                onChange={(e) => onChangeName(name, e)}
              />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Droppable droppableId={name}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {list.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <a
                            href='#'
                            onClick={(e) => onRemoveItem(name, index, e)}
                            style={{ position: 'absolute', right: '18px', top: '5px', fontSize: '11px' }}
                          >
                            <Icon icon='pencil' style={{ color: '#555' }} />
                          </a>
                          <a
                            href='#'
                            onClick={(e) => onRemoveItem(name, index, e)}
                            style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '12px' }}
                          >
                            <Icon icon='remove' style={{ color: '#555' }} />
                          </a>
                          <input
                            value={item.title}
                            name='title'
                            className='form-control'
                            style={{
                              background: 'transparent',
                              border: 'none',
                              padding: '0px',
                              boxShadow: 'none',
                              height: '18px',
                              fontWeight: '700',
                            }}
                            onChange={(e) => onChangeInput(name, index, e)}
                          />
                          <p>
                            <textarea
                              value={item.content}
                              name='content'
                              className='form-control'
                              style={{ background: 'transparent', border: 'none', padding: '0px', boxShadow: 'none', height: '18px' }}
                              onChange={(e) => onChangeInput(name, index, e)}
                            ></textarea>
                          </p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <a href='#' className='btn btn-block btn-default' onClick={(e) => onAddItem(name, e)}>
                    <Icon icon='plus' /> Nueva tarea
                  </a>
                </div>
              )}
            </Droppable>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const { items } = state;
  const listArray = [];
  for (let item in items) listArray.push(renderList(item, items[item], item));
  return (
    <Module title='Inicio'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          {listArray}
          <Col md={3} xs={6}>
            <Card>
              <a href='#' style={{ textDecoration: 'none' }} onClick={onClickAgregarLista}>
                <Card.Header className='text-center'>
                  <Card.Title>
                    <Icon icon='plus' /> Nueva lista
                  </Card.Title>
                </Card.Header>
              </a>
            </Card>
          </Col>
        </Row>
      </DragDropContext>
    </Module>
  );
};

Tareas.propTypes = {};

export default Tareas;
