import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Saving } from '@stateless';
import { Button } from 'react-bootstrap';

const CbModal = ({ show, onSave, onClose, isLoading, children, title, size = 'xl', extraButtons = [], hasFile, formRef, fullscreen = false, textSave }) => {
  const buttons = extraButtons.map((button, index) => (
    <Button key={index} variant={button.type} className='mr-5' onClick={button.onClick} disabled={button.disabled}>
      {button.text}
    </Button>
  ));
  const encType = hasFile ? 'multipart/form-data' : 'application/x-www-form-urlencoded';
  return (
    <Modal show={show} onHide={onClose} size={size || 'xl'} fullscreen={fullscreen}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form encType={encType} ref={formRef}>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          {onClose && (
            <Button type='button' variant='light' onClick={onClose} className='mr-5' disabled={isLoading}>
              Cerrar
            </Button>
          )}
          {buttons}
          {onSave && (
            <Button type='button' onClick={onSave} variant='primary' className='mr-5' disabled={isLoading}>
              {textSave || 'Guardar'}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

CbModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  extraButtons: PropTypes.array,
  hasFile: PropTypes.bool,
  formRef: PropTypes.object,
  fullscreen: PropTypes.bool,
  textSave: PropTypes.string,
};

export default CbModal;
