import React from 'react';
import List from './List';

const Notificaciones = () => {
  return <List />;
};

Notificaciones.propTypes = {};

export default Notificaciones;
