import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from '@components/forms/Select';

const New = ({ load, tiposPartidas }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    descripcion: '',
    nombre: '',
    codigo: '',
    tipoPartidaId: null,
    numero_compra_actual: 1,
    tiene_sucursal: false,
    sucursalId: null,
    tiene_almacen: false,
    almacenId: null,
    isLoading: false,
    tipoPartida: {},
  });

  const close = () => {
    setState({
      showModal: false,
      descripcion: '',
      codigo: '',
      nombre: '',
      tipoPartidaId: null,
      numero_compra_actual: 1,
      tiene_sucursal: false,
      sucursalId: null,
      tiene_almacen: false,
      almacenId: null,
      isLoading: false,
      tipoPartida: {},
    });
  };

  const open = () => {
    setState((prevState) => ({ ...prevState, showModal: true, isLoading: false }));
  };

  const onChange = (event) => {
    const { name, value, checked, type } = event.target;
    let { almacenId, sucursalId } = state;
    if (name === 'tiene_almacen' && checked === false) almacenId = '';
    if (name === 'tiene_sucursal' && checked === false) {
      sucursalId = '';
      almacenId = '';
    }
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
      almacenId,
      sucursalId,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { descripcion, nombre, codigo, numero_compra_actual, tiene_sucursal, sucursalId, tiene_almacen, almacenId, tipoPartidaId } = state;
    const data = {
      descripcion: descripcion.trim().toUpperCase(),
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      numero_compra_actual,
      tiene_sucursal,
      sucursalId,
      tiene_almacen,
      almacenId,
      tipoPartidaId,
    };

    if (validations(data)) {
      await postRequest({ url: `tipos-compras`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (params.tipoPartidaId === null) {
      validate.success = false;
      validate.message = 'Tipo de partida es requerido.';
    } else if (isEmpty(params.descripcion + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerida.';
    } else if (params.numero_compra_actual === 0) {
      validate.success = false;
      validate.message = 'Número de compra debe ser mayor a cero.';
    } else if (params.tiene_almacen && !params.almacenId) {
      validate.success = false;
      validate.message = 'Almacén es requerido.';
    } else if (params.tiene_sucursal && !params.sucursalId) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onSelect = (campo, value) => {
    let { tiene_almacen, almacenId, tipoPartida } = state;
    if (campo === 'tipoPartidaId') {
      if (value) {
        tipoPartida = { ...tiposPartidas.find((x) => x._id === value) };
        if (tipoPartida.usa_almacen) {
          tiene_almacen = false;
          almacenId = null;
        }
      } else {
        tipoPartida = {};
      }
    }
    setState((prevState) => ({
      ...prevState,
      tiene_almacen,
      almacenId,
      tipoPartida,
      [campo]: value,
    }));
  };

  const {
    showModal,
    descripcion,
    nombre,
    numero_compra_actual,
    tiene_sucursal,
    sucursalId,
    tiene_almacen,
    almacenId,
    tipoPartidaId,
    isLoading,
    tipoPartida,
    codigo,
  } = state;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Tipo de Compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleRegister}>
            <Row>
              <Col sm='4'>
                <FormInput title='Código' placeholder='Código' required name='codigo' onChange={onChange} value={codigo} />
              </Col>
              <Col sm='4'>
                <FormInput title='Nombre' placeholder='Nombre' required name='nombre' onChange={onChange} value={nombre} />
              </Col>
              <Col sm='4'>
                <DivFormGroup name='tipo_partida' title='Tipo partida' required>
                  <Select
                    name='tipoPartidaId'
                    value={tipoPartidaId}
                    options={tiposPartidas.map((tipoPartida) => ({
                      value: tipoPartida._id,
                      label: `${tipoPartida.nombre}`,
                    }))}
                    onChange={(value) => onSelect('tipoPartidaId', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <FormInput title='Descripción' placeholder='Descripción' required name='descripcion' onChange={onChange} value={descripcion} />
              </Col>
            </Row>
            {tipoPartida.usa_almacen ? (
              <Row>
                <Col sm='6'>
                  <DivFormGroup name='tiene_sucursal' title='Sucursal' required={tiene_sucursal}>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' checked={tiene_sucursal} name='tiene_sucursal' onChange={onChange} />
                      </InputGroup.Text>
                      <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} disabled={!tiene_sucursal} />
                    </InputGroup>
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='tiene_almacen' title='Almacén' required={tiene_almacen}>
                    <InputGroup>
                      <InputGroup.Text>
                        <input type='checkbox' disabled={!tiene_sucursal} checked={tiene_almacen} name='tiene_almacen' onChange={onChange} />
                      </InputGroup.Text>
                      <SelectAlmacen
                        name='almacenId'
                        value={almacenId}
                        onChange={(value) => onSelect('almacenId', value)}
                        disabled={!tiene_almacen}
                        filter={(x) => !x.sucursalId || x.sucursalId === sucursalId?._id}
                      />
                    </InputGroup>
                  </DivFormGroup>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col sm='12'>
                <FormInput
                  type='number'
                  title='Número compra'
                  required
                  name='numero_compra_actual'
                  onChange={onChange}
                  value={numero_compra_actual}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleRegister} variant='primary' className='pull-right mr-5' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} className='pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,
  tiposPartidas: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  sucursales: PropTypes.array.isRequired,
};

export default New;
