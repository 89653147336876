import If from '@controls/If';
import Search from '@controls/Search';
import React, { useState, useEffect, Fragment } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Table from './Table';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import { Col, Row } from 'react-bootstrap';

const List = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(60);
  const [search, setSearch] = useState('');
  const [almacenId, setAlmacenId] = useState(null);
  const [almacenes, setAlmacenes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { agente, almacenes },
      } = await getRequest({ url: `existencias-almacen-catalogos` });
      setAlmacenes([
        ...almacenes.map((almacen) => ({
          value: almacen._id,
          label: `${almacen.name}`,
        })),
        {
          value: '0',
          label: 'Todas las almacenes',
        },
      ]);
      setAlmacenId(agente ? agente.almacenId : null);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (almacenId) {
      loadData();
    }
  }, [almacenId, activePage, search]);

  const loadData = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: `existencias-almacen/${almacenId}/search`, params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  };

  const handleSearch = ({ search }) => {
    setSearch(search);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const onSelect = (name, value) => {
    if (name === 'almacenId') {
      setAlmacenId(value);
      setActivePage(1);
    }
  };

  return (
    <Fragment>
      <Module title='Existencias a almacen'>
        <Search onSearch={handleSearch}>
          <Row>
            <Col>
              <SelectAlmacen value={almacenId} almacenes={almacenes} onSelect={(value) => onSelect('almacenId', value)} name='almacenId' />
            </Col>
          </Row>
        </Search>
        <If condition={!loading}>
          <If.Then>
            <Table list={list} loadData={loadData} />
            <div className='text-center'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
            </div>
          </If.Then>
          <If.Else>
            <br />
            <Loading />
          </If.Else>
        </If>
      </Module>
    </Fragment>
  );
};

List.propTypes = {};

export default List;
