import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import CbBadge from './CbBadge';

const CbDomicilio = ({ address }) => {
  if (!address) return null;
  const {
    monitoreo = '', 
    nombre = '',
    calle = '',
    exterior = '',
    interior = '',
    colonia = '',
    coloniaId = 0,
    codigo_postal = '',
    municipio = '',
    estado = '',
    estadoId = 0,
    pais = '',
    longitud,
    latitud,
    tiene_posicion = false,
  } = address;

  const domicilio = `${calle} ${exterior}${interior ? `, ${interior}` : ''}`;
  const coloniaCP = `${colonia && coloniaId ? colonia : ''}${codigo_postal && codigo_postal !== '0' ? `, C.P. ${codigo_postal}` : ''}`;
  const ciudad = `${municipio}${estado && estadoId ? `, ${estado}` : ''}`;

  return (
    <small>
      <p className='mb-0' style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', wordBreak: 'break-word', hyphens: 'auto', textAlign: 'left', fontSize: '0.8rem' }}>
        {tiene_posicion && latitud && longitud && (
          <>
            <a href={`https://maps.google.com/?q=${latitud},${longitud}`} target='_blank' rel='noreferrer'>
              <Icon icon='map-marker' style={{ color: 'green' }} />{' '}
            </a>
          </>
        )}
        {nombre && <b>{ monitoreo && <span className='mr-2'><CbBadge>{monitoreo}</CbBadge></span>  }{nombre} <br/></b> }
        {domicilio}
        {colonia && <br />}
        {coloniaCP}
        {municipio && <br />}
        {ciudad} {pais}
      </p>
    </small>
  );
};

CbDomicilio.propTypes = {
  address: PropTypes.shape({
    monitoreo: PropTypes.string,
    nombre: PropTypes.string,
    calle: PropTypes.string,
    exterior: PropTypes.string,
    interior: PropTypes.string,
    colonia: PropTypes.string,
    coloniaId: PropTypes.number,
    codigo_postal: PropTypes.string,
    municipio: PropTypes.string,
    estado: PropTypes.string,
    estadoId: PropTypes.number,
    pais: PropTypes.string,
    longitud: PropTypes.number,
    latitud: PropTypes.number,
    tiene_posicion: PropTypes.bool,
  }),
};

export default CbDomicilio;
