import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Button, Card, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { Icon, Saving } from '@stateless';
import { getRequest, postUploadRequest, urlPhoto } from '@utils/api';
import { useStoreNotification } from '@stores/catalogs.store';

const Documentos = ({ seguimientoId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [imgSrcModal, setImgSrcModal] = useState('');
  const [showModalImg, setShowModalImg] = useState(false);
  const [documentos, setDocumentos] = useState([]);

  const close = () => {
    setShowModal(false);
    setDescription('');
  };

  const open = async () => {
    const { data } = await getRequest({ url: `seguimientos/${seguimientoId}` });
    setShowModal(true);
    setIsLoading(false);
    setDocumentos(
      data.documentos.map((x) => ({
        imagePreviewUrl: urlPhoto() + x.url,
        ...x,
      }))
    );
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      documentos,
      description,
    };

    const form = new FormData();
    for (let i = 0; i < documentos.length; i++) {
      const evidencia = documentos[i];
      if (evidencia._id) {
        form.append('images_data', evidencia._id);
      } else {
        form.append('images', evidencia.file);
      }
    }

    if (validations(data)) {
      await postUploadRequest({ url: `seguimientos/cliente/documentos/${seguimientoId}`, form });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (params.documentos.length === 0) {
      validate.success = false;
      validate.message = 'Nombre es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const closeModalImagen = () => {
    setShowModalImg(false);
    setImgSrcModal('');
  };

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip>Documentos</Tooltip>}>
        <Button onClick={open} variant='outline-primary' type='button'>
          <Icon icon='file' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Body>
                  <ImageUploading multiple onChange={setDocumentos} value={documentos} allowNonImageType={true} dataURLKey='imagePreviewUrl'>
                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                      <div className='upload__image-wrapper row'>
                        <div {...dragProps} className={`image-item col-sm-${documentos.length > 0 ? 4 : 12}`}>
                          <div
                            style={{
                              minHeight: '150px',
                              position: 'relative',
                              cursor: 'pointer',
                              border: isDragging ? 'dashed 1px red' : 'dashed 1px black',
                            }}
                            onClick={onImageUpload}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                fontSize: '10px',
                                transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                              }}
                            >
                              <Icon icon='plus' style={{ fontSize: '4em' }} />
                              <br />
                              {isDragging ? 'Suelte la evidencia aquí porfavor' : 'Click aquí o suelte la evidencia aquí'}
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item col-sm-4' style={{ position: 'relative' }}>
                            <div className='image-item__btn-wrapper' style={{ position: 'absolute', top: '10px', right: '30px' }}>
                              <a
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageRemove(index);
                                }}
                              >
                                <Icon icon='trash' style={{ fontSize: '1.5em', color: 'red' }} />
                              </a>
                            </div>
                            <img
                              src={image.imagePreviewUrl}
                              alt=''
                              width='100%'
                              className='img-responsive'
                              onClick={() => {
                                setShowModalImg(true);
                                setImgSrcModal(image.imagePreviewUrl);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleRegister} variant='primary' className='pull-right' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalImg} onHide={closeModalImagen}>
        <Modal.Header closeButton>
          <Modal.Title>Evidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='img-responsive' src={imgSrcModal} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModalImagen} className='pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Documentos.propTypes = {
  seguimientoId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Documentos;
