import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalImprimir = ({ showModalDownload, closeDownload, agregarOtra, onAceptar }) => {
  return (
    <Modal show={showModalDownload} onHide={closeDownload}>
      <Modal.Header closeButton>
        <Modal.Title>Descargar orden de servicio</Modal.Title>
      </Modal.Header>
      <Modal.Footer style={{ textAlign: 'center' }}>
        <Button onClick={closeDownload} variant='light' className='mr-5' type='button'>
          &nbsp;Cerrar&nbsp;
        </Button>
        <Button onClick={agregarOtra} variant='primary' className='mr-5' type='button'>
          &nbsp;Nueva&nbsp;
        </Button>
        <Button onClick={onAceptar} variant='primary' className='mr-5' type='button'>
          Imprimir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalImprimir.propTypes = {
  showModalDownload: PropTypes.bool,
  closeDownload: PropTypes.func,
  agregarOtra: PropTypes.func,
  onAceptar: PropTypes.func,
};

export default ModalImprimir;
