import React, { Fragment } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';

const CardActividadesAgente = ({ actividadesAgentePorDia }) => {
  if (actividadesAgentePorDia.length == 0) return null;
  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <div className='timeline'>
              {actividadesAgentePorDia.map((x) => (
                <>
                  <div key={x.fecha} className='time-label'>
                    <span className='bg-green'>{x.fecha}</span>
                  </div>
                  {x.actividades.map((y) => (
                    <div key={y._id}>
                      <Icon icon='time bg-blue fas' />
                      <div className='timeline-item'>
                        <span className='time'>
                          <Icon icon='time' /> {moment(y.created).local().format('HH:mm')}
                        </span>
                        <h3 className='timeline-header'>
                          <a href='#'>{y.customerId ? y.customerId.razon_social : ''}</a> {y.titulo}
                        </h3>
                        <div className='timeline-body'>{y.descripcion}</div>
                      </div>
                    </div>
                  ))}
                </>
              ))}
              <div>
                <Icon icon='time bg-blue fas' />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

CardActividadesAgente.propTypes = {
  actividadesAgentePorDia: PropTypes.array,
};

export default CardActividadesAgente;
