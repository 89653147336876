import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';
import { Button } from 'react-bootstrap';

const RowAlternativo = ({ equipo, onChangeAlternativo, onRemoveItemAlternativo }) => {
  const loadOptions = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `equipments-autocomplete`, params: { query: inputValue } });
    callback(data);
  };

  const onRemoveItemAlternativoHandler = () => {
    onRemoveItemAlternativo();
  };

  return (
    <tr>
      <td>
        <DivFormGroup>
          <AsyncSelect
            value={equipo.equipoId}
            loadOptions={loadOptions}
            onChange={(itemSelect) => onChangeAlternativo({ equipoId: itemSelect })}
            getOptionLabel={(x) => `${x.code} ${x.name}`}
            getOptionValue={(x) => x._id}
            isClearable
            placeholder='Buscar...'
          />
        </DivFormGroup>
      </td>
      <td>
        <Button onClick={onRemoveItemAlternativoHandler} variant='danger' className='pull-right' type='button'>
          <Icon icon='trash' />
        </Button>
      </td>
    </tr>
  );
};

RowAlternativo.propTypes = {
  equipo: PropTypes.object,
  onChangeAlternativo: PropTypes.func,
  onRemoveItemAlternativo: PropTypes.func,
};

export default RowAlternativo;
