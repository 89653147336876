import { OXXO, PREELABORADO, SPEI, TARJETA } from '@config/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import { pathFilesApi, postRequest } from '@utils/api';
import { printReferenceOxxo, renderNombreCliente } from '@utils/general';
import { parseAmountStripe } from '@utils/parseCost';
import SendReferenciaEmail from './SendReferenciaEmail';
import { Button, Card, ButtonGroup } from 'react-bootstrap';

const TableReferences = ({ catalogReferences }) => {
  const handleOnClickRegenerarReferencia = async (referenceId) => {
    await postRequest({ url: `references/recreate/${referenceId}` });
  };

  const renderRows = (catalogReferences) => {
    return catalogReferences.map((row, item) => {
      const { created, pdf, order, reference, source, customerId, status, paid_at, _id, facturas } = row;
      let orderId = order !== null && order.id !== null ? order.id : '';
      let orderAmount = order !== null && order.amount !== null ? order.amount : '';
      let orderCharges = order !== null && order.charges !== null ? order.charges.data : '';

      let customeName = renderNombreCliente(customerId);
      const oxxo = printReferenceOxxo(reference);
      const path = `${pathFilesApi()}${pdf}`;

      let tdOrden = <td></td>;

      let logo = '';

      if (source === OXXO || source === 'OXXO - PAY') {
        logo = 'oxxopay_brand.png';
        tdOrden = (
          <td>
            <strong>{oxxo}</strong>
          </td>
        );

        if (typeof facturas !== 'undefined') {
          if (facturas.length > 0) {
            tdOrden = (
              <td>
                <strong>{orderId}</strong>
                <br />
                <ul className='list-unstyled'>
                  <li>
                    Facturas <br />
                    <strong>
                      {facturas.map((f, i) => (
                        <span key={i}>
                          <Link to={`/facturas/detalle/${f._id}`}>
                            {f.serie}
                            {f.numero}{' '}
                          </Link>
                          {i !== facturas.length - 1 ? ', ' : ''}{' '}
                        </span>
                      ))}
                    </strong>
                  </li>
                </ul>
              </td>
            );
          }
        }
      } else if (source === SPEI) {
        logo = 'spei_brand.png';
        tdOrden = (
          <td>
            <strong>CLABE {orderCharges[0].payment_method.clabe}</strong>
            <br />
            <strong>Referencia {orderCharges[0].order_id}</strong>
          </td>
        );
      } else if (source === TARJETA) {
        tdOrden = (
          <td>
            <strong>{orderId}</strong>
          </td>
        );

        if (orderCharges[0].payment_method.brand === 'visa') {
          logo = 'visa.png';
        } else if (orderCharges[0].payment_method.brand === 'american_express') {
          logo = 'americanexpress.png';
        } else if (
          orderCharges[0].payment_method.brand === 'mastercard' ||
          orderCharges[0].payment_method.brand === 'master_card' ||
          orderCharges[0].payment_method.brand === 'mc'
        ) {
          logo = 'mastercard.png';
        }

        if (typeof facturas !== 'undefined') {
          if (facturas.length > 0) {
            tdOrden = (
              <td>
                <strong>{orderId}</strong>
                <ul className='list-unstyled'>
                  <li>
                    Facturas <br />
                    <strong>
                      {facturas.map((f, i) => (
                        <span key={i}>
                          <Link to={`/facturas/detalle/${f._id}`} rel='noreferrer' target='_blank'>
                            {f.serie}
                            {f.numero}{' '}
                          </Link>
                          {i !== facturas.length - 1 ? ', ' : ''}{' '}
                        </span>
                      ))}
                    </strong>
                  </li>
                </ul>
              </td>
            );
          }
        }
      } else if (source === PREELABORADO) {
        logo = '';
        tdOrden = (
          <td>
            <strong>Preelaborado</strong>
          </td>
        );

        if (typeof facturas !== 'undefined') {
          if (facturas.length > 0) {
            tdOrden = (
              <td>
                <strong>Preelaborado</strong>
                <ul className='list-unstyled'>
                  <li>
                    Facturas <br />
                    <strong>
                      {facturas.map((f, i) => (
                        <span key={i}>
                          <Link to={`/facturas/detalle/${f._id}`}>
                            {f.serie}
                            {f.numero}{' '}
                          </Link>
                          {i !== facturas.length - 1 ? ', ' : ''}{' '}
                        </span>
                      ))}
                    </strong>
                  </li>
                </ul>
              </td>
            );
          }
        }
      }

      let labelStatus = { label: 'Pendiente', color: 'info' };

      if (status === 'PAGADO') {
        labelStatus = { label: 'Pagada', color: 'success' };
      }

      let labelAmount = <strong>$ {parseAmountStripe(orderAmount)}</strong>;

      if (source === PREELABORADO) {
        labelAmount = <strong>$ {parseAmountStripe(row.order.amount)}</strong>;
      }

      return (
        <tr key={item}>
          <td>
            <img src={`./img/${logo}`} width='40px' alt='' className='img-thumbnail img-responsive' />
          </td>
          <td>{reference}</td>
          {tdOrden}

          <td>{labelAmount}</td>
          <td>
            <span className={`label label-${labelStatus.color}`}>{labelStatus.label}</span>
            {status === 'PAGADO' ? (
              <span>
                <br />
                <small>Pagada el {moment.unix(paid_at).format('DD/MMMM/YYYY')}</small>
              </span>
            ) : null}
          </td>
          <td>
            {customerId ? (
              <small>
                <strong>
                  <Link to={`/clientes/detalle/${customerId._id}`}>{customeName}</Link>
                </strong>
                <br />
                <a title='Correo electrónico' href={`mailto:${customerId.email}`}>
                  {customerId.email}
                </a>
                <br />
                <a title='Celular' href={`tel:${customerId.mobile}`}>
                  {customerId.mobile}
                </a>
              </small>
            ) : null}
          </td>
          <td>
            <small>{moment(created).local().format('YYYY-MM-DD')}</small>
          </td>

          {source !== TARJETA ? (
            <td>
              <ButtonGroup>
                <SendReferenciaEmail
                  id={item}
                  referenceId={_id}
                  reference={reference}
                  email={customerId ? customerId.email : ''}
                  contactos={customerId && customerId.listContactos ? customerId.listContactos : []}
                />
                <Button onClick={() => handleOnClickRegenerarReferencia(_id)} className='btn btn-outline-success'>
                  <Icon icon='refresh' />
                </Button>
                <a href={path} target='_blank' rel='noopener noreferrer' className='btn btn-outline-warning'>
                  <Icon icon='download-alt' />
                </a>
              </ButtonGroup>
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      );
    });
  };

  let rows = null;

  if (catalogReferences.length > 0) {
    rows = renderRows(catalogReferences);
  } else {
    rows = <RowsNotFound message='Aún no hay referencias generadas.' colSpan={7} />;
  }

  return (
    <Card>
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th width='5%'></th>
            <th width='10%'>Referencia</th>
            <th width='25%'>Facturas</th>
            <th width='10%'>Monto</th>
            <th width='10%'>Estado</th>
            <th width='20%'>Cliente</th>
            <th width='10%'>Fecha</th>
            <th width='10%'></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Card>
  );
};

TableReferences.propTypes = {
  catalogReferences: PropTypes.array.isRequired,
};

export default TableReferences;
