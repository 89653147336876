import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { getRequest, postRequest } from '@utils/api';
import PropTypes from 'prop-types';
import { CustomerOption } from '@stateless';
import { DivFormGroup } from '@controls';

const FormEditarSegimiento = ({
  tipo,
  cliente,
  nombre,
  paterno,
  materno,
  telefonos,
  correos,
  agente,
  agentes_asignados,
  agentes,
  motivo,
  resultadoVisita,
  motivos,
  resultados,
  estatus,
  opciones_estatus_seguimientos,
  titulo,
  onChange,
}) => {
  const onChangeSeguimiento = (e) => {
    onChange({ [e.target.name]: e.target.value });
  };

  const onChangeCliente = (selectedOption) => {
    onChange({ cliente: selectedOption });
  };

  const handleChangeTelefonos = (selectedOptions) => {
    onChange({ telefonos: selectedOptions });
  };

  const handleChangeCorreos = (selectedOptions) => {
    onChange({ correos: selectedOptions });
  };

  const onChangeAgente = (selectedOption) => {
    onChange({ agente: selectedOption });
  };

  const onChangeAgentesAsignados = (selectedOptions) => {
    onChange({ agentes_asignados: selectedOptions });
  };

  const loadOptionsAgentes = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `agentes-autocomplete`, params: { query: inputValue } });
    callback(data.map((x) => ({ _id: x._id._id, nombre: x._id.nombre })));
  };

  const loadOptions = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `customers-autocomplete`, params: { query: inputValue } });
    callback(data);
  };

  const handleChangeMotivo = async (selectedOption, tipo) => {
    if (tipo.action === 'create-option') {
      const motivo = await handleRegisterMotivo({ nombre: selectedOption.value });
      onChange({ motivo: selectedOption, motivos: [...motivos, motivo] });
    } else {
      onChange({ motivo: selectedOption });
    }
  };

  const handleChangeResultado = async (selectedOption, tipo) => {
    if (tipo.action === 'create-option') {
      const resultado = await handleRegisterResultado({ nombre: selectedOption.value });
      onChange({ resultadoVisita: selectedOption, resultados: [...resultados, resultado] });
    } else {
      onChange({ resultadoVisita: selectedOption });
    }
  };

  const handleRegisterResultado = async (body) => {
    const { data } = await postRequest({ url: 'resultados-visitas', body });
    return data;
  };

  const handleRegisterMotivo = async (body) => {
    const { data } = await postRequest({ url: 'motivos', body });
    return data;
  };

  const onSelect = (name, value) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col sm='12'>
              <DivFormGroup name='cliente' title={tipo} required>
                <AsyncSelect
                  value={cliente}
                  loadOptions={loadOptions}
                  onChange={onChangeCliente}
                  getOptionLabel={(x) => `${x.razon_social}`}
                  getOptionValue={(x) => x._id}
                  isClearable
                  placeholder='Buscar...'
                  components={{ Option: CustomerOption }}
                />
              </DivFormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col sm='4'>
              <DivFormGroup name='nombre' title='Nombre' required>
                <input className='form-control' type='text' name='nombre' placeholder='Nombre' value={nombre} onChange={onChangeSeguimiento} />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <DivFormGroup name='paterno' title='Apellido paterno'>
                <input
                  className='form-control'
                  type='text'
                  name='paterno'
                  placeholder='Apellido paterno'
                  value={paterno}
                  onChange={onChangeSeguimiento}
                />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <DivFormGroup name='materno' title='Apellido materno'>
                <input
                  className='form-control'
                  type='text'
                  name='materno'
                  placeholder='Apellido materno'
                  value={materno}
                  onChange={onChangeSeguimiento}
                />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <DivFormGroup name='telefonos' title='Télefono(s)'>
                <CreatableSelect
                  isClearable
                  isMulti
                  value={telefonos}
                  onChange={handleChangeTelefonos}
                  options={telefonos}
                  searchPromptText='Resultados...'
                  placeholder={`Agregar télefonos...`}
                  noResultsText='No se encontraron resultados'
                />
              </DivFormGroup>
            </Col>
            <Col sm='6'>
              <DivFormGroup name='correos' title='Correo(s)'>
                <CreatableSelect
                  isClearable
                  isMulti
                  value={correos}
                  onChange={handleChangeCorreos}
                  options={correos}
                  searchPromptText='Resultados...'
                  placeholder={`Agregar correos...`}
                  noResultsText='No se encontraron resultados'
                />
              </DivFormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col sm='6'>
              <DivFormGroup name='agente' title='Solicitante' required>
                <AsyncSelect
                  isClearable
                  value={agente}
                  onChange={onChangeAgente}
                  options={agentes}
                  loadOptions={loadOptionsAgentes}
                  getOptionLabel={(x) => `${x.nombre}`}
                  getOptionValue={(x) => x._id}
                  placeholder='Seleccione asesor'
                  noResultsText='No se encontraron resultados'
                />
              </DivFormGroup>
            </Col>
            <Col sm='6'>
              <DivFormGroup name='agente' title='Responsables' required>
                <AsyncSelect
                  isClearable
                  isMulti
                  value={agentes_asignados}
                  onChange={onChangeAgentesAsignados}
                  options={agentes}
                  loadOptions={loadOptionsAgentes}
                  getOptionLabel={(x) => `${x.nombre}`}
                  getOptionValue={(x) => x._id}
                  placeholder='Seleccione asesores'
                  noResultsText='No se encontraron resultados'
                />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <DivFormGroup name='motivo' title='Motivo'>
                <CreatableSelect
                  isClearable
                  value={motivo}
                  onChange={handleChangeMotivo}
                  options={motivos}
                  getOptionLabel={(x) => `${x.nombre}`}
                  getOptionValue={(x) => x._id}
                  searchPromptText='Resultados...'
                  placeholder={`Seleccione o agregue motivo...`}
                  noResultsText='No se encontraron resultados'
                />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <DivFormGroup name='resultadoVisita' title='Resultado'>
                <CreatableSelect
                  isClearable
                  value={resultadoVisita}
                  onChange={handleChangeResultado}
                  options={resultados}
                  searchPromptText='Resultados...'
                  getOptionLabel={(x) => `${x.nombre}`}
                  getOptionValue={(x) => x._id}
                  placeholder={`Seleccione o agregue resultado...`}
                  noResultsText='No se encontraron resultados'
                />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <DivFormGroup name='estatus' title='Estatus'>
                <Select
                  value={estatus}
                  onChange={onSelect.bind(this, 'estatus')}
                  options={opciones_estatus_seguimientos}
                  placeholder='Seleccione..'
                />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <DivFormGroup name='titulo' title='Titulo' required>
                <input className='form-control' type='text' name='titulo' placeholder='Titulo' value={titulo} onChange={onChangeSeguimiento} />
              </DivFormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

FormEditarSegimiento.propTypes = {
  tipo: PropTypes.string,
  cliente: PropTypes.object,
  nombre: PropTypes.string,
  paterno: PropTypes.string,
  materno: PropTypes.string,
  telefonos: PropTypes.array,
  correos: PropTypes.array,
  agente: PropTypes.object,
  agentes_asignados: PropTypes.array,
  agentes: PropTypes.array,
  motivo: PropTypes.object,
  motivos: PropTypes.array,
  estatus: PropTypes.object,
  opciones_estatus_seguimientos: PropTypes.array,
  titulo: PropTypes.string,
  onChange: PropTypes.func,
  resultadoVisita: PropTypes.object,
  resultados: PropTypes.array,
};

export default FormEditarSegimiento;
