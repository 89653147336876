import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';

const Detail = ({ lista }) => {
  const [showModal, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='list' title='Detalle' />
      <CbModal show={showModal} onClose={close} title='Detalle'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='5%'>Cantidad</th>
              <th width='10%'>Código</th>
              <th width='30%'>Equipo</th>
              <th width='10%'>Unidad de medida</th>
            </tr>
          </thead>
          <tbody>
            {lista.map((e, i) => {
              const { cantidad } = e;
              let { equipoId } = e;
              if (!equipoId) equipoId = {};
              return (
                <tr key={i}>
                  <td style={{ textAlign: 'right' }}>
                    <u>{cantidad > 0 ? cantidad : ''}</u>
                  </td>
                  <td>
                    <Link to={`/equipos/detalle/${equipoId._id}`}>{equipoId.code}</Link>
                  </td>
                  <td>{equipoId.name}</td>
                  <td>{equipoId.equipmentsMeasureId.measure}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CbModal>
    </>
  );
};

Detail.propTypes = {
  id: PropTypes.number.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lista: PropTypes.array.isRequired,
};

export default Detail;
