import { Confirmation, RedirectTooltip } from '@controls';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Icon } from '@stateless';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';
import DetailCustomer from '@components/customers/DetailCustomer';
import Domicilio from '@components/customers/Domicilio';
import CambiarEstado from './CambiarEstado';
import CambioDeFechas from './CambioDeFechas';
import Documentos from './Documentos';
import IconDropdown from '@controls/IconDropdown';
import EditarCuenta from '@pages/catalogs/cuentas-monitoreos/components/Edit.js';

const ESTATUS_CUENTA_MONITOREO = [
  { value: 'EN_SERVICIO', label: 'EN SERVICIO', type: 'success' },
  { value: 'BAJA', label: 'BAJA', type: 'danger' },
  { value: 'ACTIVACION_EN_PROCESO', label: 'ACTIVACION EN PROCESO', type: 'secondary' },
  { value: 'BAJA_EN_PROCESO', label: 'BAJA EN PROCESO', type: 'warning' },
  { value: 'SUSPENDIDO', label: 'SUSPENDIDO', type: 'warning' },
  { value: 'CUENTA_DUPLICADA', label: 'CUENTA DUPLICADA', type: 'danger' },
  { value: 'BAJA_SIN_DESCONEXION', label: 'BAJA SIN DESCONEXION', type: 'warning' },
  { value: 'CAMBIO_DE_CUENTA', label: 'CAMBIO DE CUENTA', type: 'warning' },
  { value: 'DATOS_PENDIENTES', label: 'DATOS PENDIENTES', type: 'secondary' },
  { value: 'CANCELADO', label: 'CANCELADO', type: 'danger' },
  { value: 'PERSONALIZADO', label: 'PERSONALIZADO', type: 'secondary' },
  { value: 'PENDIENTE', label: 'PENDIENTE', type: 'secondary' },
];

const RowAgendaMonitoreo = (props) => {
  const {
    _id,
    nombre_agenda,
    referencia,
    periodicidad,
    cuentas,
    estatus,
    sucursal_id,
    cliente_id,
    siguiente_emision,
    ultima_emision,
    total,
    requiere_factura,
    detalles,
    loadData,
  } = props;
  const [colapsar, setColapsar] = React.useState(false);
  const customer = cliente_id;
  const estado = ESTATUS_CUENTA_MONITOREO.find((estado) => estado.value === estatus);
  let estatus_siguiente_emision = 'success';

  if (moment(ultima_emision).startOf('month').diff(moment().startOf(), 'month') === 0) {
    estatus_siguiente_emision = 'primary';
  } else if (moment(siguiente_emision).startOf('month').diff(moment().startOf('month'), 'month') < 1) {
    estatus_siguiente_emision = 'danger';
  }

  const handleColapsarClick = (e) => {
    e.preventDefault();
    setColapsar(!colapsar);
  };

  return (
    <>
      <tr>
        <td className='text-center'>{requiere_factura && <CbBadge type='primary'>FACTURAR</CbBadge>}</td>
        <td>
          <DetailCustomer customer={customer} />
        </td>
        <td>
          <OverlayTrigger placement='top' overlay={<Tooltip>{referencia}</Tooltip>}>
            <div>{referencia.length > 50 ? referencia.substring(0, 50) + '...' : referencia}</div>
          </OverlayTrigger>
        </td>
        <td>
          {sucursal_id && (
            <>
              <CbBadge type='primary'>{sucursal_id.name}</CbBadge>
              <br />
            </>
          )}
          <CbBadge type='success'>{periodicidad}</CbBadge>
        </td>
        <td>
          <CbBadge type='info'>
            <b>Anterior:</b> {moment(ultima_emision).isValid() && moment(ultima_emision).format('DD/MM/YYYY')}
          </CbBadge>
          <br />
          <CbBadge type={estatus_siguiente_emision}>
            <b>Proxima:</b> {moment(siguiente_emision).isValid() && moment(siguiente_emision).format('DD/MM/YYYY')}
          </CbBadge>
        </td>
        <td>$ {format(total, 2)}</td>
        <td>
          {detalles.length === 0 && <CbBadge type='danger'>SIN DETALLES</CbBadge>}
          {cuentas.length === 1 && <CbBadge type='primary mr-5'>{cuentas.length} CUENTA</CbBadge>}
          {cuentas.length > 1 && <CbBadge type='primary mr-5'>{cuentas.length} CUENTAS</CbBadge>}
          <CbBadge type={estado.type}>{estado.label}</CbBadge>
        </td>
        <td>
          <ButtonGroup>
            <Button onClick={handleColapsarClick} variant='outline-success' size='sm'>
              <Icon icon={colapsar ? 'triangle-top' : 'triangle-bottom'} />
            </Button>
            <IfPermission action='editar'>
              <RedirectTooltip
                id={1}
                url={`/agendas-monitoreos/editar/${_id}`}
                labelTooltip='Editar'
                icon='pencil'
                className='btn btn-outline-primary btn-sm'
              />
            </IfPermission>
            <CambiarEstado itemId={_id} estatus={estatus} {...props} />
            <DropdownButton as={ButtonGroup} title={<IconDropdown />} variant='outline-primary' size='sm'>
              <IfPermission action='eliminar'>
                <Confirmation
                  id={_id}
                  btnIcon='trash'
                  typeRequest='deleteRequest'
                  isMenu
                  btnType='outline-danger'
                  action='Eliminar'
                  url={`agendas-monitoreos/${_id}`}
                  description={`¿Confirma que desea eliminar la agenda de monitoreo: <strong><u>${nombre_agenda}</u></strong>?`}
                  loadData={() => loadData({ page: 0, pagination: 50 })}
                  btnSize='sm'
                />
              </IfPermission>
              <Documentos agenda={props} />
              <CambioDeFechas {...props} agenda={props} />
            </DropdownButton>
          </ButtonGroup>
        </td>
      </tr>
      {colapsar && (
        <tr>
          <td colSpan='8' style={{ margin: 0, padding: 0 }}>
            <table className='table table-striped align-middle' style={{ fontSize: '12px' }}>
              <thead>
                <tr>
                  <th>Cuenta</th>
                  <th>Domicilio</th>
                  <th>Tipo</th>
                  <th width='5%'>Estado</th>
                  <th width='5%'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cuentas.map(({ cuenta_id }, index) => (
                  <tr key={index}>
                    <td>
                      {cuenta_id.dealer_id && (
                        <>
                          <CbBadge type='warning'>{cuenta_id.dealer_id?.nombre}</CbBadge> &nbsp;{' '}
                        </>
                      )}
                      {cuenta_id.numero} - {cuenta_id.nombre}
                    </td>
                    <td>
                      <Domicilio domicilio={cuenta_id.domicilio_monitoreo_id} />
                    </td>
                    <td>
                      <OverlayTrigger placement='top' overlay={<Tooltip>{cuenta_id.tipo_monitoreo_id?.nombre}</Tooltip>}>
                        <CbBadge type='primary mr-5'>{cuenta_id.tipo_monitoreo_id?.clave}</CbBadge>
                      </OverlayTrigger>
                      <CbBadge type='warming mr-5'>{cuenta_id.contrato}</CbBadge>
                    </td>
                    <td>
                      <CbBadge type={ESTATUS_CUENTA_MONITOREO.find((x) => x.value === cuenta_id.estatus).type}>
                        {ESTATUS_CUENTA_MONITOREO.find((x) => x.value === cuenta_id.estatus).label}
                      </CbBadge>
                    </td>
                    <td>
                      <ButtonGroup className='pull-right'>
                        <EditarCuenta itemId={cuenta_id._id} {...props} />
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

RowAgendaMonitoreo.propTypes = {
  factura: PropTypes.object.isRequired,
  onClickColapsar: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  tipos_monitoreos: PropTypes.array.isRequired,
  sat_usos_cfdis: PropTypes.array.isRequired,
  dealers: PropTypes.array.isRequired,
  agenda: PropTypes.object.isRequired,
  estatus: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  nombre_agenda: PropTypes.string.isRequired,
  referencia: PropTypes.string.isRequired,
  periodicidad: PropTypes.string.isRequired,
  cuentas: PropTypes.array.isRequired,
  sucursal_id: PropTypes.object.isRequired,
  cliente_id: PropTypes.object.isRequired,
  siguiente_emision: PropTypes.string.isRequired,
  ultima_emision: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  requiere_factura: PropTypes.bool.isRequired,
  detalles: PropTypes.array.isRequired,
};

export default RowAgendaMonitoreo;
