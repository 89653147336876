import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import Concepto from './Concepto';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

const ESTATUS_PEDIDOS = {
  INICIADO: 'INICIADO',
  EN_PROCESO: 'EN PROCESO',
  CANCELADO: 'CANCELADO',
  ENTREGADO: 'ENTREGADO',
  FINALIZADO: 'FINALIZADO',
};

const DetalleTraspaso = () => {
  const { pedidoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `pedido/${pedidoId}` });
      setData(data);
    };
    fetchData();
  }, [pedidoId]);

  const renderDetalle = () => {
    const { detalle } = data;
    const detalles = detalle.map((d, i) => {
      const {
        codigo,
        descripcion,
        unidadMedidaId: { measure },
        cantidad,
        ubicacionId,
      } = d;
      return (
        <tr key={i}>
          <td className='text-right'>
            <b>{cantidad}</b>
          </td>
          <td>{codigo}</td>
          <td className='text-justify'>{descripcion}</td>
          <td>{measure}</td>
          <td>{ubicacionId && ubicacionId.nombre}</td>
        </tr>
      );
    });
    return (
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Código</th>
            <th>Descripción</th>
            <th>Medida</th>
            <th>Ubicación</th>
          </tr>
        </thead>
        <tbody>{detalle.length > 0 ? detalles : <RowsNotFound message='Sin detalle' colSpan={11} />}</tbody>
      </table>
    );
  };

  const renderData = () => {
    const {
      encabezado: {
        tipoPedidoId,
        numero_pedido,
        numero_cotizacion,
        fecha,
        estatus_pedido,
        moneda,
        tipo_cambio,
        referencia,
        estado,
        cancelacion,
        sucursal,
        almacen,
        agente_solicitante,
        customer,
        observaciones,
      },
    } = data;
    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Concepto label='Número de pedido:' valor={numero_pedido} />
                <Concepto label='Número de cotización:' valor={numero_cotizacion} />
                <Concepto label='Tipo de pedido:' valor={tipoPedidoId.nombre} />
                <Concepto label='Agente solicitante:' valor={agente_solicitante.nombre} />
                <Concepto label='Cliente:' valor={customer.razon_social} />
                <Concepto label='RFC:' valor={customer.rfc} />
                <Concepto label='Domicilio:' valor={textDomicilio(customer.domicilio)} />
                {estado === 'CANCELADA' ? (
                  <div>
                    <p>{cancelacion.nota}</p>
                    <small>
                      Fecha de cancelación <em>{moment(cancelacion.fecha).local().format('DD/MM/YYYY')}</em>
                    </small>
                  </div>
                ) : null}
              </Col>
              <Col>
                <div className='pull-right'>
                  <Concepto label='Fecha:' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                  <Concepto label='Tipo de cambio:' valor={'$' + tipo_cambio} />
                  <Concepto label='Moneda:' valor={moneda} />
                  <Concepto label='Referencia:' valor={referencia} />
                  <Concepto label='Sucursal:' valor={sucursal.name} />
                  <Concepto label='Almacén:' valor={almacen.name} />
                  <Concepto label='Estatus:' valor={ESTATUS_PEDIDOS[estatus_pedido]} />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>{renderDetalle()}</Card>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Concepto label='Observaciones:' />
                {observaciones}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const numero_pedido = data && data.encabezado ? data.encabezado.numero_pedido : '';
  return (
    <Module title={`Pedido #${numero_pedido}`} onClickBack={window.history.back} loading={!data}>
      {data && renderData()}
    </Module>
  );
};

DetalleTraspaso.propTypes = {};

export default DetalleTraspaso;
