import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { pathFilesApi } from '@utils/api';
import { format } from '@utils/parseCost';
import { FileDownIcon } from 'lucide-react';

const RowQuotation = ({ quotation }) => {
  const {
    _id,
    clave,
    pdf,
    levantamientoId: { sellerId },
    created,
    total,
  } = quotation;
  const path = `${pathFilesApi()}${pdf}`;

  return (
    <tr>
      <td>{clave}</td>
      <td>
        <small>
          {sellerId.name} {sellerId.fatherLastName} {sellerId.motherLastName}
        </small>
      </td>
      <td>{moment(created).local().format('DD/MM/YYYY HH:mm')}</td>
      <td>
        <strong>$ {format(total, 2)}</strong>
      </td>
      <td>
        <RedirectTooltip id={1} labelTooltip='Detalle' url={`/cotizacion/${_id}`} icon='zoom-in' className='btn btn-outline-primary' />
        {typeof pdf != 'undefined' && pdf !== null && pdf !== '' ? (
          <a href={path} target='_blank' rel='noopener noreferrer' className='btn btn-outline-warning'>
            <FileDownIcon />
          </a>
        ) : null}
      </td>
    </tr>
  );
};

RowQuotation.propTypes = {
  customerId: PropTypes.string.isRequired,
  quotation: PropTypes.object.isRequired,
};

export default RowQuotation;
