import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput, Input, Label } from '@controls';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import AgregarDetalle from '../pedidos/AgregarDetalle';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import InputNumber from '@components/forms/InputNumber';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from '@components/forms/Select';

const New = () => {
  const { addNotification: notification } = useStoreNotification();

  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'FINALIZADA',
    sucursalId: null,
    almacenId: null,
    proveedor: null,
    productoName: '',
    productoObj: null,
    productos: [],
    tipoCambio: 0,
    existencias: [],
    estatus_ajustes: [
      { value: 'INICIADA', label: 'INICIADA' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADA', label: 'FINALIZADA' },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { sucursales, almacenes, ubicaciones, agente },
      } = await getRequest({ url: `mermas-catalogos` });
      const {
        data: { numero_ajuste },
      } = await getRequest({ url: `ajustes/siguienteNumero` });
      setState((prevState) => ({
        ...prevState,
        sucursales: sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
        agenteObj: { value: agente._id, label: agente.nombre },
        agenteId: agente._id,
        agenteName: agente.nombre,
        sucursalId: agente.sucursalId,
        almacenId: agente.almacenId,
        almacenes,
        ubicaciones,
        isLoadingForm: false,
        numeroOrden: numero_ajuste,
      }));
    };

    fetchData();
  }, []);

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus_ajuste + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almácen es requerido.';
    } else if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (params.detalles.length === 0) {
      validate.success = false;
      validate.message = 'No hay partidas asociados al ajuste';
    }

    params.detalles.forEach((detalle) => {
      if (isEmpty(detalle.descripcion + '')) {
        validate.success = false;
        validate.message = 'Descripción es requerido.';
      } else if (isEmpty(detalle.cantidad + '') || detalle.cantidad <= 0) {
        validate.success = false;
        validate.message = 'Cantidad es requerido.';
      } else if (detalle.tipo_movimiento === 'SALIDA' && detalle.cantidad > detalle.existencia) {
        validate.success = false;
        validate.message = 'Cantidad, debe ser menor o igual a la existencia.';
      } else if (isEmpty(detalle.precio + '') || detalle.precio <= 0) {
        validate.success = false;
        validate.message = 'Precio es requerido.';
      } else if (!isMongoId(detalle.ubicacionId + '')) {
        validate.success = false;
        validate.message = 'Ubicación es requerido.';
      }
    });

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'principal' || name === 'caducidad') {
      setState((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { numeroOrden, fecha, estatus, sucursalId, almacenId, agenteId, observaciones, detalles } = state;

    const data = {
      numero_ajuste: numeroOrden,
      fecha,
      estatus_ajuste: estatus,
      sucursalId,
      almacenId,
      agenteId,
      observaciones,
      detalles: detalles.map((detalle) => ({ ...detalle })),
    };
    if (validations(data)) {
      await postRequest({ url: `ajustes`, body: data });
      setTimeout(() => navigate(0), 1000);
    }
  };

  const onChangeDetalle = (name, index, event) => {
    if (['cantidad'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: [
          ...prevState.detalles.map((x, i) => {
            if (i === index) x[name] = cleanNumber(event.target.value);
            return { ...x };
          }),
        ],
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...prevState.detalles.map((x, i) => {
          if (i === index) {
            x[name] = event.target.value;
          }
          return { ...x };
        }),
      ],
    }));
  };

  const onChangeTipoMovimiento = (index, value) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...prevState.detalles.map((x, i) => {
          if (i === index) {
            x.tipo_movimiento = value;
          }
          return { ...x };
        }),
      ],
    }));
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const renderDetalles = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado detalles.' colSpan={8} />;
    return state.detalles.map((detalle, i) => {
      return (
        <tr key={i}>
          <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.noIdentificador}</th>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
            <Input className='input-sm' value={detalle.descripcion} style={{ width: '250px' }} />
          </td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.measure}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.ubicacion}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.existencia}</td>
          <td>
            <Select
              name='tipo_movimiento'
              value={detalle.tipo_movimiento}
              options={[
                { value: 'SALIDA', label: 'Salida' },
                { value: 'ENTRADA', label: 'Entrada' },
              ]}
              className='form-control input-sm'
              style={{ width: '80px' }}
              onChange={onChangeTipoMovimiento.bind(this, i)}
            />
          </td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
            <InputNumber
              className='form-control input-sm'
              style={{ width: '80px' }}
              value={detalle.cantidad}
              onChange={onChangeDetalle.bind(this, 'cantidad', i)}
            />
          </td>
          <td>
            <a href='#' onClick={onClickRemoveDetalle.bind(this, i)} style={{ color: 'red' }}>
              <Icon icon='remove' />
            </a>
          </td>
        </tr>
      );
    });
  };

  const renderView = () => {
    const { numeroOrden, fecha, estatus, sucursalId, almacenId, agenteObj, isLoading, observaciones, estatus_ajustes } = state;
    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col sm={4}>
                <FormInput
                  title='Número de orden'
                  placeholder='Número de orden'
                  disabled
                  name='numeroOrden'
                  onChange={onChange}
                  value={numeroOrden}
                />
              </Col>
              <Col sm={4}>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </Col>
              <Col sm={4}>
                <DivFormGroup>
                  <Label name='estatus' required title='Estatus' />
                  <Select name='estatus' value={estatus} disabled options={estatus_ajustes} onChange={onSelect.bind(this, 'estatus')} />
                </DivFormGroup>
              </Col>
              <Col sm={4}>
                <DivFormGroup>
                  <Label name='sucursalId' required title='Sucursal' />
                  <SelectSucursal value={sucursalId} onChange={onSelect.bind(this, 'sucursalId')} />
                </DivFormGroup>
              </Col>
              <Col sm={4}>
                <DivFormGroup>
                  <Label name='almacenId' required title='Almacen' />
                  <SelectAlmacen
                    name='almacenId'
                    value={almacenId}
                    onChange={onSelect.bind(this, 'almacenId')}
                    filter={(x) => x.sucursalId === sucursalId?._id}
                  />
                </DivFormGroup>
              </Col>
              <Col sm={4}>
                <DivFormGroup>
                  <Label name='agenteName' required title='Agente' />
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col sm={12}>
                <AgregarDetalle onAgregarDetalle={onAgregarDetalle} almacenId={almacenId} />
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        <Card>
          <div className='table-responsive'>
            <table className='table table-striped align-middle '>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descripción</th>
                  <th>Unidad</th>
                  <th>Ubicacion </th>
                  <th>Existencia</th>
                  <th>Tipo Movimiento</th>
                  <th>Cantidad</th>
                  <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
              <tbody>{renderDetalles()}</tbody>
            </table>
          </div>
          <Card.Body>
            <Row>
              <Col sm={12}>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col sm={12}>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </>
    );
  };

  const onAgregarDetalle = (existencias) => {
    const { detalles } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...detalles,
        ...existencias.map((itemSelect) => {
          const producto = itemSelect.equipmentId;
          let measure = null,
            measureId = null;
          measure =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure
              ? producto.equipmentsMeasureId.measure
              : producto.equipmentsMeasureId;
          measureId =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId._id ? producto.equipmentsMeasureId._id : producto.equipmentsMeasureId;
          return {
            ...producto,
            equipmentId: producto._id,
            descripcion: producto.name.toUpperCase(),
            noIdentificador: producto.code,
            measure,
            measureId,
            ubicacionOrigen: itemSelect.ubicacionId.nombre,
            ubicacionOrigenId: itemSelect.ubicacionId._id,
            existenciaId: itemSelect._id,
            existencia: itemSelect.stock,
            cantidad: 1,
          };
        }),
      ],
    }));
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ajustes de inventario' title='Nuevo ajuste de inventario'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

New.propTypes = {};

export default New;
