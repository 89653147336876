import { Confirmation, DivFormGroup, Input, Label } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import DetailCustomer from '@components/customers/DetailCustomer';
import CbDomicilio from '@cbcomponents/CbDomicilio';
import MultiSelectAgentes from '@components/forms/MultiSelectAgentes';

const Edit = ({ ordenServicioId, visitaId, close, showModal }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    visitaId: null,
    ordenServicioId: null,
    ordenServicio: null,
    fecha_hora: moment().format('DD/MM/YYYY HH:mm'),
    horas_servicio: 1,
    observaciones: '',
    agente_id: [],
    fecha_hora_fin: moment().add(1, 'hours').format('YYYY-MM-DD HH:mm'),
    visita: {},
  });

  useEffect(() => {
    if (showModal) {
      loadData();
    }
  }, [showModal]);

  const closeModal = () => {
    setState({
      visitaId: null,
      ordenServicioId: null,
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: moment().add(1, 'hours').format('YYYY-MM-DD HH:mm'),
      horas_servicio: 1,
      observaciones: '',
      agentes: [],
      agente_id: [],
      visita: {},
    });
    close();
  };

  const loadData = async () => {
    const { data } = await getRequest({ url: `ordenes-servicio/visita/${visitaId}` });
    setState({
      visita: data,
      showModal: true,
      ordenServicioId,
      visitaId,
      ordenServicio: data.ordenServicioId,
      fecha_hora: moment(data.fecha_hora).local().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: data.fecha_hora_fin
        ? moment(data.fecha_hora_fin).local().format('YYYY-MM-DD HH:mm')
        : moment(data.fecha_hora).add(data.horas_servicio, 'hours').local().format('YYYY-MM-DD HH:mm'),
      horas_servicio: data.horas_servicio,
      agente_id: data.agentes_asignados,
      observaciones: data.observaciones,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const handleUptVisita = async () => {
    const { visitaId, fecha_hora, horas_servicio, agente_id, observaciones, fecha_hora_fin } = state;
    const data = {
      fecha_hora: Date.parse(fecha_hora),
      fecha_hora_fin: Date.parse(fecha_hora_fin),
      horas_servicio,
      agente_id: agente_id.map((x) => x._id),
      observaciones,
    };
    if (validations(data)) {
      await postRequest({ url: `ordenes-servicio/visitas/actualizar/${visitaId}`, body: data });
      closeModal();
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    } else if (params.agente_id.length <= 0) {
      validate.success = false;
      validate.message = 'Agentes son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onDeleted = () => {
    loadData();
    close();
  };

  const { agente_id, fecha_hora, horas_servicio, observaciones, ordenServicio } = state;

  return (
    <Modal size='large' show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Editar visita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm='12'>
            <Card className='mb-3'>
              <Card.Body>
                <DetailCustomer customer={ordenServicio?.cliente} />
                <CbDomicilio address={ordenServicio?.cliente?.domicilio_entrega} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <form>
          <Row>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='fecha_hora' title='Fecha inicio' />
                <Input type='datetime-local' name='fecha_hora' onChange={handleChange} value={fecha_hora} />
              </DivFormGroup>
            </Col>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='horas_servicio' title='Horas de servicio' />
                <Input type='number' name='horas_servicio' onChange={handleChange} value={horas_servicio} />
              </DivFormGroup>
            </Col>
            <Col sm='12'>
              <DivFormGroup>
                <Label name='observaciones' title='Observaciones' required />
                <textarea className='form-control' name='observaciones' onChange={handleChange} value={observaciones} />
              </DivFormGroup>
            </Col>
            <Col sm='12'>
              <DivFormGroup>
                <Label name='agente_id' required title='Agentes' />
                <MultiSelectAgentes value={agente_id} onChange={(value) => handleSelect('agente_id', value)} />
              </DivFormGroup>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Confirmation
          id={visitaId}
          btnIcon=''
          btnTitle='Eliminar'
          typeConfirm='danger'
          btnType='danger'
          action='Eliminar visita'
          url={`ordenes-servicio/visitas/eliminar/${ordenServicioId}/${visitaId}`}
          description={`¿Confirma que desea eliminar la <strong>visita: <u> ${observaciones}</u></strong>?`}
          loadData={onDeleted}
        />
        <Button onClick={closeModal} variant='light' className='pull-right mr-5' type='button'>
          Cerrar
        </Button>
        <Button onClick={handleUptVisita} type='button' variant='success' className='pull-right mr-5'>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Edit.propTypes = {
  ordenServicioId: PropTypes.string.isRequired,
  visitaId: PropTypes.string.isRequired,
  agentes: PropTypes.array.isRequired,

  loadData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default Edit;
