import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { /* Button, */ Button, Card, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { esCliente } from '@utils/cotizaciones';
import { hasPermission } from '@utils/permissions';
import { AreasDefault, InicializarArea } from '@utils/levantamientos';
// import RowArea from './RowArea';
import { validateUpdate } from '@validations/levantamientos';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectDomicilioCliente from '@components/forms/SelectDomicilioCliente';
import MultiSelectClasificacionLevantamiento from '@components/forms/MultiSelectClasificacionLevantamiento';
import SelectClienteProspecto from '@components/forms/SelectClienteProspecto';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Nuevo = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [state, setState] = useState({
    clasificaciones: [],
    activeClasificacion: null,
    idLevantamiento: null,
    showModalDownload: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    maximo_descuento: 0,
    observaciones: '',
    estatus: { value: 'INICIADA', label: 'INICIADA' },
    sucursal: null,
    cliente: null,
    tipo_cliente: { value: 'CLIENTE', label: 'CLIENTE' },
    estatus_levantamientos: [{ value: 'INICIADA', label: 'INICIADA' }],
    domicilios: [],
    domicilioId: null,
    anticipo: 0,
    tipoLevantamientoId: null,
    showModalImagen: false,
    clasificacionesId: [],
    indicaciones_cliente: '',
    areas: [...AreasDefault],
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `clasificadores-levantamientos/all` });
      const clasificaciones = data.filter((c) => c.subclasificaciones && c.subclasificaciones.length > 0);
      const activeClasificacion = clasificaciones.length > 0 ? clasificaciones[0] : null;
      setState((prevState) => ({
        ...prevState,
        clasificaciones,
        clasificacionesId: clasificaciones,
        activeClasificacion,
        areas: prevState.areas.map((area) => InicializarArea(area, clasificaciones)),
      }));
      inicializar();
    };
    fetchData();
  }, []);

  const inicializar = async () => {
    const {
      data: { agente, tipos_levantamientos },
    } = await getRequest({ url: `levantamientos-catalogos` });
    if (!agente) {
      notification({
        title: 'Información incompleta',
        message: 'Es necesario tener configurado el usuario como agente',
        type: 'error',
      });
      setTimeout(() => navigate('/'), 1000);
      return;
    }
    const tipoLevantamientoId = tipos_levantamientos.filter((x) => x.default)[0];
    setState((prevState) => ({
      ...prevState,
      tipoLevantamientoId,
      agente: agente,
      maximo_descuento: agente.maximo_descuento,
      sucursal: agente.sucursal,
    }));
    actualizarNumeroLevantamiento(tipoLevantamientoId._id);
  };

  const actualizarNumeroLevantamiento = async (tipoLevantamientoId) => {
    const { data } = await getRequest({ url: `levantamientos/siguienteNumero`, params: { tipoLevantamientoId } });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_levantamiento,
      isLoadingForm: false,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroOrden,
      fecha,
      estatus,
      sucursal,
      tipo_cliente,
      domicilioId,
      cliente,
      observaciones,
      agente,
      tipoLevantamientoId,
      // areas,
      clasificacionesId,
      // indicaciones_cliente,
    } = state;
    const body = {
      numero_levantamiento: numeroOrden.toString(),
      tipoLevantamientoId: tipoLevantamientoId?._id,
      tipo_cliente: tipo_cliente?.value,
      fecha: moment(fecha, 'YYYY-MM-DD').utc().format(),
      estatus_levantamiento: estatus?.value,
      sucursalId: sucursal?._id,
      observaciones,
      // indicaciones_cliente,
      clienteId: cliente?._id,
      agenteId: agente?._id,
      domicilioId: domicilioId ? domicilioId._id : 1,
      clasificacionesId: clasificacionesId.map((x) => x._id),
    };
    if (validations(body)) {
      setState((prevState) => ({ ...prevState, isLoadingForm: true }));
      const { data } = await postRequest({ url: `levantamientos`, body });
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isLoadingForm: false,
          showModalDownload: true,
          idLevantamiento: data?._id,
        }));
        setTimeout(() => navigate(`/levantamientos/${data?._id}/areas`), 1000);
      } else {
        setState((prevState) => ({ ...prevState, isLoadingForm: false }));
      }
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  // const onClickClasificacion = (c, event) => {
  //   event.preventDefault();
  //   setState((prevState) => ({ ...prevState, activeClasificacion: c }));
  // };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
    if (campo === 'tipoLevantamientoId') {
      actualizarNumeroLevantamiento(value._id);
    }
  };

  const onSelectClasificacion = (value) => {
    setState((prevState) => ({ ...prevState, clasificacionesId: value }));
  };

  const onChangeAgente = (itemSelect) => {
    setState((prevState) => ({
      ...prevState,
      agente: itemSelect,
      maximo_descuento: itemSelect?.maximo_descuento,
    }));
  };

  const onChangeCliente = (itemSelect) => {
    const tipo_cliente = itemSelect ? { value: itemSelect.tipo_cliente, label: itemSelect.tipo_cliente } : null;
    if (itemSelect) {
      const domicilios = esCliente(tipo_cliente) ? itemSelect.listDomicilios : [{ ...itemSelect.domicilio, _id: 1 }];
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      if (itemSelect && itemSelect.clasification) {
        if (itemSelect.clasification.opcion === 'ADVERTENCIA') {
          notification({ title: 'Advertencia', message: 'Este cliente cuenta con saldo vencido o limite de credito.', type: 'warning' });
        } else if (itemSelect.clasification.opcion === 'OCUPA_AUTORIZACION') {
          notification({
            title: 'Advertencia',
            message: 'Este cliente cuenta con saldo vencido o limite de credito y requiere autorizacion para cotizarle.',
            type: 'warning',
          });
        } else if (itemSelect.clasification.opcion === 'SIN_AUTORIZACION') {
          return notification({
            title: 'Advertencia',
            message: 'Este cliente cuenta con saldo vencido o limite de credito asi que no es posible cotizarle.',
            type: 'error',
          });
        }
      }
      setState((prevState) => ({
        ...prevState,
        tipo_cliente,
        cliente: itemSelect,
        domicilioId,
        domicilios,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        cliente: null,
        domicilioId: null,
        domicilios: [],
      }));
    }
  };

  // const handleAgregarArea = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     areas: [
  //       ...prevState.areas,
  //       {
  //         ...InicializarArea(
  //           {
  //             nombre: '',
  //             showZonas: true,
  //             zonas: [
  //               {
  //                 nombre: '',
  //                 subclasificaciones: [],
  //               },
  //             ],
  //           },
  //           prevState.clasificaciones
  //         ),
  //       },
  //     ],
  //   }));
  // };

  // const onChangeArea = (indexI, value) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     areas: [...prevState.areas.map((x, i) => (i === indexI ? { ...value } : x))],
  //   }));
  // };

  // const onClickQuitarArea = (index) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     areas: [...prevState.areas.filter((x, i) => i !== index)],
  //   }));
  // };

  const renderView = () => {
    const {
      isLoading,
      // areas,
      // activeClasificacion,
      tipoLevantamientoId,
      numeroOrden,
      fecha,
      estatus,
      estatus_levantamientos,
      agente,
      sucursal,
      cliente,
      domicilioId,
      domicilios,
      clasificacionesId,
      // indicaciones_cliente,
      observaciones,
    } = state;
    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col xs='12' sm='6'>
                <Row>
                  <Col xs='6'>
                    <DivFormGroup name='tipoLevantamientoId' required title='Tipo de levantamiento'>
                      <SelectTipoLevantamiento value={tipoLevantamientoId} onChange={(value) => onSelect('tipoLevantamientoId', value)} />
                    </DivFormGroup>
                  </Col>
                  <Col xs='6'>
                    <FormInput required title='Núm. Levantamiento' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
                  </Col>
                </Row>
              </Col>
              <Col xs='12' sm='6'>
                <Row>
                  <Col xs='6'>
                    <FormInput
                      title='Fecha'
                      type='date'
                      required
                      name='fecha'
                      onChange={onChange}
                      disabled={!hasPermission('cambiar-fecha')}
                      value={fecha}
                    />
                  </Col>
                  <Col xs='12' sm='6'>
                    <DivFormGroup name='estatus' required title='Estatus'>
                      <Select
                        value={estatus}
                        placeholder='Seleccione'
                        options={estatus_levantamientos}
                        onChange={(value) => onSelect('estatus', value)}
                      />
                    </DivFormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='6'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agente} name='agente' onChange={onChangeAgente} />
                </DivFormGroup>
              </Col>
              <Col xs='12' sm='6'>
                <DivFormGroup name='sucursal' required title='Sucursal'>
                  <SelectSucursal name='sucursal' value={sucursal} onChange={(value) => onSelect('sucursal', value)} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='9'>
                <DivFormGroup name='cliente' required title='Cliente/Prospecto'>
                  <SelectClienteProspecto onChange={onChangeCliente} name='cliente' value={cliente} />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioId' required title='Domicilio'>
                  <SelectDomicilioCliente
                    value={domicilioId}
                    name='domicilioId'
                    onChange={(value) => onSelect('domicilioId', value)}
                    options={domicilios}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioId' required title='Clasificaciones de levantamiento'>
                  <MultiSelectClasificacionLevantamiento value={clasificacionesId} name='clasificacionesId' onChange={onSelectClasificacion} />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <FormInput title='Observaciones' type='textarea' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} variant='success' className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Levantamientos' title='Nuevo levantamiento'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Nuevo.propTypes = {};

export default Nuevo;
