import React, { useState, useRef } from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';

const Import = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [csv, setCsv] = useState(null);
  const [progress, setProgress] = useState(0);
  const frm = useRef(null);

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setCargando(true);
    setProgress(0);
    setCsv(null);
    setIsLoading(true);
    const form = new FormData(frm.current);
    const { csv } = await postUploadRequest({ url: `services/importar-csv`, form });
    setCsv(csv);
    setCargando(false);
    setIsLoading(false);
    setProgress(0);
  };

  return (
    <Module onClickBack={window.history.back} parent='Servicios' title='Importar servicios'>
      <br />
      <form ref={frm} encType='multipart/form-data'>
        <Card>
          <Card.Body>
            <Row>
              <Col xs='12' className='text-center'>
                <div className='btn btn-default btn-file mr-5'>
                  <Icon icon='paperclip' /> Archivo
                  <input type='file' name='csv' id='csv' accept='.xls,.xlsx' onChange={handleReadCSV} />
                </div>
                {isLoading ? <Saving action='Importantando' /> : ''}
              </Col>
              <Col xs='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
            </Row>
          </Card.Body>
        </Card>
      </form>
      <form>{csv !== null ? renderDataCSV() : null}</form>
    </Module>
  );

  function renderDataCSV() {
    // Implement the renderDataCSV function
  }
};

Import.propTypes = {};

export default Import;
