import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, InputGroup, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import CbTableTodosTotales from '@cbcomponents/CbTableTodosTotales';
import Required from '@controls/Required';
import RowDetalle from './components/RowDetalle';
import { validateUpdate } from '@validations/compras';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectMoneda from '@components/forms/SelectMoneda';
import SelectAgente from '@components/forms/SelectAgente';
import SelectProveedor from '@components/forms/SelectProveedor';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';
import SelectEquipoServicio from '@components/forms/SelectEquipoServicio';
import InputCurrency from '@components/forms/InputCurrency';
import Select from '@components/forms/Select';

const New = ({ navigate, notification }) => {
  const [state, setState] = useState({
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fecha_entrega: null,
    monedaId: MONEDA.DOLAR,
    proveedorObj: null,
    detalles: [],
    usoCfdiId: null,
    observaciones: '',
    estatus: 'INICIADA',
    sucursalId: null,
    almacenId: null,
    proveedor: null,
    productoObj: null,
    tipoCambio: 0,
    usa_almacen: false,
    usa_servicios: false,
    usa_gastos: false,
    tipo_partida: 'EQUIPO',
    estatus_compra: [
      { value: 'INICIADA', label: 'INICIADA' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
    ],
  });

  const loadTipoCambio = useCallback(
    async (callback) => {
      const fecha = moment().format('YYYY-MM-DD');
      const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
      if (!data?._id) {
        return notification({
          title: 'Advertencia',
          message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
          type: 'warning',
        });
      }
      const {
        data: { agente },
      } = await getRequest({ url: `compras-catalogos` });
      setState((prevState) => ({
        ...prevState,
        tipoCambio: data.valor,
        agenteObj: { value: agente._id, label: agente.nombre },
        agenteId: agente._id,
        agenteName: agente.nombre,
        isLoadingForm: false,
      }));
      callback();
    },
    [notification]
  );

  useEffect(() => {
    loadTipoCambio(() => {});
  }, [loadTipoCambio]);

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Error',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const onChangeProducto = (itemSelect) => {
    const { tipo_partida, detalles } = state;
    if (itemSelect) {
      const producto = itemSelect.row;
      let precio = producto.last_cost || 0;

      let measure = null,
        measureId = null,
        measures = [];
      if (tipo_partida === 'EQUIPO') {
        measure =
          producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure ? producto.equipmentsMeasureId.measure : producto.equipmentsMeasureId;
        measureId =
          producto.equipmentsMeasureId && producto.equipmentsMeasureId._id ? producto.equipmentsMeasureId._id : producto.equipmentsMeasureId;
      } else {
        if (producto.listCost.length > 0) {
          measures = state.measures.filter((x) => {
            const existe = producto.listCost.map((x) => x.measureId).includes(x.value);
            return existe;
          });
        } else {
          measures = state.measures.filter((x) => x.tipo === 'SERVICIO');
        }
      }
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        producto: '',
        productoObj: '',
        detalles: [
          ...detalles,
          {
            ...producto,
            relacionadoId: producto._id,
            descripcion: producto.name.toUpperCase(),
            tipo_partida: tipo_partida,
            noIdentificador: producto.code,
            moneda_original: producto.moneda,
            precio_original: producto.last_cost || 0,
            measure,
            measures,
            measureId,
            precio,
            cantidad: 1,
            descuento: 0,
            impuesto: producto.iva || 16,
            importe: precio,
          },
        ],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        productoObj: null,
        producto: null,
      }));
    }
  };

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'principal' || name === 'caducidad') {
      setState((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
  };

  const handleRegisterCompra = async (event) => {
    event.preventDefault();
    const {
      tipoCompraId,
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      almacenId,
      monedaId,
      tipoCambio,
      total,
      subtotal,
      descuento,
      impuestos,
      proveedor,
      usoCfdiId,
      observaciones,
      detalles,
      agenteId,
    } = state;

    const data = {
      tipoCompraId: tipoCompraId,
      numero_compra: numeroOrden,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      estatus_compra: estatus,
      sucursalId,
      almacenId,
      agenteId: agenteId ? agenteId : '',
      monedaId,
      tipoCambio: cleanNumber(tipoCambio),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD').utc(),
      total: total || 0,
      subtotal: subtotal || 0,
      descuento: descuento || 0,
      iva: impuestos || 0,
      proveedorId: proveedor ? proveedor._id : '',
      usoCfdiId,
      observaciones,
      detalles: detalles.map((detalle) => {
        return {
          ...detalle,
          porcentaje_iva: detalle.impuesto,
          porcentaje_descuento: detalle.descuento,
          iva: detalle.impuesto_total,
          descuento: detalle.descuento_total,
        };
      }),
    };
    if (validations(data)) {
      await postRequest({ url: `compras`, body: data });
      setTimeout(() => navigate('/compras'), 1000);
    }
  };

  const onChangeDetalle = (name, index, event) => {
    if (['cantidad', 'descuento', 'impuesto', 'precio'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: [
          ...prevState.detalles.map((x, i) => {
            if (i === index) x[name] = cleanNumber(event.target.value);
            return { ...x };
          }),
        ],
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...prevState.detalles.map((x, i) => {
          if (i === index) {
            x[name] = event.target.value;
          }
          return { ...x };
        }),
      ],
    }));
  };

  const renderDomicilio = (proveedor) => {
    if (!proveedor) return '';
    const { street, numberExt, numberInt, colony, city, postalCode, state } = proveedor.address;
    return `${street} #${numberExt}-${numberInt},${postalCode} ${colony} ${city}, ${state}`;
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const renderView = () => {
    const {
      tipoCompraId,
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      monedaId,
      isLoading,
      proveedor,
      productoObj,
      tipoCambio,
      usoCfdiId,
      detalles,
      observaciones,
      estatus_compra,
      agenteObj,
    } = state;
    return (
      <form onSubmit={handleRegisterCompra}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='3 col-xs-6'>
                <DivFormGroup name='tipoCompraId' required title='Tipo compra'>
                  <SelectTipoCompra name='tipoCompraId' value={tipoCompraId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='2 col-xs-6'>
                <FormInput title='Num. de orden' placeholder='Num. de orden' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
              </Col>
              <Col sm='2 col-xs-6'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </Col>
              <Col sm='2 col-xs-6'>
                <FormInput title='Fecha de entrega' type='date' name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </Col>
              <Col sm='3 col-xs-6'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} options={estatus_compra} onChange={onSelect.bind(this, 'estatus')} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='3 col-xs-6'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal name='sucursalId' value={sucursalId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='2 col-xs-6'>
                <DivFormGroup name='monedaId' required title='Moneda'>
                  <SelectMoneda name='monedaId' value={monedaId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='2 col-xs-6'>
                <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                  <InputCurrency name='tipoCambio' value={tipoCambio} onChange={onChange} placeholder='Tipo de cambio' />
                </DivFormGroup>
              </Col>
              <Col sm='5'>
                <DivFormGroup name='agenteId' required title='Agente'>
                  <SelectAgente name='agenteId' value={agenteObj} onChange={onSelect} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='9'>
                <DivFormGroup name='proveedorName' required title='Proveedor'>
                  <SelectProveedor name='proveedorId' value={proveedor} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput title='RFC' disabled name='proveedorRfc' onChange={onChange} value={proveedor ? proveedor.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='9'>
                <FormInput title='Domicilio' disabled name='proveedorDomicilio' onChange={onChange} value={renderDomicilio(proveedor)} />
              </Col>
              <Col sm='3'>
                <DivFormGroup name='usoCfdi' required title='Uso de CFDI'>
                  <SelectUsoCFDI name='usoCfdiId' value={usoCfdiId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={{ overflow: 'scroll' }}>
          <Card.Body>
            <Row>
              <Col sm='10'>
                <DivFormGroup>
                  <InputGroup>
                    <SelectEquipoServicio name='producto' value={productoObj} onChange={onChangeProducto} />
                  </InputGroup>
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
          <CbTableResponsive>
            <thead>
              <tr>
                <th width='10%'>#</th>
                <th>
                  Descripción <Required />
                </th>
                <th>
                  Unidad <Required />
                </th>
                <th>
                  Cantidad <Required />
                </th>
                <th>
                  Precio <Required />
                </th>
                <th>% Descuento</th>
                <th>% IVA</th>
                <th>Importe</th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <CbTableBody colSpan={9}>
              {state.detalles.map((detalle, i) => (
                <RowDetalle key={i} detalle={detalle} i={i} onChangeDetalle={onChangeDetalle} onClickRemoveDetalle={onClickRemoveDetalle} />
              ))}
              <CbTableTodosTotales colSpan={9} detalles={detalles} />
            </CbTableBody>
          </CbTableResponsive>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegisterCompra} variant='success' className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </form>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de compras' title='Nueva orden de compra'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

New.propTypes = {
  navigate: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
};

export default New;
