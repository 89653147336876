import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import TableSearchSeguimiento from './components/TableSearchSeguimiento';
import FormEditarSegimiento from './components/FormEditarSeguimiento';
import RowDetalles from './components/RowDetalles';
import { useStoreNotification } from '@stores/catalogs.store';

const EditarSeguimiento = ({ loadData, seguimientoId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    loading: false,
    activePage: 1,
    clientes: [],
    search: '',
    count: 0,
    pagination: 20,
    showModal: false,
    maximo_descuento: 0,
    isLoading: false,
    resultados: [],
    motivos: [],
    agentes: [],
    agentes_asignados: [],
    fecha: moment().format('YYYY-MM-DD HH:mm'),
    motivo: null,
    resultadoVisita: null,
    observaciones: '',
    fechaProximaVisita: null,
    fechaFinalizacion: null,
    horaProximaVisita: null,
    tieneProximaVisita: false,
    motivoProximaVisita: null,
    agenteProximavisitaId: null,
    agenteProximavisitaObj: null,
    agenteProximavisitaName: '',
    agenteId: null,
    agente: null,
    agenteName: '',
    customerId: null,
    contactoId: null,
    contacto: '',
    cliente: null,
    telefonos: [],
    correos: [],
    detalles: [],
    estatus: 'INICIADO',
    opciones_estatus_seguimientos: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'PENDIENTE', label: 'PENDIENTE' },
      { value: 'FINALIZADO', label: 'FINALIZADO' },
    ],
  });

  const open = useCallback(async () => {
    const {
      data: { agentes, motivos, resultados_visitas },
    } = await getRequest({ url: `seguimiento-catalogos` });
    const { data } = await getRequest({ url: `seguimientos/${seguimientoId}` });
    let telefonos = [];
    let correos = [];
    if (data.contactoId.phone) telefonos = [...telefonos, ...data.contactoId.phone.split(',')];
    if (data.contactoId.email) correos = [...correos, ...data.contactoId.email.split(',')];
    setState((prevState) => ({
      ...prevState,
      agentes,
      agenteId: data.agenteId._id,
      tipo: data.tipo,
      agente: data.agenteId,
      agenteName: data.agenteId.nombre,
      agenteProximavisitaId: null,
      agenteProximavisitaObj: null,
      agenteProximavisitaName: '',
      agentes_asignados: data.agentes_asignados,
      motivos,
      estatus: data.estatus,
      resultados: resultados_visitas,
      showModal: true,
      isLoading: false,
      fecha: moment(data.fecha).local().format('YYYY-MM-DD HH:mm'),
      hora: data.hora,
      motivo: data.motivoId ? data.motivoId : null,
      resultadoVisita: data.resultadoVisitaId ? data.resultadoVisitaId : null,
      observaciones: data.observaciones,
      titulo: data.titulo,
      fechaProximaVisita: moment(data.fechaProximaVisita).isValid() ? moment(data.fechaProximaVisita).local().format('YYYY-MM-DD HH:mm') : null,
      horaProximaVisita: data.horaProximaVisita,
      fechaFinalizacion: moment(data.fechaFinalizacion).isValid() ? moment(data.fechaFinalizacion).local().format('YYYY-MM-DD HH:mm') : null,
      tieneProximaVisita: data.tieneProximaVisita,
      horaFinalizacion: data.horaFinalizacion,
      motivoProximaVisita: data.motivoProximaVisitaId ? data.motivoProximaVisitaId : null,
      customerId: data.customerId ? data.customerId._id : null,
      prospectoId: data.prospectoId ? data.prospectoId._id : null,
      contactoId: data.contactoId._id,
      nombre: data.contactoId.name,
      paterno: data.contactoId.paterno,
      materno: data.contactoId.materno,
      telefonos: telefonos.map((x) => ({ value: x, label: x })),
      correos: correos.map((x) => ({ value: x, label: x })),
      activePage: 1,
      clientes: [],
      search: '',
      count: 0,
      nuevoContacto: false,
      cliente: data.customerId,
      detalles: data.detalles.map((x) => ({
        ...x,
        resultadoVisita: x.resultadoVisitaId ? x.resultadoVisitaId : x.resultadoVisita,
      })),
    }));
  }, [seguimientoId]);

  useEffect(() => {
    open();
  }, [open]);

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      customerId,
      prospectoId,
      tipo,
      contactoId,
      nombre,
      paterno,
      materno,
      telefonos,
      correos,
      agenteId,
      resultadoVisita,
      motivo,
      observaciones,
      estatus,
      detalles,
      titulo,
    } = state;

    const data = {
      customerId,
      prospectoId,
      tipo,
      contactoId,
      nombre,
      paterno,
      materno,
      telefonos: telefonos.map((x) => x.value).join(','),
      correos: correos.map((x) => x.value).join(','),
      agenteId,
      motivoId: motivo ? motivo._id : null,
      resultadoVisitaId: resultadoVisita ? resultadoVisita._id : null,
      observaciones,
      titulo,
      estatus,
      detalles: detalles.map((x) => ({
        ...x,
        resultadoVisitaId: x.resultadoVisita ? x.resultadoVisita._id : null,
      })),
    };

    if (validations(data)) {
      await putRequest({ url: `seguimientos/cliente/editar/${seguimientoId}`, body: data });
      loadData();
      setState((prevState) => ({ ...prevState, showModal: false }));
    }
  };

  const onChangeSeguimiento = (index, e) => {
    const { value, name, type, checked } = e.target;
    let { detalles } = state;
    detalles = detalles.map((x, i) => {
      if (index === i) {
        x = {
          ...x,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
      return x;
    });
    setState((prevState) => ({ ...prevState, detalles }));
  };

  const onSelectContacto = (r, c, telefonos = [], correos = []) => {
    const contacto = c ? `${c.name || ''} ${c.paterno || ''} ${c.materno || ''}` : '';
    setState((prevState) => ({
      ...prevState,
      customerId: r.customerId._id,
      cliente: r.customerId,
      contacto,
      nombre: c && c.name,
      paterno: c && c.paterno,
      materno: c && c.materno,
      contactoId: c && c._id,
      telefonos: telefonos.map((x) => ({ value: x, label: x })),
      correos: correos.map((x) => ({ value: x, label: x })),
    }));
  };

  const onClickAgregar = () => {
    let telefonos = [];
    if (state.search) telefonos.push({ value: state.search, label: state.search });
    setState((prevState) => ({ ...prevState, nuevoContacto: true, telefonos }));
  };

  const handleOnClickAgregarSeguimiento = () => {
    let { detalles } = state;
    detalles = [
      ...detalles,
      {
        agentesAsignadosName: '',
        agentes_asignados_id: null,
        agentes_asignados: [],
        estatus: 'INICIADO',
      },
    ];
    setState((prevState) => ({ ...prevState, detalles }));
  };

  const onClickElimiarSeguimiento = (index, e) => {
    e.preventDefault();
    let { detalles } = state;
    detalles = detalles.filter((e, i) => i !== index);
    setState((prevState) => ({ ...prevState, detalles }));
  };

  const renderRowDetalles = () => {
    const { resultados, opciones_estatus_seguimientos, agentes, detalles } = state;
    return detalles.map((x, i) => (
      <RowDetalles
        key={i}
        x={x}
        i={i}
        resultados={resultados}
        opciones_estatus_seguimientos={opciones_estatus_seguimientos}
        agentes={agentes}
        onClickElimiarSeguimiento={(e) => onClickElimiarSeguimiento(i, e)}
        onChangeSeguimiento={(e) => onChangeSeguimiento(i, e)}
      />
    ));
  };

  const onChangeSeguimientoPrincipal = (e) => {
    const { value, name, type, checked } = e.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (isEmpty(params.titulo + '')) {
      validate.success = false;
      validate.message = 'Titulo es requerido.';
    } else if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const {
    showModal,
    isLoading,
    motivos,
    resultados,
    motivo,
    resultadoVisita,
    agente,
    customerId,
    prospectoId,
    nombre,
    paterno,
    materno,
    telefonos,
    correos,
    nuevoContacto,
    cliente,
    estatus,
    opciones_estatus_seguimientos,
    agentes_asignados,
    agentes,
    titulo,
    tipo,
  } = state;

  return (
    <Fragment>
      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Editar seguimiento</Modal.Title>
        </Modal.Header>
        <If condition={!customerId && !prospectoId && !nuevoContacto}>
          <If.Then>
            <Modal.Body>
              <TableSearchSeguimiento onAgregar={onClickAgregar} onSelect={onSelectContacto} />
            </Modal.Body>
          </If.Then>
          <If.Else>
            <Fragment>
              <Modal.Body>
                <FormEditarSegimiento
                  tipo={tipo}
                  cliente={cliente}
                  nombre={nombre}
                  paterno={paterno}
                  materno={materno}
                  telefonos={telefonos}
                  correos={correos}
                  agente={agente}
                  agentes_asignados={agentes_asignados}
                  agentes={agentes}
                  motivo={motivo}
                  motivos={motivos}
                  estatus={estatus}
                  opciones_estatus_seguimientos={opciones_estatus_seguimientos}
                  titulo={titulo}
                  onChange={onChangeSeguimientoPrincipal}
                  resultadoVisita={resultadoVisita}
                  resultados={resultados}
                />
                <Row>
                  <Col sm='12'>
                    <h3>Seguimientos</h3>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <div className='list-group'>
                      {renderRowDetalles()}
                      <Button
                        className='list-group-item'
                        onClick={handleOnClickAgregarSeguimiento}
                        style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
                        type='button'
                      >
                        <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar seguimiento
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
            </Fragment>
          </If.Else>
        </If>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleRegister} variant='primary' className='pull-right mr-5' disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className='pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

EditarSeguimiento.propTypes = {
  loadData: PropTypes.func.isRequired,
  seguimientoId: PropTypes.string.isRequired,
};

export default EditarSeguimiento;
