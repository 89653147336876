import { DivFormGroup, Input, TextArea } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isDecimalValid } from '@utils/validations';
import { isDecimal, isEmpty, isMongoId } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectUnidadMedida from '@components/forms/SelectUnidadMedida';

const NewProduct = () => {
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    quantity: '',
    make: '',
    model: '',
    measureId: '',
    description: '',
    note: '',
    unit_price: '',
    amount: '',
    measures: [],
  });

  const frmNewProduct = useRef(null);

  useEffect(() => {
    const fetchMeasures = async () => {
      const { data } = await getRequest({ url: `measures` });
      const measures = data.map(({ _id, measure }) => ({ value: _id, label: measure, disabled: false }));
      setState((prevState) => ({ ...prevState, measures }));
    };

    fetchMeasures();
  }, []);

  const resetForm = () => {
    const measures = state.measures.map((catalog) => ({ ...catalog, disabled: false }));

    setState({
      ...state,
      measures,
      reset: true,
      quantity: '',
      make: '',
      model: '',
      measureId: '',
      description: '',
      note: '',
      unit_price: '',
      amount: '',
    });

    setTimeout(() => setState((prevState) => ({ ...prevState, reset: false })), 100);

    frmNewProduct.current.quantity.focus();
  };

  const onSelectMeasure = (measureId) => {
    const measures = state.measures.map((catalog) => ({
      ...catalog,
      disabled: measureId !== null && catalog.value.toString() === measureId.toString(),
    }));

    setState((prevState) => ({ ...prevState, measureId, measures }));
  };

  const handleRegisterProduct = async (event) => {
    event.preventDefault();

    const { measureId } = state;
    const { quantity, make, model, description, unit_price, amount, note } = frmNewProduct.current;

    const data = {
      quantity: quantity.value.trim(),
      make: make.value.trim(),
      model: model.value.trim(),
      description: description.value.trim(),
      measureId,
      unit_price: unit_price.value.trim(),
      amount: amount.value.trim(),
      note: note.value.trim(),
    };

    if (validations(data)) {
      await postRequest({ url: `products`, body: data });
      resetForm();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    // Validacion Modelo
    if (isEmpty(params.model + '')) {
      validate.success = false;
      validate.message = 'Modelo es requerido.';
    }

    // Validacion Unidad de Medida
    if (validate.success && (isEmpty(params.measureId + '') || !isMongoId(params.measureId + ''))) {
      validate.success = false;
      validate.message = 'Unidad de Medida es requerido.';
    }

    // Validacion Descripcion
    if (validate.success && isEmpty(params.description + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerido.';
    } else if (validate.success && params.description.length > 300) {
      validate.success = false;
      validate.message = 'Descripción, no debe de contener más de 300 caracteres.';
    }

    // Validacion Precio Unitario
    if (validate.success && !isEmpty(params.unit_price + '')) {
      if (!isDecimal(params.unit_price + '') || !isDecimalValid(params.unit_price, 2) || !(parseFloat(params.unit_price) > 0)) {
        validate.success = false;
        validate.message = 'Precio Unitario, es incorrecto, favor de verificarlo.';
      }
    }

    // Validacion Importe
    if (validate.success && !isEmpty(params.amount + '')) {
      if (!isDecimal(params.amount + '') || !isDecimalValid(params.amount, 2) || !(parseFloat(params.amount) > 0)) {
        validate.success = false;
        validate.message = 'Importe, solo se permiten cantidades.';
      }
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const renderForm = () => {
    const { quantity, make, model, description, note, unit_price, amount, isLoading } = state;

    return (
      <Module title='Agregar Producto'>
        <form ref={frmNewProduct} onSubmit={handleRegisterProduct}>
          <Row>
            <Col sm='6'>
              <DivFormGroup name='quantity' title='Cantidad'>
                <Input name='quantity' value={quantity} />
              </DivFormGroup>

              <DivFormGroup name='make' title='Marca'>
                <Input name='make' value={make} />
              </DivFormGroup>

              <DivFormGroup name='model' title='Modelo'>
                <Input name='model' value={model} />
              </DivFormGroup>

              <DivFormGroup name='measureId' title='Unidad de Medida'>
                <SelectUnidadMedida name='measureId' value={state.measureId} onChange={onSelectMeasure} />
              </DivFormGroup>
            </Col>

            <Col sm='6'>
              <DivFormGroup name='description' title='Descripción'>
                <TextArea name='description' rows={3} value={description} />
              </DivFormGroup>

              <DivFormGroup name='unit_price' title='Precio Unitario'>
                <Input name='unit_price' value={unit_price} />
              </DivFormGroup>

              <DivFormGroup name='amount' title='Importe'>
                <Input name='amount' value={amount} />
              </DivFormGroup>
            </Col>

            <Col sm='12'>
              <DivFormGroup name='note' title='Nota'>
                <TextArea name='note' rows={3} value={note} />
              </DivFormGroup>
            </Col>
          </Row>

          <div>
            <Saving saving={isLoading} />
            <Button type='submit' variant='primary' className='btn-lg pull-right' disabled={isLoading}>
              <Icon icon='floppy-disk' /> Guardar
            </Button>
          </div>
        </form>
      </Module>
    );
  };

  const { measures } = state;

  return measures.length > 0 ? renderForm() : <Loading />;
};

NewProduct.propTypes = {};

export default NewProduct;
