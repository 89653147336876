import * as SECRET from '@config/apiKey';
import jwt from 'jwt-simple';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { BASE_URL, getRequest, URL_FILES } from '@utils/api';
import { useStoreNotification } from '@stores/catalogs.store';

const ImprimirLevantamiento = ({ id, levantamientoId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    nombre: '',
    isLoading: false,
    detalles_equipos: [],
    detalles_servicios: [],
    sucursales: [],
    almacenes: [],
    almacenId: null,
    sucursalId: null,
    codigos: false,
    desgloseKits: false,
    horasServicio: false,
    partidasConcentradas: false,
  });

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      condicion: '',
      nombre: '',
    }));
  };

  const onClickDownloadPdf = () => {
    setState((prevState) => ({ ...prevState, showModal: true }));
  };

  const onChange = (e) => {
    const { value, name, checked, type } = e.target;
    if (type === 'checkbox') {
      setState((prevState) => ({ ...prevState, [name]: checked }));
    } else if (type === 'radio') {
      setState((prevState) => ({ ...prevState, [name]: value === 'true' }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleAceptar = async (event) => {
    event.preventDefault();
    const { codigos, desgloseKits, horasServicio, partidasConcentradas } = state;
    const { data } = await getRequest({
      url: `levantamientos/pdf/${levantamientoId}`,
      params: { codigos, desgloseKits, horasServicio, partidasConcentradas },
    });
    if (data) {
      setState(
        (prevState) => ({ ...prevState, showModalDownload: false }),
        () => {
          window.open(`${URL_FILES}/${data.url}`, '_blank');
        }
      );
    }
  };

  const handleCopiar = (e) => {
    e.preventDefault();
    const cotizacionId = jwt.encode(levantamientoId, SECRET.API_KEY);
    copyToClipboard(BASE_URL + `firmarCotizacion/${cotizacionId}`);
    notification({
      message: '¡Url copiada con éxito!',
      type: 'success',
    });
  };

  const handleExcel = async (e) => {
    e.preventDefault();
    const { file } = await getRequest({ url: `levantamientos/exportar/${levantamientoId}` });
    if (file) {
      setState(
        (prevState) => ({ ...prevState, showModalDownload: false }),
        () => {
          window.open(`${URL_FILES}/${file}`, '_blank');
        }
      );
    }
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  const { showModal, isLoading, codigos, desgloseKits, horasServicio, partidasConcentradas } = state;

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={`${id}-download`}>Descargar PDF</Tooltip>}>
        <Button onClick={onClickDownloadPdf} className='btn-outline-primary'>
          <Icon icon='download-alt' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ textAlign: 'center' }}>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Con códigos</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='codigos' onChange={onChange} value={false} checked={!codigos} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='codigos' onChange={onChange} value={true} checked={codigos} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Desglose de kits</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='desgloseKits' onChange={onChange} value={false} checked={!desgloseKits} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='desgloseKits' onChange={onChange} value={true} checked={desgloseKits} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Con horas de servicio</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='horasServicio' onChange={onChange} value={false} checked={!horasServicio} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='horasServicio' onChange={onChange} value={true} checked={horasServicio} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm='6'>
              <FormGroup className='inline'>
                <label>Partidas concentradas</label>
                <div>
                  <label className='radio-inline'>
                    <input type='radio' name='partidasConcentradas' onChange={onChange} value={false} checked={!partidasConcentradas} /> No
                  </label>
                  <label className='radio-inline'>
                    <input type='radio' name='partidasConcentradas' onChange={onChange} value={true} checked={partidasConcentradas} /> Si
                  </label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={handleAceptar} variant='primary' className='pull-right mr-5' type='button'>
            Imprimir
          </Button>
          <Button variant='warning' className='pull-right mr-5' onClick={handleCopiar} disabled={isLoading}>
            Url para firma
          </Button>
          <Button variant='success' className='pull-right mr-5' onClick={handleExcel} disabled={isLoading}>
            Excel
          </Button>
          <Button onClick={close} disabled={isLoading} variant='light' className='pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ImprimirLevantamiento.propTypes = {
  id: PropTypes.string.isRequired,

  levantamientoId: PropTypes.string.isRequired,
  numero_levantamiento: PropTypes.string.isRequired,
};

export default ImprimirLevantamiento;
