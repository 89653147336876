import { postRequest } from '@utils/api';

export const fetchAll = async ({ pagina, limite, ...params }, signal) => {
  const respuesta = await postRequest({
    url: 'sat-solicitudes/search',
    signal,
    params: {
      page: pagina,
      limit: limite,
      skip: params.skip,
      estado: params.estado,
    },
  });
  return {
    rows: respuesta.data,
    count: respuesta.count,
  };
};
