import React, { useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Icon } from '@stateless';
import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import { postUploadRequest } from '@utils/api';
import { castEquipo } from '@utils/cotizaciones';
import { useStoreNotification } from '@stores/catalogs.store';

const ModalImportarExcel = ({ show, onClose, onSave, monedaId, tipoCambio }) => {
  const frm = useRef(null);
  const archivoExcel = useRef(null);
  const getStoreNotification = useStoreNotification();

  const onSubirExcel = async (event) => {
    event.preventDefault();
    const form = new FormData(this.frm);
    const { data } = await postUploadRequest({ url: `cotizaciones/importar`, form });
    const detallesNuevos = [];
    for (let i = 0; i < data.length; i++) {
      const { equipo, cantidad } = data[i];
      let detalle = {};
      detalle = castEquipo(equipo, monedaId, tipoCambio);
      detallesNuevos.push({ ...detalle, cantidad });
    }
    onSave(detallesNuevos);
    getStoreNotification.addNotification({ title: 'Atención', message: 'Equipos importados correctamente', type: 'success' });
  };

  const handleImageChange = () => {};

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Importar partidas</Modal.Title>
      </Modal.Header>
      <form ref={frm} onSubmit={onSubirExcel} encType='multipart/form-data'>
        <Modal.Body style={{ textAlign: 'center' }}>
          <DivFormGroup>
            <input type='file' name='excel' style={{ display: 'none' }} ref={archivoExcel} onChange={handleImageChange} />
            <Button type='button' className='btn btn-primary' onClick={() => archivoExcel.current.click()}>
              <Icon icon='picture' /> Selecciona un archivo excel
            </Button>
          </DivFormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubirExcel} variant='primary' className='mr-5' type='button'>
            Importar
          </Button>
          <Button onClick={onClose} variant='light' className='mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

ModalImportarExcel.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  monedaId: PropTypes.string,
  tipoCambio: PropTypes.number,
};

export default ModalImportarExcel;
