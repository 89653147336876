import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import { Col, InputGroup, Row } from 'react-bootstrap';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import Select from '@components/forms/Select';

const Levantamientos = (props) => {
  const [state, setState] = useState({
    quotations: [],
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    storehouses: [],
    sucursales: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    descuentos: [],
    tipos_pedidos: [],
    orden: 'numero_levantamiento',
    sucursalId: '0',
    agenteId: '0',
    agentes: [],
    loading: true,
    tipo: -1,
    estatus_levantamientos: {
      PENDIENTE: 'PENDIENTE',
      INICIADA: 'INICIADA',
      ENVIADA: 'ENVIADA',
      ACEPTADA: 'ACEPTADA',
      DECLINADA: 'DECLINADA',
      AUTORIZADA: 'AUTORIZADA',
      FINALIZADA: 'FINALIZADA',
      CANCELADA: 'CANCELADA',
      PAUSADA: 'PAUSADA',
      REMISIONADA: 'REMISIONADA',
    },
    estatus: [],
    estatus_levantamiento: '0',
  });

  const loadCatalogos = useCallback(async (callback) => {
    const {
      data: { sucursales, agente, agentes, almacenes, tipos_pedidos },
    } = await getRequest({ url: `levantamientos-catalogos` });
    setState((prevState) => ({
      ...prevState,
      tipos_pedidos,
      listSucursales: sucursales,
      listAlmacenes: almacenes,
      sucursales: [
        {
          value: '0',
          label: 'Todas las sucursales',
        },
        ...sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
      ],
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: `${agente.nombre}`,
        })),
      ],
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_levantamientos).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : '0',
      sucursalId: agente && agente.sucursalId ? agente.sucursalId._id : '0',
      almacenId: agente && agente.almacenId ? agente.almacenId._id : '0',
    }));
    if (callback) callback();
  }, []);

  const handleSearch = useCallback(() => {
    const { pagination, activePage, search, fechaFin, fechaInicio, tipo, sucursalId, agenteId, orden, estatus_levantamiento } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const fetchData = async () => {
      const body = {
        search,
        fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
        fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
        orden,
        sucursalId,
        agenteId,
        tipo,
        estatus: estatus_levantamiento,
      };
      const { data, count } = await postRequest({ url: `levantamientos/search`, params: { page, limit: pagination }, body });
      setState((prevState) => ({
        ...prevState,
        quotations: data,
        count: count,
        loading: false,
      }));
    };
    fetchData();
  }, [state]);

  const onSearch = useCallback(
    (params = { search: '' }) => {
      setState((prevState) => ({
        ...prevState,
        search: params.search,
        activePage: 1,
        fechaInicio: params.fechaInicio,
        fechaFin: params.fechaFin,
        tipo: params.tipo,
        orden: params.orden,
      }));
      handleSearch();
    },
    [handleSearch]
  );

  const handlePagination = useCallback(
    (eventKey) => {
      setState((prevState) => ({ ...prevState, activePage: eventKey }));
      handleSearch();
    },
    [handleSearch]
  );

  const onSelect = useCallback(
    (name, value) => {
      setState((prevState) => ({ ...prevState, [name]: value }));
      handlePagination(1);
    },
    [handlePagination]
  );

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, []);

  const { loading, pagination, count, activePage, sucursalId, agenteId, estatus, estatus_levantamiento, quotations } = state;

  return (
    <Module title='Levantamientos'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='3'>
            <IfPermission action='TodasSucursales'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='home' title='sucursales' />
                  </InputGroup.Text>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <IfPermission action='TodosAgentes'>
              <DivFormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <Icon icon='user' title='Agentes' />
                  </InputGroup.Text>
                  <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
                </InputGroup>
              </DivFormGroup>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Estatus' />
                </InputGroup.Text>
                <Select value={estatus_levantamiento} options={estatus} onChange={(value) => onSelect('estatus_levantamiento', value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <Link to='/levantamientos/nuevo' className='btn btn-success pull-right'>
              <Icon icon='plus' /> Nuevo
            </Link>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <List {...state} {...props} list={quotations} loadData={onSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

export default Levantamientos;
