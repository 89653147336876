import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { Button } from 'react-bootstrap';

const CbTableFooterBotton = ({ onClick, title, colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <Button onClick={onClick} className='list-group-item' style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }} type='button'>
          <Icon icon='plus' style={{ fontSize: '0.8em' }} /> {title}
        </Button>
      </td>
    </tr>
  );
};

CbTableFooterBotton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  colSpan: PropTypes.number.isRequired,
};

export default CbTableFooterBotton;
