import { DivFormGroup, FormInput, Input, Label, TextArea } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Observaciones = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    isLoadingForm: true,
    fecha_hora_inicio: moment().format('YYYY-MM-DD HH:mm'),
    fecha_hora_fin: moment().format('YYYY-MM-DD HH:mm'),
    observaciones: '',
    no_concluyo_trabajo: '',
    comentarios_adicionales_cliente: '',
    ordenServicioId: null,
    observacionId: null,
    numero_orden_servicio: '',
    orden_servicio: null,
    actualizar: false,
    evidencias: [],
    se_concluyo: true,
  });

  useEffect(() => {
    const { visitaId } = params;
    loadData(visitaId);
  }, [params]);

  const loadData = async (visitaId) => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    setState((prevState) => ({
      ...prevState,
      isLoadingForm: false,
      actualizar: data.observacion ? true : false,
      observacionId: data.observacion ? data.observacion._id : '',
      ordenServicioId: data.ordenServicioId._id,
      fecha_hora_inicio: data.observacion
        ? moment(data.observacion.fecha_hora_inicio).format('YYYY-MM-DD HH:mm')
        : moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: data.observacion ? moment(data.observacion.fecha_hora_fin).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      observaciones: data.observacion ? data.observacion.observaciones : '',
      no_concluyo_trabajo: data.observacion ? data.observacion.no_concluyo_trabajo : '',
      comentarios_adicionales_cliente: data.observacion ? data.observacion.comentarios_adicionales_cliente : '',
      orden_servicio: data.ordenServicioId,
      numero_orden_servicio: data.ordenServicioId.numero_orden_servicio,
      estatus: data.estatus,
    }));
  };

  const validations = () => {
    let validate = {
      success: true,
      message: '',
    };

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const { visitaId } = params;
    const { comentarios_adicionales_cliente } = state;
    const data = {
      comentarios_adicionales_cliente,
    };
    if (validations(data)) {
      await postRequest({ url: `ordenes-servicios-visitas/comentarios/${visitaId}`, body: data });
      setTimeout(() => {
        navigate(`/ordenes-servicios-visitas/calificaciones/${visitaId}`);
      }, 1000);
    }
  };

  const handleSiguiente = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    navigate(`/ordenes-servicios-visitas/calificaciones/${visitaId}`);
  };

  const handleAtras = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    navigate(`/ordenes-servicios-visitas/observaciones/${visitaId}`);
  };

  const renderView = () => {
    const { isLoading, fecha_hora_inicio, comentarios_adicionales_cliente, numero_orden_servicio, estatus } = state;

    return (
      <form onSubmit={handleAdd}>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='wrench' /> Comentarios del cliente
            </div>
          }
        >
          <Row>
            <Col sm='6'>
              <FormInput
                title='Numero de orden de servicio'
                placeholder='Numero de orden de servicio'
                disabled
                name='numero_orden_servicio'
                value={numero_orden_servicio}
              />
            </Col>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='fecha_hora_inicio' title='Fecha y hora de inicio' required />
                <Input type='datetime-local' name='fecha_hora_inicio' onChange={onChange} disabled value={fecha_hora_inicio} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <DivFormGroup>
                <Label name='comentarios_adicionales_cliente' title='Comentarios adicionales por parte del cliente' required />
                <TextArea
                  id='comentarios_adicionales_cliente'
                  name='comentarios_adicionales_cliente'
                  rows={5}
                  value={comentarios_adicionales_cliente}
                  onChange={onChange}
                />
              </DivFormGroup>
            </Col>
          </Row>
        </Panel>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleAdd} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                {estatus === 'FINALIZADO' && (
                  <>
                    <Button onClick={handleSiguiente} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-right' /> Siguente (sin guardar)
                    </Button>
                    <Button onClick={handleAtras} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-left' /> Atrás (sin guardar)
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </form>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Detalles del trabajo'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Observaciones.propTypes = {};

export default Observaciones;
