import If from '@controls/If';
import Search from '@controls/Search';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Agregar from './Agregar';
import Table from './Table';
import SelectUsuario from '@components/forms/SelectUsuario';
import { Button, Col, Row } from 'react-bootstrap';
import useAuth from '@hooks/useAuth';

const List = () => {
  const { user } = useAuth();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [userId, setUserId] = useState(null);
  const [checkedAll] = useState(false);
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    const fetchUsuarios = async () => {
      const { data: usuarios } = await getRequest({ url: `users-all` });
      const usuario = usuarios.find((x) => x.email === user.email);
      setUsuarios([
        ...usuarios.map((usuario) => ({
          value: usuario._id,
          label: `${usuario.name} ${usuario.active ? '(Activo)' : ''}`,
        })),
        {
          value: '0',
          label: 'Todas las usuarios',
        },
      ]);
      setUserId(usuario ? usuario._id : null);
    };

    fetchUsuarios();
  }, [user.email]);

  useEffect(() => {
    loadData();
  }, [userId, activePage, search]);

  const loadData = async () => {
    if (!userId) return;
    const page = activePage * pagination - pagination;
    setLoading(true);
    const { data, count } = await getRequest({ url: `user/notificaciones/${userId}/search`, params: { page, limit: pagination, search } });
    setList(data);
    setCount(count);
    setLoading(false);
  };

  const handleSearch = ({ search }) => {
    setSearch(search);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const onSelect = (name, value) => {
    setUserId(value);
    setActivePage(1);
  };

  const onChangeChecked = (checked, index) => {
    setList(list.map((x, i) => ({ ...x, checked: index === i ? checked : x.checked })));
  };

  const onChangeCheckedAll = (checked) => {
    setList(list.map((x) => ({ ...x, checked })));
  };

  const handleMarcarComoLeido = async () => {
    const selectedList = list.filter((x) => x.checked);
    for (let i = 0; i < selectedList.length; i++) {
      const notificacion = selectedList[i];
      if (notificacion._id.estado === 'SIN_LEER') {
        await putRequest({ url: `user/notificaciones/read/${notificacion._id._id}`, body: { estado: 'LEIDO' } });
        loadData();
      }
    }
  };

  return (
    <Module title='Notificaciones'>
      <Search onSearch={handleSearch}>
        <Row>
          <Col sm={6}>
            <SelectUsuario value={userId} onChange={(value) => onSelect('userId', value)} />
          </Col>
          <Col sm={6}>
            <Agregar usuarios={usuarios} />
            <Button onClick={handleMarcarComoLeido} className='btn btn-primary pull-right mr-5' type='button'>
              <Icon icon='ok' /> Marcar como leido
            </Button>
          </Col>
        </Row>
      </Search>
      <If condition={!loading}>
        <If.Then>
          <Table list={list} loadData={loadData} onChangeChecked={onChangeChecked} onChangeCheckedAll={onChangeCheckedAll} checkedAll={checkedAll} />
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </If.Then>
        <If.Else>
          <br />
          <Loading />
        </If.Else>
      </If>
    </Module>
  );
};

List.propTypes = {
  user: PropTypes.object.isRequired,
};

export default List;
