import React, { useState, useEffect, Fragment } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import Concepto from './Concepto';
import moment from 'moment';
import { PAGADA, PAGADA_MANUAL, CANCELADA } from '@config/constants';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ESTATUS_COMPRAS = {
  INICIADA: 'INICIADA',
  EN_PROCESO: 'EN PROCESO',
  CANCELADA: 'CANCELADA',
  PAUSADA: 'PAUSADA',
  FINALIZADA: 'FINALIZADA',
};

const DetalleCompra = () => {
  const params = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { compraId } = params;
      const { data } = await getRequest({ url: `compra/${compraId}` });
      setData(data);
    };

    fetchData();
  }, [params]);

  const renderDetalle = () => {
    const {
      detalle,
      encabezado: { iva, importe, descuento, sub_total },
    } = data;

    const detalles = detalle.map((d, i) => {
      const {
        codigo,
        descripcion,
        unidadMedidaId: { measure },
        precio,
        cantidad,
        cantidad_recibida,
        descuento,
        iva,
        importe,
      } = d;
      return (
        <tr key={i}>
          <td>{cantidad}</td>
          <td>{cantidad_recibida}</td>
          <td>{codigo}</td>
          <td>{descripcion}</td>
          <td>{measure}</td>
          <td className='text-right'>${format(precio, 2)}</td>
          <td className='text-right'>${format(descuento, 2)}</td>
          <td className='text-right'>${format(iva, 2)}</td>
          <td className='text-right'>${format(importe, 2)}</td>
        </tr>
      );
    });

    return (
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th>Cant.</th>
            <th>Cant. Recibida</th>
            <th>Código</th>
            <th>Descripción</th>
            <th>Medida</th>
            <th className='text-right'>Precio</th>
            <th className='text-right'>Descuento</th>
            <th className='text-right'>IVA</th>
            <th width='8%' className='text-right'>
              Importe
            </th>
          </tr>
        </thead>
        <tbody>
          {detalle.length > 0 ? detalles : <RowsNotFound message='Sin detalle' colSpan={11} />}
          {detalle.length > 0 ? (
            <Fragment>
              <tr>
                <th colSpan='7' className='text-right'>
                  Subtotal:
                </th>
                <td colSpan='3' className='text-right'>
                  $ {format(sub_total, 2)}
                </td>
              </tr>
              {iva ? (
                <tr>
                  <th colSpan='7' className='text-right'>
                    IVA:
                  </th>
                  <td colSpan='3' className='text-right'>
                    $ {format(iva, 2)}
                  </td>
                </tr>
              ) : null}
              {descuento ? (
                <tr>
                  <th colSpan='7' className='text-right'>
                    Descuento:
                  </th>
                  <td colSpan='3' className='text-right'>
                    $ {format(descuento, 2)}
                  </td>
                </tr>
              ) : null}
              <tr>
                <th colSpan='7' className='text-right'>
                  Total:
                </th>
                <td colSpan='3' className='text-right'>
                  $ {format(importe, 2)}
                </td>
              </tr>
            </Fragment>
          ) : null}
        </tbody>
      </table>
    );
  };

  const renderDomicilio = (proveedor) => {
    if (!proveedor) return '';
    const { street, numberExt, numberInt, colony, city, postalCode, state } = proveedor.address;
    return `${street} #${numberExt}-${numberInt},${postalCode} ${colony} ${city}, ${state}`;
  };

  const renderData = () => {
    const {
      encabezado: {
        provider,
        fecha,
        fecha_entrega,
        importe,
        moneda,
        estado,
        cancelacion,
        fecha_pago,
        pago_manual,
        tipoCompraId,
        observaciones,
        estatus_compra,
        usoCfdiId,
        sucursal,
        agente,
      },
    } = data;

    return (
      <Fragment>
        <Card>
          <Card.Body>
            <Row>
              <Col xs='6'>
                <Concepto label='Fecha:' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                {fecha_entrega !== null ? <Concepto label='Fecha de entrega:' valor={moment(fecha_entrega).local().format('DD/MM/YYYY')} /> : null}
                <Concepto label='Tipo compra:' valor={tipoCompraId.nombre} />
                <Concepto label='Proveedor:' valor={provider.name} />
                <Concepto label='RFC:' valor={provider._id.rfc} />
                <Concepto label='Agente:' valor={agente.nombre} />
                <Concepto label='Uso de CFDI:' valor={usoCfdiId.codigo + ' - ' + usoCfdiId.descripcion} />
                <Concepto label='Domicilio:' valor={renderDomicilio(provider._id)} />
                <Concepto label='Estatus:' valor={ESTATUS_COMPRAS[estatus_compra]} />
                {estado === CANCELADA ? (
                  <div>
                    <p>{cancelacion.nota}</p>
                    <small>
                      Fecha de cancelación: <em>{moment(cancelacion.fecha).local().format('DD/MM/YYYY HH:mm')}</em>
                    </small>
                  </div>
                ) : null}
                {estado === PAGADA || estado === PAGADA_MANUAL ? (
                  <div>
                    {estado === PAGADA_MANUAL ? <p>{pago_manual.nota}</p> : ''}
                    <small>
                      <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
                    </small>
                  </div>
                ) : null}
              </Col>
              <Col xs='6'>
                <div className='pull-right'>
                  <Concepto label='Sucursal:' valor={sucursal.name} />
                  <Concepto label='Moneda:' valor={moneda} />
                  <div>
                    <span className='text-success'>
                      <strong>Total:</strong> <u>$ {format(importe, 2)}</u>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={{ overflowX: 'scroll' }}>
          {renderDetalle()}
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col xs='12'>
                <Concepto label='Observaciones:' />
                {observaciones}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Fragment>
    );
  };

  return (
    <Module title={`Orden de compra #${data && data.encabezado.numero_compra}`} onClickBack={window.history.back} loading={!data}>
      {data && renderData()}
    </Module>
  );
};

DetalleCompra.propTypes = {};

export default DetalleCompra;
