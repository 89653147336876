import { MONEDA, TIPO_SERVICIOS } from '@config/constants';
import { DivFormGroup, Input, TextArea } from '@controls';
import React, { useState, useEffect } from 'react';
import { Icon, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty } from 'validator';
import RowPrecio from './components/RowPrecio';
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputCurrency from '@components/forms/InputCurrency';

const NewService = () => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    reset: false,
    isLoading: true,
    service: {
      name: '',
      description: '',
      code: '',
      moneda: MONEDA.PESO,
      tipo: TIPO_SERVICIOS.MONITOREO,
      adicional: false,
      listCost: [],
      listasPreciosUnidades: [],
    },
    configurations: [],
    configurationId: '',
    recipes: [],
    recipeId: '',
    recipe: null,
    characteristics: [],
    stepsAdmin: [],
    characteristicsId: '',
    characteristic: null,
    measures: [],
    sucursales: [],
    listas_precios: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { measures, sucursales, listas_precios },
      } = await getRequest({ url: `services-catalog` });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        measures,
        sucursales,
        listas_precios,
        service: {
          ...prevState.service,
          listCost: measures.map((x) => ({
            measure: x,
            cost: 0,
            sucursales: sucursales.map((y) => ({
              sucursal: y,
              cost: 0,
            })),
          })),
          listasPreciosUnidades: measures.map((x) => ({
            unidad: x,
            precio: 0,
            listasPrecios: listas_precios.map((y) => ({
              listaPrecio: y,
              precio: 0,
            })),
          })),
        },
      }));
    };

    fetchData();
  }, []);

  const onChange = (event) => {
    let { name, checked, type, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      service: { name, description, code, tipo, adicional, moneda, listCost, listasPreciosUnidades },
    } = state;
    const data = {
      code: code,
      name: name,
      description: description,
      tipo,
      moneda,
      adicional,
      listCost: listCost.map((x) => ({
        ...x,
        cost: Number(x.cost),
        sucursales: x.sucursales.map((y) => ({
          ...y,
          cost: Number(y.cost),
        })),
      })),
      listasPreciosUnidades: listasPreciosUnidades.map((x) => ({
        unidadId: x.unidad._id,
        precio: Number(x.precio),
        listasPrecios: x.listasPrecios.map((y) => ({
          listaPrecioId: y.listaPrecio._id,
          precio: Number(y.precio),
        })),
      })),
    };

    if (validations(data)) {
      await postRequest({ url: 'services', body: data });
    }
  };

  const onChangeCosto = (index, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listCost: prevState.service.listCost.map((x, i) => {
          if (i === index)
            return {
              ...x,
              cost: cleanNumber(e.target.value),
            };
          return x;
        }),
      },
    }));
  };

  const onChangeCostoSucursal = (indexI, indexJ, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listCost: prevState.service.listCost.map((x, i) => {
          return {
            ...x,
            sucursales: x.sucursales.map((y, j) => {
              if (i === indexI && j === indexJ) {
                return {
                  ...y,
                  cost: cleanNumber(e.target.value),
                };
              }
              return y;
            }),
          };
        }),
      },
    }));
  };

  const onChangePrecioUnidad = (index, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listasPreciosUnidades: prevState.service.listasPreciosUnidades.map((x, i) => {
          if (i === index)
            return {
              ...x,
              precio: cleanNumber(e.target.value),
            };
          return x;
        }),
      },
    }));
  };

  const onChangePrecioListaPreciosUnidad = (indexI, indexJ, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listasPreciosUnidades: prevState.service.listasPreciosUnidades.map((x, i) => {
          return {
            ...x,
            listasPrecios: x.listasPrecios.map((y, j) => {
              if (i === indexI && j === indexJ) {
                return {
                  ...y,
                  precio: cleanNumber(e.target.value),
                };
              }
              return y;
            }),
          };
        }),
      },
    }));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.code + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (validate.success && isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }
    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const { isLoading, sucursales, listas_precios, service } = state;
  const { code, name, description, moneda, tipo, listCost, listasPreciosUnidades } = service;

  return (
    <Module onClickBack={window.history.back} parent='Servicios' title='Agregar Servicio' loading={isLoading}>
      <form onSubmit={handleRegister}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='code' required title='Código'>
                  <Input name='code' onChange={onChange} value={code} placeholder='Código' />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='name' required title='Nombre'>
                  <Input name='name' onChange={onChange} value={name} placeholder='Nombre' />
                </DivFormGroup>
              </Col>
              <Col sm='12'>
                <DivFormGroup name='description' required title='Características'>
                  <small>Descripción complementaria</small>
                  <TextArea name='description' onChange={onChange} value={description} placeholder='Características' />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <FormGroup className='inline'>
                  <label>Tipo de Servicio</label>
                  <div>
                    <label className='radio-inline'>
                      <input
                        type='radio'
                        name='tipo'
                        onChange={onChange}
                        value={TIPO_SERVICIOS.MONITOREO}
                        checked={tipo === TIPO_SERVICIOS.MONITOREO}
                      />{' '}
                      Monitoreo
                    </label>
                    <label className='radio-inline'>
                      <input
                        type='radio'
                        name='tipo'
                        onChange={onChange}
                        value={TIPO_SERVICIOS.INTERACTIVO}
                        checked={tipo === TIPO_SERVICIOS.INTERACTIVO}
                      />{' '}
                      Interactivo
                    </label>
                    <label className='radio-inline'>
                      <input
                        type='radio'
                        name='tipo'
                        onChange={onChange}
                        value={TIPO_SERVICIOS.MANO_OBRA}
                        checked={tipo === TIPO_SERVICIOS.MANO_OBRA}
                      />{' '}
                      Mano de obra
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col sm='3'>
                <FormGroup className='inline'>
                  <label>Moneda</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='moneda' onChange={onChange} value={MONEDA.PESO} checked={moneda === MONEDA.PESO} /> Peso
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='moneda' onChange={onChange} value={MONEDA.DOLAR} checked={moneda === MONEDA.DOLAR} /> Dolar
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon icon='usd' /> Precios
                </Card.Title>
              </Card.Header>
              <table className='table table-striped align-middle'>
                <thead>
                  <tr>
                    <th width='30%'>Medida</th>
                    <th width='20%' className='text-center'>
                      Precio
                    </th>
                    {sucursales.map((x, i) => (
                      <th key={i} width='20%' className='text-center'>
                        {x.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listCost.map((s, i) => {
                    return (
                      <tr key={i}>
                        <td>{s.measure.measure}</td>
                        <td>
                          <InputCurrency
                            name={`measureId-${s.measure._id}`}
                            className='form-control'
                            value={s.cost}
                            onChange={(e) => onChangeCosto(i, e)}
                          />
                        </td>
                        {s.sucursales.map((x, j) => (
                          <td key={j}>
                            <InputCurrency
                              name={`measureId-${x._id}-${s._id}`}
                              className='form-control'
                              value={x.cost}
                              onChange={(e) => onChangeCostoSucursal(i, j, e)}
                            />
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon icon='usd' /> Listas de Precios
                </Card.Title>
              </Card.Header>
              <table className='table table-striped align-middle'>
                <thead>
                  <tr>
                    <th width='30%'>Medida</th>
                    <th width='20%' className='text-center'>
                      Precio
                    </th>
                    {listas_precios.map((x, i) => (
                      <th key={i} width='20%' className='text-center'>
                        {x.nombre}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listasPreciosUnidades.map((s, i) => (
                    <RowPrecio
                      key={i}
                      indexI={i}
                      listaPrecioMeasure={s}
                      onChangePrecioUnidad={onChangePrecioUnidad}
                      onChangePrecioListaPreciosUnidad={onChangePrecioListaPreciosUnidad}
                    />
                  ))}
                </tbody>
              </table>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button type='submit' className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </form>
    </Module>
  );
};

NewService.propTypes = {};

export default NewService;
