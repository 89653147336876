import { DivColorBG } from '@controls';
import React, { useState, useEffect } from 'react';
import { Col, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import { Icon, Loading, Module, RowsNotFound } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import NewRecipes from './add/NewRecipes';
import NewStep from './add/NewStep';
import DeleteRecipes from './delete/DeleteRecipes';
import DeleteStep from './delete/DeleteStep';
import EditRecipes from './edit/EditRecipes';
import EditStep from './edit/EditStep';
import NameStep from './NameStep';
import ButtonDown from './options-steps/ButtonDown';
import ButtonMove from './options-steps/ButtonMove';
import ButtonUp from './options-steps/ButtonUp';
import { useParams } from 'react-router-dom';

const ConfigurationDetail = () => {
  const { configurationId } = useParams();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const { data } = await getRequest({ url: `configuration/detail/${configurationId}` });
    setDetail(data);
  };

  const handleMoveProcess = async (stepId) => {
    await putRequest({ url: `steps/${stepId}/process` });
    refresh();
  };

  const handleChangePosition = async ({ stepId, action, recipeId, addProcess }) => {
    const data = {
      action,
      recipeId,
      addProcess,
    };
    await putRequest({ url: `steps/${stepId}/position`, body: data });
    refresh();
  };

  const renderForm = () => {
    const { name, listRecipes } = detail;

    const recipes = listRecipes.map((rowRecipes, item) => {
      const { recipe, note } = rowRecipes;

      return (
        <NavItem eventKey={item} key={item}>
          <div className='clearfix'>
            <strong>{recipe}</strong>{' '}
            <span className='pull-right'>
              {' '}
              <Icon icon='menu-right' />
            </span>
            {note.length ? (
              <p style={{ paddingRight: '15px' }}>
                <small>{note}</small>
              </p>
            ) : null}
          </div>
        </NavItem>
      );
    });

    const stepsEquitment = listRecipes.map((rowRecipes, itemRecipes) => {
      const { recipe, listSteps } = rowRecipes;
      const recipeId = rowRecipes._id;

      const listStepsAdmin = listSteps.filter((step) => !step.addProcess);
      const listStepsProcess = listSteps.filter((step) => step.addProcess);

      const itemsSteps = listStepsAdmin.map((rowStep, itemStep) => {
        const { _id, addProcess } = rowStep;

        return (
          <tr key={itemStep}>
            <td>
              <ButtonMove id={1} title='Mover a Procedimiento' icon='arrow-left' callback={() => handleMoveProcess(_id)} />
              <DeleteStep id={itemStep} {...rowStep} refresh={refresh} />
              <EditStep id={itemStep} {...rowStep} refresh={refresh} tooltip={`Editar ${rowStep.step}`} />
            </td>
            <td>
              <NameStep {...rowStep} />
            </td>
            <td>
              {itemStep > 0 ? (
                <ButtonUp
                  id={3}
                  position='right'
                  callback={() =>
                    handleChangePosition({
                      stepId: _id,
                      action: 'up',
                      recipeId,
                      addProcess,
                    })
                  }
                />
              ) : null}
              {itemStep < listStepsAdmin.length - 1 ? (
                <ButtonDown
                  id={2}
                  position='right'
                  callback={() =>
                    handleChangePosition({
                      stepId: _id,
                      action: 'down',
                      recipeId,
                      addProcess,
                    })
                  }
                />
              ) : null}
            </td>
          </tr>
        );
      });

      const itemsStepsProcess = listStepsProcess.map((rowStep, itemStep) => {
        const { _id, addProcess } = rowStep;

        return (
          <tr key={itemStep}>
            <td>
              {itemStep < listStepsProcess.length - 1 ? (
                <ButtonDown
                  id={2}
                  callback={() =>
                    handleChangePosition({
                      stepId: _id,
                      action: 'down',
                      recipeId,
                      addProcess,
                    })
                  }
                />
              ) : null}
              {itemStep > 0 ? (
                <ButtonUp
                  id={3}
                  callback={() =>
                    handleChangePosition({
                      stepId: _id,
                      action: 'up',
                      recipeId,
                      addProcess,
                    })
                  }
                />
              ) : null}
            </td>
            <td>
              <NameStep {...rowStep} />
            </td>
            <td>
              <ButtonMove
                id={1}
                title='Mover a Administración'
                icon='arrow-right'
                pullPosition='pull-right'
                callback={() => handleMoveProcess(_id)}
              />
              <DeleteStep id={itemStep} {...rowStep} refresh={refresh}/>
              <EditStep id={itemStep} {...rowStep} refresh={refresh} tooltip={`Editar ${rowStep.step}`}/>
            </td>
          </tr>
        );
      });

      return (
        <Tab.Pane eventKey={itemRecipes} key={itemRecipes}>
          <Row>
            <Col sm='6'>
              <h3>
                <Icon icon='folder-open' />
                <strong>
                  <u>{recipe}</u>
                </strong>
              </h3>
            </Col>
            <Col sm='3'>
              <EditRecipes
                id={itemRecipes}
                {...rowRecipes}
                refresh={refresh}
                tooltip={`Editar ${recipe}`}
              />

              <DeleteRecipes id={itemRecipes} {...rowRecipes} name={name} refresh={refresh} />
            </Col>
            <Col sm='3'>
              <NewStep refresh={refresh} recipeId={recipeId} />
            </Col>
          </Row>

          <hr />

          {listSteps.length > 0 ? (
            <Row>
              <Col sm='6'>
                <div className='text-center'>
                  <span className='lead'>Procedimiento</span>
                </div>

                <table className='table table-striped align-middle'>
                  <thead>
                    <tr>
                      <td width='20%'></td>
                      <td width='60%'></td>
                      <td width='20%'></td>
                    </tr>
                  </thead>
                  <tbody>
                    {listStepsProcess.length > 0 ? (
                      itemsStepsProcess
                    ) : (
                      <RowsNotFound message='No hay características agregadas al Procedimiento' colSpan={3} />
                    )}
                  </tbody>
                </table>
              </Col>

              <Col sm='6'>
                <div className='text-center'>
                  <span className='lead'>Administración</span>
                </div>

                <table className='table table-striped align-middle'>
                  <thead>
                    <tr>
                      <td width='20%'></td>
                      <td width='60%'></td>
                      <td width='20%'></td>
                    </tr>
                  </thead>
                  <tbody>
                    {listStepsAdmin.length > 0 ? (
                      itemsSteps
                    ) : (
                      <RowsNotFound message='No hay características agregadas a la Administración' colSpan={3} />
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : (
            <DivColorBG text='No hay características agregadas.' color='warning' className='text-center' />
          )}
        </Tab.Pane>
      );
    });

    return (
      <Module onClickBack={window.history.back} title={`Detalle para ${name}`} icon='cog'>
        <div className='well clearfix'>
          <Col sm='6'></Col>
          <Col sm='6'>
            <NewRecipes refresh={refresh} configurationId={configurationId} />
          </Col>
        </div>
        <Tab.Container id='recipes-services' defaultActiveKey={0}>
          <Row className='clearfix'>
            <Col sm='3'>
              <h3>Tipos</h3>
              <Nav bsStyle='pills' stacked>
                {listRecipes.length > 0 ? recipes : <DivColorBG text='No hay recetas creadas.' color='warning' className='text-center' />}
              </Nav>
            </Col>
            <Col sm='9'>
              <Tab.Content animation>{stepsEquitment}</Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Module>
    );
  };

  return detail ? renderForm() : <Loading />;
};

ConfigurationDetail.propTypes = {};

export default ConfigurationDetail;
