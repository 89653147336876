import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ catalog, ...props }) => {
  const renderRows = () => {
    return catalog.map((row, item) => {
      const { _id, clave, nombre, estadoId } = row;

      return (
        <tr key={item}>
          <td>
            {estadoId?.paisId?.pais} - {estadoId?.paisId?.codigo}
          </td>
          <td>
            {estadoId?.estadoId} - {estadoId?.estado}
          </td>
          <td>{clave}</td>
          <td>{nombre}</td>
          <td className='with-actions'>
            <span className='actions'>
              <IfPermission action='eliminar'>
                <Delete id={item} municipioId={_id} nombre={nombre} {...props}  />
              </IfPermission>
              <IfPermission action='editar'>
                <Edit id={item} municipioId={_id} {...props} />
              </IfPermission>
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>País</th>
          <th width='20%'>Estado</th>
          <th width='20%'>Clave</th>
          <th width='10%'>Nombre</th>
          <th width='15%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  catalog: PropTypes.array.isRequired,
  estados: PropTypes.array.isRequired,
};

export default List;
