import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './DeleteStoreHouse';
import Edit from './EditStoreHouse';

const ListStoreHouse = ({ catalogStoreHouse }) => {
  const renderRows = () => {
    return catalogStoreHouse.map((row, item) => {
      const { _id, name, sucursalId, address } = row;
      return (
        <tr key={item}>
          <td>{name}</td>
          <td>{sucursalId ? sucursalId.name : null}</td>
          <td>{address}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={item} storehouseId={_id} sucursalId={sucursalId} name={name} />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={item} sucursalId={sucursalId} storehouseId={_id} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Nombre</th>
          <th width='20%'>Sucursal</th>
          <th width='30%'>Domicilio</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListStoreHouse.propTypes = {
  catalogStoreHouse: PropTypes.array.isRequired,
  loadStoreHouse: PropTypes.func.isRequired,
};

export default ListStoreHouse;
