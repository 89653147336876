import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { CbPagination } from '@controls';
import List from './List';
import { Col, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@components/application/controls/Stateless';
import usePagination from '@hooks/usePagination';
import { fetchAll } from '@services/solicitudesSat.service';

const Agentes = () => {
  const [pagination] = useState(50);
  const [status, setStatus] = useState(null);
  const { datos, paginaActual, count, cargando, cambiarPagina, setParams, refresh } = usePagination(fetchAll, pagination, 'numero');
  useEffect(() => {
    setParams({
      status: status?.value,
    });
  }, [status]);

  return (
    <Module title='Agentes'>
        <Row>
          <Col sm={12}>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tag' />
              </InputGroup.Text>
              <Select
                name='status'
                placeholder='Seleccione una sucursal'
                options={[
                  { value: 'ACTIVO', label: 'Activo' },
                  { value: 'BAJA', label: 'Baja' },
                  { value: 'ELIMINADO', label: 'Eliminado' },
                ]}
                isClearable
                onChange={(selectedOption) => {
                  setStatus(selectedOption);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      <Loading loading={cargando}>
        <List list={datos} refresh={refresh} />
        <div className='text-center'>
          <CbPagination activePage={paginaActual} count={count} pagination={pagination} onSelect={cambiarPagina} />
        </div>
      </Loading>
    </Module>
  );
};

Agentes.propTypes = {};

export default Agentes;
