import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import Delete from './Delete';
import DetailKits from './DetailKits';
import DetailTerceros from './DetailTerceros';
import { Card } from 'react-bootstrap';

const List = ({ catalogCanastas, ...props }) => {
  const renderRows = () => {
    return catalogCanastas.map((row, i) => {
      const { _id, maquinariaHerramientaId, listaKit, listaTerceros } = row;

      return (
        <tr key={i}>
          <td>{maquinariaHerramientaId.nombre}</td>
          <td>
            {listaKit.length} asignados
            <DetailKits id={i} planMantenimientoId={_id} nombre={maquinariaHerramientaId.nombre}/>
          </td>
          <td>
            {listaTerceros.length} asignados
            <DetailTerceros id={i} planMantenimientoId={_id} nombre={maquinariaHerramientaId.nombre}/>
          </td>
          <td>
            <Delete id={i} itemId={_id} label={maquinariaHerramientaId.nombre} {...props} />
            <Link to={`/planes-mantenimientos/edicion/${_id}`} className='btn btn-outline-info'>
              <Icon icon='pencil' />
            </Link>
          </td>
        </tr>
      );
    });
  };

  const rows = catalogCanastas.length > 0 ? renderRows() : <RowsNotFound colSpan={4} />;

  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='50%'>Maquinaria / Herramienta</th>
              <th width='20%'>Kits</th>
              <th width='20%'>Terceros</th>
              <th width='10%'></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </Card>
  );
};

List.propTypes = {
  catalogCanastas: PropTypes.array.isRequired,
};

export default List;
