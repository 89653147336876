import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ list, ...props }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, D_mnpio, c_oficina, d_asenta, d_ciudad, d_codigo, d_estado } = row;
      return (
        <tr key={item}>
          <td>{d_estado.toString().toUpperCase()}</td>
          <td>{D_mnpio.toString().toUpperCase()}</td>
          <td>
            <strong>{d_codigo || c_oficina}</strong>
          </td>
          <td>
            {d_ciudad ? `${d_ciudad.toString().toUpperCase()}, ` : ''}
            {d_asenta.toString().toUpperCase()}
          </td>
          <td className='with-actions'>
            <span className='actions'>
              <IfPermission action='eliminar'>
                <Delete id={item} coloniaId={_id} d_asenta={d_asenta} {...props}/>
              </IfPermission>
              <IfPermission action='editar'>
                <Edit id={item} coloniaId={_id} {...props} />
              </IfPermission>
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='25%'>Estado</th>
          <th width='25%'>Municipio</th>
          <th width='15%'>Codigo Postal</th>
          <th width='30%'>Ciudad, Colonia</th>
          <th width='5%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  estados: PropTypes.array.isRequired,
};

export default List;
