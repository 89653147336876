import { KITS, MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import RowEquipo from './components/RowEquipo';
import RowServicio from './components/RowServicio';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import { validateUpdate } from '@validations/kitsComerciales';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';

const NewKits = () => {
  const { addNotification: notification } = useStoreNotification();

  const navigate = useNavigate();
  const [state, setState] = useState({
    progresss: true,
    loading: false,
    tipoCotizacionId: '',
    orden: 1,
    codigo: '',
    nombre: '',
    moneda: MONEDA.DOLAR,
    precio: 0,
    tipo: null,
    clase: null,
    rows: 1,
    complementarios: 1,
    rowsServicios: 1,
    listEquipos: [{ index: 0, equipoId: null, cantidad: 1, name: '', obj: null }],
    listKits: [],
    listServicios: [{ index: 0, servicioId: null, cantidad: 1, name: '', obj: null, measureId: null }],
    listComplementarios: [],
    cotizaciones: [],
    kits: [],
    equipos: [],
    equiposComplementarios: [],
    servicios: [],
    equiposGral: [],
    medidas: [],
  });

  useEffect(() => {
    const loadData = async () => {
      const { cotizaciones, medidas } = await getRequest({ url: `kits-comerciales-catalogo` });
      setState((prevState) => ({
        ...prevState,
        cotizaciones,
        medidas,
        progresss: false,
      }));
    };
    loadData();
  }, []);

  const onChange = (event) => {
    const { name, checked, type } = event.target;
    let value = event.target.value;
    if (type === 'checkbox') value = checked;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { tipoCotizacionId, tipo, clase, orden, moneda, codigo, nombre, precio } = state;
    const lista = state.listEquipos.map((x) => ({ ...x, cantidad: cleanNumber(x.cantidad) }));
    const listaKits = state.listKits.map((x) => ({ ...x, cantidad: cleanNumber(x.cantidad) }));
    const listaComplementarios = state.listComplementarios.map((x) => ({ ...x, cantidad: cleanNumber(x.cantidad) }));
    const listaServicios = state.listServicios.map((x) => ({ ...x, cantidad: cleanNumber(x.cantidad) }));

    const data = {
      orden,
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      moneda,
      precio: cleanNumber(precio),
      tipoCotizacionId,
      tipo,
      clase,
      lista,
      listaKits,
      listaComplementarios,
      listaServicios,
    };

    if (validations(data)) {
      setState((prevState) => ({ ...prevState, loading: true }));
      await postRequest({ url: `kits-comerciales`, body: data });
      resetForm();
    }
  };

  const addItemEquipo = () => {
    setState((prevState) => ({
      ...prevState,
      listEquipos: [...prevState.listEquipos, { index: prevState.listEquipos.length, equipoId: null, cantidad: 1, name: '', obj: null }],
    }));
  };

  const removeItemEquipo = (item) => {
    setState((prevState) => ({
      ...prevState,
      listEquipos: prevState.listEquipos.filter((x, i) => i !== item),
    }));
  };

  const onChangeEquipo = (idx, equipo) => {
    setState((prevState) => ({
      ...prevState,
      listEquipos: prevState.listEquipos.map((x, i) => (i === idx ? { ...equipo } : x)),
    }));
  };

  const renderEquipos = () => {
    const views = state.listEquipos.map((equipo, i) => (
      <RowEquipo key={i} equipo={equipo} onChangeEquipo={(e) => onChangeEquipo(i, e)} onRemoveItemEquipo={() => removeItemEquipo(i)} />
    ));
    return (
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th>Equipo</th>
            <th>Cantidad</th>
            <th></th>
          </tr>
        </thead>
        <CbTableBody>
          {views}
          <CbTableFooterBotton colSpan={3} onClick={addItemEquipo} title='Agregar equipo' />
        </CbTableBody>
      </CbPanelTableResponsive>
    );
  };

  const addItemServicio = () => {
    setState((prevState) => ({
      ...prevState,
      listServicios: [...prevState.listServicios, { index: prevState.listServicios.length, servicioId: null, cantidad: 1, name: '', obj: null }],
    }));
  };

  const removeItemServicio = (item) => {
    setState((prevState) => ({
      ...prevState,
      listServicios: prevState.listServicios.filter((x, i) => i !== item),
    }));
  };

  const onChangeServicio = (idx, servicio) => {
    setState((prevState) => ({
      ...prevState,
      listServicios: prevState.listServicios.map((x, i) => (i === idx ? { ...servicio } : x)),
    }));
  };

  const renderServicios = () => {
    const views = state.listServicios.map((servicio, i) => (
      <RowServicio
        key={i}
        servicio={servicio}
        medidas={state.medidas}
        onChangeServicio={(e) => onChangeServicio(i, e)}
        onRemoveItemServicio={() => removeItemServicio(i)}
      />
    ));
    return (
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th>Servicio</th>
            <th>Unidad</th>
            <th>Cantidad</th>
            <th></th>
          </tr>
        </thead>
        <CbTableBody colSpan={4}>
          {views}
          <CbTableFooterBotton colSpan={4} onClick={addItemServicio} title='Agregar servicio' />
        </CbTableBody>
      </CbPanelTableResponsive>
    );
  };

  const renderForm = () => {
    const { orden, codigo, nombre, tipoCotizacionId, loading, clase, tipo, moneda } = state;

    return (
      <Module onClickBack={window.history.back} parent='Kits Comerciales' title='Agregar Kit comercial'>
        <form onSubmit={handleRegister}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm='6'>
                  <FormInput title='Orden' name='orden' required onChange={onChange} value={orden} />
                </Col>
                <Col sm='6'>
                  <FormInput title='Código' name='codigo' required onChange={onChange} value={codigo} />
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <FormInput title='Nombre' name='nombre' required onChange={onChange} value={nombre} />
                </Col>
                <Col sm='6'>
                  <FormGroup name='moneda' title='Moneda' required>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-dolar' onChange={onChange} value={MONEDA.DOLAR} checked={MONEDA.DOLAR === moneda} />{' '}
                        Dolares
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-peso' onChange={onChange} value={MONEDA.PESO} checked={MONEDA.PESO === moneda} />{' '}
                        Pesos
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <DivFormGroup name='tipoCotizacionId' title='Linea' required>
                    <SelectTipoLevantamiento value={tipoCotizacionId} onChange={(value) => onSelect('tipoCotizacionId', value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <FormGroup className='inline'>
                    <label>Tipo de Kit</label>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='tipo' id='rdo-hibrido' onChange={onChange} value={KITS.HIBRIDO} checked={KITS.HIBRIDO === tipo} />{' '}
                        Hibrido
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='tipo'
                          id='rdo-inalambrico'
                          onChange={onChange}
                          value={KITS.INALAMBRICO}
                          checked={KITS.INALAMBRICO === tipo}
                        />{' '}
                        Inalámbrico
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col sm='3'>
                  <FormGroup className='inline'>
                    <label>Clase de Kit</label>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='clase' id='rdo-interactivo' onChange={onChange} value={0} checked={0 === clase} /> Interactivo
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='clase' id='rdo-no-interactivo' onChange={onChange} value={1} checked={1 === clase} /> No Interactivo
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <Card.Title>Equipos</Card.Title>
                </Card.Header>
                <Card.Body>{renderEquipos()}</Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <Card.Title>Servicios</Card.Title>
                </Card.Header>
                <Card.Body>{renderServicios()}</Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Saving saving={loading} />
              <Button type='submit' className='btn btn-primary pull-right' disabled={loading}>
                <Icon icon='floppy-disk' /> Guardar
              </Button>
            </Col>
          </Row>
        </form>
      </Module>
    );
  };

  const resetForm = () => {
    setTimeout(() => navigate(0), 1000);
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error,
        type: 'error',
      });
    }
    return !error;
  };

  return !state.progresss ? renderForm() : <Loading />;
};

NewKits.propTypes = {};

export default NewKits;
