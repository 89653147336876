import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { urlPhoto } from '@utils/api';
import PropTypes from 'prop-types';
import { isURL } from 'validator';

const CbModalImage = ({ imagen, show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Imagen</Modal.Title>
      </Modal.Header>
      <img
        className='img-responsive'
        style={{ margin: 'auto', maxWidth: '100%' }}
        src={imagen ? (isURL(imagen) ? imagen : urlPhoto() + imagen) : 'https://ftp3.syscom.mx/usuarios/fotos/imagen_no_disponible.jpg'}
        alt='...'
      />
      <Modal.Footer style={{ textAlign: 'center' }}>
        <Button onClick={onClose} className='mr-5' type='button'>
          &nbsp;Cerrar&nbsp;
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CbModalImage.propTypes = {
  imagen: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CbModalImage;
