import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const INIT_STATE = {
  showModal: false,
  codigo: '',
  nombre: '',
  sucursalId: null,
  en_equipos: false,
  en_servicios: false,
  en_monitoreos: false,
};

const Edit = ({ id, nombre, codigo, sucursalId, en_equipos, en_servicios, en_monitoreos, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    ...INIT_STATE,
    isLoading: false,
    sucursales: [],
  });

  const close = () => {
    setState({ ...INIT_STATE });
  };

  const open = async () => {
    const {
      data: { sucursales },
    } = await getRequest({ url: `services-catalog` });
    setState({
      sucursales,
      nombre,
      codigo,
      sucursalId,
      en_equipos,
      en_servicios,
      en_monitoreos,
      showModal: true,
      isLoading: false,
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { codigo, nombre, sucursalId, en_equipos, en_servicios, en_monitoreos } = state;
    const data = {
      codigo,
      nombre,
      sucursalId: sucursalId?._id,
      en_equipos,
      en_servicios,
      en_monitoreos,
    };

    if (validations(data)) {
      await putRequest({ url: `listas-precios/${id}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const {
    showModal,
    codigo: stateCodigo,
    nombre: stateNombre,
    sucursalId: stateSucursalId,
    isLoading,
    en_equipos: stateEnEquipos,
    en_servicios: stateEnServicios,
    en_monitoreos: stateEnMonitoreos,
  } = state;

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='codigo' title='Código' required>
              <input
                type='text'
                id='codigo'
                name='codigo'
                className='form-control'
                onChange={(e) => setState({ ...state, codigo: e.target.value })}
                defaultValue={stateCodigo}
                placeholder='Nombre'
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='nombre' title='Nombre' required>
              <input
                type='text'
                id='nombre'
                name='nombre'
                className='form-control'
                onChange={(e) => setState({ ...state, nombre: e.target.value })}
                defaultValue={stateNombre}
                placeholder='Nombre'
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='sucursalId' title='Sucursal' required>
              <Select
                name='sucursalId'
                value={stateSucursalId}
                isClearable
                onChange={(value) => setState({ ...state, sucursalId: value })}
                options={state.sucursales}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                placeholder='Seleccione una sucursal'
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup title='Se usa en'>
              <FormGroup className='inline'>
                <label className='radio-inline'>
                  <input
                    type='checkbox'
                    name='en_equipos'
                    onChange={(e) => setState({ ...state, en_equipos: e.target.checked })}
                    checked={stateEnEquipos}
                  />{' '}
                  Equipos
                </label>
                <label className='radio-inline'>
                  <input
                    type='checkbox'
                    name='en_servicios'
                    onChange={(e) => setState({ ...state, en_servicios: e.target.checked })}
                    checked={stateEnServicios}
                  />{' '}
                  Servicios
                </label>
                <label className='radio-inline'>
                  <input
                    type='checkbox'
                    name='en_monitoreos'
                    onChange={(e) => setState({ ...state, en_monitoreos: e.target.checked })}
                    checked={stateEnMonitoreos}
                  />{' '}
                  Monitoreo
                </label>
              </FormGroup>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,

  codigo: PropTypes.string.isRequired,
  sucursalId: PropTypes.object.isRequired,
  en_equipos: PropTypes.bool.isRequired,
  en_servicios: PropTypes.bool.isRequired,
  en_monitoreos: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
};

export default Edit;
