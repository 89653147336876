import { RedirectTooltip } from '@controls';
import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module, RowsNotFound } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import { Card, Col, InputGroup, Row } from 'react-bootstrap';
import SelectClasificacionCotizacion from '@components/forms/SelectClasificacionCotizacion';
import SelectMarca from '@components/forms/SelectMarca';
import Select from '@components/forms/Select';

const Rendimientos = () => {
  const [loading, setLoading] = useState(false);
  const [listEquipments, setListEquipments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [marcaId, setMarcaId] = useState(null);
  const [status, setStatus] = useState('ACTIVO');
  const [estatus, setEstatus] = useState([]);
  const [clasificacionCotizacionId, setClasificacionCotizacionId] = useState(null);

  const status_equipos = {
    ACTIVE: 'ACTIVO',
    INACTIVE: 'INACTIVO',
    REMOVED: 'ELIMINADO',
    BAJA: 'BAJA',
    CONVERTIDO: 'CONVERTIDO',
  };

  useEffect(() => {
    loadCatalogos();
  }, []);

  const loadCatalogos = async () => {
    setEstatus([
      {
        value: '0',
        label: 'Todos los estatus',
      },
      ...Object.entries(status_equipos).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    ]);
    handlePagination(1);
  };

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      marcaId,
      clasificacionCotizacionId,
      status,
    };
    const { data, count } = await postRequest({
      url: `equipments-rendimientos/search`,
      params: { page, limit: pagination },
      body,
    });
    setListEquipments(
      data.map((equipo) => {
        let rendimientos = {
          rend0: { value: 0, level: 1 },
          rend1: { value: 0, level: 2 },
          rend2: { value: 0, level: 3 },
          rend3: { value: 0, level: 4 },
        };
        if (equipo.listWorkforce.length > 0) {
          const list = equipo.listWorkforce.sort((a, b) => a.level - b.level);
          rendimientos.rend0 = list[0] || { value: 0, level: 1 };
          if (list.length > 1) {
            rendimientos.rend1 = list[1] || { value: 0, level: 2 };
          }
          if (list.length > 2) {
            rendimientos.rend2 = list[2] || { value: 0, level: 3 };
          }
          if (list.length > 2) {
            rendimientos.rend3 = list[2] || { value: 0, level: 4 };
          }
        }
        return {
          ...equipo,
          ...rendimientos,
        };
      })
    );
    setCount(count);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const renderRows = () => {
    return listEquipments.map((rowProduct, item) => {
      const { _id, code, name, clasificacionCotizacionId, marcaId, equipmentsMeasureId, rend0, rend1, rend2, rend3 } = rowProduct;
      return (
        <tr key={item}>
          <td>
            <small>{clasificacionCotizacionId && clasificacionCotizacionId.nombre} </small>
          </td>
          <td>{marcaId && <small>{marcaId.nombre}</small>}</td>
          <td>
            <u>{code}</u>
          </td>
          <td>
            <strong>{name}</strong>
          </td>
          <td>{equipmentsMeasureId && equipmentsMeasureId.measure}</td>
          <td>{rend0.value}</td>
          <td>{rend1.value}</td>
          <td>{rend2.value}</td>
          <td>{rend3.value}</td>
          <td>
            <RedirectTooltip id={1} icon='pencil' url={`/equipos/edicion/${_id}`} labelTooltip='Editar' className='btn btn-outline-info' />
          </td>
        </tr>
      );
    });
  };

  let rows = listEquipments.length > 0 ? renderRows() : <RowsNotFound message='Aún no hay equipos registrados.' colSpan={8} />;
  return (
    <Module onClickBack={window.history.back} title='Rendimientos'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8' searchOnStopType {...{ search }}>
        <Row>
          <Col sm='3'>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tags' title='Estatus' />
              </InputGroup.Text>
              <Select
                value={status}
                options={estatus}
                onChange={(value) => {
                  setStatus(value);
                  handlePagination(1);
                }}
              />
            </InputGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Estatus' />
                </InputGroup.Text>
                <SelectClasificacionCotizacion value={clasificacionCotizacionId} onChange={(value) => setClasificacionCotizacionId(value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <Icon icon='tags' title='Estatus' />
                </InputGroup.Text>
                <SelectMarca value={marcaId} onChange={(value) => setMarcaId(value)} />
              </InputGroup>
            </DivFormGroup>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <Card>
          <div className='table-responsive'>
            <table className='table table-striped align-middle'>
              <thead>
                <tr>
                  <th width='10%'>Linea</th>
                  <th width='5%'>Marca</th>
                  <th width='10%'>Código</th>
                  <th width='20%'>Nombre</th>
                  <th width='10%'>Unidad</th>
                  <th width='10%'>Rend 0</th>
                  <th width='10%'>Rend 1</th>
                  <th width='10%'>Rend 2</th>
                  <th width='10%'>Rend 3</th>
                  <th width='10%'>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
            <div className='text-center'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
            </div>
          </div>
        </Card>
      </Loading>
    </Module>
  );
};

Rendimientos.propTypes = {};

export default Rendimientos;
