import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import FormAgente from './FormAgente';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const NewAgentes = ({ refresh }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [maximoDescuento, setMaximoDescuento] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [recibePedidos, setRecibePedidos] = useState(false);
  const [recibeServicios, setRecibeServicios] = useState(false);
  const [ocupaAutorizacion, setOcupaAutorizacion] = useState(false);
  const [esTecnico, setEsTecnico] = useState(false);
  const [sucursalId, setSucursalId] = useState(null);
  const [almacenId, setAlmacenId] = useState(null);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setNombre('');
    setCodigo('');
    setMaximoDescuento(0);
    setIsLoading(false);
    setRecibePedidos(false);
    setRecibeServicios(false);
    setEsTecnico(false);
    setOcupaAutorizacion(false);
    setSucursalId(null);
    setAlmacenId(null);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      codigo: codigo.trim(),
      nombre: nombre.trim(),
      sucursalId: sucursalId?._id,
      almacenId: almacenId?._id,
      maximo_descuento: maximoDescuento,
      recibe_pedidos: recibePedidos,
      recibe_servicios: recibeServicios,
      es_tecnico: esTecnico,
      ocupa_autorizacion: ocupaAutorizacion,
    };

    if (validations(data)) {
      const { error } = await postRequest({ url: 'agentes', body: data });
      if (!error) {
        setShowModal(false);
        refresh();
      }
      setIsLoading(false);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar agente' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <FormAgente
          nombre={nombre}
          codigo={codigo}
          maximo_descuento={maximoDescuento}
          recibe_pedidos={recibePedidos}
          recibe_servicios={recibeServicios}
          es_tecnico={esTecnico}
          ocupa_autorizacion={ocupaAutorizacion}
          sucursalId={sucursalId}
          almacenId={almacenId}
          setState={(state) => {
            setNombre(state.nombre);
            setCodigo(state.codigo);
            setMaximoDescuento(state.maximo_descuento);
            setRecibePedidos(state.recibe_pedidos);
            setRecibeServicios(state.recibe_servicios);
            setEsTecnico(state.es_tecnico);
            setOcupaAutorizacion(state.ocupa_autorizacion);
            setSucursalId(state.sucursalId);
            setAlmacenId(state.almacenId);
          }}
        />
      </CbModal>
    </>
  );
};

NewAgentes.propTypes = {
  refresh: PropTypes.func.isRequired,

  sucursales: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
};

export default NewAgentes;
