import { ABONADA, CANCELADA, PAGADA, PAGADA_MANUAL, PENDIENTE } from '@config/constants';
import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';

const RowFactura = ({ factura }) => {
  const switchEstado = (estado) => {
    let text = '';
    let color = 'secondary';

    if (estado === PENDIENTE) {
      text = 'Pendiente de pago';
      color = 'secondary';
    } else if (estado === PAGADA) {
      text = 'Pagada';
      color = 'success';
    } else if (estado === PAGADA_MANUAL) {
      text = 'Pago manual';
      color = 'success';
    } else if (estado === ABONADA) {
      text = 'Abonada';
      color = 'warning';
    } else if (estado === CANCELADA) {
      text = 'Cancelada';
      color = 'danger';
    }
    return <CbBadge type={color}>{text}</CbBadge>;
  };

  const onClickDownloadPdf = async (id, event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `factura/pdf/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const onClickDownloadXml = async (id, event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `factura/xml/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const { _id, serie, numero, uuid, importe, saldo, estado, fecha_pago, fecha } = factura;

  return (
    <tr>
      <td>
        {serie}
        {numero}
      </td>
      <td>{moment(fecha).local().format('DD/MM/YYYY HH:mm')}</td>
      <td>{uuid}</td>
      <td>${format(importe, 2)}</td>
      <td>${format(saldo, 2)}</td>
      <td>
        {switchEstado(estado)}
        {(estado === PAGADA || estado === PAGADA_MANUAL) && fecha_pago ? (
          <span>
            <br />
            <small>
              <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
            </small>
          </span>
        ) : null}
      </td>
      <td>
        <RedirectTooltip id={1} icon='search' url={`/facturas/detalle/${_id}`} labelTooltip='Completa' className='btn btn-sm btn-primary mr-5' />
        <OverlayTrigger placement='top' overlay={<Tooltip id={_id}>Descargar PDF</Tooltip>}>
          <Button type='button' onClick={(e) => onClickDownloadPdf(_id, e)} className='btn btn-sm btn-primary mr-5'>
            <Icon icon='download-alt' />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={<Tooltip id={_id}>Descargar XML</Tooltip>}>
          <Button type='button' onClick={(e) => onClickDownloadXml(_id, e)} className='btn btn-sm btn-success '>
            <Icon icon='file' />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

RowFactura.propTypes = {
  factura: PropTypes.object.isRequired,
};

export default RowFactura;
